import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

/**
 * Component to handle form items with basic Text inputs.
 * REQUIRED PROPS: 
 * - fieldName (for validation), 
 * - fieldLabel (for UI),
 * - onChange (handled in parent and passed), message (used primarily for error cases).
 * - value (controlled components should be handled in the parent),
 * DEFAULT VALS:  
 * - requiered defaults to TRUE (must declare if NOT required),
 * - disabled defaults to FALSE (must declare if disabled)
 * OPTIONAL PROPS:
 * - placeholder
 * - onBlur: function
 * - defaultValue,
 * - type: string - must be a type of inputType in html spec.
 * - error: send the whole error message node from validator.
 */

const FormInputField = ({
    // REQUIRED
    fieldName,
    fieldLabel,
    onChange,
    value,          
    // BOOLS. 
    required = true,        // default to true
    disabled = false,       // default to false
    // OPTIONAL PROPS
    placeholder = "",
    onBlur,
    defaultValue,
    type = "text",
    error                 // used for error messages. 
}) => {

    return (
        <FormGroup className={error ? "form-error" : ""}>
            <Label 
                for={fieldName} 
                className="form-label"
            >
                {fieldLabel}
                {required &&
                    <span aria-hidden="true" className="required-indicator">*</span>
                }
            </Label>
            <Input
                id={fieldName}
                className={"form-control"}
                disabled={disabled}
                
                type={type}
                name={fieldName}
                aria-labelledby={fieldName}
                aria-required={required ? "true" : "false"}

                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
            />
            {error && error}
        </FormGroup>
    )
}

export default FormInputField;