import React, { Component } from 'react';
import {
    Container, Row
} from 'reactstrap';
import ProgressBar from '../../../components/Vendor/Apple/IntakeLoss/ProgressBar';
import { Entitlement as AppleEntitlement } from '../../../components/Vendor/Apple/IntakeLoss/Entitlement';
import ClaimSummary from '../../../components/Vendor/Apple/IntakeLoss/ClaimSummary';
import { callAdobe } from '../../../helpers';
//import NeedHelp from '../../../components/Vendor/Apple/IntakeLoss/NeedHelp';
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent';
import { CombinedLangContractContext } from '../../../components/contexts/ProviderLangContractContext';

export default class EntitlementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entitlementDetails: {}
        }
    }

    componentDidMount() {
        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: 'vendor/apple/entitlement',
            pageName: 'Incident Info',
            country: this.context.languageContext?.country,
            language: this.context.languageContext?.language,
            currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
            caseId: this.props?.history?.location?.state?.entitlementDetails?.caseNumber
        })
    }

    continue = e => {
        e.preventDefault();
    }

    render() {
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/EntitlementContainer'}>
                {international => {
                    const { translations } = international
                    return (
                        <Container className="contentWrapper">
                            <Row>
                                <section className="formBody col-lg-7 mr-auto">
                                    { /* <!--Progress Bar Component Goes Here--> */}
                                    <ProgressBar step={1} aria-hidden="true" />

                                    <h1>
                                        {translations.title || 'Tell Us What Happened'}
                                    </h1>
                                    <AppleEntitlement history={this.props.history} />
                                </section>
                                <ClaimSummary step={1} entitlementDetails={this.props.history.location.state.entitlementDetails} />

                                { /* <!--Need Help Component Goes Here--> */}
                                {/*<NeedHelp/>*/}
                            </Row>
                        </Container>
                    )
                }}
            </UseTranslationInClassComponent>

        );
    }
}

EntitlementContainer.contextType = CombinedLangContractContext;