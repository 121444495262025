import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Alert } from 'reactstrap';
import RadioGroup from '../../shared/RadioGroup/RadioGroup';
import { MyPlansTableView } from './MyPlansTableView';
import { MyPlansTileView } from './MyPlansTileView';
import styles from './MyPlans.module.css';
import classNames from 'classnames';
import { ReactComponent as QueueIcon } from '../../../assets/images/menu-list.svg';
import { ReactComponent as KanbanIcon } from '../../../assets/images/menu-grid-square.svg';
import { ContractContext } from '../../contexts/ContractContext';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { getCoverageDocument } from '../../Global/DocumentHelper';
import ReprintContractModal from '../../../screens/PlanOverview/ReprintContract';
import { parse } from 'query-string';

export const MyPlans = ( props ) => {
    const { planView } = props;
    const [view, setView] = useState(planView || 'kanban')
    const history = useHistory();
    const location = useLocation();
    const context = useContext(ContractContext);
    const { contracts } = context.data;
    const { clientId } = context?.data?.contract;
    const [showReprintContractModal, setShowReprintContractModal] = useState(false);
    const [showReprintReviewMessage, setShowReprintReviewMessage] = useState(false);
    const [token, setToken] = useState(parse(location.search)?.id || parse(location.search)?.token)

    const handleSelectedActionClick = (action, contractDetails) => {
        switch (action) {
            case "ViewPlanDetails":
                let pathName = '/contractoverview';

                history.push({
                    pathname: pathName,
                    search: typeof token == 'undefined' ? null : `?token=${token}`,
                    state: {
                        contractDetails: JSON.stringify(contractDetails),
                        view: view,
                    }
                });
                break;

            case "TermsAndConditions":
                getCoverageDoc(contractDetails);
                break;
            case "ReprintContract":
                handleReprintContractModal();
                break;
        }
    }

    const getCoverageDoc = (contractDetails) => {
        const { riskProfileId, assetId, purchaseDate } = contractDetails;

        getCoverageDocument(clientId, riskProfileId, assetId, purchaseDate);
    }

    const handleReprintContractModal = () => {
        setShowReprintContractModal(true);
    };

    const handleReprintSubmit = () => {
        setShowReprintContractModal(!showReprintContractModal)
        setShowReprintReviewMessage(true);
        window.scrollTo(0, 0)
        setTimeout(() => {
            setShowReprintReviewMessage(false)
        }, 5000)
    }

    const handleChangeView = (newView) => {
        setView(newView);
        history.push({
            pathname: '/planoverview',
            search: typeof token == 'undefined' ? null : `?token=${token}`,
            state: {
                view: newView,
                activeOverviewTab: 'myPlans'
            }
        });
    }

    return (
        <>
            {showReprintReviewMessage && (
                <Alert isOpen={showReprintReviewMessage} className={styles.reprintAlert}>
                    Your reprint request was submitted!
                </Alert>
            )}
            <Row>
                <Col md="12" lg="12" className={classNames(styles.planViewToggle , "mb-4" , "d-flex")}>
                    <span className={styles.planCount}>{contracts.length} {"Plans"}</span>
                    <RadioGroup 
                        buttons={[
                            { iconL: <KanbanIcon />, value: 'kanban' },
                            { iconL: <QueueIcon />, value: 'queue' },
                        ]}
                        onChange={(newView) => handleChangeView(newView)}
                        selected={view}
                    />
                </Col>
            </Row>
            {view === "kanban" ?
                <MyPlansTileView contracts={contracts} props={props} handleSelectedActionClick={handleSelectedActionClick} contractTerms={props?.contractTerms}/>
            :
                <MyPlansTableView contracts={contracts} props={props} handleSelectedActionClick={handleSelectedActionClick} contractTerms={props?.contractTerms}/>
            }            
            {showReprintContractModal && (
                <ReprintContractModal
                    isOpen={showReprintContractModal}
                    centered={true}
                    toggle={() => setShowReprintContractModal(!showReprintContractModal)}
                    isHome={true}
                    handleSubmit={handleReprintSubmit}
                />
            )}
        </>
    )
}