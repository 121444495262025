import React, { useEffect, useState, useContext } from 'react';
import { randomized } from '../shared/util';
import { useClickOutside } from '../_ReactHooks/useClickOutside';
import { Button } from 'reactstrap';

export const DropDownInput = ({
    options,
    defaultValue,
    isFloatingEnabled = false,
    hasSearch,
    fullWidth,
    onChangedValue,
    placeholder,
    ariaLabel,
    id,
    validator,
    disabled = false
}) => {
    const [uniqueDropdownId,] = useState(`drpdown_${randomized()}`)
    const [filteredOptions, setFilteredOptions] = useState([])
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValueText, setSelectedValueText] = useState('');
    const [dropped, setDropped] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (!!defaultValue) {
            const index = options.findIndex(opt => opt.data === defaultValue)
            if (index > -1) {
                setSelectedValue(defaultValue)
                setSelectedValueText(options.filter(o => o.data === defaultValue)[0].display)
            }
        }
    })

    useClickOutside(id || uniqueDropdownId, () => setDropped(false))

    const handleDropClick = (e) => {
        e.preventDefault()
        setDropped(prevDropped => !prevDropped)
    }

    const handleSearchOptions = (e) => {
        const searchQuery = e.target.value.toLowerCase()
        const filteredOptions = [...options.filter(o => o.display.toLowerCase().includes(searchQuery))]
        setSearchQuery(searchQuery)
        setFilteredOptions(filteredOptions)
    }

    const handleSelectClick = (opt) => {
        const { data, display } = opt
        setSelectedValue(data)
        setSelectedValueText(display)
        setDropped(false)
        onChangedValue(data)
    }

    const getFilteredOptions = () => {
        if (searchQuery) return filteredOptions
        return options
    }

    return (
        <div className={isFloatingEnabled ? "full-width has-float-label" : "full-width"}>
            <div className="dropdown" id={id || uniqueDropdownId}>
                <button
                    className={`dropbtn dropbtn-placeholder form-control ${fullWidth && 'optionsBtn'}`}
                    onClick={handleDropClick}
                    aria-label={`${ariaLabel || 'Selected option is'} ${selectedValueText}`}
                    disabled={disabled}
                >
                    {selectedValue === ''
                        ? placeholder
                        : selectedValueText
                    }
                </button>

                <div
                    className="dropdown-content"
                    style={{ display: dropped ? 'block' : 'none' }}
                    role="listbox"
                    tabIndex={0}
                >
                    {hasSearch && (
                        <input
                            className="dropdown-search"
                            placeholder='Search...'
                            type="text"
                            autofocus='true'
                            onChange={handleSearchOptions}
                        />
                    )}
                    {getFilteredOptions().map((opt, index) => (
                        <Button
                            className="dropDownSelectedInput"
                            key={index}
                            onClick={() => handleSelectClick(opt)}
                            id={opt.display}
                            aria-selected={opt.display === selectedValueText}
                            role="option"
                        >
                            {opt.display}
                        </Button>
                    ))}
                </div>
            </div>
            {isFloatingEnabled && (
                <label htmlFor={uniqueDropdownId} >
                    {selectedValue === '' ? '' : placeholder}
                </label>
            )}
            {validator}

        </div>
    )
}