import React, { useState, useEffect, useContext } from 'react'
import styles from './Header.module.css'
import { ReactComponent as UsFlag } from '../../../assets/images/Vendor/Apple/flags/flag-us.svg';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { LanguageContext } from '../../contexts/LanguageTranslation/LanguageContext'
import { CircleFlag } from 'react-circle-flags'
import { useEnvironment } from '../../_ReactHooks/useEnvironment';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label } from "reactstrap";
import FooterButtonsRow from "../../shared/FooterButtonsRow/FooterButtonsRow";
import { ContractContext } from '../../contexts/ContractContext';
import { getTranslation } from '../../../helpers';
import { saveLanguage } from '../../Global/helpers';

// FUTURE EXPANSION: Add additional flags by country here
const GermanyFlag = () => <CircleFlag countryCode='de' height="24" style={{ marginRight: '16px' }} />
const SpainFlag = () => <CircleFlag countryCode='es' height="24" style={{ marginRight: '16px' }} />
const ItalyFlag = () => <CircleFlag countryCode='it' height="24" style={{ marginRight: '16px' }} />
const FranceFlag = () => <CircleFlag countryCode='fr' height="24" style={{ marginRight: '16px' }} />
const UKFlag = () => <CircleFlag countryCode='gb' height="24" style={{ marginRight: '16px' }} />
const VNFlag = () => <CircleFlag countryCode='vn' height="24" style={{ marginRight: '16px' }} />
const CLFlag = () => <CircleFlag countryCode='cl' height="24" style={{ marginRight: '16px' }} />
const NLFlag = () => <CircleFlag countryCode='nl' height="24" style={{ marginRight: '16px' }} />
const NOFlag = () => <CircleFlag countryCode='no' height="24" style={{ marginRight: '16px' }} />
const SVFlag = () => <CircleFlag countryCode='se' height="24" style={{ marginRight: '16px' }} />
const DAFlag = () => <CircleFlag countryCode='dk' height="24" style={{ marginRight: '16px' }} />
const SGFlag = () => <CircleFlag countryCode='sg' height="24" style={{ marginRight: '16px' }} />
const FIFlag = () => <CircleFlag countryCode='fi' height="24" style={{ marginRight: '16px' }} />
const ATFlag = () => <CircleFlag countryCode='at' height="24" style={{ marginRight: '16px' }} />
const DKFlag = () => <CircleFlag countryCode='dk' height="24" style={{ marginRight: '16px' }} />
const CHFlag = () => <CircleFlag countryCode='ch' height="24" style={{ marginRight: '16px' }} />
const IEFlag = () => <CircleFlag countryCode='ie' height="24" style={{ marginRight: '16px' }} />
const NZFlag = () => <CircleFlag countryCode='nz' height="24" style={{ marginRight: '16px' }} />

const LanguageToggle = (props) => {
    const [isLangDropOpen, setIsLangDropOpen] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showLangModal, setShowLangModal] = useState(false);
    const { language, country, updateLanguage, updateCountry, updateLanguageBeforeLogin } = useContext(LanguageContext);
    const [preferredLang, setPreferredLang] = useState(language);
    const [newLangTranslations, setNewLangTranslations] = useState({});
    const context = useContext(ContractContext);
    const isChileApple = context?.data?.isChileApple;
    const contractId = context.data?.contract?.contractId;

    const langCountryMappings = (country) => {
        switch (country) {
            case 'de': return 'DE'
            case 'es': return 'ES'
            case 'fr': return 'FR'
            case 'it': return 'IT'
            case 'vn': return 'VN'
            case 'cl': return 'ES/CL'
            case 'nl': return 'NL'
            case 'no': return 'NO'
            case 'sv': return 'SV'
            case 'da': return 'DA'
            case 'fi': return 'FI'
            case 'sg': return 'DE/SG'
            case 'at': return 'DE/AT'
            case 'ch': return 'FR'
            case 'dk': return 'DK'
            default: return 'US'
        }
    }

    const handleSelectLanguage = async (lang) => {
        let showModal = JSON.parse(sessionStorage.getItem('preferredLangModal'));
        setIsLangDropOpen(false);
        setPreferredLang(lang);

        if (props.isForDev) {
            saveSelectedLanguage(lang, false);
            return;
        }
        if (contractId && (showModal === null || showModal === true)) {
            if (lang.toLowerCase() === 'en') {
                setShowLangModal(true);
            } else {
                let pagePath = 'components/Vendor/Apple/AppleHeader';
                const fullPath = `customer/${langCountryMappings(lang)}/${pagePath || ''}.json`

                await getTranslation(fullPath)
                    .then(response => response.json())
                    .then(data => {
                        setNewLangTranslations(data);
                        setShowLangModal(true);
                    }).catch(error => {
                        console.log(error);
                    })
            }
        } else {
            saveSelectedLanguage(lang, false);
        }
    }

    const getLangCode = (language, countryCode) => {
        let langCode = language;

        if (language.toLowerCase() === 'cl') {
            langCode = 'es';
        }
        if (language.toLowerCase() === 'vn') {
            langCode = 'vi';
        }
        if (language.toLowerCase() === 'at' || language.toLowerCase() === 'sg') {
            langCode = 'de';
        }
        return langCode?.toLowerCase() + '-' + countryCode?.toLowerCase();
    }

    const saveSelectedLanguage = (lang, isSaving = true) => {
        updateLanguage(lang.toLowerCase())
        // saveLanguage
        if (isSaving) {
            const updatedLangCode = getLangCode(lang, country);

            saveLanguage(contractId, updatedLangCode, 'apple');
            updateLanguageBeforeLogin(lang.toLowerCase());
        } else {
            updateLanguageBeforeLogin(lang.toLowerCase())
        }
        setForceUpdate(!forceUpdate);
        window.digitalData = {
            ...window.digitalData,
            site: {
                ...window.digitalData.site,
                language: lang.toLowerCase()
            }
        }
        setShowLangModal(false);
    }

    const cancelSelectedLanguage = () => {
        setShowLangModal(false);
    }

    const FlagToggle = ({
        toggleProps = {
            label: 'English',
            icon: <UKFlag />
        },
        icon,
        showIcon = false
    }) => (
        <div className={styles.langToggleBtnFlex} >
            {showIcon && <span className={styles.flagIcon}>{icon ?? toggleProps.icon}</span>}
            <span className={styles.languageLabel}>{toggleProps.label}</span>
        </div>
    )


    // FUTURE EXPANSION: Add additional toggleProps by LANGUAGE here
    const toggleProps = {
        en: { label: 'English', icon: <UKFlag /> },
        us: { label: 'English', icon: <UKFlag /> },
        de: { label: 'Deutsch', icon: <GermanyFlag /> },
        es: { label: 'Espa\u00f1ol', icon: <SpainFlag /> },
        fr: { label: 'Fran\u00e7ais', icon: <FranceFlag /> },
        it: { label: 'Italiano', icon: <ItalyFlag /> },
        vn: { label: 'Tiếng Việt', icon: <VNFlag /> },
        cl: { label: 'Espa\u00f1ol', icon: <CLFlag /> },
        nl: { label: 'Nederlands', icon: <NLFlag /> },
        no: { label: 'Norsk', icon: <NOFlag /> },
        sv: { label: 'Svenska', icon: <SVFlag /> },
        se: { label: 'Swedish', icon: <SVFlag /> },
        da: { label: 'Dansk', icon: <DAFlag /> },
        fi: { label: 'Suomi', icon: <FIFlag /> },
        sg: { label: 'Deutsch', icon: <SGFlag /> },
        at: { label: 'Deutsch', icon: <ATFlag /> },
        dsg: { label: 'Swiss-German', icon: <SGFlag /> },
        dat: { label: 'Austrian-German', icon: <ATFlag /> },
        dk: { label: 'Denmark', icon: <DKFlag /> },
        ch: { label: 'Switzerland', icon: <CHFlag /> },
        ie: { label: 'Ireland', icon: <IEFlag /> },
        nz: { label: 'New Zealand', icon: <NZFlag /> },
    };

    const getCountryLanguages = (country) => {
        if (props.isForDev) {
            return ["en", "de", "es", "fr", "it", "vn", "cl", "nl", "no", "sv", "da", "fi", "dsg", "dat"];
        }

        switch (country) {
            case 'AT':
                return ['en', 'at'];
            case 'DK':
                return ['da', 'en'];
            case 'DE':
                return ['de', 'en'];
            case 'FI':
                return ['en', 'fi'];
            case 'IE':
                return ['en'];
            case 'NL':
                return ['nl', 'en'];
            case 'NZ':
                return ['en'];
            case 'AUS':
                return ['en'];
            case 'US':
                return ['en'];
            case 'CL':
                return ['cl', 'en'];
            case 'NO':
                return ['en', 'no'];
            case 'SE':
                return ['en', 'sv'];
            case 'CH':
                return ['en', 'fr', 'sg', 'it'];
            case 'IT':
                return ['it', 'en'];
            case 'FR':
                return ['fr', 'en'];
            case 'UK':
                return ['en'];
            case 'ES':
                return ['es', 'en'];
            case 'VN':
                return ['vn', 'en'];
            default:
                return ['en', 'de', 'es', 'fr', 'it', 'vn', 'cl', 'nl', 'no', 'sv', 'da', 'fi', 'sg', 'at'];
        }
    }

    const getFooterValue = (value) => {
        if (value === 'at') {
            return 'dat';
        }

        if (value === 'sg') {
            return 'dsg';
        }
        return value;
    }

    const getHeaderValue = (value) => {
        if (value === 'dat') {
            return 'at';
        }

        if (value === 'dsg') {
            return 'sg';
        }
        return value;
    }

    const getOptions = () => {
        const countries = getCountryLanguages(country);
        return countries.map(val => {

            if (language === val) return;

            return (
                <DropdownItem onClick={() => handleSelectLanguage(val)}>
                    <FlagToggle toggleProps={toggleProps[val]} />
                </DropdownItem>
            )
            return;
        })
    }

    const handleCheckBoxChange = (val) => {
        if (val?.target?.checked) {
            sessionStorage.setItem('preferredLangModal', false)
        } else {
            sessionStorage.setItem('preferredLangModal', true)
        }
    }

    const closeBtn = (
        <button className={styles.closeBtn} onClick={cancelSelectedLanguage} type="button">
            &times;
        </button>
    );

    const LanguageConfirmationModal = () => {
        return (
            <div>
                <Modal
                    isOpen={showLangModal}
                    aria-modal="true"
                    className="md"
                    aria-labelledBy="language selection"
                    role="dialog"
                >
                    <ModalHeader className="noLine pb-0 pr-4 pt-3" close={closeBtn}></ModalHeader>
                    <ModalBody>
                        <h4 className="mb-2" id="selectedLanguage">
                            {newLangTranslations?.preferredLanguageModalHeader || "Would you like to save your preferred language?"}
                        </h4>
                        <p>
                            {newLangTranslations?.preferredLanguageModalPara || "All communications sent to you regarding your claim will be in the selected language:"}
                        </p>
                        <p>
                            {toggleProps[preferredLang]?.label}
                        </p>
                        <Row>
                            <Col>
                                <div className="checkbox">
                                    <Input className="mr-2" type="checkbox" id="donotShow" onChange={handleCheckBoxChange} />
                                    <Label
                                        for="donotShow"
                                        className={`checkboxLabel ${isChileApple ? 'southbridge' : ''}`}
                                    >
                                        {newLangTranslations?.preferredLanguageCheckbox || "Don't show me this again."}
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <FooterButtonsRow
                            isModal
                            buttons={{
                                link: {
                                    text: newLangTranslations?.continueWithoutSaving || "Continue without Saving",
                                    onClick: () => saveSelectedLanguage(preferredLang, false),
                                    ariaLabel: "Click continue without saving to close modal and return to previous page",
                                },
                                primary: {
                                    hasSpinner: true,
                                    text: newLangTranslations?.savePreferredLanguage || "Save Preferred Language",
                                    type: "primary",
                                    onClick: () => saveSelectedLanguage(preferredLang),
                                    ariaLabel: "Continue",
                                },
                            }}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    const value = props.isForDev ? getFooterValue(language) : getHeaderValue(language);



    return (
        <>
            {(country.toLowerCase() === 'nz' ||
            country.toLowerCase() === 'ie' ||
            country.toLowerCase() === 'us' ||
            country.toLowerCase() === 'aus' ||
            country.toLowerCase() === 'uk') ?
            ""
            :
            <Dropdown isOpen={isLangDropOpen} toggle={() => setIsLangDropOpen(!isLangDropOpen)}>
                <DropdownToggle
                    className={styles.langDropToggle}
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={false}
                >
                    <FlagToggle toggleProps={toggleProps[value]} showIcon={true} icon={toggleProps[country.toLowerCase()]?.icon} />
                </DropdownToggle>
                <DropdownMenu className={styles.flagMenu}>
                    {getOptions()}
                </DropdownMenu>
                </Dropdown>
            }
            {showLangModal && <LanguageConfirmationModal />}
        </>
    )
}



export default LanguageToggle;