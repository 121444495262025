import React from 'react';
import ClientPhone from '../shared/ClientPhone';

export const brandFaqs = {
    hwa: [
        {
            title: 'default',
            faqs: [
                {
                    // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
                    question: 'Once I submit a claim, how long will it take for me to get an appointment?',
                    answer: 'The service provider that will be assigned to your request will be in contact with you shortly after you file your claim to schedule an appointment at your home. Most appointments will be scheduled during normal business hours. If you do not hear from the service provider within 24 hours, we\'ll provide you with a number so that you can contact them directly.',
                },
                {
                    question: 'When do I pay the trade call fee?',
                    answer: 'Depending on your contract, the trade call fee may be due when your service visit is scheduled or when a contractor visits your home. This will be stated clearly in your contract.',
                },
                {
                    question: 'How soon will a service technician be available to work on my claim?',
                    answer: 'HWA will dispatch service requests to an authorized contractor within 48 hours. You will then communicate directly with the contractor to arrange a mutually convenient appointment.',
                },
                {
                    question: 'What will happen when the service provider arrives at my home?',
                    answer: 'After the service provider arrives at your home, they will diagnose the problem, and if necessary, call the HWA Authorization Department to determine coverage and obtain authorization to proceed with the repair or replacement. Keep in mind, that for most repairs, the service provider will be able to complete the service request while at your home.',
                },
                {
                    question: 'How long will it take for equipment or parts to arrive?',
                    answer: 'If equipment or parts are necessary to complete the repair or replacement, HWA\'s Purchasing Department will order the equipment.',
                },
                {
                    question: 'What happens when the parts are delivered?',
                    answer: 'Once the equipment or parts are delivered, the service provider will be back in contact to schedule an appointment at your home to complete the repair or installation.',
                },
            ]
        }
    ],

    njr: [
        {
            title: 'About Service Plans',
            faqs: [
                {
                    // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
                    question: 'Why buy a Service Plan?',
                    answer: 'Over time, appliances may require service repairs or replacements. Once the manufacturer’s warranty expires, these could be unexpected and costly. A Service Plan will protect your covered essential systems and appliances when they get damaged due to normal wear and tear. With a Service Plan, if a covered incident occurs, we’ll repair or replace it.',
                },
                {
                    question: 'Once a Service Plan is purchased, how long will it take to receive the actual Service Plan contract?',
                    answer: 'If a valid email address is provided, we will send the Service Plan via email within five to seven business days (Monday-Friday) after purchase. If we do not have an email address, we will send the contract via United States Postal Service (USPS), within two to four weeks after purchase.',
                },
                {
                    question: 'What are the different payment options?',
                    answer: 'A Service Plan can either be paid monthly or yearly. If you choose monthly, payments will be automatically charged to the card provided every thirty (30) days. Yearly plans will automatically renew each year. Further details can be found in the terms and conditions.',
                },
                {
                    question: 'What payment methods can be used to purchase a plan?',
                    answer: 'We accept any Visa, MasterCard, Discover, or American Express in the form of a credit or debit card online and over the phone for U.S. purchases.',
                },
                {
                    question: 'Can a Service Plan be purchased if the product is used in a business?',
                    answer: 'Products used in a commercial setting are not eligible for coverage. See terms and conditions for complete details.',
                }
            ]
        },
        {
            title: 'Plan Options',
            faqs: [
                {
                    // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
                    question: 'What are the coverage options?',
                    answer: 'We currently offer three plan options – Kitchen Appliance Plan, Laundry Appliance Plan, and Total Appliance Plan (includes both Kitchen and Laundry Plan coverages).',
                },
                {
                    question: 'What is covered in the Kitchen Plan?',
                    answer: 'Coverage is included for: One cooking product (either a range, oven, or cooktop), dishwasher, kitchen exhaust fan, garbage disposal, built-in microwave, refrigerator (does not include ice maker), and interior electrical system.',
                },
                {
                    question: 'What is covered in the Laundry Plan?',
                    answer: 'Coverage is included for one washer and one dryer.',
                },
                {
                    question: 'Can I purchase the Kitchen and Laundry Plans at the same time?',
                    answer: 'Yes, you can purchase a Total Appliance Plan which includes both the Kitchen and Laundry Plan coverages!',
                },
                {
                    question: 'Can I add on any additional appliances or products?',
                    answer: 'Yes, for an additional cost, you can add on a secondary cooking product (either a range, oven, or cooktop), a secondary built-in microwave and/or a secondary refrigerator. Note: The refrigerator’s ice maker components are not covered under these plans.',
                },
                {
                    question: 'Can I cancel my Service Plan once I have purchased it?',
                    answer: 'Yes, you may cancel this Agreement at any time for any reason. You may cancel this Agreement via phone by calling 1-888-405-0602. If you cancel this Agreement within thirty (30) days from the Purchase Date without making a claim (“Free Look Period”), you will receive a refund of the full purchase.  If you cancel this Agreement post thirty (30) days from the Purchase Date and you pay annually, you will be refunded the remaining days of coverage on a monthly prorated basis, less costs for service performed and claims paid.  Monthly contracts will not generate a refund and will cancel your coverage after the current month’s coverage expires.',
                }
            ]
        },
        {
            title: 'Filing A Claim',
            faqs: [
                {
                    // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
                    question: 'When can a claim be filed?',
                    answer: 'There is a thirty (30) day WAIT PERIOD, from the date of the Service Plan purchase. Also, any pre-existing conditions or failures that occur prior to or during the wait period will not be covered. Products still covered under the manufacturer’s warranty period should be referred to the manufacturer for claim.',
                },
                {
                    question: 'How is a claim filed?',
                    answer: 'If a problem occurs, call toll-free 1-877-531-4872 and explain the issue.',
                },
                {
                    question: 'How is service scheduled?',
                    answer: 'Once the Service Plan has been activated, call 1-877-531-4872 to schedule service.',
                },
                {
                    question: 'Who will service the product?',
                    answer: 'Service will be scheduled at your convenience with an authorized technician in your area.',
                },
                {
                    question: 'If my appliance cannot be repaired, how will it be replaced?',
                    answer: "Our goal is to successfully resolve all valid claims. However, there are rare circumstances where the product is beyond repair. If this happens, under the terms of your Service Plan, we will either exchange your product with a replacement that has similar features and functionality or reimburse you. However, replacement and reimbursement will be based on the product's age and the remaining limit of liability. Per twelve (12) month period, we will cover up to $800 per appliance or system that is 10 years old or less in age at the time of the breakdown and $500 per appliance or system that is over 10 years old at the time of breakdown, not to exceed the aggregate limit stated on your Service Plan. Please see our complete terms and conditions for more details.",
                }
            ]
        }
    ],

    et: [
        {
            // title: 'default',
            faqs: [
                {
                    // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
                    question: 'Do I pay a service fee when I need a repair?',
                    answer: [
                        <>No! Your plan requires no service fee and has no deductibles! We pay for covered repairs up to the claim limit of your plan.</>
                    ]
                },
                {
                    question: 'How soon will a service technician be available to work on my claim?',
                    answer: [
                        <>If we are responsible for scheduling service under your Plan and in-home service is required, we will dispatch service requests to an authorized contractor within 48 hours.
                            You will then communicate directly with the contractor to arrange a mutually convenient appointment.
                        </>
                    ]
                },
                {
                    question: 'How long will it take for equipment or parts to arrive?',
                    answer: [
                        <>If equipment or parts are necessary to complete the repair or replacement, the equipment and/or parts may be ordered by either our Purchasing Department or your Service Provider.
                            How long it will take varies by availability and region. If there is a delay or shortage with your order, we will keep you posted along the way. Once the equipment or parts
                            are delivered, the designated service provider will be back in contact to schedule an appointment at your home to complete the repair or installation.
                        </>
                    ]
                },
                {
                    question: 'What do I do if I have any issues along the way?',
                    answer: [
                        <>Contact us to help you through the process and address your needs at <ClientPhone /> during the business hours of Monday through Friday 7am to 7pm CST and Saturday 8am to 4pm CST.</>
                    ]
                },
            ]
        }
    ],

    bestbuy: [
        {
            faqs: [
                {
                    question: 'Do I pay a service fee when I need a repair?',
                    answer: [
                        <>No! Your plan requires no service fee and has no deductibles! We pay for covered repairs up to the claim limit of your plan.</>
                    ]
                },
                {
                    question: 'How soon will a service technician be available to work on my claim?',
                    answer: [
                        <>If we are responsible for scheduling service under your Plan and in-home service is required, we will dispatch service requests to an authorized contractor within 48 hours.
                            You will then communicate directly with the contractor to arrange a mutually convenient appointment.
                        </>
                    ]
                },
                {
                    question: 'How long will it take for equipment or parts to arrive?',
                    answer: [
                        <>If equipment or parts are necessary to complete the repair or replacement, the equipment and/or parts may be ordered by either our Purchasing Department or your Service Provider.
                            How long it will take varies by availability and region. If there is a delay or shortage with your order, we will keep you posted along the way. Once the equipment or parts
                            are delivered, the designated service provider will be back in contact to schedule an appointment at your home to complete the repair or installation.
                        </>
                    ]
                },
                {
                    question: 'What do I do if I have any issues along the way?',
                    answer: [
                        <>Contact us to help you through the process and address your needs at <ClientPhone /> during the business hours of Monday through Friday 7am to 7pm CST and Saturday 8am to 4pm CST.</>
                    ]
                },
            ]
        }
    ],

    aig: [
        {
            title: '',
            faqs: [
                {
                    question: '',
                    answer: ''
                }
            ]
        }
    ]

    // ONLY FOR TESTING. YOU KNOW, HAVE SOME FUN! 😁🎈
    // aig: [
    //     {
    //         title: 'Lorems',
    //         faqs: [
    //             {
    //                 question: 'Bacon Ipsum?',
    //                 // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
    //                 answer: [
    //                     "Bacon ipsum dolor amet kielbasa t-bone tail kevin. Brisket do tempor ball tip jowl. Est tongue capicola enim eu pork chop burgdoggen. Flank dolor cow fugiat et corned beef ad sed kevin pastrami sirloin shoulder. In ground round buffalo, nulla spare ribs et commodo. Ut ham hock ut irure ipsum occaecat.",
    //                     "Bacon ipsum dolor amet andouille tenderloin ground round pork belly, shank swine kevin. Chuck turducken cow burgdoggen kevin pancetta corned beef landjaeger tenderloin tail. Rump venison shankle chicken. Meatball sirloin meatloaf, doner leberkas bresaola boudin cupim. Leberkas sausage pork loin prosciutto beef burgdoggen kevin. Chislic biltong pork belly short ribs."
    //                 ]
    //             },
    //             {
    //                 question: 'Cheese Ipsum?',
    //                 answer: "I love cheese, especially cheeseburger fondue. Jarlsberg cheesy feet jarlsberg goat manchego cheesy feet airedale babybel. Cream cheese boursin cheesecake cheese and wine cheesy feet pecorino cottage cheese croque monsieur. Manchego goat emmental fromage mascarpone croque monsieur mascarpone cheesecake. Cheesy feet."
    //             },
    //             {
    //                 question: 'Coffee Ipsum?',
    //                 answer: 'Dripper, macchiato, spoon caramelization doppio plunger pot beans cream. So, latte saucer, cup froth rich con panna latte con panna bar  extraction. Wings eu coffee, and filter foam seasonal aromatic. Grinder eu, dripper chicory, iced wings turkish sit seasonal. Carajillo iced seasonal turkish black eu redeye, frappuccino cup half and half wings strong.'
    //             },
    //             {
    //                 question: 'Pikachu Ipsum?',
    //                 answer: 'Pikachu ipsum dolor sit amet jolteon quis articuno seaking ivysaur, eevee labore. Anim fugiat shellder tempor aute dolore. Ut dolore hypno excepteur proident. Laboris do dragonite dolore eiusmod. '
    //             }
    //         ]
    //     },
    //     {
    //         title: 'Hedgehogs',
    //         faqs: [
    //             {
    //                 question: 'Are all hedgehogs fast?',
    //                 answer: 'Some might say supersonic',
    //             },
    //             {
    //                 question: 'Are there any blue hedgehogs?',
    //                 answer: 'While some hedgehogs are allegedly blue, many in the the larger hedgehog community dislikes the assumption that they should also be blue'
    //             },
    //             {
    //                 question: 'Do hedgehogs like hedges? And are they related to other hogs?',
    //                 answer: 'No.'
    //             }
    //         ]
    //     }
    // ],

}