import React from 'react';
import getClientContent from '../_BrandingProvider/brandContent.jsx'
import { Modal, ModalFooter, ModalBody, Row } from 'reactstrap';
import FooterButtonsRow from '../shared/FooterButtonsRow/FooterButtonsRow';

const WarrantyOfWorkModal = ({
    isOpen,
    onCloseModal,
    onContinue,
    clientId,
    deductible,
    progressContinue = false
}) => (
    isOpen && (

        <Modal isOpen={isOpen} aria-modal="true" className="md" aria-labelledBy="save" role="dialog" centered>
            <ModalBody>
                <div className='py-4'>
                    <h1 className={'h4'}>We're sorry you are experiencing this issue again.</h1>
                    <p className={'mb-0'}>
                        Since it is the same issue, the same servicer will be assigned to your new
                        claim.
                        {(deductible == null || deductible === 0) ?
                        ""
                        :
                        `You will not be required to pay another ${!!clientId ? getClientContent(clientId)?.variableText?.planDetails?.paymentType : 'service fee'}.`
                        }
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <FooterButtonsRow
                    isModal
                    buttons={{
                        primary: {
                            text: 'Continue',
                            type: 'primary',
                            onClick: onContinue,
                            ariaLabel: 'Continue',
                            hasSpinner: true,
                            isSpinning: progressContinue
                        },
                        link: {
                            text: 'Cancel',
                            onClick: onCloseModal,
                            ariaLabel: 'Click cancel to close modal and return to issue selection'
                        },
                    }}
                />
            </ModalFooter>
        </Modal>
    )
)

export default WarrantyOfWorkModal;
