import React, { useState, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import styles from './CustomerSurvey.module.css';
import ExpandableTextAreaWithLabel from "../../../shared/ExpandableTextarea/ExpandableTextAreaWithLabel";
import { ReactComponent as SmileEmoji } from '../../../../assets/images/Vendor/Apple/emoji-happy.svg';
import { ReactComponent as NeutralEmoji } from '../../../../assets/images/Vendor/Apple/emoji-neutral.svg';
import { ReactComponent as SadEmoji } from '../../../../assets/images/Vendor/Apple/emoji-sad.svg';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useHistory } from "react-router";


const cols = [
    {
        title: 'Not Satisfied',
        id: '1',
        emoji: <SadEmoji />
    },
    {
        title: 'Neutral',
        id: '2',
        emoji: <NeutralEmoji />
    },
    {
        title: 'Satisfied',
        id: '3',
        emoji: <SmileEmoji />
    }
];

//const rows = [
//    {
//        title: 'Ease of Filing a Claim',
//        id: 'ease'
//    },
//    {
//        title: 'Speed of Replacement',
//        id: 'speed'
//    },
//    {
//        title: 'Replacement Process',
//        id: 'process'
//    },
//    {
//        title: 'Replacement Device',
//        id: 'device'
//    }
//]

const CustomerSurvey = (props) => {
    const [responses, setResponses] = useState([]);
    const [description, setDescription] = useState("");
    const [recommendation, setRecommendation] = useState(null);
    const [surveyData, setSurveyData] = useState("");
    const [rows, setRows] = useState([]);
    const [surveyQuestionMappingModel, setSurveyQuestionMappingModel] = useState("");
    const [warrantyObjectMappingId, setWarrantyObjectMappingId] = useState(0);
    const history = useHistory();

    useEffect(() => {
        //console.log({ response })
        try {
            fetch(`api/survey/GetSurveyByWarrantyObject/${props?.match?.params?.claimId}/2/apple`)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    setSurveyData(data);
                    if (data?.surveyQuestionMappingModel?.length > 0)
                        setSurveyQuestionMappingModel(data?.surveyQuestionMappingModel);
                    if (data?.surveyQuestionMappingModel?.length > 0 && data?.surveyQuestionMappingModel[0]?.questionModel?.questionAreaMappingModel?.length > 0) {
                        const filteredOptions = data?.surveyQuestionMappingModel[0]?.questionModel?.questionAreaMappingModel.map(e => {
                            return { id: e.areaModel?.areaId, title: e.areaModel?.areaText }
                        });
                        console.log(filteredOptions);
                        setRows(filteredOptions);
                        const filterSurveyWarrantyObject = data?.warrantyObjectMappingModel && data?.warrantyObjectMappingModel.length > 0 ?
                            data?.warrantyObjectMappingModel.filter(e => e.warrantyObjectId == props?.match?.params?.claimId) : 0;
                        const filterSurveyWarrantyObjectId = filterSurveyWarrantyObject.length > 0 ?
                            filterSurveyWarrantyObject[(filterSurveyWarrantyObject.length - 1)].warrantyObjectMappingId : 0;

                        setWarrantyObjectMappingId(filterSurveyWarrantyObjectId);
                        //setWarrantyObjectMappingId(data?.warrantyObjectMappingModel && data?.warrantyObjectMappingModel.length > 0 ?
                        //    data?.warrantyObjectMappingModel[0].warrantyObjectMappingId : 0);

                        //surveyData?.surveyQuestionMappingModel[0]?.questionModel?.questionAreaMappingModel.mapping();
                        //setSurveyQuestionMappingModel(surveyData?.surveyQuestionMappingModel);
                    }
                });
        }
        catch (err) {
        }
    }, []);

    const customerResponse = (e) => {
        const { value } = e.target;
        const [rowId, colId] = value.split('_')
        const newResponses = responses.filter(res => !res.includes(`${rowId}_`));
        setResponses([...newResponses, value]);
    }

    const saveResponse = async () => {
        try {
            if (warrantyObjectMappingId > 0) {
                let responseData = {
                    Answers: responses,
                    Recommendation: recommendation,
                    Description: description ? description : "",
                    WarrantyObjectMappingId: warrantyObjectMappingId
                };
                let responseDataJSON = JSON.stringify(responseData);
                console.log(responseDataJSON);
                const result = await fetch(`/api/survey/SaveResponse/${props?.match?.params?.claimId}/apple`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: responseDataJSON
                });
                history.push('/vendor/apple/confirmation', { statusType: 'customerSurvey' });
            }
        }
        catch (err) {
        }
    }

    return (
        <>
            <div className={styles.pageHeader}>
                <Container className={styles.pageHeaderContent}>
                    <h1>AppleCare+ with Theft and Loss Survey</h1>
                    <p>Please rate your experience by answering the questions below.</p>
                </Container>
            </div>
            <Container className={styles.formContainer}>
                <Col lg="9" className="p-0">
                    <h2 className={styles.question}>
                        {surveyData?.surveyQuestionMappingModel && surveyData?.surveyQuestionMappingModel?.length > 0
                            ? surveyData?.surveyQuestionMappingModel[0]?.questionModel?.questionText : ""}
                    </h2>
                    <div>
                        <Row className="m-0">
                            <Col md="3"></Col>
                            {cols.map((col) => (
                                <Col className={styles.ratings}>
                                    <span className={styles.emoji}>{col.emoji}</span>
                                    <p>{col.title}</p>
                                </Col>
                            ))}
                        </Row>
                        {rows.map((row, rowIdx) => {
                            return (
                                <Row className="m-0">
                                    <Col
                                        md="3"
                                        className={`${styles.radioBtn} justify-content-start p-0 align-items-center d-flex`}
                                    >
                                        <p className="mb-0">{row.title}</p>

                                    </Col>
                                    {cols.map((col, colIdx) => {
                                        const uniqueRadioId = `${rows[rowIdx].id}_${cols[colIdx].id}`
                                        return (
                                            <Col
                                                className={`${styles.radioBtn} ${styles.borderTopBottom
                                                    } ${colIdx === 0 ? styles.borderLeft : ""} ${colIdx === cols.length - 1 ? styles.borderRight : ""
                                                    }`}
                                            >
                                                <div className="justify-content-center radio mt-0">
                                                    <input
                                                        type="radio"
                                                        value={uniqueRadioId}
                                                        id={uniqueRadioId}
                                                        onClick={customerResponse}
                                                        checked={responses.includes(uniqueRadioId)}
                                                    />
                                                    <label
                                                        className="controls-label"
                                                        htmlFor={uniqueRadioId}
                                                    />
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            );
                        })}
                    </div>
                </Col>
                <Col lg="9" className={styles.recommend}>
                    <label className={styles.question}>
                        {surveyData?.surveyQuestionMappingModel && surveyData?.surveyQuestionMappingModel?.length > 0
                            ? surveyData?.surveyQuestionMappingModel[1]?.questionModel?.questionText : ""}
                        {/*Based on your experience, how likely are you to recommend*/}
                        {/*AppleCare+ with Theft and Loss*/}
                        {/*<br className="show-lg-up" /> to your family or friends?*/}
                    </label>
                    <Row role="radiogroup" className="symptomGroup m-0">
                        <div className={classNames("radioButton", styles.recommendBtn)}>
                            <input
                                type="radio"
                                name="recommendation"
                                id="noRecon"
                                value="Not Recommend"
                                checked={recommendation === "Not Recommend"}
                                onClick={() => setRecommendation("Not Recommend")}
                            />
                            <label className="radio-controls-label" for="noRecon">
                                Not Recommend
                            </label>
                        </div>
                        <div className={classNames("radioButton", styles.recommendBtn)}>
                            <input
                                type="radio"
                                name="recommendation"
                                id="recommend"
                                value="Recommend"
                                checked={recommendation === "Recommend"}
                                onClick={() => setRecommendation("Recommend")}
                            />
                            <label className="radio-controls-label" for="recommend">
                                Recommend
                            </label>
                        </div>
                        <div className={classNames("radioButton", styles.recommendBtn)}>
                            <input
                                type="radio"
                                name="recommendation"
                                id="stronglyRecon"
                                value="Strongly Recommend"
                                checked={recommendation === "Strongly Recommend"}
                                onClick={() => setRecommendation("Strongly Recommend")}
                            />
                            <label className="radio-controls-label" for="stronglyRecon">
                                Strongly Recommend
                            </label>
                        </div>
                    </Row>
                </Col>
                <Col lg="9" className={styles.description}>
                    <ExpandableTextAreaWithLabel
                        maxLength={1000}
                        label={surveyData?.surveyQuestionMappingModel && surveyData?.surveyQuestionMappingModel?.length > 0
                            ? surveyData?.surveyQuestionMappingModel[2]?.questionModel?.questionText : ""}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                    />
                </Col>
                <button className="btn-primary mt-5 mb-4" onClick={saveResponse} disabled={!recommendation || responses.length < 4}>Submit</button>
            </Container>
        </>
    );
}

export default CustomerSurvey