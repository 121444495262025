import React, { Component } from 'react';
import Select from 'react-select';

export class DropDownAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValueText: '',
            defaultValue: props.defaultValue,
            optionsList: [],
        };
        this.handleStateChange = this.handleStateChange.bind(this);
    }
    configureOptionsList = (optionsObj) => {
        let newList = []
        if (optionsObj && optionsObj.length > 0) {
            newList = optionsObj.map(obj => ({ ...obj, label: obj.display, value: obj.data }));
        }
        return newList;
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.Value !== this.state.defaultValue) {
            this.setState({
                defaultValue: nextProps.Value,
                optionsList: this.configureOptionsList(this.props.options)
            })
        }
    }


    handleStateChange = (selectedValueText, eve) => {
        this.setState({ selectedValueText });
        this.props.onChangedValue(selectedValueText.value, eve.name, selectedValueText.label);
    }
    componentDidMount() {
        this.setState({
            defaultValue: this.props.Value,
            optionsList: this.configureOptionsList(this.props.options)
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.Value && this.props.Value != prevProps.Value) {
            this.setState({
                defaultValue: this.props.Value,
            });
        }
        if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
            this.setState({
                defaultValue: this.props.Value,
                optionsList: this.configureOptionsList(this.props.options)
            })
        }
    }

    filterOption = (option, inputValue) => {
        return option.label?.toLowerCase().startsWith(inputValue?.toLowerCase());
    }

    render() {
        const {
            id, placeholder, name, isDisabled
        } = this.props
        return (
            <React.Fragment>
                <Select
                    id={id || "billingState"}
                    value={this.state.optionsList.filter(o => o.data === this.state.defaultValue)[0] || null}
                    defaultValue={this.state.optionsList.filter(o => o.data === this.state.defaultValue)[0] || null}
                    label="Single select"
                    classNamePrefix='autoDrop'
                    placeholder={placeholder || "Please select..."}
                    onChange={this.handleStateChange}
                    options={this.state.optionsList}
                    filterOption={this.filterOption}
                    name={name}
                    isDisabled={isDisabled}
                    aria-label={id || "billingState"}
                    aria-labelledby={id || "billingState"}
                />
            </React.Fragment>
        );
    }
}