import React, { Component, useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import styles from './MyClaimsTable.module.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { AIGCircleBadge } from "../shared/StatusBadge/CircularStatusBadge";
import moment from 'moment';
import { resumeClaim } from '../../helpers'

let propsGlobal = null;
let client = "";
let contract = null;

const myClaimsColumns = [
  {
    dataField: 'claimNumber',
    text: 'Claim Number',
    classes: 'btnLink',
    headerFormatter: headerFormatter,
    formatter: (claimNum, col) => claimNumberLinkFormatter(claimNum, col),
    sort: 'true',
    sortFunc: (a, b, order, dataField, rowA, rowB) => {
      if (order === 'desc') {
        return b - a;
      }
      return a - b;
    }
  },
  {
    dataField: 'assetName',
    text: 'Covered Item',
    headerFormatter: headerFormatter,
    sort:'true',
    style: { minWidth: '225px' },
  },
  {
    dataField: 'claimStatusName',
    text: 'Claim Status',
    headerFormatter: headerFormatter,
    formatter: StatusFormatter,
    sort:'true',
    sortFunc: (a1, b1, order, dataField, rowA, rowB ) => {
      const orderValue = {
        saved: 1, serviceInProgress: 2, serviceCompleted: 3
      };
      if(order === "asc"){
        // console.log(orderValue[rowA.claimStatus] - orderValue[rowB.claimStatus], "ASC");
        return orderValue[rowA.claimStatus] - orderValue[rowB.claimStatus];  
      }
      else {
        // console.log(orderValue[rowB.claimStatus] - orderValue[rowA.claimStatus], "DESC");
        return orderValue[rowB.claimStatus] - orderValue[rowA.claimStatus];
      }
    }
  },
  {
    dataField: 'claimSetupDate',
    text: "Date Opened",
    headerFormatter: headerFormatter,
    sort:'true'
  },
  {
    dataField: 'claimFileCompletionDate',
    text: 'Date Completed',
    headerFormatter: headerFormatter,
    sort:'true'
  },
  {
    dataField: 'actions',
    text: '',
    isDummyColumn: true,
    classes:styles.myClaimActions,
    headerFormatter: headerFormatter,
    formatter: actionsFormatter,
  },
];

const claimNumberLinkFormatter = (claimNum, claim) => {
  return <a onClick={() => handleClaimNumberClick(claim)}>{claimNum}</a>
}

const handleClaimNumberClick = (claim) => {
  if (claim.claimStatusName == "Saved") {
    resumeClaimPage(claim)
  } else {
    openClaimOverview(claim)
  }
}

function headerFormatter(column, index, { sortElement }) {
  const { order } = sortElement?.props || {};
  return (
      <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
          {column.text}
      </span>
  );
}

function StatusFormatter(row, col, claimStatus, statusTypes, cell) {
    if (col.claimStatusName == "Closed with amount" || col.claimStatusName == "Closed without payment") {
        return (<AIGCircleBadge status="Closed" />)
    }
    else if (col.claimStatusName == "Cancelled" || col.claimStatusName == "Canceled") {
        return (<AIGCircleBadge status="Canceled" />)
    }
    else if (col.claimStatusName == "Open" || col.claimStatusName == "In Review") {
        return (<AIGCircleBadge status="In Progress" />)
    }
    else if (col.claimStatusName == "Saved") {
        return (<AIGCircleBadge status="Saved" />)
    }
    else {
        return (<AIGCircleBadge status="In Progress" />)
    }
}

function openClaimOverview(col) {
    let pathName = '/DetailsOverview';
    console.log(col);
    if (typeof propsGlobal?.props.history?.search != 'undefined') {
        pathName += `?id=${propsGlobal?.props.history?.search}`;
    }

    propsGlobal.props.history.push({
        pathname: pathName,
        claim: col,
        clientId: client
    });
}

function resumeClaimPage(col) {
    let historyData = resumeClaim(col, client, propsGlobal);
    propsGlobal.props.history.push(historyData);
}

//function resumeClaim(col) {
///* TODO */

//    // Add spinner, pull claim information and open claim in saved step
//    console.log(col, propsGlobal)
//    let savedStep = col.savedStep;
//    let assetId = col.contractAssetId;
//    let claimId = col.claimId;
//    let contractId = col.contractId;
//    let coverageId = col.coverageId;
//    let pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
//    let history = { pathname: pathName };

//    let entitlementDetails = {
//        ammount: null,
//        clientId: client,
//        claimNumber: col.claimNumber,
//        caseNumber: null,
//        deductible: col.tcf,
//        caseNumber: null,
//        party: col.partyModel,
//        address1: col.partyModel.serviceAddress?.address1,
//        address2: col.partyModel.serviceAddress?.address2,
//        city: col.partyModel.serviceAddress?.city,
//        state: col.partyModel.serviceAddress?.state,
//        stateId: col.partyModel.serviceAddress?.stateId,
//        zip: col.partyModel.serviceAddress?.zip,
//        contractAsset: col.contract?.contractAssetModel?.find(ca => ca.contractAssetId == assetId),
//    }

//    switch (savedStep) {
//        case "1":
//            // Entitlement page, need to figure out which symptom and part of the process
//            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
//            history = {
//                pathname: pathName,
//                claim: col,
//                clientId: client,
//                stepsCounter: col.stepsCounter
//            };
//            break;
//        case "3":
//            // Payment page
//            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
//            history = {
//                pathname: pathName,
//                claim: col,
//                clientId: client,
//                stepsCounter: col.stepsCounter,
//                isPaymentActive: true,
//                // Add entitlement details to state
//                state: { entitlementDetails: entitlementDetails}
//            };
//            break;
//        case "2":
//            // Verify info
//            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
//            history = {
//                pathname: pathName,
//                claim: col,
//                clientId: client
//            };
//            break;
//        case "4":
//            // Upload docs, not supported
//            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
//            history = {
//                pathname: pathName,
//                claim: col,
//                clientId: client
//            };
//            break;
//        case "5":
//            // Appointment
//            pathName = '/appointment';
//            history = {
//                pathname: pathName,
//                claim: col,
//                clientId: client
//            };
//            break;
//        default:
//            // send to ClaimOverview
//            pathName = '/DetailsOverview';
//            if (typeof propsGlobal?.props.history?.search != 'undefined') {
//                pathName += `?id=${propsGlobal?.props.history?.search}`;
//            }

//            history = {
//                pathname: pathName,
//                claim: col,
//                clientId: client };
//            break;
//    }

//    propsGlobal.props.history.push(history);
//}


//function is used to set the action button based on the claim status.
function actionsFormatter(row, col, claimStatus, statusTypes, cell){
    if (col.claimStatusName == "Saved") {
      // Resume claim
      let isResumeDisabled = col?.contract?.statusId == 4;
      return <button className="btn-secondary" value="Resume" disabled={isResumeDisabled} onClick={() => { resumeClaimPage(col) }}>Resume</button>
  }
    else {
        // Open Claim Overview in DetailsOverview.jsx
        return <button className="btn-secondary" value="View" onClick={() => { openClaimOverview(col) }}>View</button>
  }
}

const defaultSortedBy = [{
   dataField: "claimSetupDate",
   order: "desc",
}];

export const MyClaimsTable = (props) => {
    const [claims, setClaimsData] = useState([]);
    const [clientId, setClientIdId] = useState(props.contract?.riskProfile?.masterProgramName !== "" ? props.contract?.riskProfile?.masterProgramName:"arw");
    propsGlobal = props.props;
    contract = props.contract;
    const contractNumber = props.contract?.contractNumber;
    client = clientId;
    console.log(props, propsGlobal);

    useEffect(() => {
        getClaimsbyContractNumber().then(claimsData => {
            setClaimsData(claimsData);
        });
        
    }, []);

    const getClaimsbyContractNumber = () => {
        return new Promise((resolve, reject) => {
            fetch(`api/claim/GetClaimsByContractNumber/${contractNumber}/${clientId}`)
                .then(res => res.json())
                .then(data => {
                    console.log('claims data', data)
                    resolve(data?.filter(d => d.claimStatusName !== "Partial" && d.eventStatusId !== 5));
                })
        });
    }
   
    const claimList = claims?.map(c => {
        return {
            claimNumber: (c.historicalPortfolioClaimsFlag == 1) ? c.clientClaimNumber : c.claimNumber,
            assetName: c.assetName,
            claimStatusName: c.eventStatusId == 5 ? "Canceled" : c.claimStatusName,
            claimSetupDate: c.claimSetupDate ? moment(c.claimSetupDate).format('MM/DD/YYYY'):'',
            claimFileCompletionDate: c.claimFileCompletionDate ? moment(c.claimFileCompletionDate).format('MM/DD/YYYY') : '',
            claimId: c.claimId,
            clientId: clientId,
            brand: c.brand,
            assetDescription: c.assetDescription,
            model: c.modelNumber,
            serial: c.serialNumber,
            modelNumber: c.modelNumber,
            serialNumber: c.serialNumber,
            purchaseDate: c.assetPurchaseDate,
            symptom: c.symptom,
            incidentDate: c.lossDate,
            createdBy: 'SaveClaim',
            incidentDescription: c.incidentDescription,
            tcf: c.deductible,
            creditCardNumber: c.creditCardNumber,
            creditCardType: c.creditCardType,
            servicerName: c.servicerName,
            servicerPhone: c.servicerPhone,
            scheduledAppointment: c.scheduledAppointment,
            appointmentDate: c.appointmentDate,
            appointmentTime: c.appointmentTime,
            eventType: c.eventType,
            eventStatusId: c.eventStatusId,
            accepted: c.accepted,
            deliveryId: c.deliveryId,
            partyModel: c.partyModel,
            claimSetupDateOverview: c.claimSetupDate,
            authorizedAmount: c.authorizedAmount,
            savedStep: c.savedStep,
            stepsCounter: c.stepsCounter,
            assetId: c.assetId,
            contractAssetId: c.contractAssetId,
            coverageId: c.coverageId,
            contractId: c.contractId,
            tcf: c.tcfAmount,
            contract: props?.contract,
            result: c.featureClaimModel,
            lastQuestionAnswered: c.lastQuestionAnswered,
            servicerOptionSelected: c.servicerOptionSelected,
            invoicesCount: c.invoicesCount,
            historicalPortfolioClaimsFlag: c.historicalPortfolioClaimsFlag,
            clientClaimNumber: c.clientClaimNumber,
            eventNumberToResume: c.eventNumberToResume
        }
    });
  const options = {
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };
    return (
    <div>
      <BootstrapTable      
        keyField={'claimNumber'}
        columns={myClaimsColumns}
        data={claimList || []}
        bordered={false}
        wrapperClasses="table-responsive"
        classes={styles.myClaimsTable}
        pagination={paginationFactory(options)}
        defaultSorted={defaultSortedBy}
      />
    </div>
  );
}