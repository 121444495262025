export const countryConstants = {
    US: {
        currency: {
            symbol: '$',
            abbreviation: 'USD',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'MM/DD/YYYY',
            momentJsLongFormat: 'h:MM a, MMMM Do, YYYY', 
            momentJsLongFormatWithTimezone: 'hh:MM a z, MMMM DD, YYYY',
            momentJsLocale: 'us',           
            delimiter: '/',
            cleavePattern: ['m', 'd', 'Y']
        },
        phone: {
            countryCode: '+1',
            maxInputLength: null,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },

    // GERMANY (DE-utschland)
    DE: {
        currency: {
            symbol: '€',
            abbreviation: 'EUR',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD.MM.YYYY',
            momentJsLongFormat: 'HH:MM, DD. MMMM YYYY',
            momentJsLongFormatWithTimeZone: 'DD. MMMM YYYY, HH:MM z',
            momentJsLocale: 'de',           
            delimiter: '.',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+49',
            maxInputLength: 14,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },

    // SPAIN (ES-paña)
    ES: {
        currency: {
            symbol: '€',
            abbreviation: 'EUR',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'HH:MM, DD MMMM YYYY',
            momentJsLongFormatWithTimeZone: 'DD MMMM YYYY, HH:MM z',
            momentJsLocale: 'es',           
            delimiter: '-',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+34',
            maxInputLength: 12,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },

    // FRANCE
    FR: {
        currency: {
            symbol: '€',
            abbreviation: 'EUR',
            thousandsDelimiter: ' ',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'HH:MM, DD MMMM YYYY',
            momentJsLongFormatWithTimeZone: 'DD MMMM YYYY, HH:MM z',
            momentJsLocale: 'fr',           
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+33',
            maxInputLength: 14,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },

    // ITALY
    IT: {
        currency: {
            symbol: '€',
            abbreviation: 'EUR',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD.MM.YYYY',
            momentJsLongFormat: 'HH:MM, DD. MMMM YYYY',
            momentJsLongFormatWithTimeZone: 'DD. MMMM YYYY, HH:MM z',
            momentJsLocale: 'it',           
            delimiter: '.',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+39',
            maxInputLength: 14,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },

    // UNITED KINGDOM/GREAT BRITAIN
    UK: {
        currency: {
            symbol: '£',
            abbreviation: 'GBP',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',            
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'gb',           
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+44',
            maxInputLength: 14,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },

    // AUTRALIA
    AUS: {
        currency: {
            symbol: 'A$',
            abbreviation: 'AUD',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',            
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'au',           
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+61',
            maxInputLength: 12,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    // VIETNAM
    VN: {
        currency: {
            symbol: '₫',
            abbreviation: 'VND',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'vn',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+84',
            maxInputLength: 12,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    CL: {
        currency: {
            symbol: '$',
            abbreviation: 'CLP',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'cl',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+56',
            maxInputLength: 9,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    NZ: {
        currency: {
            symbol: '$',
            abbreviation: 'NZD',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'nz',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+64',
            maxInputLength: 14,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    AT: {
        currency: {
            symbol: ' €',
            abbreviation: 'EUR',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'at',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+43',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    DK: {
        currency: {
            symbol: ' kr.',
            abbreviation: 'DKK',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'dk',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+45',
            maxInputLength: 20,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    FI: {
        currency: {
            symbol: ' €',
            abbreviation: 'EUR',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'fi',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+358',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    IE: {
        currency: {
            symbol: '€',
            abbreviation: 'EUR',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'ie',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+353',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    NL: {
        currency: {
            symbol: ' €',
            abbreviation: 'EUR',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'nl',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+31',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    NO: {
        currency: {
            symbol: ' kr',
            abbreviation: 'NOK',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'no',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+47',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    CH: {
        currency: {
            symbol: ' CHF',
            abbreviation: 'CHF',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'ch',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+41',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
    SE: {
        currency: {
            symbol: ' kr',
            abbreviation: 'SEK',
            thousandsDelimiter: '.',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'h:MM a, DD MMMM YYYY',
            momentJsLongFormatWithTimezone: 'hh:MM a z, DD MMMM YYYY',
            momentJsLocale: 'se',
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+46',
            maxInputLength: 15,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
}

export const englishVariant = {
    american: {
        // USE CAPITALIZED VERSIONS HERE. GOING "ToLowerCase" IS EASIER THAN CAPITALIZING JUST CERTAIN LETTERS IF NEEDED
        center: 'Center',
        deductibleName: 'Deductible',
        confirmScreen: 'You will receive a replacement device within 1 to 3 business days.',
        proofOfIdTitle: 'Government ID',
        proofOfIdDescription:'Official identification forms issued by the Government of the United States that validates your name, address, and residency status, along with a picture'        
    },
    british: {
        // USE CAPITALIZED VERSIONS HERE. GOING "ToLowerCase" IS EASIER THAN CAPITALIZING JUST CERTAIN LETTERS IF NEEDED
        center: 'Centre',
        deductibleName: 'Excess Fee',
        confirmScreen: '',
        proofOfIdTitle: 'Proof of ID',
        proofOfIdDescription: 'Driving Licence or Passport',
    },
}

export const COUNTRY_NAMES = {
    us: 'United States',
    uk: 'United Kingdom',
    gb: 'United Kingdom',
    es: 'Spain',
    it: 'Italy',
    fr: 'France',
    de: 'Germany',
    au: 'Australia',
    aus: 'Australia',
    vn: 'Vietnam',
    cl: 'Chile',
    at: 'Austria',
    dk: 'Denmark',
    fi: 'Finland',
    ie: 'Ireland',
    nl: 'Netherlands',
    nz: 'New Zealand',
    no: 'Norway',
    se: 'Sweden',
    ch: 'Switzerland'
}