import React from 'react';
import { useContext } from 'react';
import { adobeDataLayerPush } from '../../helpers';
import { CombinedLangContractContext } from '../contexts/ProviderLangContractContext';

export function useAdobeAnalytics() {
    const context = useContext(CombinedLangContractContext);
    const { languageContext } = context || {};

    const callAdobeAnalyticsData = ({ pageName = '', pagePath = '', caseId, claimStatus }) => {
        const siteData = {
            name: 'Warranty Claims Portal',
            country: languageContext?.country,
            language: languageContext?.language,
            currency: languageContext?.countryVars?.currency?.abbreviation
        }

        const pageData = {
            name: pageName,
            path: pagePath,
            url: window.location.origin
        }

        const caseData = {
            caseId: caseId || window.digitalData?.case?.caseId || '',
            claimType: window.digitalData?.case?.claimType || '',
            claimStatus: claimStatus || window.digitalData?.case?.claimStatus || ''
        }

        const eConsentData = {
            retailer: ''
        }
        adobeDataLayerPush(siteData, pageData, caseData, eConsentData)
    }

    return {
        callAdobeAnalyticsData
    }

}