import React from 'react';
import styles from './PageLoading.module.css';
import classNames from 'classnames';

const PageLoading = ({
    text = "Loading...",
    height = 600,
    title,
    color = "primary"         // Primary|Secondary
}) => {
    return (
        <div className={styles.pageLoading} style={{ height: `${height}px` }}>
            <div className={classNames(styles[color], `${styles.spinnerBorder} mb-2`)} role="status"></div>
            {title && (
                <h4>{title}</h4>
            )}
            <p>
                {text}
            </p>
        </div>
    )
}

export default PageLoading; 