import React, { Component } from 'react';
//import '../../src/css/hero.css';
import { ZipInput } from '../../components/inputs/ZipInput';
import { DropDownInput } from '../../components/inputs/DropDownInput';
import { Row, Col, FormGroup, Input, Label, Form } from 'reactstrap';
import FocusLock from 'react-focus-lock';
import Cleave from 'cleave.js/react';
import styles from './PlanLookup.module.css'
import PhoneNumberInput from '../../components/shared/PhoneNumberUtility/PhoneNumberInput'
const validEmailRegex =
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default class LastNameSearch extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            lastNameSearchOptions: [],
            loading: true,
            searchOption: '',
            errors: {
                email: '',
                phone: '',
                lastName: '',
                zip: '',
                searchOption: ''
            },
            searchValues: {
                lastName: '',
                zip: '',
                email: '',
                phone: ''
            }
        });
        this.handleLastNameSearchChange = this.handleLastNameSearchChange.bind(this);
        this.sendToParent = this.sendToParent.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
    }

    componentDidMount() {
        this.getLastNameSearchOptions();
        this.props.searchClick(this.checkErrors);

        if (this.props.params != null)
            this.setState({ searchValues: this.props.params, searchOption: this.props.params.searchOption });
    }

    handleLastNameSearchChange(e) {
        let errors = this.state.errors;

        errors.searchOption = e.length === 0 ? 'Please select a search method' : '';

        this.setState({
            errors, searchOption: e, searchValues: {
                ...this.state.searchValues,
                searchOption: e
            }
        });
    }

    checkErrors() {
        return new Promise((resolve, reject) => {
            let errors = this.state.errors;
            errors.lastName = this.state.searchValues.lastName.length === 0 ? 'Last name is required.' : '';
            errors.zip = this.state.searchValues.zip.length === 0 ? 'Zip is required.' : '';
            switch (this.state.searchOption.toLowerCase()) {
                case "email": {
                    errors.email = this.state.searchValues.email.length === 0 ? 'Email is required.' : '';
                    break;
                }
                case "phone": {
                    errors.phone = this.state.searchValues.phone.length === 0 ? 'Phone is required.' : '';
                    break;
                }
            }
            this.checkSearchOption();
            this.setState({ errors: errors }, () => {
                this.props.errorsCallback(this.state.errors);
                resolve();
            });
        });
    }

    checkSearchOption() {
        let errors = this.state.errors;
        errors.searchOption = this.state.searchOption.length === 0 ? 'Please select a search method' : '';
        this.setState({ errors: errors });
    }

    handleInputChange(e) {
        e.preventDefault();
        const { name, value } = e.target;
        let errors = this.state.errors;

        switch (name) {
            case "email": {
                errors.email = validEmailRegex.test(value)
                    ? '' : 'Please enter a valid email address.';
                errors.email = value.length === 0 ? 'Email address is required.' : '';
                break;
            }
            case "phone": {
                errors.phone = value.length === 0 ? 'Phone number is required' : '';
                break;
            }
            case "lastName": {
                errors.lastName = value.length === 0 ? 'Last name is required' : ''
                break;
            }
            case "zip": {
                errors.zip = value.length === 0 ? 'Zip/postal code is required' : ''
                break;
            }
        }

        //this.checkSearchOption();

        this.setState({
            errors,
            searchValues: {
                ...this.state.searchValues,
                [name]: value
            }
        }, () => this.props.errorsCallback(this.state.errors));
    }

    sendToParent = () => {
        this.props.paramsCallback(this.state.searchValues);
    }

    async getLastNameSearchOptions() {
        const res = await fetch('api/PlanSearch/LastNameSearchOptions');
        const data = await res.json();
        this.setState({ lastNameSearchOptions: data, loading: false });
    }

    render() {
        let errors = this.state.errors;
        let lmo = this.state.lastNameSearchOptions.map(ln => {
            return {
                data: ln,
                display: ln
            }
        });

        let lastNameOptions = this.state.loading
            ? <p>Loading options...</p>
            : <DropDownInput tabIndex="0" name="lastNameSearchOptions"
                options={lmo}
                className="form-control"
                onChangedValue={this.handleLastNameSearchChange}
                onBlur={this.handleLastNameSearchChange}
                isFloatingEnabled='true'
                placeholder='Please select..'
                defaultValue={this.props.params != undefined ? this.props.params.searchOption : undefined}
            />;

        let phoneField =
            <FormGroup className={styles.phoneGroup}>
                <PhoneNumberInput
                    fieldName='phone'
                    value={this.state.searchValues.phone}
                    onChange={this.handleInputChange}
                    required={false}
                    validationFired={errors}
                    error={errors.phone.length > 0 &&
                        <div className="error-msg" aria-live="polite">{errors.phone}</div>}
                />
            </FormGroup>;

        let emailField =
            <FormGroup className="has-float-label">
                <Input type="email" id="email"
                    className="form-control"
                    placeholder="Email"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    value={this.state.searchValues.email}
                    name="email" onChange={this.handleInputChange}
                />
                <Label htmlFor="email">Email</Label>
                {errors.email.length > 0 && <div className="error-msg" aria-live="polite">{errors.email}</div>}
            </FormGroup>;

        // send entered values back to parent
        this.sendToParent();

        return (
            <>

                <Row>
                    <Col sm="7" className="pr-11">

                        <FormGroup className="has-float-label">
                            <Input type="text" id="lastName" autoFocus
                                className="form-control"
                                aria-label="Default"
                                tabIndex="0"
                                aria-describedby="inputGroup-sizing-default"
                                value={this.state.searchValues.lastName}
                                placeholder="Last Name"
                                name="lastName" onChange={this.handleInputChange}
                            />
                            <Label htmlFor="lastName">Last Name</Label>
                            {errors.lastName.length > 0 && <div className="error-msg" aria-live="polite">{errors.lastName}</div>}
                        </FormGroup>

                    </Col>
                    <Col sm="5" className="pl-11">
                        <FormGroup className="zipCodeBox has-float-label">
                            <ZipInput name="zip" value={this.state.searchValues.zip} onChangedValue={this.handleInputChange} />
                            {errors.zip.length > 0 && <div className="error-msg" aria-live="polite">{errors.zip}</div>}
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <FormGroup className="has-float-label">
                            {lastNameOptions}
                            {errors.searchOption.length > 0 && <div className="error-msg" aria-live="polite">{errors.searchOption}</div>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {this.state.searchOption === 'Email' ? emailField : null}

                        {this.state.searchOption === 'Phone' ? phoneField : null}
                    </Col>
                </Row>

            </>
        );
    }
}