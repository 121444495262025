import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import classNames from "classnames";
import styles from "./RecommendedAddressModal.module.css";
import FooterButtonsRow from "../shared/FooterButtonsRow/FooterButtonsRow";

let radioBoxWrapper = styles.radioBoxWrapper;
let noGutters = "no-gutters";

const RecommendedAddressModal = (props) => {
  const { enteredAddress, suggestedAddress, hideModal } = props;

  const [selectedServiceAddress, setSelectedServiceAddress] =
    useState(`suggestedAddress`);
  const [selectedMailingAddress, setSelectedMailingAddress] =
    useState(`suggestedAddress`);

  const [modal, setModal] = useState(true);
  const [showServiceAddressModal, setShowServiceAddressModal] = useState(false);
  const [showMailingAddressModal, setShowMailingAddressModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [confirmedAddress, setConfirmedAddress] = useState({
    serviceAddress: suggestedAddress.serviceAddress
      ? { ...suggestedAddress.serviceAddress }
      : { ...enteredAddress.serviceAddress },
    mailingAddress: suggestedAddress.mailingAddress
      ? { ...suggestedAddress.mailingAddress }
      : { ...enteredAddress.mailingAddress },
  });

  const [showConfirmAddressModal, setShowConfirmAddressModal] = useState(false);


  useEffect(() => {
    if (props.showServiceAddress) {
      setShowServiceAddressModal(true);
    } else {
      setShowMailingAddressModal(true);
    }
  }, []);
  const AddressText = ({ addressObj }) => {
    return (
      <div>
        {`${addressObj?.address1}`}
        <br />
        {typeof addressObj?.address2 === "string" &&
          addressObj?.address2.trim().length > 0 && (
            <>
              {addressObj?.address2}
              <br />
            </>
          )}
        {`${addressObj?.city}, ${addressObj?.state}`}{" "}
        {`${addressObj?.zip}${addressObj?.plus4 ? -addressObj?.plus4 : ""}`}
      </div>
    );
  };

  const onOptionChange = (e) => {
    const { name, id } = e.target;
    if (name === "serviceAddress") {
      if (id === "suggestedAddress") {
        setConfirmedAddress({
          ...confirmedAddress,
          serviceAddress: suggestedAddress.serviceAddress,
        });
      } else {
        setConfirmedAddress({
          ...confirmedAddress,
          serviceAddress: enteredAddress.serviceAddress,
        });
      }
      setSelectedServiceAddress(id);
    }
    if (name === "mailingAddress") {
      if (id === "suggestedAddress") {
        setConfirmedAddress({
          ...confirmedAddress,
          mailingAddress: suggestedAddress.mailingAddress,
        });
      } else {
        setConfirmedAddress({
          ...confirmedAddress,
          mailingAddress: enteredAddress.mailingAddress,
        });
      }
      setSelectedMailingAddress(id);
    }
  };

  const handleContinue = (e) => {
    setShowSpinner(true);
    if (showServiceAddressModal) {
      
      if (props.showMailingAddress) {
        setTimeout(()=>{
            setShowServiceAddressModal(false);
            setShowSpinner(false);
            setShowMailingAddressModal(true);
        }, 1000)
      } else {
        setTimeout(()=>hideModal(), 1000);
      }
    }

    if (showMailingAddressModal) {
      setTimeout(()=>hideModal(), 1000);
    }
  };

  const handleHideModal = (e) => {
    hideModal();
  };

  return (
    <div>
      <div>
        <Modal
          isOpen={modal}
          aria-modal="true"
          role="dialog"
          aria-hidden="true"
          aria-labelledBy="suggest"
          className="md"
        >
          <ModalHeader>Recommended Address</ModalHeader>
          <ModalBody>
            {showServiceAddressModal && (
              <div>
                <h3 className={styles.addressH3}>Service Address</h3>
                <p className={styles.addressParagraph}>
                  We found a more precise version of the address you entered. If
                  it looks correct, please use the suggested address.
                </p>
                <FormGroup
                  className={classNames(noGutters, radioBoxWrapper)}
                  role="group"
                  id="addressSelectionGroup"
                >
                  <Col md="7" className="mt-3">
                    <p
                      className={styles.addressRadioLabel}
                      aria-label="Address Entered"
                      for="radioButtonExampleGroup"
                    >
                      We Suggest:
                    </p>
                    <div className={styles.radioBox}>
                      <Input
                        type="radio"
                        name="serviceAddress"
                        id="suggestedAddress"
                        checked={selectedServiceAddress === "suggestedAddress"}
                        onChange={onOptionChange}
                      />
                      <Label
                        className={styles.radioControlsLabel}
                        for="suggestedAddress"
                      >
                        <AddressText
                          addressObj={suggestedAddress.serviceAddress}
                        />
                      </Label>
                    </div>
                  </Col>
                  <Col md="7" className="mt-3">
                    <p className={styles.addressRadioLabel}>You Entered:</p>
                    <div className={styles.radioBox}>
                      <Input
                        type="radio"
                        name="serviceAddress"
                        id="enteredAddress"
                        onChange={onOptionChange}
                        checked={selectedServiceAddress === "enteredAddress"}
                      />
                      <Label
                        className={styles.radioControlsLabel}
                        for="enteredAddress"
                      >
                        <AddressText
                          addressObj={enteredAddress.serviceAddress}
                        />
                      </Label>
                    </div>
                  </Col>
                </FormGroup>
              </div>
            )}
            {showMailingAddressModal && (
              <div>
                <h3 className={styles.addressH3}>Mailing Address</h3>
                <p className={styles.addressParagraph}>
                  We found a more precise version of the address you entered. If
                  it looks correct, please use the suggested address.
                </p>
                <FormGroup
                  className={classNames(noGutters, radioBoxWrapper)}
                  role="group"
                  id="addressSelectionGroup"
                >
                  <Col md="7" className="mt-3">
                    <p
                      className={styles.addressRadioLabel}
                      aria-label="Address Entered"
                      for="radioButtonExampleGroup"
                    >
                      We Suggest:
                    </p>
                    <div className={styles.radioBox}>
                      <Input
                        type="radio"
                        name="mailingAddress"
                        id="suggestedAddress"
                        checked="true"
                        onChange={onOptionChange}
                        checked={selectedMailingAddress === "suggestedAddress"}
                      />
                      <Label
                        className={styles.radioControlsLabel}
                        for="suggestedAddress"
                      >
                        <AddressText
                          addressObj={suggestedAddress.mailingAddress}
                        />
                      </Label>
                    </div>
                  </Col>
                  <Col md="7" className="mt-3">
                    <p className={styles.addressRadioLabel}>You Entered:</p>
                    <div className={styles.radioBox}>
                      <Input
                        type="radio"
                        name="mailingAddress"
                        id="enteredAddress"
                        onChange={onOptionChange}
                        checked={selectedMailingAddress === "enteredAddress"}
                      />
                      <Label
                        className={styles.radioControlsLabel}
                        for="enteredAddress"
                      >
                        <AddressText
                          addressObj={enteredAddress.mailingAddress}

                        />
                      </Label>
                    </div>
                  </Col>
                </FormGroup>
              </div>
            )}

            {showConfirmAddressModal && <div>Confirmation Address</div>}
          </ModalBody>
          <ModalFooter className={styles.modalFooterButtonRow}>
            <FooterButtonsRow
              isModal={true}
              buttons={{
                primary: {
                  text: "Continue",
                  type: "primary",
                  onClick: handleContinue,
                  ariaLabel: "Continue",
                  hasSpinner:true,
                  isSpinning: showSpinner,
                },
                link: {
                  text: "Edit Address",
                  ariaLabel:
                    "Click edit address to close modal and return to customer information page",
                  onClick: handleHideModal,
                },
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default RecommendedAddressModal;
