import React from 'react';
import {ReactComponent as InfoIcon} from '../../../assets/images/info-i-icon.svg';
import {ReactComponent as QuestionInfoIcon} from '../../../assets/images/info-icon.svg';
import styles from './InfoTooltip.module.css';

const InfoTooltip = ({infoType, message}) => {
  return (
      <>
        {infoType === "questionInfo" ? <span className={styles.onsiteInfo}><QuestionInfoIcon /><span className={styles.onsiteInfoTooltiptext}>{message}</span></span>: 
        <span className={styles.onsiteInfo}><InfoIcon /><span className={styles.onsiteInfoTooltiptext}>{message}</span></span> }
      </>
  )
}

export default InfoTooltip;