export const ISOCountryCodes = {
    AU: 'AU',
    AUS: 'AU',
    ES: 'ES',
    DE: 'DE',
    FR: 'FR',
    GB: 'GB',
    IT: 'IT',
    UK: 'GB',
    US: 'US',
    VN: 'VN',
    CL: 'CL',
    NZ: 'NZ',
    AT: 'AT',
    DK: 'DK',
    FI: 'FI',
    IE: 'IE',
    NL: 'NL',
    NZ: 'NZ',
    NO: 'NO',
    SE: 'SE',
    CH: 'CH',
    CL: 'CL'
}