import React, { useState, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import getClientContent from '../_BrandingProvider/brandContent.jsx'
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';


const HistoricalClaimModal = ({ isOpen, toggle, clientId, assetName }) => {
    const item = assetName;

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} className="md" zIndex="9999" aria-modal="true" role="dialog" aria-labelledBy="cancel">
                <ModalBody>
                    <div className="pt-5 pb-3 px-2">
                        <h1 className="h4">
                            We're sorry you're experiencing another issue so soon.
                        </h1>
                        <p className="px-4">
                            Since you recently filed a {item} claim, please contact the {clientId.toUpperCase()} Claims Team at
                            {` ${getClientContent(clientId).variableText?.portfolioClaim?.phone}`} so we can better assist you.
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="py-2">
                        <button className="btnLink mr-1" aria-label="Close a modal" onClick={toggle}>
                            Close
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default HistoricalClaimModal;