import React, { useContext } from 'react';
import {
    Container, Col, Row, Card, CardHeader, CardBody, Label, Input, Form, CardTitle, FormGroup
} from 'reactstrap';
import styles from '../FileClaim/Payment.module.css';
import Cleave from 'cleave.js/react';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import { ContractContext } from '../contexts/ContractContext';


export default function PaymentBillingInfo({
    state, 
    handleCheckAddress, 
    handleInputChange, 
    handleStateDropDown, 
    validator
}) {
    let verifiedAddress = state.verifiedAddress;
    const serviceAddress = state.serviceAddress;
    let useServiceAddress = false;

    const context = useContext(ContractContext)
    const { clientId } = context.data

    if (verifiedAddress == null || typeof verifiedAddress.stateId == 'undefined') {
        verifiedAddress = serviceAddress;
        useServiceAddress = true;
    }
    return (
        <div>
            <Label for="billingAddress" className="form-label col-12 p-0">Billing Address <span aria-hidden="true" className="required-indicator">*</span></Label>
            <div className="checkbox" aria-label="Mailing address checkbox is checked uncheck to edit address information">
                <Input className="mr-2" type="checkbox" id="mailingaddress" onChange={handleCheckAddress} checked={!state.customAddress} />
                <Label for="mailingaddress" className={`checkboxLabel ${clientId.toLowerCase()}`}>
                    {`Use my ${useServiceAddress ? 'service' : 'mailing'} address`}
                </Label>
            </div>
            {!state.customAddress ?
                <ul id="billingAddressStatic" className="noBullets pb-3">
                    <li>{verifiedAddress.address1 || "1203, Carlson Way"}</li>
                    <li>{verifiedAddress.address2 || "Greenwood"}</li>
                    <li>{verifiedAddress.city || 'Jersy'}, {verifiedAddress.state || 'NewJersy'} {verifiedAddress.zip || '50042'}</li>
                </ul>
                :
                <div id="billingAddressFields">
                    <FormGroup className={(state.errors.billingAddress && state.validationFired) ? "form-error" : ""}>
                        <Label for="billingAddress" className="form-label">Street Address</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <Input type="text" className="form-control" id="billingAddress" name="billingAddress" aria-required="true" onChange={handleInputChange} value={state.billingAddress.address1} />
                        {validator.message("billingAddress", state.billingAddress.address1, "required")}
                    </FormGroup>
                    <div className="form-group">
                        <Label for="billingAddress2" className="form-label">Apt, Suite, Building</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="billingAddress2"
                            name="billingAddress2"
                            aria-required="true"
                            onChange={handleInputChange}
                            value={((state.billingAddress.address2 === null || state.billingAddress.address2 === undefined) ? '' : state.billingAddress.address2)} />
                    </div>
                    <FormGroup className={((state.errors.city && state.validationFired) ? "form-error" : "")}>
                        <Label for="billingCity" className="form-label">City</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <Input type="text" className="form-control" id="billingCity" name="city" aria-required="true" onChange={handleInputChange} value={state.billingAddress.city} />
                        {validator.message("city", state.billingAddress.city, "required")}
                    </FormGroup>
                    <Row className="form-group twoColWrapper">
                        <div className="col-sm-6">
                            <label for="billingState" className="form-label">State</label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            {state.states && <DropDownAutoComplete name="state"
                                options={state.states}
                                className="form-control"
                                aria-required="true"
                                onChangedValue={handleStateDropDown}
                                isFloatingDropdown={false}
                                onBlur={handleStateDropDown}
                                defaultValue={(state.billingAddress.stateId === '' ? undefined : state.billingAddress.stateId)}
                                Value={state.billingAddress.stateId}
                            />}
                            {validator.message("state", state.billingAddress.stateId, "required")}
                        </div>
                        <div className="col-sm-6">
                            <FormGroup className={((state.errors.zip && state.validationFired) ? "form-error" : "")}>
                                <Label for="zipCode" className="form-label">Zip/Postal</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <Cleave
                                    options={{ blocks: [5], numericOnly: true }}
                                    className="form-control"
                                    id="zipCode"
                                    name="zipCode"
                                    aria-required="true"
                                    onChange={handleInputChange}
                                    value={state.billingAddress.zip}
                                />
                                {validator.message("postalCode", state.billingAddress.zip, "required")}
                            </FormGroup>
                        </div>
                    </Row>
                </div>
            }
        </div>
    );
}