import React from 'react';
import { Badge } from 'reactstrap';

const AIGStatusBadge = ({
    statusId,           // NUMBER RETURNED FROM BACKEND
    statusType,         // USE WITH STATUS ID TO GET CORRECT COLOR/CONTENT. MUST BE ONE OF THE OBJECT KEYS IN THE "GET STATUS PROPS" OBJ BELOW
    alignLeft = false,
    color,        // IF BADGE NEEDED FOR CUSTOM PURPOSE. FOLLOW BOOTSTRAP COLORING PROTOCOL
    content       // IF BADGE NEEDED FOR CUSTOM PURPOSE
}) => {
    // ADD MORE STATUS SETS HERE AS NEEDED BASED ON CONTEXT
    const getStatusProps = {
        contract: () => {
            switch (statusId) {
                case 1:
                    return { color: 'success', content: 'Active' };
                case 2:
                    return { color: 'warning', content: 'Wait' };
                case 3:
                    return { color: 'danger', content: 'Fulfilled' };
                case 4:
                    return { color: 'danger', content: 'Cancelled' };
                case 5:
                    return { color: 'danger', content: 'Deleted' };
                case 6:
                    return { color: 'danger', content: 'Suspended' };
                case 7:
                    return { color: 'danger', content: 'Expired' };
                case 8:
                    return { color: 'success', content: 'Authorized' };
                default:
                    console.error(`STATUS BADGE ERROR: Contract Status ID ${statusId} has no mapped value`)
                    return { color: 'danger', content: 'Status Unknown' }
            }
            // return { color: 'primary', contnet: 'Status Unknown'}
        },
        claim: () => {
            switch (statusId) {
                case 1:
                    return { color: 'success', content: 'Open' };
                case 2:
                    return { color: 'danger', content: 'Closed' };
                case 3:
                    return { color: 'danger', content: 'Partial' };
                case 4:
                    return { color: 'danger', content: 'Closed with amount' };
                case 5:
                    return { color: 'danger', content: 'Closed without payment' };
                case 6:
                    return { color: 'success', content: 'Saved' };
                case 7:
                    return { color: 'warning', content: 'In Review' };
                case 8:
                    return { color: 'danger', content: 'Abandoned' };

                default:
                    console.error(`STATUS BADGE ERROR: Claim Status ID ${statusId} has no mapped value`)
                    return { color: 'danger', content: 'Status Unknown' }
            }
            // return { color: 'primary', contnet: 'Status Unknown'}
        },
        event: () => {
            switch (statusId) {
                case 1:
                    return { color: 'success', content: 'Open' };
                case 2:
                    return { color: 'danger', content: 'Closed' };
                case 3:
                    return { color: 'danger', content: 'Partial' };
                case 4:
                    return { color: 'danger', content: 'Closed with amount' };
                case 5:
                    return { color: 'danger', content: 'Closed without payment' };
                case 6:
                    return { color: 'danger', content: 'Saved' };
                case 7:
                    return { color: 'warning', content: 'In Review' };
                case 8:
                    return { color: 'warning', content: 'Pending Authorization' }
                case 9:
                    return { color: 'success', content: 'Authorized' }
                case 12:
                    return { color: 'warning', content: 'Action Required' }
                case 13:
                    return { color: 'success', content: 'Upcoming Event' }
                case 14:
                    return { color: 'success', content: 'In Progress' }
                default:
                    return { color: 'danger', content: 'Status Unknown' }
            }
        }
    }

    return (
        <Badge
            color={color || getStatusProps[statusType]().color}
            className={alignLeft && 'ml-0'}>
            {content || getStatusProps[statusType]().content}
        </Badge>
    )
}

export default AIGStatusBadge;
