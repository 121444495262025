import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styles from "./RemoveAddonModal.module.css";
import FooterButtonsRow from "../shared/FooterButtonsRow/FooterButtonsRow";

const RemoveAddonModal = (props) => {
  const { addon, handlerRemoveAddon, hideModal } = props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <Modal
        isOpen={setModal}
        aria-modal="true"
        className="md"
        aria-labelledBy="remove addon"
        role="dialog"
      >
        <ModalBody className={styles.addonModalBody}>
          <h5 className="modal-title" id="save">
            {`Are you sure you want to remove ${addon.title.toLowerCase()} add-on? `}
          </h5>
        </ModalBody>
        <ModalFooter className={styles.modalFooterButtonRow}>
          <FooterButtonsRow
            isModal
            buttons={{
              link: {
                text: "Cancel",
                onClick: hideModal,
                ariaLabel:
                  "Click cancel to close modal and return to customer information page",
              },
              primary: {
                text: "Remove",
                type: "primary",
                onClick: handlerRemoveAddon,
                ariaLabel: "Remove",
              },
            }}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RemoveAddonModal;
