import React, { useEffect, useContext, useState } from 'react';
import { ContractContext } from '../contexts/ContractContext';
import { HWAHeader } from './HWAHeader/HWAHeader';
import { Header } from '../Header';
import AppleHeader from '../Vendor/Apple/AppleHeader';
import { isAppleRoute, isHWAChatEndRoute } from '../../helpers';
import NJRHeader from './NJRHeader/NJRHeader';
import { ETHeader } from './ETHeader/ETHeader';

export const HeaderController = (props) => {
    const context = useContext(ContractContext);
    const { data: { clientId }} = context;
    const checkParams = new URLSearchParams(window.location.search);

    // useEffect(() => {
    //     if (brandContext.clientId !== null) {
    //         setClientId(brandContext.clientId.toLowerCase());
    //     } else {
    //         const hwaChat = async () => {
    //             const result = await isHWAChatEndRoute();
    //             console.log(result);
    //             if (result == true) {
    //                 setClientId('hwa');
    //             }
    //         };
    //         hwaChat();
    //     }
    // }, [brandContext.clientId]);

    if (clientId === null || clientId === undefined || clientId === 'AIG' || clientId?.toLowerCase() === 'apple') {
        if (isAppleRoute() && (!window.location.pathname.toLowerCase().includes("chatend") ||
            !window.location.pathname.toLowerCase().includes("chat"))) {
            return <AppleHeader />
        }
    }

    switch (clientId?.toLowerCase()) {
        case "apple":
            return <AppleHeader />
        case "hwa":
            return <HWAHeader />
        case "njr":
            return <NJRHeader />
        case "et":
            return <ETHeader {...props} />   //To see ET header, change the return value to true in ETRoute in helpers.jsx
        case "arw":
        default:
            return <Header {...props} />
    }
}