import React, { createContext, Component } from 'react';

export const SalesContext = createContext({
  planData: {},
  formData: {}
});

export class SalesProvider extends Component {
  state = {
    planData: {
      activeAddPlans: [],
      activePlan: null,
      additionalTotal: 0,
      plan: "monthly",
      planTotal: 0,
      selectedAddPlans: [],
      selectedPlan: {}
    },
    formData: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      serviceAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "NJ",
        zip: "",
        stateId: 39,
      },
      mailingAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        stateId: "",
      },
      doNotSolicit: null,
      serviceAsMailingAddress: true
    },
    termsAndCondition: false
  }

  render() {
    return (
      <SalesContext.Provider
        value={
          {
            // DATA
            data: this.state,

            // METHODS
            updatePlanData: (p) => {
              this.setState({
                planData: p,
              })
            },
            updateFormData: (f) => {
              this.setState({
                formData: f,
              })
            },
            updateTermsAndCondition: (t) => {
              this.setState({
                termsAndCondition: t
              })
            }
          }
        }
      >
        {this.props.children}
      </SalesContext.Provider>

    );
  }
}