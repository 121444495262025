import React, { Component, useEffect, useContext } from 'react';
import { PlanLookup } from '../components/PlanSearch/PlanLookup';
import DocumentTitle from 'react-document-title';
import { ContractContext } from '../components/contexts/ContractContext';
import { getMetaDataByClient } from '../App';


export const PlanSearch = ({history}) => {
    const context = useContext(ContractContext);

    useEffect(()=> {
        let clientId
        if (context.data) {
            clientId = context.data.contract.clientId
        }
        const pageTitle = getMetaDataByClient(clientId || 'AIG', "Plan Search").title
        document.title = pageTitle
    })

    return (
        <PlanLookup history={history} />
    );
}