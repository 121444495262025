import React, { useState, useRef, useContext, useEffect } from "react";
import { Container, Col, FormGroup, Label } from "reactstrap";
import styles from "./CoverageDocuments.module.css";
import useLoadAtTop from "../../components/_ReactHooks/useLoadAtTop";
import { DropDownInput } from "../../components/inputs/DropDownInput";
import DocumentTitle from 'react-document-title';
import SimpleReactValidator from "simple-react-validator";
import { ContractContext  } from '../contexts/ContractContext';
import CallLink from '../shared/PhoneNumberUtility/CallLink';
import getClientContent from '../_BrandingProvider/brandContent';
import moment from 'moment';
import { openDocument } from '../Global/DocumentHelper';

const CoverageDocuments = () => {
    useLoadAtTop()
    const context = useContext(ContractContext);
    const [selectedPlanType, setSelectedPlanType] = useState("");
    const [selectedPurchasePlan, setSelectedPurchasePlan] = useState("");
    const [validationFired, setValidationFired] = useState(false);
    const [, forceUpdate] = useState();
    const validator = useRef(
        new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg" aria-live="polite">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
        })
    );

    const { data: { clientId } } = useContext(ContractContext);
    let { phone } = getClientContent(clientId)?.variableText?.customerService;
    const clientTextValues = getClientContent(clientId);
    const title = clientTextValues.variableText.pageTitle;

    const pageTitle = title ? `Coverage Documents | ${title}` : 'Coverage Documents';
    const [state, setState] = useState({
        coverageDocs: [],
        planTypeOptions: [],
        purchasePlanOptions: []
    })

    useEffect(() => {
        getCoverageDocs();
    }, [clientId])

    const getCoverageDocs = async () => {
        fetch(`api/document/GetCoverageDocuments/${clientId}`)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data)) {
                    let planTypeOptions = [];

                    data.map(x => {
                        if (!planTypeOptions.find(y => y.data == x.warrantyObjectValue)) {
                            planTypeOptions.push({
                                data: x.warrantyObjectValue,
                                display: x.mappedDocumentAssetName
                            })
                        }
                    })

                    setState(prev => ({
                        ...prev,
                        coverageDocs: data,
                        planTypeOptions
                    }));
                }
            });
    }

    const getDocVersions = (warrObjectValue) => {
        const { coverageDocs } = state;
        const filteredDocs = coverageDocs.filter(x => x.warrantyObjectValue == warrObjectValue);
        const purchasePlanOptions = filteredDocs.map(x => {
            return {
                data: x.documentId,
                display: `Purchased on or after ${moment(x.effectiveFrom).format("MMMM DD, YYYY")}`
            }
        })

        setState(prev => ({
            ...prev,
            purchasePlanOptions
        }));

        if (Array.isArray(purchasePlanOptions)) {
            setSelectedPurchasePlan(purchasePlanOptions[0].data);
        }
    }

    const handlePlanTypeDropdown = (e) => {
        getDocVersions(e)
        setSelectedPlanType(e);
    }

    const handlePurchaseDropDown = (e) => {
        setSelectedPurchasePlan(e);
    }

    const handleSubmit = () => {
        setValidationFired(true);
        if (validator.current.allValid()) {
            openDocument(12, selectedPlanType, selectedPurchasePlan, clientId);
        } else {            
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <DocumentTitle title={pageTitle}>
            <>
                <div className={styles.pageHeader}>
                    <Container className={styles.pageHeaderContent}>
                        <h1>Terms and Conditions</h1>
                        <p className="mb-0 text-center">
                            Terms and Condition vary depending on the date of purchase and the type of plan. 
                            Choose from the selections below to view and print the appropriate version.
                        </p>
                    </Container>
                </div>
                <Container>
                    <div className={styles.termsForm}>
                        <Col className="mb-2 p-0">
                            <FormGroup className={(validationFired && !selectedPlanType) ? "form-error" : ""}>
                                <Label htmlFor="planType" className="form-label font-weight-bold">Type of Plan</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownInput
                                    name="planType"
                                    options={state.planTypeOptions}
                                    className="form-control"
                                    onChangedValue={handlePlanTypeDropdown}
                                    placeholder={"Please select..."}
                                    id="planType"
                                    defaultValue={selectedPlanType}
                                />
                                {validator.current.message(
                                    "planType",
                                    selectedPlanType,
                                    "required",
                                    { messages: {required: "Type of Plan is required."} }
                                )}
                            </FormGroup>
                            <FormGroup className={(validationFired && !selectedPurchasePlan) ? "form-error" : ""}>
                                <Label htmlFor="purchaseDate" className="form-label font-weight-bold">
                                    When Did You Purchase the Plan?
                                </Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownInput
                                    name="purchasePlanDate"
                                    options={state.purchasePlanOptions}
                                    className="form-control"
                                    onChangedValue={handlePurchaseDropDown}
                                    placeholder={"Please select..."}
                                    id="purchasePlanDate"
                                    defaultValue={selectedPurchasePlan}
                                />
                                {validator.current.message(
                                    "purchasePlanDate",
                                    selectedPurchasePlan,
                                    "required",
                                    { messages: {required: "Please select a date range."} }
                                )}
                            </FormGroup>
                        </Col>
                        <Col lg="12" md="12" className="d-flex flex-center mb-2">
                            <button
                                className="btn btn-primary mb-2 mt-2"
                                onClick={handleSubmit}
                            >
                                View Terms and Conditions
                            </button>
                        </Col>
                        <Col className="text-center">
                            <p className="mb-0">Need Help?</p>
                            <p><CallLink phone={phone} /></p>
                        </Col>
                    </div>
                </Container>
            </>
        </DocumentTitle>
    )
}

export default CoverageDocuments