import React, { Component, Fragment } from 'react';
import {
    Container, Col, Row, Card, CardHeader, CardBody, Label, Input, Form, CardTitle
} from 'reactstrap';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import StepsHeader from './StepsHeader';
import Troubleshooting from './Troubleshooting';
import Symptoms from './Symptom';
import IncidentDate from './IncidentDate';
import IncidentDescription from './IncidentDescription'
import PriorityService from './PriorityService';
import FollowUp from './FollowUp';
import CancelModal from '../modals/CancelModal';
import { Payment } from './Payment';
import { updateSymptomClaim, addAnswerCollection, getDenialReason, dataLayerPush, pushHistory, paths } from '../../helpers';
import { VerifyInfo } from './VerifyInfo';
import { Confirmation } from './Confirmation';
import WarrantyOfWorkModal from '../modals/WarrantyOfWork';
import { Spinner } from 'react-bootstrap';
import SaveModal from '../modals/SaveModal';
import axios from "axios";
import moment from 'moment';
import qs from 'qs';
import EmailAddressModal from '../modals/EmailAddressModal';
import FooterButtonsRow from '../shared/FooterButtonsRow/FooterButtonsRow';
import SurgeClaimHireOwnServicer from './SurgeClaimHireOwnServicer';
import { ContractContext } from '../contexts/ContractContext'
import { cancelClaim, updateClaimStatus } from '../Global/helpers';
import { isAppleRoute } from "../../helpers"
import DocumentTitle from 'react-document-title';
import getClientContent from "../../components/_BrandingProvider/brandContent";

let cardTitle = styles.cardTitle;
const coverageAsAssets = [376];
export class Entitlement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contract: typeof this.props.location?.claim?.contract == 'undefined' ? null : this.props.location?.claim?.contract,
            stepsCounter: typeof this.props.location?.claim?.stepsCounter == 'undefined' ? 1 : this.props.location?.claim?.stepsCounter,
            symptoms: [],
            selectedSymptom: null,
            incidentDate: null,
            showIncidentDateError: false,
            showFollowUpError: false,
            incidentDescription: '',
            affirmationCheck: null,
            followUpChosen: null,
            troubleShootingSelected: null,
            activeStep: 1,
            isPaymentActive: this.props.location?.stepsCounter == 7 ? true : false,
            fulfillmentTypeId: typeof this.props?.location?.state?.entitlementDetails?.contract?.contractAssetModel?.find(ca => ca.contractAssetId == this.props.match.params.assetId)?.offer?.fulfillmentTypeId == 'undefined' ? 0 : this.props?.location?.state?.entitlementDetails?.contract?.contractAssetModel?.find(ca => ca.contractAssetId == this.props.match.params.assetId)?.offer?.fulfillmentTypeId,
            billingAddress: null,
            result: typeof this.props.location?.claim?.result == 'undefined' ? null : this.props.location?.claim?.result,
            clientId: this.getClientId(),
            nextQuestionId: null,
            answerColleciton: [],
            currentQuestion: 0,
            currentQuestionModel: typeof this.props.location?.claim?.lastQuestionAnswered == 'undefined' ? null : this.props.location?.claim?.lastQuestionAnswered,
            nextQuestion: 0,
            nextQuestionModel: null,
            emergencyFlag: null,
            isSymptomRepeated: false,
            showWarrantyOfWorkModal: false,
            showCancelModal: false,
            claimId: this.props.match.params.claimId,
            claimNumber: this.props.location.state.entitlementDetails?.claimNumber,
            contractAssetId: this.props.match.params.assetId,
            showSpinnerGridButton: false,
            hwaToken: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id,
            isShowEmailConfirmation: false,
            emailModel: {},
            serviceModel: {},
            spinning: false,
            showHireOwnServier: false,
            surgeTermsSelected: false,
            paymentSuccessful: false,
            cancelClaimOnLogoClick: false,
            questions: [],
            claimableCoverages: (this.props.location?.claim?.claimableCoverages || this.props.location?.state?.claim?.claimableCoverages) ?? false,
            coverageId: this.props?.match?.params?.coverageId
        }

        this.handleCloseWarrantyOfWorkModal = this.handleCloseWarrantyOfWorkModal.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
        this.keepOnPage = this.keepOnPage.bind(this);
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', this.keepOnPage);
        this.fetchSymptoms();
        const { stepsCounter, claimableCoverages } = this.state;
        let { incidentDate } = this.state;

        incidentDate = this.props?.location?.claim?.lossDate ?? incidentDate;
        if (incidentDate) {
          incidentDate = moment(incidentDate).format('L');
        }

        if (claimableCoverages && stepsCounter < 6) {
          // skip to step 5
          this.setState({
            stepsCounter: 5,
            showFollowUpError: false,
            incidentDate
          })

          return;
        }

        let contractAssetModelObj = this.props?.location?.state?.entitlementDetails?.contract?.contractAssetModel?.find(ca => ca.contractAssetId == this.props.match.params.assetId);
      this.setState({
        fulfillmentTypeId: contractAssetModelObj?.offer?.fulfillmentTypeId,
        incidentDate
      });
    }

    componentDidUpdate(prevProps) {
        if(this.state.stepsCounter !== 8 && this.props.cancelClaim !== prevProps.cancelClaim && this.props.cancelClaim) {
            this.toggleCancelModal('OPEN');
            this.setState({cancelClaimOnLogoClick: true})
        }
    }

    getClientId = () => {
        const clientId = this.props?.location?.state?.clientId || this.props?.location?.state?.entitlementDetails?.clientId;

        return clientId;
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }

    keepOnPage(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

        let entitlementDetails = this.props.history.location.state.entitlementDetails;
        let headers = {
            type: 'application/json'
        };

        let claim = this.props.history.location.state.claim;
        
        claim.symptom = this.state.selectedSymptom?.questionSymptomMappingId?.toString() ?? null;
        claim.lossDate = this.state.incidentDate == null ? null : moment(this.state.incidentDate).format('L');
        claim.incidentDescription = this.state.incidentDescription ?? null;
        claim.emergencyFlag = this.state.emergencyFlag ?? null;
        claim.affirmationFlag = this.state.affirmationCheck ?? null;

        this.saveForLaterAction(claim, true);
    }

    saveForLaterAction = (claim, claimAbandoned) => {        
        if (!claim) { return; }
        this.setState({ uploading: true });
        const data = { ...claim, createdBy: claim?.createdBy || claim?.partyModel?.createdBy, stepsCounter: this.state.stepsCounter}
        //claim.lossDate = claim.lossDate == "" ? null : claim.lossDate;

        fetch(`api/claim/Autosave/${claim?.claimId}/${this.state.clientId}/${claimAbandoned}`, { 
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(data => {
                if (data.status === 200 && !claimAbandoned) {
                    this.pushHistory(paths.fileClaimConfirmation, { statusType: 'savedClaim' });
                }

                this.setState({ uploading: false });
            });
    }

    handleSymptomSelected = async (selected) => {
        /**
         *  WIREUP SEU-195: THIS FUNCTION WILL NEED TO CHECK PREVIOUS SYMPTOMS.
         *  IF SYMPTOM IS REPEATED, DO:
         *  this.setState({
         *      isSymptomRepeated: true;     
         *  })
         */
        //const claimId = this.props.match.params.claimId;
        const contractAssetId = this.props.match.params.assetId;
        var questionSymptomMappingId = selected.questionSymptomMappingId;
        try {
            await fetch(`/api/claim/GetWowPeriodDetailsBySympton/${this.state.claimId}/${this.state.clientId}/${questionSymptomMappingId}/${contractAssetId}`)
                .then(res => res.json())
                .then(data => {
                    if (data.claimId > 0) {
                        this.state.claim = data;
                        this.setState({
                            showWarrantyOfWorkModal: true,
                            isSymptomRepeated: true
                        });
                    }
                    else {
                        this.setState({
                            showWarrantyOfWorkModal: false,
                            isSymptomRepeated: false
                        });

                        //this.getNextQuestion(this.state.selectedSymptom);
                    }
                })


        } catch (error) {
            console.log('error:fetch claims:', error)
            this.setState({ errorMessage: 'Error fetching claims. Try again.' })
        }

        this.setState({
            selectedSymptom: selected,
            showSymptomError: false,
            // isSymptomRepeated: true,
        })
    }

    getContract() {
        const claimId = this.props.match.params.claimId;
        const contractId = this.props.match.params.contractId;
        const assetId = this.props.match.params.assetId;
        return new Promise((resolve, reject) => {
            fetch(`api/contractsearch/GetByContractId/${contractId}/${this.state.clientId}`)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        contract: data[0]
                    });
                })
        });
    }

    getAssetForGtmAnalytics = () => {
        const { assets, contractAssetModel } = JSON.parse(sessionStorage.getItem('appState')).contract
        const contractAssetId = this.props.match.params.assetId
        console.log(contractAssetId, contractAssetModel, this.state.contract, this.props, JSON.parse(sessionStorage.getItem('appState')))
        const contractAsset = contractAssetModel.find(a => {
            return a.contractAssetId == contractAssetId
        })
        const asset = assets.find(a => a.assetId == contractAsset?.assetId)
        return asset
    }


    componentWillMount() {
        if (this.props.clientId !== 'apple') {
            this.getContract();
        }

        const asset = this.getAssetForGtmAnalytics()
        dataLayerPush('/entitlement', 'Entitlement', { asset: asset?.assetName })
    }

    fetchSymptoms = async () => {
        try {
            const assetId = this.props.match.params.assetId;
            const finalRoute = `api/troubleshooting/GetSymptoms/${assetId}/${this.state.clientId}`;
            const response = await fetch(finalRoute);
            const symptoms = await response.json();

            symptoms.forEach(d => {
                //add priority service quesion
                if (d.priorityService) {
                    //var questionSetMappingModel = d.questionSetMappingModel;
                    //adds priority question if priority service available
                    if (d.questionSet?.questionSetMappingModel == null || d.questionSet?.questionSetMappingModel == []) {
                        if (d.questionSet == null) {
                            d.questionSet = {}
                        }
                        d.questionSet.questionSetMappingModel = [{
                            questionModel: { questionName: 'PriorityService' },
                            sequenceNumber: 10,
                            questionTypeId: 3
                        }];
                    }
                    else {
                        var numbers = d.questionSet.questionSetMappingModel.map(x => x.sequenceNumber);
                        var highestSequenceNumber = Math.max(...numbers);
                        d.questionSet.questionSetMappingModel.push({
                            questionModel: { questionName: 'PriorityService' },
                            sequenceNumber: highestSequenceNumber + 10,
                            questionTypeId: 3
                        });
                    }
                }
                return d;
            });


            let {
                nextQuestion = 0,
                nextQuestionModel = null,
                questionSetMappingId,
                currentQuestionModel,
                currentQuestionSetMappingId,
                selectedSymptom,
                showHireOwnServier,
                fulfillmentTypeId,
                followUpChosen,
                stepsCounter
            } = this.state;

            const { claim } = this.props?.location;
            const symptomSelected = symptoms?.find(s => s.symptom == claim?.symptom);
            const lastAnsweredQuestion = claim?.lastQuestionAnswered?.questionId ?? 0;
            const questions = symptomSelected?.questionSet?.questionSetMappingModel ?? [];
            const isSurgeSymptom = symptoms?.find(s => s.symptom === "Surge Protection" && s.fulfillmentTypeId === 3)
            
            if (isSurgeSymptom) {
                showHireOwnServier = true
                fulfillmentTypeId = 3
            }

            selectedSymptom = symptomSelected;

            if (claim?.symptom && claim?.stepsCounter < 6)
            {
                let questionSetMapping = symptomSelected.questionSet?.questionSetMappingModel;
                let questionSet = questionSetMapping?.find(qsm => qsm.questionId == lastAnsweredQuestion)
                const emergencyFlag = claim?.emergencyFlag;

                if (stepsCounter == 4 && symptomSelected?.priorityService && emergencyFlag == null) {
                    questionSet = questionSetMapping[0]
                }

                if (symptomSelected) {
                    followUpChosen = true;
                }

                if (questionSetMapping) {
                    let nextQuestionSetMapping = Array.isArray(questionSetMapping) && questionSetMapping.length > 0
                        ? questionSetMapping.find((questionSetMapping) => questionSetMapping.sequenceNumber >= questionSet?.sequenceNumber)
                        : questionSetMapping.sequenceNumber >= questionSet?.sequenceNumber ? questionSetMapping : null;

                    if (nextQuestionSetMapping) {
                        nextQuestion = nextQuestionSetMapping.sequenceNumber;
                        nextQuestionModel = nextQuestionSetMapping.questionModel;
                        questionSetMappingId = nextQuestionSetMapping.questionSetMappingId;
                        currentQuestionModel = nextQuestionSetMapping.questionModel;
                        currentQuestionSetMappingId = nextQuestionSetMapping.questionSetMappingId;
                    }
                }

                if (lastAnsweredQuestion) {
                    nextQuestionModel = questions.find(e => e.questionId == lastAnsweredQuestion)?.questionModel
                    stepsCounter = stepsCounter === 3 ? stepsCounter : this.getNextStepCounter(symptomSelected?.questionSymptomMappingId)
                }
                else {
                    nextQuestionModel = questions[0]?.questionModel;
                }

                if (claim.claimStatusName === "Saved")
                    stepsCounter = claim.stepsCounter;
            }

            this.setState({
                nextQuestion,
                nextQuestionModel,
                questionSetMappingId,
                currentQuestionModel,
                currentQuestionSetMappingId,
                selectedSymptom,
                showHireOwnServier,
                fulfillmentTypeId,
                followUpChosen,
                stepsCounter,
                symptoms
            });
           
        }
        catch (error) {
            console.log("Error while pulling symptoms");
        }
    }

    //modeled after 
    getNextQuestion = (value, symptoms) => {
        let symptom = symptoms.find(({ questionSymptomMappingId }) => questionSymptomMappingId == value);
        let nextQuestionSetMapping = null;
        let {
            nextQuestion = 0,
            nextQuestionModel = null,
            questionSetMappingId,
            currentQuestionModel,
            currentQuestionSetMappingId
        } = this.state;

        if (symptom) {
            let questionSetMapping = symptom.questionSet?.questionSetMappingModel;

            if (questionSetMapping) {
                nextQuestionSetMapping = Array.isArray(questionSetMapping) && questionSetMapping.length > 0
                    ? questionSetMapping.find((questionSetMapping) => questionSetMapping.sequenceNumber > this.state.nextQuestion)
                    : questionSetMapping.sequenceNumber > this.state.nextQuestion ? questionSetMapping : null;

                if (nextQuestionSetMapping) {
                    nextQuestion = nextQuestionSetMapping.sequenceNumber;
                    nextQuestionModel = nextQuestionSetMapping.questionModel;
                    questionSetMappingId = nextQuestionSetMapping.questionSetMappingId;
                    currentQuestionModel = nextQuestionSetMapping.questionModel;
                    currentQuestionSetMappingId = nextQuestionSetMapping.questionSetMappingId;
                }
            }
        }

        this.setState({
            nextQuestion,
            nextQuestionModel,
            questionSetMappingId,
            currentQuestionModel,
            currentQuestionSetMappingId
        });

        // need to return this for an immediate update.
        return nextQuestionSetMapping;
    }

    getNextStepCounter = (value) => {
        let stepCounter = 5;
        let symptom = this.state.symptoms.find(({ questionSymptomMappingId }) => questionSymptomMappingId == value);

        if (typeof symptom !== "undefined" && symptom != null) {
            let questionSetMapping = symptom.questionSet?.questionSetMappingModel;

            if (typeof questionSetMapping !== "undefined" && questionSetMapping != null) {
                let nextQuestionSetMapping = Array.isArray(questionSetMapping) && questionSetMapping.length > 0
                    ? questionSetMapping.find((questionSetMapping) => questionSetMapping.sequenceNumber > this.state.nextQuestion)
                    : questionSetMapping.sequenceNumber > this.state.nextQuestion ? questionSetMapping : null;
                if (nextQuestionSetMapping !== null && nextQuestionSetMapping !== undefined) {
                    if (nextQuestionSetMapping?.questionModel?.questionName.toUpperCase() === "PRIORITYSERVICE") {
                        return stepCounter = 4;
                    }
                    else if (nextQuestionSetMapping?.questionModel?.questionName.toUpperCase() === "TROUBLESHOOTING") {
                        return stepCounter = 3;
                    }
                    else {
                        return stepCounter = 2;
                    }

                }
            }

        }
        return stepCounter;

    }


    handleCloseWarrantyOfWorkModal = () => {
        this.setState({
            showWarrantyOfWorkModal: false,
        })
    }

    handleWarrantyOfWork = () => {
        this.setState({ showSpinnerGridButton: true })
        //this.handleCloseWarrantyOfWork()

        if (this.state.claim.claimId > 0) {
            var featureClaimId = this.state.claim?.featureClaimModel[0]?.featureClaimId;
            try {

                axios.post(`api/claim/CreateWowEvent/${this.state.claimId}/${featureClaimId}/${this.state.clientId}`)
                    .then((res) => {
                        if (res.data !== "undefined" && res.data !== null) {
                            if (res.data.eventNumber > 0) {

                                this.deleteClaim(this.state.claimId, this.state.clientId);
                                this.setState({
                                    eventNumber: res.data.eventNumber,
                                    eventTypeId: res.data.eventTypeId,
                                    eventId: res.data.eventId
                                });

                                axios.post(`/api/dispatchservice/forceBookWOWappointments/${res.data.featureClaimId}/${this.state?.contractAssetId}/${res.data.eventId}/${this.state.claim.claimId}/${this.state.clientId}`)
                                    .then((response) => {
                                        if (response.data !== "undefined" && response.data !== null) {
                                            const servicerToBook = {
                                                "servicerId": response.data?.servicerId,
                                                "name": response.data?.servicerName,
                                                "phoneNumber": response.data?.servicerPhone
                                            }
                                            const appointmentToBook = {
                                                "date": response.data?.appointmentDate,
                                                "appointmentStartTime": response.data?.appointmentStartTime,
                                                "appointmentEndTime": response.data?.appointmentEndTime
                                            }
                                            const Type4body = {
                                                claimId: this.state.claim.claimId,
                                                alternativeType: "recommend",
                                                servicerName: response.data?.servicerName,
                                                servicerPhone: response.data?.servicerPhone,
                                                specialInstructions: "",
                                                eventNumber: this.state.eventNumber
                                            }


                                            //const EmailInfo = {
                                            //    "ClientId": this.props?.location?.state?.entitlementDetails?.clientId,
                                            //    "EmailAddress": this.props?.location?.state?.entitlementDetails?.party?.partyEmailModel[0].address,
                                            //    "EmailType": 0,
                                            //    "FirstName": this.props?.location?.state?.entitlementDetails?.party?.firstName,
                                            //    "LastName": this.props?.location?.state?.entitlementDetails?.party?.lastName,
                                            //    "ClaimNumber": this.props?.location?.state?.claim?.claimNumber,
                                            //    "ContractNumber": this.props?.location?.state?.entitlementDetails?.contract?.contractNumber,
                                            //    "AppointmentDate": moment(response?.data?.appointmentDate).format('dddd MMMM DD, YYYY'),
                                            //    "ArrivingBetween": response.data.appointmentStartTime,
                                            //    "AuthorizedServiceProvider": response?.data?.servicerName,
                                            //    "AuthorizedServiceProviderContact": response?.data?.servicerPhone


                                            //}
                                            if (response.data?.screenType === "type1" && this.state.eventTypeId === 5 && this.state.eventNumber !== null) {
                                                
                                                this.setState({
                                                    entitlementDetails: this.props.location?.state?.entitlementDetails,
                                                    servicerToBook: servicerToBook,
                                                    claimId: this.state.claim?.claimId,
                                                    claimNumber: this.state.claim.claimNumber

                                                });
                                                this.props.history.push({
                                                    pathname: '/appointment',
                                                    appointmentProps: this.state,
                                                    preferredPhone: response.data?.servicerPhone,
                                                    isWarrantyOfWork: true,
                                                    eventId: res.data.eventId
                                                })

                                            }
                                            else if (response.data?.screenType === "type2-3" && this.state.eventTypeId === 5 && this.state.eventNumber !== null) {
                                                this.pushHistory(paths.fileClaimConfirmation, { statusType: 'type2-3', servicerToBook: servicerToBook, isWarrantyOfWork: true, eventId: res.data.eventId });
                                            }
                                            else if (response.data?.screenType === "type4" && this.state.eventTypeId === 5 && this.state.eventNumber !== null) {
                                                this.pushHistory(paths.fileClaimConfirmation, { statusType: 'type4', appointmentProps: Type4body, isWarrantyOfWork: true, eventId: res.data.eventId });
                                            }
                                            else {
                                                this.setState({
                                                    showWarrantyOfWorkModal: false,
                                                    showSpinnerGridButton: false
                                                });
                                            }

                                        }
                                    });
                            }
                        }
                        else {
                            console.log("Error while creating Wow event");
                        }
                    });

            } catch (error) {
                console.log('error:fetch service Fee for claim:', error)
            }
        }
    }
    sendWowServiceAppointmentEmail =  (EmailInfo) => {
        axios.post(`api/communication/${EmailInfo.ClientId}`,
            {
                emailModel: {

                    Recipients: [EmailInfo?.EmailAddress],
                    Cc: [],
                    Bcc: [],
                    IsBodyHtml: true,
                    emailType: EmailInfo?.EmailType // Int
                },

                serviceModel: {
                    CustomerName: EmailInfo?.FirstName + ' ' + EmailInfo?.LastName,
                    ClaimNumber: EmailInfo?.ClaimNumber,
                    ContractNumber: EmailInfo?.ContractNumber,
                    AppointmentDate: EmailInfo?.AppointmentDate,
                    AppointmentWindow: EmailInfo?.ArrivingBetween,
                    Servicer: EmailInfo?.AuthorizedServiceProvider,
                    ServicerPhone: EmailInfo?.AuthorizedServiceProviderContact,
                    WarrantyObjectTypeId: 5

                }
            }
        ).then((emailResponse) => {
            console.debug(emailResponse);

        });

    }
    handleContinue = (commonData) => {

        if (this.state.isSymptomRepeated && !this.state.showWarrantyOfWorkModal) {
            this.setState({ showWarrantyOfWorkModal: true })
            return
        }

        if (this.state.isSymptomRepeated && this.state.showWarrantyOfWorkModal) {
            this.handleWarrantyOfWork()
            return
        }

        const {
            selectedSymptom,
            incidentDate,
            incidentDescription,
            affirmationCheck,
            stepsCounter,
            followUpChosen,
            troubleShootingSelected,
            surgeTermsSelected,
            currentQuestionModel,
            nextQuestionModel,
            showHireOwnServier,
            clientId,
            currentQuestionSetMappingId
        } = this.state;
        const claimId = this.props.match.params.claimId;
        const followUpSteps = [2, 4]

        if (selectedSymptom && stepsCounter === 1) {
            const claimId = this.props.match.params.claimId;
            updateSymptomClaim(this.state.clientId, claimId, selectedSymptom.questionSymptomMappingId);
        }
        if (!selectedSymptom && stepsCounter === 1) {
            this.setState({ showSymptomError: true })
            return;
        }

        if (!followUpChosen && followUpSteps.includes(stepsCounter)) {
            this.setState({ showFollowUpError: true })
            return;
        }
        //SEU-4359,Addded new condition to PriorityService error message 
        if (followUpChosen == "2" && stepsCounter === 4) {
            this.setState({ showFollowUpError: true })
            return;
        }
        //SEU-4359,SEU-226 Addded new condition to PriorityService
        if (((followUpChosen === "22" || followUpChosen === "1") && stepsCounter === 4) && followUpSteps.includes(stepsCounter)) {
            this.setState({ showFollowUpError: true })
            return;
        }
        if (!troubleShootingSelected && stepsCounter === 3) {
            this.setState({ showTroubleShootingError: true })
            return;
        }
        else if (troubleShootingSelected && stepsCounter === 3) {
            fetch(`api/claim/UpdateTroubleshooting/${this.state.claimId}/true/${this.state.clientId}`, {
                method: 'put',
                headers: {
                    "Content-Type": "application/json",
                }
            });
            // don't care about response, do not want to interrupt flow.
        }

        if (!incidentDate && stepsCounter === 5) {
            this.setState({ showIncidentDateError: true })
            return;
        }

        if (stepsCounter === 6) {
            const desc = incidentDescription;
            const affirm = affirmationCheck;
            this.setState({
                showIncidentDescError: !desc && true,
                showAffirmationError: !affirm && true,
                spinning: (!desc || !affirm) ? false : true
            });
            if (!desc || !affirm) return

        }

        if (!affirmationCheck && stepsCounter === 6) {
            this.setState({
                showAffirmationError: true,
            })
            return;
        }

        if (troubleShootingSelected && nextQuestionModel?.questionName?.toUpperCase() === "TROUBLESHOOTING") {
            const answerId = troubleShootingSelected ? 2 : 1;
            const answerDesc = troubleShootingSelected ? "Yes" : "No";

            addAnswerCollection(clientId, parseInt(claimId), this.state.currentQuestionSetMappingId, answerDesc, answerId, nextQuestionModel?.questionId);
            this.getNextQuestion(selectedSymptom.questionSymptomMappingId, this.state.symptoms)
            let nextStep = this.getNextStepCounter(selectedSymptom.questionSymptomMappingId);
            let troubleShooting = troubleShootingSelected;

            if (stepsCounter == 5 && stepsCounter == nextStep) {
                nextStep = 6
                troubleShooting = false
            }

            this.setState(state => ({
                stepsCounter: nextStep,
                showFollowUpError: false,
                troubleShootingSelected: troubleShooting,
            }))

            return;
        }
        else if (followUpChosen && stepsCounter === 2 && nextQuestionModel?.questionName.toUpperCase() !== "PRIORITYSERVICE") {
            const answerId = this.getAnswerId(followUpChosen);
            const answerDesc = this.getAnswerDescription(followUpChosen);
            if (this.state.currentQuestionSetMappingId !== undefined && answerId !== "") {
                addAnswerCollection(this.state.clientId, parseInt(claimId), this.state.currentQuestionSetMappingId, answerDesc, answerId, nextQuestionModel?.questionId);
            }

            this.getNextQuestion(selectedSymptom.questionSymptomMappingId, this.state.symptoms)
            const nextstep = this.getNextStepCounter(selectedSymptom.questionSymptomMappingId);

            this.setState(state => ({
                stepsCounter: nextstep,
                showFollowUpError: false
            }))
            return;

        }
        else if (followUpChosen && stepsCounter === 4 && nextQuestionModel?.questionName.toUpperCase() === "PRIORITYSERVICE") {
            const nextstep = this.getNextStepCounter(selectedSymptom.questionSymptomMappingId);
            const emergencyFlag = followUpChosen == "Yes" ? true : false;
            
            this.setState(state => ({
                emergencyFlag: emergencyFlag,
                stepsCounter: nextstep,
                showFollowUpError: false
            }))

            return;
        }

        if (stepsCounter === 1 && selectedSymptom.questionSet?.questionSetMappingModel?.length > 0) {
            this.getNextQuestion(selectedSymptom.questionSymptomMappingId, this.state.symptoms)
            const nextstep = this.getNextStepCounter(selectedSymptom.questionSymptomMappingId);

            this.setState(state => ({
                stepsCounter: nextstep,
                showFollowUpError: false
            }))
            return;
        }
        else if (stepsCounter === 1 && !currentQuestionModel && !selectedSymptom.questionSet?.questionsetmapping?.length > 0) {
            this.setState(state => ({
                stepsCounter: 5,
                showFollowUpError: false
            }))
            return;
        }

        if (incidentDate && stepsCounter === 5) {
            let assetId = this.props.location?.state?.entitlementDetails?.assetId;
            if (coverageAsAssets.includes(assetId)) {
                let symptomId = this.props.location?.state?.entitlementDetails?.selectedSymptom;
                let symptom = this.state.symptoms.find(s => s.questionSymptomMappingId == symptomId)

                this.setState(state => ({
                    selectedSymptom: symptom
                }))
            }

            this.setState(state => ({
                stepsCounter: 6,
                showSymptomError: false,
                showIncidentDateError: false,
                showIncidentDescError: false,
                showAffirmationError: false
            }))
            return;
        }
        if (selectedSymptom && stepsCounter === 1) {
            this.setState(state => ({
                stepsCounter: 5,
                showSymptomError: false,
                showIncidentDateError: false,
                showIncidentDescError: false,
                showAffirmationError: false
            }))
            return;
        }

        if (incidentDescription && stepsCounter === 6 && !showHireOwnServier) {
            this.handleRules()
            return
        }

        if (incidentDescription && stepsCounter === 6 && showHireOwnServier){

            const { claimId, contractId, assetId, coverageId } = this.props.match.params;
            const { clientId, incidentDescription, incidentDate,
                fulfillmentTypeId, affirmationCheck, emergencyFlag
            } = this.state;

            const asset = this.getAssetForGtmAnalytics();

            const symptomId = this.state.symptoms.find(s => s.assetId == asset.assetId && s.coverageId == coverageId)?.questionSymptomMappingId;

            let passed = 0;

            fetch(`api/claim/UpdateSymptom/${claimId}/${clientId}/${symptomId}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(claimRes => {
                if (claimRes.ok) {
                    claimRes.json().then(cdata => {
                        fetch('api/claim/featureclaim/'.concat(parseInt(claimId)), {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                claimId: parseInt(claimId),
                                coverageId: parseInt(coverageId),
                                incidentDescription: incidentDescription,
                                incidentDate: incidentDate,
                                clientId: clientId,
                                contractAssetId: parseInt(assetId),
                                EventTypeId: fulfillmentTypeId,
                                affirmed: affirmationCheck,
                                emergencyFlag: emergencyFlag ?? null
                            })
                        }).then(res => {
                            if (res.ok) {
                                res.json().then(data => {
                                    let eventModel = data?.eventModel[0];
                                    this.setState({ result: data })
                                    let entitlementRuleSet = 1;
                                    let troubleshootingRuleSet = 2;
                                    let routing = false;
                                    let ruleEligible = false;
                                    /* TODO denial reason would be customer reimbursement (maybe)*/

                                    fetch('api/rulesEngine/ExecuteRules', {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            rulesEngineExecutionModel: {
                                                clientId: clientId,
                                                contractId: parseInt(contractId),
                                                claimId: parseInt(claimId),
                                            },
                                            rules: [entitlementRuleSet, troubleshootingRuleSet]
                                        })
                                    }).then(res => {
                                        if (res.ok) {
                                            res.json().then(rulesResultList => {

                                                const rulesResult = rulesResultList;
                                                ruleEligible = rulesResult[0].success;
                                                routing = (typeof rulesResult[1] == "undefined" || rulesResult[1] == null) ? true : rulesResult[1].success;
                                                // update event model with denial reason                                                
                                                //let failedRuleSet = rulesResult.find(r => r.success == false);
                                                //let failedRuleSet = rulesResult.filter(x => x.success == false).reduce((max, rule) => (rule.highestSequenceFailure > max ? rule.highestSequenceFailure : max));
                                                let failedSet = rulesResult?.filter(x => x.success == false);
                                                let failedRuleSet = failedSet == null || failedSet.length <= 0 ? null : failedSet.reduce((max, rule) => (rule.highestSequenceFailure > max ? rule.highestSequenceFailure : max));
                                                let failedRulesetDetailId = failedRuleSet?.highestSequenceFailure ?? 0;

                                                eventModel.denialReason = getDenialReason(failedRulesetDetailId);
                                                eventModel.eventStatusId = 7;
                                                passed = ruleEligible && routing ? 1 : 0;

                                                fetch(`api/claim/ClaimRouting/${passed}/${parseInt(claimId)}/${eventModel.denialReason}/${clientId}`, {
                                                    method: 'put',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(eventModel)
                                                });

                                                if (ruleEligible && routing) {
                                                    this.setState({
                                                        stepsCounter: 8,
                                                        isPaymentActive: false,
                                                        activeStep: 3,
                                                        spinning: false
                                                    })
                                                    return;
                                                }
                                                else {
                                                    fetch(`api/Contract/GetCoverageByCoverageId/${coverageId}/${clientId}`, {
                                                        method: 'get',
                                                        headers: { 'Content-Type': 'application/json' }
                                                    }).then(res => {
                                                        if (res.ok) {
                                                            res.json().then(coverageModel => {
                                                                let stateId = this.props.location?.state?.entitlementDetails?.contract?.party?.billingAddress?.stateId;
                                                                let planType = this.props.location?.state?.entitlementDetails?.contract?.riskProfile?.description;
                                                                var emailTypeIdNew = this.state?.clientId === 'et' ? 169 : 33;
                                                                var insuranceCompanyName = "";
                                                                var insuranceCompanyAddress = "";

                                                                let contractAssetModelObj = this.props?.location?.state?.entitlementDetails?.contract?.contractAssetModel?.find(ca => ca.contractAssetId == this.props.match.params.assetId);
                                                                let assetModel = this.props?.location?.state?.entitlementDetails?.contract?.assets.find(ai => ai.assetId == contractAssetModelObj?.assetId);
                                                                const deniedEmailInfo = {
                                                                    "emailTypeId": emailTypeIdNew,
                                                                    "clientId": clientId,
                                                                    "claimNumber": this.props?.location?.state?.claim?.claimNumber,
                                                                    "clientContractNumber": this.props?.location?.state?.entitlementDetails?.contract?.clientContractNumber,
                                                                    "denialReason": eventModel.denialReason,
                                                                    "assetName": coverageModel?.coverageName || asset?.assetName,
                                                                    "claimSetupDate": moment(this.props?.location?.state?.claim?.claimSetupDate).format("MM/DD/YYYY"),
                                                                    "consumerName": this.props?.location?.state?.entitlementDetails?.party?.firstName + ' ' + this.props?.location?.state?.entitlementDetails?.party?.lastName,
                                                                    "firstName": this.props?.location?.state?.entitlementDetails?.party?.firstName,
                                                                    "insuranceCompanyName": insuranceCompanyName,
                                                                    "insuranceCompanyAddress": insuranceCompanyAddress,
                                                                    "ContractNumber": this.props?.location?.state?.entitlementDetails?.contract?.contractNumber,

                                                                }
                                                                this.getDefaultEmailCommunication(deniedEmailInfo);
                                                            })
                                                        }
                                                    })                                                    
                                                }
                                            })
                                        }
                                    })
                                })
                            }
                        })
                    })
                }
            })
        }

        if (stepsCounter === 7 || this.state.isPaymentActive) {
            this.setState(state => ({
                stepsCounter: 8,
                showSymptomError: false,
                showIncidentDateError: false,
                showIncidentDescError: false,
                showAffirmationError: false,
                isPaymentActive: false,
                activeStep: 3,
                billingAddress: commonData.billingAddress,
                paymentSuccessful: commonData?.paymentSuccessful ? commonData?.paymentSuccessful : this.state.paymentSuccessful
            }))
            return;
        }
        if (!surgeTermsSelected && stepsCounter === 9) {
            this.setState({ showSurgeTermsError: true })
            return;
        }

        if (this.state.clientId === 'et' && stepsCounter === 8 && showHireOwnServier) {
            this.setState({
                stepsCounter: 9,
                isPaymentActive: false,
                activeStep: 3,
                spinning: false
            })
            return;
        }

        if (stepsCounter === 8) {
            this.setState({ stepsCounter: 10 })
            return;
        }

        if (stepsCounter === 9) {
            const params = this.props?.match?.params;
            const claim = this.props.location?.state?.claim;

            updateClaimStatus(clientId, claim?.claimId, 1)
                .then((data) => {
                    const emailAddress = this.props?.location?.state?.entitlementDetails?.party?.partyEmailModel[0]?.address;
                    this.pushHistory(paths.fileClaimConfirmation, { statusType: 'type4', fulfillmentTypeId: 3, emailAddress });
                })

        }
    }

    deleteClaim = async (claimId, clientId) => {
        try {
            await fetch('api/claim/DeleteClaimsById/'.concat(claimId).concat('/').concat(clientId),
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }

                }).then(response => {
                    if (response.ok) {
                        console.log(response.ok);
                    }

                });


        } catch (error) {
            console.log("Error while deleting claims");
        }
    }
    handleSelectIncidentDate = (date) => {
        this.setState({ incidentDate: date, showIncidentDateError: false })
    }

    handleIncidentDescriptionChange = (e) => {
        const val = e.target.value
        this.setState({ incidentDescription: val, showIncidentDescError: false })
    }

    handleAffirmationCheck = (e) => {
        this.setState({
            affirmationCheck: e.target.checked,
            showAffirmationError: false
        })
    }

    handleFollowUpChosen = (val) => {
        let { emergencyFlag, stepsCounter, selectedSymptom } = this.state;

        if (stepsCounter == 4 && selectedSymptom?.priorityService) {
            emergencyFlag = val.toLowerCase() == "yes" ? 1: 0
        }

        this.setState({
            followUpChosen: val,
            showFollowUpError: false,
            emergencyFlag
        })
    }

    handleTroubleShootingSelected = (checked) => {
        this.setState({ troubleShootingSelected: checked, showTroubleShootingError: false })
    }

    handleSurgeAffirmationSelected = (checked) => {
        this.setState({ surgeTermsSelected: checked, showSurgeTermsError: false })
    }

    getAnswerDescription = (followUpChosen) => {
        if (followUpChosen === "Yes" || followUpChosen === "2") {
            return "Yes";
        }
        else if (followUpChosen === "22") {
            return 'I\'m not sure';;
        }
        else {
            return "No";
        }
        return '';

    }

    getAnswerId = (followUpChosen) => {
        if (followUpChosen === "Yes" || followUpChosen === "2") {
            return 2;
        }
        else if (followUpChosen === "22") {
            return 22;
        }
        else {
            return 1;
        }
        return '';
    }

    handleRules = async () => {
        const { claimId, contractId, assetId, coverageId } = this.props.match.params;
        const { clientId, incidentDescription, incidentDate,
            fulfillmentTypeId, affirmationCheck, emergencyFlag,
            selectedSymptom: {
                questionSymptomMappingId }
        } = this.state;

        let routing = false;
        let ruleEligible = false;

        const result = await fetch(`api/troubleshooting/GetSymptomsbyId/${parseInt(questionSymptomMappingId)}/${clientId}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        });

        if (result.ok) {
            const routingResult = await result.json();
        }

        fetch('api/claim/featureclaim/'.concat(parseInt(claimId)), {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                claimId: parseInt(claimId),
                coverageId: parseInt(coverageId),
                incidentDescription: incidentDescription,
                incidentDate: incidentDate,
                clientId: clientId,
                contractAssetId: parseInt(assetId),
                EventTypeId: fulfillmentTypeId,
                affirmed: affirmationCheck,
                emergencyFlag: emergencyFlag ?? null
            })
        }).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    this.setState({ result: data })
                    let entitlementRuleSet = clientId == 'hwa' ? 3 : 1;
                    let troubleshootingRuleSet = clientId == 'hwa' ? 4 : 2;
                    let eventModel = data?.eventModel[0];

                    fetch('api/rulesEngine/ExecuteRules', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            rulesEngineExecutionModel: {
                                clientId: clientId,
                                contractId: parseInt(contractId),
                                claimId: parseInt(claimId),
                            },
                            rules: [entitlementRuleSet, troubleshootingRuleSet]
                        })
                    }).then(res => {
                        if (res.ok) {
                            res.json().then(rulesResultList => {
                                const rulesResult = rulesResultList;
                                ruleEligible = rulesResult[0]?.success;
                                routing = (typeof rulesResult[1] == "undefined" || rulesResult[1] == null) ? true : rulesResult[1].success;
                                // update event model with denial reason                                                
                                //let failedRuleSet = rulesResult.find(r => r.success == false);
                                //let failedRuleSet = rulesResult.filter(x => x.success == false).reduce((max, rule) => (rule.highestSequenceFailure > max ? rule.highestSequenceFailure : max));
                                let failedSet = rulesResult?.filter(x => x.success == false);
                                let failedRuleSet = failedSet == null || failedSet.length <= 0 ? null : failedSet.reduce((max, rule) => (rule.highestSequenceFailure > max ? rule.highestSequenceFailure : max));
                                let failedRulesetDetailId = failedRuleSet?.highestSequenceFailure ?? 0;

                                eventModel.denialReason = getDenialReason(failedRulesetDetailId);
                                eventModel.eventStatusId = 7;
                                let passed = ruleEligible && routing ? 1 : 0;
                                fetch(`api/claim/ClaimRouting/${passed}/${parseInt(claimId)}/${eventModel.denialReason}/${clientId}`, {
                                    method: 'put',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(eventModel)
                                });

                                if (ruleEligible && routing) {
                                    // SEU-242 fulfillment/routing
                                    this.setState({
                                        stepsCounter: 7,
                                        isPaymentActive: true,
                                        activeStep: 2,
                                    })
                                }
                                else {
                                    let stateId = this.props.location?.state?.entitlementDetails?.contract?.party?.billingAddress?.stateId;
                                    let planType = this.props.location?.state?.entitlementDetails?.contract?.riskProfile?.description;
                                    var emailTypeIdNew = this.state?.clientId === 'et' ? 169 : 33;
                                    var insuranceCompanyName = "";
                                    var insuranceCompanyAddress = "";

                                    if (stateId == 22 || stateId == 9 && this.state.clientId == 'hwa') {
                                        if (stateId == 9) {//State California
                                            insuranceCompanyName = "New Hampshire Insurance Company";
                                            insuranceCompanyAddress = "New Hampshire Insurance Company Administrative Offices 1271 Ave of the Americas Floor 37, New York, NY 10020 - 1304";
                                        }
                                        else {
                                            insuranceCompanyName = "Illinois National Insurance Company";
                                            insuranceCompanyAddress = "Illinois National Insurance Company Administrative Offices 1271 Ave of the Americas Floor 37, New York, NY 10020 - 1304";
                                        }
                                        if (eventModel?.denialReason == "This product is not eligible for service due to the plan’s claim limit already being met.") {
                                            if (planType.includes("HWA Real Estate")) {
                                                emailTypeIdNew = 97;
                                            }
                                            else if (planType.includes("HWA DTC")) {
                                                emailTypeIdNew = 96;
                                            }
                                        }
                                        else if (eventModel?.denialReason == "Incident must be a covered issue. This incident is not eligible for service due to not being covered under your current plan") {
                                            if (planType.includes("HWA Real Estate")) {
                                                emailTypeIdNew = 99;
                                            }
                                            else if (planType.includes("HWA DTC")) {
                                                emailTypeIdNew = 98;
                                            }
                                        }
                                        else if (eventModel?.denialReason == "This product is not yet eligible for service due to the product currently being covered by the manufacturer’s warranty. The plan’s coverage will begin after the manufacturer’s warranty expires. Please contact the manufacturer to service your product.") {
                                            if (planType.includes("HWA Real Estate")) {
                                                emailTypeIdNew = 101;
                                            }
                                            else if (planType.includes("HWA DTC")) {
                                                emailTypeIdNew = 100;
                                            }
                                        }
                                    }

                                    let contractAssetModelObj = this.props?.location?.state?.entitlementDetails?.contract?.contractAssetModel?.find(ca => ca.contractAssetId == this.props.match.params.assetId);
                                    let assetModel = this.props?.location?.state?.entitlementDetails?.contract?.assets.find(ai => ai.assetId == contractAssetModelObj?.assetId);
                                    const deniedEmailInfo = {
                                        "emailTypeId": emailTypeIdNew,
                                        "clientId": clientId,
                                        "claimNumber": this.props?.location?.state?.claim?.claimNumber,
                                        "clientContractNumber": this.props?.location?.state?.entitlementDetails?.contract?.clientContractNumber,
                                        "denialReason": eventModel.denialReason,
                                        "assetName": assetModel?.assetName,
                                        "claimSetupDate": moment(this.props?.location?.state?.claim?.claimSetupDate).format("MM/DD/YYYY"),
                                        "consumerName": this.props?.location?.state?.entitlementDetails?.party?.firstName + ' ' + this.props?.location?.state?.entitlementDetails?.party?.lastName,
                                        "firstName": this.props?.location?.state?.entitlementDetails?.party?.firstName,
                                        "insuranceCompanyName": insuranceCompanyName,
                                        "insuranceCompanyAddress": insuranceCompanyAddress,
                                        "ContractNumber": this.props?.location?.state?.entitlementDetails?.contract?.contractNumber,

                                    }
                                    this.getDefaultEmailCommunication(deniedEmailInfo);
                                }
                            });
                        }
                    })
                })
            }
        })
    }

    getDefaultEmailCommunication = async (deniedEmailInfo) => {
        axios
            .get(`api/claim/GetDefaultClaimEmail/${this.props.match.params.claimId}/${deniedEmailInfo?.ContractNumber}/${this.state.clientId}`)
            .then((defaultEmail) => {
                console.debug(defaultEmail);
                var emailModel = defaultEmail.data.emailModel;
                var serviceModel = defaultEmail.data.serviceModel;
                emailModel.EmailType = deniedEmailInfo?.emailTypeId;
                serviceModel.ClientId = this.state.clientId;
                serviceModel.ClaimNumber = deniedEmailInfo?.claimNumber;
                serviceModel.ConsumerName = deniedEmailInfo?.consumerName;
                serviceModel.FirstName = deniedEmailInfo?.firstName;
                serviceModel.ClientContractNumber = deniedEmailInfo?.clientContractNumber;
                serviceModel.DenialReason = deniedEmailInfo?.denialReason;
                serviceModel.AssetName = deniedEmailInfo?.assetName;
                serviceModel.ClaimSetupDate = defaultEmail?.claimSetupDate;
                serviceModel.InsuranceCompanyName = deniedEmailInfo.insuranceCompanyName;
                serviceModel.InsuranceCompanyAddress = deniedEmailInfo.insuranceCompanyAddress;
                serviceModel.ContractNumber = deniedEmailInfo.ContractNumber;
                serviceModel.WarrantyObjectTypeId = 2;
                this.setState({
                    isShowEmailConfirmation: true,
                    spinning: false,
                    emailModel: defaultEmail.data.emailModel,
                    serviceModel: defaultEmail.data.serviceModel,
                });
            });
    }

    toggleCancelModal = (action) => {
        switch(action) {
            case "OPEN":
                this.setState({showCancelModal: true})
                return
            case "CLOSE":
                this.setState({showCancelModal: false})
                return
            default: return
        }
    }
    
    handleCancel = () => {
        cancelClaim(this.state.clientId, this.state.claimId);
        this.props.history.push('/fileclaim/confirmation', { statusType: 'cancel', claimNumber: this.props.location.state.claim.claimNumber, clientId: this.state.clientId, claimId: parseInt(this.state.claimId), contractAssetId: this.props?.match?.params?.assetId, isEmailSent: true });
    }

    handleCancelModal = () => {
        this.props.resumeClaim();
        this.toggleCancelModal("CLOSE")
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
        if (!this.state.cancelClaimOnLogoClick) {
            document.getElementById('later-id').focus()
        }
    }

    onCloseEmailModal = (e) => {
        this.setState({ isShowEmailConfirmation: false });
    };

    onContinueEmailConfirmationModal = (email) => {
        this.setState({
            emailModel: {
                ...this.state.emailModel,
                recipients: [email],
            },
            isPaymentActive: false,
            activeStep: 2,
            continuing: true,
        });

        /*serviceModel = deniedEmailInfo;*/
        fetch(`api/communication/UpdateEmailAddress/${this.state.clientId}/${this.props.location.state.entitlementDetails?.contract?.party?.partyId}/${email}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resData => {
                axios.post(`api/communication/${this.state.clientId}`, {
                    emailModel: { ...this.state.emailModel, recipients: [email] },
                    serviceModel: this.state.serviceModel,
                })
                    .then((emailResponse) => {
                        this.setState({ continuing: false, isShowEmailConfirmation: false })
                        this.pushHistory(paths.fileClaimConfirmation, { statusType: "denied", rulesetDetailId: "1" })
                    });
            });
        

    };

    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        let claim = this.props.history.location.state.claim;

        claim.symptom = this.state?.selectedSymptom?.questionSymptomMappingId?.toString() ?? null;
        claim.lossDate = this.state?.incidentDate == null ? null : moment(this.state.incidentDate).format('L');
        claim.incidentDescription = this?.state.incidentDescription ?? null;
        claim.emergencyFlag = this.state?.emergencyFlag ?? null;
        claim.affirmationFlag = this.state?.affirmationCheck ?? null;

        this.setState({ isSaveForLater: true, abandonClaim: true }, () => { this.saveForLaterAction(claim, false) });
    }




    clearRadioButtons = () => {
        var inputs = document.getElementsByTagName('input');
        for (var i = inputs.length - 1; i >= 0; i--) {
            if (inputs[i].getAttribute('type') === 'radio')
                inputs[i].checked = false;
        }
    }

    pushHistory = (path, pertinentData) => {
        pushHistory(this.state, path, pertinentData, this.props.history);
    }


    render() {
        const {
            stepsCounter,
            showSymptomError,
            showIncidentDateError,
            showIncidentDescError,
            incidentDescription,
            affirmationCheck,
            showAffirmationError,
            showFollowUpError,
            showTroubleShootingError,
            showSurgeTermsError,
            activeStep,
            isPaymentActive,
            result
        } = this.state;

        const clientTextValues = getClientContent(this.context.data.clientId);
        const title = clientTextValues.variableText.pageTitle;
        const pageTitle = title ? `File Claim | ${title}` : 'File Claim';

        let entitlementDetails = this.props?.location?.state?.entitlementDetails;

        let claim = this.props.location.claim;
        let eventModel = result?.eventModel[0];

        if (typeof claim == 'undefined') {
            claim = this.props.location?.state?.claim;
        }

        if (typeof entitlementDetails == 'undefined') {
            let party = this.props.location?.claim?.partyModel;
            let serviceAddress = party?.partyAddressModel?.find(pa => pa.addressTypeId == 2);
            entitlementDetails = {
                deductible: this.props.location?.claim?.tcf,
                clientId: this.state.clientId,
                claimNumber: this.props.location?.claim?.claimNumber,
                caseNumber: null,
                party: party,
                address1: serviceAddress?.address1,
                address2: serviceAddress?.address2,
                city: serviceAddress?.city,
                state: serviceAddress?.state,
                stateId: serviceAddress?.stateId,
                zip: serviceAddress?.zip,
                contract: claim.contract,
                contractAsset: this.props.location?.claim?.contract?.contractAssetModel?.find(ca => ca.contractAssetId == this.props.location?.claim?.contractAssetId),
            }
        }

        if (isPaymentActive) {
            dataLayerPush('/payment', 'Payment')
            return <div>
                <StepsHeader active={activeStep} fulfillmentTypeId={this.state.fulfillmentTypeId}>
                    <Container>
                        <section className="formBody">
                            <Payment history={this.props.history} {...this.props.match.params} {...this.props.location.state} nextActiveStep={this.handleContinue} entitlementDetails={entitlementDetails} fulfillmentTypeId={this.state.fulfillmentTypeId} />
                        </section>
                    </Container>
                </StepsHeader>
            </div>
        }
        if (stepsCounter == 8) {
            dataLayerPush('/verifyInfo', 'Verify Information')
            return <div>
                <StepsHeader active={activeStep} fulfillmentTypeId={this.state.fulfillmentTypeId}>
                    <Container>
                        <section className="formBody">
                            <VerifyInfo history={this.props.history} {...this.state} {...this.props} {...this.props.match.params} {...this.props.location.state} claim={claim} billingAddress={this.state.billingAddress} nextActiveStep={this.handleContinue} fulfillmentTypeId={this.state.fulfillmentTypeId} entitlementDetails={entitlementDetails} eventId={eventModel.eventId} />
                        </section>
                    </Container>
                </StepsHeader>
            </div>
        }

        if (stepsCounter == 10) {
            dataLayerPush('/confirmation', 'Confirmation')
            return <div>
                <StepsHeader active={activeStep} fulfillmentTypeId={this.state.fulfillmentTypeId}>
                    <Container>
                        <section className="formBody">
                            <Confirmation history={this.props.history} {...this.props} {...this.props.match.params} {...this.props.location.state} nextActiveStep={this.handleContinue} fulfillmentTypeId={this.state.fulfillmentTypeId} />
                        </section>
                    </Container>
                </StepsHeader>
            </div>
        }

		return (
			<DocumentTitle title={pageTitle}>
				<div>
					<StepsHeader active={activeStep} fulfillmentTypeId={this.state.fulfillmentTypeId}>
						<Container>
							<section className="formBody">

								{stepsCounter == 1 &&
									<Symptoms
										symptoms={this.state.symptoms}
										symptomSelected={(sel) => this.handleSymptomSelected(sel)}
										showError={showSymptomError}
									/>
								}
								{stepsCounter == 2 && this.state.currentQuestionModel?.questionName.toUpperCase() !== "PRIORITYSERVICE" && !this.state.currentQuestionModel?.questionName.toUpperCase() !== "TROUBLESHOOTING" &&
									<FollowUp
										followUpChosen={this.handleFollowUpChosen}
										symptom={this.state.selectedSymptom}
										currentQuestionModel={this.state.currentQuestionModel}
										symptoms={this.state.symptoms}
										showError={showFollowUpError}
									/>
								}
								{stepsCounter == 3 &&
									<Troubleshooting
										handleTroubleShootingSelected={this.handleTroubleShootingSelected}
										showError={showTroubleShootingError}
										questionSymptomMappingId={this.state?.selectedSymptom?.questionSymptomMappingId}
										symptom={this.state.selectedSymptom}
									/>
								}
								{stepsCounter == 4 &&
									<PriorityService followUpChosen={this.handleFollowUpChosen}
										showError={showFollowUpError}
									/>
								}
								{stepsCounter == 5 &&
									<IncidentDate
										handleSelectIncidentDate={this.handleSelectIncidentDate}
										showError={showIncidentDateError}
									/>
								}
								{stepsCounter == 6 &&
									<IncidentDescription
										affirmationCheck={affirmationCheck}
										handleAffirmationCheck={this.handleAffirmationCheck}
										showAffirmationError={showAffirmationError}
										incidentDescription={incidentDescription}
										handleIncidentDescriptionChange={this.handleIncidentDescriptionChange}
										showDescError={showIncidentDescError}
									/>
								}

								{stepsCounter == 7 &&
									<Payment />
								}
								{stepsCounter == 8 &&
									<VerifyInfo history={this.props.history} {...this.props.match.params} {...this.props.location.state} {...this.state.showHireOwnServier} />
								}
								{stepsCounter === 9 &&
									<SurgeClaimHireOwnServicer
										affirmationChange={this.handleSurgeAffirmationSelected}
										showError={showSurgeTermsError}
										clientId={this.state.clientId}
									/>
								}
								<Row className="stepsFormBlock m-0">
									<div className={styles.btnWrapper}>
										<div>
											<FooterButtonsRow
												isModal
												buttons={{
													primary: {
														text: 'Continue',
														type: 'primary',
														onClick: this.handleContinue,
														ariaLabel: 'Continue',
														hasSpinner: true,
														isSpinning: this.state.spinning,
													},
													secondary: {
														text: 'Cancel',
														type: 'secondary',
														onClick: () => this.toggleCancelModal('OPEN'),
														ariaLabel: 'Click to cancel claim setup',
													},
												}}
											/>
										</div>
										<CancelModal
											isOpen={this.state.showCancelModal}
											claimNumber={this.state.claimNumber}
											caseNumber={this.state.caseNumber}
											onSaveForLaterAction={this.onSaveForLaterAction}
											handleCancel={this.handleCancel}
											onCloseModal={this.handleCancelModal}
										/>
									</div>
								</Row>
								<Row className="mt-3">
									<button className="btnLink" id="later-id" onClick={this.onSaveForLaterAction} tabIndex="0" aria-label="Save for later to save your claim">Save For Later</button>
									{this.state.showConfirmation ?
										<SaveModal
											buttonClassName={classNames("btnLink")}
											aria-label="Save for later to save your claim"
											showConfirmation={this.state.showConfirmation}
											history={this.props.history}
											continuing={this.state.isSaveForLater}
											onCancel={this.onSaveCancel}
											onSaveForLaterAction={this.saveForLater}
											client={this.state.clientId} />
										: null}
								</Row>
								<Row>
									{this.state.isShowEmailConfirmation ? (
										<EmailAddressModal
											buttonClassName={classNames("btnLink")}
											aria-label="Opens a modal to save your claim"
											history={this.props.history}
											onCloseModal={this.onCloseEmailModal}
											onContinue={this.onContinueEmailConfirmationModal}
											client={this.state.clientId}
											emailModel={this.state.emailModel}
											continuing={this.state.continuing}
										/>
									) : null}
								</Row>
							</section>
						</Container>
					</StepsHeader>
					<WarrantyOfWorkModal
						isOpen={this.state.isSymptomRepeated && this.state.showWarrantyOfWorkModal}
						onCloseModal={this.handleCloseWarrantyOfWorkModal}
                        onContinue={this.handleContinue}
                        deductible={this.props.location?.state?.entitlementDetails?.deductible}
						clientId={this.state.clientId}
						progressContinue={this.state.showSpinnerGridButton}
					/>
				</div>
			</DocumentTitle>
		);
	}
}
Entitlement.contextType = ContractContext