import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Cleave from 'cleave.js/react';
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import useTranslation from '../../contexts/LanguageTranslation/useTranslation';
import StandardInput from "../../shared/StandardInput/StandardInput";

export const AddressForm = ({
    address,
    states,
    handleInputChange,
    handleStateDropDown,
    validator,
    validationFired
}) => {
    let {
        address1, address2,
        city, state, stateId, zip,
    } = address

    const international = useTranslation('components/Vendor/ET/CustomerAddressForm');

    const ZipValidator = () => {
        return validator.message("zip", zip, "required|numeric|size:5", {
            messages: {
                required: "Zip code is required.",
                size: "Zip code is required.",
            }
        })
    }

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null;

    return (
        <div id="addressFields">
            <StandardInput
                label={'Mailing Address'}
                hasError={!address1 && validationFired}
                fieldName="address1"
                ariaLabelAndFieldId={"address1"}
                value={address1}
                onChange={handleInputChange}
                validator={validator.message(
                    "address",
                    address1,
                    "required",
                    {
                        messages: {
                            required: 'Street address is required.'
                        }
                    }
                )}
            />
            <StandardInput
                notRequired
                label={'Apt., Suite, Building'}
                fieldName="address2"
                ariaLabelAndFieldId={"address2"}
                value={address2}
                onChange={handleInputChange}
            />
            <StandardInput
                label={'City'}
                hasError={!city && validationFired}
                fieldName="city"
                ariaLabelAndFieldId={"city"}
                value={city}
                onChange={handleInputChange}
                validator = {validator.message('city', city, "required", {
                    messages: {
                        required: 'City is required.'
                    }
                })}
            />
            <Row className='multiColWrapper'>
                <Col sm="6" >
                    <FormGroup className={!stateId && !states && validationFired && "form-error"}>
                        <Label htmlfor="state" aria-label="state" className="form-label">State</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <DropDownAutoComplete name="state"
                            id="state"
                            options={states}
                            className="form-control"
                            aria-required="true"
                            onChangedValue={handleStateDropDown}
                            isFloatingDropdown={false}
                            onBlur={handleStateDropDown}
                            defaultValue={(stateId === '' ? undefined : stateId)}
                            Value={stateId}
                        />
                        {validator.message("state", stateId, "required")}
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup className={validationFired && ZipValidator() && "form-error"}>
                        <Label for="zipCode" className="form-label">Zip</Label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <Cleave 
                            options={{ 
                                blocks: [5], 
                                numericOnly: true 
                            }} 
                            className="form-control" 
                            id="zipCode" 
                            name="zip" 
                            aria-required="true" 
                            onChange={handleInputChange} 
                            value={zip}
                        />
                        {ZipValidator()}
                    </FormGroup>
                </Col>                        
            </Row>
        </div>
    )
}

export const StaticAddress = ({
    addressData,
    countryCode,
    states,
    clientId
}) => {
    let country = countryCode;
    let transCountry = useTranslation().country

    if (!country) {
        country = transCountry
    }

    let {
        address1,
        address2,
        zip,
        city,
        state,
    } = addressData
    
    const [stateFullName, setStateFullName] = useState('');

    const selectedState = (stateData) => {
        const currentState =
            (stateData &&
                stateData.filter(
                    (st) =>
                        st?.abbreviation === state ||
                        st?.display === state ||
                        st?.data === addressData?.stateId,
                )) ||
            [];
        if (currentState.length) {
            setStateFullName(currentState[0]?.display);
        }
    }

    useEffect(() => {
        if (!states || !states.length) {
            fetchStates();
        } else {
            selectedState(states);
        }
      }, []);

    const fetchStates = async () => {
        await fetch("api/party/states/" + country + "/" + clientId)
          .then((res) => res.json())
          .then((data) => {
            let stateData = data.map((st) => {
                return {
                    data: st.stateId,
                    display: st.name,
                    abbreviation: st.abbreviation,
                };
            });
            selectedState(stateData);
        });
    }

    // RETURN NOTHING IF ALL VALUES ARE FALSY
    if (Object.values(addressData ? addressData : '').every(v => !v)) return null;

    return (
        <ul id="billingAddressStatic" className="noBullets pb-3" role="none">
            {address1 && <li>{address1}</li>}
            {address2 && <li>{address2}</li>}
            <li>{city && city + ', '}{stateFullName && stateFullName + ' '}{zip && zip}</li>
        </ul>
    )
}