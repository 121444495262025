export const openDocument = (warrantyObjectTypeId, warrantyObjectValue, docId, clientId) => {
    const request = {
        method: 'get',
        headers: { 'Content-Type': 'application/pdf' }
    }

    fetch(`api/document/GetDocumentById/${warrantyObjectTypeId}/${warrantyObjectValue}/${docId}/${clientId}`, request)
        .then(res => {
            if (res.status === 200) {
                return res.arrayBuffer();
            }
            else {
                return undefined;
            }
        })
        .then(data => {
            if (data != undefined) {
                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');

                link.href = fileURL;
                link.target = '_blank';
                link.click();
            }
        });
}

export const getCoverageDocument = async (clientId, riskProfileId, assetId, purchaseDate) => {
    const requestBody = {
        clientId,
        riskProfileId,
        assetId,
        purchaseDate
    }

    const warrantyObjectTypeId = 12;

    const request = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    }

    const response = await fetch('api/document/GetCoverageDocument', request)

    if (response.ok) {
        const data = await response.json();

        if (data != null) {
            openDocument(warrantyObjectTypeId, requestBody.riskProfileId, data.documentId, clientId);
        }
    }


}