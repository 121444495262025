import React, { useState, useRef, useContext, useEffect } from 'react';
import { Row, Col, Input, Label, FormGroup } from 'reactstrap';
import styles from './CustomerReimbursement.module.css';
import classNames from 'classnames';
import SimpleReactValidator from "simple-react-validator";
import ReactTooltip from 'react-tooltip';
import DragandDropFileUpload from '../../components/Vendor/Apple/UploadDocs/DragandDropFileUpload';
import { StaticAddress, AddressForm } from '../../components/Vendor/ET/CustomerAddressForm';
import SuggestedAddress from '../../components/modals/SuggestedAddress';
import InvalidAddress from '../../components/modals/InvalidAddress';
import { verifyAddress, VerificationResponseCodes } from '../../components/api/addressverification';
import CallLink from '../../components/shared/PhoneNumberUtility/CallLink';
import { ContractContext } from '../../components/contexts/ContractContext';
import { UploadProgress } from './UploadProgress';
import { ReactComponent as Info } from '../../assets/images/info-i-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-down-arrow.svg';
import { getCoverageDocument } from '../../components/Global/DocumentHelper';
import StandardInput from '../../components/shared/StandardInput/StandardInput';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import getClientContent from '../../components/_BrandingProvider/brandContent';

const CustomerReimbursement = ({claim, address}) => {
    const context = useContext(ContractContext);
    const clientId = context?.data?.contract.clientId || 'et';
    const [uploaded, setUploaded] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [isUploadBtnDisable, setUploadBtnDisable] = useState(false);
    const [customAddress, setCustomAddress] = useState(false);
    const [validationFired, setValidationFired] = useState(false);
    const [, forceUpdate] = useState();
    const [ formData, setFormData ] = useState({
        reimbursementTotal: "",
        states: [],
        address1: address?.address1 ?? "",
        address2: address?.address2 ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
        stateId: address?.stateId ?? "",
        zip: address?.zip ?? "",
    })

    const [showSuggestedAddressValidationModal, setShowSuggestedAddressValidationModal] = useState(false);
    const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [addressToVerify, setAddressToVerify] = useState(null);
    const { phone } = getClientContent(clientId).variableText.customerService;

    useEffect(() => {
        handleStateDropDown();
    }, [])

    useEffect(() => {
        getStateDropDownValues();
    }, [])

    const validator = useRef(
        new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg" aria-live="polite">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
        })
    );

    const handleUpload = (e) => {
        setValidationFired(true);
        if (validator.current.allValid()) {
            const addressObj = {
                address1: formData.address1,
                address2: formData.address2,
                city: formData.city,
                state: formData.state,
                zip: formData.zip
            }
            setAddressToVerify(addressObj)
            verifyAddress(addressObj, clientId, onAddressVerified);
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const onFileUpload = (files) => {
        setFileData(prev => [...prev, ...files]);
    }

    const onRemoveFile = (file) => {
        const filter = fileData.filter(e => e !== file);
        setFileData(filter)
    }

    const handleCheckAddress = e => {
        const { checked } = e.target;
        setCustomAddress(!checked);
    }

    const handleInputChange = e => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleCurrencyChange = (values) => {
        const { formattedValue } = values;
        setFormData({
            ...formData,
            reimbursementTotal: formattedValue
        })
    }

    const handleStateDropDown = e => {
        setFormData({
            ...formData,
            ['stateId']: e,
            ['state']: formData.states?.find(state => { return state.data == e })?.abbreviation ?? null

        })

    }

    const getStateDropDownValues = e => {
        fetch(`api/party/states/${clientId}`)
            .then((res) => res.json())
            .then((data) => {
                const statesFromAPI = data.map((s) => {
                    return {
                        data: s.stateId,
                        display: s.name,
                        abbreviation: s.abbreviation,
                    };
                });
                setFormData({
                    ...formData,
                    ['states']: statesFromAPI
                })
            });
        //DEV team will work on this
    }

    const onAddressVerified = (result) => {
        if (result.code === VerificationResponseCodes.addressVerified) {
            onContinueSaveAction(`verifiedAddress`);
            //since the address entered and address returned are same, we don't require to go SuggestedAddressValidationModal
        } else if (result.code === VerificationResponseCodes.addressChanged) {
            setVerifiedAddress(result.returnedAddress);
            setShowSuggestedAddressValidationModal(true);
        } else {
            setShowInvalidAddressModal(true)
        }
    }

    const onContinueSaveAction = (selectedAddress) => {
        //Closing the modal as of now on continue button, DEV team will work on this
        setShowSuggestedAddressValidationModal(false);
        setShowInvalidAddressModal(false);
        const files = new FormData();
        files.append('DocumentTypeId', 43);
        files.append('ClaimId', claim?.claimId);
        fileData.forEach((file, i) => files.append(`Files`, file));

        fetch(`/api/document/uploadDocuments/${clientId}`, {
            method: 'post',
            body: files
        }).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    setUploaded(true);
                    fetch(`/api/AccountsPayable/CreateNewInvoice/${claim?.claimId}/${clientId}`,
                        {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                EventId: claim?.result.eventModel[0].eventId,
                                InvoiceAmount: parseFloat(formData.reimbursementTotal.replace("$",""))
                            })
                        }).then(res => {
                            if (res.ok) {
                                res.json().then(data => {
                                    
                                });
                            }

                        });
                });
            }

        });

    }

    const onCloseAddressModal = () => {
        setShowSuggestedAddressValidationModal(false);
        setShowInvalidAddressModal(false);
    }

    const getCoverageDoc = (e) => {
        e.preventDefault();
        const { contract, assetId } = claim;
        const { riskProfileId,  purchaseDate } = contract;
        getCoverageDocument(clientId, riskProfileId, assetId, purchaseDate);
    }

    const contactUsSection = () => {
        return (
            <p>
                {"If you’d like to speak with one of our representatives, call "}
                <CallLink phone={phone} />
                <br className="show-lg-up" aria-hidden="true" /> or email
                <a href="Warranty-ReimbursementRequest@aig.com" className="pl-1">
                    Warranty-ReimbursementRequest@aig.com
                </a>
                . Please reference your claim number: {claim?.claimNumber}.
            </p>
        )
    }

    return (
        <div className="mb-4">
            {!uploaded ?
            <>
                <Row className={`no-gutters ${styles.btmLine}`}>
                    <h1 className="h3 mb-0">Upload Document(s) for Reimbursement</h1>
                </Row>
                <Row className="px-3">
                    <p>
                        Once you hire a service technician and the repairs are complete, submit the following documents within 60 days to receive reimbursement. 
                        Failure to submit the documents within 60 days could result in the claim being closed and denied.
                    </p>
                    <p>
                        Please note: Not all repairs are covered by the contract. Review the <Link onClick={(e) => getCoverageDoc(e)} className={styles.link}>terms and conditions</Link> to ensure the repair is covered and eligible for reimbursement.
                    </p>
                </Row>
                <Row className='mb-2'>
                    <Col lg="6" md="6">
                        <span className={"font-weight-bold mr-2"}>Surge Claim Form</span>
                        <span className={styles.hasTooltip}>
                            <Info
                                data-tip data-for='surgeClaimTooltip'
                                aria-label="Paid repair invoice"
                                role="tooltip"
                                className={styles.infoIcon}
                            />
                            <ReactTooltip
                                id='surgeClaimTooltip'
                                tabIndex="0"
                                clickable="true"
                                aria-haspopup='true'
                                className={styles.tooltiptext}
                                effect="solid"
                                type="light"
                                place={"bottom"}
                                border={true}>
                                <div>
                                    <p className="mb-0">
                                        This claim form should be completed in its entirety and signed. 
                                        Document all failures that resulted from the electrical surge incident.
                                    </p>                            
                                </div>
                            </ReactTooltip>
                        </span>
                    </Col>
                    <Col lg="6" md="6">
                        <a className={`font-weight-bold ${styles.link}`} href="/documents/claim-form.pdf" target="_blank">
                            Download <DownloadIcon className={styles.downloadIcon}/>
                        </a>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col lg="6" md="6">
                        <span className={"font-weight-bold mr-2"}>Cause of Damage Certification</span>
                        <span className={styles.hasTooltip}>
                            <Info
                                data-tip data-for='damageTooltip'
                                aria-label="Paid repair invoice"
                                role="tooltip"
                                className={styles.infoIcon}
                            />
                            <ReactTooltip
                                id='damageTooltip'
                                tabIndex="0"
                                clickable="true"
                                aria-haspopup='true'
                                className={styles.tooltiptext}
                                effect="solid"
                                type="light"
                                place={"bottom"}
                                border={true}>
                                <div>
                                    <p className="mb-0">
                                        This form should be completed and signed by a licensed service technician certifying the cause of damage is from an electrical surge incident.
                                    </p>                              
                                </div>
                            </ReactTooltip>
                        </span>
                    </Col>
                    <Col lg="6" md="6">
                        <a className={`font-weight-bold ${styles.link}`} href="/documents/technician-certification.pdf" target="_blank">
                            Download <DownloadIcon className={styles.downloadIcon}/>
                        </a>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col>
                        <span className={`font-weight-bold ${styles.header} mr-2`}> Paid Repair Invoice(s)</span>
                        <span className={styles.hasTooltip}>
                            <Info
                                data-tip data-for='paidtooltip'
                                aria-label="Paid repair invoice"
                                role="tooltip"
                                className={styles.infoIcon}
                            />
                            <ReactTooltip
                                id='paidtooltip'
                                tabIndex="0"
                                clickable="true"
                                aria-haspopup='true'
                                className={styles.tooltiptext}
                                effect="solid"
                                type="light"
                                place={"bottom"}
                                border={true}>
                                <div>
                                    <p className="mb-0">Provide a legible copy of your repair invoice, the invoice must:</p>
                                    <ul className="pl-3 mb-0">
                                        <li>Be paid in full</li>
                                        <li>Clearly identify the product's brand, model number and serial number</li>
                                        <li>Include the repair description and all charges associated</li>
                                        <li>
                                            If any covered product is not rapairable, documentation estimating the replacement
                                            value of the covered property will be required
                                        </li>
                                    </ul>
                                </div>
                            </ReactTooltip>
                        </span>
                    </Col>
                </Row>
                <Row >
                    <Col md="5" className={styles.totalSection}>
                        <CurrencyFormat
                            label={"Reimbursement Total"}
                            hasError={!formData.reimbursementTotal && validationFired}
                            fieldName="reimbursementTotal"
                            ariaLabelAndFieldId={"reimbursementTotal"}
                            value={formData.reimbursementTotal}
                            thousandSeparator={true}
                            placeholder="$"
                            prefix={'$'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            decimalSeparator={"."}
                            customInput={StandardInput}
                            onValueChange={(values) => handleCurrencyChange(values)}                          
                        />
                        {validator.current.message(
                            "reimbursementTotal",
                            formData.reimbursementTotal,
                            "required",
                            {
                                messages: {
                                    required: 'Reimbursement total is required.',
                                }
                            }
                        )}
                    </Col>
                </Row>
                <Row className={styles.addressSection}>
                    <Col>
                        <p>
                            Where would you like your reimbursement check sent?
                            <span aria-hidden="true" className="required-indicator">*</span>
                        </p>
                        <div
                            className="checkbox mb-3"
                            aria-label={"Mailing address checkbox is checked uncheck to edit address information"}
                        >
                            <Input 
                                className="mr-2"
                                type="checkbox"
                                id="mailingaddress"
                                onChange={(e) => handleCheckAddress(e)}
                                checked={!customAddress} />
                            <Label for="mailingaddress" className={`checkboxLabel ${clientId.toLowerCase()}`}>{'Use my mailing address'}</Label>
                        </div>
                        {!customAddress ?
                            <StaticAddress
                                addressData={formData}
                            />
                            :
                            <div className={styles.addressForm}>
                                <AddressForm
                                    states={formData.states}
                                    address={formData}
                                    stateId={formData.stateId}
                                    handleInputChange={handleInputChange}
                                    handleStateDropDown={handleStateDropDown}
                                    validationFired={validationFired}
                                    validator={validator?.current}
                                    clientId={clientId}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </>
            :
                <Row className={`no-gutters ${styles.btmLine}`}>
                    <h1 className="h3 mb-0">Reimbursement in Review</h1>
                </Row>
            }
            <Row>
                {!uploaded ?
                    <Col>
                        <DragandDropFileUpload onParentFileUpload={onFileUpload}></DragandDropFileUpload>
                        {fileData?.map(e =>
                            <UploadProgress
                                fileData={e}
                                onRemoveFile={onRemoveFile}
                                setButtonEnable={() => setUploadBtnDisable(false)}
                            />
                        )}
                        {contactUsSection()}
                        <button
                            className={classNames("btn", "btn-primary")}
                            onClick={handleUpload}
                            disabled={isUploadBtnDisable || fileData.length === 0}
                            aria-label="Click to submit documents for review"
                        >
                            Submit
                        </button>
                    </Col> :
                    <Col>
                        <p><b>Thank you for uploading your document(s).</b> One of our representatives will review the information provided. If approved, you’ll receive a reimbursement check in the mail. Please allow up to 45 business days for our team to review and process your reimbursement.</p>
                        {contactUsSection()}
                    </Col>
                }
            </Row>
            {showSuggestedAddressValidationModal &&
                <SuggestedAddress
                    showSuggestedAddressValidationModal={showSuggestedAddressValidationModal}
                    enteredAddress={addressToVerify}
                    suggestedAddress={verifiedAddress}
                    onContinueAction={onContinueSaveAction}
                    onEditAddress={onCloseAddressModal}
                />
            }
            {showInvalidAddressModal &&
                <InvalidAddress
                    showInvalidAddressModal={showInvalidAddressModal}
                    onEditAddress={onCloseAddressModal}
                    onContinueAction={onContinueSaveAction}
                />
            }
        </div>
    )
}

export default CustomerReimbursement;