import React, { Component, Fragment } from 'react';
import { Container, Col, FormGroup, Row} from 'reactstrap';
import styles from './AltServiceOptions.module.css';
import classNames from 'classnames';
import {ReactComponent as InfoIcon}  from '../../assets/images/info-i-icon.svg';
/**
 * THIS COMPONENT HAS BEEN BROUGHT INTO THE APPOINTMENT MODULE IN THE FILE "NoServicers.jsx".
 * LEAVING THIS FILE FOR LEGACY REFERENCE/DEBUGGING
 */
export class AltServiceOptions extends Component {

  state={
    optionSelected:null,
    clickedOption: null,
    wordCount: 0,
    affirmCheck: false,
  }

  onOptionChange = (e) => {
    const {target: {id}} = e;
    this.setState({clickedOption: id});
  }

  setOption = () => {
    const {optionSelected, clickedOption} = this.state;
    if(!optionSelected){
      this.setState({optionSelected: clickedOption});
    }
    this.setState({affirmCheck: false});
  }

  clearOption= () => {
    this.setState({optionSelected: null, clickedOption: null, affirmCheck: false});
  }

  handleInstructions = (e) => {
    const count = e.target.value;
    const wordCount = count.split("").length;
    this.setState({ wordCount, instructions: count });
  };

  render(){
    const {optionSelected, clickedOption, instructions, wordCount, affirmCheck} = this.state;
    const disableBtn = !clickedOption || (clickedOption === "ownServicerOption" && !affirmCheck) && !optionSelected;
    const infoProps = clickedOption === "ownServicerOption" ? styles.infoIcon: "";
    return(
      <Container>
        <div className={styles.serviceOptions}>          
          {!optionSelected && (
            <Fragment>
              <legend aria-label="Select an service option from the list" className="text-center mb-4 col-lg-7">We were unable to quickly locate a servicer in your area.
              You have a couple of options for service, which do you prefer?
              </legend>
              <Col xs="12" md="6" lg="3">
              <div className={styles.newRadioBtn} role="radio" tabIndex="0" aria-checked={clickedOption === "ownServicerOption" ? "true" : "false"}>
                  <input type="radio" aria-labelledby="own servicer" name="servicerOptions" onClick={this.onOptionChange} id="ownServicerOption" />
                  <label className="radio-controls-label m-0 w-100" for="ownServicerOption">Hire my own servicer<span className={styles.ownServInfo}>
                    <InfoIcon className={infoProps} />
                  <span className={styles.infoText}>You can hire your own servicer and submit the invoice to collect reimbursement (exclusions apply see <a>terms and conditions</a>).<br /><br/>You will receive an email with instructions on how to submit the invoice for reimbursement.</span></span></label>
              </div>
              <div className={styles.newRadioBtn} role="radio" tabIndex="0" aria-checked={clickedOption === "recommendServicerOption" ? "true" : "false"}>
                  <input type="radio" aria-labelledby="recommend a servicer" name="servicerOptions" onClick={this.onOptionChange} id="recommendServicerOption" />
                  <label className="radio-controls-label m-0 w-100" for="recommendServicerOption">Recommend a servicer</label>
              </div>
              <div className={styles.newRadioBtn} role="radio" tabIndex="0" aria-checked={clickedOption === "findServicerOption" ? "true" : "false"}>
                  <input type="radio" aria-labelledby="find servicer" name="servicerOptions" onClick={this.onOptionChange} id="findServicerOption" />
                  <label className="radio-controls-label m-0 w-100" for="findServicerOption">Find me a servicer</label>
              </div>
              </Col>
              {clickedOption === "ownServicerOption" && (<FormGroup className="col-md-9 col-lg-7">
                <div className="checkbox mb-0" aria-label="Affirmation checkbox is unchecked check to affirm you know the terms and conditions.">
                  <input className="" type="checkbox" value={affirmCheck} onChange={() => this.setState({affirmCheck: !affirmCheck})} id="affirmCheckTerms"/>
                  <label for="affirmCheckTerms" className={classNames("checkboxLabel", styles.affirmCheckLabel)}>I affirm that I understand not all repairs are covered by the contract.
                  You must review the <a>terms and conditions</a> to ensure the repair is covered and eligible for reimbursement. </label>
                </div>
                <div className="errorMsg">Please affirm you understand repair reimbursement is based on the terms and conditions.</div>
              </FormGroup>
              )}
            </Fragment>
          )}
          {optionSelected === "ownServicerOption" && (<div>Hire Your Own Servicer Confirmation Screen</div>)}
          {optionSelected === "recommendServicerOption" && (<Col lg="8" className={styles.recommendedServicerForm}>
              <legend className={styles.intialText}>We’re glad you have the inside scoop. Which servicer do you recommend? We’ll call you within 24 hours to schedule service.</legend>
              <Col md="6" className="p-0">
                <label className="form-label" for="servicerName">Servicer Name</label>
                <input id="servicerName" name="servicerName" type="text" className="form-control mb-3" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                <label className="form-label" for="servicerPhone">Servicer Phone Number</label>
                <input id="servicerPhone" type="tel" name="servicerPhonenumber" className="form-control mb-3"  aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                <label className="form-label" for="instructionsServicer">Special Instructions for the Servicer</label>
                <textarea name="instructions" className="textarea form-control" value={instructions} id="instructionsServicer" rows="5" spellCheck="" maxLength="250" placeholder="" onChange={this.handleInstructions}></textarea>
                <div className={styles.maxLength}>{wordCount}/250</div>
              </Col>
            </Col>
            )}
          {optionSelected === "findServicerOption" && (<Col lg="6" className={styles.findServicerForm}>
          <legend className={styles.intialText}>Our team will find a servicer in your area and call you within 24 hours to schedule service.</legend>
            <Col md="8" className="p-0">
              <label className="form-label" for="instructionsServicer">Special Instructions for the Servicer</label>
              <textarea name="instructions" className="textarea form-control" value={instructions} id="instructionsServicer" rows="5" spellCheck="" maxLength="250" placeholder="" onChange={this.handleInstructions}></textarea>
              <div className={styles.maxLength}>{wordCount}/250</div>
            </Col>
          </Col>
          )}
          {optionSelected !== 'ownServicerOption' && (<Fragment>
            <Row className="stepsFormBlock">
                <div className="btnWrapper mt-4">
                    <button className="btn btn-secondary" onClick={this.clearOption}>Cancel</button>
                    <button className="btn btn-primary" onClick={this.setOption} disabled={disableBtn} >Continue</button>
                    <a href="#" className="btnLink">Save For Later</a>
                </div>
            </Row>            
          </Fragment>)}
        </div>        
      </Container>
    );
  }
}