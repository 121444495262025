import React from 'react';
import Asset from './Asset'
import { TabContent, TabPane, Row } from 'reactstrap';

const AssetList = ({ tabId, activeTab, contract, contracts, assets, showModelSerial }) => {

    const coverageAsAssets = [376];
    const coverages = [25, 26, 27];
    // console.count('render',{contract,contracts,assets})
    return (
        <TabContent activeTab={activeTab} className="col">
            <TabPane tabId={tabId}>
                <Row className="row-cols-4">
                    {assets.map((asset, index) => {
                        let localContract = contracts?.find(c => c.contractAssetModel?.find(ca => ca.assetId == asset.assetId));
                        let contractAsset = contract?.contractAssetModel?.find(ca => ca.assetId == asset.assetId) ??
                            contracts?.find(c => c.contractAssetModel?.find(ca => ca.assetId == asset.assetId))?.contractAssetModel?.
                                find(ca => ca.assetId == asset.assetId);
                        const claimableCoverage = contractAsset?.contractAssetCoverageModel?.some(cac => cac.claimableCoverage == true);
                        //console.log(localContract, asset, contract)
                        if (claimableCoverage) {// dwelling
                            return (contractAsset?.contractAssetCoverageModel?.map((coverage, id) => {
                                if (coverage.claimableCoverage) {
                                    asset.assetName = coverage?.coverageModel?.coverageName;
                                    
                                    return (
                                        <Asset
                                            asset={asset}
                                            assetName={localContract.clientId === 'et' ? coverage?.coverageModel?.coverageDescription : coverage?.coverageModel?.coverageName}
                                            contract={localContract}
                                            key={index + id}
                                            activeTab={activeTab}
                                            tabId={tabId}
                                            assetIndex={index + id + 1}
                                            showModelSerial={showModelSerial}
                                        />
                                    );
                                }
                            }));
                        } else {
                            return (
                                <Asset
                                    asset={asset}
                                    assetName={asset.assetName}
                                    contract={localContract}
                                    key={index}
                                    activeTab={activeTab}
                                    tabId={tabId}
                                    assetIndex={index + 1}
                                    showModelSerial={showModelSerial}
                                />
                            );
                        }
                    }
                    )}
                </Row>
            </TabPane>
        </TabContent>
    );
}

export default AssetList;