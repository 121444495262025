import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import Cleave from 'cleave.js/react';
import { DropDownAutoComplete } from '../../../../components/inputs/DropDownAutoComplete';
import styles from './Payment.module.css';
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import StandardInput from "../../../shared/StandardInput/StandardInput";
import { DropDownInput } from '../../../inputs/DropDownInput';
import AppleIntlReplacementModal from '../../../modals/AppleInternationalReplacementModal';
import { formFieldLabels } from './addressFieldLabels';
import { countryNameFromCode } from '../../../../helpers';

export const AddressForm = ({
    includeNameFields = false,
    address,
    states,
    handleInputChange,
    handleStateDropDown,
    validator,
    validationFired,
    allowVariableCountry = false,
    onUpdateStates = () => { },
    clientId,
    countryCode,
    isChileApple = false,
    showCountryChangeModal = true
}) => {
    let {
        firstName, lastName,
        address1, address2,
        city, state, stateId, zip,
    } = address

    const international = useTranslation('components/Vendor/Apple/IntakeLoss/CountryAddressForms');
    const { country, language, translations } = international;
    const [variableCountry, setVariableCountry] = useState(address?.country?.toUpperCase() || address?.countryCode?.toUpperCase() || country)
    const [tempNextCountry, setTempNextCountry] = useState(null)
    const [isChangeCountryModalOpen, setIsChangeCountryModalOpen] = useState(false);
    const [countryLabels, setCountryLabels] = useState({ ...formFieldLabels[variableCountry]?.['en'] });


    useEffect(() => {
        setTempNextCountry(null)
        if (address?.country?.toLowerCase() !== variableCountry?.toLowerCase()) {
            fetchStates();
        }
        validator.purgeFields();
        let labelSet = formFieldLabels[variableCountry]?.[language];

        if (!labelSet) {
            labelSet = formFieldLabels[variableCountry]?.['en'];
        }

        setCountryLabels({
            ...countryLabels, ...labelSet
        })
    }, [variableCountry, language])

    const fetchStates = async () => {

        await fetch('api/party/states/' + variableCountry + '/' + clientId)
            .then(res => res.json())
            .then(data => {
                let results = (data.map(s => { return { data: s.stateId, display: s.name, abbreviation: s.abbreviation } }))
                onUpdateStates(results, variableCountry);
            });
    }
    const handleChangeCountries = (sel) => {
        if (sel === variableCountry) return

        setTempNextCountry(sel)
        if (showCountryChangeModal) {
            setIsChangeCountryModalOpen(true)
        } else {
            setVariableCountry(sel)
        }
    }

    const handleChangeCountryConfirm = (nextCountry) => {
        setVariableCountry(nextCountry)
        setIsChangeCountryModalOpen(false)
    }

    const availableCountries = [
        // keep alphabetized by DISPLAY value
        { data: "AUS", display: translations.countryOptions?.australia || "Australia" },
        { data: "AT", display: translations.countryOptions?.austria || "Austria" },
        { data: "CL", display: translations.countryOptions?.chile || "Chile" },
        { data: "DK", display: translations.countryOptions?.denmark || "Denmark" },
        { data: "FI", display: translations.countryOptions?.finland || "Finland" },
        { data: "FR", display: translations.countryOptions?.france || "France" },
        { data: "DE", display: translations.countryOptions?.germany || "Germany" },
        { data: "IE", display: translations.countryOptions?.ireland || "Ireland" },
        { data: "IT", display: translations.countryOptions?.italy || "Italy" },
        { data: "NL", display: translations.countryOptions?.netherlands || "Netherlands" },
        { data: "NZ", display: translations.countryOptions?.newzealand || "New Zealand" },
        { data: "NO", display: translations.countryOptions?.norway || "Norway" },
        { data: "ES", display: translations.countryOptions?.spain || "Spain" },
        { data: "SE", display: translations.countryOptions?.sweden || "Sweden" },
        { data: "CH", display: translations.countryOptions?.switzerland || "Switzerland" },
        { data: "UK", display: translations.countryOptions?.uk || "United Kingdom" },
        { data: "US", display: translations.countryOptions?.usa || "United States" },
        // { data: "VN", display: translations.countryOptions?.vietnam || "Vietnam" }, //commenting out for the prod 10/3/2023
    ].sort((a, b) => a.display > b.display ? 1 : -1)

    const standardGlobalCountries = ['AT', 'DK', 'FI', 'FR', 'DE', 'NL', 'NO', 'SE', 'CH']

    let zipValidator = () => {
        switch (variableCountry) {
            case "UK":
                return validator.message("postal code", zip, "required|alpha_num_space|min:3|max:8", {
                    messages: {
                        required: translations?.errors?.postalRequired || 'Postal code is required.',
                        alpha_num_space: translations?.errors?.postalInvalid || 'Please enter a valid postal code.',
                        min: translations?.errors?.postalInvalid || 'Please enter a valid postal code.'
                    }
                })
            case "AUS":
                return validator.message("postal code", zip, "required|numeric|size:4", {
                    messages: {
                        required: translations?.errors?.postalRequired || 'Postal code is required.',
                        numeric: translations?.errors?.postalInvalid || 'Please enter a valid postal code.',
                        size: translations?.errors?.postalInvalid || 'Please enter a valid postal code.'
                    }
                })
            case "CL":
                return validator.message("postal code", zip, zip?.length > 0 ? "numeric|min:4" : "", {
                    messages: {
                        numeric: translations?.errors?.postalInvalid || 'Please enter a valid postal code.',
                        min: translations?.errors?.postalInvalid || 'Please enter a valid postal code.'
                    }
                })
            case 'AT':
            case 'DK':
            case 'FI':
            case 'FR':
            case 'DE':
            case 'NL':
            case 'NO':
            case 'SE':
            case 'CH':
                return validator.message("postal code", zip, "required|alpha_num_space|min:4|max:10", {
                    messages: {
                        required: translations?.errors?.postalRequired || 'Postal code is required.',
                        alpha_num_space: translations?.errors?.postalInvalid || 'Please enter a valid postal code.',
                        min: translations?.errors?.postalInvalid || 'Please enter a valid postal code.'
                    }
                })
            case 'IE':
                return validator.message("eircode", zip, "required|alpha_num_space|min:4|max:10", {
                    messages: {
                        required: translations?.errors?.postalRequired || 'Eircode is required.',
                        alpha_num_space: translations?.errors?.postalInvalid || 'Please enter a valid eircode.',
                        min: translations?.errors?.postalInvalid || 'Please enter a valid eircode.'
                    }
                })
            case 'NZ':
                return validator.message("postcode", zip, "required|alpha_num|min:4|max:10", {
                    messages: {
                        required: translations?.errors?.postalRequired || 'Postcode is required.',
                        alpha_num: translations?.errors?.postalInvalid || 'Please enter a valid postcode.',
                        min: translations?.errors?.postalInvalid || 'Please enter a valid postcode.'
                    }
                })
            case "IT":
            case 'ES':
            case "US":
            default:
                return validator.message("postal code", zip, "required|numeric|size:5", {
                    messages: {
                        required: translations?.errors?.postalRequired || `${(variableCountry === "US") ? "ZIP" : "Postal"} code is required.`,
                        numeric: translations?.errors?.postalInvalid || `Please enter a valid ${(variableCountry === "US") ? "zip" : "postal"} code.`,
                        size: translations?.errors?.postalInvalid || `Please enter a valid ${(variableCountry === "US") ? "zip" : "postal"} code.`
                    }
                })
        }
    }

    const etZipValidator = () => {
        return validator.message("zip", zip, "required|numeric|size:5", {
            messages: {
                required: "Zip code is required.",
                size: "Zip code is required.",
            }
        })
    }


    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null;

    const address2Input = (countryCode) => {
        if (countryCode === 'VN' || standardGlobalCountries.includes(countryCode) || countryCode === 'NZ') {
            return null;
        }
        return <StandardInput
            notRequired
            label={countryLabels.address2}
            fieldName="address2"
            ariaLabelAndFieldId={"address2"}
            value={address2}
            onChange={handleInputChange}
        />
    }

    const address1RequiredMsg = () => {
        if (translations?.errors?.address1Required) {
            return translations?.errors?.address1Required
        } else {
            if (variableCountry === 'IT' || variableCountry === 'ES') {
                return 'Address is required.'
            }
            return 'Street address is required.'
        }
    }

    const poBoxRequired = () => {
        if (validator.check(address1, `required${variableCountry === 'US' ? '|pobox_check' : ''}`)) {
            return false
        } else {
            return true;
        }
    }

    const addressInputFields = () => {
        return (
            <>
                <StandardInput
                    label={countryLabels.address1}
                    hasError={poBoxRequired() && validationFired}
                    fieldName="address1"
                    ariaLabelAndFieldId={"address1"}
                    value={address1}
                    onChange={handleInputChange}
                    validator={validator.message(
                        "address",
                        address1,
                        `required${variableCountry === 'US' ? '|pobox_check' : ''}`,
                        {
                            messages: {
                                required: address1RequiredMsg()
                            }
                        }
                    )}
                />
                {address2Input(variableCountry)}
            </>
        )
    }

    return (
        <div id="addressFields">
            {includeNameFields && (
                <Row className={'multiColWrapper'}>
                    <Col sm="6">
                        <StandardInput
                            label={translations.fName || "First Name"}
                            hasError={!firstName && validationFired}
                            fieldName="firstName"
                            ariaLabelAndFieldId={"firstName"}
                            value={firstName}
                            onChange={handleInputChange}
                            validator={validator.message(
                                "firstName",
                                firstName,
                                "required",
                                {
                                    messages: {
                                        required: translations.errors?.fNameRequired || 'First name is required.'
                                    }
                                }
                            )}
                        />
                    </Col>
                    <Col sm="6">
                        <StandardInput
                            label={translations.lName || "Last Name"}
                            hasError={!lastName && validationFired}
                            fieldName="lastName"
                            ariaLabelAndFieldId={"lastName"}
                            key="lastName"
                            value={lastName}
                            onChange={handleInputChange}
                            validator={validator.message(
                                "lastName",
                                lastName,
                                "required",
                                {
                                    messages: {
                                        required: translations.errors?.lNameRequired || 'Last name is required.'
                                    }
                                }
                            )}
                        />
                    </Col>
                </Row>
            )}

                    {allowVariableCountry &&
                        <FormGroup className={!variableCountry && validationFired && "form-error"}>
                            <Label for="shipToCountry" className="form-label">
                                {isChileApple
                                    ? translations.country || "Country"
                                    : translations.countryRegion || "Country/Region"
                                }
                            </Label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <DropDownInput
                                id="shipToCountry"
                                options={availableCountries}
                                defaultValue={variableCountry}
                                onChangedValue={handleChangeCountries}
                            />
                        </FormGroup>
                    }

                    {addressInputFields()}

                    {(variableCountry === "IT" || variableCountry === "ES") && (
                        <FormGroup className={!zip && validationFired && "form-error"}>
                            <Label for="zipCode" className="form-label">{countryLabels.zip}</Label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <Cleave
                                options={{
                                    blocks: [5],
                                    numericOnly: true
                                }}
                                className="form-control"
                                id="zipCode"
                                name="zip"
                                aria-required="true"
                                onChange={handleInputChange}
                                value={zip}
                            />
                            {zipValidator()}
                        </FormGroup>
                    )}

                    {standardGlobalCountries.includes(variableCountry) && (
                        <>
                            <StandardInput
                                notRequired
                                label={countryLabels.address2}
                                fieldName="address2"
                                ariaLabelAndFieldId={"address2"}
                                value={address2}
                                onChange={handleInputChange}
                            />
                            <StandardInput
                                hasError={zipValidator() && "form-error"}
                                label={countryLabels.zip}
                                fieldName="zip"
                                ariaLabelAndFieldId={"zip"}
                                value={zip}
                                onChange={handleInputChange}
                                maxLength={10}
                                validator={zipValidator()}
                            />
                            <StandardInput
                                label={countryLabels.city}
                                hasError={!city && validationFired}
                                fieldName="city"
                                ariaLabelAndFieldId={"city"}
                                value={city}
                                onChange={handleInputChange}
                                validator={validator.message('city', city, "required", {
                                    messages: {
                                        required: translations?.errors?.cityRequired || `${countryLabels.city} is required.`
                                    }
                                })}
                            />
                        </>
                    )}

                    {(variableCountry === 'VN') && (
                        <Row className='multiColWrapper'>
                            <Col sm="6">
                                <StandardInput
                                    hasError={!address2 && validationFired}
                                    label={countryLabels.address2}
                                    fieldName="address2"
                                    ariaLabelAndFieldId={"address2"}
                                    value={address2}
                                    onChange={handleInputChange}
                                    validator={validator.message(
                                        "address2",
                                        address2,
                                        "required",
                                        {
                                            messages: {
                                                required: translations.errors?.address2Required || `${countryLabels.address2} is required.`
                                            }
                                        }
                                    )}
                                />
                            </Col>
                            <Col sm="6">
                                <StandardInput
                                    label={countryLabels.city}
                                    hasError={!city && validationFired}
                                    fieldName="city"
                                    ariaLabelAndFieldId={"city"}
                                    value={city}
                                    onChange={handleInputChange}
                                    validator={validator.message('city', city, "required", {
                                        messages: {
                                            required: translations?.errors?.cityRequired || `${countryLabels.city} is required.`
                                        }
                                    })}
                                />
                            </Col>
                        </Row>
                    )}

            {(variableCountry === "CL") && (
                <FormGroup className={zipValidator() && validationFired && "form-error"}>
                    <Label for="zipCode" className="form-label">{countryLabels.zip}</Label>
                    <Cleave
                        options={{
                            blocks: [7],
                            numericOnly: true
                        }}
                        className="form-control"
                        id="zipCode"
                        name="zip"
                        onChange={(event) => handleInputChange(event)}
                        value={zip}
                    />
                    {zipValidator()}
                </FormGroup>
            )}

                    {variableCountry === 'NZ' && (
                        <StandardInput
                            hasError={!address2 && validationFired}
                            label={countryLabels.address2}
                            fieldName="address2"
                            ariaLabelAndFieldId={"address2"}
                            value={address2}
                            onChange={handleInputChange}
                            validator={validator.message(
                                "address2",
                                address2,
                                "required",
                                {
                                    messages: {
                                        required: translations.errors?.address2Required || `${countryLabels.address2} is required.`
                                    }
                                }
                            )}
                        />
                    )}

                    {(variableCountry === "AUS" || variableCountry === "UK" || variableCountry === "IT" ||
                        variableCountry === "ES" || variableCountry === "IE" || variableCountry === 'NZ' || variableCountry === 'CL') && (
                            <StandardInput
                                label={countryLabels.city}
                                hasError={!city && validationFired}
                                fieldName="city"
                                ariaLabelAndFieldId={"city"}
                                value={city}
                                onChange={handleInputChange}
                                validator={validator.message('city', city, "required", {
                                    messages: {
                                        required: translations?.errors?.cityRequired || `${countryLabels.city} is required.`
                                    }
                                })}
                            />
                        )}

                    {variableCountry === "CL" && (
                        <FormGroup className={!stateId && validationFired && "form-error"}>
                            <Label htmlfor="state" aria-label="state" className="form-label">{countryLabels.state}</Label>
                            <span aria-hidden="true" className="required-indicator">*</span>
                            <DropDownAutoComplete
                                name="state"
                                id="state"
                                options={states}
                                className="form-control"
                                onChangedValue={handleStateDropDown}
                                placeholder={translations.stateDropdownPlaceholder || 'Please select...'}
                                isFloatingDropdown={false}
                                onBlur={handleStateDropDown}
                                defaultValue={(stateId === '' ? undefined : stateId)}
                                Value={stateId}
                            />
                            {validator.message("state", stateId, "required", {
                                messages: {
                                    required: translations?.errors?.stateRequired || `${countryLabels.state} is required.`
                                }
                            })}
                        </FormGroup>
                    )}

                    {(variableCountry === 'IT' || variableCountry === "ES" || variableCountry === "IE") && (
                        <FormGroup className={!stateId && !states && validationFired && "form-error"}>
                            <Label htmlfor="state" aria-label="state" className="form-label">{countryLabels.state}</Label>
                            <DropDownAutoComplete
                                name="state"
                                id="state"
                                options={states}
                                className="form-control"
                                onChangedValue={handleStateDropDown}
                                placeholder={translations.stateDropdownPlaceholder || 'Please select...'}
                                isFloatingDropdown={false}
                                onBlur={handleStateDropDown}
                                defaultValue={(stateId === '' ? undefined : stateId)}
                                Value={stateId}
                            />
                        </FormGroup>
                    )}

                    {(variableCountry === "IE" || variableCountry === 'NZ') && (
                        <StandardInput
                            hasError={zipValidator() && "form-error"}
                            label={countryLabels.zip}
                            fieldName="zip"
                            ariaLabelAndFieldId={"zip"}
                            value={zip}
                            onChange={handleInputChange}
                            maxLength={10}
                            validator={zipValidator()}
                        />
                    )}

                    {variableCountry === 'US' && (
                        <>
                            <Row className='multiColWrapper'>
                                <Col sm="6">
                                    <FormGroup className={zipValidator() && validationFired && "form-error"}>
                                        <Label for="zipCode" className="form-label">{countryLabels.zip}</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <Cleave
                                            options={{
                                                blocks: [5],
                                                numericOnly: true
                                            }}
                                            className="form-control"
                                            id="zipCode"
                                            name="zip"
                                            aria-required="true"
                                            onChange={handleInputChange}
                                            value={zip}
                                        />
                                        {zipValidator()}
                                    </FormGroup>
                                </Col>
                                <Col sm="6" className={styles.mb24}>
                                    <FormGroup className={!stateId && validationFired && "form-error"}>
                                        <Label htmlfor="state" aria-label="state" className="form-label">{countryLabels.state}</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <DropDownAutoComplete
                                            name="state"
                                            id="state"
                                            options={states}
                                            className="form-control"
                                            aria-required="true"
                                            onChangedValue={handleStateDropDown}
                                            isFloatingDropdown={false}
                                            onBlur={handleStateDropDown}
                                            defaultValue={(stateId === '' ? undefined : stateId)}
                                            Value={stateId}
                                            placeholder={translations.stateDropdownPlaceholder || 'Please select...'}
                                        />
                                        {validator.message("state", stateId, "required", {
                                            messages: {
                                                required: translations?.errors?.stateRequired || `${countryLabels.state} is required.`
                                            }
                                        })}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <StandardInput
                                label={countryLabels.city}
                                hasError={!city && validationFired}
                                fieldName="city"
                                ariaLabelAndFieldId={"city"}
                                value={city}
                                onChange={handleInputChange}
                                validator={validator.message('city', city, "required", {
                                    messages: {
                                        required: translations?.errors?.cityRequired || `${countryLabels.city} is required.`
                                    }
                                })}
                            />
                        </>
                    )}


                    {(variableCountry === 'UK' || variableCountry === 'AUS') && (
                        <>
                                <FormGroup
                                    className={variableCountry === 'AUS' && !stateId && validationFired && "form-error"}
                                >
                                    <Label htmlFor="state" aria-label="state" className="form-label">
                                        {countryLabels.state}
                                    </Label>
                                    {variableCountry === 'AUS' && <span aria-hidden="true" className="required-indicator">*</span>}
                                    <DropDownAutoComplete
                                        id="state"
                                        name="state"
                                        options={states}
                                        className="form-control"
                                        aria-required="true"
                                        onChangedValue={handleStateDropDown}
                                        isFloatingDropdown={false}
                                        onBlur={handleStateDropDown}
                                        defaultValue={(stateId === '' ? undefined : stateId)}
                                        Value={stateId}
                                        placeholder={translations.stateDropdownPlaceholder || 'Please select...'}
                                    />
                                    {variableCountry === 'AUS' && validator.message("state", stateId, variableCountry === "UK" ? "" : "required", {
                                        messages: {
                                            required: translations?.errors?.stateRequired || `${countryLabels.state} is required.`
                                        }
                                    })}
                                </FormGroup>                           
                                <FormGroup className={zipValidator() && "form-error"}>
                                    <Label for="zipCode" className="form-label">{countryLabels.zip}</Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <Cleave
                                        options={{
                                            blocks: variableCountry === "UK" ? [8] : [4],
                                            numericOnly: variableCountry !== 'UK'
                                        }}
                                        className="form-control"
                                        id="zipCode"
                                        name="zip"
                                        aria-required="true"
                                        onChange={(event) => handleInputChange(event)}
                                        value={zip}
                                    />
                                    {zipValidator()}
                                </FormGroup>
                        </>
                    )}

                    {variableCountry === 'VN' && (
                        <Row className={'multiColWrapper'}>
                            <Col sm="6" className={styles.mb24}>
                                <FormGroup
                                    className={!stateId && validationFired && "form-error"}
                                >
                                    <Label htmlFor="state" aria-label="state" className="form-label">
                                        {countryLabels.state}
                                    </Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        id="state"
                                        name="state"
                                        options={states}
                                        className="form-control"
                                        aria-required="true"
                                        onChangedValue={handleStateDropDown}
                                        isFloatingDropdown={false}
                                        onBlur={handleStateDropDown}
                                        defaultValue={(stateId === '' ? undefined : stateId)}
                                        Value={stateId}
                                        placeholder={translations.stateDropdownPlaceholder || 'Please select...'}
                                    />
                                    {validator.message("state", stateId, "required", {
                                        messages: {
                                            required: translations?.errors?.stateRequired || `${countryLabels.state} is required.`
                                        }
                                    })}
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="zipCode" className="form-label">{countryLabels.zip}</Label>
                                    <Cleave
                                        options={{
                                            blocks: [6],
                                            numericOnly: true
                                        }}
                                        className="form-control"
                                        id="zipCode"
                                        name="zip"
                                        onChange={(event) => handleInputChange(event)}
                                        value={zip}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

            {allowVariableCountry &&
                <AppleIntlReplacementModal
                    nextCountry={tempNextCountry}
                    isOpen={isChangeCountryModalOpen}
                    onClose={() => setIsChangeCountryModalOpen(false)}
                    onConfirm={handleChangeCountryConfirm}
                />
            }
        </div>
    )
}



export const StaticAddress = ({
    addressData,
    showCountry = false,
    showName = false,
    countryCode,
    states,
    clientId,
    customClass = ''
}) => {
    let country = addressData?.countryCode || addressData?.country;
    let transCountry = useTranslation().country

    if (!country) {
        country = transCountry
    }

    let {
        firstName,
        lastName,
        address1,
        address2,
        zip,
        city,
        state,
    } = addressData

    let nameString = showName && ((firstName || address2) && (`${!!firstName && firstName + ' '}${!!lastName && lastName}`))
    const countryName = countryNameFromCode(country);
    const [stateFullName, setStateFullName] = useState('');
    const [stateAbbr, setStateAbbr] = useState('');

    if (typeof nameString == 'undefined' && addressData.length > 2) {
        nameString = addressData[0];
        address1 = addressData[1];
        address2 = addressData[2];
    }

    useEffect(() => {

        if (!states || !states.length) {
            fetchStates();
        } else {
            const selectedState = states && states.filter((st, ind) => st.abbreviation === state || st.display === state || st.data === addressData?.stateId) || [];
            if (selectedState.length) {
                setStateAbbr(selectedState[0]?.abbreviation)
                setStateFullName(selectedState[0]?.display);
            }
        }

    }, [])

    const fetchStates = async () => {
        await fetch('api/party/states/' + country + '/' + clientId)
            .then(res => res.json())
            .then(data => {
                let results = (data.map(s => { return { data: s.stateId, display: s.name, abbreviation: s.abbreviation } }))
                const selectedState = results && results.filter((st, ind) => st.abbreviation === state || st.display === state || st.data === addressData?.stateId) || [];
                if (selectedState.length) {
                    setStateFullName(selectedState[0]?.display);
                    setStateAbbr(selectedState[0]?.abbreviation);
                }
            });
    }

    const showAddressText = () => {
        switch (country?.toUpperCase()) {
            case 'UK': return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2}</li>}
                    {city && <li>{city}</li>}
                    {stateAbbr && <li>{stateAbbr}</li>}
                    {zip && <li>{zip}</li>}
                </>
            );
            case 'AT':
            case 'DK':
            case 'FI':
            case 'FR':
            case 'DE':
            case 'NL':
            case 'NO':
            case 'SE':
            case 'CH':
                return (
                    <>
                        {address1 && <li>{address1}</li>}
                        {address2 && <li>{address2}</li>}
                        <li>{zip && zip + ' '}{city && city}</li>
                    </>
                );
            case 'IT': return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2}</li>}
                    <li>{zip && zip + ' '}{city && city + ' '}{stateAbbr && stateAbbr}</li>
                </>
            );
            case 'ES': return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2}</li>}
                    <li>{zip && zip + ' '}{city && city}</li>
                    {stateAbbr && <li>{stateAbbr}</li>}
                </>
            );
            case 'VN': return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2 + ', '}{city && city}</li>}
                    {stateFullName && <li>{stateFullName}</li>}
                    {zip && <li>{zip}</li>}
                </>
            );
            case 'CL': return (
                <>
                    <li>{address1 && address1}{address2 && `, ${address2}`}</li>
                    <li>{zip && zip + ' '}{city && city}</li>
                    {stateFullName && <li>{stateFullName}</li>}
                </>
            );
            case 'IE': return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2}</li>}
                    {city && <li>{city}</li>}
                    {stateFullName && <li>{stateFullName}</li>}
                    {zip && <li>{zip}</li>}
                </>
            );
            case 'NZ': return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2}</li>}
                    <li>{city && city + ' '}{zip && zip}</li>
                </>
            );
            case 'US':
            case 'AUS':
            default: return (
                <>
                    {address1 && <li>{address1}</li>}
                    {address2 && <li>{address2}</li>}
                    <li>{city && city + ', '}{stateAbbr && stateAbbr + ' '}{zip && zip}</li>
                </>
            );
        }
    }

    // RETURN NOTHING IF ALL VALUES ARE FALSY
    if (Object.values(addressData ? addressData : '').every(v => !v)) return null;

    return (
        <ul id="billingAddressStatic" className={`noBullets ${customClass}`} role="none">
            {showName &&
                <li>{nameString}</li>
            }
            {showAddressText()}
            <li>{countryName}</li>
        </ul>
    )
}