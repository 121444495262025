import React, { useState, useEffect } from 'react';
import { ReactComponent as HWALogo } from '../../../assets/images//Vendor/HWA/HWA-Logo.svg';
import styles from './HWAHeader.module.css';
import { TabContent, TabPane, Nav, NavItem, NavLink, Container, NavbarToggler, Collapse, Navbar } from 'reactstrap';
import classnames from 'classnames';
import qs from 'qs';
import { isHWAChatEndRoute } from '../../../helpers';
import ContactIcon from '../../../assets/images/Vendor/HWA/contact-bubble-small.png';

export const HWAHeader = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [redirectUri, setRedirectUri] = useState('');
    const [token, setToken] = useState(qs.parse(window.location.search, { ignoreQueryPrefix: true }).id);
    const [logoRedirectUri, setLogoRedirectUri] = useState('');
    const [navOptions, setNavOptions] = useState(false);
    const [chatEnd, setChatEnd] = useState(false);
    useEffect(() => {
        const hwaChat = async () => {
            const result = await isHWAChatEndRoute();
            console.log(result);
            if (result == true) {
                setChatEnd(true);
            }
        };
        hwaChat();
    }, []);
    

    useEffect(() => {
        async function fetchUri() {
            const res = await fetch('vendor/GetHwaRedirectUri');
            const data = await res.json();
            setRedirectUri(data.uri);
            setLogoRedirectUri(data.uri + 'service-agreement')
        }

        fetchUri();
        //console.log(window.location);
        //console.log('PROPS...', props);
    }, []);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);

        redirectFromHeadAction(tab);
    }

    const redirectFromHeadAction = (actionId) => {
        var route = '';
        switch (actionId) {
            case '1': {
                route = 'service-agreement';
                break;
            }
            case '2': {
                route = 'shop/hwa';
                break;
            }
            case '3': {
                route = 'user/profile';
                break;
            }
            case '4': {
                route = 'info/contact-us';
                break;
            }
            case '5': {
                route = 'auth/logout';
                break;
            }
        }
        //alert(`${redirectUri}${route}?id=${token}`);
        window.location.href = `${redirectUri}${route}?id=${token}`;
    }
    const toggleNavbar = () => { setNavOptions(!navOptions) };

    return (
        <header className={classnames("container-fluid header p-0", styles.hwaHeader)}>
            <div className={classnames(styles.hwaHeaderLg, styles.hwaContainer)}>
                <div className={styles.hwaLogo}>
                    <a href={logoRedirectUri}><HWALogo /></a>
                </div>
                <div className={styles.callUS}>
                    <img src={ContactIcon}></img>
                    <span>Call us <a className="callLink">(888) 492-7359</a></span>
                </div>
            </div>
            {!chatEnd && <>
                <div className={styles.navBarContainer}>
                    <div className={classnames(styles.navContainer, styles.hwaContainer)}>
                        <Navbar color="faded" light expand="md" className={styles.mobileNav}>
                            <NavbarToggler onClick={toggleNavbar} />
                            <Collapse isOpen={navOptions} navbar>
                                <Nav navbar className={styles.navTabsHWA}>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                            My Policy Details
                          </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                            Shop Plans
                          </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                            Personal Info
                          </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                            Contact Us
                          </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }}>
                                            Logout
                          </NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                        <div className={styles.hwaLogoMobile}>
                            <a href={logoRedirectUri}><HWALogo /></a>
                        </div>
                    </div>
                </div>
            </>}
            <div className={styles.callUSSm}>
                <img src={ContactIcon}></img>
                <span>Call us <a className="callLink">(888) 492-7359</a></span>
            </div>
            {/* <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    
                    <h4>Tab 1 Contents</h4>
                      
                </TabPane>
                <TabPane tabId="2">
                    
                    <h4>Tab 1 Contents</h4>
                      
                          
                </TabPane>
            </TabContent> */}
        </header>
    );
}