import React, { useContext, useState, useEffect } from 'react';
import qs from 'qs';
import { ContractContext } from '../../contexts/ContractContext';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'query-string';
import { verifyAigJwtToken } from '../../../../src/helpers';
import { useEnvironment } from '../../../components/_ReactHooks/useEnvironment';

export default function HandshakeWrapper( { children}) {
    const context = useContext(ContractContext);
    const location = useLocation()
    const history = useHistory()
    const { isAnyNonProduction, env } = useEnvironment()

    const [token, setToken] = useState(parse(location.search).id || parse(location.search).token)
    const [clientId, setClientId] = useState(context?.data?.clientId || 'aig')
    const [isShakingHands, setIsShakingHands] = useState(true)
    const nonHandshakeClients = ['apple', 'aig'];

    useEffect(() => {        
        // BYPASS IF NOT HANDSHAKE ROUTE
        if (env == '') {
            setIsShakingHands(true);
            return
        }
        else if (context.data.handshakeSuccessful != true && env != '' && !isAnyNonProduction && !nonHandshakeClients.includes(clientId?.toLowerCase()) && !(location.pathname.includes('/contact') || location.pathname.includes('/coveragedocs') || location.pathname.includes('/faq') || location.pathname.includes('/documents'))) {
            shakeHands();
        }
        //if (!isAnyNonProduction || (!location.pathname.includes('/handshake') || nonHandshakeClients.includes(clientId?.toLowerCase())) || location.pathname.includes('/contact') || location.pathname.includes('/coveragedocs')) {
        else {
            setIsShakingHands(false);
            return
        }

    }, [location.pathname, env])


    const shakeHands = async () => {
        if (location.pathname.includes('/vendor/handshake') || clientId?.toLowerCase() != 'hwa') {
            
            const response = await verifyAigJwtToken(token || '');
            if (response.success) {
                let value = context;
                let contract = response.contracts;
                value.updateContract(contract[0]);
                value.updateParty(contract[0]?.party);
                value.updateContracts(contract);
                setIsShakingHands(false);
                context.setHandshakeSuccessful(true);
                history.push({
                    pathname: '/planoverview',
                    search: `?token=${token}`,
                    state: {
                        view: "file",
                        activeOverviewTab: "fileClaim"
                    }
                });
            }
            else{
                var url = response.redirect;
                if (url.startsWith('http://') || url.startsWith('https://')) {
                    window.location.assign(url)
                } else {
                    setIsShakingHands(false);
                    history.push({ pathname: response.redirect })
                }
            }
        }
        else {
            var contractResponse = await getContractByToken(token);
            var verifyTokenResponse = await verifyToken(token);
            if (contractResponse.success === true && verifyTokenResponse.success === true) {
                let contract = contractResponse.contract;
                context.updateViaClientHandshake(true);
                context.updateContract(contract);
                context.updateParty(contract.party);
                context.updateContracts(contract);
                setIsShakingHands(false);
                context.setHandshakeSuccessful(true);
                history.push({
                    pathname: '/planoverview',
                    search: `?token=${token}`,
                    state: { 
                        view: "file",
                        activeOverviewTab: "fileClaim"
                    }
                });
            } else {
                // SRV 4/6/21 - redirect to hwa login page per
                if (contractResponse.success === false) {
                    window.location.href = `${contractResponse.redirect}?service_agreement_id=${contractResponse.agreementId}`;
                }
                else {
                    window.location.href = `${verifyTokenResponse.redirect}?service_agreement_id=${verifyTokenResponse.agreementId}`;
                }
            }
        }
    }

    const getContractByToken = async (token) => {
        const res = await fetch(`api/contract/GetByToken/${clientId}`, {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                token: token
            })
        });

        if (res.status === 204) {
            return null;
        }
        else {
            const contract = await res.json()
            return contract;
        }
    }


    const verifyToken = async (token) => {
        const res = await fetch(`vendor/VerifyToken/${clientId}`, {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                token: token
            })
        });

        if (res.ok)
            return await res.json();

        return false;
    }


    // Block Rendering while "handshake" remains in URL
    // if (isShakingHands) return <div>Handshaking...</div>;   // FOR DEBUGGING
    if (isShakingHands) return null;
    
    // Will render app after reroute, or if "handshake" is not in route to begin with
    return children
}