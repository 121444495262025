import React, { useContext, useEffect } from 'react';
import styles from './ContactConfirmation.module.css';
import { ContractContext } from '../../../contexts/ContractContext';
import getClientContent from '../../../_BrandingProvider/brandContent';
import { dataLayerPush } from '../../../../helpers';
import ETServicerIcon from '../../../../assets/images/Vendor/ET/Servicer_Icon.svg';

export const ETContactConfirmation = (props) => {
    const context = useContext(ContractContext)
    const { clientId } = context.data.contract;
    const { variableText } = getClientContent(clientId);
    const customerServiceNumber = variableText.customerService.phone;

    useEffect(() => {
        dataLayerPush('/vendor/et/contactusconfirmation', 'Contact Us Confirmation')
    }, [])

    return (
        <div>
            <section className={styles.confirmSection}>
                <img className={styles.confirmIcon} src={ETServicerIcon}></img>
                <h1 className="h3">We’ll Contact You Soon</h1>
                <h2>We will contact you within 1 business day about your request.</h2>
                <p>If you'd like to speak with one of our representative, call <a href={`tel:${customerServiceNumber}`} className="callLink"> {customerServiceNumber}</a>.</p>
            </section>
        </div>
    )
}