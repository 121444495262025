import React, { useContext, useEffect, useState } from 'react';
import styles from "./NotificationsBox.module.css";
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ContractContext, BrandingContext } from '../contexts/ContractContext';
import AIGStatusBadge from '../shared/StatusBadge/StatusBadge';
import { useHistory } from 'react-router';
import { resumeClaim } from '../../helpers'
import classnames from 'classnames';

export const NotificationsBox = ({
    claimData,
    type = "",
    claimNumber,
    assetType,
    statusId = "",
    apptTime,       // optional, only for "upcoming"
    onResume,       // optional, only for "inProgress"
    steps,          // optional, only for "inProgress"
    completedSteps, // optional, only for "inProgress"
    client,
    contract
}, ...props) => {
    const title = {
        inProgress: `${assetType} Claim #${claimNumber}`,
        upcoming: `${assetType} Claim #${claimNumber}`,
        saved: `Saved ${assetType} Claim #${claimNumber}`,
        noNotifications: 'You have no new notifications',
    }
    const history = useHistory();

    const isResumeDisabled = contract?.statusId == 4;

    const context = useContext(ContractContext);

    function openClaimOverview(claimData) {
        let pathName = '/DetailsOverview';
        history.push({
            pathname: pathName,
            claim: claimData,
            clientId: client
        });

    }

    function resumeClaimPage(col) {
        let historyData = resumeClaim(col, client, null);
        history.push(historyData);
    }

    const childrenByType = {
        inProgress: (
            <>
                <p></p>
                <button className={classnames("btnLink", styles.notificationBtn)} onClick={() => { openClaimOverview(claimData) }}>Track Claim</button>
            </>
        ),
        saved: (
            <>
                {/*Hiding the steps counter not required*/}
                {/*<p className={styles.notificationDescription}>{`Steps ${completedSteps} of ${steps} Complete`}</p>*/}
                <button className={classnames("btnLink", styles.notificationBtn)} disabled={isResumeDisabled} onClick={() => { resumeClaimPage(claimData) }}>Resume</button>
            </>
        ),
        upcoming: (
            <>
                <p className={styles.notificationDescription}>Service Appointment</p>
                <p className={styles.notificationDescription}>{apptTime}</p>
            </>
        ),
        noNotifications: null,
    }

    const clientUpper = client.toUpperCase()
    return (
        <Card className={classnames(styles.notificationCard, styles[clientUpper])}>
            <CardHeader className={classnames(styles.notificationHeader, styles[clientUpper])}>
                Notifications
            </CardHeader>
            <CardBody className={styles.notificationCardbody}>
                <p className={styles.notificationTitle}>{title[type]}</p>
                <div>
                    {statusId && type === 'upcoming' &&
                        <AIGStatusBadge statusId={statusId} statusType='event' />
                    }
                    {statusId && type === 'inProgress' &&
                        <AIGStatusBadge statusId={statusId} statusType='event' />
                    }
                    {statusId && type === 'saved' &&
                        <AIGStatusBadge statusId={statusId} statusType='event' />
                    }
                </div>
                {childrenByType[type]}
            </CardBody>
        </Card>
    )
}
