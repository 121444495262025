import React, { Component } from "react";
import { Container } from "reactstrap";
import styles from './CoverageDocs.module.css';
import USAMap from "../../../components/Vendor/Apple/FileInfo/usaMap";
import data from "../../../components/Vendor/Apple/FileInfo/usaMap/data/usa-states-dimensions.json";
import DocumentTitle from 'react-document-title';
import { DropDownAutoComplete } from '../../../components/inputs/DropDownAutoComplete';
import classNames from 'classnames';
import { callAdobe } from '../../../helpers'
import ExNY_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/ExNY.pdf";
import AK_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/AK.pdf";
import AL_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/AL.pdf";
import AZ_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/AZ.pdf";
import AR_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/AR.pdf";
import CA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/CA.pdf";
import CO_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/CO.pdf";
import CT_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/CT.pdf";
import DE_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/DE.pdf";
import FL_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/FL.pdf";
import GA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/GA.pdf";
import HI_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/HI.pdf";
import ID_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/ID.pdf";
import IL_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/IL.pdf";
import IN_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/IN.pdf";
import IA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/IA.pdf";
import KS_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/KS.pdf";
import KY_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/KY.pdf";
import LA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/LA.pdf";
import ME_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/ME.pdf";
import MD_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MD.pdf";
import MA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MA.pdf";
import MI_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MI.pdf";
import MN_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MN.pdf";
import MO_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MO.pdf";
import MS_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MS.pdf";
import MT_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/MT.pdf";
import NC_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NC.pdf";
import NE_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NE.pdf";
import NH_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NH.pdf";
import NJ_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NJ.pdf";
import NM_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NM.pdf";
import NV_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NV.pdf";
import NY_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/NY.pdf";
import ND_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/ND.pdf";
import OH_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/OH.pdf";
import OK_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/OK.pdf";
import OR_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/OR.pdf";
import PA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/PA.pdf";
import SD_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/SD.pdf";
import RI_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/RI.pdf";
import SC_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/SC.pdf";
import TN_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/TN.pdf";
import TX_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/TX.pdf";
import UT_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/UT.pdf";
import VT_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/VT.pdf";
import VA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/VA.pdf";
import WA_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/WA.pdf";
import WI_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/WI.pdf";
import WV_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/WV.pdf";
import WY_CoverageDoc from "../../../assets/Documents/CoverageDocuments/UnitedStates/WY.pdf";
import InternationalCoverageDocs from "./InternationalCoverageDocs";
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent'
import { LanguageContext } from '../../../components/contexts/LanguageTranslation/LanguageContext';

let pLink = styles.pLink;


export default class AppleCoverageDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
      popUp: false,
      pageTitle: 'Coverage Documents | AIG AppleCare+ with Theft and Loss Claims',
    }
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  };

  componentDidMount() {

    // FOR ADOBE ANALYTICS
    callAdobe({
      pagePath: 'vendor/apple/coveragedocs',
      pageName: 'Coverage Documents',
      country: this.context?.country,
      language: this.context?.language,
      currency: this.context?.countryVars?.currency?.abbreviation,
    })
  }

  mapHandler = (stateName) => {
    // this.props.history.push("/vendor/apple/coveragedocs/plantype");
    //this.props.history.push({ pathname: `/programcoveragedocs`, state: { stateName } })
    switch (stateName) {
      case "Alabama":
        window.open(AL_CoverageDoc, "blank");
        break;
      case "Alaska":
        window.open(AK_CoverageDoc, "blank");
        break;
      case "Arizona":
        window.open(AZ_CoverageDoc, "blank");
        break;
      case "Arkansas":
        window.open(AR_CoverageDoc, "blank");
        break;
      case "California":
        window.open(CA_CoverageDoc, "blank");
        break;
      case "Colorado":
        window.open(CO_CoverageDoc, "blank");
        break;
      case "Connecticut":
        window.open(CT_CoverageDoc, "blank");
        break;
      case "District of Columbia":
        window.open(ExNY_CoverageDoc, "blank");
        break;
      case "Delaware":
        window.open(DE_CoverageDoc, "blank");
        break;
      case "Florida":
        window.open(FL_CoverageDoc, "blank");
        break;
      case "Georgia":
        window.open(GA_CoverageDoc, "blank");
        break;
      case "Hawaii":
        window.open(HI_CoverageDoc, "blank");
        break;
      case "Idaho":
        window.open(ID_CoverageDoc, "blank");
        break;
      case "Illinois":
        window.open(IL_CoverageDoc, "blank");
        break;
      case "Indiana":
        window.open(IN_CoverageDoc, "blank");
        break;
      case "Iowa":
        window.open(IA_CoverageDoc, "blank");
        break;
      case "Kansas":
        window.open(KS_CoverageDoc, "blank");
        break;
      case "Kentucky":
        window.open(KY_CoverageDoc, "blank");
        break;
      case "Louisiana":
        window.open(LA_CoverageDoc, "blank");
        break;
      case "Maine":
        window.open(ME_CoverageDoc, "blank");
        break;
      case "Maryland":
        window.open(MD_CoverageDoc, "blank");
        break;
      case "Massachusetts":
        window.open(MA_CoverageDoc, "blank");
        break;
      case "Michigan":
        window.open(MI_CoverageDoc, "blank");
        break;
      case "Minnesota":
        window.open(MN_CoverageDoc, "blank");
        break;
      case "Mississippi":
        window.open(MS_CoverageDoc, "blank");
        break;
      case "Missouri":
        window.open(MO_CoverageDoc, "blank");
        break;
      case "Montana":
        window.open(MT_CoverageDoc, "blank");
        break;
      case "Nebraska":
        window.open(NE_CoverageDoc, "blank");
        break;
      case "Nevada":
        window.open(NV_CoverageDoc, "blank");
        break;
      case "New Hampshire":
        window.open(NH_CoverageDoc, "blank");
        break;
      case "New Jersey":
        window.open(NJ_CoverageDoc, "blank");
        break;
      case "New Mexico":
        window.open(NM_CoverageDoc, "blank");
        break;
      case "New York":
        window.open(NY_CoverageDoc, "blank");
        break;
      case "North Carolina":
        window.open(NC_CoverageDoc, "blank");
        break;
      case "North Dakota":
        window.open(ND_CoverageDoc, "blank");
        break;
      case "Ohio":
        window.open(OH_CoverageDoc, "blank");
        break;
      case "Oklahoma":
        window.open(OK_CoverageDoc, "blank");
        break;
      case "Oregon":
        window.open(OR_CoverageDoc, "blank");
        break;
      case "Pennsylvania":
        window.open(PA_CoverageDoc, "blank");
        break;
      case "Rhode Island":
        window.open(RI_CoverageDoc, "blank");
        break;
      case "South Carolina":
        window.open(SC_CoverageDoc, "blank");
        break;
      case "South Dakota":
        window.open(SD_CoverageDoc, "blank");
        break;
      case "Tennessee":
        window.open(TN_CoverageDoc, "blank");
        break;
      case "Texas":
        window.open(TX_CoverageDoc, "blank");
        break;
      case "Utah":
        window.open(UT_CoverageDoc, "blank");
        break;
      case "Vermont":
        window.open(VT_CoverageDoc, "blank");
        break;
      case "Virginia":
        window.open(VA_CoverageDoc, "blank");
        break;
      case "Washington":
        window.open(WA_CoverageDoc, "blank");
        break;
      case "West Virginia":
        window.open(WV_CoverageDoc, "blank");
        break;
      case "Wisconsin":
        window.open(WI_CoverageDoc, "blank");
        break;
      case "Wyoming":
        window.open(WY_CoverageDoc, "blank");
        break;
      default:
        console.log('All cases missed', stateName);
    }
  };

  onChangeFunc = (optionSelected, eventName, name) => {
    this.mapHandler(name)
  }
  open = e => {
    this.setState({
      popUp: !this.state.popUp
    });
  }

  close = e => {
    this.setState({
      popUp: false
    });
  }

  render() {
    const { pageTitle } = this.state;
    const filteredOptions = data && Object.values(data).map(e => {
      return { data: e.abbreviation, display: e.name }
    })

    return (
      <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/CoverageDocs'}>
        {
          international => {
            const {
              country,
              language
            } = international;
            return (
              <DocumentTitle title={pageTitle}>
                <InternationalCoverageDocs language={language} />
              </DocumentTitle>
            );
          }
        }
      </UseTranslationInClassComponent>
    );
  }
}

//export default CoverageDocs;
AppleCoverageDocs.contextType = LanguageContext
