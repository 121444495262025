import { phoneFormat } from "../shared/util";
import React from 'react';

const getTextWithLineBreak = (sentences) => (
    <>
        {sentences.map(s => {
            return <>{s}<br className='show-lg-up' /></>
        })}
    </>
)

export const appleServiceNumbers = {
    customerService: {
        US: '(866) 258-7419',
        UK: '020 8253 7435',
        DE: '07131 186 222',
        AUS: '03 9522 4099',
        ES: '9009-30413',
        FR: '0800-902128',
        IT: '800-780755',
        CL: '123-0-020-0245',
        AT: '0800 298853',
        FI: '0800 390642',
        IE: '+44 2082537480',
        NL: '0800 0221662',
        NO: '800 14983',
        SE: '020 79 0261',
        DK: '80-254570',
        CH: '0800 563528',
        NZ: '0800 453988',

    },
    incorrectDevice: {
        US: "(800) 275-2273",
        UK: "0800 107 6285",
        DE: "0800 6645 451",
        AUS: "1300 321 456",
        ES: '9009-30413',
        FR: '0800-902128',
        IT: '800-780755',
        CL: '800-380-230',
        AT: '0800 220325',
        FI: '0800 96372',
        IE: '1800 804 062',
        NL: '0800 0201581',
        NO: '240 55133',
        SE: '020 100 529',
        DK: '80-249625',
        CH: '0800 00 1853',
        NZ: '0800 1 27753',
    }
}


const voiceValues = {
    conversational: {
        scheduling: {
            type4: getTextWithLineBreak(["Well, we're still looking for a servicer in your area.", "Let's take this offline and we'll call you within 24 hours to schedule service."]),
            recommendServicer: "Do you have the inside scoop? Is there a servicer you recommend?"
        },
        confirmations: {
            type1: "It’s a date, we’ll see you then.",
            type2_3: 'Get your calendar out, we’ll call you soon.',
            type4: "Stand by, our team is looking for a servicer.",
            savedClaim: "Your claim has been saved.",
        },
        verifyInfo: {
            service: 'Are we coming to the right place and using the best contact info?',
            replacement: "Are we shipping to the right place and using the best contact info?",
        },
    },
    standard: {
        scheduling: {
            type4: getTextWithLineBreak(["We're still looking for a servicer in your area.", "We’ll call you within 24 hours to schedule service."]),
            recommendServicer: "Is there a servicer you recommend?"
        },
        confirmations: {
            type1: "Your appointment is confirmed.",
            type2_3: "We'll call you soon to make your appointment.",
            type4: "We’re locating a servicer and will be in touch soon.",
            savedClaim: "Your claim has been saved.",
        },
        verifyInfo: {
            service: "Please confirm your address and preferred contact information.",
            replacement: "Please confirm your address and preferred contact information.",
        },
    }
}

const textValues = {
    aig:
    {
        customerService: {
            email: 'AIGServiceCenter@aig.com',
            phone: phoneFormat('XXXXXXXXXX'),
        },
        sales: {
            phone: phoneFormat('XXXXXXXXXX'),
        },
        claimOverviewHero: {
            planNumber: 'Plan Number',
            planStatus: 'Plan Status',
            totalPlans: 'Total Plans'
        },
        planDetails: {
            paymentType: 'Trade Service Fee',
        },
        pageTitle: "AIG AppleCare+ with Theft and Loss Claims"
    },
    apple:
    {
        customerService: {
            email: 'AIGServiceCenter@aig.com',
            phone: phoneFormat('XXXXXXXXXX'),
        },
        sales: {
            phone: phoneFormat('XXXXXXXXXX'),
        },
        claimOverviewHero: {
            planNumber: 'Plan Number',
            planStatus: 'Plan Status',
            totalPlans: 'Total Plans'
        },
        planDetails: {
            paymentType: 'Trade Service Fee',
        },
        pageTitle: "AIG AppleCare+ with Theft and Loss Claims"
    },
    arw: {
        customerService: {
            email: 'AIGServiceCenter@aig.com',
            phone: phoneFormat('877-752-6790'),
        },
        sales: {
            phone: phoneFormat('XXXXXXXXXX'),
        },
        claimOverviewHero: {
            planNumber: 'Plan Number',
            planStatus: 'Plan Status',
            totalPlans: 'Total Plans'
        },
        planDetails: {
            paymentType: 'Trade Service Fee',
        },
    },
    hwa: {
        customerService: {
            email: '',
            phone: phoneFormat('888-492-7359'),
        },
        sales: {
            phone: phoneFormat('866-294-7941'),
        },
        portfolioClaim: {
            phone: phoneFormat('844-762-7411'),
        },
        claimOverviewHero: {
            planNumber: 'Policy ID',
            planStatus: 'Policy Status',
            totalPlans: 'Total Plans'
        },
        planDetails: {
            paymentType: 'Trade Call Fee',
        },
    },
    njr: {
        customerService: {
            email: 'AIGServiceCenter@aig.com',
            phone: phoneFormat('8884050602'),
        },
        sales: {
            phone: phoneFormat('8775314782'),
        },
        claimOverviewHero: {
            planNumber: 'Plan Number',
            planStatus: 'Plan Status',
            totalPlans: 'Total Plans'
        },
        planDetails: {
            paymentType: 'Service Fee',
        },
    },
    et: {
        customerService: {
            email: 'energytexasinfo@aig.com',
            phone: phoneFormat('8776998790'),
        },
        sales: {
            phone: phoneFormat('8336917225'),
        },
        claimOverviewHero: {
            planNumber: 'Account Number',
            planStatus: 'Plan Status',
            totalPlans: 'Total Plans'
        },
        confirmations: {
            savedClaim: "Your claim has been saved.",
        },
        planDetails: {
            paymentType: 'Trade Service Fee',
        },
        programName: "Partner Protection",
        pageTitle: "Energy Texas Protection Plan Claims"
    },
    cutler: {
        customerService: {
            email: 'AIGServiceCenter@aig.com',
            phone: phoneFormat('XXXXXXXXXX'),
        },
        sales: {
            phone: phoneFormat('XXXXXXXXXX'),
        },
        claimOverviewHero: {
            planNumber: 'Plan Number',
            planStatus: 'Plan Status',
            totalPlans: 'Total Plans'
        },
        planDetails: {
            paymentType: 'Trade Service Fee',
        },
        pageTitle: "AIG AppleCare+ with Theft and Loss Claims"
    },
    bestbuy: {
        customerService: {
            email: 'warranty-aigclaimscenter@aig.com',
            phone: phoneFormat('8002503819'),
        },
        sales: {
            phone: phoneFormat('8002503819'),
        },
        claimOverviewHero: {
            planNumber: 'Plan Number',
            planStatus: 'Plan Status',
            totalPlans: ''
        },
        planDetails: {
            paymentType: 'Trade Service Fee',
        },
        programName: "AIG Protection Plans",
        pageTitle: "AIG Protection Plan Claims"
    }
}

const getClientContent = (brandId) => {
    console.log(brandId)
    // NPB 03312021 - as of today, HWA is the only brand using alternative voicing/tone. anything other than HWA will return "conversational" text.
    const usingStandardTone = ['hwa','cutler']
    const lowerBrandId = brandId?.toLowerCase();
    const variableVoice = voiceValues[usingStandardTone.includes(lowerBrandId) ? 'standard' : 'conversational'];
    const variableText = textValues[lowerBrandId]
    return { variableText, variableVoice }
}

export default getClientContent