export const formFieldLabels = {
    // TOP LEVEL is for COUNTRY whose FORM is being called up
    AUS: {
        //SECOND LEVEL is for the LANGUAGE that the country's form needs to be translated into as per latest document
        en: {
            address1: 'Street Address',
            address2: 'Apt, Suite, Unit, Building (optional)',
            city: 'City - Suburb',
            state: 'State',
            zip: 'Postal Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Apt., Suite, Einheit, Gebäude (optional)',
            city: 'Stadt – Vorort',
            state: 'Bundesland',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'App., suite, unità, edificio (facoltativo)',
            city: 'Città - Sobborgo',
            state: 'Stato',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Appt., suite, unité, immeuble (facultatif)',
            city: 'Ville - banlieue',
            state: 'État',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Apt., oficina, unidad, edificio (opcional)',
            city: 'Ciudad - suburbio',
            state: 'Estado',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Căn hộ, Dãy căn hộ, Đơn vị, Tòa nhà (không bắt buộc)',
            city: 'Thành Phố - Ngoại thành',
            state: 'Tiểu bang',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dpto., habitación, unidad, edificio (opcional)',
            city: 'Ciudad - Barrio',
            state: 'Estado',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Apt., Suite, Einheit, Gebäude (optional)',
            city: 'Stadt – Vorort',
            state: 'Kanton',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Appartement, suite, eenheid, gebouw (optioneel)',
            city: 'Stad - buitenwijk',
            state: 'Staat',
            zip: 'Postcode'
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Leilighet, suite, enhet, bygning (valgfritt)',
            city: 'By – forstad',
            state: 'Stat',
            zip: 'Postnummer'
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Lägenhet, våning, enhet, byggnad (valfritt)',
            city: 'Ort – förort',
            state: 'Delstat',
            zip: 'Postnummer'
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Lejlighed, suite, afsnit, bygning (valgfrit)',
            city: 'By – forstad',
            state: 'Stat',
            zip: 'Postnummer'
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Asunto, huoneisto, yksikkö, rakennus (valinnainen)',
            city: 'Kaupunki – esikaupunki',
            state: 'Osavaltio',
            zip: 'Postinumero'
        },
        at: {
            address1: 'Straße',
            address2: 'Apt., Suite, Einheit, Gebäude (optional)',
            city: 'Stadt – Vorort',
            state: 'Bundesland',
            zip: 'Postleitzahl'
        }
    },

    DE: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    ES: {
        en: {
            address1: 'Address',
            address2: 'Apartment, Suite, Unit, Building or Floor',
            city: 'Town',
            state: 'Province',
            zip: 'Postal Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Apartment, Suite, Einheit, Gebäude oder Etage',
            city: 'Ort',
            state: 'Provinz',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Appartamento, suite, unità, edificio o piano',
            city: 'Città',
            state: 'Provincia',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse',
            address2: 'Appartement, suite, unité, immeuble ou étage',
            city: 'Ville',
            state: 'Province',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Apartamento, suite, unidad, edificio o piso',
            city: 'Ciudad',
            state: 'Provincia',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ',
            address2: 'Căn hộ, Dãy căn hộ, Đơn vị, Tòa nhà hoặc Tầng',
            city: 'Thành phố',
            state: 'Tỉnh',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección',
            address2: 'Departamento, habitación, unidad, edificio o piso',
            city: 'Localidad',
            state: 'Provincia',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Adresse',
            address2: 'Apartment, Suite, Einheit, Gebäude oder Etage',
            city: 'Stadt',
            state: 'Provinz',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Adres',
            address2: 'Appartement, suite, eenheid, gebouw of verdieping',
            city: 'Stad',
            state: 'Provincie',
            zip: 'Postcode',
        },
        no: {
            address1: 'Adresse',
            address2: 'Leilighet, suite, enhet, bygning eller etasje',
            city: 'By',
            state: 'Provins',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Adress',
            address2: 'Lägenhet, våning, enhet, byggnad eller våningsplan',
            city: 'Stad',
            state: 'Region',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Adresse',
            address2: 'Lejlighed, suite, afsnit, bygning eller etage',
            city: 'By',
            state: 'Provins',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Osoite',
            address2: 'Asunto, huoneisto, yksikkö, rakennus tai kerros',
            city: 'Kaupunki',
            state: 'Provinssi',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Adresse',
            address2: 'Apartment, Suite, Einheit, Gebäude oder Etage',
            city: 'Stadt',
            state: 'Provinz',
            zip: 'Postleitzahl',
        }
    },

    FR: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }

    },

    IT: {
        en: {
            address1: 'Address',
            address2: 'Apartment, Interior, Building Access Code',
            city: 'City',
            state: 'Province',
            zip: 'Postal Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Zugangscode zum Apartment, Innenbereich oder Gebäude',
            city: 'Ort',
            state: 'Provinz',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Appartamento, Interno, Codice di Accesso Edificio',
            city: 'Città',
            state: 'Stato',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse',
            address2: 'code d’accès intérieur, de l’appartement, du bâtiment',
            city: 'Ville',
            state: 'Province',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección',
            address2: 'Apartamento, interior, código de acceso al edificio',
            city: 'Ciudad',
            state: 'Provincia',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ',
            address2: 'Căn hộ, Nội thất, Mã ra vào tòa nhà',
            city: 'Thành phố',
            state: 'Tỉnh',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección',
            address2: 'Código de acceso de departamento, interior, edificio',
            city: 'Ciudad',
            state: 'Provincia',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Adresse',
            address2: 'Apartment, Inneneinrichtung, Gebäudezugangscode',
            city: 'Stadt',
            state: 'Provinz',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Adres',
            address2: 'Appartement, interieur, toegangscode gebouw',
            city: 'Stad',
            state: 'Provincie',
            zip: 'Postcode',
        },
        no: {
            address1: 'Adresse',
            address2: 'Leilighet, interiør, adgangskode for bygning',
            city: 'Sted',
            state: 'Provins',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Adress',
            address2: 'Lägenhet, byggnadens portkod',
            city: 'Ort',
            state: 'Region',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Adresse',
            address2: 'Lejlighed, indvendig, bygningsadgangskode',
            city: 'By',
            state: 'Provins',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Osoite',
            address2: 'Asunnon, sisätilojen, rakennuksen pääsykoodi',
            city: 'Kaupunki',
            state: 'Provinssi',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Adresse',
            address2: 'Zugangscode zum Apartment, Innenbereich oder Gebäude',
            city: 'Stadt',
            state: 'Provinz',
            zip: 'Postleitzahl',
        }
    },

    UK: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City',
            state: 'County (optional)',
            zip: 'Postal Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt',
            state: 'Landkreis  (optional)',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            state: 'Contea (facoltativo)',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            state: 'Département (facultatif)',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad',
            state: 'Municipio (opcional)',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố',
            state: 'Quận (không bắt buộc)',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad',
            state: 'Municipio (opcional)',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt',
            state: 'Bezirk (optional)',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            state: 'Provincie (optioneel)',
            zip: 'Postcode'
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'Sted',
            state: 'Fylke (valgfritt)',
            zip: 'Postnummer'
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort',
            state: 'Län (valfritt)',
            zip: 'Postnummer'
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            state: 'Amt (valgfrit)',
            zip: 'Postnummer'
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            state: 'Maakunta (valinnainen)',
            zip: 'Postinumero'
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt',
            state: 'Landkreis (optional)',
            zip: 'Postleitzahl'
        }
    },

    US: {
        en: {
            address1: 'Street Address',
            address2: 'Apt, Suite, Unit, Building (optional)',
            city: 'City',
            state: 'State',
            zip: 'ZIP Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Apt., Suite, Einheit, Gebäude (optional)',
            city: 'Stadt',
            state: 'Bundesland',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'App., suite, unità, edificio (facoltativo)',
            city: 'Città',
            state: 'Stato',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Appt., suite, unité, immeuble (facultatif)',
            city: 'Ville',
            state: 'État',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Apt., oficina, unidad, edificio (opcional)',
            city: 'Ciudad',
            state: 'Estado',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Căn hộ, Dãy căn hộ, Đơn vị, Tòa nhà (không bắt buộc)',
            city: 'Thành phố',
            state: 'Tiểu bang',
            zip: 'Mã ZIP',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dpto., habitación, unidad, edificio (opcional)',
            city: 'Ciudad',
            state: 'Estado',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Apt., Suite, Einheit, Gebäude (optional)',
            city: 'Stadt',
            state: 'Kanton',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Appartement, suite, eenheid, gebouw (optioneel)',
            city: 'Stad',
            state: 'Staat',
            zip: 'Postcode'
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Leilighet, suite, enhet, bygning (valgfritt)',
            city: 'Sted',
            state: 'Stat',
            zip: 'Postnummer'
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Lägenhet, våning, enhet, byggnad (valfritt)',
            city: 'Ort',
            state: 'Delstat',
            zip: 'Postnummer'
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Lejlighed, suite, afsnit, bygning (valgfrit)',
            city: 'By',
            state: 'Stat',
            zip: 'Postnummer'
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Asunto, huoneisto, yksikkö, rakennus (valinnainen)',
            city: 'Kaupunki',
            state: 'Osavaltio',
            zip: 'Postinumero'
        },
        at: {
            address1: 'Straße',
            address2: 'Apt., Suite, Einheit, Gebäude (optional)',
            city: 'Stadt',
            state: 'Bundesland',
            zip: 'Postleitzahl'
        }
    },

    VN: {
        en: {
            address1: 'Street Address',
            address2: 'Ward',
            city: 'District',
            state: 'City/Province',
            zip: 'Postal Code',
        },
        de: {
            address1: 'Adresse',
            address2: 'Station',
            city: 'Bezirk',
            state: 'Stadt/Provinz',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Circoscrizione',
            city: 'Distretto',
            state: 'Città/Provincia',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Circonscription',
            city: 'District',
            state: 'Ville/Province',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Distrito electoral',
            city: 'Distrito',
            state: 'Ciudad/Provincia',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Phường/Xã',
            city: 'Quận/Huyện',
            state: 'Thành Phố/Tỉnh',
            zip: 'Mã Bưu Chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Circunscripción',
            city: 'Distrito',
            state: 'Ciudad/Provincia',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Station',
            city: 'Bezirk',
            state: 'Stadt/Provinz',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Afdeling',
            city: 'District',
            state: 'Stad/provincie',
            zip: 'Postcode'
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Bydel',
            city: 'Distrikt',
            state: 'By/provins',
            zip: 'Postnummer'
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Avdelning',
            city: 'Distrikt',
            state: 'Ort/region',
            zip: 'Postnummer'
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Afdeling',
            city: 'Distrikt',
            state: 'By/provins',
            zip: 'Postnummer'
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Osasto',
            city: 'Piiri',
            state: 'Kaupunki/provinssi',
            zip: 'Postinumero'
        },
        at: {
            address1: 'Straße',
            address2: 'Station',
            city: 'Bezirk',
            state: 'Stadt/Provinz',
            zip: 'Postleitzahl'
        }
    },

    IE: {
        en: {
            address1: 'Street Address',
            address2: 'Apartment/Unit Number (optional)',
            city: 'City/Town',
            state: 'County (optional)',
            zip: 'Eircode'
        },
        de: {
            address1: 'Adresse',
            address2: 'Apartment-/Einheitsnummer (optional)',
            city: 'Stadt/Ort',
            state: 'Landkreis (optional)',
            zip: 'Eircode'
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Numero appartamento/unità (facoltativo)',
            city: 'Città',
            state: 'Contea (facoltativo)',
            zip: 'Eircode'
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Numéro d’appartement ou de bâtiment (facultatif)',
            city: 'Ville',
            state: 'Département (facultatif)',
            zip: 'Eircode',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Número de apartamento/unidad (opcional)',
            city: 'Ciudad/pueblo',
            state: 'Condado (opcional)',
            zip: 'Eircode',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Số căn hộ/phòng (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            state: 'Hạt (không bắt buộc)',
            zip: 'Eircode',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Número de departamento/unidad (opcional)',
            city: 'Ciudad/Localidad',
            state: 'Municipio (opcional)',
            zip: 'Eircode',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Wohnungs-/Einheitsnummer (optional)',
            city: 'Stadt/Ort',
            state: 'Kanton (optional)',
            zip: 'Eircode',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Nummer van appartement/eenheid (optioneel)',
            city: 'Stad',
            state: 'Land (optioneel)',
            zip: 'Eircode'
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Leilighets-/enhetsnummer (valgfritt)',
            city: 'By/sted',
            state: 'Fylke (valgfritt)',
            zip: 'Eircode'
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Lägenhet/enhetsnummer (valfritt)',
            city: 'Ort/stad',
            state: 'Län (valfritt)',
            zip: 'Eircode'
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Lejligheds-/afsnitsnummer (valgfrit)',
            city: 'By',
            state: 'Amt (valgfrit)',
            zip: 'Eircode'
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Asunnon/yksikön numero (valinnainen)',
            city: 'Kaupunki',
            state: 'Maakunta (valinnainen)',
            zip: 'Eircode'
        },
        at: {
            address1: 'Straße',
            address2: 'Apartment-/Einheitsnummer (optional)',
            city: 'Stadt/Ort',
            state: 'Landkreis (optional)',
            zip: 'Eircode'
        }
    },

    CL: {
        en: {
            address1: 'Street Address',
            address2: 'Apartment, Unit, or Floor',
            city: 'Community/Locality',
            state: 'Region',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Apartment, Einheit oder Stockwerk',
            city: 'Gemeinschaft/Region',
            state: 'Region',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Appartamento, unità o piano',
            city: 'Comunità/Località',
            state: 'Regione',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Appartement, bâtiment ou étage',
            city: 'Communauté/Localité',
            state: 'Région',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Apartamento, unidad o planta',
            city: 'Comunidad/Localidad',
            state: 'Región',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Căn hộ, Phòng hoặc tầng',
            city: 'Cộng đồng/Địa phương',
            state: 'Khu vực',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Departamento, unidad o piso',
            city: 'Comunidad/Localidad',
            state: 'Región',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Wohnung, Einheit oder Etage',
            city: 'Gemeinschaft/Region',
            state: 'Region',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Appartement, eenheid of verdieping',
            city: 'Gemeenschap/plaats',
            state: 'Regio',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Leilighet, enhet eller etasje',
            city: 'Samfunn/lokalitet',
            state: 'Region',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Lägenhet, enhet eller våning',
            city: 'Område/ort',
            state: 'Län',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Lejlighed, afsnit eller etage',
            city: 'Fællesskab/lokalitet',
            state: 'Region',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Asunto, yksikkö tai kerros',
            city: 'Yhteisö/paikka',
            state: 'Alue',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Apartment, Einheit oder Stockwerk',
            city: 'Gemeinde/Ort',
            state: 'Region',
            zip: 'Postleitzahl',
        }
    },

    AT: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    DK: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    FI: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    NL: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    NZ: {
        en: {
            address1: 'Street Address',
            address2: 'Suburb',
            city: 'City/Town',
            zip: 'Postcode'
        },
        de: {
            address1: 'Adresse',
            address2: 'Vorort',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Sobborgo',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Banlieue',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Suburbio',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Ngoại thành',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu điện',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Barrio',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Vorort',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Voorstad',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Forstad',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Förort',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Forstad',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Esikaupunki',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Vorort',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    NO: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    SE: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    },

    CH: {
        en: {
            address1: 'Street Address',
            address2: 'Additional Address (optional)',
            city: 'City/Town',
            zip: 'Postal Code'
        },
        de: {
            address1: 'Adresse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        it: {
            address1: 'Indirizzo',
            address2: 'Indirizzo aggiuntivo (facoltativo)',
            city: 'Città',
            zip: 'Codice postale',
        },
        fr: {
            address1: 'Adresse postale',
            address2: 'Adresse supplémentaire (facultatif)',
            city: 'Ville',
            zip: 'Code postal',
        },
        es: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/pueblo',
            zip: 'Código postal',
        },
        vn: {
            address1: 'Địa chỉ đường phố',
            address2: 'Địa chỉ bổ sung (không bắt buộc)',
            city: 'Thành phố/Thị trấn',
            zip: 'Mã bưu chính',
        },
        cl: {
            address1: 'Dirección postal',
            address2: 'Dirección adicional (opcional)',
            city: 'Ciudad/Localidad',
            zip: 'Código postal',
        },
        sg: {
            address1: 'Strasse',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        },
        nl: {
            address1: 'Huisadres',
            address2: 'Extra adres (optioneel)',
            city: 'Stad',
            zip: 'Postcode',
        },
        no: {
            address1: 'Gateadresse',
            address2: 'Ekstra adresse (valgfritt)',
            city: 'By/sted',
            zip: 'Postnummer',
        },
        sv: {
            address1: 'Gatuadress',
            address2: 'Ytterligare adress (valfritt)',
            city: 'Ort/stad',
            zip: 'Postnummer',
        },
        da: {
            address1: 'Gadenavn',
            address2: 'Yderligere adresse (valgfrit)',
            city: 'By',
            zip: 'Postnummer',
        },
        fi: {
            address1: 'Katuosoite',
            address2: 'Lisäosoite (valinnainen)',
            city: 'Kaupunki',
            zip: 'Postinumero',
        },
        at: {
            address1: 'Straße',
            address2: 'Weitere Adresse (optional)',
            city: 'Stadt/Ort',
            zip: 'Postleitzahl',
        }
    }
}