import React from 'react';
import {ReactComponent as Visa} from '../../../assets/images/cc-visa.svg'
import {ReactComponent as MasterCard} from '../../../assets/images/cc-mastercard.svg'
import {ReactComponent as AmEx} from '../../../assets/images/cc-amex.svg'
import {ReactComponent as Discover} from '../../../assets/images/cc-discover.svg'

const cardStyle = {
    height: '28px',
    marginRight: '4px'
}

export default function CreditCardIcon ({ type }) {
    const getIconByType = () => {
        switch(type) {
            case 'visa': return <Visa style={cardStyle} />
            case 'mastercard': return <MasterCard style={cardStyle} />
            case 'amex': return <AmEx style={cardStyle} />
            case 'discover': return <Discover style={cardStyle} />
        }
    }

    return (
        <span>
            {getIconByType()}
        </span>
    )
}