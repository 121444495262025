import React, { useState, useContext } from 'react';
import {Popover, PopoverHeader, PopoverBody, TabContent} from 'reactstrap';
import AssetInfoForm from './AssetForm'
import classNames from 'classnames'
import styles from './Asset.module.css'
import { useHistory} from 'react-router'
import { ContractContext } from '../../contexts/ContractContext';

const {popover, assetCard} = styles
const AssetPopover = ({
    isOpen, 
    popoverId, 
    togglePopover, 
    contractAssets,
    showModelSerial,
    assetName
}) => {
    const [activeAsset, setActiveAsset] = useState(0);
    // NPB 04292021 - These should not need to be passed. For some reason AssetInfoForm cannot access useContext or useHistory hooks. 
    const history = useHistory()
    const context = useContext(ContractContext)

    return (
        <Popover
            placement="bottom"
            isOpen={isOpen}
            flip="false"
            aria-modal="true"
            role="dialog"
            target={popoverId}
            boundariesElement="window"
            toggle={togglePopover}
            className={classNames(popover, assetCard)}
        >
            {contractAssets.length > 1 &&
                <PopoverHeader className={styles.popoverHeader}>
                    <ul tabs className={styles.navTabs}>
                        <li className={styles.navItem}>
                            <a
                                className={classNames(styles.navLink, (activeAsset === 0 && styles.active))}
                                onClick={() => { setActiveAsset(0); }}
                            >
                                {`Primary ${assetName}`}
                            </a>
                        </li>
                        <li className={styles.navItem}>
                            <a
                                className={classNames(styles.navLink, (activeAsset === 1 && styles.active))}
                                onClick={() => { setActiveAsset(1); }}
                            >
                                {`Secondary ${assetName}`}
                            </a>
                        </li>
                    </ul>
                </PopoverHeader>
            }
            <PopoverBody className={styles.popoverBody}>
                <TabContent activeTab={activeAsset}>
                    {contractAssets.map((asset, i) => (
                        <AssetInfoForm
                            activeAsset={activeAsset}
                            tabIndex={i}
                            showModelSerial={showModelSerial}
                            contractAsset={asset}
                            closePopover={togglePopover}
                            assetName={assetName}
                            // NPB 04292021 - These should not need to be passed. For some reason AssetInfoForm cannot access useContext or useHistory hooks. 
                            history={history}
                            context={context}
                        />
                    ))}
                </TabContent>
            </PopoverBody>
        </Popover>
    )
}

export default AssetPopover;