import React, { useState, useEffect, useRef, useContext } from 'react'
import styles from './Asset.module.css'
import { TabContent, TabPane, Row, Col, FormGroup, Label, Input, Form } from 'reactstrap';
import DatePicker from 'react-date-picker'
import { DropDownInput } from '../../../components/inputs/DropDownInput';
import Spinner from 'react-bootstrap/Spinner';
import NumberFormat from 'react-number-format';
import SimpleReactValidator from 'simple-react-validator';
import getClientContent from '../../_BrandingProvider/brandContent';
import { ContractContext } from '../../contexts/ContractContext';
import MultiDropDown from '../../shared/MultiDropDown/MultiDropDown';
import qs from 'qs';
import DateField from '../../shared/DateField/DateField';
import HistoricalClaimModal from "../../modals/HistoricalClaimModal";
import classNames from 'classnames';
import InfoTooltip from "../../shared/InfoTooltip/InfoTooltip";
import moment from 'moment';

const AssetInfoForm = ({
    showModelSerial,
    contractAsset,
    closePopover,
    tabIndex,
    assetName,
    // NPB 04292021 - These should not need to be passed. For some reason AssetInfoForm cannot access useContext or useHistory hooks. 
    context,
    history
}) => {
    const [contract, setContract] = useState(context.data.contract)
    const [contracts, setContracts] = useState(context.data.contracts)
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedVal, setSelectedVal] = useState([]);
    const [selectedProductDescriptions, setSelectedProductDescriptions] = useState([])
    const [serial, setSerial] = useState('');
    const [model, setModel] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [deductible, setDeductible] = useState(0);
    const [saving, setSaving] = useState(false);
    const [hwaToken, setHwaToken] = useState(qs.parse(history.location.search, { ignoreQueryPrefix: true }).id);
    const [errors, setErrors] = useState({
        productDescription: false,
        brand: false,
    })
    const [validationFired, setValidationFired] = useState(false)
    const [, forceUpdate] = useState();
    const [hasSaved, setHasSaved] = useState(false);

    const { clientId } = contract
    const [showHWAPortfolioModal, setShowHWAPortfolioModal] = useState(false);
    const [hidePurchaseDate, setHidePurchaseDate] = useState(context.data.contracts.find(c => c.contractId == contractAsset.contractId)?.assets.find(cam => cam.assetId == contractAsset.assetId)?.removePurchaseDate);

    let [contractId, setContractId] = useState(contractAsset.contractId);

    useEffect(() => {
        getBrands().then(brands => {
            setBrands(brands);
        });
        setContractId(contractAsset.contractId);
        setSerial(contractAsset.serialNumber);
        setModel(contractAsset.modelNumber);

        let brandId = contractAsset.brandId == 1806 ? 0 : contractAsset.brandId;
        setSelectedBrand(brandId);

        if (!!contractAsset.assetPurchaseDate) {
            setPurchaseDate(new Date(contractAsset.assetPurchaseDate));
        }
        let productList = getProducts();

        setProducts(productList);

        let selectedAttributes = contractAsset?.contractAssetConfigurationModel?.filter(cac => cac.contractAssetAttributeId == 2)?.map((x) => {
            return { data: parseInt(x.value), display: productList.find(y => y.data == x.value)?.display }
        }) ?? [];

        setSelectedProductDescriptions(selectedAttributes);
        getDeductible(contractAsset.offerId);
    }, [hasSaved]);

    useEffect(() => {
        setContractId(contractAsset.contractId);
        let removePurchaseDate = context.data.contracts.find(c => c.contractId == contractAsset.contractId)?.assets.find(cam => cam.assetId == contractAsset.assetId)?.removePurchaseDate;
        setHidePurchaseDate(removePurchaseDate);
    }, [assetName]);

    const assetDescriptionsFilled = () => {
        return ((selectedProductDescriptions != 0 && typeof selectedProductDescriptions != 'undefined' && selectedProductDescriptions?.length > 0)
            || (selectedVal != 0 && typeof selectedVal != 'undefined' && selectedVal?.length > 0))
    }

    const handleOptionClick = (e) => {

        const options = getProducts();
        const sel = options.find(o => o.data === e);
        const val = selectedProductDescriptions.length > 0 ? selectedProductDescriptions : selectedVal
        const nextSelected = [...val];
        //if (!selectedVal.includes(sel)) {
        //    nextSelected.push(sel)
        //} else {
        //    const selIndex = nextSelected.indexOf(sel)
        //    nextSelected.splice(selIndex, 1)
        //}
        let addInNext = true;
        val.forEach((element, index) => {
            if (element.data == sel.data) {
                addInNext = false;
                nextSelected.splice(index, 1);
                return;
            }
        })
        if (addInNext) {
            nextSelected.push(sel)
        }
        setSelectedProductDescriptions(nextSelected)
        setSelectedVal(nextSelected)
        setErrors({
            ...errors,
            product: false,
        })
        
        forceUpdate(1)
    }

    const getBrands = () => {
        return new Promise((resolve, reject) => {
            fetch(`api/brand/${clientId}`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                });
        });
    }

    const getProducts = () => {
        let products = contractAsset?.assetAttributes.map(aa => {
            return (
                { data: aa.attributeId, display: aa.attributeName }
            )
        });
        return products
    }

    const getDeductible = (offerId) => {
        fetch(`api/contract/GetDeductibleAmountByOfferId/${offerId}/${clientId}`)
            .then(res => res.json())
            .then(data => {
                setDeductible(data);
            });
    }

    const validator = useRef(new SimpleReactValidator({
        validators: {
            product: {
                message: "Product description is required.",
                rule: (val, params, validator) => {
                    return (val)
                },
                required: true
            },
            brand: {
                message: "Brand is required.",
                rule: (val, params, validator) => {
                    return (val != 1806 && typeof val != 'undefined' && val != 0)
                },
                required: true
            }
        },
        element: message => (
            <div className="errorMsg" style={{ textAlign: 'left' }}>
                {message.charAt(0).toUpperCase() + message.slice(1)}
            </div>
        ),
        messages: {
            required: ':attribute is required.'
        },
        autoForceUpdate: { forceUpdate: forceUpdate }
    }));

    const brandOptions = brands.map(b => {
        return {
            data: b.brandId,
            display: b.name
        }
    });

    const handleSelectedBrand = (e) => {
        setSelectedBrand(e);
        setErrors({
            ...errors,
            brand: false
        })
        validator.current.hideMessageFor('selectedBrand');
        forceUpdate(1);
    }

    const handleSerial = e => {
        setSerial(e.target.value);
    }
    const handleModel = e => {
        setModel(e.target.value);
    }

    const handleFileClaimClick = async () => {
        setValidationFired(true)
        if (validator.current.allValid()) {
            validator.current.hideMessages();

            let actualAssetId = contractAsset.assetId;
            let actualAssetModel = contract.assets.find(a => a.assetId == actualAssetId) ?? contracts.find(c => c.assets.find(a => a.assetId == actualAssetId))?.assets.find(a => a.assetId == actualAssetId);
            let actualAssetName = actualAssetModel.assetName;
            let alternateCoverage = contractAsset.contractAssetCoverageModel.find(cac => cac.coverageModel?.coverageName == assetName);

            //Check for HWA Portfolio WOW before creating claim
            fetch(`api/claim/CheckPortfolioClaimFlag/${contractAsset?.contractAssetId}/${clientId}`)
                .then(res => res.json())
                .then(async portfolioData => {
                    if (clientId?.toLowerCase() == 'hwa' && portfolioData == true) {
                        setShowHWAPortfolioModal(true);
                    }
                    else {
                        forceUpdate(1)
                        const assetId = contractAsset.contractAssetId;
                        const isClaimableCoverage = contractAsset?.contractAssetCoverageModel[0]?.claimableCoverage ?? false;
                        let coverageId = contractAsset.contractAssetCoverageModel[0].coverageId
                        
                        if (actualAssetName != assetName && typeof alternateCoverage?.coverageId !== "undefined") { // Dwelling Assets
                            coverageId = alternateCoverage?.coverageId;
                        }
                        
                        const settings = {
                            method: 'POST',
                            body: JSON.stringify({
                                "contractAssetId": assetId,
                                "clientId": clientId,
                                "claimIdToResume": 0,
                                "assetId": contractAsset.assetId,
                                "coverageId": coverageId,
                                "isClaimableCoverage": isClaimableCoverage
                            }
                            ),
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            }
                        }

                        // SRV 4/6/2021 save asset info
                        saveAssetAsync();

                        const response = await fetch(`/api/claim/`, settings);
                        if (!response.ok) throw Error(response.message);
                        const data = await response.json();
                        try {
                            if (contractId) {
                                //get contract
                                fetch(`api/contract/${contractId}/${clientId}`)
                                    //.then(res => res.json())
                                    .then(res => {
                                        if (res.ok) {
                                            res.json().then(cdata => {
                                                let riskProfile = cdata.riskProfile.riskProfileConfigurationModel;
                                                let deductibleToPass = riskProfile?.find(item => { return item.pricingAttributeId === 19 })?.value;
                                                // SRV 3/27/21
                                                // if deductible is null, it's likely stored at the offer level (which we got from the method above)
                                                // let's set to that value, if that's also null, it will get defaulted to 0 below
                                                if (typeof deductibleToPass === "undefined" || deductibleToPass === null)
                                                    deductibleToPass = deductible;

                                                let featureIndex = data.featureClaimModel.length - 1;
                                                let contractAssetCoverageId = typeof data.featureClaimModel[featureIndex] === "undefined" ? null : data.featureClaimModel[featureIndex].contractAssetCoverageId;
                                                //const entitlementDetails = {
                                                const entitlementDetails = {
                                                    clientId: clientId,
                                                    contract: cdata,
                                                    contractAsset: cdata.contractAssetModel[0],
                                                    party: cdata.party,
                                                    deductible: deductibleToPass || 0,
                                                    claimId: data.claimId,
                                                    claimNumber: data.claimNumber,
                                                    caseNumber: data.clientClaimNumber,
                                                    coverageId: contractAssetCoverageId,
                                                    billingAddress: cdata.party.billingAddress,
                                                    assetId: contractAsset.assetId,
                                                    selectedSymptom: data.symptom,
                                                    ...cdata.party.billingAddress
                                                };

                                                const claim = {
                                                  ...data, claimableCoverages: isClaimableCoverage
                                                }

                                                //}

                                                history.push({
                                                    pathname: `/fileclaim/entitlement/${assetId}/${data.claimId}/${contractId}/${coverageId}`,
                                                    search: typeof hwaToken !== "undefined" ? `?id=${hwaToken}` : '',
                                                    state: { entitlementDetails: entitlementDetails, claim: claim }
                                                });
                                            });
                                        }
                                    });
                            }

                            //return data;
                        } catch (err) {
                            throw err;
                        }
                    }
                });
        }
        else {
            const productDescriptionError = !assetDescriptionsFilled()
            setErrors({
                brand: !selectedBrand,
                product: productDescriptionError,
            })
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    const saveAssetAsync = () => {
        var assetInfo = {
            brandId: selectedBrand,
            assetAttributeIds: selectedVal.map(x => x.data),
            serialNumber: serial,
            modelNumber: model,
            purchaseDate: purchaseDate
        }
        fetch(`api/contract/contractAsset/${contractAsset.contractAssetId}/${clientId}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(assetInfo)
        }).then(res => {
            if (res.ok) {
                fetch('api/contract/' + contractId + '/' + clientId)
                    .then(res => res.json())
                    .then(data => {
                        context.updateContract(data);
                        context.updateParty(data.party);
                        setContract(data);
                    });
                forceUpdate(1)
                closePopover();
            }

            setSaving(false);
            setHasSaved(true);
        })
    }

    const saveAsset = (e) => {
        e.preventDefault();
        setSaving(true);

        var assetInfo = {
            brandId: selectedBrand,
            assetAttributeIds: selectedVal.map(x => x.data),
            serialNumber: serial,
            modelNumber: model,
            purchaseDate: purchaseDate
        }

        fetch(`api/contract/contractAsset/${contractAsset.contractAssetId}/${clientId}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(assetInfo)
        }).then(res => {
            if (res.ok) {
                // SRV - 4/16/2021 this was causing issues with the AssetList component on line 17, since all we're doiong is saving the info
                // no need to refetch and rehydrate state
                fetch('api/contract/' + contractId + '/' + clientId)
                    .then(res => res.json())
                    .then(data => {
                        context.updateContract(data);
                        context.updateParty(data.party);
                        setContract(data);
                    });
                forceUpdate(1)
                closePopover();
            }
            setSaving(false);
            setHasSaved(true);
        })
    }
    const updatePurchaseDate = (date) => {
        setPurchaseDate(date);
        //e.preventDefault();        

        var assetInfo = {
            brandId: contractAsset.brandId,
            assetAttributeIds: contractAsset.assetAttributeIds,// selectedVal.map(x => x.data),
            serialNumber: contractAsset.serialNumber,
            modelNumber: contractAsset.modelNumber,
            purchaseDate: date
        }

        fetch(`api/contract/contractAsset/${contractAsset.contractAssetId}/${clientId}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(assetInfo)
        }).then(res => {
            if (res.ok) {
                console.log('Purchase date Updated Successfully');
            }

        })
    }


    const BrandDropDownLoader = () => (
        <div className="optionsBtn dropbtn dropbtn-placeholder form-control">
            <Spinner color="light" animation={"border"} />
            <span className="ml-2">Loading Brands...</span>
        </div>
    )
    const isHWA = clientId === 'hwa';
    const flexDirection = isHWA ? styles.flexDirectionRow : styles.flexDirectionCol;
    const purchaseDateLabel = isHWA || contractAsset.assetPurchaseDate ? 'Purchase Date' : 'Purchase Date (if known)';

    return (
        <div>
            <TabPane tabId={tabIndex} id="panel1" tabIndex="-1" labeledby="tab1">
                <div className={styles.assetForm}>
                    <div>
                        {showModelSerial &&
                            <>
                                <Row>
                                    <Col md="6" className="pr-11">
                                        <FormGroup className={errors.brand && validationFired && "form-error"}>
                                            <Label htmlFor="" className="form-label">Brand</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            {brandOptions.length > 0 ? (
                                                <DropDownInput name="selectedBrand"
                                                    options={brandOptions}
                                                    className="form-control"
                                                    onChangedValue={(e) => handleSelectedBrand(e)}
                                                    defaultValue={selectedBrand}
                                                    placeholder="Please select..."
                                                    hasSearch
                                                />
                                            ) : (
                                                <BrandDropDownLoader />
                                            )}
                                            {validator.current.message("selectedBrand", selectedBrand, "brand")}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" className="pl-11">
                                        <FormGroup className={errors.product && validationFired && "form-error"}>
                                            <Label htmlFor="" className="form-label">Product Description</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <MultiDropDown
                                                name="selectedProduct"
                                                options={products}
                                                onOptionClick={handleOptionClick}
                                                selected={selectedProductDescriptions.length > 0 ? selectedProductDescriptions : selectedVal}
                                                className="form-control"
                                                hasSearch
                                                checkmarkBrand={clientId}
                                                hasError={errors.product && validationFired}
                                            />

                                            {validator.current.message("selectedProduct", assetDescriptionsFilled(), "product")}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6" className="pr-11">
                                        <FormGroup>
                                            <Label htmlFor="" className="form-label">Model Number</Label>
                                            <Input type="text" className="form-control" id="" placeholder=""
                                                name="model"
                                                defaultValue={contractAsset.modelNumber}
                                                onChange={handleModel}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" className="pl-11">
                                        <FormGroup>
                                            <Label htmlFor="" className="form-label">Serial Number</Label>
                                            <Input type="text" className="form-control" id="" placeholder=""
                                                name="serial"
                                                defaultValue={contractAsset.serialNumber}
                                                onChange={handleSerial}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row className={flexDirection}>
                            {!hidePurchaseDate && (
                                <Col>
                                    <FormGroup>
                                        <Label for="purchaseDate" className="form-label mr-2">{purchaseDateLabel}</Label>
                                        {clientId === 'et' && (
                                            <span className={styles.infoIcon}>
                                                <InfoTooltip
                                                    infoType="questionInfo"
                                                    message="This is the purchase date of the product needing repaired. If unknown, do not enter a date."
                                                />
                                            </span>
                                        )}
                                        {contractAsset.assetPurchaseDate ? (
                                            <div>{moment(contractAsset.assetPurchaseDate).format('MM/DD/YYYY')}</div>
                                        ) : (
                                            <DateField
                                                onDateChange={clientId === 'et' ? updatePurchaseDate : setPurchaseDate}
                                                name="purchaseDate"
                                                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 25))}
                                            />
                                        )}
                                    </FormGroup>
                                </Col>
                            )}
                            {deductible > 0 && (
                                <Col className={classNames(styles.tradeFeeWrapper, 'mb-4')}>
                                    <div className={styles.tradeFee}>
                                        <span className={'mr-3'}>
                                            {clientId && getClientContent(clientId).variableText.planDetails.paymentType}
                                        </span>
                                        <h3 className="mb-0"><NumberFormat value={deductible} displayType={'text'} prefix={'$'} /></h3>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <div className={styles.btnWrapper}>
                            <button className={classNames('btn btn-primary', showModelSerial ? 'ml-3' : 'ml-0')} onClick={handleFileClaimClick}>
                                File Claim
                            </button>
                            {!hidePurchaseDate && isHWA && (
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    onClick={saveAsset}
                                >
                                    <Spinner color="light" animation={saving ? "border" : ""} />
                                    {saving ? "" : "Save"}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </TabPane>
            <HistoricalClaimModal
                isOpen={showHWAPortfolioModal}
                assetName={assetName}
                clientId={clientId}
                //isRequestedClaimSetup={(value) => this.setState({ isRequestedClaimSetup: value }, () => this.fileClaim(this.state.contractAssetId, this.state.assetName))}
                toggle={() => setShowHWAPortfolioModal(!showHWAPortfolioModal)}
            />
        </div >
    )
}

export default AssetInfoForm;