import React, { Component } from 'react';
import { Input } from 'reactstrap';

const defaultCardBlocks = [4, 4, 4, 4];
const defaultCardLength = 19;
const defaultCardType = 'unknown';
const displayDigits = 4;

export class CreditCardInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNumber: '', // Card Number with spaces but without hidden digits. For tracking position for Delete/Backspace
            cardNumberDisplayed: '', // Card Number with spaces and with hidden digits. For UI.
            cardNumberHidden: '', // Card number without spaces and digits. 
            cardLength: defaultCardLength,
            cardType: defaultCardType,
            blocks: defaultCardBlocks
        }

        this.onKeyDown = this.onKeyDown.bind(this);

        this.cardInfo = {
            amex: {
                blocks: [4, 6, 5],
                regex: /^3[47]\d{0,13}/
            },
            discover: {
                blocks: [4, 4, 4, 4],
                regex: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/
            },
            mastercard: {
                blocks: [4, 4, 4, 4],
                regex: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/
            },
            visa: {
                blocks: [4, 4, 4, 4],
                regex: /^4\d{0,15}/
            }
        }
    }

    onKeyDown = e => {
        let { key } = e;
        let { selectionStart, selectionEnd } = e.target;
        let cardNumber = '';
        let numOfSelectedItems = selectionEnd - selectionStart;

        if (!isNaN(key)) {
            cardNumber = this.onNewNumber(key, selectionStart, numOfSelectedItems);
        } else if (key === 'Backspace') {
            cardNumber = this.onBackspace(selectionStart, numOfSelectedItems);
        } else if (key === 'Delete') {
            cardNumber = this.onDelete(selectionStart, numOfSelectedItems);
        } else {
            return;
        }

        if (cardNumber.length <= this.state.cardLength) {
            this.setState({
                cardNumber: cardNumber,
                cardNumberHidden: this.getCardNumberWithoutSpaces(cardNumber)
            }, this.onCardUpdated);
        }
    }

    onNewNumber = (key, pos, numItems) => {
        let cardNumberArray = this.state.cardNumber.split("");
        cardNumberArray.splice(pos, numItems, key);
        return this.getCardNumberWithDelimiters(cardNumberArray);
    }

    onBackspace = (pos, numItems) => {
        let cardNumberArray = this.state.cardNumber.split("");

        if (pos >= this.state.cardNumber.length) {
            cardNumberArray.pop();
        } else if (pos !== 0 || (pos === 0 && numItems > 0)) {
            let itemsToDelete = numItems === 0 ? 1 : numItems;
            let startIndex = numItems > 1 ? pos : pos - 1;
            cardNumberArray.splice(startIndex, itemsToDelete);
        }

        return this.getCardNumberWithDelimiters(cardNumberArray);
    }

    onDelete = (pos, numItems) => {
        let cardNumberArray = this.state.cardNumber.split("");
        let itemsToDelete = numItems === 0 ? 1 : numItems;
        cardNumberArray.splice(pos, itemsToDelete);
        return this.getCardNumberWithDelimiters(cardNumberArray);
    }

    onCardUpdated = () => {
        let blocks = defaultCardBlocks;
        let cardLength = defaultCardLength;
        let cardType = defaultCardType;

        for (let card in this.cardInfo) {
            let regex = this.cardInfo[card].regex;
            if (regex.test(this.state.cardNumberHidden)) {
                blocks = this.cardInfo[card].blocks;
                cardType = card;
                cardLength = this.cardInfo[card].blocks.reduce((current, next) => {
                    return current + next;
                }, 0) + this.cardInfo[card].blocks.length - 1;
            }
        }

        this.setState({
            cardType: cardType,
            cardLength: cardLength,
            blocks: blocks,
            cardNumberDisplayed: this.getCardNumberForDisplay()
        }, () => {
            this.props.onCreditCardTypeChanged(cardType);
            this.props.handleInputChange({
                target: {
                    name: this.props.name,
                    value: this.state.cardNumberHidden
                }
            });
        });
    }

    getCardNumberWithDelimiters = (cardNumberArray) => {
        var currentIndex = 0;
        cardNumberArray = cardNumberArray.filter(digit => digit !== " ");
        this.state.blocks.forEach((val) => {
            currentIndex += val;
            if (cardNumberArray.length > currentIndex) {
                cardNumberArray.splice(currentIndex, 0, " ");
                currentIndex++;
            }
        });

        return cardNumberArray.join("");
    }

    getCardNumberWithoutSpaces = (cardNumber) => {
        return cardNumber.split("").filter(digit => digit !== " ").join("");
    }

    getCardNumberForDisplay = () => {
        let cardNumberArray = this.state.cardNumberHidden.split("");
        var hiddenDigitStart = cardNumberArray.length - displayDigits;
        var updatedArray = cardNumberArray.map((item, index) => {
            if (index >= hiddenDigitStart)
                return item;
            return "*";
        });
        return this.getCardNumberWithDelimiters(updatedArray);
    }

    render() {
        return (
            <React.Fragment>
                <Input onKeyDown={this.onKeyDown}
                    aria-labelledby={this.props.arialabelledby}
                    autoComplete={this.props.autoComplete}
                    aria-required={this.props.ariarequired}
                    className={this.props.className}
                    maxlength={this.state.cardLength}
                    value={this.state.cardNumberDisplayed}
                    id={this.props.id}
                    name={this.props.name}
                    cardNum={this.state.cardNumberHidden}
                    inputmode="tel"
                />
            </React.Fragment>
        );
    }
}