import React, { Component, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import classNames from "classnames";
import styles from './CoverageDocs.module.css';
import DocumentTitle from 'react-document-title';

import { ReactComponent as AustraliaIcon } from '../../../assets/images/Vendor/Apple/flags/Australia.svg';
import { ReactComponent as MapImage } from '../../../assets/images/Vendor/Apple/map.svg';
import { ReactComponent as GermanyIcon } from '../../../assets/images/Vendor/Apple/flags/Germany.svg';
import { ReactComponent as UnitedKingdomIcon } from '../../../assets/images/Vendor/Apple/flags/UnitedKingdom.svg';
import { ReactComponent as UnitedStatesIcon } from '../../../assets/images/Vendor/Apple/flags/UnitedStates.svg';
import Germany_TheftandLoss from "../../../assets/Documents/CoverageDocuments/Countries/Germany_TheftandLoss.pdf";
import useTranslation from "../../../components/contexts/LanguageTranslation/useTranslation";
import { CircleFlag } from 'react-circle-flags'

let pLink = styles.pLink;

const GermanyFlag = () => <CircleFlag countryCode='de' height="24" style={{ marginRight: '16px' }} />
const SpainFlag = () => <CircleFlag countryCode='es' height="24" style={{ marginRight: '16px' }} />
const ItalyFlag = () => <CircleFlag countryCode='it' height="24" style={{ marginRight: '16px' }} />
const FranceFlag = () => <CircleFlag countryCode='fr' height="24" style={{ marginRight: '16px' }} />
const AustriaFlag = () => <CircleFlag countryCode='at' height="24" style={{ marginRight: '16px' }} />
const UKFlag = () => <CircleFlag countryCode='gb' height="24" style={{ marginRight: '16px' }} />
const VietnamFlag = () => <CircleFlag countryCode='vi' height="24" style={{ marginRight: '16px' }} />
const AustraliaFlag = () => <CircleFlag countryCode='au' height="24" style={{ marginRight: '16px' }} />
const DenmarkFlag = () => <CircleFlag countryCode='dk' height="24" style={{ marginRight: '16px' }} />
const FinlandFlag = () => <CircleFlag countryCode='fi' height="24" style={{ marginRight: '16px' }} />
const IrelandFlag = () => <CircleFlag countryCode='ie' height="24" style={{ marginRight: '16px' }} />
const NetherlandsFlag = () => <CircleFlag countryCode='nl' height="24" style={{ marginRight: '16px' }} />
const NorwayFlag = () => <CircleFlag countryCode='no' height="24" style={{ marginRight: '16px' }} />
const NewzealandFlag = () => <CircleFlag countryCode='nz' height="24" style={{ marginRight: '16px' }} />
const SwitzerlandFlag = () => <CircleFlag countryCode='ch' height="24" style={{ marginRight: '16px' }} />
const SwedenFlag = () => <CircleFlag countryCode='se' height="24" style={{ marginRight: '16px' }} />
const USFlag = () => <CircleFlag countryCode='us' height="24" style={{ marginRight: '16px' }} />
const ChileFlag = () => <CircleFlag countryCode='cl' height="24" style={{ marginRight: '16px' }} />


const getSwissLink = (lang) => {
    switch (lang) {
        case 'fr':
            return 'https://www.apple.com/chfr/legal/sales-support/applecare/applecareplus/ch-fr/tl/';
        case 'sg':
            return 'https://www.apple.com/chde/legal/sales-support/applecare/applecareplus/ch-de/tl/';
        case 'it':
            return 'https://www.apple.com/legal/sales-support/applecare/applecareplus/ch-it/tl/';
        case 'en':
            return 'https://www.apple.com/chde/legal/sales-support/applecare/applecareplus/ch-de/tl/';
        default:
            return 'https://www.apple.com/chde/legal/sales-support/applecare/applecareplus/ch-de/tl/';
    }
}

const RegionHeader = ({
    heading
}) => {
    return (
        <div className={styles.regionHeader}>
            <h4 className={classNames(styles.headingLabel, "mb-6")}>
                {heading}
            </h4>
            <hr className={styles.seperator} />
        </div>
    )
}

const CountryContainer = ({
    icon,
    label,
    link = '',
    ariaLabel,
    countryHandler = () => { }
}) => {
    return (
        <div className={styles.countryItem}>
            <a href={link} onClick={countryHandler} ariaLabel={ariaLabel} target="_blank" className={styles.countryLabel}>
                <div className={styles.flagIcon}>{icon}</div>
                <div className={styles.flagLabel}>{label}</div>
            </a>
        </div>
    );
}

const InternationalCoverageDocs = ({ language }) => {
    const international = useTranslation('screens/Vendor/Apple/InternationalCoverageDocs')
    const { translations, isTranslationResolved } = international

    if (!isTranslationResolved) return null

    return (

        <div className={styles.pageContainer}>
            <div className={styles.pageHeaderCoverDetails}>
                <Container className={styles.pageHeaderContentCoverDetails}>
                    <h1 className="mb-lg-3 mb-md-3 mb-sm-3 mb-1">{translations.title || "Want to View Your Coverage Details?"}</h1>
                    <p>
                        {translations.subtitle || "Select the country where you purchased your AppleCare+ with Theft and Loss plan."}
                    </p>
                    {/* <p class="mb-0">Or <a href="https://www.apple.com/legal/sales-support/applecare/applecareplus/us/" target="blank">click here</a> to view the AppleCare+ portion of your AppleCare+ with Theft and Loss terms and conditions.</p> */}
                </Container>
            </div>
            <Container>
                <Row>
                    <Col className={styles.pageBodyContent}>
                        <div className={styles.mapContainer}><MapImage /></div>
                    </Col>
                </Row>
                <Row>
                    <Col className={styles.countryHeading}>
                        <h2 className={classNames("h4", styles.headingLabelMain)}>{translations.selectCountry || "Choose Your Country"}</h2>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" lg="9" className="mx-auto">
                        <RegionHeader heading={translations.europe || "Europe"} />
                        <div className={styles.regionContainer}>
                            <CountryContainer
                                icon={AustriaFlag()}
                                label={translations.austria || "Austria"}
                                ariaLabel={translations.austriaAria || "Click to open the Austria AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/at/legal/sales-support/applecare/applecareplus/at/tl/'}
                            />
                            <CountryContainer
                                icon={DenmarkFlag()}
                                label={translations.denmark || "Denmark"}
                                ariaLabel={translations.denmarkAria || "Click to open the Denmark AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/dk/legal/sales-support/applecare/applecareplus/dk/tl/'}
                            />
                            <CountryContainer
                                icon={FinlandFlag()}
                                label={translations.finland || "Finland"}
                                ariaLabel={translations.finlandAria || "Click to open the Finland AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/fi/legal/sales-support/applecare/applecareplus/fi/tl/'}
                            />
                            <CountryContainer
                                icon={FranceFlag()}
                                label={translations.france || "France"}
                                ariaLabel={translations.franceAria || "Click to open the France AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/fr/legal/sales-support/applecare/applecareplus/fr/tl'}
                            />
                            <CountryContainer
                                icon={GermanyFlag()}
                                label={translations.germany || "Germany"}
                                ariaLabel={translations.germanyAria || "Click to open the Germany AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/de/legal/sales-support/applecare/applecareplus/de/tl/'}
                            />
                            <CountryContainer
                                icon={IrelandFlag()}
                                label={translations.ireland || "Ireland"}
                                ariaLabel={translations.irelandAria || "Click to open the Ireland AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/ie/legal/sales-support/applecare/applecareplus/ie/tl/'}
                            />
                            <CountryContainer
                                icon={ItalyFlag()}
                                label={translations.italy || "Italy"}
                                ariaLabel={translations.italyAria || "Click to open the Italy AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/it/legal/sales-support/applecare/applecareplus/it/tl'}
                            />
                            <CountryContainer
                                icon={NetherlandsFlag()}
                                label={translations.netherlands || "Netherlands"}
                                ariaLabel={translations.netherlandsAria || "Click to open the Netherlands AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/nl/legal/sales-support/applecare/applecareplus/nl/tl/'}
                            />
                            <CountryContainer
                                icon={NorwayFlag()}
                                label={translations.norway || "Norway"}
                                ariaLabel={translations.norwayAria || "Click to open the Norway AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/no/legal/sales-support/applecare/applecareplus/no/tl/'}
                            />
                            <CountryContainer
                                icon={SpainFlag()}
                                label={translations.spain || "Spain"}
                                ariaLabel={translations.spainAria || "Click to open the Spain AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/es/legal/sales-support/applecare/applecareplus/es/tl'}
                            />
                            <CountryContainer
                                icon={SwedenFlag()}
                                label={translations.sweden || "Sweden"}
                                ariaLabel={translations.swedenAria || "Click to open the Sweden AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/se/legal/sales-support/applecare/applecareplus/se/tl/'}
                            />
                            <CountryContainer
                                icon={SwitzerlandFlag()}
                                label={translations.switzerland || "Switzerland"}
                                ariaLabel={translations.switzerlandAria || "Click to open the Spain AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={getSwissLink(language)}
                            />
                            <CountryContainer
                                icon={UKFlag()}
                                label={translations.unitedKingdom || "United Kingdom"}
                                ariaLabel={translations.unitedKingdomAria || "Click to open the United Kingdom AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/uk/legal/sales-support/applecare/applecareplus/uk/tl/'}
                            />
                        </div>
                    </Col>
                </Row>
                <br className="show-md-up" />

                <Row>
                    <Col lg="9" className="mx-auto">
                        <RegionHeader heading={translations.asiaPacific || "Asia Pacific"} />
                        <div className={styles.regionContainer}>
                            <CountryContainer
                                icon={AustraliaFlag()}
                                label={translations.australia || "Australia"}
                                ariaLabel={translations.australiaAria || "Click to open the Australia AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/au/legal/sales-support/applecare/applecareplus/au/tl/'}
                            />
                            <CountryContainer
                                icon={NewzealandFlag()}
                                label={translations.newZealand || "New Zealand"}
                                ariaLabel={translations.newZealandAria || "Click to open the New Zealand AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'http://www.apple.com/nz/legal/sales-support/applecare/applecareplus/nz/tl/'}
                            />
                            {/* left for future implementation */}
                            {/*<CountryContainer*/}
                            {/*    icon={VietnamFlag()}*/}
                            {/*    label={translations.vietnam || "Vietnam"}*/}
                            {/*    ariaLabel={translations.vietnamFlag || "Click to open the United States AppleCare+ with Theft and Loss coverage documents in a new window."}*/}
                            {/*// countryHandler={onSelectUsMap}  // Displaying the vietnam flag as per the Design screen*/}
                            {/*/>*/}
                        </div>
                    </Col>
                </Row>
                <br className="show-md-up" />

                <Row>
                    <Col lg="9" className="mx-auto">
                        <RegionHeader heading={translations.northAmerica || "North America"} />
                        <div className={styles.regionContainer}>
                            <CountryContainer
                                icon={USFlag()}
                                label={translations.unitedStates || "United States"}
                                ariaLabel={translations.unitedStatesAria || "Click to open the United States AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/legal/applecare/applecareplus/theftandloss/'}
                            />
                        </div>
                    </Col>
                </Row >
                <br className="show-md-up" />

                <Row>
                    <Col lg="9" className="mx-auto">
                        <RegionHeader heading={translations.southAmerica || "South America"} />
                        <div className={styles.regionContainer}>
                            <CountryContainer
                                icon={ChileFlag()}
                                label={translations.chile || "Chile"}
                                ariaLabel={translations.chileAria || "Click to open the Chile AppleCare+ with Theft and Loss coverage documents in a new window."}
                                link={'https://www.apple.com/cl/legal/sales-support/applecare/applecareplus/cl/tl'}
                            />
                        </div>
                    </Col>
                </Row >
            </Container >
        </div >
    );
}
export default InternationalCoverageDocs;