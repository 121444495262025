import { useEffect } from 'react';

export const useClickOutside = (elementId, onOutsideClick) => {
    useEffect(()=>{
        const specifiedElement = document.getElementById(elementId);
        const detectClickOutside = () => {
            document.addEventListener('click', function(event) {
            var isClickInside = specifiedElement.contains(event.target);
        
            if (!isClickInside) {
                onOutsideClick();
            }})}
        return document.removeEventListener('click',detectClickOutside())
    }, [])
}