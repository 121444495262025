import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import styles from "../../screens/Vendor/Apple/ClaimOverview.module.css";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import DetailsDisplayBlock from '../../components/shared/DetailsDisplay/DetailsDisplayBlock';
import moment from 'moment'
import useTranslation from "../../components/contexts/LanguageTranslation/useTranslation";
import PageLoading from "../../components/shared/PageLoading/PageLoading";
import { UseTranslationInClassComponent } from '../../components/contexts/LanguageTranslation/UseTranslationInClassComponent';
import { translateIncidentType } from "../../helpers";
import InternationalPhoneWithCode from "../../components/shared/PhoneNumberUtility/InternationalPhoneWithCode";
import VisaIcon from '../../assets/images/cc-visa.svg';
import MasterCardIcon from '../../assets/images/cc-mastercard.svg';
import AmexIcon from '../../assets/images/cc-amex.svg';
import DiscoverIcon from '../../assets/images/cc-discover.svg';
import PhoneImage from '../../assets/images/Vendor/Apple/phone.png';

let btmLine = styles.btmLine;
let caseTitle = styles.caseTitle;
let colLg5 = "col-lg-5";
let noGutters = "no-gutters";
let h6 = "h6";

export default function ClaimDetails({
    ccType,
    ccNumber,
    claimOverview,
    errorMessage,
    caseNumber,
    address,
    clientId,
    countryCode
}) {
    const international = useTranslation('screens/ProgressScreenDetails/ClaimDetails');
    const {
        country,
        language,
        countryVars: {
            currency,
            date
        },
        englishVars,
        translations
    } = international

    const displayClaimedDevice = (claim) => {
        if (claim == undefined || claim == null) return false;
        let validModel =
            claim.modelNumber !== undefined &&
            claim.modelNumber != null &&
            claim.modelNumber.length > 0;
        let validSerial =
            claim.serialNumber !== undefined &&
            claim.serialNumber != null &&
            claim.serialNumber.length > 0;
        return validModel && validSerial;
    };

    const getCCType = () => {
        switch (ccType) {
            case "visa":
                return (<img className={styles.ccImage} alt="Visa" src={VisaIcon} />)
            case "mastercard":
                return (<img className={styles.ccImage} alt="mastercard" src={MasterCardIcon} />)
            case "amex":
                return (<img className={styles.ccImage} alt="Amex" src={AmexIcon} />)
            case "discover":
                return (<img className={styles.ccImage} alt="Discover" src={DiscoverIcon} />)
            default:
                return (<img className={styles.ccImage} alt="Unidentified" />)
        }
    }

    const currencySuffix = () => {
        switch (country) {
            case 'AT':
            case 'DK':
            case 'FI':
            case 'NL':
            case 'NO':
            case 'SE':
            case 'CH':
            case 'DE':
            case 'UK':            
            case 'IT':
            case 'ES':
            case 'FR':
            case 'VN':
            case 'CL':
                return ` ${currency.symbol}`
            default:
                return ''
        }
    }

    return (
        <section id="claimDetails" className={styles.overviewSection}>
            <Row className={classNames(noGutters, btmLine)}>
                <h2 className="h2 col-lg-7 mb-0">{translations.title || 'Details of Your Claim'}</h2>
                <h2 className={classNames(colLg5, caseTitle, h6)}>{translations.caseNumber || 'Case ID:'} {!claimOverview || !caseNumber ? "" : caseNumber } </h2>
            </Row>
            
            {!claimOverview && !errorMessage && (
                <Row>
                    <Col className="d-flex flex-column align-items-center justify-content-center">
                        <PageLoading text={translations.loading || "Loading Claim Details"} height={100} />
                    </Col>
                </Row>
            )}

            {errorMessage && !claimOverview && <p>{errorMessage}</p>}

            {claimOverview && (
                <Row className={styles.claimInfoRow}>
                    {displayClaimedDevice(claimOverview) && (
                        <Col md="7" className={styles.claimInfo}>
                            <h3 className={styles.claimDetailsH6}>{translations.claimedDevice || 'Claimed Device'}</h3>
                            <Row className={styles.deviceInfo}>
                                <img className={styles.deviceImg} src={PhoneImage} role="presentation" />
                                <Col lg="12">
                                    <div className="no-gutters">
                                        <b className={`col-2 ${country === 'DE' ? 'col-lg-6' : 'col-lg-3'}`}>{translations.model || 'Model'}: </b>
                                        <span className={`col-10 ${country === 'DE' ? 'col-lg-6' : 'col-lg-9'}`}>{claimOverview?.modelNumber ? claimOverview?.modelNumber : ""}</span>
                                    </div>
                                    <div className="no-gutters">
                                        <b className={`col-2 ${country === 'DE' ? 'col-lg-6' : 'col-lg-3'}`}>{translations.serial || 'Serial'}: </b>
                                        <span className={`col-10 ${country === 'DE' ? 'col-lg-6' : 'col-lg-9'}`}>{claimOverview?.serialNumber ? claimOverview?.serialNumber : ""}</span>
                                    </div>
                                    <div className="no-gutters">
                                        <b className={`col-2 ${country === 'DE' ? 'col-lg-6' : 'col-lg-3'}`}>{translations.phone || 'Phone'}: </b>
                                        <span className={`col-10 ${country === 'DE' ? 'col-lg-6' : 'col-lg-9'}`}>{claimOverview?.replacementShiptoPhone ? <InternationalPhoneWithCode number={claimOverview?.replacementShiptoPhone} /> : ""}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col md="5" className={styles.claimInfo}>
                        <h3 className={styles.claimDetailsH6}>{translations.deductible || englishVars.deductibleName}</h3>
                        <ul className="noBullets">
                            <li>
                                {translations.total || 'Total'}:&nbsp;
                                <NumberFormat
                                    value={(!claimOverview || !claimOverview?.deductibleTotal) ? 0 : parseInt(claimOverview?.deductibleTotal)}
                                    displayType={'text'}
                                    thousandSeparator={currency.thousandsDelimiter}
                                    decimalSeparator={currency.decimalDelimiter}
                                    prefix={(country == 'IE' || country == 'NZ' || country == 'US' || country == 'CL' || country == 'AUS') && currency.symbol}
                                    suffix={currencySuffix()}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            </li>
                            <li>{getCCType()}{translations.endingWith || 'ending with'} {ccNumber}</li>
                        </ul>
                    </Col>

                    <Col md="7" className={styles.claimInfo}>
                        <h3 className={styles.claimDetailsH6}>{translations.incidentDetails || 'Incident Details'}</h3>
                        {claimOverview != null && (
                            <ul className="noBullets">
                                <li>
                                    {!!claimOverview?.coverageName && translateIncidentType(claimOverview?.coverageName, language)}
                                    {" " + (translations.on || 'on') + " "}
                                    {!!claimOverview?.incidentDate && moment(claimOverview?.incidentDate).format(date.momentJsFormat)}
                                </li>
                                {!!claimOverview?.incidentDetails &&
                                    <li className={styles.wrapText}>
                                        {claimOverview.incidentDetails}
                                    </li>
                                }
                            </ul>
                        )}
                    </Col>

                    <Col md="5" className={styles.claimInfo}>
                        <DetailsDisplayBlock title={translations.shippedTo || 'Replacement Shipped To'} address={address} clientId={clientId} countryCode={countryCode} />
                    </Col>
                </Row>
            )}
        </section>
    );
}
