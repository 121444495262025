import React, { useState, useContext } from 'react';
import AssetInfoForm from './AssetForm'
import styles from './Asset.module.css'
import { TabContent } from 'reactstrap';
import classNames from 'classnames';
import { useHistory } from 'react-router'
import { ContractContext } from '../../contexts/ContractContext';

const AssetModal = ({
    isOpen,
    togglePopover,
    contractAssets,
    clientId,
    contractId,
    showModelSerial,
    assetName
}) => {
    const [activeAsset, setActiveAsset] = useState(0);
    // NPB 04292021 - These are only used to pass to children. 
    // For some reason AssetInfoForm cannot access useContext or useHistory hooks.
    // needs further investigation 
    const history = useHistory()
    const context = useContext(ContractContext)

    return (
        isOpen && (
            <div className={styles.assetModal}>
                <h1 className="h5">{assetName}</h1>
                {contractAssets.length > 1 &&
                    <div className={styles.popoverHeader}>
                        <ul tabs className={styles.navTabs}>
                            <li className={styles.navItem}>
                                <a
                                    className={classNames(styles.navLink, (activeAsset === 0 && styles.active))}
                                    onClick={() => { setActiveAsset(0); }}
                                >
                                    Primary
                                </a>
                            </li>
                            <li className={styles.navItem}>
                                <a
                                    className={classNames(styles.navLink, (activeAsset === 1 && styles.active))}
                                    onClick={() => { setActiveAsset(1); }}
                                >
                                    Secondary
                                </a>
                            </li>
                        </ul>
                    </div>
                }
                <TabContent activeTab={activeAsset}>
                    {contractAssets.map((asset, i)=> (
                        <AssetInfoForm
                            activeAsset={activeAsset}
                            tabIndex={i}
                            showModelSerial={showModelSerial}
                            contractAsset={asset}
                            closePopover={togglePopover}
                            assetName={assetName}
                            // NPB 04292021 - These should not need to be passed. For some reason AssetInfoForm cannot access useContext or useHistory hooks. 
                            history={history}
                            context={context}
                        />
                    ))}
                    </TabContent>
                <button type="button" className={styles.close} aria-label="Close" onClick={togglePopover}/>
            </div>  
        )
    )
}

export default AssetModal;