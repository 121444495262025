import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './AppointmentSelect.module.css'
import { useContext } from 'react';
import { ApptContext } from './AppointmentContext';
import { useMediaQuery } from 'react-responsive';

export const AppointmentGridColumn = (props) => {
    PropTypes.checkPropTypes(AppointmentGridColumn.propTypes, props, 'prop', 'Appointment Grid Column');
    const isMobile = useMediaQuery({query: '(max-width: 640px)'})
    const { dispatch, state } = useContext(ApptContext);
    const {date, available: {am, pm, allDay}} = props;
    const { appointment } = state
    
    const dateString = moment(date).format('L');

    const handleSelection = (appt) => {
        const {date, window} = appt;
        dispatch({
            type: 'SELECT_APPOINTMENT',
            appointment: {
                date,
                window
            }
        })
    }

    // for persisting checked state between "weeks"
    const checked = (date, timeslot) => {
        return ( 
            // but only if an appointment date is selected. 
            !!appointment.date
            && moment(date).isSame(appointment.date, 'day') 
            && timeslot === appointment.window 
        ) ? true : false;
    }

    
    return(
        <div className={styles.dayWrapper}>
            <div className={styles.dateDay}>
                <span className={styles.dayOfWeek}>
                    {moment(date).format('ddd')}
                </span>
                <br/>
                <span className={styles.date}>
                    {moment(date).format('MMM DD')}
                </span>
            </div>
            <div className={styles.timeslotWrapper}>
                <div className={`${styles.timeslot} ${am && styles.available}`}>
                    <input 
                        type="radio" 
                        name="scheduleService" 
                        id={`${dateString}-am`}
                        disabled={am === false}
                        checked={checked(date, 'AM')}
                        onChange={() => handleSelection({date: dateString, window: 'AM'})}
                    />
                    <label htmlFor={`${dateString}-am`}>{isMobile ? '8am-Noon' : '8 am - Noon'}</label>
                </div>
                <div className={`${styles.timeslot} ${pm && styles.available}`}>
                    <input 
                        type="radio" 
                        name="scheduleService" 
                        id={`${dateString}-pm`}
                        disabled={pm === false}
                        checked={checked(date, 'PM')}
                        onChange={() => handleSelection({date: dateString, window: 'PM'})}
                    />
                    <label htmlFor={`${dateString}-pm`}>{isMobile ? 'Noon-5pm' : 'Noon - 5 pm'}</label>
                </div>
                <div className={`${styles.timeslot} ${allDay && styles.available}`}>
                    <input 
                        type="radio" 
                        name="scheduleService" 
                        id={`${dateString}-allDay`}
                        disabled={allDay === false}
                        checked={checked(date, 'ALL_DAY')}
                        onChange={() => handleSelection({date: dateString, window: 'ALL_DAY'})}
                    />
                    <label htmlFor={`${dateString}-allDay`}>All Day</label>
                </div>
            </div>
        </div>
    )
}

AppointmentGridColumn.propTypes = {
    date: PropTypes.string.isRequired,
    available: PropTypes.shape({
        am: PropTypes.bool,
        pm: PropTypes.bool,
        allDay: PropTypes.bool
    })
}
