import React, { useState, useContext, useEffect } from 'react';
import { ReactComponent as MailIcon } from '../../../assets/images/envelope.svg'
import { ReactComponent as Phone } from '../../../assets/images/call.svg'
import styles from './ContactUsCards.module.css';
import classNames from 'classnames';
import { stripPhoneNumber } from '../util';

const ContactUsCards = ({
    email,
    phone,
    phoneDescription,
    emailDescription
}) => {
    return (
            <div className={styles.contactOptions}>
                <a href={`tel:${stripPhoneNumber(phone)}`}>
                    <button className={styles.optionButton}>
                        <Phone className={classNames(styles.moreQuestionsIcon, styles.phone)} />
                        <div>
                            <p>{phone}</p>
                            <p>{phoneDescription}</p>
                        </div>
                    </button>
                </a>
                <a  href={`mailto:${email}`}>
                    <button className={styles.optionButton}>
                        <MailIcon className={classNames(styles.moreQuestionsIcon, styles.email)} />
                        <div>
                            <p>{email}</p>
                            <p>{emailDescription}</p>
                        </div>
                    </button>
                </a>
            </div>
    )
}
export default ContactUsCards;