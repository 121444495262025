import React, { Component, useContext, useLayoutEffect, useState } from "react";
import {
    Container, Row, Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from "reactstrap";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LogoModal from "../../modals/LogoModal";
import styles from "./Header.module.css"
import classNames from 'classnames';
import LanguageToggle from './LanguageToggle'
import useTranslation from "../../contexts/LanguageTranslation/useTranslation";
import useMediaQuery from 'react-responsive'
import { LanguageContext } from "../../contexts/LanguageTranslation/LanguageContext";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Label, Input } from 'reactstrap';
import { useEnvironment } from '../../_ReactHooks/useEnvironment';
import CloseMobile from '../../../assets/images/close-mobile.svg';

const mobileMenuBg = styles.mobileMenu;
const centerNavItemsOnMobile = styles.centerNavItemsOnMobile;

export default function AppleHeader() {
    const [isOpen, setIsOpen] = useState(false);
    const context = useContext(LanguageContext)
    const international = useTranslation('components/Vendor/Apple/AppleHeader')
    const { country, language, translations } = international;
    const navbar = window.location.href.toLowerCase().includes("customersurvey") ? false : true;

    const { isDevOrLower } = useEnvironment();    

    useLayoutEffect(() => {
        if (!!window.localStorage.locale) {
            console.log('getting locale from local storage', window.localStorage.locale)
            const locale = window.localStorage.getItem('locale')
            const country = locale.slice(3)
            context.updateCountry(country)
            window.localStorage.removeItem('locale')
        }
    }, [])

    const mobileMenu = useMediaQuery({ query: "(max-width: 767px)" })
    const linksClasses = `nav-links mr-4`


    const toggle = () => setIsOpen(!isOpen);


    // HELPER TO WRAP AND THUS ONLY HAVE TO MAINTAIN ONE MENU INSTEAD OF TWO
    const ConditionalNavWrapper = ({ children }) => (
        isOpen ? (
            <Modal isOpen={true} toggle={toggle} className={`md header-content ${styles.navmenu}`} aria-modal="true" role="alertdialog" aria-describedby="suggest" aria-labelledby="modalHeading">
                <h2 id="modalHeading" className={styles.hidden}>Navigation Menu</h2>
                <a onClick={toggle}>
                    <img tabIndex={0} className={`logoClose ${styles.closeButtonTop}`} src={CloseMobile} alt="Close" />
                </a>
                <ModalBody>
                    {children}
                </ModalBody>

            </Modal>
        ) : (
            <Collapse navbar>
                {children}
            </Collapse>
        )
    )

    const isEnglishSpeaking = ['UK', 'US', 'AUS'].includes(country);

    const preserveLanguageContext = () => {
        window.localStorage.setItem('locale', `${language}-${country}`)
    }

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <header>
            <Container>
                <Navbar color="faded" light expand="md" role="navigation" className={styles.appleNav}>
                    <LogoModal />
                    {navbar && (
                        <NavbarToggler 
                            aria-label={translations.navMenuAria ||"On click opens a navigation menu"} 
                            onClick={toggle} 
                        />
                    )}
                    <ConditionalNavWrapper>
                        <Nav className={classNames('ml-auto', mobileMenuBg)} navbar>
                            {navbar && country === "US" && (
                                <NavItem className={centerNavItemsOnMobile}>
                                    <a
                                        onClick={preserveLanguageContext}
                                        className={linksClasses}
                                        href="/vendor/apple/faq"
                                        id="suggest"
                                        target="_blank"
                                        aria-label={translations.faqAria || "FAQs-Frequently Asked Questions opens a new details window"}
                                    >
                                        {translations.faq || 'FAQs'}
                                    </a>
                                </NavItem>
                            )}
                            {navbar && <NavItem className={centerNavItemsOnMobile}>
                                <a
                                    onClick={preserveLanguageContext}
                                    className={linksClasses}
                                    href="/vendor/apple/coveragedocs"
                                    target="_blank"
                                    aria-label={translations.coverageAria || "Coverage Documents opens a new details window"}
                                >
                                    {translations.coverage || 'Coverage Documents'}
                                </a>
                            </NavItem>}
                            {navbar && country === 'US' && (
                                <NavItem className={centerNavItemsOnMobile}>
                                    <Link to="/vendor/apple/econsent" className={linksClasses} target="_blank" aria-label="eConsent opens a new details window">
                                        eConsent
                                    </Link>
                                </NavItem>
                            )}
                            {navbar && <NavItem className={centerNavItemsOnMobile}>
                                <a
                                    onClick={preserveLanguageContext}
                                    className={linksClasses}
                                    href="/vendor/apple/contactus"
                                    target="_blank"
                                    aria-label={translations.contactAria || "Contact Us opens a new details window"}
                                >
                                    {translations.contactUs || 'Contact Us'}
                                </a>
                            </NavItem>}

                            {/* HIDING ON MOBILE UNTIL MOBILE STYLE CONFIRMED */}
                            {(!isEnglishSpeaking || isDevOrLower) && !mobileMenu && (
                                <NavItem>
                                    <LanguageToggle />
                                </NavItem>
                            )}
                        </Nav>
                    </ConditionalNavWrapper>
                </Navbar>
            </Container>
        </header>
    );
}

