import React, { useContext, useEffect, useState } from 'react';
// import { isHWAChatEndRoute } from '../../helpers';
import { ContractContext } from '../contexts/ContractContext';
import Footer from '../Vendor/Apple/Footer';
import { HWAFooter } from './HWAFooter/HWAFooter';
import { ETFooter } from './ETFooter/ETFooter';

export const FooterController = () => {
    const { data: { clientId }} = useContext(ContractContext)

 
    // useEffect(() => {
    //     if (brandContext.clientId !== null) {
    //         setClientId(brandContext.clientId.toLowerCase());
    //     } else {
    //         const hwaChat = async () => {
    //             const result = await isHWAChatEndRoute();
    //             console.log(result);
    //             if (result == true) {
    //                 setClientId('hwa');
    //             }
    //         };
    //         hwaChat();
    //     }        
    // }, []);
    
    switch (clientId?.toLowerCase()) {
        case "hwa":
            return <HWAFooter />
        case "et":
            return <ETFooter />
        case "arw":
        case "aig":
        case "apple":
        default:
            return <Footer />
    }
    
}  
  