import React, { Component } from 'react';
import {
    Container, Card, CardHeader, CardBody, Table, Row, Col
} from 'reactstrap';
import styles from './ClaimOverview.module.css';
import classNames from 'classnames';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

let event = styles.event;
let header = styles.header;
let detailsTable = styles.detailsTable;
let featureCovTable = styles.featureCovTable;

export default class ClaimDocuments extends Component {
    constructor() {
        super();
        this.state = {
            claimId: this.props
        }
    }
    render() {
        return (
            <Router>
                <Container>
                    <Row className="twoColWrapper">
                        <Col>
                            <Card className={styles.claimsCard}>
                                <CardHeader>
                                    <Row>
                                        <h1 className="h4 col-md-5">Documents needed</h1>
                                        <h1 className="h4 col-md-5">Claim: 123456789 <span className="badge badge-success">Active</span> ClaimId: {this.props.match.params.claimId}</h1>
                                        <Col md="7" className={styles.claimsNavBar}>
                                            <ul tabs className="nav navTabs" id="claimsTab" role="tablist">
                                                <li className="navItem">
                                                    <a className="navLink active" id="claimDetailsTab" data-toggle="pill" href="#claimDetails" role="tab" aria-controls="claimDetails" aria-selected="true">Details</a>
                                                </li>
                                                <li className="navItem">
                                                    <a className="navLink" id="claimHistoryTab" data-toggle="pill" href="#claimHistory" role="tab" aria-controls="claimHistory" aria-selected="false">History</a>
                                                </li>
                                                <li className="navItem">
                                                    <a className="navLink" id="claimAttachmentTab" data-toggle="pill" href="#claimAttachment" role="tab" aria-controls="pills-attachment" aria-selected="false">Attachment</a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="claimDetails" role="tabpanel" aria-labelledby="claimDetailsTab">
                                            <Row>
                                                <Col md="6">
                                                    <Table className="ltHead">
                                                        <tbody className="tableStriped">
                                                            <tr>
                                                                <th scope="row">Asset</th>
                                                                <td>Washer, Thermostat</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Claim Setup Date</th>
                                                                <td>MM/DD/YYYY</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Date of Incident</th>
                                                                <td>02/25/2020</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Description of Incident</th>
                                                                <td>Washer is not working</td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md="6">
                                                    <Table className="ltHead">
                                                        <tbody className="tableStriped">
                                                            <tr>
                                                                <th scope="row">Symptom</th>
                                                                <td>No Power</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Standard Thermostat</th>
                                                                <td>Yes</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Troubleshooting</th>
                                                                <td>Yes</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Emergency Services</th>
                                                                <td>No</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="tab-pane fade" id="claimHistory" role="tabpanel" aria-labelledby="claimHistoryTab">...</div>
                                        <div className="tab-pane fade" id="claimAttachment" role="tabpanel" aria-labelledby="claimAttachmentTab">...</div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className={styles.eventsCard}>
                                <CardHeader>Events</CardHeader>
                                <CardBody>
                                    <div className={classNames("accordion", event, header)}>
                                        <Col>Type</Col>
                                        <Col>Status</Col>
                                        <Col className={styles.colHiddenSm}>Event Number</Col>
                                        <Col className={styles.colHiddenSm}>Service/Payee</Col>
                                        <Col className={styles.colHiddenSm}>Scheduled Date</Col>
                                        <Col className="btn-group dropleft"></Col>
                                    </div>
                                    <div className="accordionGroup">
                                        <div className={styles.accordionWrapper}>
                                            <div className={classNames("accordion", "collapsed", event)} id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <Col>Replacement</Col>
                                                <Col><span className="circleBadge success"></span>Open</Col>
                                                <Col className={styles.colHiddenSm}>7538464092</Col>
                                                <Col className={styles.colHiddenSm}>Servicer Name</Col>
                                                <Col className={styles.colHiddenSm}>03/7/2020</Col>
                                            </div>
                                            <div className="btn-group dropleft">
                                                <div className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {/*<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.22 13.97" style="height: 0.95rem; fill: #001871;"><defs><style>.cls-1{fill:none;}</style></defs><rect className="cls-1" x="-592.41" y="-303.06" width="1280" height="1040" /><circle cx="1.61" cy="1.61" r="1.61" /><circle cx="1.61" cy="6.98" r="1.61" /><circle cx="1.61" cy="12.35" r="1.61" /></svg>*/}
                                                </div>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a className="dropdown-item" href="#">Assign</a>
                                                    <a className="dropdown-item" href="#">Close</a>
                                                    <a className="dropdown-item" href="#">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseOne" className="accordionContent collapse" aria-labelledby="headingOne" data-parent="#assetAccordion">
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <Row>
                                                        <Col md="6">
                                                            <Table className={classNames("noBorder", detailsTable)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" colspan="2">Servicer Details</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Name</th>
                                                                        <td>John</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Phone:</th>
                                                                        <td>814-244-9877</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Address:</th>
                                                                        <td>123 Any Street City, State 12345</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                        <Col md="6">
                                                            <Table className={classNames("noBorder", detailsTable)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" colspan="2 ">Appointment Details</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row ">Requested:</th>
                                                                        <td>John</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Scheduled:</th>
                                                                        <td>814-244-9877</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Completed:</th>
                                                                        <td>03/09/2020</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                    <Row className="row">
                                                        <Col>
                                                            <Table className={classNames("borderless", featureCovTable)}>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Feature Coverage</th>
                                                                        <th scope="col">Fulfillment Method</th>
                                                                        <th scope="col">Contract Liability</th>
                                                                        <th scope="col">Asset Liability</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Mechanical &amp; Electrical</td>
                                                                        <td>Repair</td>
                                                                        <td>$0000.00</td>
                                                                        <td>$0000.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Deluxe Advantage</td>
                                                                        <td>Repair</td>
                                                                        <td>$0000.00</td>
                                                                        <td>$0000.00</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className={styles.activityFeed}>
                            <div className="card h-100">
                                <div className="card-header">
                                    Last Activity
          </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Router>
        );
    }
}