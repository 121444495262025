import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import NeedHelp from '../../../components/modals/NeedHelp/NeedHelp';
import getClientContent from '../../_BrandingProvider/brandContent';
import styles from './ETFooter.module.css';
import { ContractContext } from '../../contexts/ContractContext';
import { ReactComponent as AIGLogo } from '../../../assets/images/aig-logo_w.svg';
import ETLogo from '../../../assets/images/Vendor/ET/ET-logo.jpg';

export const ETFooter = () => {
    const { data: { clientId } } = useContext(ContractContext);

    const programName = getClientContent(clientId)?.variableText?.programName;
    return (
        <>
            <Container className={classNames(styles.etFooterText, "px-3")}>
                <div className={classNames(styles.footerLg, styles.footerBrandInfo, 'container', 'px-0')}>
                    <div className={styles.LogoContainer}>
                        <img className={styles.Logo} src={ETLogo} title="Energy Texas" />
                    </div>
                    <div className={styles.footerRight}>
                        <span>Provided by</span>
                        <div className={styles.aigLogo}>
                            <AIGLogo />
                        </div>
                    </div>
                </div>
                *Service contract plans are sold by Declaration Energy LLC d/b/a Energy Texas. Plans are issued and
                administered by AIG WarrantyGuard, Inc., part of AIG, or one or more of its affiliates.  Plans are subject to
                detailed terms, conditions, and limitations. For further information, please review the full plan <a href="#" onClick={(e) => { e.preventDefault(); window.open('/coveragedocs', '_blank') }}>terms and conditions</a>.
                Energy Texas is not affiliated with AIG or any of its affiliates. Trademarks used with permission.
            </Container>
            <footer className={styles.etFooter}>
                <Container className="px-0">
                    <ul className={styles.horzList}>
                        <li><a href="https://www.aig.com/privacy-policy" target="_blank"> Privacy Policy</a></li>
                        <li><a href="https://www.aig.com/terms-of-use" target="_blank"> Terms of Use</a></li>
                    </ul>
                    <p>Copyright &#169; {(new Date().getFullYear())}, American International Group, Inc. All rights reserved.</p>
                </Container>
                <NeedHelp />
            </footer>
        </>
    )
}