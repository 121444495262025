import React from 'react'
import useTranslation from '../../contexts/LanguageTranslation/useTranslation';
import { phoneFormat, stripPhoneNumber } from '../util';

const InternationalPhoneStatic = ({ number }) => {
    const {country, countryVars: { phone: { countryCode } } } = useTranslation();

    if (country === 'US') return <span>{phoneFormat(number)}</span>;

    return (
        <span>
            {`${countryCode} ${stripPhoneNumber(number)}`}
        </span>
    )
}

export default InternationalPhoneStatic;