import React from 'react'
import styles from './RadioGroup.module.css'

const RadioGroup = ({buttons, selected, onChange}) => {
    /**
     * Buttons should have the following shape: {
     *      iconL: SVG, 
     *      iconR: SVG, 
     *      title: string, 
     *      value: string
     * }
     */

    const handleClick = (value) => {
        onChange(value)
    }

    return (
        <div className={styles.wrapper}>
            {buttons.map(b => (
                <button className={`${styles.btn} ${b.value === selected ? styles.selected : ""}`} onClick={()=>handleClick(b.value)}>
                    {b.iconL && <span className={styles.icon}>{b.iconL}</span>}
                    {b.title}
                    {b.iconR && <span className={styles.icon}>{b.iconR}</span>}
                </button>
            ))}
        </div>
    )
}

export default RadioGroup;