import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavbarBrand } from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { updatePartnerCase, updateRepair, cancelClaim } from '../../helpers';
import { useLocation } from 'react-router-dom';
import useTranslation from '../contexts/LanguageTranslation/useTranslation';
import { ContractContext } from '../contexts/ContractContext';
import AIGLogo from '../../assets/images/aig-logo.svg';
import SouthBridgeLogo from '../../assets/images/Vendor/SB/southbridge-logo.png';



const LogoModal = (props) => {
    const  history = useHistory()
    const location = useLocation();
    const international = useTranslation('components/modals/logomodal')
    const { translations } = international
    const contractContext = useContext(ContractContext);
    const { data: { isChileApple } } = contractContext;
    const [modal, setModal] = useState(false);
    const [logoSrc, setLogoSrc] = useState();

     const toggle = (e) => {
        if ((location.pathname === "/vendor/apple/entitlement")
            || (location.pathname === "/vendor/apple/verifyinfo")
            || (location.pathname === "/vendor/apple/payment")
            || (location.pathname === "/vendor/apple/uploaddocs/")) {
            e.preventDefault();
            setModal(!modal);
            document.getElementById('logo-button').focus()
        }
        else {
            setModal(modal)
            history.push('/vendor/apple')
        }      
    };

    const cancelAction = () => {
        setModal(false)
        props.history.push('/vendor/apple')

    }

    useEffect(() => {
        if (isChileApple) {
            setLogoSrc(SouthBridgeLogo);
        } else {
            setLogoSrc(AIGLogo);
        }
    }, [isChileApple])

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div>
            <div onClick={toggle} role="button" tabIndex="0" id="logo-button">  
                <img
                    className="logo"
                    src={logoSrc}
                    alt="AIG Home"
                />
            </div>
            <Modal isOpen={modal} toggle={toggle} className="md" aria-modal="true" role="dialog" aria-labelledBy="cancel">
                <ModalHeader toggle={toggle} className="noLine">
                    {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>*/}
                </ModalHeader>
                <ModalBody>
                <h4 className="modal-title" id="cancel">{translations.areYouSure || 'Are you sure you want to leave claim setup?'}</h4>
                    <p>{translations.dataWillBeLost || 'Any information you entered may not be saved.'}</p>
                </ModalBody>
                <ModalFooter className="multiBtnLayout">
                    <button 
                        className="btn btn-secondary" 
                        aria-label={translations.stayAria || "Click stay on page to close modal and continue filing claim"} 
                        onClick={toggle}
                    >
                        {translations.stay || 'Stay on Page'}
                    </button>
                    <div className="btnWrapper">
                        <button 
                            className="btn btn-primary" 
                            onClick={cancelAction} 
                            aria-label={translations.leaveAria || "On click exits claim setup and opens home page"}
                        >
                            {translations.leave || 'Leave Page'}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default withRouter(LogoModal);