import React, { useContext, useState } from 'react';
import Cleave from 'cleave.js/react'
import moment from 'moment';
import styles from './DateField.module.css';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg'
import Calendar from 'react-calendar'
import { ContractContext } from '../../contexts/ContractContext';
// import { useMediaQuery } from 'react-responsive'; // Commenting this line because have seen date picker issue used in mobile screens 
// So commenting above line will result in, Same date picker will be used in all screens.


const DateField = ({
    onDateChange = () => {}, 
    name, 
    showError,
    // default min and max are TODAY and ONE YEAR AGO, respectively
    minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    maxDate = new Date(),
    noPlaceholder = false,
    defaultDate,
    calendarMargin = true,
    required = false,
    inputMinDate
}) => {
    const [selectedDate, setSelectedDate]=useState(defaultDate ? new Date(defaultDate) : maxDate);
    const [showCalendar, setShowCalendar]=useState(false);
    const [tempDate, setTempDate]=useState(defaultDate ? moment(defaultDate).format('MM/DD/YYYY') : null);

    // const isMobile = useMediaQuery({query: '(max-width: 640px)'}) //Commenting this line because have seen date picker issue used in
    // mobile screens. So commenting above line will result in, Same date picker will be used in all screens.

    const isMobile = true;

    const toggleCalendar=()=>{
        setShowCalendar(showCalendar => !showCalendar)
    }

    const handleCalendarDate = (e) => {
        const date = moment(e).format('MM/DD/YYYY')
        onDateChange(e);

        setTempDate(date) //for display in Cleave field only
        setSelectedDate(e)
        setShowCalendar(false)
    }

    const handleCleaveDate = e => {
        let cleaveDate = e.target.value;
        let date = new Date(cleaveDate);
        onDateChange(date)

        if (!isNaN(date.getTime())) {
            setTempDate(cleaveDate);
            setSelectedDate(date)
        }
        else {
            setTempDate(cleaveDate)
        }

        const dateRegex = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/)
        if (dateRegex.test(cleaveDate)){
            setShowCalendar(false);
        }
    }

    return (
        <div className={styles.inputWrap}>
            {isMobile ? (
                <>
                    <Cleave
                        onFocus={()=>setShowCalendar(true)}
                        className={styles.dateInput}
                        name={name}
                        id={`${name}Input`}
                        placeholder={!noPlaceholder && 'mm/dd/yyyy'}
                        value={tempDate}
                        defaultValue={tempDate}
                        onChange={handleCleaveDate}
                        options={{
                            date: true,
                            delimiter: '/',
                            datePattern: ['m', 'd', 'Y'],
                            dateMax: moment().format('YYYY-MM-DD'),
                            dateMin: inputMinDate && moment().subtract(1, 'years').format('YYYY-MM-DD'),
                        }}
                    />
                    <CalendarIcon className={`ml-3 ${styles.calendarIcon}`} onClick={toggleCalendar}/>
                    {showCalendar && (
                        <Calendar
                            className={`${styles.calendar} ${!calendarMargin && 'mt-0'}`}
                            name="incidentDate"
                            autocomplete="off"
                            value={selectedDate}
                            // defaultValue={new Date(defaultDate) || new Date()}
                            onChange={handleCalendarDate}
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                    )}
                </>
            ) : (
                <input  
                    type="date" 
                    className={'form-control'} 
                    defaultValue="" 
                    name={name} 
                    id={`${name}Input`} 
                    value={tempDate}
                    defaultValue={tempDate}
                    onChange={handleCleaveDate} 
                    required={required} 
                    max={moment().format('YYYY-MM-DD')}
                    min={moment(new Date().setFullYear(new Date().getFullYear() - 1)).format('YYYY-MM-DD')} 
                />
            )}
            {showError && (
                <p className='error-msg'>
                    Select a date to continue
                </p>
            )}
        </div>
    )
}

export default DateField;