import React, { Component } from 'react';
import { Row, Col, Container, Badge, Card, CardBody, CardHeader, Button } from 'reactstrap';
import styles from './Contract.module.css';
import DocumentTitle from 'react-document-title';
import { ReactComponent as Chevron } from '../../assets/images/arrow-cheveron-down.svg';
import { ReactComponent as ExternalWater } from '../../assets/images/external-water.svg';
import { ReactComponent as Home } from '../../assets/images/home.svg';
import { ReactComponent as Sewer } from '../../assets/images/sewer.svg';
import classNames from 'classnames';
import moment from 'moment';
import { ContractContext } from '../../components/contexts/ContractContext';
import { parse } from 'query-string';

let h100 = "h-100";
let card = styles.card;


class ContractsOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'AIG Claims Portal | Contracts Overview',
        }

        this.viewPlan = this.viewPlan.bind(this);
    }

    viewPlan = (contract) => {
        var val = {
            contract: contract,
            party: contract.party,
            contracts: this.props.contracts
        };
        sessionStorage.setItem("appState", JSON.stringify(val));
        let value = this.context;
        
        value.updateContract(contract);
        value.updateParty(contract.party);
        const pathName = '/planoverview';
        const token = parse(this.props.location.search)?.id || parse(this.props.location.search)?.token;
        setTimeout(() => {
            this.props.history.push({
                pathname: pathName,
                search: typeof token == 'undefined' ? null : `?token=${token}`
            });
        }, 1000);
    }

    render() {
        const { pageTitle } = this.state;

        return (
            <div>
                <section className={styles.planhero}>
                    <Container>
                        <Row>
                            <Col md={5} lg={7}>
                                <h1>My Plans</h1>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row>
                            <a onClick={() => this.props.showPlanSearchBack()} className={classNames(styles.search, "mb-3")}><Chevron className={styles.searchIcon} /> Search  </a>
                        </Row>
                        <Row>
                            {this.props.contracts.map(contract => (
                                <Col md={6} lg={4} className={styles.mb3}>
                                    <Card className={classNames(card, h100)}>
                                        <CardHeader className={styles.cardHeader}>
                                            <h3>{(contract.assetPackageModel != null) ? contract.assetPackageModel.name : contract.assets[0].assetName}</h3>
                                            <Badge color={getStatusColor(contract.statusModel.statusName)}>{contract.statusModel.statusName}</Badge>
                                        </CardHeader>
                                        <CardBody className={styles['card-body']}>
                                            <Col className="p-0">
                                                <ul className="noBullets">
                                                    <li>{contract.party.partyName}</li>
                                                    <li>Plan Start {moment(contract.purchaseDate).format("MM/DD/YYYY")}</li>
                                                    <li>Contract</li>
                                                    <li>#{contract.contractNumber}</li>
                                                </ul>
                                                <Button color="secondary" onClick={(e) => this.viewPlan(contract)} className={styles.planbtn}>View Plan</Button>
                                            </Col>
                                            {contract.assets[0].assetDisplayCategory == "Appliances" ? <Home className={styles.cardIcon} /> : null}
                                            {contract.assets[0].assetDisplayCategory == "Plumbing" ? <Sewer className={styles.cardIcon} /> : null}
                                            {contract.assets[0].assetDisplayCategory == "Heating and Air" ? <ExternalWater className={styles.cardIcon} /> : null}
                                            {contract.assets[0].assetDisplayCategory == "Electrical" ? <Home className={styles.cardIcon} /> : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <p className="mt-4">Incorrect plan? Call us at <b><a href="tel:XXX-XXX-XXXX" className="callLink" aria-label="Click to Call (XXX) XXX-XXXX">(XXX) XXX-XXXX</a></b></p>
                    </Container>
                </section>
            </div>
        );
    }
}

ContractsOverview.contextType = ContractContext;
export { ContractsOverview };

export function getStatusColor(statusName) {

    switch (statusName) {
        case "Active":
            return "success";
        case "Expired":
        case "Cancelled":
            return "danger";
        case "Suspended":
            return "warning";
        case "Fulfilled":
            return "danger";
    }
    return statusName;
}
