import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Progress from '../../components/shared/StatusProgress/Progress';
import moment from 'moment';
import ClaimDetails from '../../screens/ProgressClaimDetails/ClaimDetails';
import styles from '../../screens/Vendor/Apple/ClaimOverview.module.css';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { phoneFormat } from '../../components/shared/util';
import ClaimInformation from '../ProgressClaimDetails/ClaimInformation';
import CreditCardIcon from '../../components/shared/CreditCardIcon/CreditCardIcon';
import momenttz from 'moment-timezone';
import useTranslation from '../../components/contexts/LanguageTranslation/useTranslation';


let orderLast = "order-last";
let orderLGFirst = "order-lg-first";
let overviewBody = styles.overviewBody;
let mrAuto = "mr-auto";
const timezoneArea = momenttz.tz.guess();

/*
 * REMOVE LATER - TO TEST TOGGLING BETWEEN MULTIPLE VIEWS.
 * ROUTE TO HWA /detailsoverview/?type=buyout
 * USE buyout, replacement,appointment, hireservicer(EVENTS)
 */
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export function DetailsOverview(props) {
    const { translations } = useTranslation("screens/PlanOverview/DetailsOverview")

    const [stepsInfo, setStepsInfo] = React.useState([]);
    const [event, setEvent] = React.useState({});
    let query = useQuery();
    console.log(props);
    let claim = props?.location?.claim;
    let type = claim?.eventType;
    let servicerHired = claim?.servicerOptionSelected == "hireservicer" ? true : false;
    type = servicerHired ? "hireservicer" : type;
    let approvedStatus = [1, 6, 9];
    let completedStatus = [6, 9];
    let partyInformation = claim?.partyModel;
    let authorizedAmount = claim?.authorizedAmount;

    const portfolioDealer = claim?.historicalPortfolioClaimsFlag === 1 ? true : false;

    const getLocalTimeZoneString = (datestr, timezone = 'America/indianapolis') => {
        const estTime = momenttz.tz(datestr, timezone);
        const currentTimeZone = momenttz.tz.guess(true);
        const localTimeString = momenttz(estTime).tz(currentTimeZone).format('hh:mm A z, MMMM DD, YYYY');
        return localTimeString;
    }

    /*
    * CLAIM STATUS STEPS BASED ON EVENT TYPE
    */
    const getSteps = () => {
        const eventType = portfolioDealer ? 'portfolioDealer' : type;
        console.log({ claim });
        let steps = [];
        switch (eventType) {

            case 'portfolioDealer':
                steps = [{
                    name: 'Claim Received',
                    style: (claim?.claimStatusName === 'Open' || claim?.claimStatusName === 'Closed with amount' || claim?.claimStatusName === 'Closed without payment') ? 'completed' : 'active',
                    date: claim?.claimSetupDateOverview != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : "N/A",
                }, {
                    name: 'Service Completed',
                    style: claim?.claimStatusName === 'Closed with amount' ? 'completed' : 'active',
                    date: null,
                }]
                return steps;
            case 'buyout':
                steps = [{
                    name: 'Claim Received',
                    style: claim?.claimSetupDate != null ? 'completed' : 'active',
                    date: claim?.claimSetupDate != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : "N/A",
                }, {
                    name: 'Claim Approved',
                    style: approvedStatus.includes(claim?.eventStatusId) ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Reimbursement Sent',
                    style: 'active',
                    date: null,
                }]
                return steps;

            case 'appointment':
            case 'wow':  
                steps = [{
                    name: 'Claim Received',
                    style: claim?.claimSetupDateOverview != null || claim?.claimSetupDate != null ? 'completed' : 'active',
                    date: claim?.claimSetupDateOverview != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : claim?.claimSetupDate != null ? getLocalTimeZoneString(claim?.claimSetupDate):'N/A' ,
                }, {
                    name: 'Claim Approved',
                    style: approvedStatus.includes(claim?.eventStatusId) ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Scheduled Appointment',
                    style: claim?.scheduledAppointment != null ? 'completed' : 'active',
                    date: claim?.scheduledAppointment != null ? momenttz(claim?.scheduledAppointment).tz(timezoneArea).format('hh:mm A z, MMMM DD, YYYY') : "",
                }, {
                    name: 'Service In Progress',
                    style: (claim?.scheduledAppointment != null) && (approvedStatus.includes(claim?.eventStatusId) || claim?.accepted) && ((moment(claim?.scheduledAppointment).format('MMMM Do, YYYY')) <= (moment(Date()).format('MMMM Do, YYYY'))) ? 'completed' : 'active',
                    date: null,
                    id: 'deliveryId'
                }, {
                    name: 'Service Completed',
                    style: completedStatus.includes(claim?.eventStatusId) ? 'complete' : 'active',
                    date: null,
                }]
                return steps;

            case 'replacement':
                steps = [{
                    name: 'Claim Received',
                    style: claim?.claimSetupDate != null ? 'completed' : 'active',
                    date: claim?.claimSetupDate != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : "N/A",
                }, {
                    name: 'Claim Approved',
                    style: approvedStatus.includes(claim?.eventStatusId) ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Replacement Ordered',
                    style: 'active',
                    date: null,
                }]
                return steps;

            case 'hireservicer':
                steps = [{
                    name: 'Claim Received',
                    style: claim?.claimSetupDate != null ? 'completed' : 'active',
                    date: claim?.claimSetupDate != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : "N/A",
                }, {
                    name: 'Claim Approved',
                    style: approvedStatus.includes(claim?.eventStatusId) ? 'completed' : claim?.claimStatusName == "Open" ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Hire Your Own Servicer',
                    style: servicerHired ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Invoice Submitted',
                    style: claim?.invoicesCount > 0 ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Reimbursement Mailed',
                    style: servicerHired && claim?.invoicesCount > 0 && (claim?.claimStatusName == "Closed without payment" || claim?.claimStatusName == "Closed with amount" || claim?.claimStatusName == "Closed") ? 'completed' : 'active',
                    date: null,
                }]
                return steps;
            
            case 'reimbursement':
                steps = [{
                    name: 'Claim Received',
                    style: claim?.claimSetupDate != null ? 'completed' : 'active',
                    date: claim?.claimSetupDate != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : "N/A",
                }, {
                    name: 'Claim Approved',
                    style: approvedStatus.includes(claim?.eventStatusId) ? 'completed' : claim?.claimStatusName == "Open" ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Hire Your Own Servicer',
                    style: 'completed',
                    date: null,
                }, {
                    name: 'Invoice Submitted',
                    style: claim?.invoicesCount > 0 ? 'completed' : 'active',
                    date: null,
                }, {
                    name: 'Reimbursement Mailed',
                    style: servicerHired && claim?.invoicesCount > 0 && (claim?.claimStatusName == "Closed without payment" || claim?.claimStatusName == "Closed with amount" || claim?.claimStatusName == "Closed") ? 'completed' : 'active',
                    date: null,
                }]
                return steps;
            
            default:
                steps = [{
                    name: 'Claim Received',
                    style: claim?.claimSetupDate != null ? 'completed' : 'active',
                    date: claim?.claimSetupDate != null ? getLocalTimeZoneString(claim?.claimSetupDateOverview) : "N/A",
                }, {
                    name: 'Claim Approved',
                    style: approvedStatus.includes(claim?.eventStatusId) ? 'completed' : 'active',
                    date: null,
                }]
                return steps;
        }
    }

    React.useEffect(() => {
        // get events from the claim
        //fetch(`api/claim/GetEventInformation/${claim?.claimId}/${claim?.clientId}`)
        //    .then(res => res.json())
        //    .then(data => {
        //      setEvent(data);
        //    });

        const step = getSteps();
        setStepsInfo(step);

        //fetch(`api/claim/GetClaimInformationByClaimNumber/${claimNumber}/${props.clientId}`)
        //    .then(res => res.json())
        //    .then(data => {
        //    });

    }, [props])

    const assetInformation = {
        asset: claim?.assetName
    }

    function getProductName(claim) {
        let brand = claim?.brand;
        let productName = "";
        if (brand == 'Unknown' || brand == 'UNK' || typeof brand == 'undefined') {
            productName = claim?.assetName;
        }
        else {
            productName = `${brand} ${claim?.assetName}`
        }
        return productName;
    }

    function getAppointentTime(time) {
        switch (time) {
            case '08:00:00':
                return '8 AM - Noon'
            case '12:00:00':
                return 'Noon - 5 PM'
            default:
                return '8 AM - 5 PM'

        }

    }
    const productInformation = [{
        name: 'Product',
        value: getProductName(claim)
    }, {
        name: 'Description',
        value: claim?.assetDescription
    }, {
        name: 'Model',
        value: claim?.modelNumber
    }, {
        name: 'Serial',
        value: claim?.serialNumber
    }, {
        name: 'Purchase Date',
        value: claim?.purchaseDate != null ? moment(claim?.purchaseDate).format('MM/DD/YYYY') : claim?.assetPurchaseDate != null ? moment(claim?.assetPurchaseDate).format('MM/DD/YYYY'): null
    }]

    const claimDetails = [{
        name: 'Symptom',
        value: claim?.symptom
    }, {
        name: 'Incident Date',
        value: claim?.incidentDate != null ? moment(claim?.incidentDate).format('MM/DD/YYYY') : claim?.lossDate != null ? moment(claim?.lossDate).format('MM/DD/YYYY') : null
    }, {
        name: 'Incident Description',
        value: claim?.incidentDescription
    }]

    const serviceAppointment = [{
        name: 'Date',
        value: claim?.appointmentDate != null ? moment(claim?.appointmentDate).format('dddd, MMMM DD, YYYY') : null
    }, {
        name: 'Time',
        value: claim?.appointmentTime != null ? getAppointentTime(claim?.appointmentTime) + ' ' + momenttz.tz(timezoneArea).format('z') : null
    }, {
        name: 'Servicer',
        value: typeof claim?.servicerPhone == 'undefined' ? null : claim?.servicerName
    }, {
        name: 'Servicer Phone',
        value: typeof claim?.servicerPhone == 'undefined' || claim?.servicerPhone == null ? null : phoneFormat(claim?.servicerPhone)
    }]

    if (type === "buyout") {
        //list.push("Reimbursement Amount: $xx.xxx");
    }

    return (
        <Container className="contentWrapper pl-md-0">
            <Row>
                <Col lg="7" className={classNames(overviewBody, mrAuto, orderLast, orderLGFirst)}>
                    <ClaimInformation
                        {...props}
                        claim={claim}
                        serviceAppointment={serviceAppointment}
                        assetInformation={assetInformation}
                        claimDetails={claimDetails}
                        productInformation={productInformation}
                        eventType={type}
                        partyInformation={partyInformation}
                        portfolioDealer={portfolioDealer} />
                </Col>
                <Progress steps={stepsInfo} portfolioDealer={portfolioDealer} />
            </Row>
        </Container>
    );
}
