import React, { Component } from 'react';
import {
    Container, Row, Col
} from 'reactstrap';
import styles from '../../../components/Vendor/Apple/IntakeLoss/Payment.module.css';
import Payment from '../../../components/Vendor/Apple/IntakeLoss/Payment';
import ProgressBar from '../../../components/Vendor/Apple/IntakeLoss/ProgressBar';
import ClaimSummary from '../../../components/Vendor/Apple/IntakeLoss/ClaimSummary';
//import NeedHelp from '../../../components/Vendor/Apple/IntakeLoss/NeedHelp';
import NumberFormat from 'react-number-format';
import { callAdobe } from '../../../helpers';
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent';
import { CombinedLangContractContext } from '../../../components/contexts/ProviderLangContractContext';
import { ContractContext } from '../../../components/contexts/ContractContext';

export default class PaymentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            claimNumber: typeof this.props.location.state === "undefined" ? "1" : this.props.location.claimNumber,
            clientId: typeof this.props.location.state === "undefined" ? "apple" : this.props.location.clientId,
        }
    }

    componentWillMount() {
        fetch('api/claim/GetAmountByClaimNumber/' + this.state.claimNumber + "/" + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    amount: data.amount
                });

                return this.state.amount;
            });
    }

    componentDidMount() {
        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: 'vendor/apple/payment',
            pageName: 'Payment',
            country: this.context.languageContext?.country,
            language: this.context.languageContext?.language,
            currency: this.context.languageContext?.countryVars?.currency?.abbreviation
        })
    }


    render() {
        let entitlementDetails = typeof this.props.location.state === "undefined" ? new Object() : this.props.location.state.entitlementDetails;
        let claim = typeof this.props.location.state === "undefined" ? new Object() : this.props.location.state.claim;
        const entitlementDeductible = entitlementDetails?.deductible;

        const currencySuffix = (country, symbol) => {
            switch (country) {
                case 'AT':
                case 'DK':
                case 'FI':
                case 'NL':
                case 'NO':
                case 'SE':
                case 'CH':
                case 'DE':
                case 'UK':                
                case 'IT':
                case 'ES':
                case 'FR':
                case 'VN':
                    return `${symbol}`
                default:
                    return ''
            }
        }

        //entitlementDetails.amount = this.state.amount;
        ////console.log(this.state.amount);
        //// Remove later, included for testing
        //entitlementDetails.claimNumber = "20200505"
        //entitlementDetails.amount = 199;
        //entitlementDetails.firstName = "John";
        //entitlementDetails.lastName = "Smith";
        //entitlementDetails.mailingAddress = "123 Any Street";
        //entitlementDetails.mailingAddress2 = null;
        //entitlementDetails.city = "City";
        //entitlementDetails.state = "State";
        //entitlementDetails.zip = "12345";
        //entitlementDetails.email = "email@emailaddress.com";
        //entitlementDetails.contactPhone = "123-123-1234";
        //console.log(entitlementDetails);

        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/PaymentContainer'}>
                {international => {
                    const {
                        country,
                        translations,
                        englishVars: {
                            deductibleName
                        }
                    } = international

                    const { symbol,
                        thousandsDelimiter,
                        decimalDelimiter
                    } = international.countryVars.currency;
                    
                    return (
                        <Container className="contentWrapper">
                            <Row>
                                <section className="formBody col-lg-7 mr-auto">
                                    <ProgressBar step={3} />
                                    <Row className="no-gutters">
                                        <h1 className="mr-auto">{translations.title || 'Select Your Payments'}</h1>
                                        <div className={styles.dedbtn}> 
                                            {translations.total || deductibleName}:
                                            <span className={styles.deductibleTotal}>
                                                <NumberFormat
                                                    value={entitlementDeductible}
                                                    displayType={'text'}
                                                    decimalSeparator={decimalDelimiter}
                                                    thousandSeparator={thousandsDelimiter}
                                                    prefix={(
                                                                country == 'IE' || 
                                                                country == 'NZ' || 
                                                                country == 'US' || 
                                                                country == 'CL' ||
                                                                country == 'AUS' 
                                                            ) && 
                                                            symbol
                                                    }
                                                    suffix={currencySuffix(country, symbol)}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </span>
                                        </div>
                                    </Row>
                                    <Col md="8" className="p-0">
                                        <Payment history={this.props.history} entitlementDetails={entitlementDetails} />
                                    </Col>
                                </section>
                                <ClaimSummary step={3} entitlementDetails={entitlementDetails} history={this.props.history} claim={claim} />
                                { /* <!--Need Help Component Goes Here--> */}
                                {/*<NeedHelp/>*/}
                            </Row>
                        </Container>
                    )
                }
                }
            </UseTranslationInClassComponent>
        );
    }
}

PaymentContainer.contextType = CombinedLangContractContext;