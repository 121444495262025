import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AppointmentSelect.module.css';
import {
    AppointmentGridColumn,
    ServicersTable,
    ApptContext,
    SpecialNotesToServicer
} from '.';
import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch'
import { ArrowButton } from '.';
import {useMediaQuery} from 'react-responsive';
import { Container, Row } from 'reactstrap';
import classNames from 'classnames';
import CancelModal from '../../modals/CancelModal';
import moment from 'moment';
import { dataLayerPush } from '../../../helpers'
import { Spinner } from 'react-bootstrap';
import SaveModal from '../../modals/SaveModal';
import { ContractContext } from '../../contexts/ContractContext';
import { stripPhoneNumber } from '../../shared/util';
import SpinnerButton from '../../shared/SpinnerButton/SpinnerButton';

import axios from "axios";
const ColorLegend = () => (
    <div className={styles.legend}>
        <div className={`${styles.key} ${styles.available}`}/>
        <span>Available</span>
        <div className={`${styles.key} ${styles.disabled}`}/>
        <span>Not Available</span>
        <div className={`${styles.key} ${styles.selected}`}/>
        <span>Selected</span>
    </div>
)

export const AppointmentGrid = (props) => {
    useEffect(() => {
        dataLayerPush('/appointment', 'Schedule Service')
    }, [])

    useEffect(() => {
        window.addEventListener('beforeunload', keepOnPage);
        return () => { window.removeEventListener('beforeunload', keepOnPage); }//cleanup function
    }, [])

    PropTypes.checkPropTypes(AppointmentGrid.propTypes, props, 'props', 'AppointmentGrid')
    const isTablet = useMediaQuery({query: '(max-width: 991px)'});
    const {state: {
        appointment, isInternalPortal, viewAllServicers, selectedServicer, servicerData, noAppointmentsFound, contractAssetDetails, notesForServicer,
        noServicersFound, noServicerAlternative, suggestedServicer
    }, dispatch } = useContext(ApptContext)
    const context = useContext(ContractContext);
    const { data: { contract: { clientId } }, data: { contract } } = context;
    const [displayedWeek, setDisplayedWeek] = useState(1);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isSaveForLater, setIsSaveForLater] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getFirstAvailable = (appts) => {
        // keep currently selected appointment
        if (appointment.date) return appointment;

        // if none available, reset to null
        const availableSlots = Object.entries(appts).filter(d=>(!!d[1].am || !!d[1].pm || !!d[1].allDay));
        const noneFound = { date: null, window: null };
        if (!availableSlots.length) return noneFound;

        // find first available slot in day and return that.
        const firstAvailDay = availableSlots[0];
        if (firstAvailDay[1].am) return { date: firstAvailDay[0], window: 'AM'}
        if (firstAvailDay[1].pm) return { date: firstAvailDay[0], window: 'PM'}
        if (firstAvailDay[1].allDay) return { date: firstAvailDay[0], window: 'ALL_DAY'}

        // developer error. should not reach this.
        return noneFound;
    }

    useEffect(()=>{
        //dispatch({type: 'SELECT_APPOINTMENT', appointment: getFirstAvailable(props.appts)});
    }, [])

    const createDayCols = () => {
        const days = [];
        for (const d in props.appts) {
            days.push(
                <AppointmentGridColumn
                    date={d}
                    available={props.appts[d]}
                />
            )
        }
        return days;
    }

    function getFormattedDate(inDate) {
        const newDate = moment(inDate).format('MM/DD/YYYY');
        return newDate;
    }
    function formattedPhone(phoneNo) {
        var cleaned = ('' + phoneNo).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    }

    const handleAppointmentBook = async () => {
        const appointmentProps = props?.history?.location?.appointmentProps ? props?.history?.location?.appointmentProps : props?.history?.location?.state;
        const contractAssetId = parseInt(typeof appointmentProps?.claim?.contractAssetId == 'undefined' ? appointmentProps?.contractAssetId : appointmentProps?.claim?.contractAssetId);
        const currentAsset = appointmentProps?.entitlementDetails?.contract?.contractAssetModel?.filter(e => e.contractAssetId === contractAssetId);
        const isWarrantyOfWork = props?.history?.location?.isWarrantyOfWork == true ? true : false;

        var appointmentStartTime = '8:00 AM';
        var appointmentEndTime = '5:00 PM';
        switch (appointment?.window) {
            case 'AM':
                appointmentEndTime = '12:00 PM';
                break;
            case 'PM':
                appointmentStartTime = '12:00 PM';
                break;
            case 'ALL_DAY':
                //appointmentStartTime = '8:00 AM';
                //appointmentEndTime = '5:00 PM';
                break;
            default:
        }


        //debugger;

        // just because there is an appointment, doesn't mean that the firsst servicer is the one that has the appointment, so
        // we need to loop through the servicers and pick the first one that has it, because one of them should
        const servicers = servicerData?.appointmentSearchResponse?.servicers;
        var selectedAppointment;
        var servicerIndex;

        for (var i = 0; i < servicers.length; i++) {
            servicerIndex = i;
            // debugger;
            selectedAppointment = servicers?.[i]?.appointments?.filter(appt => {

                return (noAppointmentsFound ?

                    (servicers?.[i]?.appointments) :

                    moment(getFormattedDate(appt.date)).isSame(appointment?.date)
                    && appt.appointmentStartTime === appointmentStartTime && appt.appointmentEndTime === appointmentEndTime)
            });
            
            // debugger;
            if (selectedAppointment.length > 0)
                break;
        }

        // SRV 4/7/2021
        // let's repull party information so we have what was collected during the process to use
        const res = await fetch(`api/contract/${contract.contractId}/${clientId}`);
        const data = await res.json();
        const party = data.party;

        //const entitlementDetails = props?.history?.location?.appointmentProps?.entitlementDetails;
        const mobile = party?.partyPhoneModel?.filter(pp => pp.phoneTypeId === 2);
        const mobileNumber = mobile.length > 0 ? mobile[0].number : party?.partyPhoneModel[0]?.number;
        const homePhone = party?.partyPhoneModel?.filter(pp => pp.phoneTypeId === 1);
        const homePhoneNumber = homePhone.length > 0 ? homePhone[0].number : party?.partyPhoneModel[0]?.number;
        const serviceAddress = party.partyAddressModel.filter(pa => pa.addressTypeId === 2)[0];
        const entitlementDetails = appointmentProps?.entitlementDetails;
        let eventNumber = typeof props?.result?.eventModel[0]?.eventNumber !== 'undefined' ? props?.result?.eventModel[0]?.eventNumber : props?.eventNumber;
        let repeatCall = props?.eventTypeId === 5 && props?.eventNumber !== null ? true : false;
        let eventId = typeof props?.result?.eventModel[0]?.eventId !== 'undefined' ? props?.result?.eventModel[0]?.eventId : props?.eventId;
        
        const body = {
            "ClaimId": parseInt(props?.claimId ? props?.claimId : appointmentProps?.claim?.claimId),
            "ClaimNumber": eventNumber ? eventNumber : appointmentProps?.claim?.eventNumberToResume,
            "ProductInformation": {
                "ProductDetails": {
                    "ProductCode": contractAssetDetails?.productCode,
                    "BrandCode": contractAssetDetails?.brandCode,
                    "ModelNumber": currentAsset?.[0].modelNumber,
                    "SerialNumber": currentAsset?.[0].serialNumber,
                    "InstallationDate": currentAsset?.[0].assetPurchaseDate,
                    "EquipmentRetail": currentAsset?.[0].assetPurchasePrice ?? 0
                },
                "ProductLocation": {
                    "Street": serviceAddress?.address1,
                    "Aux": "",
                    "City": serviceAddress?.city,
                    "State": serviceAddress?.state,
                    "Zip": serviceAddress?.zip
                }
            },
            "AccountId": contractAssetDetails?.accountId,
            "AppointmentToBook": selectedAppointment?.[0],
            "WarrantyType": "SC",
            "ServiceType": "Repair",
            "ProblemCode": "",
            "ServicerToBook": servicerData?.appointmentSearchResponse?.servicers?.[servicerIndex],
            "CustomerInformation": {
                "FirstName": contract?.party?.firstName,
                "LastName": contract?.party?.lastName,
                "EmailAddress": contract?.party?.partyEmailModel?.[0].address,
                "MobilePhone": stripPhoneNumber(mobileNumber),
                "HomePhone": stripPhoneNumber(homePhoneNumber),
                "ServiceAddress": {
                    "Street": serviceAddress?.address1,
                    "Aux": "",
                    "City": serviceAddress?.city,
                    "State": serviceAddress?.state,
                    "Zip": serviceAddress?.zip
                }

            },
            "ServiceSite": "IH",
            "ProblemType": "REP",
            "ProblemDescription": `${appointmentProps?.selectedSymptom?.symptom}_${appointmentProps?.incidentDescription}`,
            "EmergencyService": false,
            "RepeatCall": repeatCall,
            "SpecialInstructions": notesForServicer,
            "IsRedispatch": false,
            "IsReschedule": false
        }

        const settings = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        const response = await fetch(`/api/dispatchservice/appointments/book/${clientId}`, settings);

        //if (!response.ok) throw Error(response.message);
        //console.log({ result })
        //const response = await result?.json()
        let eligible = false;
        try {
            if (response.ok) {
                const data = await response.json();
                if (props?.clientId == "hwa")
                {
                    fetch('api/rulesEngine/ExecuteRules', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            rulesEngineExecutionModel: {
                                clientId: props?.clientId,
                                contractId: parseInt(contract.contractId),
                                claimId: parseInt(props?.claimId),
                                eventId: eventId
                            },
                            rules: [9]
                        })
                    }).then(res => {
                        if (res.ok) {
                            res.json().then(rulesResult => {
                                console.log('Rules Result:', rulesResult);
                                eligible = rulesResult[0].success;
                                if (!eligible) {
                                    fetch(`api/claim/HwaLimitofLiabilityQueueRouting/${props?.clientId}/${props?.claimId}/${props?.eventId}`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(result => {
                                        if (result.ok) {
                                            result.json().then(routingResult => {
                                                //console.log(routingResult);
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                if (data.success) {
                    props.history.push('/fileclaim/confirmation', { statusType: 'type1', appointmentProps: body, claimNumber: props?.claim?.claimNumber, clientId: props?.clientId, claimId: typeof props?.claim?.claimId == 'undefined' ? props?.claimId : props?.claim?.claimId, contractAssetId: typeof props?.claim?.contractAssetId == 'undefined' ? props?.contractAssetId : props?.claim?.contractAssetId, isWarrantyOfWork: isWarrantyOfWork, eventId })
                    if (props?.clientId == 'et') {
                        var emailType = servicerData?.appointmentSearchResponse?.servicers?.[servicerIndex]?.servicerType == '1' ? 175 : 0;

                        // SRV 5/16/2023 --- THIS WAS SENDING DUPLICATE ERRONEOUS EMAIL
                        // CONFIRMATION.JSX handles email sends
                        //axios.post(`api/communication/${props?.clientId}`,
                        //    {
                        //        emailModel: {

                        //            Recipients: [party?.partyEmailModel[0]?.address],
                        //            Cc: [],
                        //            Bcc: [],
                        //            IsBodyHtml: true,
                        //            emailType: emailType // Int
                        //        },

                        //        serviceModel: {
                        //            CustomerName: contract?.party?.firstName + ' ' + contract?.party?.lastName,
                        //            ClaimNumber: props?.claim?.claimNumber,
                        //            ContractNumber: contract?.contractNumber,
                        //            AppointmentDate: moment(appointment?.date).format('dddd MMMM DD, YYYY'),
                        //            AppointmentWindow: selectedAppointment?.[0].appointmentStartTime,
                        //            Servicer: servicerData?.appointmentSearchResponse?.servicers?.[servicerIndex]?.name,
                        //            ServicerPhone: formattedPhone(servicerData?.appointmentSearchResponse?.servicers?.[servicerIndex]?.phoneNumber)


                        //        }
                        //    }
                        //).then((emailResponse) => {
                        //    console.debug(emailResponse);

                        //});

                    }
                }
            }

            //return data;
        } catch (err) {
            //throw err;
        }
    }




    const handleContinue = async () => {
        try {
            setIsLoading(true)
            await handleAppointmentBook();

            // set the claim status no matter the service outcome
            await updateClaimStatus(1);
            setIsLoading(false)
        } catch (e) {
            console.log('Error while continuing', e)
            setIsLoading(false)
        }
      
    }

    const handleCancel = () => {
        // MUTATE AND REROUTE WHEN USER CANCELS
        props.history.push('/planoverview');
    }

    const updateClaimStatus = async (claimStatusId) => {
        let claimId = typeof props?.claim?.claimId == 'undefined' ? props?.claimId : props?.claim?.claimId
        const settings = {
            body: JSON.stringify({}),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        await fetch(`/api/claim/UpdateClaimStatus/${'hwa'}/${claimId}/${claimStatusId}`, settings);
    }

    const keepOnPage = e => {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';
        saveForLaterAction(props?.claim, true);
    }

    const onSaveForLaterAction = e => {
        e.preventDefault();
        setShowConfirmation(true);
    }

    const saveForLater = (e) => {
        e && e.preventDefault();
        setIsSaveForLater(true);
        saveForLaterAction(props?.claim, false);
    }

    const onSaveCancel = e => {
        e.preventDefault();
        setShowConfirmation(false);
    }

    const saveForLaterAction = (claim, claimAbandoned) => {
        if (!claim) { return; }
        setUploading(true);

        let claimDetails = {
            claimNumber: claim.claimNumber ?? "",
            symptom: claim.coveredItemId ?? "",
            createdBy: claim.createdBy ?? "",
            incidentDescription: claim.incidentDescription ?? "",
            emergencyFlag: claim.emergencyFlag ?? null,
            contractAssetId: claim.contractAssetId ?? 0,
            createdDate: claim.createdDate
            }

        let stepSaved = 5;

        // not getting through the controller;
        stepSaved = clientId == 'apple' ? 4 : stepSaved;

        fetch(`api/claim/SaveForLater/${claim.claimId}/${clientId}/${stepSaved}/${claimAbandoned}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(claimDetails)
        })
            .then(data => {
                if (data.status === 200 && !claimAbandoned) {
                    props.history.push('/fileclaim/confirmation', { statusType: 'savedClaim', claimNumber: claim?.claimNumber, clientId: claim?.clientId, claimId: claim.claimId, contractAssetId: props.match?.params?.assetId });
                }
                setUploading(false);
            });
    }

    const handleChangeWeek = (week) => {
        //only accept "PREV" and "NEXT"
        if (!week) {
            if (week !== 'PREV' && week !== 'NEXT') {
                return;
            }
        }

        if (week === 'PREV') {
            displayedWeek !== 1 ? setDisplayedWeek(displayedWeek - 1) : setDisplayedWeek(1);
        }

        if (week === 'NEXT') {
            displayedWeek < 3 ? setDisplayedWeek(displayedWeek + 1) : setDisplayedWeek(3);
        }
    }

    const handleCheck = () => {
        dispatch({ type: 'TOGGLE_VIEW_SERVICERS' })
    }

    // FOLLOW THIS PATTERN IF MORE PAGES NEEDED. 
    const page1 = createDayCols().splice(0,5);
    const page2 = createDayCols().splice(5,5);
    const page3 = createDayCols().splice(10);

    const disableBtn = !appointment.date;

    return (
        <Container className={styles.contentWrapper}>
            <section>
                <fieldset>
                    <legend className={`${styles.instructions} mb-4`}>What date and time works for you?</legend>
                    {isTablet && <ColorLegend/>}
                    <div className={styles.gridWrapper}>
                        {isTablet &&
                            <div className={styles.mobileButtonWrapper}>
                                <ArrowButton
                                    direction='left'
                                    onClick={() => handleChangeWeek('PREV')}
                                    disabled={displayedWeek === 1}
                                    altText='View Previous Week'
                                />
                                <ArrowButton
                                    direction='right'
                                    onClick={() => handleChangeWeek('NEXT')}
                                    disabled={displayedWeek === 3}
                                    altText='View Next Week'
                                />
                            </div>
                        }
                        {!isTablet &&
                            <ArrowButton
                                direction='left'
                                onClick={() => handleChangeWeek('PREV')}
                                disabled={displayedWeek === 1}
                                altText='View Previous Week'
                            />
                        }
                        <div className={styles.tableAndLegendWrapper}>
                            <div className={styles.datesContainer} data-gridLength={displayedWeek === 3 ? 2 : 5}>
                                {displayedWeek === 1 && page1.map(d=>d)}
                                {displayedWeek === 2 && page2.map(d=>d)}
                                {displayedWeek === 3 && page3.map(d=>d)}
                            </div>

                            {!isTablet && <ColorLegend/>}

                        </div>
                        {!isTablet &&
                            <ArrowButton
                                direction='right'
                                onClick={() => handleChangeWeek('NEXT')}
                                disabled={displayedWeek === 3}
                                altText='View Previous Week'
                            />
                        }
                    </div>
                </fieldset>
            </section>

            {isInternalPortal &&
                <section>
                    <p className={styles.cardInstructions}>Select Servicer by Rank</p>
                    <ToggleSwitch
                        id='viewAllServicers'
                        labelText='View List of Available Servicers'
                        checked={viewAllServicers}
                        onCheck={handleCheck}
                    />
                    {(!!appointment.date || viewAllServicers) &&
                        <ServicersTable servicers={props.servicers}/>
                    }
                </section>
            }
            <SpecialNotesToServicer/>

            <Row className="stepsFormBlock">
                <div className="btnWrapper">
                    <SpinnerButton
                        className="btn btn-primary"
                        type="primary"
                        text="Continue"
                        disabled={isLoading || disableBtn}
                        spinning={isLoading}
                        onClick={() => handleContinue()}
                    />
                </div>
            </Row>

            <Row className="stepsFormBlock mt-3">
                {showConfirmation ?
                    <SaveModal buttonClassName={classNames("btnLink")} showConfirmation={showConfirmation} history={props?.history} continuing={isSaveForLater}
                        onCancel={onSaveCancel} onSaveForLaterAction={saveForLater} client={props?.clientId} />
                    : null}

                <a href="#" className="btnLink" onClick={onSaveForLaterAction}>Save For Later</a>
            </Row>
        </Container>
    )
}

AppointmentGrid.propTypes = {
    appts: PropTypes.shape([
        {
            am: PropTypes.bool,
            pm: PropTypes.bool,
            allDay: PropTypes.bool,
        }
    ])
}

// EXPECTED SHAPE FROM SERVICEPOWER. DELETE BEFORE PUSH
const dataFormat = {
    appointmentSearchResponse: {
        responseIdentifier: 'string',
        ResultMessages: 'string',
        success: true,
        servicers: [
            {
                id: 'string of numbers',
                name: 'String',
                oemauthorized: true,
                phoneNumber: 'strring of digits',
                ranking: 'number between 1-100 for ranking purposes',
                servicerType: 'string with limited options',
                address: {
                    city: 'string',
                    state: 'string',
                    street: 'string',
                    zip: '12345',
                },
                appointments: [
                    {
                        appointmentStartTime: 'string',
                        appointmentEndTime: 'string',
                        date: new Date(),
                        GroupId: 'string of numbers',
                        id: 'is this a specific appointmentId we can use? string.'
                    }
                ]
            }
        ]
    }
}