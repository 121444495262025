import React from 'react';
import { Container } from 'reactstrap';
import NeedHelp from '../../Vendor/Apple/IntakeLoss/NeedHelp';
import  styles from './HWAFooter.module.css';

export const HWAFooter = () => {  
  return(    
      <footer className={styles.hwaFooter}>
        <Container className="text-center">
          <p>Copyright &#169; {(new Date().getFullYear())}, AIG WarrantyGuard, Inc. All rights reserved.</p>
          <p>
            Home Warranty of America names, logos, and design marks are the trademarks or service marks of Home
            Warranty of America and are used with permission. Service contracts are provided by AIG WarrantyGuard, Inc.                 
            in D.C. and all U.S. states except Florida and California. In Florida, service contracts are provided 
            by AIG Warranty Services of Florida, Inc. In California, service contracts are provided by Home Warranty 
            of America, Inc. Service contracts are subject to detailed terms, conditions, limitations, and exclusions. 
            Products and services may vary by state and may not be available in all states.
          </p>
          <ul className={styles.horzList}>
            <li>View <a href="https://www.aig.com/privacy-policy" target="_blank"> Privacy Policy</a></li>
            <li>View <a href="https://www.aig.com/terms-of-use" target="_blank"> Terms of Use</a></li>
          </ul>
        </Container>
        <NeedHelp />
      </footer>    
  )
}