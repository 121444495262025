import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { Input, Col, Label, Modal, ModalBody, ModalFooter, Row, FormGroup } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import classNames from "classnames";
import styles from "./ViewPlanDetails.module.css";
import AIGFormInputField from "../../components/shared/AIGFormInputField";
import CheckboxInput from "../../components/shared/CheckboxInput/CheckboxInput";
import StandardInput from "../../components/shared/StandardInput/StandardInput";
import { DropDownAutoComplete } from "../../../src/components/inputs/DropDownAutoComplete";
import { ContractContext } from "../../components/contexts/ContractContext";

const ReprintContractModal = ({
    toggle,
    isOpen,
    emailAddress,
    handleSubmit,
    centered
}) => {

    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ":attribute is required.",
            email: "The email must be a valid email address."
        }
    }));

    const [, forceUpdate] = useState();
    const context = useContext(ContractContext)
    const { party, contract } = context.data
    const [validationFired, setValidationFired] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState("mailingaddress");
    const [mailInDays, setMailInDays] = useState(false);
    const [emailInDays, setEmailInDays] = useState(true);
    const [emailForDocuments, setEmailForDocuments] = useState(emailAddress || party.partyEmailModel[0].address);
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [planDocumentType, setPlanDocumentType] = useState('');
    const [formData, setFormData] = useState({
        newAddress: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            stateId: 0,
        },
    });
    const [states, setStates] = useState([]);

    const clientId = context?.data?.contract.clientId || "AIG";

    const mailingAddress = {
        address1: party.shippingAddress?.address1 ?? "",
        address2: party.shippingAddress?.address2 ?? "",
        state: party.shippingAddress?.state ?? "",
        zip: party.shippingAddress?.zip ?? "",
        city: party.shippingAddress?.city ?? "",
    }

    const serviceAddress = {
        address1: party.serviceAddress?.address1 ?? "",
        address2: party.serviceAddress?.address2 ?? "",
        state: party.serviceAddress?.state ?? "",
        zip: party.serviceAddress?.zip ?? "",
        city: party.serviceAddress?.city ?? "",
    }

    const handleNewAddress = (e) => {
        let { name, value } = e.target;

        if (name === 'zip' && value.length > 5) {
            return;
        }

        let { newAddress } = formData;
        setFormData({
            ...formData,
            newAddress: {
                ...newAddress,
                [name]: value,
            },
        });
    };

    const handleStateDropDown = (e) => {
        let state = states.find(({ data }) => data === e).abbreviation;
        let { newAddress } = formData;
        setFormData({
            ...formData,
            newAddress: {
                ...newAddress,
                stateId: e,
                state,
            },
        });
    };

    const getStatesFromAPI = useCallback(() => {
        fetch(`api/party/states/${clientId}`)
            .then((res) => res.json())
            .then((data) => {
                const statesFromAPI = data.map((s) => {
                    return {
                        data: s.stateId,
                        display: s.name,
                        abbreviation: s.abbreviation,
                    };
                });
                setStates(statesFromAPI);
            });
    });

    useEffect(() => {
        getStatesFromAPI();
        if (mailingAddress.address1 === serviceAddress.address1 &&
            mailingAddress.address2 === serviceAddress.address2 &&
            mailingAddress.state === serviceAddress.state &&
            mailingAddress.city === serviceAddress.city &&
            mailingAddress.zip === serviceAddress.zip
        ) {
            setIsSameAddress(true)
        }
    }, []);

    const handleCheckBoxChange = (event) => {
        const { id, checked } = event.target;
        if (id === "mailInDays") {
            setMailInDays(checked)
        }
        if (id === "emailInDays") {
            setEmailInDays(checked)
        }
    }
    let planDocuments = ''
    if (emailInDays || mailInDays) {

        if (emailInDays && mailInDays)
            planDocuments = 'email_mail'
        else if (mailInDays)
            planDocuments = 'mail'
        else if (emailInDays)
            planDocuments = 'email'
    }

    const handleSubmitAction = () => {
        setValidationFired(true);
        if (validator.current.allValid()) {
            handleSubmit(true)
            let contractConfigurationModel = {
                ContractId: parseInt(contract.contractId),
                ContractAttributeId: 24,
                value: planDocuments
            }
            fetch(`api/Contract/CreateOrUpdateContractConfiguration/${contract.clientId}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(contractConfigurationModel)
            }).then(res => {
                if (res.ok) {
                    if (res.status === "200") {
                        validator.current.showMessages();
                        forceUpdate(1);
                    }
                }

                else {
                    validator.current.showMessages();
                    forceUpdate(1);
                }
            });
        } else {
            validator.current.showMessages();
        }
    }

    const handleAddressChange = (e) => {
        setFormData({
            ...formData,
            newAddress: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                stateId: 0,
            }
        })
        setSelectedAddress(e.target.id);
        validator.current.purgeFields()
        setValidationFired(false);
        validator.current.hideMessages();
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} centered={centered} className="modal-md" aria-modal="true" role="dialog" aria-labelledBy="open">
                <ModalBody className={styles.textAlignLeft}>
                    <h5>How would you like to receive your coverage documents?</h5>
                    <p>We will send your coverage documents via:</p>
                    <Row>
                        <Col>
                            <CheckboxInput
                                id="emailInDays"
                                customClass="mb-3"
                                checked={emailInDays}
                                handleCheck={handleCheckBoxChange}
                                clientId={clientId}
                            >
                                Email within 7-14 business days
                            </CheckboxInput>
                            {emailInDays && (
                                <div className={styles.documentsEmail}>
                                    <AIGFormInputField
                                        className={classNames(!validator.current.check(emailForDocuments, "required|email") && validationFired ? "form-error" : "")}
                                        required={false}
                                        type="email"
                                        ariaLabelAndFieldId="email"
                                        fieldName="email"
                                        value={emailForDocuments}
                                        onChange={(e) => setEmailForDocuments(e.target.value)}
                                        message={validator.current.message("email", emailForDocuments, "required|email")}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CheckboxInput
                                id="mailInDays"
                                customClass="mb-0"
                                checked={mailInDays}
                                handleCheck={handleCheckBoxChange}
                                clientId={clientId}
                            >
                                Mail within 30 days
                            </CheckboxInput>
                            {mailInDays && (
                                <div>
                                    {(mailingAddress.address1 ||
                                        mailingAddress.address2 ||
                                        mailingAddress.city ||
                                        mailingAddress.state ||
                                        mailingAddress.zip) &&
                                        <div className="radio">
                                            <Input
                                                type="radio"
                                                name="planDocumentsAddress"
                                                id="mailingaddress"
                                                onChange={(e) => handleAddressChange(e)}
                                                checked={selectedAddress === "mailingaddress"}
                                            />
                                            <Label
                                                className={`${styles.controlsLabelRadio} controls-label`}
                                                for="mailingaddress"
                                            >
                                                <span>
                                                    {`${(mailingAddress.address1 ||
                                                        mailingAddress.address2 ||
                                                        mailingAddress.city ||
                                                        mailingAddress.state ||
                                                        mailingAddress.zip) &&
                                                        "Mailing Address: "}
                                                    ${mailingAddress.address1 && mailingAddress.address1 + ", "}
                                                    ${mailingAddress.address2 && mailingAddress.address2 + ", "}
                                                    ${mailingAddress.city && mailingAddress.city + ",  "}
                                                    ${mailingAddress.state && mailingAddress.state + "  "}
                                                    ${mailingAddress.zip && mailingAddress.zip}`
                                                    }
                                                </span>
                                            </Label>
                                        </div>}
                                    {!isSameAddress && (
                                        <div className="radio">
                                            <Input
                                                type="radio"
                                                name="planDocumentsAddress"
                                                id="serviceaddress"
                                                onChange={(e) => handleAddressChange(e)}
                                                checked={selectedAddress === "serviceaddress"}
                                            />
                                            <Label
                                                className={`${styles.controlsLabelRadio} controls-label`}
                                                for="serviceaddress"
                                            >
                                                <span>
                                                    {`${(serviceAddress.address1 ||
                                                        serviceAddress.address2 ||
                                                        serviceAddress.city ||
                                                        serviceAddress.state ||
                                                        serviceAddress.zip) &&
                                                        "Service Address: "}
                                                        ${serviceAddress.address1 && serviceAddress.address1 + ", "}
                                                        ${serviceAddress.address2 && serviceAddress.address2 + ", "}
                                                        ${serviceAddress.city && serviceAddress.city + ",  "}
                                                        ${serviceAddress.state && serviceAddress.state + "  "}
                                                        ${serviceAddress.zip && serviceAddress.zip}`
                                                    }
                                                </span>
                                            </Label>
                                        </div>
                                    )}
                                    <div className="radio">
                                        <Input
                                            type="radio"
                                            name="planDocumentsAddress"
                                            id="newAddress"
                                            onChange={(e) => handleAddressChange(e)}
                                            checked={selectedAddress === "newAddress"}
                                        />
                                        <Label
                                            className={`${styles.newAddressLabelRadio} controls-label`}
                                            for="newAddress"
                                        >
                                            New Address
                                        </Label>
                                    </div>
                                    {selectedAddress === "newAddress" && (
                                        <div className={`${styles.newAddressWrapper} mb-0 mt-4`}>
                                            <div className="row mb-0">
                                                <Col sm="12">
                                                    <StandardInput
                                                        hasError={(!validator.current.check(formData.newAddress.address1, "required") && validationFired) && "form-error"}
                                                        label="Street Address"
                                                        fieldName="address1"
                                                        autoComplete="address-line1"
                                                        ariaLabelAndFieldId="address1"
                                                        value={formData.newAddress.address1}
                                                        onChange={handleNewAddress}
                                                        validator={validator.current.message("streetAddress", formData.newAddress.address1, "required")}
                                                    />
                                                </Col>                                                
                                            </div>
                                            <div className="row mb-0">
                                                <Col sm="12">
                                                    <StandardInput
                                                        notRequired
                                                        label="Apt., Suite, Building"
                                                        fieldName="address2"
                                                        autoComplete="address-line2"
                                                        ariaLabelAndFieldId="address2"
                                                        value={formData.newAddress.address2}
                                                        onChange={handleNewAddress}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-0">
                                                <Col sm="12">
                                                    <StandardInput
                                                        hasError={(!validator.current.check(formData.newAddress.city, "required") && validationFired) && "form-error"}
                                                        label="City"
                                                        fieldName="city"
                                                        autoComplete="address-level2"
                                                        ariaLabelAndFieldId="city"
                                                        value={formData.newAddress.city}
                                                        onChange={handleNewAddress}
                                                        validator={validator.current.message("city", formData.newAddress.city, "required")}
                                                    />
                                                </Col>                                                
                                            </div>
                                            <div className="multiColWrapper row mb-0">
                                                <Col sm="6">
                                                    <FormGroup className={(!validator.current.check(formData.newAddress.state, "required") && validationFired) && "form-error"}>
                                                        <Label for="serviceState" className="form-label">
                                                            State
                                                        </Label>
                                                        <span aria-hidden="true" className="required-indicator">*</span>
                                                        {states.length > 0 && (
                                                            <DropDownAutoComplete
                                                                name="state"
                                                                id="serviceState"
                                                                aria-label="Select State"
                                                                className="form-control"
                                                                options={states}
                                                                defaultValue={
                                                                    formData.newAddress.stateId === "" ||
                                                                        formData.newAddress.stateId === null
                                                                        ? undefined
                                                                        : formData.newAddress.stateId
                                                                }
                                                                onChangedValue={handleStateDropDown}
                                                                onBlur={handleStateDropDown}
                                                                aria-required="true"
                                                                Value={formData.newAddress.stateId}
                                                                autoComplete="address-level1"
                                                            />
                                                        )}
                                                        {validator.current.message("state", formData.newAddress.state, "required")}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <StandardInput
                                                        hasError={(!validator.current.check(formData.newAddress.zip, "required") && validationFired) && "form-error"}
                                                        includeFormGroupWrapper={false}
                                                        label="Zip Code"
                                                        fieldName="zip"
                                                        ariaLabelAndFieldId="zip"
                                                        autoComplete="postal-code"
                                                        maxLength={5}
                                                        htmlInputType="number"
                                                        value={formData.newAddress.zip}
                                                        onChange={handleNewAddress}
                                                        validator={validator.current.message("zipCode", formData.newAddress.zip, "required")}
                                                    />
                                                </Col>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className={styles.reprintModalButtonWrapper}>
                    <button className="btnLink m-0" onClick={toggle}>Cancel</button>
                    <button className="btn-primary" disabled={!(emailInDays || mailInDays)} onClick={handleSubmitAction}>Submit</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ReprintContractModal;
