import React from 'react'
import styles from './CreditCardIcons.module.css'
import VisaIcon from '../../../../assets/images/cc-visa.svg';
import MasterCardIcon from '../../../../assets/images/cc-mastercard.svg';
import AmexIcon from '../../../../assets/images/cc-amex.svg';
import DiscoverIcon from '../../../../assets/images/cc-discover.svg';

const CreditCardIcons = ({
    showAmex = true, 
    showDiscover = true, 
    ccType
}) => {
    return (
        <ul className={styles.ccImageList}>
            {(ccType === "visa" || ccType === "unknown") &&
                <li className="visa">
                    <span>
                        <img className={styles.ccImages} alt="Visa" src={VisaIcon} />
                    </span>
                </li>
            }
            {(ccType === "mastercard" || ccType === "unknown") &&
                <li className="mastercard">
                    <span className="">
                        <img className={styles.ccImages} alt="mastercard" src={MasterCardIcon} />
                    </span>
                </li>
            }
            {showAmex && (ccType === "amex" || ccType === "unknown") &&
                <li className="amex">
                    <span className="">
                        <img className={styles.ccImages} alt="Amex" src={AmexIcon} />
                    </span>
                </li>
            }
            {showDiscover && (ccType === "discover" || ccType === "unknown") &&
                <li className="discover">
                    <span className="visuallyhidden">
                        <img className={styles.ccImages} alt="Discover" src={DiscoverIcon} />
                    </span>
                </li>
            }
        </ul>
    )
}

export default CreditCardIcons;