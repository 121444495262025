import React, { useContext } from 'react'
import { countryConstants } from '../../contexts/LanguageTranslation/CountryConstants';
import useTranslation from '../../contexts/LanguageTranslation/useTranslation';

/** 
 * THIS COMPONENT IS FOR ANY CALL LINKS.
 * TAKES THE **FORMATTED** PHONE NUMBER STRING AS A PROP
 * WILL STRIP CHARS AND ADD COUNTRY CODE ON THE HREF
 * WILL DISPLAY THE PASSED STRING AS THE DISPLAY TEXT
*/

const CallLink = ({ phone, ariaLabel = '' }) => {
    const { country } = useTranslation();
    const { countryCode } = countryConstants[country].phone

    const telLink = () => {
        let phoneNum = phone
        if (!phone) phoneNum = ''

        if (typeof phoneNum !== "string") phoneNum = phone.toString()

        const phoneSubstr = phoneNum?.charAt(0) === "0" ? phoneNum.slice(1) : phoneNum
        const stripped= phoneSubstr?.replace(/[^\d]/g, "")

        return `${countryCode}${stripped}`
    } 

    return (
        <a href={`tel:${telLink()}`} aria-label={ariaLabel} className="callLink">
            {phone}
        </a>
    )
}

export default CallLink;