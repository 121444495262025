import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './Asset.module.css';
import { Col } from 'reactstrap';
import AssetIcon from './AssetIcon';
import { withRouter } from 'react-router';
import { useMediaQuery } from 'react-responsive'
import AssetPopover from './AssetPopoverDesktop';
import AssetModal from './AssetModalMobile';
import { useLocation } from 'react-router';

const Asset = ({
    contract,
    assetName,
    asset,
    assetIndex,
    tabId,
    showModelSerial
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
    const location = useLocation();
    const [activeAssetId,] = useState(location?.state?.activeAssetId);
    const [activeAssetClaim, setActiveAssetClaim] = useState(false);

    useEffect(() => {
        if (isMobile && popoverOpen) {
            document.body.style.overflow = 'hidden'
        }
        return () => document.body.style.overflow = 'unset';
    }, [popoverOpen, isMobile]);

    useEffect(() => {
        if (activeAssetId === asset?.assetId) {
            setActiveAssetClaim(true);
            togglePopover()
        }
    }, [activeAssetId])

    const popoverId = ["PopoverAsset", assetIndex, tabId].join('-')

    const togglePopover = (toggle) => {
        setPopoverOpen(!popoverOpen);
        toggle && setActiveAssetClaim(false);
    }

    const togglePopoverOnEnter = (event) => {
        if (event.keyCode == 13) {
            setPopoverOpen(!popoverOpen);
        }
    }

    let contractAssets = contract?.contractAssetModel?.filter(ca => ca?.assetId === asset.assetId);

    return (
        <Col xs="6" md="3">
            <button
                id={popoverId}
                type="Button"
                tabIndex="0"
                className={`${styles.btnPopover} ${activeAssetClaim ? styles.activeAsset : ''}`}
                onClick={() => togglePopover(true)}
                onKeyDown={togglePopoverOnEnter}
            >

                <AssetIcon className={styles.assetIcon} assetName={assetName} />{assetName}

            </button>
            {popoverOpen && (
                isMobile ? (
                    <AssetModal
                        isOpen={isMobile && popoverOpen}
                        togglePopover={togglePopover}
                        contractAssets={contractAssets}
                        clientId={contract.clientId}
                        contractId={contract.contractId}
                        showModelSerial={showModelSerial}
                        assetName={assetName}
                    />
                ) : (
                    <AssetPopover
                        isOpen={!isMobile && popoverOpen}
                        popoverId={popoverId}
                        togglePopover={togglePopover}
                        contractAssets={contractAssets}
                        clientId={contract.clientId}
                        contractId={contract.contractId}
                        showModelSerial={showModelSerial}
                        assetName={assetName}
                    />
                )
            )}
        </Col>
    );
}
export default withRouter(Asset)