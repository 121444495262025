import React from 'react';
import PropTypes from 'prop-types'
import { FormGroup, Input, Label } from 'reactstrap';

/**
 * Component to handle form items with basic Text inputs.
 * REQUIRED PROPS: 
 * - fieldName (for validation), 
 * - fieldLabel (for UI),
 * - onChange (handled in parent and passed), message (used primarily for error cases).
 * DEFAULT VALS:  
 * - requiered defaults to TRUE (must declare if NOT required),
 * - disabled defaults to FALSE (must declare if disabled)
 * OPTIONAL PROPS:
 * - onBlur: function
 * - className: add a className (can be conditional in parent based on validation),
 * - defaultValue,
 * - value (controlled components should be handled in the parent),
 * - placeholder
 */

const AIGFormInputField = (props) => {
    PropTypes.checkPropTypes(AIGFormInputField.propTypes, props, 'prop', 'AIGFormInputField');
    const {
        fieldName,
        fieldLabel,
        onChange,
        required,
        disabled,
        onBlur,
        className,
        defaultValue,
        value,
        placeholder,
        message
    } = props;

    return (
        <FormGroup
            className={className}
        >
            {fieldLabel && (
                <Label for={fieldName} className="form-label">
                    {fieldLabel}
                    {required && (
                        <span aria-hidden="true" className="required-indicator">
                            *
                        </span>
                    )}
                </Label>
            )}
            <Input
                type="text"
                disabled={disabled}
                className={className}
                id={fieldName}
                name={fieldName}
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
            />
            {message}
        </FormGroup>
    )
}

AIGFormInputField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
}

AIGFormInputField.defaultProps = {
    required: true,
    disabled: false,
}

export default AIGFormInputField;