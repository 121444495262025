import React, { useState } from 'react';
import AIGLogo from '../assets/images/aig-logo.svg';
import classnames from 'classnames';
import styles from './Header.module.css';
import { Nav, NavItem, NavLink, NavbarToggler, Collapse, Modal, ModalBody, Navbar } from 'reactstrap';
import CloseMobile from '../assets/images/close-mobile.svg';

export const Header = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [isOpen, setIsOpen] = useState(false);

    const handleLogoClick = (event) => {
        event.preventDefault();
        props.onLogoClickHandler(event);
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        linkToRedirect(tab);
    }

    const linkToRedirect = (actionId) => {
        let route = '';
        switch (actionId) {
            case '1': {
                route = 'faq';
                break;
            }
            case '2': {
                route = 'coveragedocs';
                break;
            }
            case '3': {
                route = 'info/contact-us';
                break;
            }
        }
        const link = `/${route}`;
        window.open(link, '_blank')
    }

    const toggleClose = () => {
        setIsOpen(!isOpen);
    }

    // HELPER TO WRAP AND THUS ONLY HAVE TO MAINTAIN ONE MENU INSTEAD OF TWO
    const ConditionalNavWrapper = ({ children }) => (
        isOpen ? (
            <Modal
                isOpen={true}
                toggle={toggle}
                className={`md header-content ${styles.navMenu}`}
                aria-modal="true"
            >
                <a onClick={toggleClose}>
                    <img
                        tabIndex={0}
                        className={`logoClose ${styles.closeButton}`}
                        src={CloseMobile}
                        alt="Close"
                    />
                </a>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>
        ) : (
            <Collapse navbar>
                {children}
            </Collapse>
        )
    )

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    };

    return (
        <header>
            <div className={styles.headerBorder}>
                <div className={classnames(styles.headerLg, 'container', 'px-0')}>
                    <div className={classnames(styles.logoContainer, 'my-1')}>
                        <a onClick={(event) => handleLogoClick(event)}>
                            <img
                                className="logo"
                                src={AIGLogo}
                                alt="AIG"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container px-0">
                <div className={styles.navContainer}>
                    <div className={styles.mobileLogo}>
                        <a onClick={(event) => handleLogoClick(event)}>
                            <img
                                className={styles.logo}
                                src={AIGLogo}
                                alt="AIG"
                            />
                        </a>
                    </div>
                    <Navbar light expand="md" role="navigation" className={styles.mobileNav}>
                        <NavbarToggler aria-label="On click opens a navigation menu" onClick={toggleMenu} />
                        <ConditionalNavWrapper>
                            <Nav className={classnames('ml-auto', styles.navTabs)} navbar>
                                <NavItem>
                                    <NavLink className={{ active: activeTab === '1' }} onClick={() => { toggle('1'); }}>
                                        FAQs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={{ active: activeTab === '2' }} onClick={() => { toggle('2'); }}>
                                        Coverage Documents
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={{ active: activeTab === '3' }} onClick={() => { toggle('3'); }}>
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </ConditionalNavWrapper>
                    </Navbar>
                </div>
            </div>
        </header>
    );
}
