import React, { useState, useEffect, useContext, createContext } from 'react';
import { useWhiteLabel } from './useWhiteLabel';
import { ContractContext } from '../contexts/ContractContext';
// import { isHWAChatEndRoute } from '../../helpers';

const WhiteLabelAccountContext = createContext(null);

const usingAIGBranding = ['aig', 'arw', null, undefined]

const WhiteLabelProvider = ({ children }) => {
    const context = useContext(ContractContext)
    const { data } = context
    const account =
        data?.isChileApple
            ? 'southbridge'
            : ((data.clientId || data.contract.clientId)?.toLowerCase());
    // returns bool
    const brandingComplete = useWhiteLabel(account)

    const needsSkinning = !usingAIGBranding.includes(account) && !brandingComplete

    // useEffect(() => {
    //     if (brandingContext.clientId !== null) {
    //         setAccount(brandingContext.clientId.toLowerCase());
    //     } else {
    //         // set clientId and resolve if data present
    //         if (data) {
    //             setAccount(data.contract.clientId);
    //         }
    //         //if the data is not null and clientId is hwa it automatically displays hwa branding
    //         //initially the data value is loading as null during that time if the ischatendroute is true then account is set to hwa for hwa branding
    //         else {
    //             const hwaChat = async () => {
    //                 const result = await isHWAChatEndRoute();
    //                 console.log(result);
    //                 if (result == true) {
    //                     setAccount('hwa');
    //                 }
    //             };
    //             hwaChat();
    //         }
    //     }
    //     setIsLoading(false)
    // }, [data, brandingContext.clientId])
    
    // FOR DEBUGGING
    // if (needsSkinning || isLoading) return <div>{`skinning... needsSkinning: ${needsSkinning}, isLoading: ${isLoading}`}</div>    // FOR DEBUGGING

    if (needsSkinning) return null

    return (
        <WhiteLabelAccountContext.Provider value={account}>
            {children}
        </WhiteLabelAccountContext.Provider>
    )
};

export { WhiteLabelProvider, WhiteLabelAccountContext };