import React, { useState } from 'react';
import { useContext } from 'react';
import { Input, Label } from 'reactstrap';
import { ApptContext } from '.';
import styles from './AppointmentSelect.module.css';

/**
 * Reusable in types 1, 2, 3, 4 of the file claim appt process
 * Could be developed into free-standing reusable component
 */

export const SpecialNotesToServicer = ({
    fullwidth = false   //boolean ,default is false
}) => {
    const { dispatch, state } = useContext(ApptContext)

    const notes = state.notesForServicer;

    return (
        <div className={fullwidth ? styles.fullWidthNotesWrapper : styles.specialNotesWrapper}>
            <Label className={styles.notesLabel} for="exampleText">Special Instructions for the Servicer</Label>
            <Input 
                className={styles.notesField}
                type="textarea" 
                name="text" 
                id="exampleText" 
                onChange={(e)=>dispatch({
                    type: 'SERVICER_NOTES',
                    notes: e.target.value
                })} 
                maxLength={250}
                value={notes}
            />
            <p>{`${notes ? notes.length : 0}/250`}</p>
        </div>
    )
}
