import React, { useContext, Component } from 'react';
import qs from 'qs';
import { verifyaigHwaToken, verifyAigJwtToken } from '../../../../src/helpers'
import { ContractContext } from '../../contexts/ContractContext';

export default class VerifyAigToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aigtoken: this.props.match.params.aigtoken,
            agreementid: this.props.match.params.agreementid,
            vendorToken: this.props.match.params.token
        }
    }

    verifyAigToken = async () => {
        const response= await verifyaigHwaToken(this.props.match.params.aigtoken, this.props.match.params.agreementid);
        
        if (response.tokenModel != null && response.contracts != null) {
            let value = this.context;
            let contract = response.contracts;
            value.updateContract(contract[0]);
            value.updateParty(contract[0]?.party);
            value.updateContracts(contract);
            this.props.history.push('/planoverview');
        }
        else {
           var res= await fetch('vendor/GetHwaRedirectUri');
            const data = await res.json();
            window.location.href = `${data.uri}/auth/login`;
        }
    }

    verifyJwtToken = async () => {
        var token = typeof this.state.vendorToken == 'undefined' ? qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.token : this.state.vendorToken;
        
        const response = await verifyAigJwtToken(token);
        if (response.tokenModel != null && response.contracts != null) {
            
            let value = this.context;
            let contract = response.contracts;
            value.updateContract(contract[0]);
            value.updateParty(contract[0]?.party);
            value.updateContracts(contract);
            this.props.history.push({
                pathname: '/planoverview',
                search: `?token=${token}`,
                state: {
                    view: "file",
                    activeOverviewTab: "fileClaim"
                }
            });
        }
        else {
            var url = response.redirect;
            if (url.startsWith('http://') || url.startsWith('https://')) {
                window.location.assign(url)
            } else {
                this.props.history.push({ pathname: response.redirect })
            }
        }
    }

    async componentDidMount() {
        if (!window.location.pathname.toLowerCase().includes("hwa")) {
            await this.verifyJwtToken(this.state.vendorToken);
        }
        else {
            await this.verifyAigToken(this.state.aigtoken, this.state.agreementid);
        }
    }

    render() {
        return null
    }
}
VerifyAigToken.contextType = ContractContext;
