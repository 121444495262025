import React, { Component } from "react";
import "./Faqs.Module.css";
import { Container, Card, CardHeader, CardBody, Collapse, Row, Col } from "reactstrap";
import classNames from "classnames";
import { ReactComponent as Chevron } from '../../../assets/images/Vendor/Apple/arrow-cheveron-down.svg';
import Ques from "./Ques";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { callAdobe } from "../../../helpers";
import InternationalPhoneStatic from "../../../components/shared/PhoneNumberUtility/InternationalPhoneStatic";
import CloseIcon from '../../../assets/images/close.svg';
import SearchIcon from '../../../assets/images/search.svg';


class Faqs extends Component {
    state = {
        open: [],
        activeTab: "general",
        searchTerm: '',
        searchResults: [],
        pageTitle: 'FAQs | AIG AppleCare+ with Theft and Loss Claims'
    };

    constructor(props) {
        super(props);
        this.child = React.createRef()
    }

    componentDidMount() {

        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: '/vendor/apple/FAQ',
            pageName: 'FAQs'
        })
    }

    toggle = (e) => {
        const { currentTarget } = e;
        const name = currentTarget.getAttribute("name");

        let pos = -1;
        const { open } = this.state;
        if (name) {
            pos = open.indexOf(name);
            if (pos > -1) {
                open.splice(pos, 1);
            } else {
                open.push(name);
            }
        }
        this.setState({ open });
    };

    handleFormSubmit = (e) => {

        e.preventDefault();

    }

    onChangeTab = (e) => {
        const { currentTarget } = e;
        const activeTab = currentTarget.getAttribute("name");
        this.setState({ activeTab });
    };

    handleSearch = (e) => {
        this.setState({ searchTerm: e.target.value }, () => {
            this.state.searchTerm.length > 2 &&
                this.child.current.handleSearch(this.state.searchTerm);
        });
    }
    handleClearAction = () => {
        this.setState({ searchTerm: '' })
    }

    render() {
        const { open, activeTab, searchTerm, searchResults, pageTitle } = this.state;
        return (
            <DocumentTitle title={pageTitle}>
                <div>
                    <div className="pageHeader">
                        <Container>
                            <form onSubmit={this.handleFormSubmit} className="pageHeaderContent">
                                <Col md="5" lg="4" className="form-group">
                                    <h1 className="h3 cus" id="faqSearchLabel">How Can We help?</h1>
                                    <div className="searchBox">
                                        <input className="form-control" type="text" id="faqSearch" aria-labelledby="faqSearchLabel" value={searchTerm} onChange={this.handleSearch} />
                                        {searchTerm ? <span onClick={this.handleClearAction} className="searchIcon" role="button"><img className="icon" role="presentation" src={CloseIcon} /> </span> :
                                            <span className="searchIcon" ><img className="icon search-svg" role="presentation" src={SearchIcon} /> </span>}
                                    </div>
                                </Col>
                            </form>
                        </Container>
                    </div>

                    <Container className="searchResultsContainer">
                        {searchTerm && searchTerm.length > 2 && <Ques searchTerm={this.state.searchTerm} ref={this.child} />}
                    </Container>
                    <Container>
                        <ul
                            className="nav faqNavs row no-gutters"
                            id="faqTabs"
                            role="tablist"
                        >
                            <li className="navItem col-4 col-lg" role="tab">
                                <p
                                    className={`nav-link ${activeTab === "general" ? "active" : ""
                                        }`}
                                    id="generalFAQs"
                                    data-toggle="pill"
                                    onClick={this.onChangeTab}
                                    name="general"
                                >
                                    General <br className="show-sm-down" />Questions
                                </p>

                            </li>
                            <li className="navItem col-4 col-lg" role="tab">
                                <p
                                    className={`nav-link ${activeTab === "claims" ? "active" : ""}`}
                                    id="claimFAQs"
                                    data-toggle="pill"
                                    onClick={this.onChangeTab}
                                    name="claims"
                                >
                                    Claim <br className="show-sm-down" />Process
                                </p>
                            </li>
                            <li className="navItem col-4 col-lg" role="tab">
                                <p
                                    className={`nav-link ${activeTab === "replacement" ? "active" : ""
                                        }`}
                                    id="replaceFaQs"
                                    data-toggle="pill"
                                    onClick={this.onChangeTab}
                                    name="replacement"
                                >
                                    Replacement <br className="show-sm-down" />Device
                                </p>
                            </li>
                        </ul>
                        <div className="tab-content" id="tabs-tabContent">
                            {activeTab === "general" && (
                                <div
                                    className={activeTab === "general" ? "active" : ""}
                                    id="tabsGeneralFAQs"
                                    role="tabpanel"
                                    aria-labelledby="generalFAQs"
                                >
                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="general0"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q1">

                                                <div
                                                    className={`${open.includes("general0") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>01.</p>
                                                    <p>What is AppleCare+ with Theft and Loss? </p>
                                                </div>

                                                <div
                                                    className={`${open.includes("general0") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("general0")} aria-labelledby="Q1" role="region">
                                            <CardBody>
                                                <p>
                                                    AppleCare+ with Theft and Loss is an optional device protection program that provides combined benefits under a service contract (AppleCare+) for hardware coverage, technical support, battery depletion, and accidental damage from handling (ADH) and an insurance policy (Theft and Loss) that provides benefits for the enrolled device including hardware coverage.
                                                </p>
                                                <p className="mb-0">
                                                    To purchase Theft and Loss as a stand-alone insurance policy, please see detailed pricing on your state-specific <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents</Link>, send an email to <a href="mailto:contractcreation@aig.com">contractcreation@aig.com</a> or call <a href="tel:866-243-6434" className="callLink">
                                                        <InternationalPhoneStatic number={'(866) 243-6434.'} />
                                                    </a>
                                                </p>
                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="general1"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q2" >
                                                <div
                                                    className={`${open.includes("general1") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>02.</p>
                                                    <p>Is there a deductible?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("general1") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("general1")} aria-labelledby="Q2" role="region">
                                            <CardBody>
                                                A service fee or deductible of $149 will apply per approved theft or loss incident/claim.
                                                For specific details about the Plan’s deductibles, refer to your state-specific{" "}
                                                <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents</Link>.
                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="general3"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q4" >
                                                <div
                                                    className={`${open.includes("general3") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>03.</p>
                                                    <p>How many claims are covered under a plan?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("general3") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("general3")} aria-labelledby="Q4" role="region">
                                            <CardBody>
                                                <p>
                                                    AppleCare+ with Theft and Loss provides coverage for:
                                                    unlimited repairs or replacement for accidental damage from
                                                    handling (ADH) provided by Apple under AppleCare+,
                                                    subject to the per incident service fees listed below;
                                                    and a maximum of 2 replacements per 12-month period from your date of purchase for loss or theft under the Theft and Loss insurance policy, subject to the per claim
                                                    deductible listed below. The maximum amount to be
                                                    paid for any one claim is limited to the retail price of
                                                    your device. There is no claim limit for hardware
                                                    coverage (malfunction after the manufacturer’s warranty
                                                    expires). For specific details about the Plan’s claim
                                                    limits, refer to your state-specific{" "}
                                                    <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents.</Link>
                                                </p>
                                                <p className="mb-0">
                                                    For your lost or stolen claim to be eligible for
                                                    coverage, Find My iPhone must be enabled on your device
                                                    when it is lost or stolen.
                                                </p>
                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="general4"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q5" >

                                                <div
                                                    className={`${open.includes("general4") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>04.</p>
                                                    <p>How long do I have to file a claim?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("general4") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("general4")} aria-labelledby="Q5" role="region">
                                            <CardBody>
                                                The Plan owner must file a claim within the time frame
                                                indicated in the Plan’s state-specific{" "}
                                                <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents.</Link>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                </div>
                            )}

                            {/* Claims FAQ */}

                            {activeTab === "claims" && (
                                <div
                                    className={activeTab === "claims" ? "active" : ""}
                                    id="tabs-claimFAQs"
                                    role="tabpanel"
                                    aria-labelledby="claimFAQs"
                                >
                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="claims0"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q6">
                                                <div
                                                    className={`${open.includes("claims0") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>01.</p>
                                                    <p>What is the fastest way to file a claim?</p>
                                                </div>

                                                <div
                                                    className={`${open.includes("claims0") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("claims0")} aria-labelledby="Q6" role="region">
                                            <CardBody>
                                                To begin a theft and loss claim,
                                                please visit <a href="https://support.apple.com/choose-country-region" target="blank">support.apple.com</a>  or contact Apple Support at {" "}
                                                <a href="tel:800-275-2273" className="callLink">
                                                    800-APL-CARE (800-275-2273)
                                                </a>.
                                                Please use the unique link in the email from the AIG Theft and Loss Service Center to complete your claim.
                                                Once you begin your Theft or Loss claim,
                                                your device will be erased and disabled.
                                                Once your claim is approved, ownership will be transferred to AIG.
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="claims1"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q7">
                                                <div
                                                    className={`${open.includes("claims1") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>02.</p>
                                                    <p>What information do I need to start a claim?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("claims1") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("claims1")} aria-labelledby="Q7" role="region">
                                            <CardBody>
                                                <ul>
                                                    <li>Apple ID and password </li>
                                                    <li>The claimed device's Serial Number</li>
                                                    <li>
                                                        Information about the claim, including the date of
                                                        loss{" "}
                                                    </li>
                                                    <li>
                                                        Email Address (to provide you with updates on the
                                                        status of your claim or replacement device)
                                                    </li>
                                                    <li>
                                                        A credit card or debit card for your deductible
                                                        payment
                                                    </li>
                                                </ul>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="claims2"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q8">
                                                <div
                                                    className={`${open.includes("claims2") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>03.</p>
                                                    <p>
                                                        What is the importance of providing a valid email
                                                        during the claims process?
                                                    </p>
                                                </div>
                                                <div
                                                    className={`${open.includes("claims2") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("claims2")} aria-labelledby="Q8" role="region">
                                            <CardBody>
                                                Your email address allows us to provide you with important
                                                information on the status of your claim, such as when it
                                                has been approved, when your device shipped, and a
                                                tracking number to confirm delivery of your device. Please
                                                note that your email address is used for claim purposes
                                                only.
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="claims3"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q9">
                                                <div
                                                    className={`${open.includes("claims3") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>04.</p>
                                                    <p>How can I check the status of my claim?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("claims3") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("claims3")} aria-labelledby="Q9" role="region">
                                            <CardBody>
                                                The fastest and easiest way to check the status of your
                                                claim anytime is by visiting the <a href="https://aigtheftandloss.com/" target="_blank"> Homepage</a>{" "}
                                                please check the link on this site and entering your Apple Dispatch ID or
                                                Case/Claim Number and Zip Code.
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                </div>
                            )}

                            {/* Replacement FAQ */}

                            {activeTab === "replacement" && (
                                <div
                                    className={activeTab === "replacement" ? "active" : ""}
                                    id="tabsReplacementFAQs"
                                    role="tabpanel"
                                    aria-labelledby="ReplacementFAQs"
                                >
                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="replacement0"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q10">
                                                <div
                                                    className={`${open.includes("replacement0") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>01.</p>
                                                    <p>
                                                        How long does it take to receive a replacement device?
                                                    </p>
                                                </div>
                                                <div
                                                    className={`${open.includes("replacement0") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("replacement0")} aria-labelledby="Q10" role="region">
                                            <CardBody>
                                                Once your claim is approved, your device is ordered and shipped directly from Apple
                                                via next-business-day delivery Monday through Friday;
                                                when available, Saturday delivery may be available for an additional fee.
                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="replacement2"
                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q12">
                                                <div
                                                    className={`${open.includes("replacement2") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>02.</p>
                                                    <p>How do I activate my replacement device?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("replacement2") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("replacement2")} aria-labelledby="Q12" role="region">
                                            <CardBody>
                                                <p>
                                                    Enclosed with each replacement is a Quick Start Guide to
                                                    help you get your replacement device up and running. If
                                                    your replacement is for a loss or theft claim, please
                                                    use the instructions below:
                                                </p>
                                                <ol>
                                                    <li>Charge your replacement device</li>
                                                    <li>
                                                        Power on your device and follow on-screen instructions
                                                    </li>
                                                    <li>
                                                        Enable Find My iPhone, follow the steps provided at{" "}
                                                        <a href="https://support.apple.com/en-us/HT205362" target="blank" > support.apple.com/HT205362</a>
                                                    </li>
                                                </ol>
                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="replacement3"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q13">
                                                <div
                                                    className={`${open.includes("replacement3") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>03.</p>
                                                    <p>Does my replacement device have a warranty?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("replacement3") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("replacement3")} aria-labelledby="Q13" role="region">
                                            <CardBody>
                                                Your replacement device comes with a 90-day warranty or
                                                the remainder of your AppleCare+ with Theft and Loss
                                                agreement, whichever is longer.
                                            </CardBody>
                                        </Collapse>
                                    </Card>

                                    <Card className="questionCard">
                                        <CardHeader className="card-header"
                                            onClick={this.toggle}
                                            name="replacement5"

                                        >
                                            <div className="accordionFaq" title="opens a accordian" role="button" id="Q15">
                                                <div
                                                    className={`${open.includes("replacement5") ? "active" : "closed"
                                                        } question`}
                                                >
                                                    <p>04.</p>
                                                    <p>Need help with your replacement?</p>
                                                </div>
                                                <div
                                                    className={`${open.includes("replacement5") ? "active" : "closed"
                                                        } arrow`}
                                                >
                                                    <Chevron />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={open.includes("replacement5")} aria-labelledby="Q15" role="region">
                                            <CardBody>
                                                <p>
                                                    For questions regarding your shipment or hardware issues, please contact Apple at{" "}
                                                    <a href="tel:800-692-7753" className="callLink">
                                                        <InternationalPhoneStatic number="866-692-7753" />
                                                        {/* (800) 692-7753 */}
                                                    </a>.
                                                </p>
                                                <p>
                                                    For mobile service issues, contact your mobile service
                                                    provider.
                                                </p>
                                                <p>
                                                    For all other issues, please contact us at{" "}
                                                    <a href="tel:866-258-7419" className="callLink">
                                                        <InternationalPhoneStatic number="866-258-7419" />
                                                        {/* (866) 258-7419 */}
                                                    </a>.
                                                </p>
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                </div>
                            )}
                        </div>
                        <div className="helpLine">
                            <h2 class="h4">Still have questions?</h2>
                            <p>If you are unable to find answers to your questions{" "} <br className="show-md-up" aria-hidden="true" /> within our FAQs, you can always <Link to="/vendor/apple/contactus" target="_blank" aria-label="contact us opens a new details window">
                                contact us!
                            </Link></p>
                            {/* <Row className="d-flex justify-content-center">
                                <Col xs="10" md="8" lg="4">
                                    <div className="helpLineNum">
                                        <img src={require("../../../assets/images/Phone_Icon.svg")} role="presentation" />
                                        <p><a href="tel:866-258-7419" className="callLink" aria-label="Click to Call (866) 258-7419">(866) 258-7419</a></p>
                                        <p>Available 24/7</p>
                                    </div>
                                </Col>
                                <Col xs="10" md="8" lg="4">
                                    <div className="helpLineNum">
                                        <img src={require("../../../assets/images/Mail_Icon.svg")} role="presentation" />
                                        <p><a href="mailto:aigservicecenter@servicenet.com" aria-label="Click to Email">aigservicecenter@servicenet.com</a></p>
                                        <p>Send us an email</p>
                                    </div>
                                </Col>
                            </Row>*/}
                        </div>
                    </Container>
                </div>
            </DocumentTitle>

        );
    }
}

export default Faqs;
