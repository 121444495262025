import React, { useState, useEffect, useContext } from 'react';
import {
    Container, Row, Col,
} from 'reactstrap';
import styles from './ConfirmationLT.module.css';
import classNames from 'classnames';
import useLoadAtTop from '../../../_ReactHooks/useLoadAtTop';
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import { ContractContext } from '../../../contexts/ContractContext';
import { appleServiceNumbers } from "../../../../components/_BrandingProvider/brandContent";
import ExclamationCircle from '../../../../assets/images/exclamation-circle.svg';
import SBExclamationCircle from '../../../../assets/images/Vendor/SB/exclamation-circle.svg';

let btnPrimary = "btn-primary";
let mt18 = styles.mt18;
let confirmBold = styles.confirmBold;

const ErrorMessages = () => {
    useLoadAtTop();
    const international = useTranslation('components/Vendor/Apple/IntakeLoss/ErrorMessages')
    const { translations, country } = international
    const contractContext = useContext(ContractContext);
    const { data: { isChileApple } } = contractContext;
    const [errorImgSrc, setErrorImgSrc] = useState(ExclamationCircle);
    const customerServicePhone = appleServiceNumbers.customerService[country]

    useEffect(() => {
        document.title = 'System Error | AIG AppleCare+ with Theft and Loss Claims'
    },[])

    useEffect(() => {
        if (isChileApple) {
            setErrorImgSrc(SBExclamationCircle);
        } else {
            setErrorImgSrc(ExclamationCircle);
        }
    }, [isChileApple])

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <Container>
            <section id="apiError" className={styles.confirmSection}>
                <Row>
                    <Col>
                        <img className={styles.errorIcon} alt="System Error" src={errorImgSrc} />
                        <h1 className="h3">{translations.title || 'Oops, something went wrong on our side.'}</h1>
                        
                        <p className={styles.confirmBold}>
                            {translations.p1 || 'Our system is unable to process your request at this time.'}
                        </p>
                        
                        <p className={styles.confirmBold}>
                            {translations.pleaseCall || 'Please call '}
                            <a href={`tel:${customerServicePhone}`} className={classNames("callLink", confirmBold)}>
                                {customerServicePhone}
                            </a>
                            {translations.orTryLater || ' or try again later.'}
                        </p>
                    </Col>
                </Row>
            </section>
        </Container>
    )
}

export default ErrorMessages