import React, { useEffect } from 'react';
import axios from 'axios';
import PageLoading from "../shared/PageLoading/PageLoading";

function PdfViewer({ match }) {
 const { name } = match.params;

 useEffect(() => {
   axios({
     url: `api/document/${name}`,
     method: 'GET',
     responseType: 'blob', // important
   }).then(response => {
     const file = new Blob([response.data], { type: 'application/pdf' });
     const fileUrl = URL.createObjectURL(file);
     window.open(fileUrl, '_self'); // open the PDF in the same tab
   });
 }, [name]);

 return (
    <PageLoading text={"Loading PDF..."} height={300} />
 );
}

export default PdfViewer;