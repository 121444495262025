import { parsePhoneNumber } from 'awesome-phonenumber';
import React from 'react'
import useTranslation from '../../contexts/LanguageTranslation/useTranslation';
import { ISOCountryCodes } from './phoneUtils';

const InternationalPhoneWithCode = ({ number }) => {
    // console.log("=== number ===", number)
    const { country } = useTranslation();
    const pn = parsePhoneNumber(number, ISOCountryCodes[country.toUpperCase()])
    const internationalFormat = pn.getNumber('international')
    const nationalFormat = pn.getNumber('national')


    return (
        <span>
            {country === "US" 
                ? nationalFormat 
                : internationalFormat
            }
        </span>
    )
}

export default InternationalPhoneWithCode;