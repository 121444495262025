import React, { Fragment, useContext, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  NavbarToggler,
  Collapse,
  Navbar,
} from "reactstrap";
import styles from "./NJRHeader.module.css";
import classnames from "classnames";
import { ReactComponent as NJRLogo } from "../../../assets/images/Vendor/NJR/njr-retail-logo.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import { useMediaQuery } from "react-responsive";
import getClientContent from "../../../components/_BrandingProvider/brandContent";
import { ContractContext } from "../../../components/contexts/ContractContext";

const NJRHeader = () => {
  // const context = useContext(ContractContext)
  // const { clientId } = context.data.contract
  // const {variableText} = getClientContent(clientId);
  // const CustomerServPhoneNumber = variableText.customerService.phone;
  const [activeTab, setActiveTab] = useState("1");
  const [navOptions, setNavOptions] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleNavbar = () => {
    setNavOptions(!navOptions);
  };

  return (
    <>
      <div className={styles.callNum}>
        <Container className="p-0">
          Call <a className="callLink">(XXX) XXX-XXXX</a>
          {/* Replace the ph number with {CustomerServPhoneNumber} when clientId is added for NJR. */}
        </Container>
      </div>
      <Container className="p-0">
        <div className={styles.njrHeader}>
          <a href="#" className={styles.njrLogo}>
            <NJRLogo />
          </a>
          <Navbar color="faded" light expand="md" className={styles.mobileNav}>
            <NavbarToggler onClick={toggleNavbar} />
            {!isMobile && (
              <Collapse isOpen={navOptions} navbar>
                <Nav navbar className={styles.navTabsNJR}>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "1" },
                        styles.navLinkNJR,
                        "mr-5"
                      )}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      FAQs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === "2" },
                        styles.navLinkNJR
                      )}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Contact Us
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            )}
          </Navbar>
        </div>
        {isMobile && (
          <>
            <Collapse isOpen={navOptions} navbar className={styles.mobileMenu}>
              <span
                type="button"
                onClick={toggleNavbar}
                className={styles.closeMenu}
              >
                <CloseIcon />
              </span>
              <Nav navbar className={styles.navTabsNJR}>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "1" },
                      styles.navLinkNJR
                    )}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    FAQs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "2" },
                      styles.navLinkNJR
                    )}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Contact Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "3" },
                      styles.navLinkNJR
                    )}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Call <a className="callLink">(XXX) XXX-XXXX</a>
                    {/* Replace the ph number with {CustomerServPhoneNumber} when clientId is added for NJR. */}
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </>
        )}
      </Container>
    </>
  );
};

export default NJRHeader;
