import React, { useState } from 'react';
import Cleave from 'cleave.js/react';
import { FormGroup, Label } from 'reactstrap';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import useTranslation from '../../contexts/LanguageTranslation/useTranslation';
import { parsePhoneNumber } from 'awesome-phonenumber'
import { ISOCountryCodes } from './phoneUtils';
/**
 *  FOR USE IN ALL CASES OF PHONE NUMBERS. CANNOT TRANSLATE INSIDE COMPONENT.
 *  MUST PASS TRANSLATED STRINGS FROM PARENT IF TRANSLATION NEEDED.
 */

const PhoneNumberInput = ({
    fieldName,
    label,
    labelCustomClass = "",
    error,      // pass this.validator.message( FIELDNAME, VALUE, RULE)
    onChange = () => { },
    value,
    validationFired,
    required = true
}) => {
    const language = useTranslation()
    const { country, countryVars: { phone } } = language
    const isoCountry = ISOCountryCodes[country]

    // console.log("=== value ===", value)
    const passedNumberFormatted = parsePhoneNumber(value || '', isoCountry).getNumber('national') || ''
    const [number, setNumber] = useState(passedNumberFormatted)

    const handleChange = (e) => {
        const pn = parsePhoneNumber(e.target.value, isoCountry)
        e.target.phoneNumberModel = pn.toJSON()
        // console.log("=== pn.getNumber() ===", pn.toJSON())
        setNumber(pn.getNumber('national'))
        onChange(e)
    }

    const cleaveOptions = (country === "US" ?
        {
            blocks: [0, 3, 0, 3, 4],
            delimiters: ["(", ")", " ", "-"],
            numericOnly: true
        }
        :
        {
            phone: true,
            phoneRegionCode: isoCountry
        }
    )

    return (
        <FormGroup className={error && validationFired && "form-error"}>
            <Label htmlFor={fieldName} className={`form-label ${labelCustomClass}`}>{label}</Label>
            {required && <span aria-hidden="true" className="required-indicator">*</span>}
            <Cleave
                id={fieldName}
                name={fieldName}
                className="form-control"
                type="tel"
                autoComplete="tel"
                aria-required="true"
                value={number}
                onChange={handleChange}
                onBlur={handleChange}
                options={cleaveOptions}
                maxLength={phone.maxInputLength}
            />
            {validationFired && error}
            {/* 
                // THIS IS WHAT YOU SHOULD PASS TO "ERROR" FROM PARENT:
                {this.validator.message("attributeName", valueToValidate, "required|phone")} 
            */}
        </FormGroup>
    )
}

export default PhoneNumberInput;