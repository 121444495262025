import { COUNTRY_NAMES } from "./components/contexts/LanguageTranslation/CountryConstants";

export const paths = {
    fileClaimConfirmation: '/fileclaim/confirmation'
}


export async function verifyHwaToken(token) {
    const res = await fetch('vendor/VerifyToken/hwa', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            token: token
        })
    });

    if (res.ok) {
        const result = await res.json();
        return result.success;
    }

    return false;
}

export async function verifyAigToken(token) {

    token = token?.replaceAll('?token=')
    const res = await fetch('vendor/verifyJwt?token=' + token, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            token: token
        })
    });

    if (res.ok) {
        const result = await res.json();
        return result.success;
    }

    return false;
}

export async function verifyaigHwaToken(aigToken, agreementId) {
    const res = await fetch(`vendor/verifyAigToken/${aigToken}/${agreementId}`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    });
    if (res.ok) {
        const result = await res.json();
        return result;
    }

    return false;
}

export async function verifyAigJwtToken(token) {

    token = token?.replaceAll('?token=')
    const res = await fetch(`vendor/verifyJwt?token=${token}`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    });
    if (res.ok) {
        const result = await res.json();
        return result;
    }

    return false;
}

export async function getHwaRedirectUrl() {
    const res = await fetch('api/helper/GetHwaRedirectUrl');
    const data = await res.json();
    return data.redirectUrl;
}

export function updatePartnerCase(claimNumber, status, clientId) {
    var route = "vendor/apple/partnercaseupdate/";
    var finalRoute = route.concat(claimNumber).concat('/' + status).concat('/' + clientId);
    fetch(finalRoute, {
        method: 'post',
    }).then(res => {
        if (res.ok) {
            console.log('happy');
        }
    })
}

export function createRepair(clientId, claimNumber) {
    var route = "vendor/apple/repair/";
    var finalRoute = route.concat(clientId).concat('/' + claimNumber);

    return fetch(finalRoute, {
        method: 'post',
    });
}

export function updateRepair(clientId, claimNumber, status) {
    var route = "vendor/apple/repair/update/";
    var finalRoute = route.concat(claimNumber).concat('/' + clientId + '/' + status);
    return fetch(finalRoute, {
        method: 'post',
    });
}

export function cancelClaim(clientId, claimNumber, isOnReview) {
    var route = "api/claim/CancelClaim/";
    var finalRoute = route.concat(clientId).concat('/' + claimNumber).concat('/' + isOnReview);
    fetch(finalRoute, {
        method: 'post',
    }).then(res => {
        if (res.ok) {
            console.log('Cancel Claim - Success');
        }
    })
}

export function getStatusColor(statusName) {
    return statusName;
}

export async function getTUToken(clientId) {
    var route = "vendor/transunion/GetToken/";
    var finalRoute = route.concat(clientId).concat('/');
    return await fetch(finalRoute);
}

export async function getTUSessionId(clientId) {
    var route = "vendor/transunion/GetSessionId/";
    var finalRoute = route.concat(clientId).concat('/');
    return await fetch(finalRoute);
}

export async function addTUCase(token, model) {
    var route = "vendor/transunion/AddCase/";
    var finalRoute = route.concat(token).concat('/');
    return await fetch(finalRoute, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            PartyId: model.PartyId.toString(),
            Addresses: model.Addresses,
            Phone: model.Phone,
            FirstName: model.FirstName,
            LastName: model.LastName,
            EmailAddress: model.EmailAddress,
            ClaimNumber: model.ClaimNumber,
            ClaimId: model.ClaimId,
            Token: token,
            SessionId: model.SessionId,
            ClientId: model.ClientId
        })
    });
}


export async function getTranslation(path, language) {
    var route = "api/dynamicContent/text";
    return await fetch(route, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Path: path ?? '',
            //language is currently not used, may be used in later stories
            Language: language ?? ''
        })
    });
}

export async function isETChatEndRoute() {
    var url = window.location.host.toLowerCase();
    var pathname = window.location.pathname.toLowerCase()
    var route = "api/helper/CheckChatEndUrlClientId/";
    if (pathname == "/chat" || pathname == "/chatend") {
        const finalRoute = route.concat(url).concat(pathname + '/');
        const res = await fetch(finalRoute);
        const response = await res.json();
        return response;

    }
    else {
        return false;
    }
}

export async function getFormatting(path, country) {
    var route = "api/dynamicContent/format";
    return await fetch(route, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Path: path,
            //country is currently not used, may be used in later stories
            Country: country
        })
    });
}

export const translateIncidentType = (type, language) => {
    let typeLower = type.toLowerCase();
    switch (language) {
        case "de": if (typeLower === 'theft') return 'Diebstahl'
            if (typeLower === 'loss') return 'Verlust'
        case "es": if (typeLower === 'theft') return 'Robo'
            if (typeLower === 'loss') return 'P\u00E9rdida'
        case "fr": if (typeLower === 'theft') return 'Vol'
            if (typeLower === 'loss') return 'Perte'
        case "it": if (typeLower === 'theft') return 'Furto'
            if (typeLower === 'loss') return 'Smarrimento'
        case "vn": if (typeLower === 'theft') return 'Trộm cắp'
            if (typeLower === 'loss') return 'Mất mát'
        case "cl": if (typeLower === 'theft') return 'Robo'
            if (typeLower === 'loss') return 'Pérdida'
        case "nl": if (typeLower === 'theft') return 'Diefstal'
            if (typeLower === 'loss') return 'Verlies'
        case "no": if (typeLower === 'theft') return 'Tyveri'
            if (typeLower === 'loss') return 'Tap'
        case "sv": if (typeLower === 'theft') return 'Stöld'
            if (typeLower === 'loss') return 'Förlust'
        case "da": if (typeLower === 'theft') return 'Tyveri'
            if (typeLower === 'loss') return 'Tab'
        case "fi": if (typeLower === 'theft') return 'Varkaus'
            if (typeLower === 'loss') return 'Katoaminen'
        case "sg": if (typeLower === 'theft') return 'Diebstahl'
            if (typeLower === 'loss') return 'Verlust'
        case "at": if (typeLower === 'theft') return 'Diebstahl'
            if (typeLower === 'loss') return 'Verlust'
        default:
            return type
    }
}

export async function getTUDetailedDecision(token, caseId, clientId, claimId) {
    var route = "vendor/transunion/GetDecision/";
    var finalRoute = route.concat(token).concat('/' + caseId + '/' + claimId + '/' + clientId);
    return await fetch(finalRoute);
}

export function sendEmail(clientId, emailType, customerName, phone, caseNumber, model, caseDate, url, address, claimNumber, claimSetupDate, serialNumber, modelNumber, claimId,
    servicer, servicerPhone, appointmentDate, appointmentWindow, clientContractNumber, fee, assetName, cancelDate = '', contractNumber, servicerType, warrantyObjectTypeId, eventId) {
    // console.log(clientId, customerName, phone, caseNumber, model, caseDate, url, address, claimNumber, claimSetupDate, serialNumber,
    //     servicer, servicerPhone, appointmentDate, appointmentWindow, clientContractNumber, fee, assetName);
    // Send Approved Mail
    return fetch('api/communication/'.concat(clientId), {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            emailModel: {

                Recipients: [address],
                Cc: [],
                Bcc: [],
                IsBodyHtml: true,
                emailType: emailType // Int
            },
            serviceModel: {
                ClaimNumber: claimNumber,
                ConsumerName: customerName,
                CustomerName: customerName,
                SubscriberName: customerName, // First Name + Last Name
                MiniSiteURL: url, // '<a href="https://www.aigtheftandloss.com">www.aigtheftandloss.com</a>'
                WirelessNumber: phone, //this.props.party.partyPhoneModel[0].number
                DeviceMakeandModel: clientId == 'et' ? assetName : model,//this.props.contract.contractAssetModel[0].modelNumber
                CaseNumber: caseNumber,//this.props.clientClaimNumber
                CaseNumberDate: caseDate, //moment(this.props.claimIncidentDate).format("MM/DD/YYYY")
                ClaimSetupDate: claimSetupDate,
                SerialNumber: serialNumber,
                ClaimId: claimId,
                ClientContractNumber: clientContractNumber,
                Fee: fee,
                Servicer: servicer,
                ServicerPhone: servicerPhone,
                AppointmentDate: appointmentDate,
                AppointmentWindow: appointmentWindow,
                AssetName: assetName,
                FirstName: customerName,
                CancelDate: cancelDate,
                ContractNumber: contractNumber,
                ModelNumber: modelNumber,
                ServicerType: servicerType,
                WarrantyObjectTypeId: warrantyObjectTypeId,
                EventId: eventId
            }

        })
    }).then(function (res) {
        let statusCode = res.status;
        return res.json();
    })
}

export async function addTUStatus(claimNumber, token, caseId, status, clientId) {
    var route = "vendor/transunion/AddStatus/";
    var finalRoute = route.concat(clientId);

    return await fetch(finalRoute, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            Token: token,
            CaseId: caseId,
            ClaimNumber: claimNumber,
            AddStatusRequest: {
                Comment: "",
                Id: caseId,
                Status: status
            }
        })
    });
}

export async function getTUCaseId(claimNumber, clientId) {
    var route = "vendor/transunion/GetCaseId/";
    var finalRoute = route.concat(claimNumber).concat('/' + clientId);
    return await fetch(finalRoute);
}

export async function createNewAuthorization(claimId, billingCode, status, clientId, quantity = 1, createdBy = "CustomerPortal", adjuster = "") {
    var route = `api/authorization/${clientId}`;
    return await fetch(route, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ClaimId: claimId,
            BillingCode: billingCode,
            AuthorizedQuantity: quantity,
            AuthorizationStatus: status,
            Adjuster: adjuster,
            CreatedBy: createdBy
        })
    });
}

export async function updateClaimConfiguration(claimId, attributeId, value, clientId) {
    var route = "api/claim/UpdateClaimConfigurationAttributeValue/";
    var finalRoute = route.concat(clientId);
    return await fetch(finalRoute, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            claimId: claimId,
            claimAttributeId: attributeId,
            Value: value
        })
    });
}

export function updateSymptomClaim(clientId, claimId, symptom) {
    var route = "api/claim/updatesymptom/";
    var finalRoute = route.concat(claimId).concat('/' + clientId + '/' + symptom);
    return fetch(finalRoute, {
        method: 'put',
    });
}

export function addAnswerCollection(clientId, claimId, questionSetMappingId, answerText, answerId, questionId) {
    var route = "api/troubleshooting/AddAnswerCollection/";
    var finalRoute = route.concat(clientId);
    return fetch(finalRoute, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            warrantyObjectTypeId: 2,
            warrantyObjectValue: claimId,
            questionSetMappingId: questionSetMappingId,
            answerId: answerId,
            alternateAnswerText: answerText,
            questionId: questionId
        })
    });
}

export async function fetchPaymentSession(validationUrl, countryCode) {
    //var route = "vendor/ApplePay/GetPaymentSession/";
    var route = "vendor/ApplePay/PaymentSession/";
    var finalRoute = route.concat("apple");
    let session = 0;
    //let response = await fetch(finalRoute)
    let response = await fetch(finalRoute, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            url: validationUrl,
            countryCode: countryCode
        })
    })
        .then(function (res) {
            session = res.status;
            return res.json();
        }).then(data => {
            if (session === 200) {
                return data;
            }
        });

    return response;
}

export async function processResponse(promise) {
    try {
        let response = await promise;
        let status = response.merchantSessionIdentifier;
    }
    catch (Exception) {
        return 0;
    }

}

export function getDenialReason(rulesetDetailId) {
    // changes to these reasons should also be applied to the denied section in confirmation.jsx
    switch (rulesetDetailId) {
        case 1:
        case 7:
            return "This plan is not active; therefore, we cannot proceed with filing a new claim at this time.";
        case 2:
        case 8:
            return "This product is not eligible for service because coverage has not started. The coverage start date cannot be in the future.";
        case 6:
            return "Incident must be a covered issue. This incident is not eligible for service due to not being covered under your current plan.";
        case 5:
        case 13:
            return "This product is not eligible for service due to the plan’s claim limit already being met.";
        case 3:
        case 9:
            return "This product is not yet eligible for service due to the product currently being covered by the manufacturer’s warranty. The plan’s coverage will begin after the manufacturer’s warranty expires. Please contact the manufacturer to service your product.";

        case 4:
        case 10:
        case 11:
            return "This product is not eligible for service due to the incident date is prior to the contract begin date.";

        case 12:
            return "Incident must be a covered issue. This incident is not eligible for service due to not being covered under your current plan";
        default:
            return null;
    }
}

export const dataLayerPush = (pagePath, pageTitle, data = {}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'Pageview',
        pagePath,
        pageTitle,
        ...data
    });
}

export const removeEmptyObjects = (obj) => {
    for (let propName in obj) {

        if (typeof obj[propName] === 'object') {
            removeEmptyObjects(obj[propName])
        }
        if (!obj[propName]) {
            delete obj[propName];
        }
    }

    for (let propName in obj) {
        if (!Object.keys(obj[propName]).length) {
            delete obj[propName]
        }
    }
    return obj;
}

export const adobeDataLayerPush = (siteData = {}, pageData = {}, caseData = {}, eConsentData = {}) => {
    const dataObject = {
        "site": {
            "name": siteData.name || "Warranty Claims Portal",
            "country": siteData?.country ?? '',
            "language": siteData?.language ?? '',
            "currency": siteData?.currency ?? 'USD'
        },
        "page": {
            "name": pageData?.name ?? '',
            "path": pageData?.path ?? '',
            "url": window.location.origin
        },
        "case": {
            "caseId": caseData?.caseId ?? '',
            "claimType": caseData?.claimType,
            "claimStatus": caseData?.claimStatus
        },
        "econsent": {
            "retailer": eConsentData?.retailer ?? ''
        }
    };
    window.digitalData = removeEmptyObjects(dataObject);
}


export const callAdobe = ({
    country,
    language,
    currency,
    pageName,
    pagePath,
    caseId,
    claimStatus,
    claimType,
    retailer
}) => {

    const siteData = {
        name: 'Warranty Claims Portal',
        country: country || window.digitalData?.site?.country || '',
        language: language || window.digitalData?.site?.language || '',
        currency: currency || window.digitalData?.site?.currency || ''
    }

    const pageData = {
        name: pageName || window.digitalData?.page?.name || '',
        path: pagePath || window.digitalData?.page?.path || '',
        url: window.location.origin
    }

    const caseData = {
        caseId: caseId || window.digitalData?.case?.caseId || '',
        claimType: claimType || window.digitalData?.case?.claimType || '',
        claimStatus: claimStatus || window.digitalData?.case?.claimStatus || '',
    }

    const eConsentData = {
        retailer: retailer || window.digitalData?.econsent?.retailer || ''
    }

    adobeDataLayerPush(siteData, pageData, caseData, eConsentData)
    window['_satellite']?.track('pageview');
}



export function resumeClaim(col, client, propsGlobal) {
    /* TODO */

    // Add spinner, pull claim information and open claim in saved step
    let savedStep = col.savedStep;
    let assetId = col.contractAssetId;
    let claimId = col.claimId;
    let contractId = col.contractId;
    let coverageId = col.coverageId;
    let pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
    let history = { pathname: pathName };
    let setupDate = Date(col.claimSetupDate).toISOString;//prevents errors about translating to nullable datetime
    let completionDate = Date(col.claimFileCompletionDate).toISOString;
    col.claimFileCompletionDate = completionDate;
    col.claimSetupDate = setupDate;
    let entitlementDetails = {
        ammount: null,
        clientId: client,
        claimNumber: col.claimNumber,
        claimId: col.claimId,
        caseNumber: null,
        deductible: col.tcf,
        caseNumber: null,
        symptom: col.symptom,
        contract: col.contract,
        party: col.partyModel,
        address1: col.partyModel.serviceAddress?.address1,
        address2: col.partyModel.serviceAddress?.address2,
        city: col.partyModel.serviceAddress?.city,
        state: col.partyModel.serviceAddress?.state,
        stateId: col.partyModel.serviceAddress?.stateId,
        zip: col.partyModel.serviceAddress?.zip,
        contractAsset: col.contract?.contractAssetModel?.find(ca => ca.contractAssetId == assetId),
        assetId: col.assetId ?? col.contract?.contractAssetModel?.find(ca => ca.contractAssetId == assetId)?.assetId ?? 0,
    }

    switch (savedStep) {
        case "1":
            // Entitlement page, need to figure out which symptom and part of the process
            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
            return history = {
                pathname: pathName,
                claim: col,
                clientId: client,
                stepsCounter: col.stepsCounter,
                state: { entitlementDetails: entitlementDetails, claim: col }
            };
        case "3":
            // Payment page
            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
            return history = {
                pathname: pathName,
                claim: col,
                clientId: client,
                stepsCounter: col.stepsCounter,
                isPaymentActive: true,
                // Add entitlement details to state
                state: { entitlementDetails: entitlementDetails, claim: col }
            };
        case "2":
            // Verify info
            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
            return history = {
                pathname: pathName,
                claim: col,
                clientId: client,
                state: { entitlementDetails: entitlementDetails, claim: col }
            };
        case "4":
            // Upload docs, not supported
            pathName = `/fileclaim/entitlement/${assetId}/${claimId}/${contractId}/${coverageId}`;
            return history = {
                pathname: pathName,
                claim: col,
                clientId: client,
                state: { entitlementDetails: entitlementDetails, claim: col }
            };
        case "5":
            // Appointment
            pathName = '/appointment';
            return history = {
                pathname: pathName,
                claim: col,
                clientId: client,
                state: { entitlementDetails: entitlementDetails, claim: col }
            };
        default:
            // send to ClaimOverview
            pathName = '/DetailsOverview';
            if (typeof propsGlobal?.props.history?.search != 'undefined') {
                pathName += `?id=${propsGlobal?.props.history?.search}`;
            }

            return history = {
                pathname: pathName,
                claim: col,
                clientId: client,
                state: { claim: col }
            };
    }


}

export const isAppleRoute = () => {
    const isAIGTheftLoss = window.location.href.toLowerCase().includes("aigtheftandloss");
    const isFileClaimOrEntitlement = window.location.pathname.toLowerCase().includes("fileclaim/entitlement");

    return (
        window.location.pathname.toLowerCase().includes("apple") ||
        window.location.pathname.toLowerCase().includes("file/entitlement") ||
        window.location.pathname.toLowerCase().includes("file/payment") ||
        window.location.pathname.toLowerCase().includes("file/VerifyInfo") ||
        window.location.pathname.toLowerCase().includes("programcoveragedocs") ||
        window.location.pathname.toLowerCase().includes("econsent") ||
        window.location.pathname.toLowerCase().includes("programsummarydisclosure") ||
        !(isAIGTheftLoss && isFileClaimOrEntitlement) || (isAIGTheftLoss && !isFileClaimOrEntitlement)
    )
}

//WIRE UP by DEV Team
export const isETRoute = (clientId) => {
    const clientIdLower = clientId?.toLowerCase()
    if (clientIdLower == 'et') {
        return true
    }
    return false
}

export async function isHWAChatEndRoute() {
    var url = window.location.host.toLowerCase();
    var pathname = window.location.pathname.toLowerCase()
    var route = "api/helper/CheckChatEndUrlClientId/";
    if (pathname == "/chat" || pathname == "/chatend") {
        const finalRoute = route.concat(url).concat(pathname + '/');
        const res = await fetch(finalRoute);
        const response = await res.json();
        return response;

    }
    else {
        return false;
    }
}

export function createClaimHistoryItem(warrantyObjectId, clientId, eventName, historyNotes) {
    var route = "api/history/CreateClaimHistoryItem/";
    var finalRoute = route.concat(clientId).concat('/' + warrantyObjectId + '/' + eventName + '/' + historyNotes);

    return fetch(finalRoute, {
        method: 'post',
    });
}


export async function getCountryByUrl() {
    const res = await fetch('api/helper/GetCountryByUrl');
    const data = await res.json();
    return data;
}

export const getEnvironment = async () => {
    const apiCall = await fetch("api/helper/getenvironment")
    const data = await apiCall.json()
    return data
}

export const getApplePayDetails = async (countyCode) => {
    const response = await fetch("vendor/ApplePay/Details/".concat(countyCode ?? "us"));
    return response;
}

export async function createOrUpdateClaimConfiguration(claimId, attributeId, value, clientId) {
    var route = "api/claim/CreateOrUpdateClaimConfiguration/";
    var finalRoute = route.concat(clientId);
    return await fetch(finalRoute, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            claimId: claimId,
            claimAttributeId: attributeId,
            Value: value
        })
    });
}

export const countryNameFromCode = (country, language) => COUNTRY_NAMES[country?.toLowerCase()]

export const getCoverageByCoverageId = async (coverageId, clientId) => {
    return new Promise((resolve, reject) => {
        fetch(`api/contract/GetCoverageByCoverageId/${coverageId}/${clientId}`)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch(() => {
                // console.log('Error fetching coverage')
                reject(null);
            });
    });
}

export const pushHistory = (currentState, path, pertinentData, history, customPath = '') => {
    // we need one single place for appending history and providing the payload
    // to prevent missing information during transition to another page.
    let target = path.toLowerCase();
    const payload = getHistoryPayload(currentState, target, pertinentData);

    target = customPath !== '' ? customPath : target;
    history.push(target, payload);
}

export const getHistoryPayload = (currentState, target, pertinentData) => {
    // place all history payloads here, only static or function specific data should be in pertinentData parameter,
    // all other else, get it from state, props or context maybe.
    const {
        clientId,
        claimNumber,
        claimId,
        contractAssetId,
        coverageId
    } = currentState;

    const { ...rest } = pertinentData;

    // default payload
    let payload = {
        clientId,
        claimNumber,
        claimId,
        contractAssetId,
        coverageId
    };

    if (target === paths.fileClaimConfirmation) {
        payload = {
            clientId,
            claimNumber,
            claimId,
            contractAssetId,
            coverageId
        };
    }

    Object.assign(payload, rest);

    return payload;
}