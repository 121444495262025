import React from 'react';
import {
    Modal, ModalBody
} from 'reactstrap';
import PageLoading from '../shared/PageLoading/PageLoading';
import useTranslation from '../contexts/LanguageTranslation/useTranslation';

const ProcessingModal = ({ isProcessing }) => {
    const international = useTranslation('components/modals/processingmodal')
    const { translations } = international

    if (!international.isTranslationResolved) return null
    
    return (
        <Modal isOpen={isProcessing} className="md" >
            <ModalBody>
                <PageLoading
                    title={translations.title || "Processing, please wait..."}
                    text={translations.text || "We are processing your payment. Do not refresh or close your browser."}
                    height={300}
                    color={"secondary"}
                />
            </ModalBody>
        </Modal>
    )
}
export default ProcessingModal;