import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import styles from './Toggle.module.css'

const ToggleSwitch = ({checked, id, labelText, onCheck}) => {
    PropTypes.checkPropTypes(ToggleSwitch.propTypes, {checked, id, labelText, onCheck}, 'props', 'ToggleSwitch')
    return (
        <FormGroup className={styles.formGroupWrapper}>
            <label className={styles.formLabel} htmlFor="commPreference">{labelText}</label>
            <div id="commPreference" className={styles.switchWrapper}>                
                <label data-label={labelText} className={styles.switch} htmlFor={id}>
                    <input 
                        type="checkbox" 
                        id={id} 
                        name={id} 
                        checked={checked}
                        onChange={onCheck}
                    />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
            </div>
        </FormGroup>
    );
}

ToggleSwitch.propTypes = {
    checked: PropTypes.bool,
    id: PropTypes.string,
    labelText: PropTypes.string,
    onCheck: PropTypes.func
}

export default ToggleSwitch;