export const VerificationResponseCodes =
{
    error: 0,
    addressVerified: 1,
    addressChanged: 2,
    invalidAddress: 3,
    thirdpartyerror: 4
}

export function verifyAddress(address, clientId, onResult) {
    fetch(`api/addressverification/verify/${clientId}`,
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(address)
            })
        .then(result => {
                if (result.status === 200) {
                    result.json().then(verificationResponse => {
                        if (verificationResponse.successful) {
                            onResult({
                                code: verificationResponse.addressChanged
                                    ? VerificationResponseCodes.addressChanged
                                    : VerificationResponseCodes.addressVerified,
                                returnedAddress: verificationResponse.returnedAddress
                            });
                        } else {
                            if (verificationResponse.errorDetails.length > 0) {
                                onResult({
                                    code: VerificationResponseCodes.invalidAddress
                                });
                            } else {
                                onResult({
                                    code: VerificationResponseCodes.thirdpartyerror,
                                    errors: verificationResponse.messages
                                });
                            }
                        }
                    });

                } else {
                    onResult({
                        code: VerificationResponseCodes.error,
                        errors: result.messages
                    });
                }
            }
        );
}

export function getLocationFromZip(zip, clientId, onResult) {
    fetch(`api/addressverification/zip/${zip}/${clientId}`)
        .then(res => res.json())
        .then(data => {
            onResult(data);
        });
}