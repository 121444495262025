import { Row, Col, Container, Alert } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import styles from './PlanOverviewScreen.module.css';
import { ContractContext } from '../../components/contexts/ContractContext';
import OverviewTabber from '../../components/PlanOverview/PlanOverviewTabber';
import getClientContent from '../../components/_BrandingProvider/brandContent.jsx'
import { getMetaDataByClient } from '../../App';
import { dataLayerPush, getHwaRedirectUrl } from '../../helpers';
import { NotificationsBox } from '../../components/PlanOverview/NotificationsBox';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { useHistory, useLocation } from 'react-router';
import { parse } from 'query-string';
import { verifyAigJwtToken } from '../../../src/helpers';
import classnames from 'classnames';
import DocumentTitle from 'react-document-title';

export function PlanOverview(props) {
    const context = useContext(ContractContext);
    const timezoneArea = momenttz.tz.guess();
    const location = useLocation();
    const history = useHistory();
    const [token, setToken] = useState(parse(location.search)?.id || parse(location.search)?.token)

    const contract = context.data?.contract
    const contracts = context.data?.contracts
    const party = location?.state?.accountDetailsUpdated ? location?.state?.updatedPartyName : context.data?.party
    const [claim, setClaimsData] = useState([]);
    const [type, setType] = useState();
    const [apptTime, setApptTime] = useState();
    const [statusId, setStatusId] = useState();
    const [savedStep, setSavedStep] = useState();
    const [accountDetailsUpdated, setAccountDetailsUpdated] = useState(location?.state?.accountDetailsUpdated || false);
    const contractNumber = context?.data?.contract?.contractNumber;
    const clientId = context?.data?.contract?.clientId || context.data.clientId
    const eventStatusesNotAllowed = [2, 5, 7, 8, 9, 10, 11]

    const clientTextValues = getClientContent(clientId);
    console.log(clientTextValues);
    const title = clientTextValues.variableText.pageTitle;
    const programName = getClientContent(clientId)?.variableText?.programName;
    const pageTitle = title ? `Plan Overview | ${title}` : 'Plan Overview';

    useEffect(() => {
        if (!context.data) { // should never be false
            (async () => {
                if (!!token) {
                    // if context is null but there is a token available, validate token

                    const response = await verifyAigJwtToken(token);
                    if (response.tokenModel != null && response.contracts != null) {
                        let contract = response.contracts;
                        context.updateContract(contract[0]);
                        context.updateParty(contract[0]?.party);
                        context.updateContracts(contract);

                        history.push({
                            pathname: '/planoverview',
                            search: `?token=${token}`,
                            state: {
                                view: "file",
                                activeOverviewTab: "fileClaim"
                            }
                        });
                    }
                    else {
                        var url = response.redirect;
                        if (url.startsWith('http://') || url.startsWith('https://')) {
                            window.location.assign(url)
                        } else {
                            history.push({ pathname: response.redirect })
                        }
                    }
                }
            })();
        }
    }, [context.data])

    useEffect(() => {
        if (!!contract) {
            const pageTitle = getMetaDataByClient(contract?.clientId, "Plan Overview").title
            document.title = pageTitle
            window.scrollTo(0, 0)
            dataLayerPush('/planoverview', "Plan Overview");

            const getClaimsbyContractNumber = async () => {
                await fetch(`api/claim/GetClaimsByContractNumber/${contractNumber}/${clientId}`, {
                    method: 'get'
                }).then(res => {
                    if (res.ok) {
                        res.json().then(claimsResult => {
                            let mostRecentDate = new Date(Math.max.apply(null, claimsResult?.map(e => {
                                return new Date(e.claimSetupDate);
                            })));
                            let claimsdata = claimsResult?.filter(a => moment(a.claimSetupDate).format('DD/MM/YYYY hh:mm A') === moment(mostRecentDate).format('DD/MM/YYYY hh:mm A')
                                && !eventStatusesNotAllowed.includes(a.eventStatusId))[0];
                            setClaimsData(claimsdata);

                            if (claimsdata?.savedStep) {

                            }
                            switch (claimsdata?.savedStep) {
                                case "2": {
                                    setSavedStep(3);
                                    break;
                                }
                                case "3": {
                                    setSavedStep(2);
                                    break;
                                }
                                case "5": {
                                    setSavedStep(4);
                                    break;
                                }
                                default: {
                                    setSavedStep(claimsdata?.savedStep);
                                    break;
                                }
                            }
                            if (typeof claimsdata !== "undefined" && claimsdata?.appointmentDate !== null) {
                                setType("upcoming");
                                setStatusId(13);
                                setApptTime(moment(claimsdata?.appointmentDate).format('dddd') + ", " + moment(claimsdata?.appointmentDate).format('LL') + " between " + getAppointentTime(claimsdata?.appointmentTime) + ' ' + momenttz.tz(timezoneArea).format('z'));
                            }
                            else if (typeof claimsdata !== "undefined" && claimsdata?.claimStatusName === "Saved") {
                                setType("saved");
                                setStatusId(12)
                            }
                            else if (typeof claimsdata !== "undefined" && claimsdata?.eventType === "appointment" || claimsdata?.eventType === "replacement" || claimsdata?.eventType === "reimbursement"
                                || claimsdata?.eventType === "redispatch" || claimsdata?.eventType === "wow" || claimsdata?.eventType === "buyout" || claimsdata?.eventType === "hireservicer") {
                                setType("inProgress");
                                setStatusId(14)
                            }
                            else {
                                setType("noNotifications");
                                setStatusId("");
                            }

                        });
                    }

                });
            }

            getClaimsbyContractNumber();
        }
    }, [contract])

    useEffect(() => {
        fetch(`api/PlanSearch/contract/${contract?.clientId}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                contractNumber: contract?.clientContractNumber,
                lastName: party?.lastName,
                isSuperUser: true
            })
        })
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    res.json()
                        .then(contract => {
                            console.log(contract)
                            context.updateContracts(contract);  // the inital plan overview page loaded with the context records we just added the logic what we did in plan search submission
                            context.updateParty(contract[0]?.party);
                        })
                }

            })
    }, [])

    useEffect(() => {
        if (accountDetailsUpdated) {
            setTimeout(() => {
                setAccountDetailsUpdated(false);

            }, 5000)
        }
    }, [accountDetailsUpdated])

    const openCustomerPortal = (contract) => {
        //window.location.href = `${contract?.originUrl}service-agreement`;
        //window.location.href = '/planoverview/contractsoverview';
        console.log(context?.data?.contracts);
        history.push({
            pathname: '/planoverview/contractsoverview',
            state: {
                contracts: context?.data?.contracts
            }
        });
    }

    const navigateToAccountDetails = () => {
        const activeTabView = location?.state?.activeOverviewTab || "fileClaim";
        const activePlanView = location?.state?.view || "kanban";
        history.push({
            pathname: '/customerDetails',
            search: typeof token == 'undefined' ? null : `?token=${token}`,
            state: {
                view: activePlanView,
                activeOverviewTab: activeTabView
            }
        });
    }

    const getAppointentTime = (time) => {
        var result = '8 AM - 5 PM'

        switch (time) {
            case '08:00:00':
                result = '8 AM - Noon';
                break;
            case '12:00:00':
                result = 'Noon - 5 PM';
                break;
            default:
                result = '8 AM - 5 PM';
        }

        return result;
    }



    if (!contract) return null

    const {
        clientId: contractClient,
        statusModel,
        clientContractNumber
    } = contract
    const partyName = party?.partyName === null ? party?.firstName.concat(' ', party?.lastName) : party?.partyName
    const { claimOverviewHero: heroText } = getClientContent(contractClient ?? clientId)?.variableText

    const clientUpper = clientId.toUpperCase()
    return (
        <DocumentTitle title={pageTitle}>
            <div>
                <section className={styles.hero}>
                    <div className={classnames(styles.heroContainer, styles[clientUpper])}>
                        <Container className={styles.subContainer}>
                            <Row className="flex-v-center w-100">
                                <Col md={5} lg={6}>
                                    <h1 className="h1">{contractClient === 'et' || clientId === 'et' || clientId === 'bestbuy' ? programName : 'Home Warranty Plan'}</h1>
                                    <div>

                                        <p>Name: {partyName}</p>

                                        <p>{`${heroText?.planNumber}: ${clientContractNumber}`}</p>

                                        {/* TODO: needs to opperate off account type, NOT CLIENT ID.*/}
                                        {contractClient === 'et' ? (
                                            <p>Total Plans: {contracts.length}</p>
                                        ) : (
                                            <p>{`${heroText?.planStatus}: ${statusModel?.statusName}`}</p>
                                        )}

                                    </div>
                                    {/*Comment for deployment and uncomment after*/}
                                    {contractClient === 'hwa' ? (
                                        <button className="btn btn-secondary mt-3" onClick={() => { openCustomerPortal(contract) }}>
                                            View All Policies
                                        </button>
                                    ) : (
                                        <button className={`btn btn-secondary mt-3 ${styles.accountDetails}`} onClick={() => navigateToAccountDetails()}>
                                            Account Details
                                        </button>
                                    )}
                                </Col>
                                <Col md={7} lg={6}>
                                    {/* <NotificationsBox type="inProgress" completedSteps="2" steps="3" assetType="Washer" claimNumber="12345678" statusId={10}/> */}
                                    {/*{typeof type !== "undefined" && <NotificationsBox claimData={claim} type={type} apptTime={apptTime} assetType={claim?.assetName} claimNumber={claim?.claimNumber} statusId={statusId} client={clientId} completedSteps={savedStep} steps="4" contract={contract} />}*/}
                                    {/* <NotificationsBox claimData={claim} type="noNotifications"/>  */}
                                    <Alert isOpen={accountDetailsUpdated} className={styles.accountUpdateAlert}>
                                        Account information is successfully updated.
                                    </Alert>
                                    {typeof type !== "undefined" &&
                                        <NotificationsBox
                                            claimData={claim}
                                            type={type}
                                            apptTime={apptTime}
                                            assetType={claim?.assetName}
                                            claimNumber={claim?.claimNumber}
                                            statusId={statusId}
                                            client={clientId}
                                            completedSteps={savedStep}
                                            steps="4"
                                            contract={contract}
                                        />}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
                <section>
                    <OverviewTabber props={props} />
                </section>
            </div>
        </DocumentTitle>
    )
}