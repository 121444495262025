import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from '../../components/contexts/LanguageTranslation/useTranslation'

const AppleIntlReplacementModal = ({
    isOpen,
    onConfirm,
    onClose,
    nextCountry
}) => {
    // code/logic/vars here
    const international = useTranslation('components/modals/InternationalReplacement');
    const { country, language, translations } = international;

    return (
        <Modal
            isOpen={isOpen}
            className="md"
            aria-modal="true"
            role="dialog"
            aria-labelledBy="invalid"
        // toggle={onClose}   // UNCOMMENT to allow modal to close on outside click (no action taken)
        >
            <ModalHeader className="noLine" />

            <ModalBody>
                <h2>
                    {translations.title || "International Replacements"}
                </h2>
                <p>
                    {translations.text1 || "Please be advised, once your claim is approved, delivery can take up to 4 days to arrive. If you are not at the address provided when it arrives, the fulfillment process may be delayed 7 or more days."}
                </p>
                <p>
                    {translations.text2 || "If you choose to have your replacement device shipped outside the country where you purchased your plan, the color of your replacement device may not match the original."}
                </p>
            </ModalBody>

            <ModalFooter>
                <button className="btn button-link" onClick={onClose}>
                    {translations.noThanks || "No Thanks"}
                </button>
                <button className='btn btn-primary' onClick={() => onConfirm(nextCountry)}>
                    {translations.continue || "I Understand and Agree"}
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default AppleIntlReplacementModal;