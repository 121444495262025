export const troubleShootingMapping = [
    {
        questionSymptomMappingId: 0,
        symptoms: ["will not power on"],
        troubleShooting: [
            {
                id: 1,
                primaryBullet: "Confirm the product is plugged in and turned on.",
                secondaryBullet: ""
            },
            {
                id: 2,
                primaryBullet: "If the product is controlled by a light switch, ensure switch is ON.",
                secondaryBullet: ""
            },
            {
                id: 3,
                primaryBullet: "Reset/Flip circuit breaker to ensure it is functioning properly.",
                secondaryBullet: ""
            },
            {
                id: 4,
                primaryBullet: "Confirm batteries have been replaced (if applicable).",
                secondaryBullet: ""
            }
        ]
    },
    {
        questionSymptomMappingId: 1,
        symptoms: ["Charger not connecting to Wi-Fi"],
        troubleShooting: [
            {
                id: 1,
                primaryBullet: "Check for Internet Access",
                secondaryBullet: "Check your WiFi and Internet connection near your charger. If your Internet Service Provider (ISP) connection is down, your charger won’t be able to connect to the Internet, even if your WiFi network is up and running. To make sure your ISP is working, try connecting to the Internet from a computer, tablet or phone while it is connected to your home WiFi network. If you can connect to the Internet via WiFi with another device, the problem is not with your ISP."
            },
            {
                id: 2,
                primaryBullet: "Restart Router",
                secondaryBullet: "Most of the time, problems with your WiFi access point or router can be resolved with a restart. The best way to restart your router is to unplug it from the wall, wait a few seconds, and plug it back in. Refer to your access point’s manual for more detailed reset instructions."
            },
            {
                id: 3,
                primaryBullet: "Check Cable Connections",
                secondaryBullet: "Check all your cables and connections. If you still can’t connect to your network or get online, make sure your router is connected to the modem and turned on."
            },
            {
                id: 4,
                primaryBullet: "Connect to a different WiFi Network",
                secondaryBullet: "If there isn’t a problem with your home network, it could be that you’ve recently changed the name or password of the WiFi network in your home or there’s been a change to your WiFi access point. In this case, you’ll need to go the mobile app and select Reconfigure WiFi from the home charger settings menu."
            }
        ]
    },
    {
        questionSymptomMappingId: 2,
        symptoms: ["Car not charging"],
        troubleShooting: [
            {
                id: 1,
                primaryBullet: "Check to see if the cable is fully connected at both ends.",
                secondaryBullet: "Please try unplugging both ends of the cable before re-inserting them firmly to ensure a full connection is made."
            },
            {
                id: 2,
                primaryBullet: "Check to see if a scheduled charge or delay time has been set.",
                secondaryBullet: "Charging may not occur if a schedule or delay time has been set."
            },
            {
                id: 3,
                primaryBullet: 'Check to see if "Smart Charge" has been activated.',
                secondaryBullet: 'Please go to the app to see if "Smart Charging" is activated. If activated, charging will be scheduled for later. If you need to start charging, deactivate the "smart charging" from the settings page and select save.'
            }
        ]
    },
    {
        questionSymptomMappingId: 3,
        symptoms: ["Receiving error: Unable to Connect to Home Charger or Cant find home charger"],
        troubleShooting: [
            {
                id: 1,
                primaryBullet: "Make sure you are standing near the charging station and that Bluetooth is turned on. The phone communicates with your home charger using Bluetooth, so it’s best to be standing within a few feet of the station when activating it.",
                secondaryBullet: ""
            }
        ]
    },
    {
        questionSymptomMappingId: 4,
        symptoms: ["Receiving Error: Unable to find WiFi network"],
        troubleShooting: [
            {
                id: 1,
                primaryBullet: "Make sure you are standing near the station and that you have WiFi signal on your phone",
                secondaryBullet: ""
            }
        ]
    }

];