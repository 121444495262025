import React from 'react';
import { ReactComponent as MicrowaveIcon } from '../../../assets/images/microwave.svg';
import { ReactComponent as DishwasherIcon } from '../../../assets/images/dishwasher.svg';
import { ReactComponent as FreezerIcon } from '../../../assets/images/freezer.svg';
import { ReactComponent as DisposalIcon } from '../../../assets/images/garbage-disposal.svg';
import { ReactComponent as ExhaustIcon } from '../../../assets/images/exhaust-fan.svg';
import { ReactComponent as RefrigeratorIcon } from '../../../assets/images/refrigerator.svg';
import { ReactComponent as ExternalWaterLine } from '../../../assets/images/external-water.svg';
import { ReactComponent as GarageOpener } from '../../../assets/images/garage-opener.svg';
import { ReactComponent as CeilingFan } from '../../../assets/images/ceiling-fan.svg';
import { ReactComponent as Humidifier } from '../../../assets/images/humidifier.svg';
import { ReactComponent as WaterHeater } from '../../../assets/images/water-heater.svg';
import { ReactComponent as HeatingSystem } from '../../../assets/images/heating-system.svg';
import { ReactComponent as DuctWorkIcon } from '../../../assets/images/ductwork.svg';
import { ReactComponent as PlumbingStoppage } from '../../../assets/images/plumbing-stoppage.svg';
import { ReactComponent as PlumbingSystem } from '../../../assets/images/plumbing-system.svg';
import { ReactComponent as RangeIcon } from '../../../assets/images/range.svg';
import { ReactComponent as ElectricalSystem } from '../../../assets/images/electrical-system.svg';
import { ReactComponent as SpaIcon } from '../../../assets/images/hot-tub.svg';
import { ReactComponent as SwimIcon } from '../../../assets/images/swim.svg';
import { ReactComponent as TermiteTreatment } from '../../../assets/images/termite.svg';
import { ReactComponent as PlumbingIcon } from '../../../assets/images/plumbing.svg';
import { ReactComponent as WellPump } from '../../../assets/images/well-pump.svg';
import { ReactComponent as DryerIcon } from '../../../assets/images/dryer.svg';
import { ReactComponent as WasherIcon } from '../../../assets/images/washer.svg';
import { ReactComponent as DoorBell } from '../../../assets/images/doorbell.svg';
import { ReactComponent as Telephone } from '../../../assets/images/telephone.svg';
import { ReactComponent as AlarmIcon } from '../../../assets/images/alarm.svg';
import { ReactComponent as CentralVacuum } from '../../../assets/images/vacuum.svg';
import { ReactComponent as HWDispenser } from '../../../assets/images/hw-dispenser.svg';
import { ReactComponent as TrashCompactor } from '../../../assets/images/trash-compactor.svg';
import { ReactComponent as FoodCenter } from '../../../assets/images/food-center.svg';
import { ReactComponent as SepticSystem } from '../../../assets/images/sewer.svg';
import { ReactComponent as RoofIcon } from '../../../assets/images/roof.svg';
import { ReactComponent as ACSystemIcon } from '../../../assets/images/air-conditioner.svg';
import { ReactComponent as KeyEntry } from '../../../assets/images/key-entry.svg';
import { ReactComponent as PestControl } from '../../../assets/images/pest-control.svg';
import { ReactComponent as Laundry } from '../../../assets/images/laundry.svg';
import { ReactComponent as SewagePump } from '../../../assets/images/sewage-pump.svg';
import { ReactComponent as SurgeProtectionIcon } from '../../../assets/images/Vendor/ET/electric-surge.svg';
import { ReactComponent as VehicleChargerIcon } from '../../../assets/images/Vendor/ET/vehicle-charger.svg';


const AssetIcon = ({assetName, className}) => {
    if (!assetName) return null
    return (
        <>
            {assetName.toLowerCase() === "dishwasher" && <DishwasherIcon className={className} />}
            {assetName.toLowerCase() === "refrigerator" && <RefrigeratorIcon className={className} />}
            {assetName.toLowerCase() === "freezer" && <FreezerIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "externalwaterline" && <ExternalWaterLine className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "garagedooropener" && <GarageOpener className={className} />}
            {assetName.toLowerCase() === "ceiling fan" && <CeilingFan className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "humidifier" && <Humidifier className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "microwaveovenbuiltin" && <MicrowaveIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "kitchenexhaustfan" && <ExhaustIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "ceiling/exhaustfans" && <CeilingFan className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "waterheater" && <WaterHeater className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "garbagedisposal" && <DisposalIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "centralhomeheatingsyst" && <HeatingSystem className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "interiorelectricalsyst" && <ElectricalSystem className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "heatingductwork" && <DuctWorkIcon className={className} />}
            {assetName.replace(/[- )(]/g, '').toLowerCase() === "plbstoppagesfxtnotinc" && <PlumbingStoppage className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "plumbingsystem/stoppages" && <PlumbingStoppage className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "plbsysfixturesnotinc" && <PlumbingSystem className={className} />}
            {assetName.replace(/\\|\//g,'').toLowerCase() === "rangeovenstove" && <RangeIcon className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "addonspaonly" && <SpaIcon className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "addonswimmingpoolonly" && <SwimIcon className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "addonswimmingpool&spa" && <SwimIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase()=== "internalplumbing" && <PlumbingIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase()=== "refrigeratorincicemaker" && <RefrigeratorIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase()=== "wellpump" && <WellPump className={className} />}
            {assetName.toLowerCase() === "dryer" && <DryerIcon className={className} />}
            {assetName.toLowerCase() === "washer" && <WasherIcon className={className} />}
            {assetName.toLowerCase() === "doorbells" && <DoorBell className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "telephoneline" && <Telephone className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "burglar/firealarmsystems" && <AlarmIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase()=== "centralvacuumsystem" && <CentralVacuum className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "hotwaterdispenser" && <HWDispenser className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "trashcompactorbuiltin" && <TrashCompactor className={className} />}
            {assetName.replace(/[- )(]/g,'').toLowerCase() === "builtinfoodcenter" && <FoodCenter className={className} />}
            {assetName.replace(/\\|\//g,'').toLowerCase() === "dexternalwatersewerseptic" && <SepticSystem className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase()=== "roofleakrepair" && <RoofIcon className={className} />}
            {assetName.replace(/\s|\//g,'').toLowerCase()=== "cntrlachpsyselconly" && <ACSystemIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "re-key" && <KeyEntry className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "pestcontrol" && <PestControl className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "subterraneantermitetreatment" && <TermiteTreatment className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "septicsystem" && <SepticSystem className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "externalsewerline" && <SepticSystem className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "washeranddryerpair" && <Laundry className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "sewageejectorpump" && <SewagePump className={className} />}
            {assetName.replace(/\s/g,'').toLowerCase()=== "a/csystem" && <ACSystemIcon className={className} />}
            {assetName.replace(/\s/g,'').toLowerCase()=== "surgeprotection" && <SurgeProtectionIcon className={className} />}
            {assetName.replace(/\s/g,'').toLowerCase() === "surge" && <SurgeProtectionIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "electricvehiclecharger" && <VehicleChargerIcon className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "exteriorelectricline" && <ElectricalSystem className={className} />}
            {assetName.replace(/\s/g, '').toLowerCase() === "electricalsystems" && <ElectricalSystem className={className} />}

        </>
    );
}

export default AssetIcon;