import React from 'react';
import {
    Container, Col, Row, Card, CardHeader, CardBody, Label, Input, Form, CardTitle, FormGroup
} from 'reactstrap';
import styles from '../FileClaim/Payment.module.css';
import { CreditCardInput } from '../../components/inputs/CreditCardInput';
import Cleave from 'cleave.js/react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Info } from '../../assets/images/info-icon-blk.svg';
import CreditCardIcons from './PaymentComponents/CreditCardIcons/CreditCardIcons';
import SecurityIcon from '../../assets/images/cc-security-code.svg';



const PaymentInputs = ({ 
    state, 
    validator, 
    handleInputChange, 
    ccTypeChanged 
}) => {

    return (<div>
        <FormGroup className={(state.errors.ccNumber || state.errors.ccInvalid) && state.validationFired ? "form-error inputContainer" : "inputContainer"}>
            <Label for="ccNumber" className="form-label">Credit Card Number</Label>
            <span aria-hidden="true" className="required-indicator">*</span>
            <CreditCardInput
                onCreditCardTypeChanged={ccTypeChanged}
                handleInputChange={handleInputChange}
                arialabelledby="ccNumber" autoComplete="cc-number"
                ariarequired="true" className="form-control"
                name="ccNumber" id="ccNumber"
            />
            <CreditCardIcons ccType={state.ccType}/>

            {state.errors.ccInvalid && <div className="errorMsg" role="alert">The credit card number is invalid.</div>}
            {validator.message("creditCardNumber", state.ccNumber, "required|card_num")}
            {/*<div className="errorMsg">Credit card number is required.</div>
                                                    <div class="errorMsg">The credit card number is invalid.</div>*/}

        </FormGroup>
        <Row className="form-group twoColWrapper">
            <div className="col-6">
                <FormGroup className={(((state.errors.cardExpires || state.errors.ccExpired) && state.validationFired) ? "form-error" : "")}>
                    <Label for="cardExpires" className="form-label">Expiration Date</Label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    {/*<Input aria-labelledby="cardExpires" className="form-control" type="text" placeholder="MM/YY" id="cardExpires" />*/}
                    <Cleave
                        options={{ date: true, datePattern: ['m', 'y'] }}
                        aria-labelledby="cardExpires"
                        type="number"
                        autoComplete="cc-exp"
                        className="form-control"
                        type="text"
                        placeholder="MM/YY"
                        id="cardExpires"
                        name="cardExpires"
                        aria-required="true"
                        onChange={handleInputChange}
                        value={state.cardExpires}
                        onBlur={() => validator.showMessageFor('cardExpires')}
                    />
                    {state.errors.ccExpired && <div className="errorMsg" role="alert">The credit card being used has expired.</div>}
                    {validator.message("expirationDate", state.cardExpires, "required")}
                </FormGroup>
            </div>
            <div className="col-6">
                <FormGroup className={((state.errors.securityCode || state.errors.securityCodeInvalid) && state.validationFired) ? "form-error" : ""}>
                    <Label for="securityCode" className="form-label">Security Code</Label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <div className={styles.inputTooltip}>
                        <span className={styles.hasTooltip}>
                            <Info data-tip data-for='tooltip' aria-label="3 or 4 digits on the back of the card (front of American Express)" role="tooltip" className={styles.infoIcon} />
                            <ReactTooltip
                                id='tooltip'
                                tabIndex="0"
                                clickable="true"
                                aria-haspopup='true'
                                className={styles.tooltiptext}
                                effect="solid"
                                type="light"
                                place={"bottom", "right"}
                                border={true}>
                                <img
                                    className={styles.ccPin}
                                    alt="Back of Card"
                                    src={SecurityIcon}
                                />
                                <br className="show-md-only" aria-hidden="true" />
                                3 or 4 digits on the back of the card (front of American Express)
                            </ReactTooltip>
                        </span>
                        <Cleave
                            options={{ numericOnly: true, blocks: [4] }}
                            autoComplete="cc-csc"
                            aria-labelledby="securityCode"
                            className="form-control"
                            aria-label="Security Code 3 or 4 digits on the back of the card (front of American Express)"
                            id="securityCode"
                            name="securityCode"
                            maxLength={4}
                            aria-required="true"
                            onChange={handleInputChange}
                            value={state.securityCode}
                            onBlur={() => validator.showMessageFor('securityCode')}
                        />
                    </div>
                    {validator.message("securityCode", state.securityCode, "required|cvv")}
                    {state.errors.securityCodeInvalid && <div className="errorMsg" role="alert">The security code is invalid.</div>}
                </FormGroup>
            </div>
        </Row>
    </div>)
}

export default PaymentInputs