import React, { useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import styles from "../PlanOverview/DetailsOverview.module.css";
import { ReactComponent as Chevron } from "../../assets/images/arrow-cheveron-down.svg";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import AssetIcon from '../../components/PlanOverview/Asset/AssetIcon';
import DetailsDisplayBlock from '../../components/shared/DetailsDisplay/DetailsDisplayBlock';
import CreditCardIcon from "../../components/shared/CreditCardIcon/CreditCardIcon";
import { useHistory } from "react-router";
import { dataLayerPush } from '../../helpers'
import getClientContent from '../../components/_BrandingProvider/brandContent.jsx'
import { ContractContext } from "../../components/contexts/ContractContext";
import CustomerReimbursement from "../../components/FileClaim/CustomerReimbursement"


let btmLine = styles.btmLine;
let caseTitle = styles.caseTitle;
let colLg5 = "col-lg-5";
let noGutters = "no-gutters";
let h6 = "h6";
let px3 = "px-3";

const ClaimInformation = (
    props
) => {
    /*
    * SWITCHING DISPLAYS FOR CLAIM DETAILS AND STATUS PROGRESS FOR HWA BASED ON EVENT 
    */
    const history = useHistory()
    const context = useContext(ContractContext)
    const { clientId } = context.data.contract
    const getEventTypeLabel = props.eventType === 'replacement' ? 'Replacement Shipped To' : props.eventType === 'buyout' ? 'Reimbursement Sent To' : null;
    const isReplacementOrBuyout = (props.eventType === 'replacement' || props.eventType === 'buyout') && props?.claim?.eventStatusId != 7;
    const amount = (props?.claim?.authorizedAmount);
    const partyModel = props?.partyInformation;
    const partyInfo = [partyModel?.partyName === null || partyModel?.partyName === "" ? partyModel?.firstName.concat(' ', partyModel?.lastName) : partyModel?.partyName];
    const { variableText } = getClientContent(clientId);
    const claimPhoneNumber = variableText.customerService.phone;
    const tradeFeeLabel = variableText.planDetails.paymentType;
    
    useEffect(() => {
        const { eventType } = props
        switch (eventType) {
            case 'replacement':
                dataLayerPush('/claiminformation/replacement', 'Claim Replacement')
                break;
            case 'buyout':
                dataLayerPush('/claiminformation/buyout', 'Claim Buyout')
                break;
            case 'appointment':
                dataLayerPush('/claiminformation/appointment', 'Claim Appointment')
                break;
            case 'denial':
                dataLayerPush('/claiminformation/denial', 'Claim Denial')
                break;
            default:
                dataLayerPush('/claiminformation', 'Claim Information')
                break;
        }
    }, [])
    const shippingAddress = partyModel?.shippingAddress !== null
        && partyModel?.shippingAddress !== ""
        && typeof partyModel?.shippingAddress !== "undefined"
        ? partyModel?.shippingAddress
        : partyModel?.serviceAddress;

    const backToMyClaims = (props) => {
        props.history.push({
            pathname: '/planoverview',
            state: {
                view: "view",
                activeOverviewTab: 'myClaims'
            }
        });
    }
    const getDenialReason = (props) => {
        let events = props?.claim?.result?.eventModel != null ? props?.claim?.result?.eventModel : props?.claim?.featureClaimModel?.eventModel;
        let deniedEvents = events?.filter(e => e.denialReason !== null)
        if (deniedEvents) {
            let deniedReason = deniedEvents[0]?.denialReason
            return deniedReason
        }
    }
    //console.log(partyInfo, props, shippingAddress)
    if (shippingAddress !== null) {
        let partyStateAndZip = shippingAddress?.city !== null && shippingAddress?.city !== "" ?
            shippingAddress?.city + ", " + shippingAddress?.state + " " + shippingAddress?.zip : shippingAddress?.state?.concat(' ', shippingAddress?.zip);

        if (shippingAddress?.address1 !== null && shippingAddress?.address1 !== "")
            partyInfo.push(shippingAddress?.address1);

        if (typeof partyStateAndZip !== "undefined")
            partyInfo.push(partyStateAndZip);
    }
    if (props.eventType === 'buyout') {
        partyInfo.push("Reimbursement Amount: $" + Number(amount).toFixed(2));
    }
    //console.log(partyInfo, props)
    const hideServiceAddress = props.serviceAppointment.reduce((acc, { name, value }) => {
        if ((name === "Servicer" && !value) || (name === 'Servicer Phone' && !value)) {
            acc.push({ [name]: value })
        }
        return acc;
    }, []).length === 2;

    const showServiceAppointment = (eventType, claim) => {
        return (
          eventType?.toLowerCase() !== "reimbursement" &&
          eventType?.toLowerCase() !== "replacement" &&
          eventType?.toLowerCase() !== "buyout" &&
          eventType?.toLowerCase() !== "hireservicer" &&
          claim?.claimStatusName?.toLowerCase() !== "closed without payment"
        );
    }

    return (
        <>
            <Row>
                <a className={styles.search} onClick={() => { backToMyClaims(props) }}><Chevron className={styles.searchIcon} />
                    View All Claims
                </a>
            </Row>
            <section id="claimDetails" className={styles.overviewSection}>
                {props?.claim?.claimStatusName === 'Closed without payment' && !props.portfolioDealer && (
                    <div>
                        <Row className={classNames(noGutters, btmLine)}>
                            <h1 className="h3 mb-0">This Claim has been Denied</h1>
                        </Row>

                        <Row className={px3}>
                            <p>
                                We're sorry, we are unable to proceed with service at this time. Based on the claim details and your
                                plan coverage, we are unable to approve this claim due to the following reason:
                            </p>
                            {/*WIREUP: DENIAL REASON NEEDED FROM API TEAM. */}
                            <p>
                                {getDenialReason(props)}
                            </p>
                            <p>
                                If you'd like to speak with one of our representatives, call
                                <a href={`tel:${claimPhoneNumber}`} className="callLink"> {claimPhoneNumber}</a>.
                                Please reference your claim number: {props?.claim?.claimNumber}.
                            </p>
                        </Row>
                    </div>
                )}

                {props?.eventType?.toLowerCase() === "reimbursement" && props?.claim?.assetName?.toLowerCase() === "surge protection" && (
                    <CustomerReimbursement 
                        address={shippingAddress}
                        claim={props?.claim}
                    />
                )}

                <Row className={classNames(noGutters, btmLine)}>
                    <h1 className="h3 col-lg-7 mb-0">Details of Your Claim</h1>
                    <h2 className={classNames(colLg5, caseTitle, h6)}>
                        Claim Number: {(props?.portfolioDealer) ? (props?.claim?.clientClaimNumber) : (props?.claim?.claimNumber)}
                    </h2>
                </Row>

                <Row className={styles.claimInfoRow}>
                    {props?.eventType?.toLowerCase() !== "reimbursement" &&
                        <Col md="7" className={styles.claimInfo}>
                            <h3 className={styles.claimDetailsH6}>Products Information</h3>
                            <Row className={styles.deviceInfo}>
                                <AssetIcon className={styles.deviceImg} assetName={props.assetInformation.asset} />
                                <Col lg="12">
                                    {props.productInformation.map(e => {
                                        console.log(e, props)
                                        if (props?.claim?.claimStatusName === 'Closed without payment' && (e.name === 'Product' || ((e.name === 'Model' || e.name === 'Serial' || e.name === 'Purchase Date' || e.name === 'Description') && e.value != null))) {
                                            return (
                                                <Row className="no-gutters">
                                                    <span className="col-10 col-lg-10">
                                                        {e.name}: {e.value}
                                                    </span>
                                                </Row>
                                            )
                                        }

                                        if (props?.claim?.claimStatusName !== 'Closed without payment') {
                                            return (
                                                <Row className="no-gutters">
                                                    {e.value &&
                                                        <span className="col-10 col-lg-10">
                                                            {e.name}: {e.value}
                                                        </span>
                                                    }
                                                </Row>
                                            )
                                        }
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    }

                    <Col md={props?.eventType?.toLowerCase() !== "reimbursement" ? "5" : "6"} className={styles.claimInfo}>
                        <h3 className={styles.claimDetailsH6}>Claim Details</h3>
                        {props.claimDetails.map(e => {
                            if (!props.portfolioDealer || e.name !== 'Symptom') {
                                return (
                                    <Row className="no-gutters">
                                        <span className="col-10 col-lg-10">{e.name}: {e.value}</span>
                                    </Row>
                                )
                            }
                        }
                        )}
                    </Col>

                    {props.portfolioDealer && hideServiceAddress ? null :
                        showServiceAppointment(props?.eventType, props?.claim) ?
                            <Col md="7" className={styles.claimInfo}>

                                <h3 className={styles.claimDetailsH6}>Service Appointment</h3>
                                {
                                    props.serviceAppointment.length > 0 ?
                                        props.serviceAppointment.map(e => {
                                            if (!e.value) {
                                                return e.name === 'Date' && !props.portfolioDealer ? (
                                                    <Row className="no-gutters">
                                                        <span className="col-10 col-lg-10">{e.name}: Contacting soon to schedule</span>
                                                    </Row>
                                                ) : null
                                            }
                                            return !props.portfolioDealer ?
                                                (

                                                    <Row className="no-gutters">
                                                        <span className="col-10 col-lg-10">{e.name}: {e.value}</span>
                                                    </Row>
                                                ) : (e.name != 'Time' && e.name != 'Date') ? (

                                                    <Row className="no-gutters">
                                                        <span className="col-10 col-lg-10">{e.name}: {e.value}</span>
                                                    </Row>
                                                ) : null
                                        }
                                        ) : props?.eventType !== "hireservicer" ? (
                                            // IF NO ITEMS IN SERVICE APPOINTMENT ARRAY
                                            <Row className="no-gutters">
                                                <span className="col-10 col-lg-10">Date: Contacting soon to schedule</span>
                                            </Row>
                                        ) : null
                                }
                            </Col> : ""}

                    {isReplacementOrBuyout &&
                        <Col md="7" className={styles.claimInfo}>
                            <DetailsDisplayBlock
                                // eventType={props.eventType} 
                                title={getEventTypeLabel}
                                address={partyInfo}
                            />
                        </Col>
                    }
               
                    {props.claim?.tcf > 0 && <Col md={props?.eventType?.toLowerCase() !== "reimbursement" ? "5" : "6"} className={styles.claimInfo}>
                        <h3 className={styles.claimDetailsH6}>{tradeFeeLabel}</h3>
                        <ul className="noBullets">
                            <li>Total:&nbsp;
                                <NumberFormat
                                    value={props?.claim?.tcf ?? 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true} />
                            </li>
                            {!props.portfolioDealer && <li>
                                <CreditCardIcon type={props?.claim?.creditCardType?.toLowerCase()} />
                                {` ending with ${props?.claim?.creditCardNumber}`}
                            </li>}
                        </ul>
                    </Col>}
                </Row>
            </section>
        </>
    );

}

export default ClaimInformation
