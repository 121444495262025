import React, { useState, useContext }  from 'react';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import shortAndSweet from 'short-and-sweet';
import { ContractContext } from '../contexts/ContractContext';

let maxLength = styles.maxLength;
let affirm = styles.affirm;

export default function IncidentDescription ({
    // affirmation props
    handleAffirmationCheck,
    affirmationCheck,
    showAffirmationError,
    // description props
    handleIncidentDescriptionChange,
    incidentDescription,
    showDescError,
}) {
    const {data: { clientId }} = useContext(ContractContext);
    
    const affirmationText = clientId === 'hwa' ? 'your claim may be denied' : 'it may be considered fraud'
    return(
        <>
            <div id="incidentDescription">
                <div className="form-group">
                    <label htmlFor="incidentDescInput" className={styles.cardTitle}>
                        Please provide a brief description of the issue.
                    </label>
                    <Row className="stepsFormBlock">
                        <Col lg="6" md="10" className={showDescError ? "form-error" : ''}>
                            <textarea 
                                name="incidentDescription" 
                                type="text" 
                                maxLength="250" 
                                autoComplete="off" 
                                onChange={handleIncidentDescriptionChange} 
                                className="textarea form-control" 
                                id="incidentDescInput" 
                                rows="5"
                                value={incidentDescription} 
                                spellCheck="" 
                                aria-required="true"
                            />
                            <Row>
                                <Col>
                                    {showDescError && 
                                        <p className="errorMsg">
                                            Provide a description to continue.
                                        </p>
                                    }
                                </Col>
                                <Col>
                                    <p className={classNames(styles.maxLength, 'mb-0')}>
                                        {incidentDescription.length}/250
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className={classNames("stepsFormBlock", affirm)}>
                <Col lg="8" md="10">
                    <div className="checkbox scriptBox">
                        <input 
                            className="mr-2" 
                            type="checkbox" 
                            id="affirmStatement" 
                            onChange={handleAffirmationCheck}
                            checked={affirmationCheck}
                        />
                            <label 
                                htmlFor="affirmStatement" 
                                className={`checkboxLabel ${clientId.toLowerCase()}`}
                            >
                                To complete your claim you affirm the information 
                                you've provided regarding your claim is true and 
                                accurate. You understand that if you knowingly and 
                                intentionally provide false or untrue information 
                                regarding your claim, {affirmationText}.
                            </label>
                            {showAffirmationError && (
                                <p style={{marginLeft: '36px'}} className="errorMsg">
                                    Please affirm the information provided is true and accurate.
                                </p>
                            )}
                    </div>
                </Col>
            </Row>
            
        </>
    )
}