import React, { Component, Fragment } from 'react';
import {
    Container, Col, Row, Card, CardHeader, CardBody, Label, Input, Form, CardTitle, FormGroup
} from 'reactstrap';
import styles from './Payment.module.css';
import { ReactComponent as Info } from '../../assets/images/info-icon.svg'
import { ReactComponent as VisaIcon } from '../../assets/images/cc-visa.svg';
import { ReactComponent as AmexIcon } from '../../assets/images/cc-amex.svg';
import { ReactComponent as MasterCard } from '../../assets/images/cc-mastercard.svg';
import { ReactComponent as Discover } from '../../assets/images/cc-discover.svg';
import ReactTooltip from 'react-tooltip';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import classNames from 'classnames';
import StepsHeader from './StepsHeader';
import { CreditCardInput } from '../../components/inputs/CreditCardInput';
import { getLocationFromZip } from '../api/addressverification';
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import { Spinner } from 'react-bootstrap';
import CancelModal from '../../components/modals/CancelModal';
import SaveModal from '../../components/modals/SaveModal';
import NumberFormat from 'react-number-format';
import { updatePartnerCase, createRepair, updateRepair, getTUDetailedDecision, addTUCase, getTUToken, getTUSessionId, addTUStatus, createNewAuthorization, createClaimHistoryItem } from '../../helpers';
import moment from 'moment';
import { billingCodeDisplayIds, authorizationStatus } from '../Global/AdjudicationConstants';
import { ContractContext } from '../contexts/ContractContext';
import PaymentInputs from '../shared/PaymentInputs';
import PaymentBillingInfo from '../shared/PaymentBillingInfo';
import { cancelClaim } from '../Global/helpers';


let amount = styles.amount;
let paymentTotal = styles.paymentTotal;
let sessionId = "";
let btnPrimary = "btn-primary";
let btn = "btn";
let hideBtnTextForSpinner = styles.hideBtnTextForSpinner;
let spinnerPosition = styles.spinnerPosition;
let payOption = styles.payOption;
let ml15 = styles.ml15;

export class Payment extends Component {
    constructor(props) {
        super(props);
        console.log("PROPS-data", this.props);
        this.state = {
            amount: this.props.entitlementDetails.deductible,
            clientId: this.props.entitlementDetails.clientId,
            claimNumber: this.props.entitlementDetails.claimNumber,
            caseNumber: this.props.entitlementDetails.caseNumber,
            firstName: this.props.entitlementDetails.party.firstName,
            lastName: this.props.entitlementDetails.party.lastName,
            ccType: 'unknown',
            ccNumber: "",
            states: null,
            cardExpires: "",
            securityCode: "",
            customAddress: false,
            callNumber: "866-258-7419",
            errors: {
                ccInvalid: false,
                ccExpired: false,
                ccNumber: false,
                cardExpires: false,
                securityCode: false,
                billingAddress: false,
                city: false,
            },
            verifiedAddress: {
                address1: this.props.entitlementDetails.address1,
                address2: this.props.entitlementDetails.address2,
                city: this.props.entitlementDetails.city,
                state: this.props.entitlementDetails.state,
                stateId: this.props.entitlementDetails.stateId,
                zip: this.props.entitlementDetails.zip
            },
            billingAddress: {
                address1: this.props.entitlementDetails.address1,
                address2: this.props.entitlementDetails.address2,
                city: this.props.entitlementDetails.city,
                state: this.props.entitlementDetails.state,
                stateId: this.props.entitlementDetails.stateId,
                zip: this.props.entitlementDetails.zip
            },
            serviceAddress: {
                address1: this.props.entitlementDetails?.party?.serviceAddress.address1,
                address2: this.props.entitlementDetails?.party?.serviceAddress.address2,
                city: this.props.entitlementDetails?.party?.serviceAddress.city,
                state: this.props.entitlementDetails?.party?.serviceAddress.state,
                stateId: this.props.entitlementDetails?.party?.serviceAddress.stateId,
                zip: this.props.entitlementDetails?.party?.serviceAddress.zip
            },
        }
        this.ccTypeChanged = this.ccTypeChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
        this.validator = new SimpleReactValidator({
            validators: {
                card_expires: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(0[1-9]|1[0-2])\/?([0-9]{2})$/) && params.indexOf(val) === -1
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },
                cvv: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{3,4}$/) && params.indexOf(val) === -1
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                card_num: 'The credit card number is invalid.',
                card_exp: 'The :attribute is invalid.',
                required: ':attribute is required.'
            },
        });
    }
    handleCheckAddress = e => {
        const { checked } = e.target;
        this.setState({
            customAddress: !checked,
        });
    }

    ccTypeChanged(type) {
        switch (type) {
            case "visa":
                break;
            case "mastercard":
                break;
            case "amex":
                break;
            case "discover":
                break;
            default:
                type = "unknown"
                break;
        };

        this.setState(() => ({
            ccType: type
        }));
    }
    pullLocationInfoFromZip = (zip) => {
        if (zip.length >= 5) {
            getLocationFromZip(zip, this.state.clientId, this.onZipVerified);
        }
    }

    async componentWillMount() {
        window.addEventListener('beforeunload', this.keepOnPage);
        // SRV 2/21/2023 - if the deductible amount is 0, proceed to next step
        if (this.props.entitlementDetails.deductible === '0'
            || this.props.entitlementDetails.deductible === 0
            || typeof this.props.entitlementDetails.deductible === 'undefined') {
            this.props.nextActiveStep({ billingAddress: this.state.billingAddress });
        }

        fetch('api/party/states/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    states: data.map(s => {
                        return {
                            data: s.stateId,
                            display: s.name,
                            abbreviation: s.abbreviation
                        }
                    })
                });
            })

        // Get SessionId
        sessionId = typeof window.TrustevV2 == "undefined" ? "" : window.TrustevV2.SessionId;
        //console.log(sessionId, window.TrustevV2.SessionId);

        if (this.state.billingAddress == null || typeof this.state.billingAddress?.stateId == 'undefined') {
            this.setState({ verifiedAddress: this.state.serviceAddress });
        }

        let sessionStatusCode = 0;

        if (sessionId == "" || typeof sessionId == "undefined") {
            console.log("Session Id workaround required");
            getTUSessionId(this.state.clientId)
                .then(function (resSession) {
                    sessionStatusCode = resSession.status;
                    return resSession.json();
                })
                .then(sessionData => {
                    if (sessionStatusCode === 200) {
                        sessionId = sessionData.sessionId;
                        console.log("Session Id workaround: " + sessionId);
                    }
                    else {
                        console.log("Session Id failed");
                        sessionId = "";
                    }
                });
        }

        // SEU-1862 show credit card option
        this.setState({
            paymentMethod: "ccard"
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    keepOnPage(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

        let entitlementDetails = this.props.history.location.state.entitlementDetails;
        let headers = {
            type: 'application/json'
        };

        let party = entitlementDetails.party;
        let claimId = entitlementDetails.claimId;

        party.billingAddress = this.state.billingAddress;
        party.billingAddress.addressType = 1;
        party.serviceAddress = this.state.serviceAddress;
        party.serviceAddress.addressType = 2;
        //party.shippingAddress = this.state.verifiedAddress;
        //party.shippingAddress.addressType = 3;

        console.log("closing");
        this.saveForLaterAction(party, claimId);
    }

    saveForLaterAction = (party, claimId) => {
        if (!party) { return; }
        this.setState({ uploading: true });
        var stepName = 'payment';
        // this api call also saves the claim and step in fnol flow
        fetch(`api/claim/AutosaveParty/${claimId}/${stepName}/${this.state.clientId}${this.state.abandonClaim}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(party)
        })
            .then(data => {
                if (data.status === 204) {
                }
                if (data.status === 200) {

                    //this.props.history.push(`FileClaim/Confirmation`, { statusType: "savedClaim" });
                    this.props.history.push('/fileclaim/confirmation', { statusType: 'savedClaim', claimNumber: this.state.claimNumber, clientId: this.state.clientId, claimId: this.state.claimId ? this.state.claimId : this.props.claim?.claimId, contractAssetId: this.props?.claim?.contractAssetId ? this.props?.claim?.contractAssetId : this.props.assetId });

                }
                this.setState({ uploading: false });
            });
    }

    onZipVerified = (result) => {
        if (result.successful) {
            let state = this.state.states.find(({ abbreviation }) => abbreviation === result.location.state).data;
            let billingAddress = this.state.billingAddress;
            billingAddress.stateId = state;
            billingAddress.city = result.location.city;
            this.setState({
                billingAddress: billingAddress
            });
        }
    }

    handleStateDropDown = e => {
        let billingAddress = this.state.billingAddress;
        billingAddress["stateId"] = e;
        billingAddress["state"] = this.state.states.find(state => { return state.data == e }).abbreviation
        this.setState({ billingAddress: billingAddress });
    }


    handleInputChange = e => {
        let { name, value } = e.target;
        let fieldName = name;
        let errors = this.state.errors;
        let billingAddress = this.state.billingAddress;
        console.log(billingAddress)
        //let messages = this.validator.getErrorMessages();
        let validations = "required";

        if (name === "ccNumber") {
            value = e.target.value;
        }
        if (name === "cardExpires") {
            value = e.target.rawValue;
        }

        if (name === "zipCode") {
            this.pullLocationInfoFromZip(value);
        }


        switch (name) {
            case "ccNumber":
                errors["ccInvalid"] = false;
                fieldName = "creditCardNumber";
                validations = "required|card_num";
                break;
            case "cardExpires":
                errors["ccExpired"] = false;
                fieldName = "expirationDate";
                validations = "required|card_expires";
                break;
            case "securityCode":
                fieldName = "securityCode"
                validations = "required|cvv";
                break;
            case "billingAddress":
                fieldName = "billingAddress";
                billingAddress["address1"] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "billingAddress2":
                billingAddress["address2"] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "city":
                billingAddress[name] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "state":
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "zipCode":
                fieldName = "Zip/Postal code"
                billingAddress["zip"] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            default:
                break;
        }

        errors[name] = !this.validator.check(value, validations);
        console.log(errors)

        this.setState({
            [name]: value,
            billingAddress: billingAddress,
            errors: errors
        });

        //console.log(this.state);

    }

    handleCancel = () => {
        cancelClaim(this.state.clientId, this.state.claimNumber);
        this.props.history.push('/fileclaim/confirmation', { statusType: 'cancel', claimNumber: this.state.claimNumber, clientId: this.state.clientId, claimId: this.state.claimId ? this.state.claimId : this.props.claim?.claimId, contractAssetId: this.props?.claim?.contractAssetId ? this.props?.claim?.contractAssetId : this.props.assetId, isEmailSent: true });
    }

    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        let entitlementDetails = this.props.history.location.state.entitlementDetails;
        let headers = {
            type: 'application/json'
        };

        let party = entitlementDetails.party;
        let claimId = entitlementDetails.claimId;
        party.billingAddress = this.state.billingAddress;
        party.billingAddress.addressType = 1;
        party.serviceAddress = this.state.serviceAddress;
        party.serviceAddress.addressType = 2;
        this.setState({ isSaveForLater: true, abandonClaim: true }, () => { this.saveForLaterAction(party, claimId) });
    }


    continue = e => {
        e.preventDefault();
        this.setState({ validationFired: true });

        console.log(this.props, this.state);
        let claimNumber = typeof this.state.claimNumber === "undefined" ? "" : this.state.claimNumber.toString();
        let assetConfig = this.props.entitlementDetails.contractAsset.assetConfigDictionary;
        let makeAndModelNumber = typeof assetConfig === 'object' && assetConfig.hasOwnProperty(2) ? assetConfig[2] : "";

        if (this.validator.allValid() && this.state.ccType !== "unknown") {
            // Clear previous errors
            let errors = this.state.errors;
            for (var key of Object.keys(errors)) {
                errors[key] = false;
            }

            this.setState({ errors: errors });

            // Sets address for payment call
            let verifiedAddress = this.state.verifiedAddress;
            const serviceAddress = this.state.serviceAddress;

            if (verifiedAddress == null || typeof verifiedAddress?.stateId == 'undefined') {
                verifiedAddress = serviceAddress;
            }

            let address = this.state.customAddress ? this.state.billingAddress : verifiedAddress;
            console.log(address);
            let alternateAddress = this.state.customAddress ? this.state.verifiedAddress : this.state.billingAddress;

            if (this.state.customAddress) {
                // save new address as billing address
                fetch('api/party/partyaddress/'
                    .concat(this.state.clientId),
                    {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(
                            {
                                partyId: this.props.entitlementDetails.contract.party.partyId,
                                addressType: 1,
                                address1: this.state.billingAddress.address1,
                                address2: this.state.billingAddress.address2,
                                city: this.state.billingAddress.city,
                                stateId: this.state.billingAddress.stateId,
                                zip: this.state.billingAddress.zip
                            }
                        )
                    }).then(res => {
                        if (res.ok) {
                            if (res.status === "204") {
                                res.json().then(data => {
                                    console.log(data);
                                });
                            }
                        }
                        else {
                            console.log(res);
                        }
                    })
            }

            // Sets credit card type
            let ccTypeId = this.state.ccTypeId;
            console.log("ccTypeId", ccTypeId, this.state.ccType)
            switch (this.state.ccType) {
                case "visa":
                    ccTypeId = "1";
                    break;
                case "amex":
                    ccTypeId = "2";
                    break;
                case "mastercard":
                    ccTypeId = "4";
                    break;
                case "discover":
                    ccTypeId = "3";
                    break;
                default:
                    break;
            }

            // Get month and year from expiration date
            let currentMonth = parseInt((new Date().getMonth() + 1).toString());
            let currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
            let month = parseInt(this.state.cardExpires.substring(0, 2));
            let year = parseInt(this.state.cardExpires.substring(2, 4));

            if (year < currentYear) {
                errors["ccExpired"] = true;
                this.setState({ errors: errors });
                return;
            }
            if (year === currentYear) {
                if (month < currentMonth) {
                    errors["ccExpired"] = true;
                    this.setState({ errors: errors });
                    return;
                }
            }

            let statusCode = '';
            let valid = false;

            // keep spinner active while fetching
            this.setState({
                searching: true,
                paymentAddress: address,
                ccTypeId: ccTypeId
            });

            let amount = 0;

            // call payment service
            fetch('api/payment/'.concat(this.state.clientId), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Amount: parseFloat(this.state.amount),
                    CardHolder: {
                        FirstName: this.state.firstName,
                        LastName: this.state.lastName,
                        SelectedAddress: this.state.selectedAddress,
                        BillingAddress: {
                            StreetAddress: address.address1,
                            Locality: address.city,
                            Region: this.state.states.find(state => { return state.data == address.stateId }).display,
                            PostalCode: address.zip.substring(0, 5),
                            ThreeLetterIsoCode: "USA"
                        },
                        ShippingAddress: {
                            StreetAddress: address.address1,
                            Locality: address.city,
                            Region: this.state.states.find(state => { return state.data == address.stateId }).display,
                            PostalCode: address.zip.substring(0, 5),
                            ThreeLetterIsoCode: "USA"
                        }
                    },
                    CardInfo: {
                        FullNumber: this.state.ccNumber.replace('-', ''),
                        ExpirationMonth: parseInt(month),
                        ExpirationYear: parseInt('20' + year),
                        CreditCardVerificationNumber: this.state.securityCode,
                        CardType: ccTypeId
                    },
                    CurrencyCode: "USD",
                    RelatedTo: 2,
                    CultureName: "en-US",
                    RelatedToIds: [claimNumber]
                })
            }).then(function (res) {

                console.log(res);
                statusCode = res.status;
                return res.json();
            }).then(data => {
                if (statusCode === 204) {
                    let errors = this.state.errors;
                    errors["general"] = true;
                    this.setState({ searching: false, errors: errors }, () => {
                        // Show error messages
                        console.log(statusCode);
                        return;
                    });

                }
                else if (statusCode === 200) {
                    if (!data.isSuccessful) {
                        let errorsResponse = data.errors;
                        if (errorsResponse.length > 0) {
                            errorsResponse.map(function (error) {
                                if (error.errorCode === '9576') {
                                    errors["ccInvalid"] = true;
                                }
                                else if (error.errorCode.includes('EXPIRED')) {
                                    errors["ccExpired"] = true;
                                }
                                else if (error.errorCode.includes('FUND')) {
                                    errors["funds"] = true;
                                }
                                else if (error.errorCode.includes('ADDRESS')) {
                                    errors["billingAddressMatch"] = true;
                                }
                                else if (error.errorCode.includes('DECLINED')) {
                                    errors["declined"] = true;
                                }
                                else {
                                    errors["general"] = true;
                                }
                            });

                            this.setState({ errors: errors, searching: false });

                            return;
                        }
                    }
                    if (this.props.fulfillmentTypeId === 2) {
                        fetch('api/queue/createqueueitem/'.concat(this.state.clientId), {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                AgentQueueId: 1,
                                ItemValue: this.props.entitlementDetails?.claimId,
                                EnqueueReasonId: 10
                            })
                        });
                    }
                    valid = true;


                    if (this.state.amount > 1) {
                        let historyNotes = 'Deductible collected in amount of ${' + this.state.amount + '}';
                        createClaimHistoryItem(this.props.entitlementDetails?.claimId, this.state.clientId, "Deductible Collected", historyNotes);
                    }
                    console.log("success");
                    this.props.nextActiveStep({ billingAddress: this.state.billingAddress, paymentSuccessful: true });
                }
            })


        }
        else {
            let errors = this.state.errors;
            let state = this.state;
            let fieldName = "";
            let validations = "";
            //console.log(this.validator.getErrorMessages());
            //console.log(this.state);
            for (let [key, value] of Object.entries(state)) {
                switch (key) {
                    case "ccNumber":
                        fieldName = "creditCardNumber";
                        validations = "required|card_num";
                        break;
                    case "cardExpires":
                        fieldName = "expirationDate";
                        validations = "required|card_expires";
                        break;
                    case "securityCode":
                        fieldName = "securityCode"
                        validations = "required|cvv";
                        break;
                    case "billingAddress":
                        fieldName = "billingAddress";
                        validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                        break;
                    default:
                        break;
                }
                errors[key] = !this.validator.check(value, "required");
                //console.log(key, value, !this.validator.check(value, "required"));
            }
            if (this.state.ccType === "unknown" && !errors["ccNumber"] ) {
                errors["ccInvalid"] = true;
            }
            this.setState({
                errors: errors
            })
            this.validator.showMessages();
            this.forceUpdate();
        }
    }




    render() {
        console.log("PROPS-data", this.props);
        let verifiedAddress = this.state.verifiedAddress;
        const serviceAddress = this.state.serviceAddress;
        let useServiceAddress = false;

        if (verifiedAddress == null || typeof verifiedAddress?.stateId == 'undefined') {
            verifiedAddress = serviceAddress;
            useServiceAddress = true;
        }

        return (
            <Container>
                <section className="formBody">
                    <Row className="stepsFormBlock">
                        <Col md="7" lg="6" className="no-gutters">
                            <Form>
                                <div className="cardTitle">
                                    <h2 className={classNames("h3", paymentTotal)}>Payment Amount:</h2>
                                    <h2 className={classNames("h3", amount)}><NumberFormat value={this.state.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></h2>
                                </div>
                                <PaymentInputs
                                    state={this.state}
                                    handleInputChange={this.handleInputChange}
                                    validator={this.validator}
                                    ccTypeChanged={this.ccTypeChanged}
                                />
                                <PaymentBillingInfo
                                    state={this.state}
                                    handleInputChange={this.handleInputChange}
                                    validator={this.validator}
                                    handleStateDropDown={this.handleStateDropDown}
                                    handleCheckAddress={this.handleCheckAddress}
                                />
                            </Form>
                        </Col>
                    </Row>
                    {/*<Row className="stepsFormBlock">
                            <div className="btnWrapper">
                                <button className="btn btn-secondary">Cancel</button>
                                <button className="btn btn-primary" onClick={this.continue}>Pay $XX.XX</button>
                                <a href="#" className="btnLink">Save For Later</a>
                            </div>
                        </Row>*/}
                    {this.state.paymentMethod === "ccard" ?
                        <div>
                            {this.state.errors.billingAddressMatch &&
                                <div className="validationSummary text-center">The billing address does not match the cards records.</div>
                            }
                            {this.state.errors.funds &&
                                <div className="validationSummary text-center">Unfortunately there are not enough funds available on this card. Please try paying with a different card.</div>
                            }
                            {this.state.errors.internal &&
                                <div className="validationSummary text-center">Something went wrong processing your payment. This issue was on our side, not yours. Your card was not charged, please try again later.</div>
                            }
                            {this.state.errors.declined &&
                                <div className="validationSummary text-center">The credit card entered was declined, please try paying with a different credit card.</div>
                            }
                            {this.state.errors.general &&
                                <div className="validationSummary text-center">There was an issue processing your payment. Please try again.</div>
                            }
                            {this.state.paid &&
                                <div className="form-label">The payment was successful.</div>
                            }
                        </div>
                        : null
                    }
                    <Row className="stepsFormBlock">
                        <div className={styles.paymentBtnWrapper}>
                            {this.state.paymentMethod === "ccard" ?
                                <button
                                    className={classNames(btnPrimary, btn)}
                                    aria-label="Click pay and submit to pay deductible and complete claim setup or continue tabbing to review your claim summary"
                                    disabled={this.state.searching}
                                    aria-disabled="false"
                                    onClick={this.continue}>
                                    {this.state.searching &&
                                        <Spinner color="light" size="sm" className={spinnerPosition}
                                            animation={this.state.searching ? "border" : ""} />}
                                    <span className={`${this.state.searching && hideBtnTextForSpinner}`}>
                                        {`Pay `}
                                        <NumberFormat
                                            value={this.state.amount}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </span>
                                </button>
                                : null}
                            <CancelModal role="button" buttonClassName={classNames("btn btn-secondary", ml15)} ariaLabel="Opens a modal to cancel your claim" buttonLabel="Cancel" isButton={false} history={this.props.history} claimNumber={this.state.claimNumber} caseNumber={this.state.caseNumber}
                                onSaveForLaterAction={this.onSaveForLaterAction} handleCancel={this.handleCancel} />
                            <br />
                        </div>
                    </Row>
                    <Row className="stepsFormBlock mt-3">
                        {this.state.showConfirmation ?
                            <SaveModal buttonClassName={classNames("btnLink", ml15)} showConfirmation={this.state.showConfirmation} history={this.props.history} continuing={this.state.isSaveForLater}
                                onCancel={this.onSaveCancel} onSaveForLaterAction={this.saveForLater} client={this.state.clientId} />
                            : null}
                        {<a href="#" className={classNames("btnLink", ml15)} onClick={this.onSaveForLaterAction} aria-label="Opens a modal to save your claim">Save For Later</a> }
                    </Row>
                </section>
            </Container>
        );
    }
}

PaymentBillingInfo.contextType = ContractContext