import React from 'react';
import { ReactComponent as ElectricalSystem } from '../../assets/images/electrical-system.svg';
import { ReactComponent as ACSystem } from '../../assets/images/air-conditioner.svg';
import { ReactComponent as VehicleCharger } from '../../assets/images/Vendor/ET/vehicle-charger.svg';
import { ReactComponent as SurgeProtection } from '../../assets/images/Vendor/ET/electric-surge.svg';
import { ReactComponent as Dryer } from '../../assets/images/dryer.svg';
import { ReactComponent as Washer } from '../../assets/images/washer.svg';
import { ReactComponent as Refrigerator } from '../../assets/images/refrigerator.svg';
import { ReactComponent as Dishwasher } from '../../assets/images/dishwasher.svg';
import { ReactComponent as Microwave } from '../../assets/images/microwave.svg';
import { ReactComponent as Range } from '../../assets/images/range.svg';

export const assetInformation = {

    'Electrical Systems': {
        icon: <ElectricalSystem />,
        description: `Electrical issues can be difficult to safely self-diagnose, and repairs can be expensive. This plan 
            provides certain repair coverage for your home's internal and external electrical system that you are responsible for maintaining, up 
            to the maximum annual limit of $10,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for both interior and exterior electrical system components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum annual limit of $10,000`,
            'Breaker panel',
            'Fuse boxes',
            'Standard light switches',
            'Standard and GFCI wall outlets',
            'Disconnect boxes',
            'Interior wiring (the high voltage wiring that services the interior limits of your home)',
            'Fuses and circuit breakers of the main panel',
            'Meter base',
            'Service entrance conductor',
            'Functional frayed high voltage wiring',
            `High voltage overhead and underground electrical wiring between your utility provider’s supply lines and your home's exterior 
                walls that you own`,
            'Weatherhead',
            'Insulator',
            'Riser',
            'Incoming supply conduit',
            'Thermostats and dimmers',
            'Double-voltage outlets for heavy-use appliances'
        ],
        notCoveredInfo: [
            'Electric services that are rated over 400 amps',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Issues that are subject to a manufacturer’s recall',
            `Issues caused by not following the manufacturer’s operation, care or maintenance instructions, as outlined in the owner's manual`,
            'Any pre-existing conditions or failures that occur during the Wait Period, prior to the coverage start date',
            'Unauthorized modifications, alterations or enhancements to covered electrical system components',
            'Non-copper wiring',
            `Any low-voltage system or systems dedicated to sending data or signal through wiring, including wiring for speakers, garage doors, low-voltage 
            lighting or fans, whole-home vacuums, cable television, phones, computers and security systems`,
            `Internet-enabled or 'smart' functions`,
            'Extension cords, plugs and fuses within plugs',
            'Utility meters and surge protectors',
            'Vehicle charging systems or non-utility electric supply systems (such as generators, batteries and solar panels)',
            `Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the electrical system’s proper operation or 
                functionality, including scratches, peeling, discoloration, dents and chips`,
            'Cleaning, periodic checkups and preventive maintenance',
            'Fixtures and appliances that are external to the electric system',
            `External lighting not attached to the side of the service address residence, animal fencing systems, ice melting systems, external security systems 
            and any external connections to a pool, HVAC unit or other external appliance of fixture`,
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures around electric system',
            'Costs of the disposal of hazardous or toxic material',
            'Incidental or consequential damages beyond the benefits specified',
            'Delivery damage and damage caused by packing, unpacking, assembly, installation or removal',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Data or audio cables',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct'
        ]
    },

    'A/C System': {
        icon: <ACSystem />,
        description: `This plan covers repair costs for the primary air conditioning system 
            in your home, including mini-split systems or AC systems capable of generating 
            heat (such as heat pump). AC repairs can be costly, but the Air Conditioning Protection Program
            provides up to $800 per incident for covered repairs, up to a maximum annual limit of $3,000. We make it easy 
            to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Certain repair costs for your central air conditioning system and internal components up to $800 per 
                incident or a cash settlement up to the maximum annual limit of $3,000 minus any prior claims paid if the covered loss can’t be fixed`,
            'Primary cooling system (including one with a heat function)',
            'Repairs caused by normal wear and tear',
            'Capacitors',
            'Circuit boards',
            'Internal fuses',
            'Breakers',
            'Condenser motor',
            'Condenser fan',
            'Contactor switches',
            'Delay time',
            'Fan controls',
            'Fan relays',
            'Filter dryer',
            'High and low pressure switches',
            'Limit controls',
            'Coolant (up to 2 pounds or more depending on repair failure)',
            'Relays',
            'Transformers',
            'Coils',
            'Accumulator',
            'Compressor',
            'Thermostat',
            'Refrigerant reclamation (subject to refrigerant recharge limitation)',
            'Leak search',
            'Brazing material',
            'Air handlers',
            'Costs associated with disposal and permits',
            'Valves'
        ],
        notCoveredInfo: [
            'Coolant replenishment in excess of 2 pounds if unrelated to a repair',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Issues that are subject to a manufacturer’s recall',
            `Issues caused by not following the manufacturer’s operation, care or maintenance instructions, as outlined in the owner's manual`,
            'Any pre-existing conditions or failures that occur during the Wait Period, prior to the coverage start date',
            'Unauthorized modifications, alterations or enhancements to the covered AC System',
            'AC systems purchased without a manufacturer’s warranty or “as-is”',
            'Boiler expansion tanks',
            'Any accessories that are not included in the original packaging of AC system',
            `Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the AC system’s proper operation or 
                functionality, including scratches, peeling, discoloration, dents and chips`,
            'External wiring and piping that is not internal to the unit, including external fuel, water, condensate and electrical lines and related fixtures and components',
            'Costs associated with providing access to or closing access from AC system such as concrete-encased or otherwise obstructed or inaccessible issues',
            'Gas, geothermal and indirect coil systems',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures around AC system',
            'Costs of the disposal of hazardous or toxic material',
            `Consumable items outlined in the manufacturer's owner's manual that are designed to be replaced by the owner such as air filters and lubricants`,
            'Delivery damage and damage caused by packing, unpacking, assembly, installation or removal',
            'Water or electric lines to/from central cooling system, including condensate pumps and related components',
            'Air distribution system that connects to the unit, including air ducts, filters, air handling components (such as plenum, ductwork, return, registers, grills and radiators), diverters, zone controllers, registers, pipes and radiators',
            'Portable units, swamp coolers, dehumidifiers or humidifiers',
            'Individual window, wall or rooftop units',
            'Chimneys, flues or vents',
            'Cosmetic coverings including doors, panels, trim, flushing, insulation or baseboard coverings',
            `Electronics, internet-enabled, or 'smart' components or functions (such as zone controllers)`,
            'Cooling units not configured to code or in line with manufacturer instructions',
            'Cleaning, periodic checkups and preventive maintenance',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Units used for commercial or rental purposes',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct'
        ]
    },

    'Electric Vehicle Charger': {
        icon: <VehicleCharger />,
        description: `This plan will arrange and pay for covered repairs on your Level 2, 240-Volt electric vehicle charger should you experience 
            a structural or operational failure due to defects in materials or workmanship or power surge, up to the maximum annual limit of $2,500. We make it 
            easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair or replacement coverage for Level 2, 240-Volt electric vehicle 
                chargers due to defects in materials or workmanship or a power surge, up to the maximum annual limit of $2,500`,
            'Accessories that are included in the original packaging',
            'Power relay',
            'Contactors',
            'Wire-to-board connectors',
            'Wire-to-wire connectors',
            'Passive components (power resistors)',
            'Electronic filters',
            'Switches',
            'Electrical internal wiring components (terminal blocks, distribution blocks, terminals and splices)',
        ],
        notCoveredInfo: [
            'Electric wiring (other than the point of connection to the charger), fuse boxes or other electrical items that are covered under Electric Line Protection Program',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Issues that are subject to a manufacturer’s recall',
            `Issues caused by not following the manufacturer’s operation, care or maintenance instructions, as outlined in the owner's manual`,
            'Any pre-existing conditions or failures that occur during the Wait Period, prior to the coverage start date',
            'Unauthorized modifications, alterations or enhancements to a covered EV Charger',
            'EV Chargers purchased without a manufacturer’s warranty or “as-is”',
            `Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the unit’s proper operation or functionality, 
                including scratches, peeling, discoloration, dents and chips`,
            `Batteries, solar arrays, external electrical fixtures, outlets, wiring, circuit boxes and breakers and related components of the service 
                address’s electrical grid`,
            'Any accessories that are not included in the original packaging',
            'Cleaning, periodic checkups and preventive maintenance',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Costs of opening, removing, or restoring walls or floors, countertops, cabinets or similar fixtures around the Level 2 charger',
            'Costs of the disposal of hazardous or toxic material',
            `Consumable items outlined in the manufacturer's owner's manual that are designed to be replaced by the owner`,
            'Losses, failures or intermittent performance issues caused by low or poor internet speeds or connections',
            'Incidental or consequential damages beyond the benefits specified',
            'Units used for commercial or rental purposes',
            'Any third party support services or software provided with the covered EV Charger',
            'Software or on-line service performance issues',
            'Losses caused by third-party hardware, software, programming or accessory items',
            'Restoration of data, software or programming',
            'Covered EV Chargers that are lost, misplaced or stolen',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Electric or hybrid motor vehicles and their components, parts and accessories (other than EV Chargers)',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct'
        ]
    },

    'Surge Protection': {
        icon: <SurgeProtection />,
        description: `Lightning, grid abnormalities, and other variables can cause electricity voltage spikes that can fry your electronics. This plan 
            covers repair or replacement of covered electronic items within your home in the event they are damaged by a surge event, up to the maximum
            annual limit of $5,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            'Repair or replacement of covered electronic items, valued at $100 or more, that a qualified repair technician certifies as damaged due to electrical surge event',
            `Coverage up to the eligible item's original purchase price, up to the maximum annual limit of $5,000 minus any prior claims paid`,
            'Covers the following electronic devices owned by residents at the service address',
            'Televisions',
            'Gaming systems',
            'Video Surveillance and alarm monitoring systems',
            'Smart home devices',
            'Desktop and laptop computers',
            'Computer monitors',
            'Keyboards and mice',
            'External hard drives and storage drives',
            'Home theater sound systems (speakers, sound bars, etc.)',
            'Audio tuners',
            'Receivers and amplifiers',
            'Cellular telephones',
            'Smartphones',
            'Tablets and notebooks',
            'Digital cameras',
            'Printers',
            'Projectors',
            'DVD players, DVR and blu-ray players'
        ],
        notCoveredInfo: [
            'Electric wiring, fuse boxes or other electrical items that are covered under Electric System Protection Program',
            'Loss or damage resulting from any cause other than an electrical surge event properly certified by a qualified repair technician',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            `Issues caused by not following the manufacturer’s operation, care or maintenance instructions, as outlined in the owner's manual of the covered electronic`,
            'Any pre-existing conditions or failures that occur during the Wait Period, prior to the coverage start date',
            'Unauthorized modifications, alterations or enhancements to covered electronics',
            'Covered electronics purchased without a manufacturer’s warranty or “as-is”',
            `Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the covered electronics' proper operation or functionality, including scratches, peeling, discoloration, dents and chips`,
            'Electrical fixtures, outlets, wiring, circuit boxes and breakers and related components of the service address’s electrical grid',
            'Any accessories that are not included in the original packaging',
            'Cleaning, periodic checkups and preventive maintenance',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Costs of opening, removing, or restoring walls or floors, countertops, cabinets or similar fixtures around AC system',
            'Costs of the disposal of hazardous or toxic material',
            `Consumable items that are designed to be replaced by the owner such as replaceable batteries`,
            'Incidental or consequential damages beyond the benefits specified',
            'Any product purchased for or used at any time for commercial or rental purposes',
            'Restoration of data, software or programming',
            'Covered electronics that are lost, misplaced or stolen',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Electric vehicles and their connections',
            'Electronic items of less than $100 value',
            'Issues that are subject to a manufacturer’s recall',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct'
        ]
    },

    'Microwave': {
        icon: <Microwave />,
        description: `This plan will arrange and pay for covered repairs on your microwave oven should you experience a mechanical or electrical failure, up to the maximum limit of liability of $3,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for mechanical parts and components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum limit of liability of $3,000`,
            'Door interlock',
            'Electrical switch',
            'Touch pad/controller',
            'Control board',
            'Power supply',
            'Motor',
            'Magnetron'
        ],
        notCoveredInfo: [
            'Countertop or portable units',
            'Convection units',
            'Infrared units',
            'High-speed units',
            'Interior linings',
            'Glass',
            'Shelves',
            'Handles',
            'Meat probe assemblies or rotisseries',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the microwave’s proper operation or functionality, including scratches, peeling, discoloration, dents and chips',
            'Venting components',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Costs of the disposal of hazardous or toxic material',
            'Costs related to permits',
            'Units used for commercial purposes',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures around microwave',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Damage or failure caused by animals or insects, including infestation and human or animal bodily fluids'
        ]
    },

    'Dishwasher': {
        icon: <Dishwasher />,
        description: `This plan will arrange and pay for covered repairs on your dishwasher should you experience a mechanical or electrical failure, up to the maximum limit of liability of $3,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for mechanical parts and components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum limit of liability of $3,000`,
            'Heating element',
            'Drain pump',
            'Thermostat',
            'Washer and spray arms',
            'Drain valve',
            'Motor assembly',
            'Door switch interlock',
            'Timer',
            'Float switch',
            'Inter valve',
            'Internal hoses',
            'Control panel and related electrical parts'
        ],
        notCoveredInfo: [
            'Cleaning or repair due to failure caused by foreign objects, scale, minerals, and other deposits',
            'Door seals or gaskets',
            'Baskets',
            'Portable, mini, or countertop units',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Costs of the disposal of hazardous or toxic material',
            'Costs related to permits',
            'Units used for commercial purposes',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the dishwasher’s proper operation or functionality, including scratches, peeling, discoloration, dents and chips',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures around dishwasher',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Damage or failure caused by animals or insects, including infestation and human or animal bodily fluids'
        ]
    },

    'Cooking Product': {
        icon: <Range />,
        description: `This plan will arrange and pay for covered repairs on your cooking products (range, oven, or cooktop) should you experience a mechanical or electrical failure, up to the maximum limit of liability of $3,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for mechanical parts and components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum limit of liability of $3,000`,
            'Gas valve',
            'Main burner',
            'Pilot burner',
            'Thermocouple',
            'Manifold transformer',
            'Relay',
            'Regulator',
            'Standard thermostat',
            'Igniter',
            'Fuse',
            'Sensor',
            'Power pack',
            'Surface unit controls',
            'Programmed cooking controls',
            'Heating elements',
            'Internal wiring',
            'Control board'
        ],
        notCoveredInfo: [
            'Magnetic induction',
            'Glass',
            'Self-cleaning function',
            'Convection function',
            'Venting',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents and chips',
            'Door seals and/or gaskets',
            'Thermostatic controllers will only be replaced with standard controls',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Costs of the disposal of hazardous or toxic material',
            'Costs related to permits',
            'Units used for commercial purposes',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Damage or failure caused by animals or insects, including infestation and human or animal bodily fluids'
        ]
    },

    'Refrigerator': {
        icon: <Refrigerator />,
        description: `This plan will arrange and pay for covered repairs on your refrigerator should you experience a mechanical or electrical failure, up to the maximum limit of liability of $5,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for mechanical parts and components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum limit of liability of $3,000`,
            'Ice makers and controls',
            'Condenser',
            'Defrost heating element',
            'Standard thermostat',
            'Fuse',
            'Relay',
            'Transformer',
            'Motor',
            'Compressor',
            'Pulleys',
            'Timer',
            'Fan control',
            'Bearings',
            'Pump motor',
            'Switches',
            'Electrodes',
            'Semi-conductors',
            'Rectifiers',
            'Valves',
            'Electronics circuits',
            'Food spoilage up to $300',
            'Control board'
        ],
        notCoveredInfo: [
            'Ice crusher',
            'Beverage dispensers and associated parts',
            'Handles and knobs',
            'Door seals and/or gaskets',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents and chips',
            'Interior thermal shells or liners',
            'Inaccessible refrigerant leaks and/or repairs',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Costs of the disposal of hazardous or toxic material',
            'Costs related to permits',
            'Units used for commercial purposes',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Damage or failure caused by animals or insects, including infestation and human or animal bodily fluids',
            'Units not located in kitchen'
        ]
    },

    'Washer': {
        icon: <Washer />,
        description: `This plan will arrange and pay for covered repairs on your washer should you experience a mechanical or electrical failure, up to the maximum limit of liability of $3,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for mechanical parts and components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum limit of liability of $3,000`,
            'Water level switch',
            'Water inlet valve',
            'Water temperature switch',
            'Drive basket',
            'Brakes',
            'Clutch assembly',
            'Timer',
            'Sequencer',
            'Lid switch and actuator',
            'Touch pad',
            'Control board',
            'Power supply',
            'Motor',
            'Agitator',
            'Pump coupling',
            'Wigwag',
            'Drive belt',
            'Boot seal, and related electrical parts'
        ],
        notCoveredInfo: [
            'Fabric softener dispensers',
            'Filter screens',
            'Knobs and dials',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents and chips',
            'Damage to clothing',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Costs of the disposal of hazardous or toxic material',
            'Costs related to permits',
            'Units used for commercial purposes',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Damage or failure caused by animals or insects, including infestation and human or animal bodily fluids'
        ]
    },

    'Dryer': {
        icon: <Dryer />,
        description: `This plan will arrange and pay for covered repairs on your dryer should you experience a mechanical or electrical failure, up to the maximum limit of liability of $3,000. We make it easy to file a claim, review your benefits and manage your plan.`,
        coveredInfo: [
            `Provides certain repair coverage for mechanical parts and components that suffer failures due to 
                defects in materials or workmanship or normal wear and tear up to maximum limit of liability of $3,000`,
            'Gas valve',
            'Main burner',
            'Pilot burner',
            'Thermocouple',
            'Controls',
            'Transformer',
            'Relay',
            'Regulator',
            'Standard thermostat',
            'Igniter',
            'Fuse',
            'Sensor',
            'Power pack',
            'Seals',
            'Drive belt',
            'Motor',
            'Bearings',
            'Pulleys',
            'Timer',
            'Electrical heating element'
        ],
        notCoveredInfo: [
            'Venting',
            'Knobs and dials',
            'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents and chips',
            'Damage to clothing',
            'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
            'Costs of the disposal of hazardous or toxic material',
            'Costs related to permits',
            'Units used for commercial purposes',
            'Costs of opening, removing or restoring walls or floors, countertops, cabinets or similar fixtures around dishwasher',
            'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful or reckless misconduct',
            'Incidental or consequential damages beyond the benefits specified',
            'Costs relating to upgrade or modification of parts, components or equipment and bringing up to code',
            'Costs arising from or relating to any needed installation, set-up, removal or disposal services',
            'Damage or failure caused by animals or insects, including infestation and human or animal bodily fluids'
        ]
    },
}