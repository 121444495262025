import React, {useState, useEffect} from 'react'
import ReCAPTCHA from 'react-recaptcha';
import useTranslation from '../../../components/contexts/LanguageTranslation/useTranslation';

const TEST_SITE_KEY = "6LeyNfoSAAAAAPb-dogoo24hW1Ah4rn8g6Vb9o_z";

const InternationalReCAPTCHA = ({handleCaptcha}) => {
    const international = useTranslation()
    const { language } = international
    const [hlLangCode, setHlLangCode] = useState('en');

    const getHl = () => {
        switch (language) {
            case 'de': return 'de';
            case 'en': return 'en';
            case 'es': return 'es';
            case 'fr': return 'fr';
            case 'it': return 'it';
            case 'vn': return 'vi';
            case 'cl': return 'es';
            case 'nl': return 'nl';
            case 'no': return 'no';
            case 'sv': return 'sv';
            case 'da': return 'da';
            case 'fi': return 'fi';
            case 'sg': return 'de-CH';
            case 'at': return 'de-AT';
            default: return 'en';
            
        }
    }

    useEffect(() => {
        const hlValue = getHl()
        setHlLangCode(hlValue);
    }, [language])
    
    
    return (
        <ReCAPTCHA
            key={hlLangCode}
            sitekey={TEST_SITE_KEY}
            reneder="explicit"
            verifyCallback={handleCaptcha}
            hl={hlLangCode}
        />  
    )
}

export default InternationalReCAPTCHA;