import React, { useContext } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import styles from './SurgeClaimHireOwnServicer.module.css';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Info } from '../../assets/images/info-i-icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-down-arrow.svg';
import { ContractContext } from '../../components/contexts/ContractContext';
import { openDocument } from '../Global/DocumentHelper';

function SurgeClaimHireOwnServicer({ affirmationChange, showError, clientId }) {
    const context = useContext(ContractContext)
    const coverageDocsData = context.data?.coverageDocs;
    const riskProfileId = context.data?.contract?.riskProfile?.riskProfileId;
    const filteredDocs = coverageDocsData?.filter(doc => doc.warrantyObjectValue === riskProfileId);
    const documentModel = filteredDocs?.map(item => item.documentModel);
    const termsConditionsDoc = documentModel?.find(doc => doc.documentTypeId === 11);
    const termsConditionsDocId = termsConditionsDoc?.documentId;
    const warrantyObjectTypeId = 12;

    const openTermsAndConditions = (e) => {
        e.preventDefault();
        openDocument(warrantyObjectTypeId, riskProfileId, termsConditionsDocId, clientId);
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={classNames(styles.instructionHeader, "mb-4", "mt-0")}>
                For surge claims, hire your own servicer and complete the following instructions to submit for reimbursement:
            </div>

            <p className='mb-4'>
                <strong className={styles.headerWrap}>Step 1: </strong>
                Hire a licensed service technician to certify the cause of damage is a result of an electrical surge. Request the technician to complete and sign the certification form below.
            </p>
            <p className='mb-4'>
                <strong className={styles.headerWrap}>Step 2: </strong>Once certified, have the product(s) repaired.
                <span className={`${styles.hasTooltip} ml-2`}>
                    <Info
                        data-tip data-for='tooltip'
                        aria-label="Product repaired"
                        role="tooltip"
                        className={styles.infoIcon}
                    />
                    <ReactTooltip
                        id='tooltip'
                        tabIndex="0"
                        clickable="true"
                        aria-haspopup='true'
                        className={styles.tooltiptext}
                        effect="solid"
                        type="light"
                        place={"bottom"}
                        border={true}
                    >
                        <div>
                            <p className='mb-2'>
                                Please note: Not all repairs are covered by the contract. Review the <a href="#" onClick={(e) => openTermsAndConditions(e)} className={styles.link}>terms and conditions</a> to ensure the repair is covered and eligible for reimbursement.
                            </p>
                            <p className='mb-0'>
                                If any covered electronics is not repairable, documentation estimating the replacement value of the covered property will be required.
                            </p>
                        </div>
                    </ReactTooltip>
                </span>
            </p>
            <p className='mb-4'>
                <strong className={styles.headerWrap}>Step 3: </strong> 
                Submit the following documents within 60 days of filing a claim to receive reimbursement. You will receive an email with instructions on how to submit.
            </p>
            <div className={styles.docWrapper}>
                <Row className='mb-2'>
                    <Col lg="6" md="6" className={`font-weight-bold`}>Surge Claim Form</Col>
                    <Col lg="6" md="6">
                        <a className={`font-weight-bold ${styles.link}`} href="/documents/claim-form.pdf" target="_blank">
                            Download
                            <DownloadIcon className={styles.downloadIcon}/>
                        </a>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col lg="6" md="6" className={`font-weight-bold`}>Cause of Damage Certification</Col>
                    <Col lg="6" md="6">
                        <a className={`font-weight-bold ${styles.link}`} href="/documents/technician-certification.pdf" target="_blank">
                            Download
                            <DownloadIcon className={styles.downloadIcon}/>
                        </a>
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col>
                        <span className={`font-weight-bold ${styles.header} mr-2`}> Paid Repair Invoice</span>
                        <span className={styles.hasTooltip}>
                            <Info
                                data-tip data-for='paidtooltip'
                                aria-label="Paid repair invoice"
                                role="tooltip"
                                className={styles.infoIcon}
                            />
                            <ReactTooltip
                                id='paidtooltip'
                                tabIndex="0"
                                clickable="true"
                                aria-haspopup='true'
                                className={styles.tooltiptext}
                                effect="solid"
                                type="light"
                                place={"bottom"}
                                border={true}>
                                <div>
                                    <p className="mb-0">Provide a legible copy of your repair invoice, the invoice must:</p>
                                    <ul className="pl-3 mb-0">
                                        <li>Be paid in full</li>
                                        <li>Clearly identify the product's brand, model number and serial number</li>
                                        <li>Include the repair description and all charges associated</li>
                                    </ul>
                                </div>
                            </ReactTooltip>
                        </span>
                    </Col>
                </Row>
            </div>
            <Row id="affirm" className="formBlock mb-2">
                <Col>
                    <div className="checkbox scriptBox m-0">
                        <Input
                            className="mr-2"
                            type="checkbox"
                            id={"affirmStatement"}
                            name="affirmationStatement"
                            onChange={e => affirmationChange(e.target.checked)}
                        />
                        <Label for={"affirmStatement"} className={`checkboxLabel ${styles.affirmCheckboxLabel} ${clientId.toLowerCase()}`}>
                            I affirm that I understand not all repairs and replacements are covered by the contract. 
                            You must view the <a href="#" onClick={(e) => openTermsAndConditions(e)} className={styles.link}>terms and conditions</a> to ensure the repair is covered and eligible for reimbursement.
                        </Label>
                        {showError &&
                            <p className={`${styles.affirmError} errorMsg`}>
                                Please affirm you understand not all repairs and replacements are covered.
                            </p>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SurgeClaimHireOwnServicer;