import React, { useState, useEffect, useRef } from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import styles from '../Vendor/Apple/IntakeLoss/Entitlement.module.css';
import FooterButtonsRow from '../shared/FooterButtonsRow/FooterButtonsRow';
import SimpleReactValidator from "simple-react-validator";
import useTranslation from '../../components/contexts/LanguageTranslation/useTranslation';
import FormInputField from '../shared/FormInputField';

const SaveModal = (props) => {
    const {
        buttonLabel,
        buttonClassName,
        className,
        isButton,
        history,
        showConfirmation,
        continuing,
        emailAddress,
        client
    } = props;

    //let continuing = false;

    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState(props.history?.location?.state?.entitlementDetails?.party?.partyEmailModel[0]?.address);
    const [, forceUpdate] = useState();
    //const [showConfirmation, setConfirm] = useState(false);
    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                email: "Please enter a valid email.",
                required: ":attribute is required.",
            },
            element: (message) => (
                <div
                    className="errorMsg"
                    role="alert"
                >
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
        })
    );
    const toggle = (e) => {
        e.preventDefault();
        setModal(!modal)
        props.onCancel(e);
    };

    const confirmationToggle = (e) => {
        e.preventDefault();
        toggle(e);
        setModal(false);
        console.log(e, props);
        setEmail(email);
        //setConfirm(!showConfirmation);
        props.onCancel(e);
    };

    const cancelAction = (e) => {
        e.preventDefault();
        history.push('/vendor/apple/confirmation', { statusType: "cancelledClaim", caseNumber: history.location.state.caseNumber });
    }

    const saveForLaterAction = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            saveEmailAddress().then(props.onSaveForLaterAction(e));
            toggle(e);
        }
        else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }

        // history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: history.location.state.caseNumber });
    }

    const setEmailValue = (e) => {
        e.preventDefault();
        let { name, value, id } = e.target;
        setEmail(value);
        return value;
    }

    const saveEmailAddress = async () => {
        let model = props.history?.location?.state?.entitlementDetails?.party?.partyEmailModel[0];

        if (typeof model === "undefined") {
            model = { address: email, createdBy: model?.createdBy, partyId: props.history?.location?.state?.entitlementDetails?.party?.partyId };
        } else {
            model.address = email;
        }

        console.log(model);

        // Save email address for PartyId
        await fetch(`api/party/partyemail/${client}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        });
    }

    const international = useTranslation('components/modals/SaveModal');
    const {
        country,
        translations
    } = international;

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div>
            <div>
                <Modal isOpen={showConfirmation} toggle={confirmationToggle} aria-modal="true" className="md" aria-labelledBy="save" role="dialog">
                    <ModalHeader className="noLine">
                        <button type="button" className="close" onClick={toggle} data-dismiss="modal" aria-label={translations.closeAria ||"Close"}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </ModalHeader>
                    <ModalBody className={styles.saveModalBody}>
                        <h4 className="modal-title" id="save">{translations.title || 'Would you like to save your claim to continue later?'}</h4>
                        <p>{translations.youWillReceive || 'You will receive an email with a link to resume your claim.'}</p>
                        <Col md="7">
                            <FormInputField
                                fieldName='email'
                                fieldLabel={translations.email || 'Email'}
                                onChange={setEmailValue}
                                value={email}
                                error={simpleValidator.current.message(
                                    "email",
                                    email,
                                    "required|email",
                                    {
                                        messages: {
                                            required: translations.errors?.emailError || "Email is required.",
                                            email: translations.errors?.emailInvalid || "Please enter a valid email address."
                                        }
                                    }
                                )}
                            />
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <FooterButtonsRow
                            isModal
                            buttons={{
                                secondary: {
                                    text: translations.resumeClaim || 'Resume Claim',
                                    type: 'secondary',
                                    onClick: (e) => { confirmationToggle(e) },
                                    ariaLabel: translations.resumeClaimAria || 'Click resume claim to close modal and continue filing claim',
                                },
                                primary: {
                                    hasSpinner: true,
                                    isSpinning: props.continuing,
                                    text: translations.saveClaim || 'Save Claim',
                                    type: 'primary',
                                    onClick: (e) => { saveForLaterAction(e) },
                                    ariaLabel: translations.saveClaimAria || 'Click resume claim to close modal and continue filing claim',
                                }
                            }}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

export default withRouter(SaveModal);