import React, { Component } from 'react';
import {
    Row, Col
} from 'reactstrap';
import styles from './ProgressBar.module.css';
import classNames from 'classnames';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent';

export default class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step
        }
    }

    isActive = s => {
        let active = styles.active;
        return s === this.state.step ? active : '';
    }

    isCompleted = s => {
        let completed = styles.completed;
        return s < this.state.step ? completed : '';
    }

    render() {
        let progressBar = styles.progressBar;
        let noGutters = "no-gutters";
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/IntakeLoss/ProgressBar'}>
                {international => {
                    const { translations } = international
                    return (
                        /*To toggle progress bar states use .active or .completed*/
                        <Row className={classNames(progressBar, noGutters)} >
                            <Col xs="4" className={classNames(this.isActive(1), this.isCompleted(1))}>1. {translations.step1 || 'What Happened'}</Col>
                            <Col xs="4" className={classNames(this.isActive(2), this.isCompleted(2))}>2. {translations.step2 || 'Shipping'}</Col>
                            <Col xs="4" className={classNames(this.isActive(3), this.isCompleted(3))}>3. {translations.step3 || 'Payment'}</Col>
                        </Row>
                    )
                }}
            </UseTranslationInClassComponent>
        );
    }
}