import React, { Component } from 'react';
import styles from "../../../screens/PlanOverview/DetailsOverview.module.css";
import { StaticAddress } from '../../Vendor/Apple/IntakeLoss/CountryAddressForms';


export default function DetailsDisplayBlock({ title, address, clientId, countryCode }) {
    //console.log(title,address)
    return (
        <>
            <h3 className={styles.claimDetailsH6}>{title}</h3>
            <div>
                {address && <StaticAddress addressData={address} showName={true} clientId={clientId} countryCode={countryCode} />}
            </div>
        </>
    );
}
