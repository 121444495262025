import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Label, Input, Container, FormGroup, Form } from "reactstrap";
import classNames from "classnames";
import styles from "./OrderSummary.module.css";
import SimpleReactValidator from "simple-react-validator";

let btnPrimary = "btn-primary";
let btn = "btn";

const PromoCode = ({ handleApplyPromoCode, isPromoApplied }) => {
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        max: "The :attribute must not be greater than :max characters.",
        required: ":attribute is required.",
      },
      element: (message) => (
        <div className="errorMsg" aria-live="polite">
          {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
      ),
    })
  );

  const [promoCodeText, setPromoCodeText] = useState("");
  const [, forceUpdate] = useState();
  const [errMsg, setErrMsg] = useState({});

  const [errors, setErrors] = useState({
    promocode: false,
  });

  const validatePromoCode = () => {
    let isValidPromo = true;
    //TODO: API call to validate promo code

    if (isValidPromo) {
      handleApplyPromoCode({
        type: "percent",
        value: 10,
        name: promoCodeText,
      });
      setPromoCodeText("");
    } else {
      // TODO: this block should be based on the promo code validation service response

      // setting dummy vlaues for UI development
      setErrors({ promocode: true });
      setErrMsg({ msg: "Promo code entered is invalid." });
    //   setErrMsg({msg:'Promo code entered is expired.'})
    }
  };

  const onApplyButtonClick = (event) => {
    event.preventDefault();
    if (simpleValidator.current.allValid()) {
      validatePromoCode();
    } else {
      setErrors({ promocode: true });
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <FormGroup className={errors.promocode && "form-error"}>
      <Row className="mb-4">
        <Col lg="8" md="7">
          <Label for="promocode" className="form-label">
            Promo Code
          </Label>
          <Input
            type="text"
            className="form-control"
            type="text"
            name="promocode"
            value={promoCodeText}
            disabled={isPromoApplied}
            onChange={(e) => {
              setErrMsg({});
              setErrors(false);
              setPromoCodeText(e?.target?.value);
            }}
          />
          {!isPromoApplied &&
            simpleValidator.current.message(
              "promocode",
              promoCodeText,
              "required|alpha_num|max:16"
            )}
          {errMsg && (
            <div class="errorMsg" aria-live="polite">
              {errMsg.msg}
            </div>
          )}
        </Col>
        <Col lg="4" md="5" className="pl-md-0">
          <button
            disabled={isPromoApplied}
            className={` ${classNames(btnPrimary, btn)} ${styles.applyBtn}`}
            aria-label="apply"
            onClick={onApplyButtonClick}
          >
            Apply
          </button>
        </Col>
      </Row>
    </FormGroup>
  );
};
export default PromoCode;
