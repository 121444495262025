import React from 'react';
import useTranslation from './useTranslation';

export const UseTranslationInClassComponent = ({componentPathFromSrcFolder, children}) => {
    const international = useTranslation(componentPathFromSrcFolder)
    
    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return children(international)
}

// export default UseTranslationInClassComponent