import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styles from './Reimbursement.module.css';
import { ReactComponent as Chevron } from '../../assets/images/arrow-cheveron-down.svg';
import classNames from 'classnames';
import DragandDropFileUpload from '../../components/Vendor/Apple/UploadDocs/DragandDropFileUpload';
import { UploadProgress } from './UploadProgress';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import DocumentTitle from 'react-document-title';

let rtCol = styles.rtCol;
let submitBtn = styles.submitBtn;
let colLg4 = "col-lg-4";
let progressItem = styles.progressItem;
let progressItemCompleted = styles.progressItemCompleted;
let orderLast = "order-last";
let orderLGFirst = "order-lg-first";
let overviewBody = styles.overviewBody;
let mrAuto = "mr-auto";

export class Reimbursement extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            fileData: [],
            isUpdatebtnDisable: true,
            uploaded: false
        }
    }

    handleUpload = (e) => {
        this.setState({ uploaded: true })
    }

    onFileUpload = (files) => {
        console.log('files', files)
        this.setState(prev => ({ fileData: [...prev.fileData, ...files] }))
    }

    onRemoveFile = (file) => {
        const filter = this.state.fileData.filter(e => e !== file);
        console.log({filter})
        this.setState({fileData: filter})
    }


    render() {
        return (
            <Container className="contentWrapper pl-md-0">
                <Row>
                    <a className={classNames(styles.search, "mb-3")}><Chevron className={styles.searchIcon} /> View Plan Details </a>
                </Row>
                <Row>
                    <Col lg="7" className={classNames(overviewBody, mrAuto, orderLast, orderLGFirst)}>
                        {!this.state.uploaded ? <section id="uploadDocsConfirm" className={styles.overviewSection}>
                            <h1 className={styles.btmLine}>Upload Document(s) for Reimbursement</h1>
                            <p className="mb-1">To receive reimbursement for service, please upload a legible copy of your repair invoice. <br className="show-lg-up" aria-hidden="true"/>The invoice must:</p>
                            <ul className={styles.reimbursementList}>
                                <li>Be paid in full</li>
                                <li>Clearly identify the product's brand, model number and serial number</li>
                                <li>Include the repair description and all charges associated</li>
                            </ul>
                            <p> Please note: Not all repairs are covered by the contract.  Review the  <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label=" terms and conditions opens a new details window">
                                terms and conditions </Link> to ensure the repair is covered and eligible for reimbursement.</p>
                        </section> :
                            <section id="uploadDocsConfirm" className={styles.overviewSection}>
                                <h1 className={styles.btmLine}>Reimbursement in Review</h1>
                            </section>}

                        {!this.state.uploaded ? <section id="uploadDocsWrapper">
                            <DragandDropFileUpload onParentFileUpload={this.onFileUpload}></DragandDropFileUpload>
                            {this.state.fileData && this.state.fileData?.map(e => <UploadProgress fileData={e} onRemoveFile={this.onRemoveFile} setButtonEnable={() => this.setState({ isUpdatebtnDisable: false })} />)}
                            <p>If you’d like to speak with one of our representatives, call <a href="tel:866-523-8649" aria-label="Click to Call 866-523-8649" className="callLink">(866) 523-8649</a> <br className="show-lg-up" aria-hidden="true" /> or <a href="mailto:invoiceprocessing@sndirect.com">invoiceprocessing@sndirect.com</a>. Please reference your Claim Number: 93045349</p>
                            <button className={classNames("btn", "btn-primary", submitBtn)} onClick={this.handleUpload} disabled={this.state.isUpdatebtnDisable || this.state.fileData.length === 0} aria-label="Click to submit documents for review"> Upload </button>
                        </section> :
                            <section id="uploadDocsWrapper">
                                <p><b>Thank you for uploading your document(s).</b> One of our representatives will review the information provided. If approved, you’ll receive a reimbursement check in the mail. Please allow up to 45 business days for our team to review and process your reimbursement.</p>
                                <p>If you’d like to speak with one of our representatives, call <a href="tel:866-523-8649" aria-label="Click to Call 866-523-8649" className="callLink">(866) 523-8649</a> <br className="show-lg-up" aria-hidden="true" /> or <a href="mailto:invoiceprocessing@sndirect.com">invoiceprocessing@sndirect.com</a>. Please reference your Claim Number: 93045349.</p>
                            </section>}
                    </Col>

                    {/*MUST BE CREATED AS A SEPERATE COMPONENT*/}

                    <article className={classNames(colLg4, rtCol)}>
                        <h1 className="h3 mb-0">Claim Status</h1>
                        <div id="trackinNo" hidden>
                            <h3 className={styles.estArrival}>Arrives: Saturday, April 27 </h3>
                            <a href="#" aria-label="UPS Shipment Tracking Status" target="_blank">Tracking #: 7678</a>
                        </div>
                        <ul className={styles.claimProgress}>
                            <li className={classNames(progressItem, progressItemCompleted)}>
                                <p>Claim Received</p>
                                <p>{moment().format('MM/DD/YYYY hh:mm a')}</p>
                            </li>
                            <li id="claimAp" className={classNames(progressItem, progressItemCompleted)}>
                                <p>Claim Approved</p>
                                <p>{moment().format('MM/DD/YYYY hh:mm a')}</p>
                            </li>
                            <li id="deliveryId" className={classNames(progressItem, progressItemCompleted)}>
                                <p>Device Shipped</p>
                                <p>See all updates</p>
                            </li>
                        </ul>
                        <button id="trackShipBtn" hidden className="btn btn-primary" aria-label="Opens a UPS Carrier website for tracking shipment">Track Shipment</button>
                    </article>
                </Row>
            </Container>
        );
    }
}