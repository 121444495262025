import React, { Component, useContext } from 'react';
import {
    Container, Col, Row, Label, Input, Form, FormGroup
} from 'reactstrap';
import styles from './VerifyInfo.module.css';
import classNames from 'classnames';
import { DropDownAutoComplete } from '../../components/inputs/DropDownAutoComplete';
import { getTUSessionId, pushHistory, paths } from '../../helpers';
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import { DropDownInput } from '../../components/inputs/DropDownInput';
import CancelModal from '../modals/CancelModal';
import { ContractContext } from '../contexts/ContractContext';
import getClientContent from '../_BrandingProvider/brandContent.jsx'
import BrandedIcon from '../_BrandingProvider/BrandedIcon';
import SaveModal from '../modals/SaveModal';
import qs from 'qs';
import { cancelClaim } from '../Global/helpers';

let cardTitle = styles.cardTitle;
let phonePreference = styles.phonePreference;
let sessionId = "";
let ml15 = styles.ml15;

export class VerifyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCancelModal: false,
        }

        this.keepOnPage = this.keepOnPage.bind(this);
        let mobile = "";
        let alternateMobile = "";
        const phoneModel = this.props.entitlementDetails.party.partyPhoneModel;

        if (phoneModel && phoneModel.length >= 1) {
            phoneModel.forEach((obj) => {
                if (obj.isPrimary) {
                    mobile = obj.number;
                } else {
                    alternateMobile = obj.number;
                }
            })
        }
        let phone = "";
        if (mobile) {
            phone = mobile;
        } else {
            phone = alternateMobile;
        }
        let email = "";
        const emailModel = this.props.entitlementDetails.party.partyEmailModel;
        if (emailModel && emailModel.length >= 1) {
            email = emailModel[0].address
        }
        let billingAddress = {}
        if (this.props.fulfillmentTypeId === 2) {
            billingAddress = {
                address1: this.props.entitlementDetails.address1,
                address2: this.props.entitlementDetails.address2,
                city: this.props.entitlementDetails.city,
                state: this.props.entitlementDetails.state,
                stateId: this.props.entitlementDetails.stateId,
                zip: this.props.entitlementDetails.zip
            }
        } else {
            billingAddress = {
                address1: this.props.entitlementDetails.party.serviceAddress?.address1,
                address2: this.props.entitlementDetails.party.serviceAddress?.address2,
                city: this.props.entitlementDetails.party.serviceAddress?.city,
                state: this.props.entitlementDetails.party.serviceAddress?.state,
                stateId: this.props.entitlementDetails.party.serviceAddress?.stateId,
                zip: this.props.entitlementDetails.party.serviceAddress?.zip
            }
        }
        this.state = {
            editing: false,
            states: null,
            firstName: this.props.entitlementDetails.party.firstName,
            lastName: this.props.entitlementDetails.party.lastName,
            email: email,
            isSaveForLater: false,
            phone: phone,
            billingAddress: billingAddress,
            mobileType: "Mobile Phone",
            hwaToken: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id,
            validationFired: false
        }
        this.toggleEdit = this.toggleEdit.bind(this);
        this.validator = new SimpleReactValidator({
            messages: {
                phone: "Please enter a valid phone number.",
                email: "Please enter a valid email.",
                required: ':attribute is required.'
            },
            validators: {
                valid_phone: {
                    message: 'Please enter a valid phone number.',
                    rule: (val, params, validator) => {
                        return !new RegExp("^([0-9])\\1{9}$").test(val.match(/\d+/g).join(''))
                    }
                }
            },
            element: message => <div className="errorMsg" aria-live="polite">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        });
    }

    toggleCancelModal = (action) => {
        switch (action) {
            case "OPEN":
                this.setState({ showCancelModal: true })
                return
            case "CLOSE":
                this.setState({ showCancelModal: false })
                return
            default: return
        }
    }

    toggleEdit(e) {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.cancelClaim !== prevProps.cancelClaim && this.props.cancelClaim) {
            this.toggleCancelModal('OPEN');
        }
    }

    componentWillMount() {
        window.addEventListener('beforeunload', this.keepOnPage);
        fetch('api/party/states/' + this.props?.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    states: data.map(s => {
                        return {
                            data: s.stateId,
                            display: s.name,
                            abbreviation: s.abbreviation
                        }
                    })
                });
            })

        // Get SessionId
        sessionId = typeof window.TrustevV2 == "undefined" ? "" : window.TrustevV2.SessionId;
        //console.log(sessionId, window.TrustevV2.SessionId);

        let sessionStatusCode = 0;

        if (sessionId == "" || typeof sessionId == "undefined") {
            console.log("Session Id workaround required");
            getTUSessionId(this.state.clientId)
                .then(function (resSession) {
                    sessionStatusCode = resSession.status;
                    return resSession.json();
                })
                .then(sessionData => {
                    if (sessionStatusCode === 200) {
                        sessionId = sessionData.sessionId;
                        console.log("Session Id workaround: " + sessionId);
                    }
                    else {
                        console.log("Session Id failed");
                        sessionId = "";
                    }
                });
        }

        // SEU-1862 show credit card option
        this.setState({
            paymentMethod: "ccard"
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    keepOnPage(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

        let entitlementDetails = this.props.history.location.state.entitlementDetails;
        let headers = {
            type: 'application/json'
        };

        let party = entitlementDetails.party;
        let claimId = entitlementDetails.claimId;

        party.billingAddress = this.state.billingAddress;
        party.billingAddress.addressType = 1;
        //party.shippingAddress = this.state.verifiedAddress;
        //party.shippingAddress.addressType = 3;

        console.log("closing");
        this.saveForLaterAction(party, claimId);
    }

    saveForLaterAction = (party, claimId) => {
        if (!party) { return; }
        this.setState({ uploading: true });

        var stepName = 'verifyInfo';

        //api call also saves step in fnol process
        fetch(`api/claim/AutosaveParty/${claimId}/${stepName}/${this.props?.clientId}/${this.state.abandonClaim}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(party)
        })
            .then(data => {
                if (data.status === 200) {
                    pushHistory(this.props, paths.fileClaimConfirmation, { statusType: 'savedClaim' }, this.props.history);
                }

                this.setState({ uploading: false });
            });
    }

    handleStateDropDown = e => {
        let billingAddress = this.state.billingAddress;
        billingAddress["stateId"] = e;
        this.setState({ billingAddress: billingAddress });
    }

    handlePhoneDropDown = e => {
        let mobile = "";
        let alternateMobile = "";
        const phoneModel = this.props.entitlementDetails.party.partyPhoneModel;
        if (phoneModel && phoneModel.length >= 1) {
            phoneModel.forEach((obj) => {
                if (obj.isPrimary) {
                    mobile = obj.number;
                } else {
                    alternateMobile = obj.number;
                }
            })
        }
        let phone = this.state.phone;
        if (e == "Mobile Phone") {
            phone = mobile;
        } else if (e == "Alternate") {
            phone = alternateMobile;
        }
        console.log("Mobile", mobile, "alter", alternateMobile, phone)
        this.setState({ mobileType: e, phone: phone });
    }

    handleInputChange = e => {
        let { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleCancel = () => {
        cancelClaim(this.props.clientId, this.props.entitlementDetails.claimId);
        this.props.history.push('/fileclaim/confirmation', { statusType: 'cancel', claimNumber: this.props.claim?.claimNumber, clientId: this.props.clientId, claimId: this.props.claim?.claimId, contractAssetId: this.props.match?.params?.assetId, isEmailSent: true });
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState({ isSaveForLater: true, abandonClaim: true }, () => { this.continue() });
    }



    saveCollectedInfo = async () => {
        const party = this.props.entitlementDetails.party;

        const response = await fetch(`api/Party/SavePreferredPhone/${this.props?.clientId}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                partyId: party.partyId,
                number: this.state.phone,
                phoneType: this.state.mobileType,
                emailAddress: this.state.email
            })
        })
        if (response.ok) {
            const data = await response.json();
            return data.success;
        }
        return false;
    }

    handleCancelModal = () => {
        this.props.resumeClaim();
        this.toggleCancelModal("CLOSE")
    }

    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState(prevState => ({ isSaveForLater: !prevState.isSaveForLater, abandonClaim: true }), () => { this.continue() });
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
    }


    continue = async (e) => {
        const props = this.props;
        this.setState({
            validationFired: true
        })

        if (this.validator.allValid()) {
            // save info 4/7/2021 SRV
            const saveInfoResult = await this.saveCollectedInfo();
            if (this.state.isSaveForLater) {
                let { entitlementDetails } = props?.history?.location?.state;
                let { party } = entitlementDetails;
                const { claimId } = props;

                party.billingAddress = this.state.billingAddress;
                party.billingAddress.addressType = 1;

                this.saveForLaterAction(party, claimId);
                return;
            }
            if (saveInfoResult === true) {
                if (props.fulfillmentTypeId === 2 || props.showHireOwnServier) {
                    props.nextActiveStep()
                    return;
                }

                var appointmentProps = {
                    claim: props.claim,
                    entitlementDetails: props.entitlementDetails,
                    selectedSymptom: props.selectedSymptom,
                    result: props.result,
                }

                var state = {
                    entitlementDetails: props.entitlementDetails
                }

                try {
                    sessionStorage.setItem("appointmentProps", JSON.stringify(appointmentProps));
                }
                catch (error) {
                    console.log(error);
                }
                this.props.history.push({
                    pathname: '/appointment',
                    appointmentProps: props,
                    preferredPhone: this.state.phone,
                    search: typeof this.state.hwaToken !== "undefined" ? `?id=${this.state.hwaToken}` : '',
                    state
                })
            }
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        let stateOptions = this.state.states;
        const billingAddress = this.state.billingAddress;

        let phoneOptions = [{
            data: "Mobile Phone",
            display: "Mobile Phone"
        },
        {
            data: "Alternate",
            display: "Alternate Phone"
        }]

        return (
            <div>
                <Container>
                    <ContractContext.Consumer>
                        {context => {
                            const { clientId } = context.data.contract;
                            const variableClientContent = getClientContent(clientId)
                            const clientPhoneNumber = variableClientContent.variableText.customerService.phone;
                            return (
                                <section className="formBody">
                                    <Label className={classNames("mb-0", cardTitle)}>
                                        {this.props?.result?.eventModel[0]?.eventTypeId === 2 ?
                                            'Are we shipping to the right place and using the best contact info?'
                                            :
                                            variableClientContent.variableVoice.verifyInfo.service
                                        }
                                    </Label>
                                    <Row className="stepsFormBlock">
                                        <Col md="6" className={styles.custDetailsBox}>
                                            <Form>
                                                <div className="text-center">
                                                    <BrandedIcon client={clientId} iconType={'customer'} />
                                                    <h2>{this.state.firstName} {this.state.lastName}</h2>
                                                    <ul className={styles.srvAddress}>
                                                        {this.props.fulfillmentTypeId === 2 ?
                                                            <li>Shipping Address</li>
                                                            : <li>Service Address</li>
                                                        }
                                                        <li>{billingAddress.address1 || ""}</li>
                                                        <li>{billingAddress.address2}</li>
                                                        <li>{billingAddress.city}, {billingAddress.state} {billingAddress.zip}</li>
                                                        {/* <li><button class="btnLink" onClick={this.toggleEdit}>Edit</button></li> */}
                                                        <li>
                                                            {'Incorrect address? Call '}
                                                            <a href={`tel:${clientPhoneNumber}`} className="callLink">
                                                                {clientPhoneNumber}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {this.state.editing ? <div className={styles.serviceAddressFields}>
                                                    <FormGroup>
                                                        <Label for="serviceAddress" className="form-label">Service Address</Label>
                                                        <span aria-hidden="true" className="required-indicator">*</span>
                                                        <Input type="text" className="form-control" id="serviceAddress" placeholder="" />
                                                        <div class="errorMsg">Service address is required.</div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="serviceAddress2" className="form-label">Apt, Suite, Building</Label>
                                                        <Input type="text" className="form-control" id="serviceAddress2" />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="serviceCity" className="form-label">City</Label>
                                                        <span aria-hidden="true" className="required-indicator">*</span>
                                                        <Input type="text" className="form-control" id="serviceCity" placeholder="" />
                                                        <div className="errorMsg">City is required.</div>
                                                    </FormGroup>
                                                    <div className="row twoColWrapper">
                                                        <Col sm="6">
                                                            <label htmlfor="serviceState" className="form-label">State</label>
                                                            <span aria-hidden="true" className="required-indicator">*</span>
                                                            {this.state.states &&
                                                                <DropDownAutoComplete
                                                                    id="serviceState"
                                                                    name="state"
                                                                    options={this.state.states}
                                                                    className="form-control"
                                                                    aria-required="true"
                                                                    onChangedValue={this.handleStateDropDown}
                                                                    isFloatingDropdown={false}
                                                                    onBlur={this.handleStateDropDown}
                                                                    defaultValue={(this.state.billingAddress.stateId === '' ? undefined : this.state.billingAddress.stateId)}
                                                                    Value={this.state.billingAddress.stateId}
                                                                />}
                                                            <div className="errorMsg">State is required.</div>
                                                        </Col>
                                                        <Col sm="6">
                                                            <Label for="servicePostal" className="form-label">Zip/Postal</Label>
                                                            <span aria-hidden="true" className="required-indicator">*</span>
                                                            <Input type="text" className="form-control" id="servicePostal" placeholder="" />
                                                            <div className="errorMsg">Zip/Postal code is required.</div>
                                                        </Col>
                                                    </div>
                                                </div> : null}
                                                {/*
                                                    <FormGroup>
                                                        <div className="input-group inputGroup">
                                                            <div className="input-group-prepend">
                                                                <label for="phonePreference" className={styles.floatingLabel}>Preferred Phone</label>
                                                                <div className={classNames("dropdown", phonePreference)}>
                                                                    <button className="dropbtn">Mobile</button>
                                                                    <div className="dropdown-content">
                                                                        <a href="#">Mobile</a>
                                                                        <a href="#">Alternate</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input type="tel" className="form-control" aria-label="preferred phone number" />
                                                        </div>
                                                        <p class="errorMsg text-left">Preferred phone is required.</p>
                                                    </FormGroup>
                                                    <FormGroup className="mb-0">
                                                        <Label for="claimEmail" className={styles.floatingLabel}>Email</Label>
                                                        <input aria-labelledby="claimEmail" className="form-control" type="text" id="claimEmail" />
                                                        <div class="errorMsg text-left">Email is required.</div>
                                                    </FormGroup>
                                                */}
                                                <FormGroup className={this.state.validationFired && !this.validator.check(this.state.phone, "required|phone|valid_phone") ? "form-error" : ""}>
                                                    <div className="input-group inputGroup">
                                                        <div className={`input-group-prepend ${styles.verifyDropContainer}`}>
                                                            <label for="phonePreference" className={styles.floatingLabel}>Preferred Phone</label>
                                                            <DropDownInput name="states"
                                                                fullWidth={true}
                                                                options={phoneOptions}
                                                                className="form-control"
                                                                onChangedValue={this.handlePhoneDropDown}
                                                                defaultValue={"Mobile Phone"}
                                                                placeholder="Please select..." />
                                                        </div>
                                                        <Cleave options={{ blocks: [0, 3, 3, 4], delimiters: ["(", ") ", "-"], numericOnly: true }} type="tel" className="form-control" autoComplete="tel" id="phone"
                                                            name="phone" value={this.state.phone} onChange={this.handleInputChange} aria-required="true" />

                                                    </div>
                                                    {this.validator.message("phone", this.state.phone, "required|phone|valid_phone")}
                                                </FormGroup>
                                                <FormGroup className={this.state.validationFired && !this.validator.check(this.state.email, "required|email") ? "form-error" : ""}>
                                                    <Label for="claimEmail" className={styles.floatingLabel}>Email</Label>
                                                    <Input className="form-control" autoComplete="email" id="emailAddress" type="email" name="email" onChange={this.handleInputChange} value={this.state.email} aria-required="true" />
                                                    {this.validator.message("email", this.state.email, "required|email")}
                                                </FormGroup>
                                                <p className={styles.mesgDiscl}>Claim status updates provided by email and SMS (when applicable).</p>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row className="stepsFormBlock m-0">
                                        <div className={styles.btnWrapper}>
                                            <button className="btn btn-primary ml-3" onClick={this.continue}>Continue</button>
                                            {!this.props.paymentSuccessful &&
                                                <button
                                                    className="btn btn-secondary"
                                                    id="cancel-id"
                                                    tabIndex="0"
                                                    aria-label="Opens a modal to cancel your claim"
                                                    color="secondary"
                                                    onClick={() => this.toggleCancelModal("OPEN")}
                                                >
                                                    {"Cancel"}
                                                </button>
                                            }
                                            <CancelModal
                                                isOpen={this.state.showCancelModal}
                                                claimNumber={this.state.claimNumber}
                                                caseNumber={this.state.caseNumber}
                                                onSaveForLaterAction={this.onSaveForLaterAction}
                                                handleCancel={this.handleCancel}
                                                onCloseModal={this.handleCancelModal}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="stepsFormBlock mt-3">
                                        <a href="#" className="btnLink" onClick={this.onSaveForLaterAction} aria-label="Opens a modal to save your claim">Save For Later</a>
                                        {this.state.showConfirmation ?
                                            <SaveModal buttonClassName={classNames("btnLink")} showConfirmation={this.state.showConfirmation} history={this.props.history} continuing={this.state.isSaveForLater}
                                                onCancel={this.onSaveCancel} onSaveForLaterAction={this.saveForLater} client={clientId} />
                                            : null}
                                    </Row>
                                </section>
                            )
                        }}
                    </ContractContext.Consumer>
                </Container>
            </div>
        );
    }
}

