import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useTranslation from '../contexts/LanguageTranslation/useTranslation';

const InvalidAddress = ({
    buttonLabel,
    className,
    showInvalidAddressModal,
    onEditAddress,
    onContinueAction
}) => {
    const international = useTranslation('components/modals/invalidAddress')
    const { translations } = international
    const [modal, setModal] = useState(showInvalidAddressModal);

    const toggle = () => setModal(!modal);

    const onClose = (e) => {
        setModal(false);
        onEditAddress();
    }

    const onContinue = (e) => {
        setModal(false);
        onContinueAction("enteredAddress");
    }
    
    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div>
            <Modal isOpen={modal} toggle={onClose} aria-modal="true" role="dialog" aria-labelledBy="invalid" className="md">
                <ModalHeader toggle={onClose} className="noLine" closeAriaLabel={translations.closeAria ||"Close Modal"}></ModalHeader>
                <ModalBody className="text-center">
                    <h4 className="modal-title">{translations.title || 'Invalid Address'}</h4>
                    <p id="invalid">
                        {translations.text || (
                            <>
                                The address you entered does not appear to be a valid postal address. 
                                <br className="show-md-up" />
                                Please update the street address or ZIP/Postal code.
                            </>
                        )}
                    </p>
                </ModalBody>
                <ModalFooter className="linkBtnLayout">
                    <div className="btnWrapper d-flex">
                        <button className="btnLink my-0 mx-0" aria-label = {translations.continueWithEnteredAddressAria || "Click to proceed to payment step"} onClick={onContinue}>{translations.continueWithEntered || 'Continue with Entered Address'}</button>{' '}
                        <button className="btn-primary" aria-label = {translations.editAddressAria || "Click to Edit address in shipping step"} onClick={onClose}>{translations.editAddress || 'Edit Address'}</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default InvalidAddress;