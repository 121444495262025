import React from 'react';
import styles from './ScriptBox.module.css';
import ChatIcon from '../../../assets/images/chat-icon.svg'

const ScriptBox = ({message}) => (
    <div className={styles.scriptBox}>
        <img className={styles.chatIcon} src={ChatIcon}></img>
        <p className={styles.msg}>{message}</p>
    </div>
)

export default ScriptBox;