import React, { useContext, useEffect, useState } from 'react';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import { Col, Row, Label, Input } from 'reactstrap';
import { ContractContext } from '../contexts/ContractContext'
import { troubleShootingMapping } from './TroubleshootingConstants'

let cardTitle = styles.cardTitle;

function Troubleshooting({ handleTroubleShootingSelected, showError, ...props }) {
    const context = useContext(ContractContext);
    const { clientId } = context.data.contract
    const [troubleShooting, setTroubleShooting] = useState([]);

    useEffect(() => {
        getQuestionSymptomMapping();
    }, [props?.questionSymptomMappingId])

    const getQuestionSymptomMapping = () => {
        const { questionSymptomMappingId } = props;
        let { symptom } = props?.symptom ?? "will not power on";
        symptom = symptom?.replace(/'|-|“|”|’|"/g, '')
            .trim().toLowerCase();

        const mappings = troubleShootingMapping?.find(x => x.symptoms?.find(y => y.replace(/'|-|“|”|’|"/g, '').trim().toLowerCase() == symptom)) ?? troubleShootingMapping?.find(x => x.questionSymptomMappingId == 0)

        if (mappings) {
            setTroubleShooting(mappings.troubleShooting);
        }
    }

    const getTroubleShootingDisplay = (display) => {
        if (display.secondaryBullet.trim() == "") {
            return (<li>{ display.primaryBullet }</li>)
        }
        else {
            return (<>
                <li>{ display.primaryBullet }</li>
                <ul><li>{ display.secondaryBullet }</li></ul>
            </>)
        }
    }

    return (
        <div id="troubleshooting">
            <form>
                <div className="form-group">
                    <Row id="troubleshootingsteps" className="stepsFormBlock">
                        <Col lg="6" className={styles.troubleshootMW}>
                            <h2 className={classNames("text-left", cardTitle)}>Please try the following before continuing:</h2>
                            <ul>
                                {
                                    troubleShooting.map((x) => getTroubleShootingDisplay(x) )
                                }
                            </ul>
                            <div className="checkbox scriptBox">
                                <Input className="mr-2" type="checkbox" id="confirmTroubleshooting" onChange={e => handleTroubleShootingSelected(e.target.checked)} />
                                <Label for="confirmTroubleshooting" className={`checkboxLabel text-left ${clientId.toLowerCase()}`}>I have completed the actions above and this did not resolve my issue.</Label>
                                {showError && <p className="errorMsg ">
                                    Please affirm you have completed the actions above.
                                </p>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>
        </div>
    )
}

export default Troubleshooting;