import React, { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import styles from './UploadDoc.module.css';
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import { ContractContext } from '../../../contexts/ContractContext';
import CloudIcon from '../../../../assets/images/cloud-upload.svg';
import SBUploadCircle from '../../../../assets/images/Vendor/SB/upload-circle.svg';
import UploadCircle from '../../../../assets/images/upload-circle.svg';

const Dropzone = (props) => {
    const {
        onParentFileUpload,
    } = props;

    const context = useContext(ContractContext);
    const clientId = context?.data?.contract?.clientId;
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: '.jpeg, .png, .pdf, .jpg',
        maxSize: 4.195e+6,
        minSize: 2,
        onDrop: acceptedFiles => {
            //setFiles(acceptedFiles.map(file => Object.assign(file, {
            //    preview: URL.createObjectURL(file)
            //})));
            console.log(acceptedFiles);
            console.log(acceptedFiles[0]);
            onParentFileUpload(acceptedFiles);
        }
    });

    const international = useTranslation('components/Vendor/Apple/UploadDocs/UploadDocsFileUpload');
    const { translations } = international;
    const contractContext = useContext(ContractContext);
    const { data: { isChileApple } } = contractContext;
    const [uploadImageSrc, setUploadImageSrc] = useState(CloudIcon);

    useEffect(() => {
        if (isChileApple) {
            setUploadImageSrc(SBUploadCircle);
        } else {
            setUploadImageSrc(CloudIcon);
        }
    }, [isChileApple])

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <section>
            <div {...getRootProps({ className: styles.dropzone })}>
                <img src={uploadImageSrc} role="presentation" className={styles.uploadImage} />
                <div className={styles.dzMessage}>
                    <input {...getInputProps()} />
                    {translations.dragAndDrop || 'Drag and Drop or'}&nbsp;
                    <a className={styles.browseLink} onClick={open} onKeyPress={open} id="reach-skip-nav" tabIndex="0" role="button">{translations.searchFiles || 'Browse Files'}</a>
                    <div className={styles.fileTypes}>{(translations.supportedFiles || 'Files Supported:') + " "}PDF, PNG, JPG, JPEG</div>
                    <div className={styles.fileSize}>{translations.maxFileSize || 'Maximum Size: 4MB'}</div>
                </div>
            </div>
            <div className={styles.mobileUpload}>
                <img
                    src={
                        clientId === "et"
                            ? UploadCircle
                            : CloudIcon
                    }
                    role="presentation"
                    className={styles.uploadImage}
                />
                <div className={styles.dzMessage}>
                    <input {...getInputProps()} />
                    Upload document from your device
                    <div className={styles.fileTypes}>{(translations.supportedFiles || 'Files Supported:') + " "}PDF, PNG, JPG, JPEG</div>
                    <div className={styles.fileSize}>{translations.maxFileSize || 'Maximum Size: 4MB'}</div>
                    <a className="btn btn-primary" onClick={open} aria-label="Click to open file explorer to select the file" onKeyPress={open} tabIndex="0" role="button">{translations.searchFiles || 'Browse Files'}</a>
                </div>
            </div>
        </section>
    );
}

export default withRouter(Dropzone);