import React, { useContext } from 'react';
import { ContractContext } from '../contexts/ContractContext';
import AppleCoverageDocs from "../../screens/Vendor/Apple/CoverageDocs";
import CoverageDocuments from './CoverageDocuments';

export const CoverageDocsController = () => {
    const context = useContext(ContractContext);
    const clientId = context.data?.clientId ?? 'aig';

    switch (clientId?.toLowerCase()) {
        case "aig":
        case "apple":
            return <AppleCoverageDocs />
        default:
            return <CoverageDocuments />
    }
}