import React from "react";
import styles from "./ExpandableTextarea.module.css";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import classNames from 'classnames';
import { FormGroup, Label } from "reactstrap";

const ExpandableTextarea = ({
  label,
  labelCustomClass,
  inputCustomClass,
  required = true,
  hasError,
  ariaLabelAndFieldId = 'description',
  validator,
  placeholder,
  value,
  maxLength = 250,
  onChange,
}) => {
  return (
    <FormGroup className={hasError && 'form-error'}> 
      <Label htmlFor={ariaLabelAndFieldId} className={classNames("form-label", labelCustomClass)}>
        {label}
      </Label>
      {required && <span aria-hidden="true" className="required-indicator">*</span>}
      <div className={styles.expandableTextarea}>
        <TextareaAutosize
          onChange={onChange}
          value={value}
          placeholder={placeholder || "Enter Description"}
          className={classNames( "form-control",  styles.formControlTextarea, inputCustomClass)}
          maxLength={maxLength}
          id={ariaLabelAndFieldId}
        />

        {/* ERROR MESSAGE AND CHARACTER COUNTER */}
        <div style={{display: 'flex', justifyContent:'space-between'}}>
          <div>
            {validator}
          </div>
          <div className={styles.charCount}>
            {value.length}/{maxLength}
          </div>
        </div>

        {/* CLEAR FIELD ENTRY BUTTON */}
        {value.length > 0 && (
          <div
            type="button"
            onClick={() => onChange({ target: { value: "" } })}
            className={styles.emptyTextArea}
          >
            <CloseIcon />
          </div>
        )}

      </div>
    </FormGroup>
  );
};

export default ExpandableTextarea;
