import React, { Component } from 'react';
import { Input, Label, Container } from 'reactstrap';
import styles from "./PlanInfo.module.css";
import classNames from 'classnames';
import { callAdobe } from '../../../helpers'
import AppleConsentPDF from '../../../assets/Documents/Apple AC TL eConsent - V1.pdf';
import BBYConsentPDF from '../../../assets/Documents/BBYAC TLeConsent - V4.pdf';


let radioGroup = styles.radioGroup;
let retailerSelection = styles.retailerSelection;


export class EConsent extends Component {

    state = {
        retailerSelected: false,
        redirectURL: '',
        isAppleSelected: false,
    }

    componentDidMount() {
        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: '/vendor/apple/econsent',
            pageName: 'eConsent'
        })
    }

    render() {
        let redirectUrl = '';
        return (
            <div>
                <div className={styles.pageHeader}>
                    <Container className={styles.pageHeaderContent}>
                        <h1>AppleCare+ with Theft and Loss eConsent</h1>
                        <p>Choose from the selection below to view the correct eConsent to conduct business electronically.</p>
                    </Container>
                </div>
                <div className={classNames("container", retailerSelection)}>
                    <Label className="cardTitle mb-0" for="radioButtonGroup">Which retailer did you purchase your plan from?</Label>
                    <div className={classNames("radioButton", "twoColWrapper", radioGroup)} id="radioButtonGroup">
                        <Input type="radio" value="Apple" id="apple" name="retailerSelect" onChange={() => {
                            this.setState({
                                retailerSelected: true,
                                isAppleSelected: true,
                            })
                            callAdobe({
                                retailer: 'Apple'
                            })
                            redirectUrl = '../../../assets/Documents/Apple AC TL eConsent - V1.pdf';
                        }
                        } />
                        <Label for="apple" className="radio-controls-label" value="Apple">Apple</Label>
                        <Input type="radio" value="BestBuy" id="bestBuy" name="retailerSelect" onChange={() => {
                            this.setState({
                                retailerSelected: true,
                                isAppleSelected: false,
                            })
                            callAdobe({
                                retailer: 'Best Buy'
                            })
                            redirectUrl = '../../../assets/Documents/BBYAC TLeConsent - V4.pdf';
                        }
                        } />
                        <Label for="bestBuy" className="radio-controls-label" value="Best Buy">Best Buy</Label>
                    </div>
                    {this.state.retailerSelected ? (< a href={this.state.isAppleSelected ? AppleConsentPDF : BBYConsentPDF} target="_blank" className="btn btn-primary" disabled={!this.state.retailerSelected} >View eConsent</a>) : (<button className="btn btn-primary" disabled={true}>View eConsent</button>)}
                </div>
            </div>
        );
    }
}