import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Label, Input, Container } from "reactstrap";
import classNames from "classnames";
import styles from "./OrderSummary.module.css";
import RemoveAddonModal from "./RemoveAddonModal";
import PromoCode from "./PromoCode";
import { SalesContext } from "../contexts/SalesContext";
import { useHistory, useLocation } from "react-router";

const OrderSummary = (props) => {
  const context = useContext(SalesContext);
  const planData = context.data.planData;

  let addOns = [
    {
      name: "Refrigerator",
      price: 150.55,
    },
  ];
  const [isShowRemoveModal, setIsShowRemoveModal] = useState(false);
  const [addOnToRemove, setAddOnToRemove] = useState({});
  const [allAddons, setAllAddons] = useState([...addOns]);
  const taxRate = 6.625 / 100;
  const [addonsPrice, setAddonsPrice] = useState(0);
  const [totalTaxPrice, setTotalTaxPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMonthly, setIsMonthly] = useState(false);
  const [promocodeObj, setPromoCodeObject] = useState(null);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const history = useHistory();

  const calculateTotals = () => {
    let addonsPriceLocal = 0;
    if (planData.selectedAddPlans.length) {
      addonsPriceLocal = planData.selectedAddPlans.reduce((accu, cur) => accu + cur.riskProfile.totalBundlePrice, 0);
    }
    addonsPriceLocal = addonsPriceLocal.toFixed(2);
    const componentsPrice = parseFloat(
      parseFloat(planData.planTotal + parseFloat(addonsPriceLocal)).toFixed(2)
    );
    let promoPrice = 0;
    let totalPrice = 0;
    if (promocodeObj) {
      promoPrice = promocodeObj.value;
      if (promocodeObj.type == "percent") {
        promoPrice = componentsPrice * (promocodeObj.value / 100);
      }
      promocodeObj.promoPrice = promoPrice.toFixed(2);
    }
    const totalTax = parseFloat(
      (
        (componentsPrice - parseFloat(promoPrice)) *
        parseFloat(taxRate)
      ).toFixed(2)
    );
    totalPrice = parseFloat(
      componentsPrice - parseFloat(promoPrice) + totalTax
    ).toFixed(2);
    setAddonsPrice(addonsPriceLocal);
    setTotalTaxPrice(totalTax);
    setTotalPrice(totalPrice);
  };

  useEffect(() => {
    calculateTotals();
  });

  const handleRemoveClick = (e, addon) => {
    e.preventDefault();
    setIsShowRemoveModal(true);
    setAddOnToRemove(addon);
  };

  const removeAddonHandler = () => {
    planData.selectedAddPlans = planData.selectedAddPlans.filter((ad, i) => addOnToRemove.title != ad.title);
    planData.activeAddPlans = planData.activeAddPlans.filter((ad) => addOnToRemove.name != ad);
    planData.additionalTotal -= addOnToRemove.riskProfile.totalBundlePrice;

    context.updatePlanData(planData);
    calculateTotals();
    setIsShowRemoveModal(false);
  };

  const hideAddonRemoveModalHandler = () => {
    setIsShowRemoveModal(false);
    setAddOnToRemove({});
  };

  const onEditPlanHandler = (event) => {
    event.preventDefault();
    if (props.saveFormData) {
      props.saveFormData();
    }
    history.push("/shopplan");
  };

  const handleApplyPromoCode = (obj) => {
    setPromoCodeObject(obj);
    setIsPromoApplied(true);
  };

  const removePromoCode = (event) => {
    event.preventDefault();
    setPromoCodeObject(null);
    setIsPromoApplied(false);
  };

  return (
    <Container className={styles.orderSummaryContainer}>
      <h2 className={classNames("h5", styles.orderSummaryHeading)}>
        Order Summary
      </h2>
      <Row>
        <Col>
          <Row className="mb-3">
            <Col className="col-9">
              {planData.selectedPlan.title}
              <a href="#" onClick={onEditPlanHandler} className="ml-2">
                Edit
              </a>
            </Col>
            <Col className={` ${styles.textAlignRight} col-3`}>
              {`$${planData.planTotal}`}
            </Col>
          </Row>

          {planData.selectedAddPlans.map((addon, index) => (
            <Row className="mb-3" key={index}>
              <Col className="col-9">
                <span>
                  {addon.title}
                  <a
                    href="#"
                    onClick={(e) => handleRemoveClick(e, addon)}
                    className="ml-2"
                  >
                    Remove
                  </a>
                </span>
              </Col>
              <Col
                className={` ${styles.textAlignRight} col-3`}
              >{`$${addon.riskProfile.totalBundlePrice}`}</Col>
            </Row>
          ))}
          {promocodeObj && (
            <Row className="mb-3">
              <Col className="col-9">
                <span>
                  {promocodeObj.name}
                  <a href="#" onClick={removePromoCode} className="ml-2">
                    Remove
                  </a>
                </span>
              </Col>
              <Col
                className={` ${styles.textAlignRight} col-3`}
              >{`-$${promocodeObj.promoPrice}`}</Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col className="col-9">
              <span>Tax</span>
            </Col>
            <Col
              className={` ${styles.textAlignRight} col-3`}
            >{`$${totalTaxPrice.toFixed(2)}`}</Col>
          </Row>
          <Row className="mb-5">
            <Col className="col-9">Total </Col>
            <Col
              className={` ${styles.textAlignRight} col-3`}
            >{`$${totalPrice}`}</Col>
          </Row>
        </Col>
      </Row>

      <PromoCode
        handleApplyPromoCode={handleApplyPromoCode}
        isPromoApplied={isPromoApplied}
      />

      <Row className={classNames("mb-3", styles.dueContainer)}>
        <Col>Due Today:</Col>
        <Col col="6">{`$${totalPrice}`}</Col>
      </Row>
      <Row>
        <Col col="6">
          <p className="mb-0">
            {isMonthly
              ? "Payments will be automatically charged to the card provided every thirty(30) " +
                "days until the subscription is canceled. Further details can be found in the"
              : "Plans will automatically renew annually, but can be canceled anytime." +
                " Further details can be found in the"}
            <a href="#" className="ml-1">
              terms and conditions.
            </a>
          </p>
        </Col>
      </Row>

      {isShowRemoveModal && (
        <RemoveAddonModal
          addon={addOnToRemove}
          handlerRemoveAddon={removeAddonHandler}
          hideModal={hideAddonRemoveModalHandler}
        />
      )}
    </Container>
  );
};

export default OrderSummary;
