import React, { useEffect, useState, useContext } from 'react';
import styles from './NeedHelp.module.css';
import getClientContent, { appleServiceNumbers } from '../../../_BrandingProvider/brandContent.jsx'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { ContractContext } from '../../../contexts/ContractContext';
import { phoneFormat } from '../../../shared/util';
import { useClickOutside } from '../../../_ReactHooks/useClickOutside';
import InternationalPhoneStatic from '../../../shared/PhoneNumberUtility/InternationalPhoneStatic';
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import CallLink from '../../../shared/PhoneNumberUtility/CallLink';

const sh = document.getElementById('recurring_true');
const showArray = [
    "/vendor/apple/entitlement",
    "/vendor/apple/verifyinfo",
    "/vendor/apple/payment",
    "/vendor/apple/uploaddocs",
    "/planoverview",
    "/fileclaim/entitlement",
    "/fileclaim/confirmation",
    "/appointment",
    "/DetailsOverview",
    "/chatend"
]

function NeedHelp() {
    const [popUp, setPopUp] = useState(false)
    const [showElementExists, setShowElement] = useState(false)
    const context = useContext(ContractContext);

    let clientId = context.data?.contract?.clientId || 'aig'
    const location = useLocation();
    const pathname = location?.pathname;
    const international = useTranslation('components/Vendor/Apple/IntakeLoss/NeedHelp');
    const { country, translations } = international;

    // Apple Requirements
    const isApple = pathname.includes('/vendor/apple') || pathname.includes('theftandloss')
    const applePhoneNumber = appleServiceNumbers.customerService[country]
    const appleEmail = 'warranty-aigservicecenter@aig.com'
    const appleFaqUrl = '/vendor/apple/faq'

    useEffect(() => {
        const exists = !!document.body.contains(document.getElementById('recurring_true'))
        setShowElement(exists)
    }, [])

    // closes popper on outside click
    useClickOutside("ContactUs", () => setPopUp(false))

    const showHelpButton = showArray.some(url => pathname.toLowerCase().includes(url.toLowerCase()))
    const contactUs = () => {
        if (!context.data && !isApple) return {  // Sound the alarms! Something has gone terribly and horribly wrong 😜
            phone: phoneFormat('XXXXXXXXXX'),
            email: 'AIGServiceCenter@aig.com'
        }
        if (isApple) return {
            phone: applePhoneNumber,
            email: appleEmail
        }
        return getClientContent(clientId).variableText.customerService
    }


    return (
        <div className={styles.helpBtnWrapper}
            id="ContactUs">
            {(showHelpButton || showElementExists) &&
                <button className={styles.helpBtn} aria-label={translations.needHelpAria || "Need Help?"} onClick={() => setPopUp(!popUp)}>
                    {translations.help || 'Help'}
                </button>
            }

            {popUp &&
                <div
                    className={styles.helpTipText}
                >
                    <h4>{translations.speakWithAgent || 'Speak to an Agent'}</h4>
                    <ul className="noBullets">
                        <li>
                            <CallLink phone={contactUs().phone} />
                        </li>
                        <li>
                            <a href={`mailto:${contactUs().email}`}>
                                {isApple ? appleEmail : contactUs().email}
                            </a>
                        </li>
                    </ul>

                    {(isApple && country.toLowerCase() === 'us') &&
                        <>
                            <h4>{translations.haveQuestions || 'Have Questions?'}</h4>
                            <Link to={isApple ? appleFaqUrl : '/faq'} target={isApple ? "_blank" : ''} aria-label={translations.viewFaqsAria || "Frequently Asked Questions opens a new details window"}>
                                {translations.viewFaqs || 'View Frequently Asked Questions'}
                            </Link>
                        </>
                    }
                    <button type="button" className={styles.close} aria-label={translations.closeAria || "Close Help Modal"} onClick={() => setPopUp(false)} />
                </div>
            }
        </div>
    );
}

export default NeedHelp;