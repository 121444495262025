import React from 'react';
import styles from './FooterButtons.module.css'
import { useMediaQuery } from 'react-responsive'
import { Spinner } from 'react-bootstrap'


/**
 * "buttons" prop below expects an object containing buttons
 * buttons: {
 *      primary: {
 *          type: 'primary' || 'secondary',
 *          text: 'STRING TO FILL BUTTON'
 *          onClick: function () => {},
 *          ariaLabel: optional STRING,
 *          hasSpinner: boolean. Primary and secondary buttons may or may not have spinners.
 *          isSpinning: boolean. If there is a spinner, this will trigger it to spin.
 *      },
 *      secondry: {...same as primary button above},
 *      link: {
 *          ...optional but same as button 1,
 *          type: will not be used here. type is always "link"
 *          Spinner not used on link buttons
 *      }           
 * }
 */

const FooterButtonsRow = ({
    isModal = false, 
    buttons,
}) => {
    const {
        primary,
        secondary,
        link
    } = buttons;
    
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

    if (isMobile) return (
        <div className={styles.footerButtonsRowWrapper}>
            {primary && <PrimaryAndSecondaryButton btn={primary} margin={primary && secondary &&'0 0 16px 0'}/>} 
            {secondary && <PrimaryAndSecondaryButton btn={secondary} />}
            {link && <LinkButton btn={link}/>}
        </div>
    )

    if (isModal) return (
        <div className={styles.footerButtonsRowWrapper}>
            <div className={`${styles.mainButtonsContainer} ${isModal && styles.modalVersion}`}>
                {secondary && <PrimaryAndSecondaryButton btn={secondary} />}
                <div className={styles.modalVersion}>
                    {link && <LinkButton btn={link}/>}
                    {primary && <PrimaryAndSecondaryButton btn={primary}/>} 
                </div>
            </div>
        </div>
    )
    
    return (
        <div className={styles.footerButtonsRowWrapper}>
            <div className={styles.mainButtonsContainer}>
                {secondary && <PrimaryAndSecondaryButton btn={secondary}/>}
                {primary && (
                    <PrimaryAndSecondaryButton 
                        btn={primary} 
                        margin={primary && secondary && '0 0 0 16px'}
                    />
                )} 
            </div>
            {link && <LinkButton btn={link}/>}
        </div>
    )
}

export default FooterButtonsRow;


////////// HELPER COMPONENTS USED ABOVE /////////////

const PrimaryAndSecondaryButton = ({
    margin,
    btn: {
        text,
        type,
        ariaLabel,
        onClick,
        hasSpinner = false,
        isSpinning,
    }
}) => (

    <button 
        className={`
            btn 
            btn-${type.toLowerCase()} 
        `}
        style={{ margin }}
        aria-label={ariaLabel}
        onClick={onClick}
    >
        {hasSpinner ? (
            <>
                <Spinner 
                    color="light" 
                    size="sm" 
                    animation={isSpinning ? "border" : ""} 
                />
                {isSpinning ? "" : text}
            </>
        ) : (
            text
        )}
    </button>
)



const LinkButton = ({
    btn: {
        text,
        onClick,
        ariaLabel,
    }
}) => (
    <button 
        className={'btn btnLink'}
        aria-label={ariaLabel}
        onClick={onClick}
    >
        {text}
    </button>
)