import React from 'react'
import { Input, Label } from 'reactstrap';
import styles from './CheckboxInput.module.css'
import classNames from 'classnames';

const CheckboxInput = ({
    checked,
    handleCheck,
    clientId = "",
    id = "",
    children,
    customClass = ""            // INSERT THE CHECKBOX "STATEMENT" OR "TEXT" INSIDE THE COMPONENT ON IMPLEMENTATION IN PARENT.
}) => (
    <div className={`${styles.checkbox} ${customClass}`}>
        <Input
            className="mr-2"
            type="checkbox"
            id={id}
            onChange={handleCheck}
            checked={checked}
        />
        <Label htmlFor={id} className={classNames(styles.checkboxLabel, styles[clientId?.toLowerCase()])}>
            <span>
                {children}
            </span>
        </Label>
    </div>
)

export default CheckboxInput;