import React from 'react';
import styles from './VerifyInfo.module.css';
import { Row } from 'reactstrap';
import { ContractContext } from '../contexts/ContractContext';
import classNames from 'classnames';
import getClientContent from '../_BrandingProvider/brandContent.jsx'
import { useLocation } from 'react-router-dom';

const StepsHeader = ({ active, children, fulfillmentTypeId }) => {
    const { state } = useLocation();
    return (<ContractContext.Consumer>
        {context => {
            const {
                contract,
                contract: {
                    clientId
                }
            } = context.data;

            const deductible = parseInt(state?.entitlementDetails?.deductible ?? 0);
            return (
                <>
                    <div className={styles.pageHeader}>
                        <Row className="progressBar">
                            <div className={`progressStep ${active === 1 && 'active'} ${active > 1 && 'completed'}`}>
                                <span className="pageTxt">Incident</span>
                                <div>
                                    <span className="circle">
                                        <span className="circlePoint"></span>
                                    </span>
                                </div>
                            </div>
                            {fulfillmentTypeId !== 3 && deductible > 0 && 
                                <div className={`progressStep ${active === 2 && 'active'} ${active > 2 && 'completed'}`}>
                                    <span className="pageTxt">Payment</span>
                                    <div>
                                        <span className="circle">
                                            <span className="circlePoint">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            }
                            <div className={`progressStep ${active === 3 && 'active'} ${active > 3 && 'completed'}`}>
                                <span className="pageTxt">Solution</span>
                                <div>
                                    <span className="circle last">
                                        <span className="circlePoint">
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </Row>
                        {active === 1 && <h1 className={classNames("h1", styles.pageTitle)}>Tell Us What Happened</h1>}
                        {active === 2 &&
                            <h1 className={classNames("h1", styles.pageTitle)}>
                                {`Pay Your ${getClientContent(clientId).variableText.planDetails.paymentType}`}
                            </h1>
                        }
                        {active === 3 && (
                            fulfillmentTypeId === 3 ? <h1 className={classNames("h1", styles.pageTitle)}>Hire Your Own Servicer</h1> :
                                fulfillmentTypeId === 2 ? <h1 className={classNames("h1", styles.pageTitle)}>Let's Talk Replacement</h1> :
                                    <h1 className={classNames("h1", styles.pageTitle)}>Let's Schedule Service {fulfillmentTypeId}</h1>
                        )}

                    </div>
                    <section>
                        {children}
                    </section>
                </>
            )
        }}
    </ContractContext.Consumer>
)}


export default StepsHeader;