import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as AIGLogo } from '../../../assets/images/aig-logo_w.svg';
import styles from './ETHeader.module.css';
import { Nav, NavItem, NavLink, NavbarToggler, Collapse, Modal, ModalBody, Container, Navbar } from 'reactstrap';
import classnames from 'classnames';
import CloseMobile from '../../../assets/images/close-mobile.svg';
import ETLogo from '../../../assets/images/Vendor/ET/ET-logo.jpg';

export const ETHeader = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [redirectUri, setRedirectUri] = useState('');
    const [logoRedirectUri, setLogoRedirectUri] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        async function fetchUri() {
            const res = await fetch('vendor/GetHwaRedirectUri');
            const data = await res.json();
            setRedirectUri('/');
            setLogoRedirectUri('/planoverview?id=ET')
        }
        fetchUri();
    }, []);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        redirectFromHeadAction(tab);
    }

    const toggleClose = () => setIsOpen(!isOpen);

    const redirectFromHeadAction = (actionId) => {
        var route = '';
        switch (actionId) {
            case '1': {
                route = 'faq';
                break;
            }
            case '2': {
                route = 'coveragedocs';
                break;
            }
            case '3': {
                route = 'info/contact-us';
                break;
            }
        }
        const link = `${redirectUri}${route}`;
        window.open(link, '_blank')
    }

    const handleLogoClick = (event) => {
        event.preventDefault();
        props.onLogoClickHandler(event)
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    };

    // HELPER TO WRAP AND THUS ONLY HAVE TO MAINTAIN ONE MENU INSTEAD OF TWO
    const ConditionalNavWrapper = ({ children }) => (
        isOpen ? (
            <Modal isOpen={true} toggle={toggle} className={`md header-content et-header-content ${styles.navmenu}`} aria-modal="true" role="alertdialog" aria-describedby="suggest" aria-labelledby="modalHeading">
                <h2 id="modalHeading" className={styles.hidden}>Navigation Menu</h2>
                <a onClick={toggleClose}>
                    <img tabIndex={0} className={`logoClose ${styles.closeButtonTop}`} src={CloseMobile} alt="Close" />
                </a>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>
        ) : (
            <Collapse navbar>
                {children}
            </Collapse>
        )
    )

    return (
        <header>
            <div className={styles.HeaderBorder}>
                <div className={classnames(styles.HeaderLg, 'container', 'px-0')}>
                    <div className={styles.LogoContainer}>
                        <a onClick={(event) => handleLogoClick(event)}>
                            <img className={styles.Logo} src={ETLogo} title="Energy Texas" />
                        </a>
                    </div>
                    <div className={styles.HeaderRight}>
                        <span>Provided by</span>
                        <div className={styles.aigLogo}>
                            <AIGLogo />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classnames(styles.navBarContainer, 'container', 'px-0')}>
                <div className={classnames(styles.navContainer)}>
                    <div className={styles.mobileLogo}>
                        <a href={logoRedirectUri}>
                            <img className={styles.Logo} src={ETLogo} title="Energy Texas" />
                        </a>
                    </div>
                    <Navbar light expand="md" role="navigation" className={styles.mobileNav}>
                        <span className={styles.providedBy}>Provided by AIG</span>&nbsp;
                        <NavbarToggler aria-label="On click opens a navigation menu" onClick={toggleMenu} />
                        <ConditionalNavWrapper>
                            <Nav className={classnames('ml-auto', styles.navTabs)} navbar>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                        FAQs
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                        Coverage Documents
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </ConditionalNavWrapper>
                    </Navbar>
                </div>
            </div>
        </header>
    );
}