import React, { Component } from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import styles from '../Vendor/Apple/UploadDocs/UploadDoc.module.css';
import classNames from 'classnames';
import { Progress } from 'reactstrap';
import { UseTranslationInClassComponent } from '../../components/contexts/LanguageTranslation/UseTranslationInClassComponent';
import PaperIcon from '../../assets/images/paper.svg';

export class UploadProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdatebtnDisable: true
        }

    }


    componentDidMount() {
        const fileData = this.props.fileData;
        if (fileData) {
            const currentFile = fileData;

            this.setState(state => ({ fileName: currentFile.name }));
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState(state => ({ selectedDoc: reader.result }));
                this.setState({ isUpdatebtnDisable: false });
                this.props.setButtonEnable()
            }, false)

            reader.addEventListener("abort", (ev) => {
                console.log("file aborted", ev);
            }, false)

            reader.addEventListener("error", (ev) => {
                console.log("file error", ev);
            }, false)

            reader.addEventListener("progress", (event) => {
                console.log("total" + event.total);
                console.log("progress" + event.loaded);
                this.setState({
                    fileUploadedPercentage: Math.round((event.total / event.loaded) * 100)
                });
            })

            reader.readAsDataURL(currentFile);
            this.setState({ invalidDoc: false });
        }
        else {
            this.setState({ invalidDoc: true });
        }
    }



    render() {
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'Components/Vendor/Apple/UploadDocs/UploadDocsFileUpload'}>
                { international => {
                    const {
                        translations,
                    } = international;
                    return (
                        <Container className="pl-md-0">
                            <section id="uploadDocsWrapper">
                                {!this.state.isUpdatebtnDisable && <div className={styles.uploadProgress}>
                                    <img className={styles.file} src={PaperIcon} role="presentation" className={styles.file} />
                                    <div className={styles.progressWrapper}>
                                        <div className={styles.fileName}>{this.state.fileName}</div>
                                        <div class={styles.uploadProgressWrapper}>
                                            <Progress className={styles.progress} value={this.state.fileUploadedPercentage} />
                                            <button onClick={() => this.props.onRemoveFile(this.props.fileData)} aria-label="Cancel Uploaded File" className={styles.closeButton}></button>
                                        </div>
                                        <div aria-live="assertive" className={styles.uploadPercentage}>
                                            {translations.progress1 || 'Uploading'} 
                                            <span className='pl-1'>
                                                {this.state.fileUploadedPercentage}% {translations.progress2 || 'completed'}
                                            </span>
                                        </div>
                                    </div>
                                </div>}

                                {this.state.invalidDoc &&
                                    <p className="validationSummary">The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)</p>
                                }
                            </section>
                        </Container>
                    );
                }}
            </UseTranslationInClassComponent>
        );
    }
}