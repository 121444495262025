/**
 * THIS FILE IS FOR HELPER FUNCTIONS THAT CAN BE USED REPETETIVELY THROUGHOUT THE APP
 * FOR MORE CONSISITENT UI. 
 */
 import { countryConstants } from "../contexts/LanguageTranslation/CountryConstants";


// Return random 5-7 digit key for use in mapping/keys/ids.
// IMPORTANT: Use for UI only, not for anything that needs to be secure. 
// Not "truly" random enough for security purposes.
export const randomized = () => Math.random().toString(36).substring(7);




 // takes ANY numeric string and returns a phone number formatted the correct way.
 // CORRECT: (123) 456-7890
 // INCORRECT: 848-484-4848, 939 393 9393, (848)484-4848, 2828282828
export function phoneFormat(phone) {
    //normalize string and remove all unnecessary characters
    const cleaned = phone.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (cleaned.length == 10) {
        //reformat and return phone number
        return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }

    return phone;
}

// STRIP phone number is primarily for 
export function stripPhoneNumber (phone, country = 'US') {
    const stripped = phone?.replace(/[^\d]/g, "")
    if (country !== 'US') {
        return `${countryConstants[country].phone.countryCode}${stripped}`
    }
    return stripped;
}