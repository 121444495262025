import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { ContractContext } from '../contexts/ContractContext';
import LanguageToggle from '../Vendor/Apple/LanguageToggle';
import { useEnvironment } from '../_ReactHooks/useEnvironment';
import styles from './WhiteLabeling.module.css';

const DevelopmentBrandToggler = () => {
    const { updateClientId, setIsChileApple } = useContext(ContractContext);
    const { isDevOrLower, isDevelopment, isStagingOrLower } = useEnvironment();

    const toggleBrand = (brand) => {
        if (brand === 'southbridge') {
            setIsChileApple(true);
            updateClientId('apple')
        } else {
            setIsChileApple(false);
            updateClientId(brand);
        }
    }

  return (
      <div className={styles.footerAlign}>
          <div className={styles.footerAlign}>
              Select Brand to Test:
              <Button className="btn-primary ml-4" onClick={() => toggleBrand('et')}>ET</Button>
              <Button className="btn-primary ml-4" onClick={() => toggleBrand('aig')}>AIG</Button>
              <Button className="btn-primary ml-4" onClick={() => toggleBrand('hwa')}>HWA</Button>
              <Button className="btn-primary ml-4" onClick={() => toggleBrand('southbridge')}>SB</Button>
          </div>
          <LanguageToggle isForDev={isStagingOrLower} />
      </div>
  )
}

export default DevelopmentBrandToggler;