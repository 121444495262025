import React from 'react';


export const AIGCircleBadge = ({ status }) => {
    let customClassName;
    switch (status) {
        case 'Paid':
        case 'Service In Progress':
            customClassName = 'success';
            break;
        case 'Exception':
        case 'Service Completed':
        case 'Closed':
        case 'Cancelled':
        case 'Canceled':
        case 'Suspended':
        case 'Expired':
        case "Fulfilled":
            customClassName = 'danger';
            break;
        case 'Audit':
        case 'Saved':
        case 'Wait':
        case 'Waiting':
            customClassName = 'warning';
            break;
        default:
            customClassName = 'success';
            break;

    }
    return (
        <>
            <span><span className={`circleBadge ${customClassName}`}></span>{status}</span>
        </>
    );
}