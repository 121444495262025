import React, { useState, useEffect } from 'react';
import styles from './FileClaim.module.css';
import { Card, Row, Col } from 'reactstrap';
import AssetList from './Asset/AssetList';
import AssetListIcon from './Asset/AssetListIcon';
import classNames from 'classnames';
import { useLocation } from 'react-router';

const FileClaim = (props) => {
    const [activeTab, setActiveTab] = useState(1);
    const [appliances, setAppliances] = useState([]);
    const [plumbing, setPlumbing] = useState([]);
    const [electric, setElectric] = useState([]);
    const [heatingAir, setHeatingAir] = useState([]);
    const [pool, setPool] = useState([]);
    const [indooroutdoor, setIndooroutdoor] = useState([]);
    const [updateCategories, setUpdateCategories] = useState(false);
    const location = useLocation();
    const [activeAssetCategory,] = useState(location?.state?.activeAssetCategory)

    const toggle = tab => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }
    const toggleOnEnterKey = (tab, event) => {
        if (event.keyCode == 13) {
            toggle(tab);
        }
    }
    const isActive = (tab) => {
        let active = styles.active;
        return tab === activeTab ? active : '';
    }

    //let navLink = styles['nav-link'];
    let navTabs = styles.navTabs;
    let noGutters = "no-gutters";
    let navItem = styles.navItem;
    let navLink = styles.navLink;

    let categories = [
        'Appliances',
        'Electric',
        'Heating & AC',
        'Plumbing',
        'Pool & Spa',
        'Indoor/Outdoor'
    ];

    const returnPlanAssets = (contract) => {
        return contract.assets;
    }

    //let assets = props.contract.assets;
    let assetCollection = props.contracts?.filter(c => c.partyId === props.party?.partyId)?.map(returnPlanAssets);
    let assets = [].concat.apply([], assetCollection)

    let contractAssets = props.contract?.contractAssetModel?.filter(de => de.assetId == 376);

    const categoryMap = () => {
        setAppliances(assets.filter(a => a.assetDisplayCategory == null ? null : a.assetDisplayCategory.toLowerCase() === "appliances"));
        setPlumbing(assets.filter(p => p.assetDisplayCategory == null ? null : p.assetDisplayCategory.toLowerCase() === "plumbing"));
        setElectric(assets.filter(e => e.assetDisplayCategory == null ? null : e.assetDisplayCategory.toLowerCase() === "electrical"));
        setHeatingAir(assets.filter(ha => ha.assetDisplayCategory == null ? null : ha.assetDisplayCategory.replace(/\s/g, '').toLowerCase() === "heatingandair"));
        setPool(assets.filter(po => po.assetDisplayCategory == null ? null : po.assetDisplayCategory.toLowerCase() === "pool"));
        setIndooroutdoor(assets.filter(ind => ind.assetDisplayCategory == null ? null : ind.assetDisplayCategory.toLowerCase() === "indooroutdoor"));
        setUpdateCategories(true);
    }



    const mapCategories = (cat) => {
        switch (cat) {
            case 'Appliances':
                return appliances;
            case 'Electric':
            case 'Electrical':
                return electric;
            case 'HeatingAndAir':
                return heatingAir;
            case 'Heating & AC':
            case 'HeatingAndAir':
                return heatingAir;
            case 'Plumbing':
                return plumbing;
            case 'Pool & Spa':
                return pool;
            case 'Indoor/Outdoor':
            case 'IndoorOutdoor':
                return indooroutdoor;
            default:
                return [];
        }
    }

    useEffect(() => {
        categoryMap()
    }, [])

    useEffect(() => {
        for (let i = 0; i < categories.length; i++) {
            if (mapCategories(categories[i])?.length) {
                setActiveTab(i + 1);
                break;
            }
        }
        if (activeAssetCategory === "HeatingAndAir") {
            setActiveTab(3)
        }
    }, [updateCategories, activeAssetCategory])

    return (
        <Card className={styles.fileClaim}>
            <Row className="no-gutters">
                <Col lg="4" className={styles.staticTab}>
                    <div className={styles.vaCenter}>
                        <h2 className="h2 mb-0" tabIndex="0">File A Claim</h2>
                        <p>Select a category to get started</p>
                    </div>
                </Col>
                <Col>
                    <ul className={classNames(noGutters, navTabs)}>
                        {categories.map((cat, index) => {

                            if (!!mapCategories(cat)?.length) {
                                return (

                                    <li className={`${styles.navItem} ${styles.navItemET}`} onKeyDown={(e) => { toggleOnEnterKey(index + 1, e); }} key={index}>
                                        <a
                                            className={classNames(navLink, isActive(index + 1))}
                                            onClick={() => { toggle(index + 1); }}
                                        >
                                            <AssetListIcon
                                                categoryName={cat}
                                                className={styles.tabIcon}
                                                activeTab={activeTab}
                                            />
                                            {cat}
                                        </a>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </Col>
                {/* asset list */}
            </Row>
            <ul className={styles.assetList}>
                {!!appliances?.length && <AssetList tabId={1} activeTab={activeTab} contracts={ props.contracts } contract={props.contracts.find(c => appliances?.some(ap => c.assets.includes(ap)))} assets={appliances} showModelSerial={true} />}
                {!!electric?.length && <AssetList tabId={2} activeTab={activeTab} contracts={props.contracts} contract={props.contracts.find(c => electric?.some(ap => c.assets.includes(ap)))} assets={electric} showModelSerial={false} />}
                {!!heatingAir?.length && <AssetList tabId={3} activeTab={activeTab} contracts={props.contracts} contract={props.contracts.find(c => heatingAir?.some(ap => c.assets.includes(ap)))} assets={heatingAir} showModelSerial={false} />}
                {!!plumbing?.length && <AssetList tabId={4} activeTab={activeTab} contracts={props.contracts} contract={props.contracts.find(c => plumbing?.some(ap => c.assets.includes(ap)))} assets={plumbing} showModelSerial={false} />}
                {!!pool?.length && <AssetList tabId={5} activeTab={activeTab} contracts={props.contracts} contract={props.contracts.find(c => pool?.some(ap => c.assets.includes(ap)))} assets={pool} showModelSerial={false} />}
                {!!indooroutdoor?.length && <AssetList tabId={6} activeTab={activeTab} contracts={props.contracts} contract={props.contracts.find(c => indooroutdoor?.some(ap => c.assets.includes(ap)))} assets={indooroutdoor} showModelSerial={false} />}
            </ul>
        </Card>
    );
}

export default FileClaim;