import React, { Component } from "react";
import styles from "../../../../screens/Vendor/Apple/CoverageDocs.module.css";
import DocumentTitle from 'react-document-title';
import AppleCarePlusPDF from '../../../../assets/Documents/AppleCarePlus.pdf';

let form = styles.form
class PlanType extends Component {
    state = {
        stateName: '',
        paymentType: '',
        pageTitle: 'Program Coverage Documents | AIG AppleCare+ with Theft and Loss Claims'
    }

    componentDidMount() {
        console.log('hello', this.props.location.state);
        const { location: { state = {} } = {} } = this.props;
        this.setState({
            stateName: state.stateName
        })
    }

    handleKeyDown = (e) => {
        console.log('e', e)
        if (e.key === 'Enter') {
            e.preventDefault();
            let { name, value, id } = e.target;

            let checked = false;

            switch (id) {
                case "reach-skip-nav":
                    checked = document.getElementById('singlePayment').checked;
                    break;
                case "pay2Div":
                    checked = document.getElementById('payMonthly').checked;
                    break;
                default:
                    checked = e.target.checked;
            }
            if (checked === false) {
                switch (id) {
                    case "reach-skip-nav":
                        value = "singlePayment";
                        break;
                    case "pay2Div":
                        value = "payMonthly";
                        break;

                }
            } else {
                value = null;
            }

            this.setState({
                paymentType: value,
                validated: false,
            });
        }
    }
    selectionHandler = (event) => {
        this.props.history.push("/vendor/apple/coveragedocs");
    };

    render() {
        const { pageTitle } = this.state;
        console.log(this.props);
        return (
            <DocumentTitle title={pageTitle} >
                <div>
                    <div className={styles.pageHeader}>
                        <h1 className="h3">{this.state.stateName || ''} Coverage Documents</h1>
                        <p>Select the type of plan purchased to view the correct document.</p>
                    </div>
                    <form className={styles.planSelForm}>
                        <div className={styles.form}>
                            <label className="form-label-radio" aria-label="" for="radioButtonGroup" >
                                Which type of plan did you purchase?
            </label>
                            <br />
                            {console.log('hi pay', this.state.paymentType)}
                            <div className="twoColWrapper" role="group" id="radioButtonGroup">
                                <div className="radioButton programRadio" role="radio" id="reach-skip-nav" tabIndex="0" aria-checked={this.state.paymentType === "singlePayment" ? "true" : "false"} onKeyPress={this.handleKeyDown}>
                                    <input type="radio" name="paymentSelection" id="singlePayment" tabIndex="0" value="singlePayment" checked={this.state.paymentType === "singlePayment" ? true : false
                                    } onClick={() => this.setState({ paymentType: "singlePayment" })} />
                                    <label className="radio-controls-label" for="singlePayment">
                                        Single Pay
                    </label>
                                </div>
                                <div className="radioButton programRadio" id="pay2Div" role="radio" tabIndex="0" aria-checked={this.state.paymentType === "payMonthly" ? "true" : "false"} onKeyPress={this.handleKeyDown}>
                                    <input type="radio" name="paymentSelection" tabIndex="0" id="payMonthly" value="payMonthly" checked={this.state.paymentType === "payMonthly" ? true : false} onClick={() => this.setState({ paymentType: "payMonthly" })} />
                                    <label className="radio-controls-label" for="payMonthly">
                                        Pay Monthly
                    </label>
                                </div>
                            </div>
                            <a className={`btn btn-primary ${!this.state.paymentType ? 'disabled' : ''}`} href={AppleCarePlusPDF} aria-label="On click opens a new window to view program coverage documents" target="_blank">View Policy</a>
                            <button className="btnLink mt-4" aria-label="On selecting takes you coverage documents page to choose different state" onClick={this.selectionHandler}>
                                Select Different State
            </button>
                        </div>
                    </form>
                </div>
            </DocumentTitle>
        );
    }
}
export default PlanType;
