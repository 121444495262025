import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

const useIsApple = () => {
    const [isApple, setIsApple] = useState(false)

    const validAppleOrigins = [
        'https://aigtheftandloss.com',
        'https://aigtheftandloss.com.au',
        'https://aigtheftandloss.uk',
        'https://aigtheftandloss.de',
        'https://qa.aigtheftandloss.com',
        'https://qa.aigtheftandloss.com.au',
        'https://qa.aigtheftandloss.uk',
        'https://qa.aigtheftandloss.de',
    ]

    useEffect(() => {
        const currentPathIsValidAppleDomain = validAppleOrigins.indexOf(window.location.origin) > -1
        // look by path first(this will fire on local pages as well)
        if (
            window.location.pathname.toLowerCase().includes("apple") ||
            window.location.pathname.toLowerCase().includes("file/entitlement") ||
            window.location.pathname.toLowerCase().includes("file/payment") ||
            window.location.pathname.toLowerCase().includes("file/VerifyInfo") ||
            window.location.pathname.toLowerCase().includes("programcoveragedocs") ||
            window.location.pathname.toLowerCase().includes("econsent") ||
            window.location.pathname.toLowerCase().includes("programsummarydisclosure") ||
            window.location.href.toLowerCase().includes("aigtheftandloss")
        ) {
            setIsApple(true)
        }

        // double check based on domain
        if (currentPathIsValidAppleDomain) {
            setIsApple(true)
        }
    }, []);

    return isApple
}

export default useIsApple
