import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

//HELPER COMPONENT  
const ConditionalFormGroupWrapper = ({ hasError=false, wrap, children}) => {
    return (
        wrap ? (
            <FormGroup className={hasError && "form-error"}>
                {children}
            </FormGroup>
        ) : (
            <>{children}</>
        )
    )
}

const StandardInput = ({
    label,    
    labelCustomClass = '',              // IF CUSTOM STYLES REQUIRED ON LABEL COMPONENT
    hasError,                           // PASS CONDITION TO ADD 'FORM ERROR' TO FORMGROUP                         
    fieldName,                          // FOR ARIA AND NAME ATTR ON INPUT
    notRequired = false,                 // BOOLEAN -- PASS 'FALSE' TO TURN OFF ASTERISK
    htmlInputType = 'text',             // WHEN SPECIFIC "TYPE" ATTR NEEDED E.G. "tel" or "email"
    customClass,                        // IF CUSTOM STYLING REQUIRED, WILL MODIFY NORMAL STYLES
    autoComplete,                       // STRING, OPTIONAL
    ariaLabelAndFieldId = '',           // IF HTML ID REQUIRED
    value,                              // VALUE FROM PARENT
    onChange,                           // CHANGE HANDLER FROM PARENT
    validator,                          // --- IMPORTANT --- PASS ENTIRE VALIDATOR REQUIREMENT (validator.current.message(...args))
    excludeFormGroupWrapper = false,    // BY DEFAULT WRAPS IN FORMGROUP COMPONENT. TURN ---OFF--- IF NESTED IN COL FOR LAYOUT PURPOSES, ETC 
    placeholder = '',    
    maxLength = 25,                     // NUMBER       
}) =>{   

    
    return (
        <ConditionalFormGroupWrapper wrap={!excludeFormGroupWrapper} hasError={hasError}>
            <Label htmlFor={ariaLabelAndFieldId} className={`form-label ${labelCustomClass}`}>
                {label}
            </Label>
            {!notRequired && <span aria-hidden="true" className="required-indicator">*</span>}
            <Input
                type={htmlInputType}
                className={`form-control ${customClass}`}
                autoComplete={autoComplete && autoComplete}
                id={ariaLabelAndFieldId}
                name={fieldName}
                value={value}
                onChange={e => onChange(e)}
                maxLength = {maxLength}
                aria-required={!notRequired && "true"}
                placeholder={placeholder}
            />
            {validator}
        </ConditionalFormGroupWrapper>
    )   
}

export default StandardInput;