import React, { Component } from 'react';
import {
    Container, Row, Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

class RedirectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: typeof this.props.match.params.token == "undefined" || this.props.match.params.token == null ? null : this.props.match.params.token,
            clientId: typeof this.props.match.params.clientId == "undefined" || this.props.match.params.clientId == null ? null : this.props.match.params.clientId
        }
    }

    componentDidMount() {

        // get the posted value
        //console.log("Pay now: " + this.state.token);
        fetch('api/claim/ClaimNumberSearch/' + this.state.token + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                console.log(data);
                //this.setState({
                //    caseModel: data,
                //    contract: data.contractAsset,
                //    caseNumber: data.caseId,
                //    isRecurring: false,
                //    rendering: false
                //});

                this.props.history.push('/vendor/apple/payment', {
                    entitlementDetails: {
                        clientId: this.state.clientId,
                        caseNumber: data.caseNumber,
                        partyId: data.party.partyId,
                        partyStatusId: data.party.partyStatusId,
                        firstName: data.party.firstName,
                        lastName: data.party.lastName,
                        partyName: data.party.firstName + " " + data.party.lastName,
                        partyAddressModel: data.party.partyAddressModel,
                        partyPhoneModel: data.party.partyPhoneModel,
                        partyEmailModel: data.party.partyEmailModel,
                        contract: data.contract,
                        contractAsset: data.contractAsset,
                        claimId: data.claim.claimId,
                        claimNumber: data.claim.claimNumber,
                        party: data.party,
                        deductible: data.deductible,
                        incidentDescription: data.claim.incidentDescription,
                        incidentDate: data.claim.incidentDate === undefined ? data.claim.lossDate : data.claim.incidentDate,
                        claimSetupDate: data.claim.claimSetupDate,
                        coverageId: data.coverageId,
                        mailingAddress: data.party.billingAddress.address1,
                        mailingAddress2: data.party.billingAddress.address2,
                        city: data.party.billingAddress.city,
                        state: data.party.billingAddress.state,
                        stateId: data.party.billingAddress.stateId,
                        zip: data.party.billingAddress.zip,
                        contactPhone: data.party.partyPhoneModel.find(pp => pp.phoneType = 2).number,
                        email: data.party.partyEmailModel[0].address,
                        countryCode: data.countryCode ?? "us",
                        shipToCountryCode: data.shipToCountryCode
                    }, claim: data.claim
                });

                // Redirect to Payment page
            }, (error) => {
                console.log(error);
                this.props.history.push('/vendor/apple/', {});

                // Redirect to landing page
                //this.setState({
                //    isRecurring: true,
                //    rendering: false
                //});
            });
    }

    render() {
        //if (this.state.rendering) {
        //    return <div />
        //}
        //const { pageTitle } = this.state;
        //console.log(this.state);
        //console.log('my carrier', this.state.carrier);
        //let caseModel = this.state.caseModel;
        return <Container className="pageLoading">
            <div className="centeredXY text-center">
                <Spinner animation="border" variant="primary" role="status" />
                <p>Loading...</p>
            </div>
        </Container>
    }
}

export default RedirectComponent;