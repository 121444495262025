import React, { useState } from 'react';
import { Card, CardHeader, Collapse, CardBody } from 'reactstrap'
import { ReactComponent as Chevron } from '../../../assets/images/arrow-cheveron-down.svg';
import styles from './Accordion.module.css'
import classNames from 'classnames'


const Accordion = ({
    id, 
    question, 
    answer,      // ANSWER MAY BE A SINGLE STRING, OR MAY BE AN ARRAY OF STRINGS, WHERE EACH NEW STRING REPRESENTS A NEW PARAGRAPH
    type = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const renderBody = (ans) => {
        if (typeof ans === 'string') {
            return <p>{ans}</p>
        }else {
            return ans.map( a => <p>{a}</p>)
        }
    }

    return (
        <Card className={styles.questionCard}>
            <CardHeader className={`rounded-0 ${styles.cardHeader}`}
                onClick={()=>setIsOpen(!isOpen)}
                name="general0"

            >
                <div className={styles.accordionFaq} title="opens a accordian" role="button" id="Q1">

                    <div className={styles.question}>
                        <p>{id}</p>
                        <p>{question}</p>
                    </div>
                    <Chevron className={classNames(styles.arrow, (isOpen ? styles.active : styles.closed))}/>
                </div>
            </CardHeader>
            <Collapse isOpen={isOpen} aria-labelledby="Q1" role="region">
                <CardBody className={styles.answer}>
                    {/* HANDLE EITHER STRING OR ARRAY OF STRINGS AS ANSWER  */}

                    {type === 'component' ?  answer : renderBody(answer)}
                    
                </CardBody>
            </Collapse>
        </Card>
    )
}

export default Accordion;