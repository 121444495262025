import React, { useState, useContext } from 'react';
import styles from './NeedHelp.module.css';
import getClientContent from '../../_BrandingProvider/brandContent'
import { ContractContext } from '../../../components/contexts/ContractContext';


const NeedHelp = () => {
    const [popUp, setPopUp] = useState(false);
    const { data: { clientId } } = useContext(ContractContext);

    const open = e => {
        setPopUp(!popUp);
    }

    const close = e => {
        setPopUp(false);
    }

    const [modal, setModal] = useState(false);

    const toggle = (e) => {
        e.preventDefault();
        setModal(!modal)
    }

    return (
        <div className={styles.helpBtnWrapper}>
            <button className={styles.helpBtn} aria-label="Need Help?" onClick={open}>Help</button>
            {popUp &&
                <div className={styles.helpTipText}>
                    <h1 className={`h4 mb-0 ${styles.helpHeading}`} >Speak to an Agent</h1>
                    <div className='mb-3'>
                        <div>
                            <a href={`tel:${getClientContent(clientId).variableText.customerService.phone}`} className='callLink pl-0'>
                                {getClientContent(clientId).variableText.customerService.phone}
                            </a>
                        </div>
                        <div>
                            <a href={`mailto:${getClientContent(clientId).variableText.customerService.email}`} className='callLink pl-0'>
                                {getClientContent(clientId).variableText.customerService.email}
                            </a>
                        </div>
                    </div>
                    <h1 className={`h4 mb-0 ${styles.helpHeading}`}>Have Questions?</h1>
                    <a href="/faq" target="_blank" className={styles.faqLink}>View Frequently Asked Questions</a>
                    <button type="button" className={styles.close} aria-label="Close" onClick={close}></button>
                </div>
            }
        </div>
    );
}

export default NeedHelp;