import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Row, Col, Form, FormGroup, Label, Input, Container } from "reactstrap";
import styles from "./CustomerInfo.module.css";
import { DropDownAutoComplete } from "../inputs/DropDownAutoComplete";
import SimpleReactValidator from "simple-react-validator";
import { useHistory, useLocation } from "react-router";
import Cleave from "cleave.js/react";
import { ContractContext } from "../contexts/ContractContext";
import { SalesContext } from "../contexts/SalesContext";
import SaveModal from "../modals/SaveModal";
import classNames from "classnames";
import InfoTooltip from "../shared/InfoTooltip/InfoTooltip";
import OrderSummary from "./OrderSummary";
import RecommendedAddressModal from "./RecommendedAddressModal";
import IncorrectAddressModal from "./IncorrectAddressModal";

let btnPrimary = "btn-primary";
let btn = "btn";
let btnLink = "btnLink";
let btnsecondary = "btn-secondary";

const CustomerInfo = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    serviceAddress: {
      address1: "",
      address2: "",
      city: "",
      state: "NJ",
      zip: "",
      stateId: 39,
    },
    mailingAddress: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      stateId: "",
    },
    doNotSolicit: null,
    termsAndCondition: false,
    serviceAsMailingAddress: true
  });

  const [states, setStates] = useState([]);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const contractContext = useContext(ContractContext);
  const context = useContext(SalesContext);
  const clientId = contractContext?.data?.clientId || "aig";
  const [, forceUpdate] = useState();
  const [
    isOpenAddressValidationModal,
    setIsOpenAddressValidationModal,
  ] = useState(false);
  const [isOpenUnableAddressModal, setIsOpenUnableAddressModal] = useState(
    false
  );
  const [recommendedAddress, setRecommendedAddress] = useState({});
  const [showSaveModal, setShowSaveModal] = useState(false);
  const handleBackbutton = () => {
    context.updateFormData(formData);
    history.push("/shopplan");
  };

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        phone: "Please enter a valid phone number.",
        email: "Please enter a valid email.",
        required: ":attribute is required.",
      },
      element: (message) => (
        <div className="errorMsg" aria-live="polite">
          {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
      ),
    })
  );

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    value = name === "phone" ? e.target.rawValue : value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleServiceAddressChange = (e) => {
    let { name, value } = e.target;
    let { serviceAddress } = formData;
    setFormData({
      ...formData,
      serviceAddress: {
        ...serviceAddress,
        [name]: value,
      },
    });
  };

  const handleMailingAddressChange = (e) => {
    let { name, value } = e.target;
    let { mailingAddress } = formData;
    setFormData({
      ...formData,
      mailingAddress: {
        ...mailingAddress,
        [name]: value,
      },
    });
  };

  const handleStateDropDown = (e) => {
    var state = states.find(({ data }) => data === e).display;
    let { mailingAddress } = formData;
    setFormData({
      ...formData,
      mailingAddress: {
        ...mailingAddress,
        stateId: e,
        state,
      },
    });
  };

  const getStatesFromAPI = useCallback(() => {
    fetch(`api/party/states/${clientId}`)
      .then((res) => res.json())
      .then((data) => {
        const statesFromAPI = data.map((s) => {
          return {
            data: s.stateId,
            display: s.name,
            abbreviation: s.abbreviation,
          };
        });
        setStates(statesFromAPI);
      });
  });

  useEffect(() => {
    getStatesFromAPI();
    if (context.data.formData) {
      setFormData(context.data.formData);
    }
  }, []);

  

  const [showServiceAddress, setShowServiceAddress] = useState(false);
  const [showMailingAddress, setShowMailingAddress] = useState(false);
  const handleContinue = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let validData = { ...formData };
      if (formData.serviceAsMailingAddress) {
        setFormData({
          ...formData,
          mailingAddress: { ...formData.serviceAddress },
        });
        validData = {
          ...validData,
          mailingAddress: { ...formData.serviceAddress },
        };
      }

      context.updateFormData(formData);
      history.push({
        pathname: "/ReviewPlanDetails",
        state: {
          data: validData,
        },
      });

      /* todo service call*/

      let validAndSuggested = true;

      if (validAndSuggested) {
        const recommendedAddress = {
          serviceAddress: {
            address1: "",
            address2: "",
            city: "",
            zip: "",
            state: "",
          },
          mailingAddress: {
            address1: "",
            address2: "",
            city: "",
            zip: "",
            state: "",
          },
        };
        if (
          recommendedAddress.serviceAddress === null &&
          recommendedAddress.mailingAddress === null
        ) {
          // TODO: navigate to Review Plan Details when both are null - SP 6/22/21
        }
        setRecommendedAddress(recommendedAddress);
        if (
          recommendedAddress.serviceAddress === null &&
          recommendedAddress.mailingAddress !== null
        ) {
          setShowMailingAddress(true);
          setShowServiceAddress(false);
          setIsOpenAddressValidationModal(true);
        }
        if (
          recommendedAddress.serviceAddress !== null &&
          recommendedAddress.mailingAddress === null
        ) {
          setShowMailingAddress(false);
          setShowServiceAddress(true);
          setIsOpenAddressValidationModal(true);
        }
        if (
          recommendedAddress.serviceAddress !== null &&
          recommendedAddress.mailingAddress !== null
        ) {
          setShowMailingAddress(true);
          setShowServiceAddress(true);
          setIsOpenAddressValidationModal(true);
        }
      } else {
        setIsOpenUnableAddressModal(true);
      }

      /* todo service call for getting recommended address*/
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const hideModal = () => {
    setIsOpenUnableAddressModal(false);
    setIsOpenAddressValidationModal(false);
  };

  const handleDoNotSolicit = () => {
    let solicitDate;
    setTermsAndCondition(!termsAndCondition);
    solicitDate = !termsAndCondition ? new Date() : null;
    setFormData({
      ...formData,
      doNotSolicit: solicitDate,
      termsAndCondition: !termsAndCondition,
    });
  };

  const handleServiceAsMailing = () => {
    setFormData({
      ...formData,
      serviceAsMailingAddress: !formData.serviceAsMailingAddress,
    });
  };

  const handleShowActionModal = (event) => {
    event.preventDefault();
    setShowSaveModal(true);
  };

  const saveFormData = () => {
    context.updateFormData(formData);
  }

  return (
    <main className="container contentWrapper">
      <Form>
        <Row>
          <Col md="7" lg="6" mb="3">
            <Col md="9" id="serviceAddressFields" className="p-0 mb-3">
              <h1 className="h5">Your Details</h1>
              <FormGroup className="row twoColWrapper">
                <Col sm="6">
                  <Label for="firstName" className="form-label">
                    First Name
                  </Label>
                  <span aria-hidden="true" className="required-indicator">
                    *
                  </span>
                  <Input
                    aria-labelledby="firstName"
                    autoComplete="given-name"
                    className="form-control"
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    aria-required="true"
                  />
                  {simpleValidator.current.message(
                    "firstName",
                    formData.firstName,
                    "required"
                  )}
                </Col>
                <Col sm="6">
                  <Label for="lastName" className="form-label">
                    Last Name
                  </Label>
                  <span aria-hidden="true" className="required-indicator">
                    *
                  </span>
                  <Input
                    aria-labelledby="lastName"
                    autoComplete="family-name"
                    className="form-control"
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    aria-required="true"
                  />
                  {simpleValidator.current.message(
                    "lastName",
                    formData.lastName,
                    "required"
                  )}
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="phone" className="form-label">
                  Phone
                </Label>
                <span aria-hidden="true" className="required-indicator">
                  *
                </span>
                <Cleave
                  options={{
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ["(", ")", " ", "-"],
                    numericOnly: true,
                  }}
                  type="tel"
                  className="form-control"
                  autoComplete="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  aria-required="true"
                />
                {simpleValidator.current.message(
                  "phone",
                  formData.phone,
                  "required|phone"
                )}
              </FormGroup>
              <FormGroup>
                <Label for="email" className="form-label">
                  Email Address
                </Label>
                <span aria-hidden="true" className="required-indicator">
                  *
                </span>
                <Input
                  type="text"
                  className="form-control"
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  aria-required="true"
                />
                {simpleValidator.current.message(
                  "email",
                  formData.email,
                  "required|email"
                )}
              </FormGroup>
            </Col>
            <Col className="p-0 mb-3">
              <div className="checkbox">
                <Input
                  className="mr-2"
                  type="checkbox"
                  id="termsAndCondition"
                  onChange={handleDoNotSolicit}
                  checked={formData.termsAndCondition}
                />
                <Label
                  for="termsAndCondition"
                  className={classNames("checkboxLabel", styles.checkboxText)}
                >
                  I do not wish to receive special offers, promotions or other
                  email marketing communications from NJR Home Services Service
                  Plans. All information we gather is governed by our{" "}
                  <a
                    href="https://www.aig.com/privacy-policy"
                    target="_blank"
                    className={styles.privacyLink}
                  >
                    privacy statement.
                  </a>
                </Label>
              </div>
            </Col>
            <Col md="9" className="p-0 mb-5">
              <h3 className="h5">Service Address</h3>
              <FormGroup>
                <Label for="serviceAddress" className="form-label">
                  Address
                </Label>
                <span aria-hidden="true" className="required-indicator">
                  *
                </span>
                <Input
                  type="text"
                  className="form-control"
                  autoComplete="address-line1"
                  id="serviceAddress"
                  name="address1"
                  value={formData.serviceAddress.address1}
                  onChange={handleServiceAddressChange}
                  aria-required="true"
                />
                {simpleValidator.current.message(
                  "service address",
                  formData.serviceAddress.address1,
                  "required"
                )}
              </FormGroup>
              <FormGroup>
                <Label for="serviceAddress2" className="form-label">
                  Apt., Suite, Building
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  autoComplete="address-line2"
                  id="serviceAddress2"
                  name="address2"
                  value={formData.serviceAddress.address2}
                  onChange={handleServiceAddressChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="serviceCity" className="form-label">
                  City
                </Label>
                <span aria-hidden="true" className="required-indicator">
                  *
                </span>
                <Input
                  type="text"
                  className="form-control"
                  autoComplete="address-line3"
                  id="serviceCity"
                  name="city"
                  value={formData.serviceAddress.city}
                  onChange={handleServiceAddressChange}
                  aria-required="true"
                />
                {simpleValidator.current.message(
                  "city",
                  formData.serviceAddress.city,
                  "required"
                )}
              </FormGroup>
              <FormGroup className="row twoColWrapper">
                <Col sm="6">
                  <Label for="serviceState" className="form-label">
                    State
                  </Label>
                  <span aria-hidden="true" className="required-indicator">
                    *
                  </span>
                  <span>
                    <InfoTooltip
                      infoType="questionInfo"
                      message="NJR Service Plans are currently only available to New Jersey residents."
                    />
                  </span>
                  {states.length > 0 && (
                    <DropDownAutoComplete
                      name="state"
                      id="serviceState"
                      aria-label="Select State"
                      className="form-control"
                      options={states}
                      isDisabled={true}
                      defaultValue={
                        formData.serviceAddress.stateId === "" ||
                        formData.serviceAddress.stateId === null
                          ? undefined
                          : formData.serviceAddress.stateId
                      }
                      aria-required="true"
                      Value={formData.serviceAddress.stateId}
                    />
                  )}
                </Col>
                <Col sm="6">
                  <Label for="servicePostal" className="form-label">
                    Zip Code
                  </Label>
                  <span aria-hidden="true" className="required-indicator">
                    *
                  </span>
                  <Input
                    type="text"
                    className="form-control"
                    autoComplete="postal-code"
                    id="servicePostal"
                    maxLength={5}
                    name="zip"
                    value={formData.serviceAddress.zip}
                    onChange={handleServiceAddressChange}
                    aria-required="true"
                  />
                  {simpleValidator.current.message(
                    "zip",
                    formData.serviceAddress.zip,
                    "required|numeric|size:5"
                  )}
                </Col>
              </FormGroup>
            </Col>
            <Col md="9" className="p-0 mb-4">
              <h3 className="h5">Mailing Address</h3>
              <div className="checkbox">
                <Input
                  className="mr-2"
                  type="checkbox"
                  id="mailingAddress"
                  onChange={handleServiceAsMailing}
                  checked={formData.serviceAsMailingAddress}
                />
                <Label for="mailingAddress" className="checkboxLabel">
                  Same as service address
                </Label>
              </div>
              {!formData.serviceAsMailingAddress && (
                <>
                  <FormGroup>
                    <Label for="mailingAddress" className="form-label">
                      Address
                    </Label>
                    <span aria-hidden="true" className="required-indicator">
                      *
                    </span>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="address-line1"
                      id="mailingAddress"
                      name="address1"
                      value={formData.mailingAddress.address1}
                      onChange={handleMailingAddressChange}
                      aria-required="true"
                    />
                    {simpleValidator.current.message(
                      "mailing address",
                      formData.mailingAddress.address1,
                      "required"
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="mailingAddress2" className="form-label">
                      Apt., Suite, Building
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="address-line2"
                      id="mailingAddress2"
                      name="address2"
                      value={formData.mailingAddress.address2}
                      onChange={handleMailingAddressChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="mailingCity" className="form-label">
                      City
                    </Label>
                    <span aria-hidden="true" className="required-indicator">
                      *
                    </span>
                    <Input
                      type="text"
                      className="form-control"
                      autoComplete="address-line3"
                      id="mailingCity"
                      name="city"
                      value={formData.mailingAddress.city}
                      onChange={handleMailingAddressChange}
                      aria-required="true"
                    />
                    {simpleValidator.current.message(
                      "city",
                      formData.mailingAddress.city,
                      "required"
                    )}
                  </FormGroup>
                  <FormGroup className="row twoColWrapper">
                    <Col sm="6">
                      <Label for="mailingState" className="form-label">
                        State
                      </Label>
                      <span aria-hidden="true" className="required-indicator">
                        *
                      </span>
                      {states.length > 0 && (
                        <DropDownAutoComplete
                          name="state"
                          id="mailingState"
                          options={states}
                          className="form-control"
                          aria-required="true"
                          onChangedValue={handleStateDropDown}
                          isFloatingDropdown={false}
                          onBlur={handleStateDropDown}
                          defaultValue={
                            formData.mailingAddress.stateId === ""
                              ? undefined
                              : formData.mailingAddress.stateId
                          }
                          Value={formData.mailingAddress.stateId}
                        />
                      )}
                      {simpleValidator.current.message(
                        "state",
                        formData.mailingAddress.state,
                        "required"
                      )}
                    </Col>
                    <Col sm="6">
                      <Label for="mailingPostal" className="form-label">
                        Zip Code
                      </Label>
                      <span aria-hidden="true" className="required-indicator">
                        *
                      </span>
                      <Input
                        type="text"
                        className="form-control"
                        autoComplete="postal-code"
                        id="mailingPostal"
                        maxLength={5}
                        name="zip"
                        value={formData.mailingAddress.zip}
                        onChange={handleMailingAddressChange}
                        aria-required="true"
                      />
                      {simpleValidator.current.message(
                        "zip",
                        formData.mailingAddress.zip,
                        "required|numeric|size:5"
                      )}
                    </Col>
                  </FormGroup>
                </>
              )}
            </Col>
          </Col>
          <Col
            className="mb-3"
            md={{ size: "5", offset: 0 }}
            lg={{ size: "5", offset: 1 }}
          >
            <OrderSummary saveFormData={ saveFormData }/>
          </Col>
        </Row>
        <Row>
          <Col md="7" className="mb-3">
            <div className={styles.customerInfoBtnWrapper}>
              <button
                role="button"
                className={classNames(btnsecondary, btn)}
                aria-label="Click back to open shop plans screen"
                onClick={handleBackbutton}
              >
                Back
              </button>
              <button
                className={classNames(btnPrimary, btn)}
                aria-label="Click continue to open to review the plan details screen"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </Col>
          <Col md="7" className={classNames(styles.savelaterwrapper)}>
            {showSaveModal && (
              <SaveModal
                isOpen={true}
                centered={true}
                toggle={() => setShowSaveModal(false)}
              />
            )}
            {
              <a
                href="#"
                onClick={handleShowActionModal}
                className={classNames(btnLink, styles.saveForLater)}
                aria-label="Opens a modal to save your information"
              >
                Save For Later
              </a>
            }
          </Col>
        </Row>
        {isOpenAddressValidationModal && (
          <RecommendedAddressModal
            enteredAddress={formData}
            suggestedAddress={recommendedAddress}
            showServiceAddress={showServiceAddress}
            showMailingAddress={showMailingAddress}
            hideModal={hideModal}
          />
        )}

        {isOpenUnableAddressModal && (
          <IncorrectAddressModal
            enteredAddress={formData}
            hideModal={hideModal}
          />
        )}
      </Form>
    </main>
  );
};

export default CustomerInfo;
