import React from 'react'
import { ContractProvider } from "./components/contexts/ContractContext"
import { LanguageProvider } from "./components/contexts/LanguageTranslation/LanguageContext"
import { SalesProvider } from "./components/contexts/SalesContext"
import ResumeClaim from "./components/FileClaim/ResumeClaim"
import HandshakeWrapper from "./components/Vendor/Hwa/HandshakeWrapper"
import { WhiteLabelProvider } from "./components/_BrandingProvider/WhiteLabelProvider"
import { CombinedLangContractProvider } from './components/contexts/ProviderLangContractContext'

export const Providers = ({children}) => (
    <ContractProvider>
        <SalesProvider>
            <HandshakeWrapper>
                <LanguageProvider>
                    {/*
                       Used for class components for when both Language and Contract 
                       Context are used in component to allow access to multiple context
                    */}
                    <CombinedLangContractProvider>
                        <ResumeClaim>
                            <WhiteLabelProvider>
                                {children}
                            </WhiteLabelProvider>
                        </ResumeClaim>
                    </CombinedLangContractProvider>
                </LanguageProvider>
            </HandshakeWrapper>
        </SalesProvider>
    </ContractProvider>
)