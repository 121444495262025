import React, { useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import StandardInput from "../StandardInput/StandardInput";
import PhoneNumberInput from '../PhoneNumberUtility/PhoneNumberInput';
import { phoneFormat } from '../util';

export const ContactInfoForm = ({
    contactInfo,
    validator,
    title,
    validationFired,
    handleInputChange
}) => {
    const { name, email, phone } = contactInfo;

    const phoneErrors = (name, val) => {
        if (validationFired && !phone?.mobilePhone && !phone?.alternatePhone) {
            return (
                <div className="error-msg" aria-live="polite">
                    {"Please enter at least one phone number."}
                </div>
            );
        } else {
            return validator.current.message(name, val, "phone|valid_phone");
        }
    };

    return (
        <>
            <h6>{title}</h6>
            <Form>
                <Row className={'multiColWrapper'}>
                    <Col sm="6" lg="6">
                        <StandardInput
                            label={"Name"}
                            hasError={!name && validationFired}
                            fieldName="name"
                            ariaLabelAndFieldId={"name"}
                            value={name}
                            onChange={handleInputChange}
                            validator = {validator.current.message(
                                "name", 
                                name, 
                                "required", { messages: { required: 'Name is required.'} }
                            )}
                        />
                    </Col>
                    <Col sm="6" lg="6">
                        <StandardInput
                            label={"Email"}
                            hasError={validator.current.message("email", email, "required|email") && validationFired}
                            fieldName="email"
                            ariaLabelAndFieldId={"name"}
                            value={email}
                            onChange={handleInputChange}
                            validator = {validator.current.message(
                                "email", 
                                email, 
                                "required|email", { messages: { required: 'Email is required.'} }
                            )}
                            maxLength={320}
                        />
                    </Col>
                </Row>
                <Row className={'multiColWrapper'}>
                    <Col sm="6" lg="6">
                        <PhoneNumberInput
                            fieldName="mobilePhone"
                            label={"Mobile Phone"}
                            value={phone?.mobilePhone}
                            required={false}
                            onChange={handleInputChange}
                            validationFired={validationFired}
                            error={phoneErrors("mobilePhone", phone?.mobilePhone)}
                        />
                    </Col>
                    <Col sm="6" lg="6">
                        <PhoneNumberInput
                            fieldName="alternatePhone"
                            label={"Alternate Phone"}
                            value={phone?.alternatePhone}
                            required={false}
                            onChange={handleInputChange}
                            validationFired={validationFired}
                            error={phoneErrors("alternatePhone", phone?.alternatePhone)}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export const StaticContactInfo = ({
    contactInfo,
    title
}) => {

    return (
        <>
            <p className="cardTitle font-weight-bold mb-0">{title}</p>
            <ul className="noBullets pb-3" role="none">
                <li>{contactInfo?.name}</li>
                <li>{"Mobile Phone:"} {phoneFormat(contactInfo?.mobilePhone)}</li>
                <li>{"Alternate Phone:"} {phoneFormat(contactInfo?.alternatePhone)}</li>
                <li>{contactInfo?.email}</li>
            </ul>
        </>
    )
}
