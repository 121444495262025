import React from 'react';
import { Col, Row } from 'reactstrap';
import { PlanCard } from './PlanCard';

export const MyPlansTileView = ({ contracts, contractTerms, ...props }) => {
    const handleSelectedActionClick = (action, contractDetails) => {
        return props?.handleSelectedActionClick(action, contractDetails);
    }

    return (
        <Row>
            {contracts.map(contract => (
                <Col md="6" lg="6">
                    <PlanCard contract={contract} handleSelectedActionClick={handleSelectedActionClick} contractTerms={contractTerms} />
                </Col>
            ))}
        </Row>
    )

}