import React, { useState } from 'react';
import SpinnerButton from '../../../shared/SpinnerButton/SpinnerButton'
import CancelModal from '../../../modals/CancelModal';
import styles from './IntakeLossButtons.module.css';
import useTranslation from '../../../contexts/LanguageTranslation/useTranslation';
import { isAppleRoute } from '../../../../helpers';

const IntakeLossButtons = ({
    // PRIMARY BUTTON PROPS
    buttonText,
    onButtonClick,
    buttonDisabled,
    buttonSpinning,
    buttonAriaLabel,
    hideButtonBecauseOfApplePay = false,
    // FIRST LINK (USUALLY "SAVE FOR LATER")
    showLink1,
    link1Text,
    link1Id = '',
    onLink1Click,
    link1AriaLabel,
    // CANCEL CLAIM LINK AT BOTTOM OF LIST
    caseNumberToCancel,
    claimNumberToCancel,
    saveClaimClickActionFromWithinCancelModal,
    isSubmittedClaim = false,
    hideCancelClaimButton = false,
    isUploadDocs = false
}) => {
    const international = useTranslation('components/Vendor/Apple/IntakeLoss/IntakeLossButtons');
    const { translations } = international;
    const [showCancelModal, setShowCancelModal] = useState(false)

    const toggleShowModal = (e, bool) => {
        e.preventDefault();
        setShowCancelModal(bool)
    }
    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div className={styles.buttonsWrapper}>
            {!hideButtonBecauseOfApplePay &&
                <SpinnerButton
                    type="primary"
                    text={buttonText}
                    disabled={buttonDisabled}
                    spinning={buttonSpinning}
                    onClick={onButtonClick}
                    ariaLabel={buttonAriaLabel}
                />
            }

            {showLink1 && (
                <p>
                    <a
                        id={link1Id}
                        href="#"
                        onClick={onLink1Click}
                        className={`btnLink ${styles.link1}`}
                        aria-label={link1AriaLabel}
                    >
                        {link1Text}
                    </a>
                </p>
            )}

            {!hideCancelClaimButton &&
            <button
                className={isAppleRoute() ? "btnLink" : "btn btn-secondary"}
                id="cancel-id"
                tabIndex="0"
                aria-label={translations.cancelBtnAria || "Opens a modal to cancel your claim"}
                color="secondary"
                onClick={e => toggleShowModal(e, true)}
            >
                {translations.cancel || "Cancel Claim"}
            </button>
            }
            <CancelModal
                isOpen={showCancelModal}
                caseNumber={caseNumberToCancel}
                claimNumber={claimNumberToCancel}
                onSaveForLaterAction={saveClaimClickActionFromWithinCancelModal}
                onCloseModal={e => toggleShowModal(e, false)}
                isSubmittedClaim={isSubmittedClaim}
                isUploadDocs={isUploadDocs}
            />

        </div>
    )
}

export default IntakeLossButtons;