import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { ContractContext } from '../contexts/ContractContext';
import { resumeClaim } from '../../helpers';
import moment from 'moment';

const ResumeClaim = ({ children }) => {
    const match = useRouteMatch('/resumeclaim/:claimNumber/:clientId')
    const searchMatch = useRouteMatch('/vendor/apple/:caseId/:email?')
    const history = useHistory();
    const loc = useLocation();
    const context = useContext(ContractContext);

    const [clientId] = useState(match?.params?.clientId);
    const [claimNumber] = useState(match?.params?.claimNumber);
    const [resumingClaim, setResumingClaim] = useState(true);
    const [caseId] = useState(searchMatch?.params?.caseId);
    const [email] = useState(searchMatch?.params?.email);

    useEffect(() => {
        if (loc.pathname.includes('/vendor/apple/')) {
            if (!isNaN(caseId) && typeof email == 'undefined') {
                window.location.href = '/vendor/apple/' + caseId + '/' + "null";
                //setResumingClaim(false)
                return;
            }
        }

        if (!loc.pathname.includes('/resumeclaim')) {
            setResumingClaim(false)
            return;
        }

        resume();
    }, [loc.pathname])

    const resume = async () => {
        //const claimId = this.props.match.params.claimId;

        fetch(`api/claim/ClaimUpdatePartial/${parseInt(claimNumber)}/${clientId}`, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        });

        await fetch('api/claim/GetClaimSummaryByClaimNumber/' + claimNumber + '/' + clientId)
            .then(res => res.json())
            .then(data => {
                let col = data;
                fetch(`api/contract/${col?.contractId}/${clientId}`)
                    .then(res => res.json())
                    .then(contract => {
                        let contracts = [contract];
                        var val = {
                            contract: contract,
                            party: contract?.party,
                            contracts: contracts
                        };
                        context.updateContract(contract);
                        context.updateParty(contract?.party);
                        context.updateContracts(contracts);

                        // sessionStorage.setItem("appState", JSON.stringify(val));
                        
                        let claimList = {
                            claimNumber: col.claimNumber,
                            assetName: col.assetName,
                            claimStatusName: col.claimStatusName,
                            claimSetupDate: col.claimSetupDate ? moment(col.claimSetupDate).format('MM/DD/YYYY') : '',
                            claimFileCompletionDate: col.claimFileCompletionDate ? moment(col.claimFileCompletionDate).format('MM/DD/YYYY') : '',
                            claimId: col.claimId,
                            clientId: clientId,
                            brand: col.brand,
                            assetDescription: col.assetDescription,
                            model: col.modelNumber,
                            serial: col.serialNumber,
                            modelNumber: col.modelNumber,
                            serialNumber: col.serialNumber,
                            purchaseDate: col.assetPurchaseDate,
                            symptom: col.symptom,
                            incidentDate: col.lossDate,
                            createdBy: 'SaveClaim',
                            incidentDescription: col.incidentDescription,
                            tcf: col.deductible,
                            creditCardNumber: col.creditCardNumber,
                            creditCardType: col.creditCardType,
                            servicerName: col.servicerName,
                            servicerPhone: col.servicerPhone,
                            scheduledAppointment: col.scheduledAppointment,
                            appointmentDate: col.appointmentDate,
                            appointmentTime: col.appointmentTime,
                            eventType: col.eventType,
                            eventStatusId: col.eventStatusId,
                            accepted: col.accepted,
                            deliveryId: col.deliveryId,
                            partyModel: col.partyModel,
                            claimSetupDateOverview: col.claimSetupDate,
                            authorizedAmount: col.authorizedAmount,
                            savedStep: col.savedStep,
                            stepsCounter: col.stepsCounter,
                            assetId: col.assetId,
                            contractAssetId: col.contractAssetId,
                            coverageId: col.coverageId,
                            contractId: col.contractId,
                            tcf: col.tcfAmount,
                            contract: contract,
                            result: col.featureClaimModel,
                            lastQuestionAnswered: col.lastQuestionAnswered,
                        }

                        let historyData = resumeClaim(claimList, clientId, history);
                        history.push(historyData);
                    });
            });
    }

    if (resumingClaim) return null

    return children
}

export default ResumeClaim;