import moment from 'moment';

const randomized = () => Math.random().toString(36).substring(7);

export const fAKe_dAtA = {
    appointmentSearchResponse: {
        responseIdentifier: '12345-abcde-98765-zyxwv',
        ResultMessages: 'Successfully Fetched',
        success: true,
        servicers: [
            {
                id: randomized(),
                name: 'Test Servicer 1',
                oemAuthorized: false,
                phoneNumber: '5025551234',
                ranking: 80.67971,
                servicerType: 'Type1',
                status: 'Active',
                address: {
                    city: 'Louisville',
                    state: 'Kentucky',
                    street: '123 Any Way',
                    zip: '11111',
                },
                appointments: [
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(12, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(2, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(2, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(9, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                ]
            },
            {
                id: randomized(),
                name: 'Test Servicer 2',
                oemAuthorized: true,
                phoneNumber: '5025551234',
                ranking: 69.97,
                servicerType: 'Type1',
                status: 'Active',
                address: {
                    city: 'Louisville',
                    state: 'Kentucky',
                    street: '123 Any Way',
                    zip: '11111',
                },
                appointments: [
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(7, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(9, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(9, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(9, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(8, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(8, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(9, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                ]
            },
            {
                id: randomized(),
                name: 'Test Servicer 3',
                oemAuthorized: true,
                phoneNumber: '5025551234',
                ranking: 68.78,
                servicerType: 'Type1',
                status: 'Suspended',
                address: {
                    city: 'Louisville',
                    state: 'Kentucky',
                    street: '123 Any Way',
                    zip: '11111',
                },
                appointments: [
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(11, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(7, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '12:00 PM',
                        date: moment().add(7, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '12:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(12, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                ]
            },
            {
                id: randomized(),
                name: 'Test Servicer 4',
                oemAuthorized: true,
                phoneNumber: '5025551234',
                ranking: 62.84,
                servicerType: 'Type1',
                status: 'Active',
                address: {
                    city: 'Louisville',
                    state: 'Kentucky',
                    street: '123 Any Way',
                    zip: '11111',
                },
                appointments: [
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(7, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(11, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(1, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                ]
            },
            {
                id: randomized(),
                name: 'Test Servicer 5',
                oemAuthorized: false,
                phoneNumber: '5025551234',
                ranking: 64.83,
                servicerType: 'Type2',
                status: 'Active',
                address: {
                    city: 'Louisville',
                    state: 'Kentucky',
                    street: '123 Any Way',
                    zip: '11111',
                },
                appointments: [
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(6, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(5, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(7, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 PM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(11, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                    {
                        appointmentStartTime: '8:00 AM',
                        appointmentEndTime: '5:00 PM',
                        date: moment().add(1, 'days').startOf('day'),
                        GroupId: randomized(),
                        id: randomized()
                    },
                ]
            },
        ]
    }
}