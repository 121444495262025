import React from 'react';
import { ReactComponent as CircleArrowIcon } from '../../../assets/images/circle-arrow.svg'
import styles from './AppointmentSelect.module.css';

export const ArrowButton = ({direction, onClick, disabled, altText}) => {
    return (
        <button 
            className={`
                ${styles.arrowButton} 
                ${disabled && styles.disabled}
            `} 
            onClick={onClick}
        >
            <CircleArrowIcon className={`${styles.arrowIcon} ${styles[direction]}`} alt={altText}/>
        </button>
    )
}
