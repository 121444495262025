import React, { useContext } from 'react';
import getClientContent from '../_BrandingProvider/brandContent';
import { ContractContext } from '../contexts/ContractContext';
import { phoneFormat } from './util';

const ClientPhone = () => {
    const { data: { clientId } } = useContext(ContractContext);
    const { phone } = getClientContent(clientId)?.variableText?.customerService;
    const formattedNumber = phoneFormat(phone)
    return <>{formattedNumber}</>
}

export default ClientPhone;