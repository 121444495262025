import React, { useState, useEffect, useContext } from 'react';
import { randomized } from '../util'
import {useClickOutside} from '../../_ReactHooks/useClickOutside'
import styles from './MultiDrop.module.css'
import { ContractContext } from '../../contexts/ContractContext';
import { classNames } from 'classnames'

/**
 * ============================================
 * ============== START COMPONENT =============
 * ============================================
 */

const MultiDropDown = ({
    options,            // 1. pass options from parent (array of objects with {value, display})
    onOptionClick,      // 2. parent controls what to do with selections
    selected,           // 3. and passes selections back to component. (Array of each selected option.value string)
    hasSearch = false,  // allow searchable options
    borderless = false, // for use in a table where cell provides border
    disabled = false,
    placeholder = "Please Select...",
    checkmarkBrand = 'aig',
    hasError
}) => {
    const [drop, setDrop] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])

    useEffect(() => {
        setSearchResult(options.filter(o => o.display.toLowerCase().includes(searchValue)));
    }, [searchValue]);

    // unique ids necessary to allow multiple dropdowns to operate on a single page.
    const idKey = ['multidrop', randomized()].join('_')
    useClickOutside(idKey, ()=>setDrop(false))

    const handleSearch = (val) =>  {
        setSearchValue(val.toLowerCase());
    }

    const getButtonText = () => {
        const { length } = selected;
        if (length === 0) {
            return(placeholder);
        } else if (length === 1) {
            console.log(options);
            console.log(selected)
            const getDisplayName = options.find(o => o.data === selected[0].data || o.data == selected[0])
            
            return (getDisplayName?.display)
        } else {
            return (`${length} selected`)
        }
    }

    const arrayToFilter = !!searchValue ? searchResult : options 
    return (
        <div id={idKey} style={{position: 'relative'}} className={`${styles.wrapper} ${!!borderless && 'queueDropdown'}`}>
            <button className={`dropbtn dropbtn-placeholder ${hasError && styles.dropError}`} onClick={()=>setDrop(!drop)}>{getButtonText()}</button>
            {drop &&
                <div 
                className={styles.optionsContent}
                >
                    {hasSearch &&
                        <label className='mb-0'>
                            <input 
                                className={styles.searchInput}
                                value={searchValue}
                                type='text' 
                                placeholder='Search...'
                                onChange={(e)=>handleSearch(e.target.value)}
                            />
                        </label>
                    }
                {arrayToFilter?.map(o => (
                        <a 
                            className={`${styles.dropOption} checkbox m-0`} 
                            onClick={()=>onOptionClick(o.data)}
                            key={o.data}
                        >
                            <input
                                className="mr-2"
                                type='checkbox' 
                                value={o.data}
                                disabled={disabled}
                                checked={selected.filter(s=>s.data == o.data || s == o.data).length > 0}
                            />
                            <label className={`checkboxLabel ${checkmarkBrand}`}>{o.display}</label>
                        </a>
                    ))}
                </div>
            }
        </div>
    )
}

export default MultiDropDown;