import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Label,
  Input,
  FormGroup
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

import styles from "../../components/FileClaim/Entitlement.module.css";
import FooterButtonsRow from "../shared/FooterButtonsRow/FooterButtonsRow";


const EmailAddressModal = (props) => {
  const {
    onCloseModal,
    onContinue,
    emailModel,
  } = props;

  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState(emailModel.recipients[0]);
  const [isEmailValid, setIsEmailValid] = useState(true);
  
  const [, forceUpdate] = useState();

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        email: "Please enter a valid email.",
        required: ":attribute is required.",
      },
      element: (message) => (
        <div className="errorMsg" aria-live="polite">
          {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
      ),
    })
  );

  const setEmailValue = (e) => {
    e.preventDefault();
    let {value } = e.target;
    simpleValidator.current.purgeFields();
    if(value.length) {
        setIsEmailValid(true);
    }
    setEmail(value);
    
  };

  const onCloseModalEmail = () => {
    onCloseModal();
  };

  const saveEmailAddress =  (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      onContinue(email);
    } else {
      setIsEmailValid(false);  
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };


  return (
    <div>
        <Modal
          isOpen={setModal}
          aria-modal="true"
          className="md"
          aria-labelledBy="email address"
          role="dialog"
        >
          <ModalHeader className="noLine"></ModalHeader>
          <ModalBody className={styles.emailModalBody}>
            <h4 className="modal-title" id="save">
              Please confirm your email address
            </h4>
            <p>
              Please verify your preferred email address to recieve claim status
              updates.
            </p>
                <Col md="7">
                    <FormGroup className={!isEmailValid && "form-error"}>
                        <Label className="form-label" for="email" >
                            Email
                            <span aria-hidden="true" className="required-indicator">
                            *
                            </span>
                        </Label> 
                        <Input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={setEmailValue}
                            aria-required="true"
                        />
                        {simpleValidator.current.message(
                            "email",
                            email,
                            "required|email"
                        )}
                </FormGroup>
                </Col>

          </ModalBody>
         
          <ModalFooter>
            <FooterButtonsRow
              isModal
              buttons={{
                link: {
                  text: "Close",
                  onClick: onCloseModalEmail,
                  ariaLabel:
                    "Click close to close modal and return to affirmation statement page",
                },
                primary: {
                  hasSpinner: true,
                  isSpinning: props.continuing,
                  text: "Continue",
                  type: "primary",
                  onClick: saveEmailAddress,
                  ariaLabel: "Continue",
                },
              }}
            />
          </ModalFooter>
        </Modal>
    </div>
  );
};

export default EmailAddressModal;
