import React, { Component } from 'react';
import styles from './Progress.module.css';
import classNames from 'classnames';
import moment from 'moment';
import { UseTranslationInClassComponent } from '../../contexts/LanguageTranslation/UseTranslationInClassComponent';

let rtCol = styles.rtCol;
let colLg4 = "col-lg-4";
let progressItem = styles.progressItem;
let progressItemCompleted = styles.progressItemCompleted;
let progressItemActive = styles.progressItemActive;
let hoverHand = styles.hoverHand;
let allUpdate = styles.allUpdate;

export default function Progress({ steps, claimOverview }) {
    /*
    * BACKEND TEAM NEED TO DETERMINE IF NEED TO REMOVE THIS FUNCTION.
    * TRACKING NUMBER FROM BACKEND SHOULD ENABLE BUTTON AND TRACKING INFORMATION 
    * CURRENTLY, ON CLICKING DEVICE SHIPPED STEP PROGRESS LAYOUT IS CHANGING
    */
    const hideOrShowTracking = () => {
        var k = document.getElementById("claimApproved");
        var d = document.getElementById("deliveryId");
        k.classList.add("ClaimOverview_progressItemCompleted__1Lt7s");
        d.classList.add("ClaimOverview_progressItemCompleted__1Lt7s");
        document.getElementById("trackinNum").hidden = false;
        document.getElementById("trackShipmentBtn").hidden = false;
        if (document.getElementById("claimReview") != null) { document.getElementById("claimReview").hidden = true; }
        if (document.getElementById("uploadDocsConfirm") != null) { document.getElementById("uploadDocsConfirm").hidden = true; }
        document.getElementById("seeUpdates").hidden = false;
    }

    return (
        <UseTranslationInClassComponent componentPathFromSrcFolder={'components/shared/StatusProgress/Progress'}>
            { international => {
                const {
                    country,
                    translations
                } = international;
                return (
                    <article className={classNames(colLg4, rtCol)}>
                        <h2 className="h3 mb-0">{translations.title || 'Claim Status'}</h2>
                        <div id="trackinNo" hidden>
                            <h3 className={styles.estArrival}>{translations.arrives || 'Arrives:'} Saturday, April 27 </h3>
                            <a href="#" aria-label="UPS Shipment Tracking Status" target="_blank">{translations.trackingNumber || 'Tracking #'}: {claimOverview != null ? claimOverview.trackingNumber != null ? claimOverview.trackingNumber : "" : ""}</a>
                        </div>
                        <ul className={styles.claimProgress}>
                            {steps.map(step =>
                                <li id={step.id} className={classNames(progressItem, step.style === 'completed' && progressItemCompleted, step.style === 'active' && progressItemActive)} onClick={step.name === "Device Shipped" && hideOrShowTracking}>
                                    <h2 className={classNames('h6', 'mb-0', styles.stepName)}>{step.name}</h2>
                                    {step.date && <p>{step.date}</p>}
                                    {step.id === "deliveryId" && <p id="seeUpdates" hidden className={hoverHand, allUpdate} aria-label="Shipping Progess Updates">{translations.seeAllUpdates || 'See all updates'}</p>}
                                </li>)}
                        </ul>
                        <button id="trackShipBtn" hidden className="btn btn-primary" aria-label="Opens a UPS Carrier website for tracking shipment">{translations.trackShipment || 'Track Shipment'}</button>
                    </article>
                )
            }}
        </UseTranslationInClassComponent>
    );
}
