import React, { createContext, Component, useState, useEffect, useMemo } from 'react';
import { useEnvironment } from '../_ReactHooks/useEnvironment'

export const ContractContext = createContext();

export const ContractProvider = ({ children }) => {
  // on first load, get values from session and use for initial state
  const restoreSession = useMemo(() => {
    return JSON.parse(sessionStorage.getItem('appState'))
  }, [])


    // TODO: We should change these from "update" to "set" to follow React conventions.
    const [contract, updateContract] = useState(restoreSession?.contract ?? {});
    const [contracts, updateContracts] = useState(restoreSession?.contracts ?? [])
    const [party, updateParty] = useState(restoreSession?.party ?? {})
    const [clientId, updateClientId] = useState(contract?.clientId ?? null);
    const [viaClientHandshake, updateViaClientHandshake] = useState(restoreSession?.contract ?? false)
    const [hwaToken, updateHwaToken] = useState(restoreSession?.contract ?? '') // PROBABLY DEPRECATED?
    const [handshakeSuccessful, setHandshakeSuccessful] = useState(null);
    
    const [coverageDocs, setCoverageDocs] = useState(null)
    const [isChileApple, setIsChileApple] = useState(false);
    const { isDevOrLower } = useEnvironment();
    const [isFetchComplete, setIsFetchComplete] = useState(true);

  // SET INITIAL BRAND BY URL
  useEffect(() => {
    var client = contract?.clientId || 'AIG';
    updateClientId(client);

      if (!isDevOrLower && Object.keys(contract).length === 0)
      clientSetup();
  }, [])

  useEffect(() => {
      if (clientId) {
        getCoverageDocs();
      }
    }, [contract?.clientId])


  // KEEP SESSION STORAGE IN SYNC WITH STATE. 
  useEffect(() => {
    const appState = {
      contract, party, contracts, viaClientHandshake, hwaToken, clientId, handshakeSuccessful, coverageDocs
    }
    sessionStorage.setItem('appState', JSON.stringify(appState))
  }, [
    contract, contracts, party, clientId, viaClientHandshake, handshakeSuccessful,
    hwaToken, coverageDocs // PROBABLY DEPRECATED?
  ])

    const getCoverageDocs = async () => {
        if (contract?.clientId) {
            fetch(`api/document/GetCoverageDocuments/${contract?.clientId}`)
                .then(res => res.json())
                .then(data => {
                    setCoverageDocs(data);
                });
        }
    }

  const clientSetup = async () => {
    setIsFetchComplete(false)
    await fetch('api/helper/getClientByUrl', {
      method: 'get',
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then(res => res.json())
      .then(envRes => {
        const clientId = envRes.clientId.toUpperCase();
        updateClientId(clientId);
      })
      .catch(err => { console.error(err) })
      .finally(() => setIsFetchComplete(true))
  }

  if (!isFetchComplete) return null

  return (
    <ContractContext.Provider
      value={{
        // Ideally, do away with "data" and just return values
        data: {
          contract,
          contracts,
          isChileApple,
          party,
          clientId,
          viaClientHandshake,
          hwaToken,
          handshakeSuccessful,
          coverageDocs
        },
        // contract,
        updateContract,
        // contracts,
        updateContracts,
        // party,
        updateParty,
        // clientId,
        updateClientId,
        // viaClientHandshake,
        updateViaClientHandshake,
        // hwaToken,
        updateHwaToken,
        // handshakeSuccessful
        setHandshakeSuccessful,
        // chileApple
        setIsChileApple
      }}
    >
      {children}
    </ContractContext.Provider>
  )
}
