import React, { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, CardBody, Alert } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import styles from './ViewPlanDetails.module.css';
import { ReactComponent as Chevron } from '../../assets/images/arrow-cheveron-down.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/checkmark.svg';
import Accordion from '../../components/shared/AccordionCard/Accordion';
import { AIGCircleBadge } from '../../components/shared/StatusBadge/CircularStatusBadge';
import { assetInformation } from './PlanDetails';
import { ContractContext } from '../../components/contexts/ContractContext';
import getClientContent from '../../components/_BrandingProvider/brandContent';
import ReprintContractModal from './ReprintContract';
import AIGStatusBadge from '../../components/shared/StatusBadge/StatusBadge';
import moment from 'moment';
import { resumeClaim } from '../../helpers'
import { useHistory, useLocation } from 'react-router';
import { getEnvironment } from '../../../src/helpers';
import { getCoverageDocument } from '../../components/Global/DocumentHelper';
import { parse } from 'query-string';
import DocumentTitle from 'react-document-title';

const ViewPlanDetails = (props) => {
    const [claimHistoryData, setClaimHistoryData] = useState([]);
    const context = useContext(ContractContext)
    const location = useLocation();
    const history = useHistory();
    const [clientId, setClientId] = useState(context?.data?.clientId || 'et');
    const [showReprintContractModal, setShowReprintContractModal] = useState(false);
    const [showReprintReviewMessage, setShowReprintReviewMessage] = useState(false);
    const [asset, setAsset] = useState(JSON.parse(location?.state?.contractDetails));
    const [activeView,] = useState(location?.state?.view);
    const [assetSymptoms, setAssetSymptoms] = useState([]);
    const dateFormat = "MM/DD/YYYY";
    const currentAsset = assetInformation[asset?.assetName];
    const [isStagingEnvironment, setIsStagingEnvironment] = useState(false);
    const [token, setToken] = useState(parse(location.search)?.id || parse(location.search)?.token);

    const clientTextValues = getClientContent(clientId);
    const title = clientTextValues.variableText.pageTitle;
    const pageTitle = title ? `Contract Overview | ${title}` : 'Contract Overview';

    const headerFormatter = (column, index, { sortElement }) => {
        const { order } = sortElement?.props || {};
        return (
            <span className={`${order === 'asc' ? 'ascending' : ''} ${order === 'desc' ? 'descending' : ''}`}>
                {column.text}
            </span>
        );
    }

    const openClaimOverview = (col) => {
        let pathName = '/DetailsOverview';
        if (typeof history?.search != 'undefined') {
            pathName += `?id=${history?.search}`;
        }
        history.push({
            pathname: pathName,
            claim: col,
            clientId: clientId
        });
    }

    const resumeClaimPage = (col) => {
        const historyData = resumeClaim(col, clientId, props);
        history.push(historyData);
    }

    const actionsFormatter = (cell, row) => {
        if (row.claimStatusName === "Saved") {
            const isResumeDisabled = row?.contract?.statusId == 4;
            return <button className="btn-secondary" value="Resume" disabled={isResumeDisabled} onClick={() => resumeClaimPage(row)}>Resume</button>
        } else {
            return <button className="btn-secondary" value="View" onClick={() => openClaimOverview(row)}>View</button>
        }
    }

    const statusFormatter = (cell, col) => {
        if (col.claimStatusName == 'Closed with amount' || col.claimStatusName == 'Closed without payment') {
            return (<AIGCircleBadge status='Closed' />)
        }
        else if (col.claimStatusName == 'Open' || col.claimStatusName == 'In Review') {
            return (<AIGCircleBadge status='In Progress' />)
        }
        else if (col.claimStatusName == 'Saved') {
            return (<AIGCircleBadge status='Saved' />)
        }
        else {
            return (<AIGCircleBadge status='In Progress' />)
        }
    }


    useEffect(() => {
        const setEnv = async () => {
            const env = await getEnvironment();
            const envLower = env.environment.toLowerCase()
            setIsStagingEnvironment(envLower === "local" || envLower === "staging" || envLower === "production")
        }
        setEnv();

        if (asset?.contract) {
            context.updateContract(asset?.contract);
        }

    }, []);

    const claimHistoryColumns = [
        {
            dataField: 'claimNumber',
            text: 'Claim Number',
            classes: 'btnLink',
            headerFormatter: headerFormatter,
            formatter: (claimNum, col) => claimNumberLinkFormatter(claimNum, col),
            sort: 'true',
            sortFunc: (a, b, order) => {
                if (order === 'desc') {
                    return b - a;
                }
                return a - b;
            }
        },
        {
            dataField: 'coveredItem',
            text: 'Covered Item/Symptoms',
            sort: 'true',
            headerFormatter: headerFormatter
        },
        {
            dataField: 'claimStatusName',
            text: 'Claim Status',
            headerFormatter: headerFormatter,
            formatter: statusFormatter,
            sort: 'true',
        },
        {
            dataField: 'dateOpened',
            text: 'Date Opened',
            sort: 'true',
            headerFormatter: headerFormatter
        },
        {
            dataField: 'dateCompleted',
            text: 'Date Completed',
            sort: 'true',
            headerFormatter: headerFormatter
        },
        {
            dataField: 'actions',
            text: '',
            isDummyColumn: true,
            classes: styles.claimActions,
            headerFormatter: headerFormatter,
            formatter: actionsFormatter,
        }
    ]

    const claimNumberLinkFormatter = (claimNum, claim) => {
        return <a onClick={() => handleClaimNumberClick(claim)}>{claimNum}</a>
    }

    const handleClaimNumberClick = (claim) => {
        if (claim.claimStatusName == "Saved") {
            resumeClaimPage(claim)
        } else {
            openClaimOverview(claim)
        }
    }

    const defaultSortedBy = [{
        dataField: 'claimNumber',
        order: 'desc',
    }];

    const backToMyPlans = () => {
        history.push({
            pathname: '/planoverview',
            search: typeof token == 'undefined' ? null : `?token=${token}`,
            state: {
                view: activeView,
                activeOverviewTab: 'myPlans'
            }
        });
    }

    const fileNewClaim = (asset) => {
        history.push({
            pathname: '/planoverview',
            state: {
                view: 'view',
                activeOverviewTab: 'fileClaim',
                activeAssetId: asset?.assetId,
                activeAssetCategory: asset.assetDisplayCategory
            }
        });
    }

    const handleReprintContractModal = (event) => {
        event.preventDefault();
        setShowReprintContractModal(true);
    };

    const handleReprintSubmit = () => {
        setShowReprintContractModal(!showReprintContractModal)
        setShowReprintReviewMessage(true);
        setTimeout(() => {
            setShowReprintReviewMessage(false)
        }, 5000)
    }
    const CoveredInformation = () => {
        return (
            <div className={styles.coveredInfoContainer}>
                {currentAsset?.coveredInfo.map(item => (
                    <div className={styles.coveredItemsRow}>
                        <div>{item}</div>
                        <div> <CheckmarkIcon className={styles.checkmarkIcon} /></div>
                    </div>
                ))}
            </div>
        )
    }

    const NotCoveredInformation = () => {
        return (
            <div className={styles.notCoveredInfoContainer}>
                <ul className='pl-4 mb-0'>
                    {currentAsset?.notCoveredInfo.map(item => (
                        <li>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    useEffect(() => {
        getAssetSymptoms();
        getClaimHistory();
    }, [])

    useEffect(() => {
        if (claimHistoryData.length > 0 && assetSymptoms.length > 0) {
            mapSymptoms()
        }

    }, [claimHistoryData?.length])

    const mapSymptoms = () => {
        if (claimHistoryData.length > 0) {
            const updatedHistoryData = claimHistoryData.map((claim) => {
                return {
                    ...claim,
                    coveredItem: getAssetSymptomMapping(claim.coveredItemId),
                    assetName: asset?.assetName
                }
            })

            setClaimHistoryData(updatedHistoryData);
        }
    }

    const getClaimHistory = () => {
        const { contract } = context?.data;

        getClaimsbyContractNumber().then(data => {
            if (Array.isArray(data)) {
                fetch(`api/claim/GetClaimsByContractAsset/${asset?.contractAssetId}/${context?.data?.contract?.contractId}/${clientId}`)
                    .then(res => res.json())
                    .then(cad => {
                        const historyData = data.map((cd) => {
                            var symptomData = cad?.filter(ad => ad.claimNumber === cd.claimNumber)[0];

                            return {
                                ...cd,
                                claimNumber: cd?.claimNumber,
                                coveredItemId: symptomData?.symptom,
                                claimStatusId: cd?.claimStatusId,
                                dateOpened: cd?.claimSetupDate ? moment(cd.claimSetupDate).format(dateFormat) : "",
                                dateCompleted: cd?.claimFileCompletionDate ? moment(cd.claimFileCompletionDate).format(dateFormat) : "",
                                coveredItem: "",
                                claimStatusName: cd?.claimStatusName,
                                assetName: cd?.assetName,
                                contract: contract
                            }
                        });

                        const filtered = historyData.filter(contractAsset => contractAsset?.assetName === asset?.assetName)
                        setClaimHistoryData(filtered);
                    })
            }
        });
    }

    const getClaimsbyContractNumber = () => {
        return new Promise((resolve, reject) => {
            fetch(`api/claim/GetClaimsByContractNumber/${context.data.contract.contractNumber}/${clientId}`)
                .then(res => res.json())
                .then(data => {
                    resolve(data?.filter(d => d.claimStatusName !== "Partial" && d.eventStatusId !== 5 || (d.claimStatusName !== "Closed without payment" && d.eventStatusId == 7)));
                })
        });
    }

    const getAssetSymptoms = () => {
        fetch(`api/troubleshooting/GetSymptomsByAssetId/${asset?.assetId}/${clientId}`)
            .then(res => res.json())
            .then(data => {
                setAssetSymptoms(data);
            });
    }

    const getAssetSymptomMapping = (symptomId) => {
        let symptomDesc = '';

        if (Array.isArray(assetSymptoms)) {
            let s = assetSymptoms.find(x => x.questionSymptomMappingId == symptomId);
            if (s)
                symptomDesc = s.symptom;
        }

        return symptomDesc;
    }

    const getCoverageDoc = (contractDetails) => {
        const { riskProfileId, assetId, purchaseDate } = contractDetails;
        getCoverageDocument(clientId, riskProfileId, assetId, purchaseDate);
    }

    return (
        <DocumentTitle title={pageTitle}>
            <div className={`container contentWrapper ${styles.planContainer}`}>
                <section>
                    <a className={styles.viewLink} onClick={() => { backToMyPlans() }}>
                        <Chevron className={styles.arrowIcon} /> View All Plans
                    </a>
                    {showReprintReviewMessage && (
                        <Alert isOpen={showReprintReviewMessage} className={styles.reprintAlert}>
                            Your reprint request was submitted!
                        </Alert>
                    )}
                </section>
                <Card className='mb-3'>
                    <div>
                        <div className={styles.assetContainer}>
                            <div>
                                <h4 className='mb-1'>{asset?.assetName}</h4>
                                <div className={styles.contractInfo}>
                                    Contract Number: {asset?.contractNumber}
                                    <span>
                                        <AIGStatusBadge
                                            statusId={asset?.statusId}
                                            statusType='contract'
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={styles.assetIcon} >
                                {currentAsset?.icon}
                            </div>
                        </div>
                        <div className='p-4'>
                            <p>{currentAsset?.description}</p>
                            <div>
                                Plan Start: <b>{moment(asset?.planStartDate).format('MM/DD/YYYY')}</b>
                            </div>
                            {asset?.waitPeriodEnds &&
                                <div>
                                    Wait Period Ends: <b>{asset?.waitPeriodEnds}</b>
                                </div>
                            }
                            <div className={styles.contractActionsWrapper}>
                                <div className={styles.btnWrapper}>
                                    <button
                                        className='btn-secondary'
                                        onClick={() => fileNewClaim(asset)}
                                    >
                                        File New Claim
                                    </button>
                                    <button
                                        className='btn-secondary'
                                        disabled={asset?.statusId === 5}
                                        onClick={handleReprintContractModal}
                                    >
                                        Reprint Contract
                                    </button>
                                    <button
                                        className='btn-secondary'
                                        onClick={() => getCoverageDoc(asset)}
                                    >
                                        Terms and Conditions
                                    </button>
                                </div>
                                <div className={styles.cancelHelp}>
                                    <div>Need to cancel the contract?</div>
                                    <div>Call {getClientContent(clientId).variableText.sales.phone}</div>
                                </div>
                            </div>
                            <Accordion
                                id=''
                                question="What's Covered*"
                                answer={<CoveredInformation />}
                                type='component'
                            />
                            <Accordion
                                id=''
                                question="What's Not Covered"
                                answer={<NotCoveredInformation />}
                                type='component'
                            />
                        </div>
                    </div>
                </Card>
                <Card>
                    <CardHeader className={`h4 mb-0 ${styles.tableHeader}`}>Claim History</CardHeader>
                    <CardBody className={styles.tableBody}>
                        <BootstrapTable
                            keyField={'claimNumber'}
                            columns={claimHistoryColumns}
                            data={claimHistoryData || []}
                            bordered={false}
                            wrapperClasses='table-responsive'
                            classes={styles.claimsTable}
                            defaultSorted={defaultSortedBy}
                        />
                    </CardBody>
                </Card>
                {showReprintContractModal && (
                    <ReprintContractModal
                        isOpen={showReprintContractModal}
                        centered={true}
                        toggle={() => setShowReprintContractModal(!showReprintContractModal)}
                        isHome={true}
                        handleSubmit={handleReprintSubmit}
                    />
                )}
            </div>
        </DocumentTitle>
    )
}

export default ViewPlanDetails;
