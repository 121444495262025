import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, FormGroup, Label, Input } from 'reactstrap';
import styles from './SuggestedAddress.module.css';
import classNames from 'classnames';
import useTranslation from '../contexts/LanguageTranslation/useTranslation';
import { StaticAddress } from '../Vendor/Apple/IntakeLoss/CountryAddressForms';


let radioBoxWrapper = styles.radioBoxWrapper;
let noGutters = "no-gutters"

const SuggestedAddress = ({
    showSuggestedAddressValidationModal,
    enteredAddress,
    suggestedAddress,
    onEditAddress,
    onContinueAction,
    clientId
}) => {
    const international = useTranslation('components/modals/suggestedaddress');
    const { translations } = international
    const [modal, setModal] = useState(showSuggestedAddressValidationModal);
    const [selectedAddress, setSelectedAddress] = useState(`suggestedAddress`);

    const toggle = () => setModal(!modal);

    const onClose = (e) => {
        setModal(false);
        onEditAddress();
    }

    const onContinue = (e) => {
        setModal(false);
        onContinueAction(selectedAddress);
    }

    const onOptionChange = (e) => {
        setSelectedAddress(e.target.id);
    }

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <div>
            <Modal isOpen={modal} toggle={onClose} aria-modal="true" role="dialog" aria-labelledBy="suggest" className="modal-md">
                <ModalHeader toggle={onClose} className="noLine" closeAriaLabel={translations.closeAria ||"Close Modal"}></ModalHeader>
                <ModalBody>
                    <h4 className="modal-title text-center" id="suggest">{translations.title || 'Use Recommended Address'}</h4>
                    <p className="text-center">
                        {translations.text || (
                            <>
                                We found a more precise version of the address you entered. If it looks right,
                                <br className="show-md-up" />
                                please use the suggested address.
                            </>
                        )}

                    </p>
                    <fieldset role="group">
                        <legend>
                            <FormGroup className={classNames(noGutters, radioBoxWrapper)} role="group" id="addressSelectionGroup">
                                <Col md="7" className="mt-3">
                                    <p className={styles.addressRadioLabel} aria-label={translations.suggestedAddressAria || "Click to proceed with suggested address"}>{translations.suggestedAddress || 'Suggested Address'}</p>
                                    <div aria-label="Click to Proceed with Suggested Address" className={styles.radioBox} tabIndex="0">
                                        <Input type="radio" name="addressSelection" id="suggestedAddress" checked={selectedAddress === "suggestedAddress"}
                                            onChange={onOptionChange} />
                                        <Label className={styles.radioControlsLabel} for="suggestedAddress">
                                            <StaticAddress
                                                showCountry={clientId === 'apple' ? true : false}
                                                addressData={suggestedAddress}
                                                customClass="mb-0"
                                            />
                                        </Label>
                                    </div>
                                </Col>
                                <Col md="7">
                                    <p className={styles.addressRadioLabel} aria-label={translations.enteredAddressAria || "Click to proceed with entered address"} for="radioButtonExampleGroup">{translations.enteredAddress || 'Address Entered'}</p>
                                    <div className={styles.radioBox} aria-label="Click to Proceed with Entered Address" tabIndex="0">
                                        <Input type="radio" name="addressSelection" id="enteredAddress" checked={selectedAddress === "enteredAddress"}
                                            onChange={onOptionChange} />
                                        <Label className={styles.radioControlsLabel} for="enteredAddress">
                                            <StaticAddress
                                                showCountry={clientId === 'apple' ? true : false}
                                                addressData={enteredAddress}
                                                customClass="mb-0"
                                            />
                                        </Label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </legend>
                    </fieldset>
                </ModalBody>
                <ModalFooter className="linkBtnLayout">
                    <div className="btnWrapper d-flex">
                        <button className="btnLink" aria-label={translations.editAddressAria || "Click to Edit address in Shipping step"} onClick={onClose}>{translations.editAddress || 'Edit Address'}</button>{' '}
                        <button className="btn btn-primary" aria-label={translations.continueButtonAria || "Click to proceed payment step with adress selected "}  onClick={onContinue}>{translations.continue || 'Continue'}</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SuggestedAddress;