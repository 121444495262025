import React, { useState, createContext, useMemo, useEffect, useLayoutEffect, useContext } from 'react';
import { countryConstants, englishVariant } from './CountryConstants';
import { getCountryByUrl, getEnvironment } from '../../../helpers';
import useIsApple from '../../_ReactHooks/useIsApple';
import { ContractContext } from '../ContractContext';
const defaultLangByCountry = {
    US: 'en',
    AUS: 'en',
    UK: 'en',
    DE: 'de',
    ES: 'es',
    FR: 'fr',
    IT: 'it',
    VN: 'vn',
    DA: 'da',
    FI: 'fi',
    SG: 'sg',
    AT: 'at',
    NZ: 'en',
    DK: 'da',
    NL: 'nl',
    NO: 'no',
    SE: 'sv',
    CL: 'cl',
    CH: 'fr',
    IE: 'en'
}

const LanguageContext = createContext(null);

const LanguageProvider = ({children}) => {
    const isApple = useIsApple()
    const { setIsChileApple } = useContext(ContractContext);
    const [loading, setLoading] = useState(true)
    const [locale, setLocale] = useState({
        country: 'US',
        language: 'en'
    })
    const [languageBeforeLogin, setLanguageBeforeLogin] = useState('')
    const { country, language } = locale
    
    useLayoutEffect(() => {
        getAppleCountryByUrl();
    }, [isApple]);
    
    const getAppleCountryByUrl = async () => {
        const { countryCode } = await getCountryByUrl()
        const country = countryCode?.toUpperCase() || "US";
        const lang = defaultLangByCountry[country]
        //Set true if the country is Chile for Southbrige branding to be used
        country === 'CL' && setIsChileApple(true);

        setLocale({
            country,
            language: lang,
        })

        setLoading(false)
    }
    
    
    const englishVars = englishVariant[country === 'US' ? 'american' : 'british']
    const countryVars = countryConstants[country]

    if (loading) return null
    
    // console.log('=== language, country ===', locale)
    return (
        <LanguageContext.Provider 
            value={{
                country,
                language,
                englishVars,
                countryVars,
                languageBeforeLogin,
                updateCountry: (countryCode) => {
                    const countryCodeApplied = countryCode || 'US'
                    const defaultLanguage = defaultLangByCountry[countryCodeApplied]
                    // console.log('=== {countryCodeApplied, defaultLanguage} ===', {countryCodeApplied, defaultLanguage})
                    setLocale({
                        country: countryCodeApplied.toUpperCase(),
                        language: defaultLanguage,
                    })
                },
                updateLanguage: lang => {
                    setLocale(prevLocale => { return { ...prevLocale, language: lang } })
                },
                updateLanguageBeforeLogin: (lang) => {
                    setLanguageBeforeLogin(lang);
                }
            }}
        >
            {children}
        </LanguageContext.Provider>
    )
}

export { LanguageProvider, LanguageContext };