import React, { Component, useContext } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Input, Label, Col, Row, FormGroup, Form
} from 'reactstrap';
import { DropDownInput } from '../inputs/DropDownInput';
import { ContractContext } from '../../components/contexts/ContractContext';
import Spinner from 'react-bootstrap/Spinner';
import { formatPhoneNumber } from 'react-phone-number-input';
import NumberFormat from 'react-number-format';

export class CustomerInfoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            party: {},
            errors: {
                partyName: '',
                email: '',
                mobilePhone: '',
                homePhone: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: ''
            },
            states: [],
            saving : false
        }
        // base state
        this.baseState = {};

        this.toggle = this.toggle.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleMobile = this.handleMobile.bind(this);
        this.handleStateDropDown = this.handleStateDropDown.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    toggle() {
        this.setState({ modal: !this.state.modal });
        document.getElementById('cancel-button').focus()
    }

    componentWillMount() {
        this.setState({ party: this.props.party });

        fetch('api/Party/States/arw')
            .then(res => res.json())
            .then(data => {
                this.setState({
                    states: data
                }); 
            });
    }

    componentDidMount() {
        this.baseState = this.state;

        // SRV - looking back, this should have been designed a little differently, this is mainly to edit
        // an address and was first built WITH ARW in mind. HWA does not send us billing/mailing
        // in file, so we're going to stub one in to mock edit, then save back
        // first let's look for a billing address
        const index = this.state.party.partyAddressModel.findIndex(at => at.addressTypeID === 1);
        if (index === -1) { // no billing address
            //stub in a blank billing address for editing/adding
            this.state.party.partyAddressModel.push({
                partyId: this.state.party.partyId,
                addressTypeId: 1,
                address1: '',
                address2: null,
                city: '',
                state: '',
                stateId: 0,
                zip: '',
                zip4: ''
            });
        }
        console.log(this.state.party);
    }

    handleInputChange(e) {
        const { name, value } = e.target;
        console.log(name + " " + value);
        let errors = this.state.errors;

        switch (name) {
            case "partyName": {
                errors.partyName = value.length === 0 ? "Name is required." : ''
                break;
            }
        }

        this.setState({
            party: {
                ...this.state.party,
                [name]: value
            }
        });
    }

    handleEmail(e) {
        console.log(e.target.value);
        let errors = this.state.errors;
        errors.email = e.target.value.length === 0 ? 'Email is required.' : ''
        const val = e.target.value;
        const el = this.state.party.partyEmailModel.findIndex(el => el.id === 0);
        let newAr = [...this.state.party.partyEmailModel];
        newAr[0] = { ...newAr[0], address: val };
        console.log(newAr);
        this.setState({
            party: {
                ...this.state.party,
                partyEmailModel: newAr
            }
        });
    }

    cancel = () => {
        this.toggle();
    }

    handleMobile(e) {
        const { name, value } = e.target;
        console.log(value);
        let partyPhones = this.state.party.partyPhoneModel;
        let errors = this.state.errors;
        console.log(value.length);
        errors.mobilePhone = value.length === 0 ? "Mobile is required." : ""
        console.log(errors);
        const mobile = partyPhones.filter(pp => pp.phoneTypeId === 2);
        if (mobile.length === 0) {
            // no phones - add one
            var newPhone = {
                phoneTypeId: 2, // mobile
                number: value
            };
            let newAr = [...this.state.party.partyPhoneModel];
            newAr.push(newPhone);
            this.setState({
                party: {
                    ...this.state.party,
                    partyPhoneModel: newAr
                }
            });
        }
        else {
            const el = this.state.party.partyPhoneModel.findIndex(pm => pm.phoneTypeId === 2);
            let newAr = [...this.state.party.partyPhoneModel];
            newAr[el] = { ...newAr[el], number: value };
            this.setState({errors,
                party: {
                    ...this.state.party,
                    partyPhoneModel: newAr
                }
            });
        }
    }

    handleHome(e) {
        const { name, value } = e.target;
        console.log(value);
        let partyPhones = this.state.party.partyPhoneModel;
        let errors = this.state.errors;
        console.log(value.length);
        //errors.mobilePhone = value.length === 0 ? "Mobile is required." : ""
        console.log(errors);
        const home = partyPhones.filter(pp => pp.phoneTypeId === 1);
        if (home.length === 0) {
            // no phones - add one
            var newPhone = {
                phoneTypeId: 1, // home
                number: value
            };
            let newAr = [...this.state.party.partyPhoneModel];
            newAr.push(newPhone);
            this.setState({
                party: {
                    ...this.state.party,
                    partyPhoneModel: newAr
                }
            });
        }
        else {
            const el = this.state.party.partyPhoneModel.findIndex(pm => pm.phoneTypeId === 1);
            let newAr = [...this.state.party.partyPhoneModel];
            newAr[el] = { ...newAr[el], number: value };
            this.setState({
                errors,
                party: {
                    ...this.state.party,
                    partyPhoneModel: newAr
                }
            });
        }
    }

    handleAddress(e) {
        const { name, value } = e.target;
        let errors = this.state.errors;
        switch (name) {
            case "address1": {
                errors.address1 = value.length === 0 ? 'Mailing address is required.' : ''
                break;
            }
            case "city": {
                errors.city = value.length === 0 ? "City is required." : ""
            }
        }

        const el = this.state.party.partyAddressModel.findIndex(am => am.addressTypeId === 1);
        let newAr = [...this.state.party.partyAddressModel];
        newAr[el] = { ...newAr[el], [name]: value };
        this.setState({
            party: {
                ...this.state.party,
                partyAddressModel: newAr
            }
        });
    }

    handleStateDropDown(e) {
        const el = this.state.party.partyAddressModel.findIndex(am => am.addressTypeId === 1);
        let newAr = [...this.state.party.partyAddressModel];
        newAr[el] = { ...newAr[el], stateId: e };

        this.setState({
            party: {
                ...this.state.party,
                partyAddressModel: newAr
            }
        });
    }

    validateForm = (errors) => {
        this.validateAddress();
        let valid = true;
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateAddress() {
        let errors = this.state.errors;
        console.log(this.state.party);
        var ba = this.state.party.partyAddressModel.filter(a => a.addressTypeId === 1)[0];
        errors.address1 = ba.address1 === null || ba.address1.length === 0 ? "Mailing Address is required." : "";

        this.setState({ errors: errors });
    }

    sendBackYourInfo = () => {
        this.props.collectUpdate(this.state.party);
    }

    save = () => {
        this.setState(prevState => ({
            saving: !prevState.saving
        }));
        if (this.validateForm(this.state.errors)) {
            //console.info("Valid Form");
            fetch('api/party/' + this.state.party.partyId+'/arw', {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.party)
            }).then(res => {
                if (res.ok) {
                    res.json().then(data => {
                        this.setState(prev => ({
                            party: data,
                            saving: !prev.saving,
                                modal: !prev.modal
                            }));
                        let value = this.context;
                        value.updateParty(data);
                        this.sendBackYourInfo(data);
                    })
                } else {
                    this.toggle();
                }
            });
        }
        else {
            this.setState(ps => ({ saving: !ps.saving }));
            console.error("Invalid Form");
        }
    }

    render() {
        let partyEmail = this.state.party.partyEmailModel[0];
        let party = this.state.party;
        let mobilePhone = this.state.party.partyPhoneModel.filter(m => m.phoneTypeId === 2)[0];
        let homePhone = this.state.party.partyPhoneModel.filter(h => h.phoneTypeId === 1)[0];
        let billingAddress = this.state.party.partyAddressModel.filter(am => am.addressTypeId === 1)[0];

        // if billing address doesn't exist, default the object to nulls so page renders.
        if (typeof billingAddress === "undefined") {
            billingAddress = {
                address1: null, address2: null, city: null, stateId: null, zip: null
            }
        }

        let errors = this.state.errors;
        let stateOptions = this.state.states.map(s => {
            return {
                data: s.stateId,
                display: s.name
            }
        });
        return (
            <div>
                <Button color="secondary" aria-label="Opens a modal to edit and save your information" tabIndex="0" id="cancel-button" onClick={this.toggle}>Edit</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} aria-modal="true" aria-labelledBy="information" className="modal-md" role="dialog">
                    <ModalHeader toggle={this.toggle} id="information">Your Information</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md="6" className="pr-11">
                                    <FormGroup>
                                        <Label htmlFor="customerName" className="form-label">Name</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <Input type="text" className="form-control" id="customerName" defaultValue={party.partyName}
                                            name="partyName" onChange={this.handleInputChange}/>
                                        {errors.partyName.length > 0 &&
                                            <div className="error-msg" aria-live="polite">Name is required.</div>}
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="pl-11">
                                    <FormGroup>
                                        <Label htmlFor="customerEmail" className="form-label">Email</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <Input type="email" className="form-control" id="customerEmail"
                                            defaultValue={typeof partyEmail == "undefined" || partyEmail == null ? "" : partyEmail.address} name="email"
                                            onChange={this.handleEmail}/>
                                        {errors.email.length > 0 &&
                                            <div className="error-msg" aria-live="polite">Email is required.</div>}
                                        {/*<div className="error-msg">Please enter a valid email address.</div>*/}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="pr-11">
                                    <FormGroup>
                                        <Label htmlFor="customerMobile" className="form-label">Mobile Phone</Label>                                                                             
                                        <NumberFormat id="customerMobile" className="form-control"
                                            value={typeof mobilePhone === "undefined" ? "" : mobilePhone.number} displayType={"tel"} format="###-###-####"
                                            onChange={this.handleMobile}
                                        />

                                        {errors.mobilePhone.length > 0 &&
                                            <div className="error-msg" aria-live="polite">Please enter at least one phone number.</div>}
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="pl-11">
                                    <FormGroup>
                                        <Label htmlFor="customerPhone" className="form-label">Alternate Phone</Label>
                                        <NumberFormat id="customerPhone" className="form-control" value={typeof homePhone === "undefined" ? "" : homePhone.number}
                                            onChange={this.handleHome} display={"tel"} format="###-###-####"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="mailingAddress" className="form-label">Mailing Address</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <Input type="text" className="form-control" id="mailingAddress"
                                            value={typeof billingAddress === "undefined" ? "" : billingAddress.address1} name="address1"
                                            onChange={this.handleAddress}
                                        />
                                        {errors.address1.length > 0 &&
                                            <div className="error-msg" aria-live="polite">Mailing address is required.</div>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="mailingAddress2" className="form-label">Address Line 2</Label>
                                        <Input type="text" className="form-control" id="mailingAddress2"
                                            placeholder="Apt., Suite, Bldg." name="address2"
                                            defaultValue={billingAddress.address2 === null ? "" : billingAddress.address2}
                                            onChange={this.handleAddress}
                                        name="address2"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="pr-11">
                                    <FormGroup>
                                        <Label htmlFor="mailingCity" className="form-label">City</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <Input type="text" className="form-control" id="mailingCity"
                                            defaultValue={billingAddress.city} name="city"
                                            onChange={this.handleAddress} />
                                        {errors.city.length > 0 &&
                                            <div className="error-msg" aria-live="polite">City is required.</div>}
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="pl-11">
                                    <FormGroup>
                                        <Label htmlFor="mailingState" className="form-label">State</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                         <DropDownInput name="states"
                                            options={stateOptions}
                                            className="form-control"
                                            onChangedValue={this.handleStateDropDown}
                                            defaultValue={billingAddress.stateId}
                                            placeholder="Please select..." />
                                        {errors.state.length > 0 &&
                                            <div className="error-msg" aria-live="polite">State is required.</div>}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="pr-11">
                                    <FormGroup>
                                        <Label htmlFor="mailingPostal" className="form-label">Zip/Postal</Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>
                                        <Input type="text" className="form-control" id="mailingPostal"
                                            value={billingAddress.zip} name="zip" maxLength="5"
                                            onChange={this.handleAddress}
                                        />
                                        {errors.zip.length > 0 &&
                                            <div className="error-msg" aria-live="polite">Zip/Postal code is required.</div>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="button-link" onClick={this.cancel}>Cancel</button>
                        <Button className="save" color="primary" onClick={this.save}>
                            <Spinner color="light"
                                animation={this.state.saving ? "border" : ""}
                            />
                            {this.state.saving ? "" : "Save"}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

CustomerInfoModal.contextType = ContractContext;