import React, { Component, useEffect, useContext } from "react";
import { Container } from "reactstrap";
import styles from "./ContactUs.module.css";
import { ContactUsForm } from "./ContactUsForm";
import { useHistory } from "react-router-dom";
import useTranslation from "../../../components/contexts/LanguageTranslation/useTranslation";
import useLoadAtTop from "../../../components/_ReactHooks/useLoadAtTop";
import DocumentTitle from 'react-document-title';
import { useAdobeAnalytics } from "../../../components/hooks/useAdobeAnalytics";
import { ContractContext } from '../../../components/contexts/ContractContext';
import getClientContent from "../../../components/_BrandingProvider/brandContent";

const ContactUs = () => {
    useLoadAtTop()
    const history = useHistory()
    const international = useTranslation('screens/Vendor/Apple/ContactUs')
    const { callAdobeAnalyticsData } = useAdobeAnalytics();
    const { translations, englishVars } = international;
    const { data: { clientId } } = useContext(ContractContext);
    const clientTextValues = getClientContent(clientId);
    const title = clientTextValues?.variableText?.pageTitle;

    const pageTitle = title ? `Contact Us | ${title}` : 'Contact Us';


    useEffect(() => {

        // FOR ADOBE ANALYTICS
        callAdobeAnalyticsData({
            pagePath: '/vendor/apple/contactus',
            pageName: 'Contact Us'
        })
    }, []);

    const handleFAQRoute = () => {
        history.push("/vendor/apple/Faqs");
    };

    // NOT IMMEDIATELY CLEAR WHAT THIS IS FOR. MAY POTENTIALLY BE ELIGIBLE FOR REMOVAL
    const fetchtheAgentPortalHostname = url => {
        if (url.includes("localhost")) {
            return "https://localhost:44385";
        } else if (url.includes("dev")) {
            return "https://dev.warrantyinfoservhub.com";
        } else if (url.includes("qa")) {
            return "https://qa.warrantyinfoservhub.com";
        } else {
            return "https://warrantyinfoservhub.com";
        }
    }

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <DocumentTitle title={pageTitle}>
            <>
                <div className={styles.pageHeader}>
                    <Container className={styles.pageHeaderContent}>
                        <h1>{translations.title || 'Need More Information?'}</h1>
                        <p>
                            {translations.heroP1 || `Our Service ${englishVars.center} Representatives are ready to answer your questions.`}
                        </p>
                        {/* 
                  COMMENTING AND NOT REMOVING FAQ TEXT BELOW BECAUSE STORY WAS UNCLEAR WHETHER THIS 
                  APPLIES TO ALL COUNTRIES OR ONLY SOME. REVISIT TO DELETE AT A LATER DATE. 
                  --NPB 11-5-21
                */}
                        {/* <p>
                    {translations.heroP2BeforeLink || 'You may also find answers within our '}
                    <a onClick={handleFAQRoute}>
                        {translations.faqLinkText || 'FAQs'}
                    </a>
                    {translations.heroP2AfterLink || ''}.
                </p> */}
                    </Container>
                </div>
                <Container className={styles.contactUsFormWrapper}>
                    <div className={styles.helpForm}>
                        <ContactUsForm />
                    </div>
                </Container>
            </>
        </DocumentTitle>
    )
}

export default ContactUs