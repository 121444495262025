import React, { Component } from 'react';
import {
    Row, Form, Button
} from 'reactstrap';
import styles from './UploadDoc.module.css';
import classNames from 'classnames';
import SaveModal from '../../../../components/modals/SaveModal';
import Dropzone from './DragandDropFileUpload';
import { Progress } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import DocumentTitle from 'react-document-title';
import { ReactComponent as Info } from '../../../../assets/images/info-icon-blk.svg';
import { updateClaimConfiguration } from '../../../../helpers'
import moment from 'moment';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent';
import InternationalPhoneStatic from '../../../shared/PhoneNumberUtility/InternationalPhoneStatic'
import IntakeLossButtons from '../IntakeLoss/IntakeLossButtons';
import { ToSpeakWithARepresentative } from '../IntakeLoss/ConfirmationLT';
import { ContractContext } from '../../../contexts/ContractContext';
import PaperIcon from '../../../../assets/images/paper.svg';

let dSmFlex = "d-sm-flex";
let btmLine = styles.btmLine;


export class UploadDocs extends Component {

    constructor(props) {

        super(props);
        this.state = {
            statusType: this.props?.statusType,
            contractNumber: "",  // case number
            client: typeof this.props?.history?.location?.state === "undefined" ? "" : this.props?.history?.location?.state?.clientId,
            countryCode: this.props?.history?.location?.state?.entitlementDetails?.countryCode,
            claimId: this.props?.claimId,
            claimNumber: this.props?.claimNumber,
            clientId: this.props?.clientId,
            isUpdatebtnDisable: true,
            selectedDoc: "",
            fileName: "",
            fileUploadedPercentage: 0,
            pendingDocs: false,
            isGovtDoc: true,
            isFraud: typeof this.props?.history?.location.state === "undefined" || typeof this.props?.history?.location?.state?.isFraud === "undefined" ? true : this.props?.history?.location?.state?.isFraud,
            additionalReview: typeof this.props?.history?.location.state === "undefined" || typeof this.props?.history?.location?.state?.additionalReview === "undefined" ? false : this.props?.history?.location?.state?.additionalReview,
            isClaimOverview: false,
            ccNumber: "",
            ccType: "",
            model: {
                DocumentId: 0,
                DocumentTypeId: 1,
                DocumentFileTypeId: 1,
                DocumentName: "test",
                DocumentData: [],
                CreatedBy: "CustomerPortal",
                CreatedDate: moment().format(),
                ModifiedBy: "",
                ModifiedDate: moment().format()

            },
            invalidDoc: false,
            caseNumber: this.props.caseNumber,
            uploading: false,
            pageTitle: 'Upload Documents | AIG AppleCare+ with Theft and Loss Claims'
        }

        this.uploadDocument = this.uploadDocument.bind(this);
        this.viewConfirmation = this.viewConfirmation.bind(this);
        this.saveForLater = this.saveForLater.bind(this);
        this.saveForLaterAction = this.saveForLaterAction.bind(this);
        this.onSaveForLaterAction = this.onSaveForLaterAction.bind(this);
    }


    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState({ isSaveForLater: true }, () => { this.saveForLaterAction(this.props.history.location.state.claimId) });
    }

    saveForLaterAction = (claimId) => {
        // What should happen here?
        if (!claimId) { return; }
        this.setState({ uploading: true });
        fetch(`api/claim/SaveForLater/${claimId}/apple/4/0`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ claimNumber: this.props.history.location.state.claimNumber, createdBy: "SaveClaim" })
        }).then(data => {
            if (!data.ok) {
                throw new Error(`HTTP error! Status: ${data.status}`);
            }
            if (data.status === 204) {
            }
            if (data.status === 200) {
                this.props.history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: this.props.history.location.state.caseNumber });
            }
            this.setState({ uploading: false, isSaveForLater: false });
        }).catch(e => {
            this.setState({ uploading: false, isSaveForLater: false });
        })
    }

    componentDidMount() {

        this.setState(() => ({
            /// statusType: this.props.location.state.statusType == undefined ? ConfirmationStatus.approved : this.props.location.state.statusType,
            contractNumber: this.props?.contractNumber == undefined ? "230404953" : this.props?.contractNumber,
            client: this.props?.client == undefined ? "apple" : this.props.client,
            claimNumber: typeof this.props?.history?.location?.state === "undefined" ? "" : this.props?.history?.location?.state?.claimNumber,
            clientId: this.props?.clientId == undefined ? "1" : this.props?.clientId,
            claimId: this.props?.history?.location?.state?.claimId == undefined ? false : this.props?.history?.location?.state?.claimId,
            caseNumber: this.props?.history?.location?.state?.caseNumber == undefined ? false : this.props?.history?.location?.state?.caseNumber,
            pendingDocs: this.props?.pendingDocs == undefined ? false : this.props.pendingDocs,
            isClaimOverview: this.props?.isClaimOverview == undefined ? false : this.props.isClaimOverview,
            isFraud: typeof this.props?.history?.location?.state === "undefined" || typeof this.props?.history?.location?.state?.isFraud === "undefined" ? true : this.props?.history?.location?.state?.isFraud,
            ccNumber: typeof this.props?.history?.location?.state === "undefined" ? false : this.props?.history?.location?.state?.ccNumber,
            ccType: typeof this.props?.history?.location?.state === "undefined" ? false : this.props?.history?.location?.state?.ccType,
            additionalReview: typeof this.props?.history?.location?.state === "undefined" || typeof this.props?.history?.location?.state?.additionalReview === "undefined" ? false : this.props?.history?.location?.state?.additionalReview,
        }));
    }
    uploadDocument = e => {
        e.preventDefault();

        this.setState({ uploading: true });
        let statusCode = '';
        fetch('api/document/'.concat(this.state.client), {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                DocumentId: 0,
                DocumentTypeId: 1,
                DocumentFileTypeId: 1,
                DocumentName: this.state.fileName,
                DocumentData: this.state.selectedDoc,
                CreatedBy: "CustomerPortal",
                CreatedDate: moment().format(),
                ModifiedBy: "",
                ModifiedDate: moment().format(),
                ObjectDocumentMappingModel: [{
                    WarrantyObjectValue: parseInt(this.state.claimId),
                    WarrantyObjectTypeId: 2,
                    DocumentId: 0


                }]

            })
        })
            .then(function (res) {
                // this.setState({ isUpdatebtnDisable: true }); //disabling upload button aftrer file upload button click
                statusCode = res.status;
                return res.json();
            })
            .then(data => {
                if (statusCode === 204) {
                }
                if (statusCode === 200) {
                    updateClaimConfiguration(parseInt(this.state.claimId), 17, "false", this.state.client).then(res => {
                        if (res.ok && res.status === 200) {
                            if (!this.state.isClaimOverview) {
                                this.props.history.push('/vendor/apple/confirmation', { statusType: "docsUploaded", claimId: this.state.claimId, caseNumber: this.state.caseNumber, ccNumber: this.props.history.location.state.ccNumber, ccType: this.props.history.location.state.ccType });
                            }
                            else {
                                this.props.onDocumentUploadSuccess(true);
                            }
                        }
                        this.setState({ uploading: false });
                    });
                }
            });
    }

    // On file select (from the pop up) 



    viewConfirmation = e => {
        e.preventDefault();
        this.props.history.push('/vendor/apple/confirmation', {
            isFraud: true,
            statusType: "docsPending",
            claimId: typeof this.props.history.location.state === "undefined" ? false : this.props.history.location.state.claimId,
            caseNumber: this.props.history.location.state.caseNumber,
            ccNumber: this.props.history.location.state.ccNumber,
            ccType: this.props.history.location.state.ccType,
            claimNumber: this.props.history.location.state.claimNumber,
            entitlementDetails: this.props.history.location.state.entitlementDetails
        });

    }


    callBackFuncFileUpload = (fileData) => {

        if (fileData && fileData.length > 0) {
            const currentFile = fileData[0];
            this.setState(state => ({ fileName: currentFile.name }));

            const reader = new FileReader();

            reader.addEventListener("load", () => {

                this.setState(state => ({ selectedDoc: reader.result }));
                this.setState({ isUpdatebtnDisable: false });

            }, false)
            reader.addEventListener("abort", (ev) => {
                console.log("file aborted", ev);
            }, false)
            reader.addEventListener("error", (ev) => {
                console.log("file error", ev);
            }, false)

            reader.addEventListener("progress", (event) => {
                console.log("total" + event.total);
                console.log("progress" + event.loaded);
                this.setState({
                    fileUploadedPercentage: Math.round((event.total / event.loaded) * 100)
                });


            })

            reader.readAsDataURL(currentFile);
            this.setState({ invalidDoc: false });
        }
        else {
            //alert("The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)");
            this.setState({ invalidDoc: true });
        }


    }

    onRemoveFile = event => {

        this.setState(state => ({ selectedDoc: '' }));
        this.setState({ isUpdatebtnDisable: true });
        this.setState({
            fileUploadedPercentage: 0
        });

    }
    render() {
        const { pageTitle } = this.state;
        const { data: { isChileApple } } = this.context;
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/UploadDocs/UploadDocs'}>
                {international => {
                    const {
                        translations,
                        countryVars: {
                            currency
                        },
                        englishVars: {
                            proofOfIdTitle,
                            proofOfIdDescription
                        }
                    } = international;
                    return (
                        <DocumentTitle title={pageTitle}>
                            <>
                                <section id="uploadDocs">
                                    <h1 className={classNames(dSmFlex, this.state.isClaimOverview ? btmLine : "")}>{translations.pageTitle || 'Claim in Review'} <span className={styles.badgeAlert}>{translations.actionRequired || 'Action Required'}</span></h1>
                                    <p className="mb-0">{translations.coverage || 'To process your claim, we need you to upload one of the the following document(s):'}</p>
                                    <ul className={styles.docsReq}>
                                        {(this.state.isFraud || this.state.additionalReview) &&
                                            <li className="proofPurchase" aria-label={translations.proofOfPurchaseAria || "Documentation that confirms you’ve paid for the device you’re requesting to replace"}>{translations.proofOfPurchase || 'Proof of Purchase'}
                                                <Button
                                                    className={styles.tooltipButton}
                                                    onClick={() => { ReactTooltip.show(this.tooltip1Ref) }}
                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip1Ref)}
                                                    role="button"
                                                ><Info ref={ref => this.tooltip1Ref = ref} data-tip data-for='tooltip1' aria-label="Help text" className={styles.infoIcon} /></Button>
                                                <ReactTooltip
                                                    id='tooltip1'
                                                    place="top" aria-haspopup='true'
                                                    role="tooltip"
                                                    aria-label={translations.proofOfPurchaseAria || "Documentation that confirms you’ve paid for the device you’re requesting to replace."}
                                                    className={styles.tooltiptext}
                                                    effect="solid"
                                                    type="light"
                                                    clickable={true}
                                                    border={true}>
                                                    <b>{translations.proofOfPurchase || 'Proof of Purchase'}</b> - {translations.proofOfPurchaseAria || "Documentation that confirms you’ve paid for the device you’re requesting to replace"}
                                                </ReactTooltip>
                                            </li>
                                        }
                                        {(!this.state.isFraud) &&
                                            <li className="proofAddress" aria-label="Proof of Address - Documentation that confirms where you live (must have your full name and address listed)">Proof of Address
                                                <Button
                                                    role="button"
                                                    className={styles.tooltipButton}
                                                    onClick={() => { ReactTooltip.show(this.tooltip2Ref) }}
                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip2Ref)}
                                                ><Info ref={ref => this.tooltip2Ref = ref} data-tip data-for='tooltip2' aria-label="Help text" className={styles.infoIcon} /></Button>
                                                <ReactTooltip
                                                    id='tooltip2'
                                                    aria-haspopup='true'
                                                    place="top"
                                                    role="tooltip"
                                                    aria-label="Documentation that confirms where you live (must have your full name and address listed)."
                                                    className={styles.tooltiptext}
                                                    effect="solid"
                                                    type="light"
                                                    border={true}>
                                                    <b>{translations.proofOfAddress || 'Proof of Address'}</b> - Utility Bill or Bank Statement
                                                </ReactTooltip>
                                            </li>
                                        }
                                        {(this.state.isFraud && !this.state.additionalReview) &&
                                            <li className="govID" aria-label={`${translations.proofOfId || 'Proof of ID'} - ${translations.proofOfIdDescription || proofOfIdDescription}`}>{translations.proofOfId || 'Proof of ID'}
                                                <Button
                                                    role="button"
                                                    className={styles.tooltipButton}
                                                    onClick={() => { ReactTooltip.show(this.tooltip3Ref) }}
                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip3Ref)}
                                                ><Info data-tip data-for='tooltip3' aria-label="Help text" className={styles.infoIcon} />
                                                </Button>
                                                <ReactTooltip
                                                    id='tooltip3'
                                                    className={styles.tooltiptext}
                                                    effect="solid"
                                                    type="light"
                                                    place="top"
                                                    border={true}
                                                >
                                                <b>{translations.proofOfId || 'Proof of ID'}</b> - {translations.proofOfIdDescription || proofOfIdDescription}
                                                </ReactTooltip>
                                            </li>
                                        }
                                        {(this.state.additionalReview) &&
                                            <li className="wirelessBill" aria-label="Wireless Bill - Monthly statement of services and charges from your wireless carrier for the device you're requesting to replace">Wireless Bill
                                                <Button
                                                    role="button"
                                                    className={styles.tooltipButton}
                                                    onClick={() => { ReactTooltip.show(this.tooltip4Ref) }}
                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip4Ref)}
                                                ><Info data-tip data-for='tooltip4' aria-label="Help text" className={styles.infoIcon} /></Button>
                                                <ReactTooltip
                                                    id='tooltip4'
                                                    aria-haspopup='true'
                                                    role="tooltip"
                                                    className={styles.tooltiptext}
                                                    effect="solid"
                                                    type="light"
                                                    place="top"
                                                    border={true}>
                                                    <b>Wireless Bill</b> - Monthly statement of services and charges from your wireless carrier for the device you're requesting to replace
                                                </ReactTooltip>
                                            </li>
                                        }
                                        {(!this.state.isFraud) &&
                                            <li className="utilityBill" aria-label="Utility Bill - Monthly statement of services and charges from the utility company supplying your service at the address you provided (must have your full name and address listed)">Utility Bill
                                                <Button
                                                    role="button"
                                                    className={styles.tooltipButton}
                                                    onClick={() => { ReactTooltip.show(this.tooltip4Ref) }}
                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip4Ref)}
                                                ><Info data-tip data-for='tooltip5' aria-label="Help text" className={styles.infoIcon} />
                                                </Button>
                                                <ReactTooltip
                                                    id='tooltip5'
                                                    aria-haspopup='true'
                                                    role="tooltip"
                                                    className={styles.tooltiptext}
                                                    effect="solid"
                                                    type="light"
                                                    place="top"
                                                    border={true}>
                                                    <b>Utility Bill</b> - Monthly statement of services and charges from the utility company supplying your service at the address you provided (must have your full name and address listed)
                                                </ReactTooltip>
                                            </li>
                                        }
                                        {(!this.state.isFraud) &&
                                            <li className="cardStatement" aria-label="Card Statement - Main page of your monthly statement of services and charges from the credit card company of the card you provided to pay any required claim fees (must have your full name, the last four digits of the card number, and the credit card billing address)">Card Statement
                                                <Button
                                                    role="button"
                                                    className={styles.tooltipButton}
                                                    onClick={() => { ReactTooltip.show(this.tooltip4Ref) }}
                                                    onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltip4Ref)}
                                                ><Info data-tip data-for='tooltip6' aria-label="Help text" className={styles.infoIcon} />
                                                </Button>
                                                <ReactTooltip
                                                    id='tooltip6'
                                                    aria-haspopup='true'
                                                    role="tooltip"
                                                    className={styles.tooltiptext}
                                                    effect="solid"
                                                    type="light"
                                                    place="top"
                                                    border={true}>
                                                    <b>Card Statement</b> - Main page of your monthly statement of services and charges from the credit card company of the card you provided to pay any required claim fees (must have your full name, the last four digits of the card number, and the credit card billing address)
                                                </ReactTooltip>
                                            </li>
                                        }
                                    </ul>
                                    <p>
                                        {isChileApple ? (
                                            translations.cardNotChargedUntil || 'Your card will not be charged until your claim has been approved.'
                                        ) : (
                                                <b>{translations.cardNotChargedUntil || 'Your card will not be charged until your claim has been approved.'}</b>
                                            )}
                                    </p>
                                    <Form>
                                        <Dropzone onParentFileUpload={this.callBackFuncFileUpload}></Dropzone>

                                        {!this.state.isUpdatebtnDisable && <div className={styles.uploadProgress}>
                                            <img className={styles.file} src={PaperIcon} role="presentation"/>
                                            <div className={styles.progressWrapper}>
                                                <div className={styles.fileName}>{this.state.fileName}</div>
                                                <div className='w-100'>
                                                <div class={styles.uploadProgressWrapper}>
                                                    <Progress
                                                        className={styles.progress}
                                                        value={this.state.fileUploadedPercentage}
                                                        barClassName={styles.progressBar}
                                                    />
                                                    <button
                                                        onClick={this.onRemoveFile}
                                                        aria-label={translations.cancelUploadAria || "Cancel Uploaded File"}
                                                        className={styles.closeButton}
                                                    ></button>
                                                </div>
                                                    <div className={styles.uploadPercentage}>
                                                        {translations.progress1 || "Upload"}
                                                        <span> {this.state.fileUploadedPercentage}% {translations.progress2 || "completed"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {this.state.invalidDoc &&
                                            <p className="validationSummary">The file is either larger than 4MB or is not an acceptable file type (.jpg, .jpeg, .png, or .pdf)</p>
                                        }

                                        <ToSpeakWithARepresentative caseNumber={this.state.caseNumber} countryCode={this.state.countryCode} />
                                        <Row className="formBlock">
                                            <IntakeLossButtons
                                                // PRIMARY BUTTON PROPS
                                                buttonText={translations.continue || "Continue"}
                                                onButtonClick={(e) => this.uploadDocument(e)}
                                                buttonDisabled={this.state.isUpdatebtnDisable}
                                                buttonSpinning={this.state.uploading}
                                                buttonAriaLabel={translations.continueBtnAria || "Click to submit documents for review"}
                                                // FIRST LINK UNDER BUTTON
                                                showLink1={!this.state.pendingDocs}
                                                onLink1Click={this.viewConfirmation}
                                                link1AriaLabel={translations.uploadLaterAria || "Saves claim so documents can be uploaded later"}
                                                link1Text={translations.uploadLater || "Upload Later"}
                                                // CANCEL LINK PROPS CONTROL CANCEL MODAL
                                                saveClaimClickActionFromWithinCancelModal={this.onSaveForLaterAction}
                                                caseNumberToCancel={this.state.caseNumber}
                                                claimNumberToCancel={this.state.claimNumber}
                                                hideCancelClaimLink={!!this.props.isClaimOverview}
                                                isSubmittedClaim={this.props.isSubmittedClaim}
                                                hideCancelClaimButton={this.props.hideCancelClaimButton}
                                                isUploadDocs={true}
                                            />

                                            {/* 
                                                CODE ABOVE SEEMS TO BE WORKING PROPERLY AS OF 08/02/21. 
                                                THE BELOW CODE IS OLD VERSION. DUE TO DEADLINES, PRESERVING IN CASE
                                                THINGS BREAK. DELETE IF STILL FUNCTIONING AFTER NEXT ROUND OF UAT
                                                IN AUGUST 2021.
                                                -NPB
                                            */}

                                            {/* <div className="btnWrapper">
                                                <button className={classNames("btn", "btn-primary", submitBtn)} onClick={(e) => this.uploadDocument(e)} disabled={this.state.isUpdatebtnDisable} aria-label="Click to submit documents for review">
                                                    <Spinner color="light" size="sm" animation={this.state.uploading ? "border" : ""} />
                                                    {this.state.uploading ? "" : "Submit"}</button>
                                                {!this.state.pendingDocs && < a href="#" onClick={this.viewConfirmation} className={classNames("btnLink", ml15)} aria-label="Saves claim so documents can be uploaded later">Upload Later</a>}
                                                <CancelModal buttonClassName={classNames("btnLink", ml15)} buttonLabel="Cancel Claim" aria-label="Opens a modal to cancel your claim" isButton={false} history={this.props.history} caseNumber={this.state.caseNumber}
                                                    claimNumber={this.state.claimNumber} onSaveForLaterAction={this.onSaveForLaterAction} />
                                            </div> */}

                                            {this.state.showConfirmation &&
                                                <SaveModal
                                                    isOpen={this.state.showConfirmation}
                                                    showConfirmation={this.state.showConfirmation}
                                                    history={this.props.history}
                                                    continuing={this.state.isSaveForLater}
                                                    onCancel={this.onSaveCancel}
                                                    onSaveForLaterAction={this.saveForLater}
                                                    client={"apple"}
                                                />
                                            }
                                        </Row>
                                    </Form>
                                </section>
                            </>
                        </DocumentTitle>
                    );
                }
                }
            </UseTranslationInClassComponent>
        );
    }
}
UploadDocs.contextType = ContractContext