import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import classNames from "classnames";
import styles from "./RecommendedAddressModal.module.css";
import FooterButtonsRow from "../shared/FooterButtonsRow/FooterButtonsRow";

let radioBoxWrapper = styles.radioBoxWrapper;
let noGutters = "no-gutters";

const IncorrectAddressValidationModal = (props) => {


  const { enteredAddress, hideModal } = props;
  const [modal, setModal] = useState(true);
  const [showServiceAddress, setShowServiceAddress] = useState(true);
  const [showMailingAddress, setShowMailingAddress] = useState(false);
  const [showConfirmAddressModal, setShowConfirmAddressModal] = useState(false);

  const handleContinue = (e) => {
    setShowServiceAddress(false);
    setShowMailingAddress(true);
    if (showMailingAddress) {
      setShowMailingAddress(false);
      hideModal();
    }
  };

  const handleHideModal = (e) => {
    hideModal();
  };

  const AddressText = ({ addressObj }) => {
    return (
      <div>
        {`${addressObj?.address1}`}
        <br />
        {typeof addressObj?.address2 === "string" &&
          addressObj?.address2.trim().length > 0 && (
            <>
              {addressObj?.address2}
              <br />
            </>
          )}
        {`${addressObj?.city}, ${addressObj?.state}`}{" "}
        {`${addressObj?.zip}${addressObj?.plus4 ? -addressObj?.plus4 : ""}`}
      </div>
    );
  };

  return (
    <div>
      <div>
        <Modal
          isOpen={modal}
          aria-modal="true"
          role="dialog"
          aria-hidden="true"
          aria-labelledBy="suggest"
          className="md"
        >
          <ModalHeader>Recommended Address</ModalHeader>
          <ModalBody>
            {showServiceAddress && (
              <div>
                <h3 className={styles.addressH3}>Service Address</h3>
                <p className={styles.addressParagraph}>
                  We were unable to confirm the address you entered with the
                  United States Postal Service.
                  <br /> Please update the address or confirm it is correct.
                </p>
                <FormGroup
                  className={classNames(noGutters, radioBoxWrapper)}
                  role="group"
                  id="addressSelectionGroup"
                >
                  <Col md="7" className="mt-3">
                    <p className={styles.addressRadioLabel}>You Entered:</p>
                    <div className={styles.radioBox}>
                      <div
                        className={styles.addressContainer}
                      >
                        <AddressText
                          addressObj={enteredAddress.serviceAddress}
                        />
                      </div>
                    </div>
                  </Col>
                </FormGroup>
              </div>
            )}
            {showMailingAddress && (
              <div>
                <h3 className={styles.addressH3}>Mailing Address</h3>
                <p className={styles.addressParagraph}>
                  We were unable to confirm the address you entered with the
                  United States Postal Service.
                  <br /> Please update the address or confirm it is correct.
                </p>
                <FormGroup
                  className={classNames(noGutters, radioBoxWrapper)}
                  role="group"
                  id="addressSelectionGroup"
                >
                  <Col md="7" className="mt-3">
                    <p className={styles.addressRadioLabel}>You Entered:</p>
                    <div className={styles.radioBox}>
                      <div
                        className={styles.addressContainer}
                      >
                        <AddressText
                          addressObj={enteredAddress.mailingAddress}
                        />
                      </div>
                    </div>
                  </Col>
                </FormGroup>
              </div>
            )}

            {showConfirmAddressModal && <div>Confirmation Address</div>}
          </ModalBody>
          <ModalFooter className={styles.modalFooterButtonRow}>
            <FooterButtonsRow
              isModal={true}
              buttons={{
                link: {
                  text: "Continue with Entered address",
                  onClick: handleContinue,
                  ariaLabel: "Continue with entered address",
                  type: "link",
                },
                primary: {
                  text: "Edit Address",
                  ariaLabel:
                    "Click edit address to close modal and return to customer information page",
                  onClick: handleHideModal,
                  type: "primary",
                },
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default IncorrectAddressValidationModal;
