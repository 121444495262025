import React, { useReducer, createContext } from 'react';
import { fAKe_dAtA } from './testdata';
import moment from 'moment';

// THIS IS THE DEFAULT STATE. 
const initialState = {
    appointment: {
        date: null,
        window: null,
    },
    servicerData: null,
    viewAllServicers: false,
    selectedServicer: null,
    notesForServicer: null,
    noAppointmentsFound: false,
    noServicersFound: false,
    // for situations where no servicers in the area.
    suggestedServicer: {
        name: null,
        phone: null,
    },
    // declares user choice when no servicers found.
    // will either be "hire" | "recommend" | "find"
    noServicerAlternative: null,
    acceptReimbursementTerms: {
        accepted: false,
        timestamp: null,
    },
    // reset isInternalPortal to FALSE when using this in CustomerPortal (user cannot select servicer)
    // hard code this in each solution; 
    // DO NOT UPDATE with reducer dispatch method.
    isInternalPortal: false,
}

const apptsReducer = (state, action) => {
    // console.log('USING REDUCER', {state, action})
    switch (action.type) {
        case 'UPDATE_DATA':

            // no data received
            if (!action.data) {
                console.log('no data received')
                return {
                    ...state,
                    noServicersFound: true,
                };
            }

            const data = action.data.appointmentSearchResponse && action.data.appointmentSearchResponse;

            // data but no servicers
            if (!data.servicers || !data.servicers.length) {
                return ({
                    ...state,
                    servicerData: { ...action.data },
                    noServicersFound: true,
                });
            }

            // data and servicers but no appointments
            var minDate = moment("0001-01-01T00:00:00");
            var hasAppointments = data.servicers.some(servicer => (servicer.appointments && servicer.appointments.some(appointment => (appointment.appointmentStartTime != '' && appointment.appointmentEndTime != '' && moment(appointment.date).isAfter(minDate)))));
            if (!hasAppointments) {
                console.log('No Appointments Found');
                return ({
                    ...state,
                    servicerData: { ...action.data },
                    noAppointmentsFound: true,
                });
            } 

            // data and servicers and appointments
            return {
                ...state,
                servicerData: { ...action.data },
            };

        case 'SELECT_APPOINTMENT':
            if (!action.appointment) {
                console.log('invalid reducer payload')
                return state;
            }

            const {date, window} = action.appointment;
            return {
                ...state,
                appointment: {
                    date,
                    window 
                }
            };
        case 'TOGGLE_VIEW_SERVICERS':
            console.log(`${state.viewAllServicers ? 'Hiding' : 'Showing'} servicers`);
            return {
                ...state,
                viewAllServicers: !state.viewAllServicers,
                // when going from "all servicers" to "all appointments", reset servicer
                selectedServicer: state.viewAllServicers ? null : { ...state.selectedServicer },
                // when going from "all appointments" to "all servicers", reset appointment. make user choose again.
                appointment: !state.viewAllServicers ? { date: null, window: null } : { ...state.appointment }
            }
        case 'SELECT_SERVICER':
            console.log('servicer selected:', action.servicer.name)
            return {
                ...state,
                selectedServicer: { ...action.servicer }
            }
        case 'SERVICER_NOTES':
            // console.log('updating servicer notes');
            return {
                ...state,
                notesForServicer: action.notes
            }
        case 'SUGGEST_SERVICER_NAME':
            // console.log('No servicers available in the area. Local servicer suggested');
            return {
                ...state,
                suggestedServicer: { 
                    ...state.suggestedServicer,
                    name: action.name,
                }
            }
        case 'SUGGEST_SERVICER_PHONE':
            // console.log('No servicers available in the area. Local servicer suggested');
            return {
                ...state,
                suggestedServicer: {
                    ...state.suggestedServicer, 
                    phone: action.phone,
                }
            }
        case 'CONTRACT_ASSET_DETAILS':
            return {
                ...state,
                contractAssetDetails: action.contractAssetDetails
            }
        case 'TOGGLE_REIMBURSE_TERMS':
            // either accept reimbursement disclaimer with timestamp, 
            // or reset to initial state.
            return {
                ...state,
                acceptReimbursementTerms: {
                    accepted: !state.acceptReimbursementTerms.accepted,
                    timestamp: !state.acceptReimbursementTerms.accepted ? new Date() : null,
                }
            }
        case 'SET_ALT_OPTION':
            return {
                ...state,
                noServicerAlternative: action.altOpt
            }
        default: 
            return state;
    }
}

const ApptContext = createContext(null);

const ApptContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(apptsReducer, initialState);
    return (
        <ApptContext.Provider value={{state, dispatch}}>
            {children}
        </ApptContext.Provider>
    )
}

export { ApptContextProvider, ApptContext}