import React, { Component, Fragment } from 'react';
import {
    Container, Col, Row, Card, CardHeader, CardBody
} from 'reactstrap';
import styles from './Confirmation.module.css';
import moment from 'moment';
import { ContractContext } from '../contexts/ContractContext';
import { phoneFormat } from '../shared/util';
import getClientContent from '../_BrandingProvider/brandContent.jsx';
import BrandedIcon from '../_BrandingProvider/BrandedIcon';
import { dataLayerPush, sendEmail } from '../../helpers';
import CallLink from '../shared/PhoneNumberUtility/CallLink';
import { getCoverageByCoverageId } from "../../helpers"
import { openDocument } from '../Global/DocumentHelper';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';


let liMargin1 = styles.liMargin1;
let servicerDetailsGroup = styles.servicerDetailsGroup;

export class Confirmation extends Component {
    constructor(props) {
        super(props);

        const locationState = this.props?.location?.state;
        const historyState = this.props?.history?.location?.state;

        this.state = {
            client: locationState?.clientId ?? historyState?.clientId,
            claimId: locationState?.claimId ?? historyState?.claimId,
            contract: null,
            statusType: locationState?.statusType ?? historyState?.statusType,
            rulesetDetailId: locationState?.rulesetDetailId,
            claimNumber: locationState?.claimNumber ?? (historyState?.claimNumber ?? historyState?.claim?.claimNumber),
            isEmailSent: typeof locationState?.isEmailSent == 'undefined' ? false : locationState?.isEmailSent,
            fulfillmentTypeId: locationState?.fulfillmentTypeId ?? historyState?.fulfillmentTypeId,
            customerEmailAddress: locationState?.emailAddress ?? historyState?.emailAddress,
            coverageId: locationState?.coverageId ?? historyState?.coverageId,
            isWarrantyOfWork: locationState?.isWarrantyOfWork ? true : false,
            eventId: locationState?.eventId,
            contractAssetId: locationState?.contractAssetId ?? historyState?.contractAssetId
        }

        this.viewClaim = this.viewClaim.bind(this);
    }

    getAmount = (contractAsset) => {
        if (typeof contractAsset[0].offer.offerConfigurationModel.find(pa => pa.pricingAttributeId === 19) == "undefined") {
            return 0;
        }
        return contractAsset[0].offer.offerConfigurationModel.find(pa => pa.pricingAttributeId === 19).value;
    }


    componentWillMount() {
        const { contractAssetId, client, statusType } = this.state;

        if (!this.state.isEmailSent)
            this.setEmailDetails();

        if (this.props.fulfillmentTypeId === 2) {
            dataLayerPush('/confirmation/replacement', 'Replacement Confirmation')
        }

        if (statusType === 'denied') {
            dataLayerPush('/confirmation/denied', 'Claim Denied Confirmation')
            this.fetchRuleSet();
        }
        else if (statusType === 'type1') {
            dataLayerPush('/confirmation/type1', 'Appointment Scheduled Confirmation')
            const appointmentProps = this.props.location?.state?.appointmentProps;

            this.setState(() => ({
                appointmentStartTime: appointmentProps.AppointmentToBook.appointmentStartTime,
                appointmentEndTime: appointmentProps.AppointmentToBook.appointmentEndTime,
                appointmentDate: moment(appointmentProps.AppointmentToBook.date).format('dddd, MMMM DD'),
                servicerName: appointmentProps.ServicerToBook.name,
                servicerPhone: this.formattedPhone(appointmentProps.ServicerToBook.phoneNumber)
            }))
        }
        else if (statusType === 'type2-3') {
            dataLayerPush('/confirmation/type2-3', 'Servicer Will Schedule Confirmation')
            this.setState(() => ({
                servicerName: this.props.location?.state?.servicerToBook?.name,
                servicerPhone: this.formattedPhone(this.props.location?.state?.servicerToBook?.phoneNumber)
            }))
        }
        else if (statusType === "type4" || statusType === "savedClaim") {
            const pageTitle = statusType == "type4" ? 'Manual Dispatch Confirmation' : 'Saved Claim Confirmation';
            dataLayerPush(`/confirmation/${statusType}`, pageTitle)
        }
    }
   

    formattedPhone = phone => {
        var cleaned = ('' + phone).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    }

    viewClaim = e => {
        e.preventDefault();
        // redirecting URL
    }

    fetchRuleSet = e => {
        fetch(`api/rulesEngine/GetFailedRuleSetExecutionByClaimId/${this.state.claimId}/${this.state.client}`, {
            method: 'get'
        }).then(res => {
            if (res.ok) {
                res.json().then(rulesResult => {
                    this.setState(() => ({
                        rulesetDetailId: rulesResult.rulesetDetailId,
                        // claimNumber: rulesResult.claimNumber
                    }))
                });
            }
        });
    }

    viewPlanDetails = e => {
        this.props.history.push(`/planoverview`);
    }

    handleContinue = () => {
        fetch('api/claim/GetClaimSummaryByClaimNumber/' + this.state.claimNumber + '/' + this.state.client)
            .then(res => res.json())
            .then(data => {
                let col = data;
                let columndata = col;
                let pathName = '/DetailsOverview';
                columndata.tcf = col.tcfAmount;
                columndata.incidentDate = col.lossDate;
                columndata.claimSetupDateOverview = col.claimSetupDate;
                if (typeof this.props.history?.search != 'undefined') {
                    pathName += `?id=${this.props.history?.search}`;
                }
                this.props.history.push({
                    pathname: pathName,
                    claim: columndata,
                    clientId: this.state.client
                });
            })
    }

    viewClaimDetails = e => {
        this.props.history.push({
            pathname: '/planoverview',
            state: {
                view: "view",
                activeOverviewTab: "myClaims"
            }
        });
    }

    viewMyPlans = e => {
        this.props.history.push({
            pathname: '/planoverview',
            state: {
                view: "kanban",
                activeOverviewTab: "myPlans"
            }
        });
    }

    termsCondition = (docId, clientId) => {
        openDocument(docId, clientId);
    }

    setEmailDetails = async () => {
        const { client, claimId, contractAssetId, coverageId, claimNumber } = this.state;
        const { assets, contractAssetModel } = JSON.parse(sessionStorage.getItem('appState')).contract;

        let emailType = 0;
        //let claimNumber = '';

        this.setState(() => ({ isEmailSent: true }));

        const getClientByUrl = await fetch(`api/Claim/GetHostByClient/${client}`);
        const miniSiteUrl = getClientByUrl.status == 200 ? await getClientByUrl.json() : null;
        const coverageDetails = await fetch(`api/Contract/GetCoverageByCoverageId/${coverageId}/${client}`);
        const coverageResponse = coverageDetails.status == 200 ? await coverageDetails.json() : null;
        const contractResponse = await fetch(`api/contract/GetByContractAssetId/${contractAssetId}/${client}`, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (contractResponse.ok) {
            contractResponse.json().then(async cdata => {
                var contractAssetCoverageModel = cdata?.contractAssetModel;
                var contractAssetCoverageModelmap = contractAssetCoverageModel.map(function (item) {
                    return item.contractAssetCoverageModel;
                })[0];
                var covresdata = '';
                let isClaimableCoverage = false;
                var claimableCoverages = contractAssetCoverageModelmap?.filter(cac => cac.claimableCoverage == true)
                if (claimableCoverages) {
                    let coverage = contractAssetCoverageModelmap?.find(key => key.claimableCoverage == true);
                    if (coverage != null) {
                        isClaimableCoverage = coverage.claimableCoverage;
                        await getCoverageByCoverageId(coverage.coverageId, cdata.clientId)
                            .then(data => {
                                covresdata = data;
                            })
                    }
                }

                fetch(`api/claim/ClaimSearch`, {
                    method: 'Post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Site-Req-Auth': claimId
                    },
                    body: JSON.stringify({
                        searchClaimId: claimId,                       
                        clientId: this.state.clientId
                    })
                }).then(claimResponse => {
                    if (claimResponse.ok) {
                        claimResponse.json().then(claimData => {
                            let claim = claimData;
                            //claimNumber = claim.claimNumber;
                            let contract = cdata;
                            let customerName = this.props?.location?.state?.appointmentProps?.CustomerInformation?.FirstName + ' ' + this.props?.location?.state?.appointmentProps?.CustomerInformation?.LastName;
                            
                            const appointmentProps = this.props.location?.state?.appointmentProps;
                            let servicerName = appointmentProps?.ServicerToBook?.name;
                            let servicerPhone = '';
                            if (appointmentProps?.ServicerToBook?.phoneNumber)
                                servicerPhone = this.formattedPhone(appointmentProps?.ServicerToBook?.phoneNumber);
                            let appointmentDate = moment(appointmentProps?.AppointmentToBook?.date).format('dddd, MMMM DD, YYYY');
                            let appointmentWindow = appointmentProps?.AppointmentToBook?.appointmentStartTime + '-' + appointmentProps?.AppointmentToBook?.appointmentEndTime;
                            let emailAddress = '';
                            let clientContractNumber = '';
                            let currentAsset = contract?.contractAssetModel?.filter(e => e.contractAssetId == contractAssetId);
                            let serviceFee = this.getAmount(currentAsset);
                            serviceFee = "$".concat(serviceFee);
                            let assetModel = contract?.assets.find(ai => ai.assetId == currentAsset[0]?.assetId);
                            let claimSetupDate = moment(claim?.claimSetupDate).format("MM/DD/YYYY")
                            let assetName = coverageResponse?.coverageName;
                            let cancelDate = moment(new Date().toString()).format("MM/DD/YYYY");
                            let contractNumber = contract?.contractNumber;
                            let claimSetupDateWithDay = moment(claim?.claimSetupDate).format("dddd, MM/DD/YYYY")
                            let url = miniSiteUrl?.messages[0]
                            let assetNameResume = '';
                            if (typeof covresdata !== 'undefined' && covresdata) {
                                assetNameResume = covresdata.coverageName;
                            }
                            else {
                                assetNameResume = assetModel?.assetName;
                            }

                            if (!this.props.location?.state?.appointmentProps?.CustomerInformation?.FirstName)
                                customerName = contract?.party?.firstName + ' ' + contract?.party?.lastName;

                            clientContractNumber = contract?.clientContractNumber;
                            if (contract?.party?.partyEmailModel[0] && contract?.party?.partyEmailModel[0]?.address) {
                                emailAddress = contract?.party?.partyEmailModel[0]?.address;
                                this.setState({
                                    customerEmailAddress: emailAddress
                                })
                            }

                            let thisFirstName = contract?.party?.firstName;
                            let phone = contract?.party.partyPhoneModel[0].number;
                            let model = contract?.contractAssetModel[0].modelNumber;
                            let serialNumber = contract?.contractAssetModel[0].serialNumber;
                            let modelNumber = contract?.contractAssetModel[0].modelNumber;

                            if (this.props.fulfillmentTypeId === 2 && this.state.statusType != 'savedClaim') {
                                emailType = 35;
                                sendEmail("hwa", emailType, thisFirstName, null, null, null, null, null, emailAddress, claimNumber, null, serialNumber, modelNumber, claimId, null, null, null, null, clientContractNumber, serviceFee, null, null, contractNumber, null, 2 , this.state.eventId);
                            }
                            else {
                                const client = contract?.clientId;
                                if (this.state.statusType === 'type1') {
                                    switch (client) {
                                        case 'et':
                                            if (this.state.isWarrantyOfWork)
                                                emailType = 175;
                                            else
                                                emailType = 172;
                                            break;
                                        default:
                                            if (this.state.isWarrantyOfWork)
                                                emailType = 175;
                                            else
                                                emailType = 27;
                                            break;
                                    }
                                    sendEmail(client, emailType, customerName, null, null, null, null, null, emailAddress, claimNumber, null, serialNumber, modelNumber, claimId, servicerName, servicerPhone, appointmentDate, appointmentWindow, clientContractNumber, serviceFee, null, null, contractNumber, "type1", 5, this.state.eventId);
                                } else if (this.state.statusType === 'type2-3') {
                                    switch (client) {
                                        case 'et':
                                            if (this.state.isWarrantyOfWork)
                                                emailType = 176;
                                            else
                                                emailType = 173;
                                            break;
                                        default:
                                            if (this.state.isWarrantyOfWork)
                                                emailType = 175;
                                            else
                                                emailType = 22;
                                            break;
                                    }
                                    servicerName = this.props.location?.state?.servicerToBook?.name;
                                    if (this.props.location?.state?.servicerToBook?.phoneNumber)
                                        servicerPhone = this.formattedPhone(this.props.location?.state?.servicerToBook?.phoneNumber);
                                    sendEmail(client, emailType, customerName, null, null, null, null, null, emailAddress, claimNumber, null, serialNumber, modelNumber, claimId, servicerName, servicerPhone, null, null, clientContractNumber, serviceFee, null, null, contractNumber, "type2", 5, this.state.eventId);
                                }
                                else if (this.state.statusType === "type4") {
                                    switch (client) {
                                        case 'et':
                                            if (this.state.isWarrantyOfWork)
                                                emailType = 177;
                                            else if (this.state.fulfillmentTypeId === 3 && isClaimableCoverage)
                                                emailType = 217;
                                            else if (this.state.fulfillmentTypeId === 3 && !isClaimableCoverage)
                                                emailType = 216;
                                            else
                                                emailType = 174;
                                            break;
                                        default:
                                            if (this.state.isWarrantyOfWork)
                                                emailType = 175;
                                            else
                                                emailType = 23;
                                            break;
                                    }
                                    if (this.state.fulfillmentTypeId === 3) {     //if the asset is surge protect
                                        emailType = client === 'et' ? 217 : 23;
                                        sendEmail(client, emailType, client === 'et' ? customerName : thisFirstName, phone, null, model, null, url, emailAddress, claimNumber, claimSetupDate, serialNumber, modelNumber, claimId, null, null, null, null, clientContractNumber, serviceFee, assetName, null, contractNumber, "type4", 5, this.state.eventId);
                                    }
                                    else {
                                        sendEmail(client, emailType, customerName, phone, null, model, null,
                                            url, emailAddress, claimNumber, claimSetupDate, serialNumber, modelNumber, claimId, null, null, null, null,
                                            clientContractNumber, serviceFee, assetName, null, contractNumber, "type4", 5, this.state.eventId);
                                    }
                                }
                                else if (this.state.statusType === "cancel") {
                                    switch (client) {
                                        case "et":
                                            emailType = 182;
                                            break;
                                    }
                                    sendEmail(client, emailType, customerName, null, null, null, null, null, emailAddress, claimNumber, null, null, null, claimId, null, null, null, null, clientContractNumber, null, null, cancelDate, contractNumber, "cancel", 5, this.state.eventId);
                                }
                                else if (this.state.statusType === 'savedClaim') {
                                    if (client === "et") {
                                        emailType = 183;
                                    }
                                    else {
                                        emailType = 37;
                                    }

                                    fetch(`api/claim/GetHostByClient/${client}`, { // TODO, make client dynamic
                                        method: 'Get',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(response1 => {
                                        if (response1.ok) {
                                            response1.json().then(hostUrl => {
                                                let miniSiteUrl = "#";
                                                if (hostUrl.successful == true)
                                                    miniSiteUrl = `${hostUrl.messages[0]}/resumeclaim/${claimNumber}/${client}`;
                                                sendEmail(client, emailType, customerName, null, null, null, null, miniSiteUrl, emailAddress, claimNumber, claimSetupDateWithDay, null, null, claimId, null, null, null, null, clientContractNumber, serviceFee, assetNameResume, null, contractNumber, null, 2, this.state.eventId);
                                            });
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            });
        }
    }

    render() {
        return (
            <ContractContext.Consumer>
                { context => {
                    const { clientId } = context.data.contract;
                    const covDocResponse = context.data.coverageDocs;
                    const riskProfileId = context.data.contract.riskProfile?.riskProfileId;
                    const filteredDocs = covDocResponse?.filter(x => x.warrantyObjectValue == riskProfileId);
                    var documentModel = filteredDocs?.map(function (item) {
                        return item.documentModel;
                    });
                    const documentTermsCond = documentModel?.find(x => x.documentTypeId == 11);
                    const termsCondDocId = documentTermsCond?.documentId;
                    const { phone } = getClientContent(clientId).variableText.customerService
                    const clientTextValues = getClientContent(clientId);
                    const title = clientTextValues.variableText.pageTitle;
                    const pageTitle = title ? `Confirmation | ${title}` : 'Confirmation';
                    const warrantyObjectTypeId = 12;

					return (
						<DocumentTitle title={pageTitle}>
							<Container className={styles.confirmContainer}>
								{this.props.fulfillmentTypeId === 2 && (
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">Stand by, our team is working on your replacement.</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Col>
												{/* <img src={require("../../assets/images/claim-complete.svg")} alt="We will contact you with replecement details" /> */}
												<BrandedIcon client={clientId} iconType={'replacement'} />
												<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
												<Col md="10" lg="10" className={styles.apptMessage}>
													<h2 className="h4">We will contact you within 48 hours <br className="show-lg-up" aria-hidden="true" />with details about your replacement.</h2>
												</Col>
												<button className="btnLink mb-2" onClick={this.handleContinue} > View Claim Details</button>
											</Col>
										</CardBody>
									</Card>
								)}
								{this.state.statusType === "denied" &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">Unfortunately, we are unable to proceed with service.</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Row>
												<Col>
													<div className={styles.apptMessage}>
														<section id="denied">
															<BrandedIcon client={clientId} iconType={'denied'} />
															<p className={styles.claimNum}>
																Claim #{this.state.claimNumber}
															</p>

															<Col md="10" lg="9" className={styles.apptMessage}>
																{ /*updates made to these reasons should also be applied to the getDenialReason method in helpers.js*/}
																{(this.state.rulesetDetailId == 1 || this.state.rulesetDetailId == 7) &&
																	<h2 className="h4" id="paymentDue">This plan is not active; therefore, we cannot proceed with filing a new claim at this time</h2>
																}
																{(this.state.rulesetDetailId == 3 || this.state.rulesetDetailId == 9) &&
																	<h2 className="h4" id="oem">This product is not yet eligible for service due to the product currently being covered by the manufacturer’s warranty. The plan’s coverage will begin after the manufacturer’s warranty expires. Please contact the manufacturer to service your product.</h2>
																}
																{(this.state.rulesetDetailId == 2 || this.state.rulesetDetailId == 8) &&
																	<h2 className="h4" id="coverageStart">This product is not eligible for service because coverage has not started. The coverage start date cannot be in the future.</h2>
																}
																{(this.state.rulesetDetailId == 4 || this.state.rulesetDetailId == 10 || this.state.rulesetDetailId == 11) &&
																	<h2 className="h4" id="expired">This product is not eligible for service due to the incident date is prior to the contract begin date.</h2>
																}
																{(this.state.rulesetDetailId == 13 || this.state.rulesetDetailId == 5) &&
																	<h2 className="h4" id="limitOfLiability">This product is not eligible for service due to the plan’s claim limit already being met.</h2>
																}
																{this.state.rulesetDetailId == 12 || this.state.rulesetDetailId == 6 &&
																	<h2 className="h4" id="notCovered">Incident must be a covered issue. This incident is not eligible for service due to not being covered under your current plan.</h2>
																}

															</Col>
														</section>
													</div>
													<button className="btnLink mb-2" onClick={this.handleContinue}>View Claim Details</button>
												</Col>
											</Row>
										</CardBody>
									</Card>
								}
								{this.state.statusType === "type1" &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">
												{getClientContent(context.data.contract.clientId).variableVoice.confirmations.type1}
											</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Row>
												<Col>
													<BrandedIcon client={clientId} iconType={'confirm1'} />
													<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
													<p className={styles.servicerDet}>We have confirmed your service appointment.</p>

													<div className={styles.apptTiming}>
														<h2>{this.state.appointmentStartTime} - {this.state.appointmentEndTime}</h2>
														<span className={styles.lineMiddle}></span>
														<h2>{this.state.appointmentDate}</h2>
													</div>
													<div className={styles.servicerDet}>
														<p>{this.state.servicerName}</p>
														<p>{phoneFormat(this.state.servicerPhone)}</p>
													</div>
													<div className="text-center">
														<button className="btnLink mb-2" onClick={this.handleContinue}>View Claim Details</button>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								}
								{this.state.statusType === "type1" &&
									<div className={styles.rescheduleAppt}>
										<p>Need to reschedule?</p>
										<p>Please contact your servicer directly at least 24 hours in advance.</p>
									</div>
								}
								{this.state.statusType === "type2-3" &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">
												{getClientContent(context.data.contract.clientId).variableVoice.confirmations.type2_3}
											</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Row>
												<Col>
													<BrandedIcon client={clientId} iconType={'confirm2-3'} />
													<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
													<div className={styles.apptMessage}>
														<h2 className="h4">Your servicer will contact you <br className="show-lg-up" /> within 24 hours to schedule service.</h2>
													</div>
													<div className={styles.servicerDet}>
														<p>{this.state.servicerName}</p>
														<p>{phoneFormat(this.state.servicerPhone)}</p>
													</div>
													<button className="btnLink mb-2" onClick={this.handleContinue}>View Claim Details</button>
												</Col>
											</Row>
										</CardBody>
									</Card>
								}
								{this.state.statusType === "type4" && this.state.fulfillmentTypeId !== 3 &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">
												{getClientContent(context.data.contract.clientId).variableVoice.confirmations.type4}
											</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Col>
												<BrandedIcon client={clientId} iconType={'confirm4'} />
												<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
												<Col md="8" className={styles.apptMessage}>
													<h2 className="h4">We will contact you within 24 hours to schedule service.</h2>
												</Col>
												<button className="btnLink mb-2" onClick={this.handleContinue}>View Claim Details</button>
											</Col>
										</CardBody>
									</Card>
								}
								{this.state.statusType === "type4" && this.state.fulfillmentTypeId === 3 &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">Check your email, we're sending instructions.</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Col>
												<BrandedIcon client={clientId} iconType={'emailsent'} />
												<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
												<Col md="8" className={styles.apptMessage}>
													<h2 className="h4">Hire a licensed service technician.</h2>
													<p className="text-left">
														Please review the <Link onClick={() => this.termsCondition(warrantyObjectTypeId, riskProfileId,termsCondDocId, clientId)}>terms and conditions</Link> to ensure the repair is covered by your contract and eligible for reimbursement.
                                            </p>
													<p className="text-left">
														Once the cause of damage is certified a result of electrical surge,
                                                follow the instructions emailed to {this.state.customerEmailAddress} to submit the required documentation for reimbursement within 60 days.
                                            </p>
												</Col>
												<button className="btnLink mb-2" onClick={this.handleContinue}>View Claim Details</button>
											</Col>
										</CardBody>
									</Card>
								}
								{this.state.statusType === "savedClaim" &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">
												{getClientContent(context.data.contract.clientId).variableVoice.confirmations.savedClaim}
											</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Col>
												<BrandedIcon client={clientId} iconType={'confirmsavedclaim'} />
												<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
												<Col md="10" className={styles.apptMessage}>
													<h2 className="h4">You will receive an email with a link <br className="show-lg-up" />to resume your claim.</h2>
													<p>
														If you&apos;d like to speak with one of our representatives, call
                                                <br className="show-lg-up" />
														<a href={`tel:${phone}`} className="callLink">
															{phone}
														</a>
                                                . Please reference your claim number: {this.state.claimNumber}.
                                            </p>
												</Col>
											</Col>
										</CardBody>
									</Card>
								}
								{this.state.statusType === "others" &&
									<Card className={`${styles.onlineConfirmCard} col-md-8 col-xs-12`}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">Stand by, our team is working on your replacement.</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Col>
												<BrandedIcon client={clientId} iconType={'replacement'} />
												<p className={styles.claimNum}>Claim #123456789</p>
												<Col md="10" lg="10" className={styles.apptMessage}>
													<h2 className="h4">We will contact you within 48 hours <br className="show-lg-up" aria-hidden="true" />with details about your replacement.</h2>
												</Col>
												<button className="btnLink mb-2" onClick={this.handleContinue}>View Claim Details</button>
											</Col>
										</CardBody>
									</Card>
								}

								{this.state.statusType === "cancel" &&
									<Card className={styles.onlineConfirmCard}>
										<CardHeader className={styles.cardheader}>
											<h1 className="h3">Your claim has been canceled.</h1>
										</CardHeader>
										<CardBody className="text-center">
											<Col>
												<BrandedIcon client={clientId} iconType={'denied'} />
												<p className={styles.claimNum}>Claim #{this.state.claimNumber}</p>
												<Col md="10" lg="10" className={styles.apptMessage}>
													<h2 className="h4">As requested, your claim has been canceled.</h2>
													<p className="mx-3"> If you&apos;d like to speak with one of our representatives, call  <CallLink phone={phone} />.

                                                <br className="show-lg-up" />
                                                Please reference your claim number: {this.state.claimNumber}.
                                            </p>
												</Col>
												<button className="btnLink mb-2" onClick={this.viewMyPlans}>View My Plans</button>
											</Col>
										</CardBody>
									</Card>
								}
							</Container>
						</DocumentTitle>
					)
				}}
			</ContractContext.Consumer>
        );
    }
}