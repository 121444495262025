import React, { useRef, useContext} from 'react';
import { CardBody, Row, Col, Container, FormGroup } from 'reactstrap';
import { SpecialNotesToServicer } from '.';
import { ApptContext } from './AppointmentContext';
import AIGFormInputField from '../../shared/AIGFormInputField';
import Cleave from 'cleave.js/react';
import SimpleReactValidator from 'simple-react-validator'



export const RecommendServicer = () => {
    const { state, dispatch } = useContext(ApptContext);
    const { name, phone } = state.suggestedServicer;

    const validator = useRef(new SimpleReactValidator({
        element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        messages: {
            required: ':attribute is required.'
        },
    }));

    return (
        <Col md="6" className="p-0" style={{ margin: '0 auto' }}>
            <AIGFormInputField
                fieldName="servicerName"
                fieldLabel="Servicer Name"
                required={!state.noServicersFound}
                onChange={(e) => dispatch({ type: 'SUGGEST_SERVICER_NAME', name: e.target.value })}
                value={name}
                message={validator.current.message('servicerName', name, 'required')}
            />
            <FormGroup>
                <label htmlFor="servicerPhone" className="form-label">Servicer Phone Number</label>
                <Cleave
                    className="form-control"
                    id="servicerPhone"
                    type="text"
                    name="servicerPhone"
                    aria-required="true"
                    value={phone}
                    onChange={(e) => dispatch({ type: 'SUGGEST_SERVICER_PHONE', phone: e.target.rawValue })}
                    options={{
                        blocks: [0, 3, 3, 4],
                        delimiters: ["(", ") ", "-"],
                        delimiterLazyShow: true,
                        numericOnly: true,
                    }}
                />
            </FormGroup>
            <SpecialNotesToServicer fullwidth={true} />
        </Col>
    )
}