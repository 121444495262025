import React, { Component } from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner'
import styles from './PlanLookup.module.css';
import LastNameSearch from '../PlanSearch/LastNameSearch';
import PlanNumberSearch from '../PlanSearch/PlanNumberSearch';
import { ContractContext } from '../../components/contexts/ContractContext';
import { ContractsOverview } from '../../screens/PlanOverview/ContractsOverview';
import { getHwaRedirectUrl } from '../../helpers';
import { SkipNavContent } from '@reach/skip-nav';
import { WhiteLabelProvider } from '../_BrandingProvider/WhiteLabelProvider';

class PlanLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planNumberParams: {},
            lastNameParam: {},
            planSearch: true,
            searching: false,
            contract: null,
            errors: {},
            showPlanNotFound: false,
            buttonEnabled: false,
            showSearchResult: false,
            showPlanSearch: typeof this.props.match?.params?.channelId == "undefined" ? true : false,
            contracts: []
        }
        var lastNameParams = {}; // do not remove this value is used
        var planNumberParams = {};
        this.lastNameSearchParams = this.lastNameSearchParams.bind(this);
        this.planSearchParams = this.planSearchParams.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.search = this.search.bind(this);
        this.buttonEnabled = this.buttonEnabled.bind(this);
        this.showPlanSearchBack = this.showPlanSearchBack.bind(this);
    }

    lastNameSearchParams = (params) => {
        this.lastNameParams = params;
        // check if button is enabled
        //this.buttonEnabled();
    }

    planSearchParams = (params) => {
        this.planNumberParams = params;
    }

    toggleSearch() {
        this.setState(prevState => ({
            planSearch: !prevState.planSearch,
            showPlanNotFound: false
        }), () => console.log(this.state.planSearch));
    }
    toggleSearchOnEnter = (event) => {
        if (event.keyCode == 13) {
            this.setState(prevState => ({
                planSearch: !prevState.planSearch,
                showPlanNotFound: false
            }), () => console.log(this.state.planSearch));
        }
    }
    validateForm = (errors) => {
        console.log(errors);
        let valid = true;
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    lastNameErrors = (errs) => {
        this.setState({ errors: errs });
    }

    planSearchErrors = (errs) => {
        this.setState({ errors: errs });
    }

    buttonEnabled = () => {
        var params = this.lastNameParams;
        var enabled = params.lastName !== "" && params.zip !== "" && (params.email !== "" || params.phone !== "");

        this.setState({
            buttonEnabled: enabled
        })
    }

    showPlanSearchBack = () => {
        this.setState({
            showPlanSearch: true,
            showSearchResult: false,
        });
    }
    componentWillMount()
    {
        if (typeof this.props.match?.params?.channelId != "undefined")
        {
            //the logic only for plan search agent portal Super User
            fetch('/api/PlanSearch/verifyUser/'+this.props.match?.params?.userId)
                .then(res => res.json())
                .then(isSuccess =>
                {
                    if (isSuccess)
                    {
                        
                    fetch(`api/PlanSearch/contract/${this.props.match?.params?.clientId}`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        contractNumber: this.props.match.params.plannumber,
                        lastName: this.props.match.params.lastname,
                        isSuperUser: isSuccess
                    })
                })
                .then(res => {
                        if (res.status === 204) {
                            this.setState({ showPlanNotFound: true, searching: false }, () => {
                                return;
                            });
                        }
                        else {
                            res.json()
                                .then(contract =>
                                {
                                    let value = this.context;
                                    value.updateContract(contract[0]);
                                    value.updateParty(contract[0].party);
                                    value.updateContracts(contract);  // the inital plan overview page loaded with the context records we just added the logic what we did in plan search submission
                                    if (contract.length == 1) {
                                        this.props.history.push('/planoverview');
                                    }
                                    else {
                                        this.setState({ showSearchResult: true, showPlanSearch: false, searching: false, contracts: contract });
                                    }
                                })
                        }

                    })
                    }
                    else {
                        (async () => {
                            const url = await getHwaRedirectUrl();
                            window.location = `${url}/auth/login`;
                        })();
                    }
                });


        }
    }


    search() {
        this.clickChild().then(() => {
            if (this.validateForm(this.state.errors)) {
                console.info('Valid Form');
            }
            else {
                console.error('Invalid Form');
                return;
            }

            this.setState(prevState => ({
                searching: !prevState.searching,
                noPlanFound: false
            }));

            if (this.state.planSearch) {
                fetch('api/PlanSearch/contract/arw', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        contractNumber: this.planNumberParams.planNumber,
                        lastName: this.planNumberParams.lastName
                    })
                })
                    .then(res => {
                        if (res.status === 204) {
                            this.setState({ showPlanNotFound: true, searching: false }, () => {
                                return;
                            });
                        }
                        else {
                            res.json()
                                .then(contract => {

                                    let value = this.context;
                                    value.updateContract(contract[0]);
                                    value.updateParty(contract[0].party);
                                    value.updateContracts(contract);
                                    value.updateClientId(contract[0].clientId)
                                    this.props.history.push('/planoverview');
                                })
                        }

                    })
            }
            else {
                fetch('api/PlanSearch/LastNameSearch/arw', {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        lastName: this.lastNameParams.lastName,
                        zip: this.lastNameParams.zip,
                        email: this.lastNameParams.email,
                        phone: this.lastNameParams.phone,
                        searchOption: this.lastNameParams.searchOption
                    })
                })
                    .then(res => {
                        if (res.ok) {
                            if (res.status === 204) {
                                this.setState({ showPlanNotFound: true, searching: false }, () => {
                                    return;
                                });
                            }
                            else {
                                res.json()
                                    .then(contract => {

                                        let value = this.context;
                                        value.updateContract(contract[0]);
                                        value.updateParty(contract[0].party);
                                        value.updateContracts(contract);
                                        if (contract.length == 1) {
                                            this.props.history.push('/planoverview');
                                        }
                                        else {
                                            value.updateContracts(contract);
                                            this.setState({ showSearchResult: true, showPlanSearch: false, searching: false, contracts: contract });

                                        }
                                    })
                            }
                        }
                    })
            }
        });
    }

    render() {
        let params = this.lastNameParams;
        var buttonEnabled = false;
        if (typeof params !== "undefined") {
            buttonEnabled = params.lastName !== "" && params.zip !== "" && (params.email !== "" || params.phone !== "");
        }

        return (
            <>
                {this.state.showPlanSearch && (
                    <Container fluid={true} className="p-0">
                        <div className={styles.hero}>
                            <Container>
                                <Row>
                                    <Col md={7} lg={5} className="ml-auto">
                                        <Card className={styles.card}>
                                            <h1 className="h2">File or Track a Claim </h1>
                                            {this.state.planSearch === true ? (
                                                <PlanNumberSearch
                                                    paramsCallback={this.planSearchParams}
                                                    errorsCallback={this.planSearchErrors}
                                                    searchClick={click => this.clickChild = click}
                                                    params={this.planNumberParams}
                                                />
                                            ) : (
                                                <LastNameSearch
                                                    paramsCallback={this.lastNameSearchParams}
                                                    errorsCallback={this.lastNameErrors}
                                                    searchClick={click => this.clickChild = click}
                                                    params={this.lastNameParams}
                                                />
                                            )}

                                            <Col className={styles.linkToPlanNum}>

                                                {this.state.showPlanNotFound && !this.state.planSearch &&
                                                    <div className="validation-summary" aria-live="polite">
                                                        Sorry, we couldn't find your plan. Please try again or call us at <a class="callLink" href="tel:877-637-7890">877-637-7890</a>.
                                                    </div>
                                                }

                                                {this.state.showPlanNotFound && this.state.planSearch &&
                                                        <div className="validation-summary" aria-live="polite">
                                                            Sorry, we couldn't find your plan.  We can help you <span class="link" style={{ color: "inherit" }} onClick={this.toggleSearch}>search a different method</span>.
                                                        </div>
                                                    }

                                                    <Button 
                                                        onClick={this.search}
                                                        type="submit"
                                                        tabIndex="0" 
                                                        aria-label="On click takes to plan overview page"
                                                        color="primary" 
                                                        className={styles['btn-primary']} {...this.state.planSearch === false ? { disabled: !buttonEnabled } : {}}
                                                    >
                                                        <Spinner color="light" animation={this.state.searching ? "border" : ""} />
                                                        {this.state.searching ? "" : "Get Started"}
                                                    </Button>
                                                    {this.state.planSearch ? (
                                                        <Col className="linkToPlanNum">

                                                            <div>
                                                                <p className="mb-0" tabIndex="0">Don't have your plan number?</p>
                                                                <a onClick={this.toggleSearch} onKeyDown={this.toggleSearchOnEnter} tabIndex="0" aria-label="On Click takes to plan number search method">Try different search method.</a>
                                                            </div>
                                                        </Col>
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                <p className="mb-0" tabIndex="0">Have your plan number?</p>
                                                                <a onClick={this.toggleSearch} onKeyDown={this.toggleSearchOnEnter} tabIndex="0" aria-label="On Click takes to different search method">Search by plan number.</a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Col>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Container>
                )}

                {this.state.showSearchResult && (
                    <Container fluid={true} className="p-0">
                        <ContractsOverview history={this.props.history} contracts={this.state.contracts} viewPlan={this.viewPlan} showPlanSearchBack={this.showPlanSearchBack} />
                    </Container>
                )}

            </>
        );

    }
}

PlanLookup.contextType = ContractContext;
export { PlanLookup };