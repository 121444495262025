import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApptContext } from '.';
import BootstrapTable from 'react-bootstrap-table-next';
import styles from './AppointmentSelect.module.css';
import NumberFormat from 'react-number-format';

const booleanFormatter = (cell, row) => {
     return !!cell ? 'Yes' : 'No'
}

const phoneFormatter = (cell) => {
    return (
        <NumberFormat
            format={'(###) ###-####'}
            value={cell}
            displayType='text'
        />
    )
}

const headerFormatter = (column, index, { sortElement }) => {
    const { order } = sortElement.props;
    return (
        <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
            {column.text}
        </span>
    );
}

export const ServicersTable = ({servicers, onSelect}) => {
    const { dispatch } = useContext(ApptContext);
    const [selected, setSelected] = useState(servicers[0].id)
    const tableRef = useRef(null);

    useEffect(()=>{
        dispatch({type: 'SELECT_SERVICER', servicer: servicers[0] })
    }, [])

    /**
     * THIS FUNCTION IS FOR CUSTOMIZED RADIO BUTTONS IN THE TABLE, 
     * BUT DOES NOT SEEM TO IMPACT THE BUTTONS THEMSELVES.
     * 
     * FOUND ON THE CREATOR'S ISSUES FOR react-bootstrap-table
     * https://github.com/AllenFang/react-bootstrap-table/issues/529#issuecomment-265737088
     */
    // const customRadioSelect = (props) => {
    //     console.table(props)
    //     const { type, checked, disabled, onChange, rowIndex } = props;

    //     return (
    //     <div className='checkbox-personalized'>
    //         <input
    //             type={ type }
    //             name={ 'radio' + rowIndex }
    //             id={ 'radio' + rowIndex }
    //             checked={ checked }
    //             disabled={ disabled }
    //             onChange={ e=> onChange(e, rowIndex) }
    //             ref={ input => {
    //                 if (input) {
    //                     input.indeterminate = props.indeterminate;
    //                 }
    //             }}
    //         />
    //         <label htmlFor={ 'radio' + rowIndex }>
    //             <div className='sel'></div>
    //         </label>
    //     </div>);
    // }

    const handleSelectRow = (row, isSelect) => {
        dispatch({type: 'SELECT_SERVICER', servicer: row});
        setSelected(row.id)
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            headerFormatter: headerFormatter
        }, 
        {
            dataField: 'servicerType',
            text: 'Type',
        }, 
        {
            dataField: 'ranking',
            text: 'Rank',
            sort: true,
            formatter: (cell) => parseInt(cell).toFixed(2),
            headerFormatter: headerFormatter
        }, 
        {
            dataField: 'id',
            text: 'ID',
        }, 
        {
            dataField: 'oemAuthorized',
            text: 'OEM Auth',
            formatter: booleanFormatter,
        },
        {
            dataField: 'phoneNumber',
            text: 'Phone',
            formatter: phoneFormatter,
        },
        {
            dataField: 'status',
            text: 'Status',
           // formatter: (cell) => cell.substr(0,3).toUpperCase(),
        }
    ];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        align: 'center',
        classes: [styles.selectedRow],
        // customComponent does not seem to be supported. See above.
        // customComponent: customRadioSelect,
        bgColor: '#f2f7fc',
        selected: [selected],
        selectColumnStyle: () => {
            return {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                border: 'none',
                paddingTop: '18px',
            };
        },
        onSelect: handleSelectRow
    }

    const defaultSorted = [{ // MUST BE ARRAY OF OBJECTS
        dataField: "ranking",
        order: "desc",
    }]

    return(
        <BootstrapTable
            bootstrap4
            ref={tableRef}
            keyField={'id'}
            columns={columns}
            data={servicers}
            bordered={false}
            defaultSorted={defaultSorted}
            selectRow={selectRow}
            classes={styles.servicerTable}
            wrapperClasses="table-responsive"
            rowClasses="text-nowrap"
            sortIndicator={true}
        />
    )
};