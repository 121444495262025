import React, { useState, useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import { ReactComponent as ClearSearchIcon } from '../../assets/images/close.svg'
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import { ReactComponent as MailIcon } from '../../assets/images/envelope.svg'
import { ReactComponent as Phone } from '../../assets/images/call.svg'
import Accordion from '../shared/AccordionCard/Accordion';
import { brandFaqs } from './brandFaqs';
import styles from './Faq.module.css';
import classNames from 'classnames'
import getClientContent from '../_BrandingProvider/brandContent';
import { useMediaQuery } from 'react-responsive';
import { stripPhoneNumber } from '../shared/util'
import { ContractContext  } from '../contexts/ContractContext';
import { getMetaDataByClient } from '../../App';
import { dataLayerPush, isETRoute } from '../../helpers';
import CallLink from '../shared/PhoneNumberUtility/CallLink';
import ContactUsCards from '../shared/ContactUsCards/ContactUsCards';

const FAQ = () => {
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState([])
    const [activeCategory, setActiveCategory] = useState(0)
    const isMobile = useMediaQuery({ maxWidth: '767px' })
    const {data: {clientId}} = useContext(ContractContext)

    useEffect(() => {
        const pageTitle = getMetaDataByClient(clientId || 'AIG', "FAQ").title
        document.title = pageTitle
    })

    useEffect(() => {
        dataLayerPush('/FAQ', 'FAQs')
    })

    const categories = brandFaqs[clientId?.toLowerCase()];

    const handleQuery = (e) => {
        const searchQuery = e.target.value
        setQuery(searchQuery);
        const queryLower = searchQuery.toLowerCase()
        const results = [];

        if (!!searchQuery) {
            categories.map((cat) => {
                cat.faqs.map((faq) => {
                    const answerIsString = typeof faq.answer === 'string'
                    const qLower = faq.question.toLowerCase()
                    const aLower = answerIsString ? faq.answer.toLowerCase() : faq.answer.join(' ').toLowerCase()
                    if (qLower.includes(queryLower) || aLower.includes(queryLower)) {
                        results.push(faq)
                    }
                })
            })
        }
        setSearchResults(results)
    }

    const clearSearch = () => {
        setSearchResults([])
        setQuery('')
    }
  

    const resultsText = searchResults.length === 1 ? `${searchResults.length} Result Found` : `${searchResults.length} Results Found`
    const { phone } = getClientContent(clientId)?.variableText?.customerService
    const { email } = getClientContent(clientId)?.variableText?.customerService
    const isET = isETRoute(clientId)

    return (
        <>
            <div className={styles.pageHeader}>
                <Container>
                    <form className={styles.pageHeaderContent}>
                        <h1 className="h3 cus" id="faqSearchLabel">How can we help?</h1>
                        <div className={styles.searchBox}>
                            <input
                                className={`form-control ${styles.searchInput}`}
                                type='text'
                                id='faqSearch'
                                aria-labelledby="faqSearchLabel"
                                value={query}
                                onChange={handleQuery}
                                placeholder="Search..."
                            />
                            {query ? (
                                <span onClick={clearSearch} >
                                    <ClearSearchIcon className={classNames(styles.close, styles.searchBarIcon)} />
                                </span>
                            ) : (
                                <span>
                                    <SearchIcon className={styles.searchBarIcon} />
                                </span>
                            )}
                        </div>
                        {/* {query && <p>{`${searchResults.length} Results Found`}</p>} */}
                    </form>
                </Container>
            </div>

            {query && !!searchResults.length && (
                <Container className='p-5'>
                    {!isET && <h2 className="h3">Frequently Asked Questions</h2>}
                    <h2 className={classNames("h5", styles.resultsCounter)}>{resultsText}</h2>
                    <div className='tabContent'>
                        {searchResults.map((res, i) => (
                            <Accordion
                                id={``}
                                question={res.question}
                                answer={res.answer}
                            />
                        ))}
                    </div>
                </Container>
            )}

            {query && searchResults.length === 0 && (
                <Container className="p-5">
                    {!isET && <h2 className="h3">Frequently Asked Questions</h2>}
                    <h2 className={classNames("h5", styles.resultsCounter)}>{resultsText}</h2>
                </Container>
            )}

            {!query && !searchResults?.length && (
                <Container className={'p-5'}>
                    {(clientId !== 'njr') && <h2 className="h3">Frequently Asked Questions</h2>}
                    
                    {categories?.length > 1 && (
                        <ul className={`nav ${styles.faqNavs} row no-gutters`}>
                            {categories.map((cat, index) => (
                                <li className={styles.navItem} >
                                    <p
                                        className={`${styles.navLink} ${activeCategory === index && styles.active}`}
                                        onClick={() => setActiveCategory(index)}
                                    >
                                        {cat.title}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className='tab-content'>
                        {categories && categories[activeCategory]?.faqs?.map((faq, i) => (
                            <Accordion
                                id={i > 9 ? `${i}.` : `0${i + 1}.`}
                                question={faq.question}
                                answer={faq.answer}
                                key={i}
                            />
                        ))}
                    </div>
                </Container>
            )}

            <Container className={classNames("p-5", styles.moreQuestions)}>
                <h3 className="h4">
                    Still Have Questions?
                </h3>
                {isET ? (
                    <p>If you are unable to find answers to your questions within our FAQs, you can always contact us!</p>
                ) : (
                    <p>If you are unable to find answers to your questions within our FAQs, you can always contact us at <CallLink phone={phone} />!</p>
                )}
                {isET && (
                    <ContactUsCards
                        email={email}
                        emailDescription={'Send us an email'}
                        phone={phone}
                        phoneDescription={'Available 6 days a week'}
                    />
                )}
            </Container>
        </>
    )
}

export default FAQ;