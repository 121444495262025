import React, { useEffect, useContext, useState } from 'react';
import { AppointmentGrid, ApptContext, NoAppointments, NoServicers } from '.';
import { Container } from 'reactstrap';
import { Spinner } from 'react-bootstrap'
import moment from 'moment';
import { v4 as uuid4 } from 'uuid';
import { useHistory } from 'react-router-dom';

export const DisplayController = (props) => {
    const { state: { 
        servicerData, 
        selectedServicer, 
        appointment, 
        noServicersFound,
        noAppointmentsFound, 
        viewAllServicers,
        notesForServicer,
        contractAssetDetails
    }, dispatch } = useContext(ApptContext);

    const [loading, setLoading] = useState(true);
    const history = useHistory()
    const isWarrantyOfWork = history?.location?.isWarrantyOfWork == true ? true : false;
    const eventId = history?.location?.eventId;
    
    if (typeof props.history !== "undefined") {

        if (!props.history) {
            props = JSON.parse(sessionStorage.getItem("appointmentProps"));
        }

        if (!props.history.location.appointmentProps) {
            history.push({
                appointmentProps: props
            });
       }
    }
    
    const handleSubmit = async () => {
        const appointmentProps = props?.history?.location?.appointmentProps ? props?.history?.location?.appointmentProps : props?.history?.location?.state;
        const contractAssetId = appointmentProps?.claim?.contractAssetId;
        let currentAsset = appointmentProps?.entitlementDetails?.contract?.contractAssetModel?.filter(e => e.contractAssetId === contractAssetId);

        if (!currentAsset || currentAsset?.length <= 0) {
            const currentContract = await fetch(`/api/contract/GetByContractAssetId/` + contractAssetId + '/' + props?.clientId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            currentAsset = currentContract?.contractAssetModel?.filter(e => e.contractAssetId === contractAssetId);
        }

        var appointmentStartTime = '8:00 AM';
        var appointmentEndTime = '5:00 PM';
        switch (appointment?.window) {
            case 'AM':
                appointmentEndTime = '12:00 PM';
                break;
            case 'PM':
                appointmentStartTime = '12:00 PM';
                break;
            case 'ALL_DAY':
                //appointmentStartTime = '8:00 AM';
                //appointmentEndTime = '5:00 PM';
                break;
            default:
        }

        const selectedAppointment = servicerData?.appointmentSearchResponse?.servicers?.[0]?.appointments?.filter(appt => {

            return (noAppointmentsFound ? (servicerData?.appointmentSearchResponse?.servicers?.[0]?.appointments) :
                moment(appt.date).isSame(appointment?.date)
                && appt.appointmentStartTime === appointmentStartTime && appt.appointmentEndTime === appointmentEndTime)
        });

        const party = appointmentProps?.entitlementDetails?.contract?.party ?? props?.location?.claim?.partyModel;
        const entitlementDetails = appointmentProps?.entitlementDetails;
        let eventNumber = typeof appointmentProps?.result?.eventModel?.[0].eventNumber !== 'undefined' ?appointmentProps?.result?.eventModel?.[0].eventNumber : props?.eventNumber;
        let repeatCall = props?.eventTypeId === 5 && props?.eventNumber !== null ? true : false;

        const body = {
            "ClaimId": parseInt(props?.claimId ? props?.claimId : appointmentProps?.claim?.claimId),
            "ClaimNumber": eventNumber ? eventNumber : appointmentProps?.claim?.eventNumberToResume,
            "ProductInformation": {
                "ProductDetails": {
                    "ProductCode": contractAssetDetails?.productCode,
                    "BrandCode": contractAssetDetails?.brandCode,
                    "ModelNumber": currentAsset?.[0].modelNumber,
                    "SerialNumber": currentAsset?.[0].serialNumber,
                    "InstallationDate": currentAsset?.[0].assetPurchaseDate,
                    "EquipmentRetail": currentAsset?.[0].assetPurchasePrice ?? 0
                },
                "ProductLocation": {
                    "Street": entitlementDetails?.billingAddress?.address1,
                    "Aux": "",
                    "City": entitlementDetails?.billingAddress?.city,
                    "State": entitlementDetails?.billingAddress?.state,
                    "Zip": entitlementDetails?.billingAddress?.zip
                }
            },
            "AccountId": contractAssetDetails?.accountId,
            "AppointmentToBook": selectedAppointment?.[0],
            "WarrantyType": "SC",
            "ServiceType": "Repair",
            "ProblemCode": "",
            "ServicerToBook": servicerData?.appointmentSearchResponse?.servicers?.[0],
            "CustomerInformation": {
                "FirstName": party?.firstName,
                "LastName": party?.lastName,
                "EmailAddress": party?.partyEmailModel?.[0].address,
                "MobilePhone": party?.partyPhoneModel?.[0].number,
                "HomePhone": "",
                "ServiceAddress": {
                    "Street": party?.serviceAddress?.address1,
                    "Aux": "",
                    "City": party?.serviceAddress?.city,
                    "State": party?.serviceAddress?.state,
                    "Zip": party?.serviceAddress?.zip
                }
            },
            "ServiceSite": "IH",
            "ProblemType": "REP",
            "ProblemDescription": `${appointmentProps?.selectedSymptom?.symptom}_${appointmentProps?.claim?.incidentDescription}`,
            "EmergencyService": false,
            "RepeatCall": repeatCall,
            "SpecialInstructions": notesForServicer,
            "IsRedispatch": false,
            "IsReschedule": false
        }

        const settings = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        //let userId = JSON.parse(sessionStorage.getItem("user")).id;

        const response = await fetch(`/api/dispatchservice/appointments/book/${props?.clientId}`, settings);
        //if (!response.ok) throw Error(response.message);
        //const response = await result?.json()

        try {
            const data = await response.json();
            //.push('/confirmation', { statusType: 'type1' })
            return data;
        } catch (err) {
            throw err;
        }
    }


    useEffect(()=>{
        // WIREUP: API CALL HERE, REPLACE FAKE DATA ABOVE.
        // .then(res => dispatch({
        //      type: 'UPDATE_DATA',
        //      data: res
        // }))

        // TIMEOUT JUST TO TEST LOADING SPINNER REMOVE AFTER API WIREUP
        const fetchData = async () => {
            setLoading(true);
            const contractAssetId = parseInt(typeof props?.claim?.contractAssetId == 'undefined' ? props?.contractAssetId : props?.claim?.contractAssetId);
            let currentAsset = props.entitlementDetails?.contract?.contractAssetModel?.filter(e => e.contractAssetId === contractAssetId);
            const masterProgramId = props.entitlementDetails?.contract?.riskProfile?.masterProgramId;

            if (!currentAsset || currentAsset?.length <= 0) {
                const currentContract = await fetch(`/api/contract/GetByContractAssetId/` + contractAssetId + '/' + props?.clientId, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                currentAsset = currentContract?.contractAssetModel?.filter(e => e.contractAssetId === contractAssetId);
            }

            try {
                const contractAssetDetailsResponse = await fetch(`/api/contract/GetContractAssetDetails/` + props?.clientId, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        contractAssetId: contractAssetId,
                        claimId: props?.claimId
                        //masterProgramId: masterProgramId
                    })
                });
                var contractAssetDetails = await contractAssetDetailsResponse?.json();
                
                dispatch({
                    type: 'CONTRACT_ASSET_DETAILS',
                    contractAssetDetails
                })

                const result = await fetch(`/api/dispatchservice/appointments/search/${props?.clientId}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        productInformation: {
                            productDetails: {
                                productCode: contractAssetDetails?.productCode,
                                brandCode: contractAssetDetails?.brandCode,
                                modelNumber: currentAsset?.[0].modelNumber,
                                serialNumber: currentAsset?.[0].serialNumber,
                                installationDate: currentAsset?.[0].assetPurchaseDate,
                                equipmentRetail: currentAsset?.[0].assetPurchasePrice ?? 0
                            },
                            productLocation: {
                                zip: props.entitlementDetails?.party?.serviceAddress?.zip
                            }
                        },
                        searchStartDate: moment().format('MM/DD/YYYY'),
                        numberOfDaysToSearch: 14,
                        accountId: contractAssetDetails?.accountId,
                        serviceType: "REP",
                        procedureCode: "FS",
                        warrantyType: "SC",
                        responseIdentifier: uuid4(),
                        redispatchEventId: props?.redispatchEventId === null ? 0 : props?.redispatchEventId,
                        isRedispatch: false,
                        isReschedule: false,
                    })
                });
                
                let response = await result?.json()

                //wow servicers
                let wowResponse = {
                    "responseIdentifier": response?.responseIdentifier,
                    "resultMessages": response?.resultMessages,
                    "success": response?.success,
                    "servicers": response?.servicers?.filter(r => r.id === props?.servicerToBook?.servicerId)
                }
                if (props?.eventTypeId == 5 && props?.eventNumber != null && props?.servicerToBook?.servicerId != null && props?.servicerToBook?.servicerId != "" && wowResponse.servicers.length > 0) {
                    response = wowResponse;
                }
                dispatch({
                    type: 'UPDATE_DATA',
                    data: { appointmentSearchResponse: { ...response } }
                });
            } catch (error) {
                console.log('fetch appointments error', error)
            }

            setLoading(false);
        };

        fetchData();

   }, [])

    useEffect(() => {
        if (noAppointmentsFound) {
            handleSubmit()
        }

    }, [noAppointmentsFound])
  

    // display spinner until API call loads.
    if (loading) return (
        <div className="mainContainerWrapper">
            <Container className="pageLoading">
                <div className="centeredXY text-center">
                    <Spinner animation="border" variant="primary" role="status" />
                    <p>Looking for Appointments</p>
                </div>
            </Container>
        </div>
    );;
    
    const { servicers } = !!servicerData && servicerData.appointmentSearchResponse;

    const getApptArray = () => {
        const appts = {}

        for (let i = 1; i <= 14 ; i++) {
            // create object containing next 2 weeks
            const day = moment().add(i, 'days').format('l');

            // THIS CONDITION REMOVES SUNDAYS.
            // IF DISPLAYING SUNDAYS, REMOVE THIS CONDITION (but keep the expression inside)
            if (moment(day).day() !== 0) {
                appts[day] = {am: false, pm: false, allDay: false};
            }
        }

        const appointmentHelper = (srvcr) => {
            for (const a of srvcr.appointments) {
                const apptDate = moment(a.date).format('l');

                // if B.E. only returns days in the future (dates starting tomorrow),
                // then this IF condition may be removed at WIRE UP.
                if (moment(apptDate).isAfter(moment(), 'day') && appts[apptDate]) {
                    if (a.appointmentEndTime.includes('12:00')) {
                        appts[apptDate].am = true;
                    } else if (a.appointmentEndTime.includes('5:00')) {
                        a.appointmentStartTime.includes('12:00') ?
                        appts[apptDate].pm = true
                        : appts[apptDate].allDay = true;
                    }
                }
            }
        }  

        if (viewAllServicers) {
            if (!selectedServicer || !selectedServicer.id) {
                return appts; 
            }
            appointmentHelper(selectedServicer)
        } else {
            for (const s of (servicers ?? [])) {
                appointmentHelper(s);
            }
        }

        return appts;
    }

    const getFilteredServicers = () => {
        if (viewAllServicers) return servicers;

        let avail = [];
        var minDate = moment("0001-01-01T00:00:00");
        for (const s of (servicers ?? [])) {
            
            for (const a of s.appointments) {
                if (moment(a.date).isSame(appointment.date)) {
                    const start = a.appointmentStartTime;
                    const end = a.appointmentEndTime;
                    const { window } = appointment;
                    if (
                        (window === 'ALL_DAY' && start.includes('8:00') && end.includes('5:00'))
                        || (window === 'AM' && start.includes('8:00') && end.includes('12:00'))
                        || (window === 'PM' && start.includes('12:00') && end.includes('5:00'))
                    ) {
                        !avail.includes(s) && avail.push(s)
                    }
                }
                else if (a.appointmentStartTime == '' && a.appointmentEndTime == '' && moment(a.date).isSame(minDate)) {
                    !avail.includes(s) && avail.push(s)
                }
            }
        }
        return avail.sort((a, b) => a.ranking < b.ranking);
    }

    //Skip to confirmation if no appoinments found
    if (noAppointmentsFound) {
        
        history.push('/fileclaim/confirmation', { statusType: 'type2-3', claimNumber: props?.claim?.claimNumber, servicerToBook: servicerData?.appointmentSearchResponse?.servicers?.[0], clientId: props?.clientId, claimId: props?.claim?.claimId, contractAssetId: props?.claim?.contractAssetId, isWarrantyOfWork, eventId });
        return 'Loading...';
    }

    // SWITCH DISPLAYS BASED ON CASE HERE.
    if (noServicersFound) return <NoServicers {...props} />;
    if (noAppointmentsFound) return <NoAppointments servicers={getFilteredServicers()} />;

    return (
        <AppointmentGrid appts={getApptArray()} servicers={getFilteredServicers()} history={history} {...props}/>
    )
}
