import React from 'react';
import ScriptBox from '../../shared/ScriptBox/ScriptBox';
import { SpecialNotesToServicer } from '.';
import { ServicersTable } from '.';

/**
 * BELONGS TO SEU 337
 */

export const NoAppointments = ({servicers}) => {
    return(
        <section>
            <ScriptBox 
                message="The servicer will contact you within 24 hours to schedule service at a time that is convenient for you."
            />

            {/* 
                THIS IS REPETETIVE. WE COULD JUST REPLACE THE TABLE WITH A SCRIPTBOX IN 
                THE APPOINTMNET GRID COMPONENT ONLY QUESTION IS THE FUNCTION/PRESENCE OF 
                A "SHOW ALL SERVICERS" SWITCH. I WOULD ARGUE THAT SWITCH DOESN'T MAKE 
                SENSE IN THIS CASE. IF THERE ARE NO APPOINTMENTS, WE WILL SHOW ALL AVAILABLE
                TECHNICIANS. 
            */}

            <ServicersTable servicers={servicers} />

            <SpecialNotesToServicer />

        </section>
    )
}