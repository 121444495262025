import React, { Component } from "react";
import "./Faqs.Module.css";
import { Container, Card, CardHeader, CardBody, Collapse } from "reactstrap";
import classNames from "classnames";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

class Ques extends Component {

    state = {
        open: [],
        searchResults: [],
        totalCount: 0,
	    faqQuestionsList: document.querySelectorAll('.faqs .question')
    };

    componentDidMount() {     
        this.setState(
            { faqQuestionsList: document.querySelectorAll('.faqs .question') },
            () => this.handleSearch(this.props.searchTerm, true)
        )
    }

    toggle = (e) => {
        const { currentTarget } = e;
        const name = currentTarget.getAttribute("name");
        console.log(name, currentTarget);

        let pos = -1;
        const { open } = this.state;
        if (name) {
            pos = open.indexOf(name);
            if (pos > -1) {
                open.splice(pos, 1);
            } else {
                open.push(name);
            }
        }
        this.setState({ open });
    };



    handleSearch = (searchTerm, isSearchFromMount) => {
        
        const list = document.querySelectorAll('.faqs .question');	
	    let updatedFaqList = isSearchFromMount ? list : [...this.state.faqQuestionsList];
        console.log('list', updatedFaqList);
        let searchList = [];
        let number = 1;
        for (var i = 0; i < updatedFaqList.length; ++i) {
            var item = updatedFaqList[i];
            console.log(item)
            console.log(item.innerHTML)
            console.log(item.querySelectorAll('p'))

            if (item.innerText.toLowerCase().includes(searchTerm.toLowerCase())) {
                const allTags = item.querySelectorAll('p');
                allTags[0].innerHTML = number <= 9 ? `0${number}` : number;
                const closestElement = item.closest('.questionCard')
                document.getElementById('searchResults').appendChild(closestElement)
                number++;
                searchList.push(closestElement)
            } else {

                const closestElement = item.closest('.questionCard')
                if (document.getElementById('searchResults').contains(closestElement)) {
                    document.getElementById('searchResults').removeChild(closestElement)
                }
            }
        }
        console.log('s', searchList)

        this.setState({ searchResults: searchList, totalCount: --number })
    }

    render() {
        const { searchTerm, totalCount, searchResults, open } = this.state;
        console.log(searchResults);
        return (
            <div>
                <h4 className="count" role="status">{totalCount} Search Results Found</h4>
                <div id="searchResults"></div>


                <Container>

                    <div className="faqs tab-content" id="tabs-tabContent">
                        <div

                            id="tabsGeneralFAQs"
                            role="tabpanel"
                            aria-labelledby="generalFAQs"
                        >
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="general0"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("general0") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>01.</p>
                                            <p>What is AppleCare+ with Theft and Loss?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("general0") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("general0")}>
                                    <CardBody>
                                        <p>
                                            AppleCare+ with Theft and Loss is an optional device protection program that provides combined benefits under a service contract (AppleCare+) for hardware coverage, technical support, battery depletion, and accidental damage from handling (ADH) and an insurance policy (Theft and Loss) that provides benefits for the enrolled device including hardware coverage.
                                                </p>
                                        <p className="mb-0">
                                            To purchase Theft and Loss as a stand-alone insurance policy, please see detailed pricing on your state-specific <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents</Link>, send an email to <a href="mailto:contractcreation@aig.com">contractcreation@aig.com</a> or call <a href="tel:866-243-6434" className="callLink">(866) 243-6434.</a>
                                        </p>
                                    </CardBody>
                                </Collapse>
                            </Card>

                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="general1"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("general1") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>02.</p>
                                            <p>Is there a deductible?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("general1") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("general1")}>
                                    <CardBody>
                                        A service fee or deductible of $149 will apply per approved theft or loss incident/claim.
                                                For specific details about the Plan’s deductibles, refer to your state-specific{" "}
                                        <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents</Link>.
                    </CardBody>
                                </Collapse>
                            </Card>



                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="general3"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("general3") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>03.</p>
                                            <p>How many claims are covered under a plan?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("general3") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("general3")}>
                                    <CardBody>
                                        <p>
                                            AppleCare+ with Theft and Loss provides coverage for: a
                                            maximum of 2 repairs or replacements per 12-month period
                                            from your date of purchase for accidental damage from
                                            handling (ADH) provided by Apple under AppleCare+,
                                            subject to the per incident service fees listed below;
                                            and a maximum of 2 replacements per 12-month period from your date of purchase for loss or theft under the Theft and Loss insurance policy, subject to the per claim
                                            deductible listed below. The maximum amount to be
                                            paid for any one claim is limited to the retail price of
                                            your device. There is no claim limit for hardware
                                            coverage (malfunction after the manufacturer’s warranty
                                            expires). For specific details about the Plan’s claim
                        limits, refer to your state-specific{" "}
                                            <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents.</Link>
                                        </p>
                                        <p className="mb-0">
                                            For your lost or stolen claim to be eligible for
                                            coverage, Find My iPhone must be enabled on your device
                                            when it is lost or stolen.
                      </p>
                                    </CardBody>
                                </Collapse>
                            </Card>

                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="general4"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("general4") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>04.</p>
                                            <p>How long do I have to file a claim?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("general3") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("general4")}>
                                    <CardBody>
                                        The Plan owner must file a claim within the time frame
                      indicated in the Plan’s state-specific{" "}
                                        <Link to="/vendor/apple/coveragedocs" target="_blank" aria-label="Coverage Documents opens a new details window">Insurance Coverage Documents.</Link>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </div>



                        <div

                            id="tabs-claimFAQs"
                            role="tabpanel"
                            aria-labelledby="claimFAQs"
                        >
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="claims0"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("claims1") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>01.</p>
                                            <p>What is the fastest way to file a claim?</p>
                                        </div>

                                        <div
                                            className={`${
                                                open.includes("claims0") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("claims0")}>
                                    <CardBody>
                                        To begin a theft and loss claim,
                                            please visit <a href="https://support.apple.com/choose-country-region" target="blank">support.apple.com</a>  or contact Apple Support at {" "}
                                        <a href="tel:800-275-2273" className="callLink">
                                            800-APL-CARE (800-275-2273)
                                        </a>.
                                         Please use the unique link in the email from the AIG Theft and Loss Service Center to complete your claim.
                                                                       Once you begin your Theft or Loss claim,
                                                                       your device will be erased and disabled.
                                                                       Once your claim is approved, ownership will be transferred to AIG.
                                        </CardBody>
                                </Collapse>
                            </Card>
                            <Card className="questionCard">
                                <CardHeader className="card-header">
                                    <div
                                        onClick={this.toggle}
                                        name="claims1"
                                        className="accordionFaq"
                                    >
                                        <div
                                            className={`${
                                                open.includes("claims1") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>02.</p>
                                            <p>What information do I need to start a claim?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("claims1") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("claims1")}>
                                    <CardBody>

                                        <ul>
                                            <li>Apple ID and password </li>
                                            <li>The claimed device's Serial Number</li>
                                            <li>
                                                Information about the claim, including the date of
                          loss{" "}
                                            </li>
                                            <li>
                                                Email Address (to provide you with updates on the
                                                status of your claim or replacement device)
                        </li>
                                            <li>
                                                A credit card or debit card for your deductible
                                                payment
                        </li>
                                        </ul>
                                    </CardBody>
                                </Collapse>
                            </Card>
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="claims2"

                                >

                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("claims2") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>03.</p>
                                            <p>
                                                What is the importance of providing a valid email
                                                during the claims process?
                        </p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("claims2") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("claims2")}>
                                    <CardBody>
                                        Your email address allows us to provide you with important
                                        information on the status of your claim, such as when it
                                        has been approved, when your device shipped, and a
                                        tracking number to confirm delivery of your device. Please
                                        note that your email address is used for claim purposes
                                        only.
                    </CardBody>
                                </Collapse>
                            </Card>
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="claims3"
                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("claims3") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>04.</p>
                                            <p>How can I check the status of my claim?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("claims3") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("claims3")}>
                                    <CardBody>
                                        The fastest and easiest way to check the status of your
                      claim anytime is by visiting the <a href="https://aigtheftandloss.com/" target="_blank"> Homepage</a>{" "}
                                        please check the link on this site and entering your Apple Dispatch ID or
                                         Case/Claim Number and Zip Code.
                    </CardBody>
                                </Collapse>
                            </Card>
                        </div>



                        <div

                            id="tabsReplacementFAQs"
                            role="tabpanel"
                            aria-labelledby="ReplacementFAQs"
                        >
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="replacement0"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("replacement0") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>01.</p>
                                            <p>
                                                How long does it take to receive a replacement device?
                        </p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("replacement0") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("replacement0")}>
                                    <CardBody>
                                        Once your claim is approved, your device is ordered and shipped directly from Apple
                                                 via next-business-day delivery Monday through Friday;
                                                when available, Saturday delivery may be available for an additional fee.
                                    </CardBody>
                                </Collapse>
                            </Card>
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="replacement2"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("replacement2") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>02.</p>
                                            <p>How do I activate my replacement device?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("replacement2") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("replacement2")}>
                                    <CardBody>
                                        <p>
                                            Enclosed with each replacement is a Quick Start Guide to
                                            help you get your replacement device up and running. If
                                            your replacement is for a loss or theft claim, please
                                            use the instructions below:
                                        </p>
                                        <ol>
                                            <li>Charge your replacement device</li>
                                            <li>Insert your new SIM card using the provided SIM-eject
                                                tool
                                            </li>
                                            <li>Power on your device and follow on-screen instructions
                                            </li>
                                            <li>Enable Find My iPhone, follow the steps provided at{" "}
                                                <a href="https://support.apple.com/en-us/HT205362" target="_blank"> support.apple.com/HT205362</a>
                                            </li>
                                        </ol>
                                    </CardBody>
                                </Collapse>
                            </Card>
                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="replacement3"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("replacement3") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>03.</p>
                                            <p>Does my replacement device have a warranty?</p>
                                        </div>
                                        <div
                                            className={`${
                                                open.includes("replacement3") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("replacement3")}>
                                    <CardBody>
                                        Your replacement device comes with a 90-day warranty or
                                        the remainder of your AppleCare+ with Theft and Loss
                                        agreement, whichever is longer.
                    </CardBody>
                                </Collapse>
                            </Card>


                            <Card className="questionCard">
                                <CardHeader className="card-header"
                                    onClick={this.toggle}
                                    name="replacement5"

                                >
                                    <div className="accordionFaq">
                                        <div
                                            className={`${
                                                open.includes("replacement4") ? "active" : "closed"
                                                } question`}
                                        >
                                            <p>04.</p>
                                            <p>Need help with your replacement?</p>
                                        </div>

                                        <div
                                            className={`${
                                                open.includes("replacement5") ? "active" : "closed"
                                                } arrow`}
                                        >
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 48 24"
                                                style={{
                                                    enableBackground: "new 0 0 48 24",
                                                    height: "8px",
                                                }}
                                                xmlSpace="preserve"
                                            >
                                                <polygon
                                                    className="st0"
                                                    points="48,2.1 45.9,0 24,19.7 2.1,0 0,2.1 24,24 "
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={open.includes("replacement5")}>
                                    <CardBody>
                                        <p>
                                            For questions regarding your shipment or hardware issues, please contact Apple at{" "}
                                            <a href="tel:800-692-7753" className="callLink">
                                                (800) 692-7753
                        </a>.
                      </p>
                                        <p>
                                            For mobile service issues, contact your mobile service
                                            provider.
                      </p>
                                        <p>
                                            For all other issues, please contact us at{" "}
                                            <a href="tel:866-258-7419" className="callLink">
                                                (866) 258-7419
                        </a>
                                            .
                      </p>
                                    </CardBody>
                                </Collapse>
                            </Card>

                        </div>

                    </div>

                </Container>
            </div>
        );

    }
}

export default Ques;
