import React, { Component } from 'react';
import {
    Container, Row, Col
} from 'reactstrap';
import styles from './ClaimOverview.module.css';
import Progress from '../../../components/shared/StatusProgress/Progress';
import classNames from 'classnames';
import { UploadDocs } from '../../../components/Vendor/Apple/UploadDocs/UploadDocs';
import moment from 'moment';
import DocumentTitle from 'react-document-title';
import ClaimDetails from '../../ProgressClaimDetails/ClaimDetails';
import { updateRepair, updatePartnerCase, cancelClaim, callAdobe } from '../../../helpers';
import { phoneFormat } from '../../../components/shared/util';
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent'
import { ToSpeakWithARepresentative } from '../../../components/Vendor/Apple/IntakeLoss/ConfirmationLT';
import CancelModal from '../../../components/modals/CancelModal';
import { CombinedLangContractContext } from '../../../components/contexts/ProviderLangContractContext';
import { ContractContext } from '../../../components/contexts/ContractContext';
import VisaIcon from '../../../assets/images/cc-visa.svg';
import MasterCardIcon from '../../../assets/images/cc-mastercard.svg';
import AmexIcon from '../../../assets/images/cc-amex.svg';
import DiscoverIcon from '../../../assets/images/cc-discover.svg';

let rtCol = styles.rtCol;
let btmLine = styles.btmLine;
let h2 = "h2";
let caseTitle = styles.caseTitle;
let colLg4 = "col-lg-4";
let colLg5 = "col-lg-5";
let noGutters = "no-gutters"
let progressItem = styles.progressItem;
let progressItemCompleted = styles.progressItemCompleted;
let progressItemActive = styles.progressItemActive;
let orderFirst = "order-first";
let orderLGLast = "order-lg-last";
let orderLast = "order-last";
let orderLGFirst = "order-lg-first";
let overviewBody = styles.overviewBody;
let hoverHand = styles.hoverHand;
let allUpdate = styles.allUpdate;
let contentWrapper = styles.contentWrapper;
let mrAuto = "mr-auto";
let h6 = "h6"

export class ClaimOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claim: this.props.history.location.state?.claim || true,
            claimId: this.props.history.location.state?.claimId || true,
            claimNumber: this.props.history.location.state?.claimNumber || true,
            additionalReview: this.props.history.location?.state?.additionalReview,
            isDocUploaded: (typeof this.props.history.location.state === "undefined") ? true : this.props.history.location.state.isDocUploaded,
            isUnderReview: this.props.history.location?.state?.isUnderReview,
            isValidClaim: this.props.history.location?.state?.isValidClaim,
            claimOverview: null,
            caseNumber: this.props.history.location?.state?.caseNumber,
            isDeductibleUnsuccessful: this.props.history.location?.state?.isDeductibleUnsuccessful,
            isPendingReview: this.props.history.location?.state?.isPendingReview,
            isClaimDenied: this.props.history.location?.state?.isClaimDenied,
            errorMessage: '',
            ccNumber: this.props.history.location.state?.ccNumber || true,
            ccType: this.props.history.location.state?.ccType || true,
            pageTitle: 'Claim Overview | AIG AppleCare+ with Theft and Loss Claims',
            countryCode: this.props?.history?.location?.state?.entitlementDetails?.countryCode,
            showCancelModal: false
        }
        
        this.fetchClaimOverview = this.fetchClaimOverview.bind(this);
        this.getCCType = this.getCCType.bind(this);
    }


    componentDidMount() {
        this.fetchClaimOverview();
    }

    getCCType = () => {
        switch (this.state.ccType) {
            case "visa":
                return (<img className={styles.ccImage} alt="Visa" src={VisaIcon} />)
            case "mastercard":
                return (<img className={styles.ccImage} alt="mastercard" src={MasterCardIcon} />)
            case "amex":
                return (<img className={styles.ccImage} alt="Amex" src={AmexIcon} />)
            case "discover":
                return (<img className={styles.ccImage} alt="Discover" src={DiscoverIcon} />)
            default:
                return (<img className={styles.ccImage} alt="Unidentified" />)
        }
    }

    fetchClaimOverview = async () => {
        try {
            const claimId = this.state.claimId;
            const caseId = this.state.caseNumber;
            const response = await fetch(`/api/claim/GetClaimOverview/${claimId}/${caseId}/apple`);
            const claims = await response.json();
            this.setState({ claimOverview: claims });
            // FOR ADOBE ANALYTICS
            callAdobe({
                pagePath: 'vendor/apple/claimoverview',
                pageName: 'Claim Overview',
                country: this.context.languageContext?.country,
                language: this.context.languageContext?.language,
                currency: this.context.languageContext?.countryVars.currency.abbreviation,
                caseId: this.props.history.location?.state?.caseNumber,
                claimType: claims?.coverageName,
                claimStatus: (this.state.isDocUploaded && this.state.isUnderReview)
                    ? 'claimInReview'
                    : (this.state.isDocUploaded && !this.state.isUnderReview)
                        ? 'docsUploaded'
                        : 'docsPending'
            })
        } catch (error) {
            this.setState({ errorMessage: 'Error fetching claim overview. Try again.' })
        }
    }

    callBackUploadDocSuccess = (isDocUploaded) => {
        this.setState({ isDocUploaded: isDocUploaded, isUnderReview: isDocUploaded });

    }

    displayClaimedDevice = (claim) => {
        if (claim == undefined || claim == null)
            return false;

        let validModel = claim.modelNumber !== undefined && claim.modelNumber != null && claim.modelNumber.length > 0;
        let validSerial = claim.serialNumber !== undefined && claim.serialNumber != null && claim.serialNumber.length > 0;
        return validModel && validSerial;
    }

    saveForLater = (e) => {
        e.preventDefault();
        e.preventDefault();

        this.setState({ uploading: true });
        let statusCode = '';
        fetch(`api/claim/SaveForLater/${this.state.claimId}/apple/5/0`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.claim)
        })
            //.then(function (res) {
            //    // this.setState({ isUpdatebtnDisable: true }); //disabling upload button aftrer file upload button click
            //    statusCode = res.status;
            //    return res.json();
            //})
            .then(data => {
                if (data.status === 204) {
                }
                if (data.status === 200) {
                    this.props.history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: this.props.location.state.claim.caseNumber });
                }
                this.setState({ uploading: false });
            });
    }

    handleCancelClaim = () => {
        const { claimNumber, caseNumber } = this.state
        updateRepair("apple", claimNumber, "CCCR")
            .then(res => {
                cancelClaim("apple", claimNumber, false);
                updatePartnerCase(claimNumber, "CCCR", "apple");
                this.props.history.push('/vendor/apple/confirmation', { statusType: "cancelledClaim", caseNumber });
            });
    }

    handleToggleCancelClaimModal = (action) => {
        switch(action) {
            case "OPEN":
                this.setState({showCancelModal: true})
                return
            case "CLOSE":
                this.setState({showCancelModal: false})
                return
            default: return
        }
    }

    render() {
        const { pageTitle, claimOverview, showCancelModal } = this.state;
        const countryCode = this.props?.history?.location?.state?.entitlementDetails?.countryCode
        let shipToAddress = claimOverview?.replacementParty?.shippingAddress || claimOverview?.replacementParty?.serviceAddress;
        let replacementShipToAddress;
        const { contractContext: { data: { isChileApple } } } = this.context;

        if (claimOverview) {
            replacementShipToAddress = {
                firstName: claimOverview.replacementParty.firstName,
                lastName: claimOverview.replacementParty.lastName,
                address1: shipToAddress.address1,
                address2: shipToAddress.address2,
                zip: shipToAddress.zip,
                city: shipToAddress.city,
                state: shipToAddress.state || '',
                stateId: shipToAddress.stateId,
                phone: phoneFormat(claimOverview.replacementShiptoPhone),
                email: claimOverview.replacementShiptoEmail,
                countryCode: shipToAddress.countryCode
            }
        }

        return (
            <DocumentTitle title={pageTitle} >
                <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/ClaimOverview'}>
                    {international => {
                        const {
                            englishVars,
                            country,
                            countryVars: {
                                date: {
                                    momentJsLongFormat
                                }
                            },
                            translations,
                        } = international;
                        const steps = (dateFormat) => [{
                            name: translations.claimReceived || 'Claim Received',
                            style: 'completed',
                            date: moment(claimOverview != null ? claimOverview.claimSetupDate != null ? claimOverview.claimSetupDate : "" : "").format(dateFormat),
                            id: 'claimReceived'
                        }, {
                            name: translations.claimApproved || 'Claim Approved',
                            style: claimOverview != null ? claimOverview.isClaimApproved ? 'completed' : 'active' : 'active',
                            date: null,
                            id: 'claimApproved'
                           
                        }, {
                            name: translations.deviceShipped || 'Device Shipped',
                            style: claimOverview != null ? claimOverview.trackingNumber != null ? 'completed' : 'active' : 'active',
                            date: null,
                            id: 'deliveryId'
                        }]
                        return (
                            <Container className="contentWrapper pl-md-0">
                                <Row>
                                    <Col lg="7" className={classNames(overviewBody, mrAuto, orderLast, orderLGFirst)}>
                                        {!this.state.isDocUploaded && !claimOverview?.isClaimApproved && 
                                            <section id="uploadDocsWrapper">
                                                <UploadDocs
                                                    history={this.props.history}
                                                    pendingDocs="true"
                                                    isClaimOverview="true"
                                                    onDocumentUploadSuccess={this.callBackUploadDocSuccess}
                                                    onSaveForLaterAction={this.saveForLater}
                                                    claimNumber={this.props.claimNumber}
                                                    entitlementDetails={this.props.entitlementDetails}
                                                    isSubmittedClaim={this.state.isDeductibleUnsuccessful && (this.state.isPendingReview || this.state.isClaimDenied)}
                                                    hideCancelClaimButton={!this.state.isDeductibleUnsuccessful}
                                                />
                                            </section>
                                        }
                                        {this.state.isDocUploaded && this.state.isUnderReview && !claimOverview?.isClaimApproved &&
                                            <section id="uploadDocsConfirm" className={styles.overviewSection}>
                                                <h1 className={classNames(h2, btmLine)}>{translations.title || 'Claim in Review'}</h1>
                                                <p>{translations?.uploadedInReviewP1 || <><b>Thank you for uploading your document(s).</b> Once one of our representatives has reviewed the information provided, you’ll be notified of the outcome by email. Please allow up to 2 business days for our team to review and process.</>}</p>
                                                <p>{translations?.uploadedInReviewP2 || `Should your claim be approved, we will charge the card you provided for the ${englishVars.deductibleName.toLowerCase()} and proceed with sending you a replacement.`}</p>
                                                <ToSpeakWithARepresentative caseNumber={this.state.caseNumber} countryCode={countryCode} />
                                            </section>
                                        }
                                        {this.state.isDocUploaded && !this.state.isUnderReview && !this.state.isValidClaim && !claimOverview?.isClaimApproved &&
                                            <section id="claimReview" className={styles.overviewSection}>
                                                <h1 className={classNames(h2, btmLine)}>{translations?.title || "Claim in Review"}</h1>
                                                <p>
                                                   {isChileApple ? (
                                                        translations?.uploadedButInvalidBold || "We will contact you within 2 business days with details about your claim. "
                                                    ) : (
                                                        <b>{translations?.uploadedButInvalidBold || "We will contact you within 2 business days with details about your claim. "}</b>
                                                    )}
                                                    {translations?.uploadedButInvalidPText || "Your card will not be charged until your claim has been approved."}
                                                </p>
                                                <ToSpeakWithARepresentative caseNumber={this.state.caseNumber} countryCode={countryCode} />
                                            </section>
                                        }
                                        {this.state.isDocUploaded && this.state.isDeductibleUnsuccessful && (this.state.isPendingReview || this.state.isClaimDenied) && !claimOverview?.isClaimApproved &&
                                            <>
                                                <button
                                                    className="btnLink"
                                                    tabIndex="0"
                                                    aria-label={translations.cancelClaimAria || "Opens a modal to cancel your claim"}
                                                    color="secondary"
                                                    onClick={() => this.handleToggleCancelClaimModal("OPEN")}
                                                >
                                                    {translations.cancelClaim || "Cancel Claim"}
                                                </button>
                                                <CancelModal
                                                    claimNumber={this.state.claimNumber}
                                                    caseNumber={this.state.caseNumber}
                                                    isOpen={showCancelModal}
                                                    handleCancel={this.handleCancelClaim}
                                                    onCloseModal={() => this.handleToggleCancelClaimModal("CLOSE")}
                                                    clientId={this.props?.history?.location?.state?.entitlementDetails?.clientId || 'apple'}
                                                    isSubmittedClaim
                                                />
                                            </>
                                        }
                                         <ClaimDetails
                                            ccType={this.state.ccType}
                                            ccNumber={this.state.ccNumber}
                                            claimOverview={this.state.claimOverview}
                                            errorMessage={this.state.errorMessage}
                                            caseNumber={this.state.caseNumber}
                                            address={replacementShipToAddress}
                                            clientId={this.props?.history?.location?.state?.entitlementDetails?.clientId || 'apple'}
                                            countryCode={this.props?.history?.location?.state?.entitlementDetails?.shipToCountryCode}
                                        />
                                    </Col>
                                    <Progress  {...this.state} steps={steps(momentJsLongFormat)} />
                                </Row>
                            </Container>
                        )
                    }}
                </UseTranslationInClassComponent>
            </DocumentTitle>
        );
    }
}

ClaimOverview.contextType = CombinedLangContractContext;