import React, { Fragment } from "react";
import styles from './style.css';
const USAState = (props) => {
  return (
    <Fragment>
          <path d={props.dimensions} fill={props.fill} data-name={props.state} className= "map" onClick={() => props.onClickState(props.stateName)} id={props.state} />
    </Fragment>
  );
}
export default USAState;
