import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
//import '../../css/hero.css';

export class ZipInput extends Component {
    render() {
        return (
            <>
                
                <Input type="text" maxLength={5} placeholder="Zip/Postal" id="zipInput" className="zipCodeField form-control"
                    aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    name={this.props.name} value={this.props.value} onChange={this.props.onChangedValue}
                    />
                    <Label htmlFor="zipInput">Zip/Postal</Label>
                
            </>
        );
    }
}