import React, { Component } from 'react';
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
//import '../../src/css/hero.css';

export default class PlanNumberSearch extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            lastName: '',
            planNumber: '',
            errors: {
                lastName: '', planNumber: ''
            }
        })
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
    }

    componentDidMount() {
        this.props.searchClick(this.checkErrors);

        if (this.props.params != null) {
            this.setState({ lastName: this.props.params.lastName, planNumber: this.props.params.planNumber });
        }
    }

    checkErrors() {
        return new Promise((resolve, reject) => {
            let errors = this.state.errors;
            errors.lastName = this.state.lastName.length === 0 ? 'Last name is required.' : '';
            errors.planNumber = this.state.planNumber.length === 0 ? 'Plan number is required.' : '';

            this.setState({ errors: errors }, () => {
                this.props.errorsCallback(this.state.errors);
                //console.log(this.state.errors);
                resolve();
            });
        });
    }

    handleInputChange(e) {
        const { name, value } = e.target;
        let errors = this.state.errors;

        switch (name) {
            case "lastName": {
                errors.lastName = value.length === 0 ? 'Last name is required.' : ''
                break;
            }
            case "planNumber": {
                errors.planNumber = value.length === 0 ? 'Plan number is required.' : ''
            }
        }

        this.setState({ errors, [name]: value }, () => {
            this.props.errorsCallback(this.state.errors);
        });
    }

    sendToParent = () => {
        this.props.paramsCallback(this.state);
    }

    render() {
        let errors = this.state.errors;
        // send entered values back to parent
        this.sendToParent();

        return (
            <>
                <Row>
                    <Col sm="12">
                        <FormGroup className="has-float-label">
                            <Input id="reach-skip-nav" className="form-control"
                                type="text"
                                tabindex="-1"
                                placeholder="Last Name"
                                value={this.state.lastName}
                                onChange={this.handleInputChange}
                                name="lastName" />
                            <Label htmlFor="reach-skip-nav">Last Name</Label>
                            {errors.lastName.length > 0 &&
                                <div className="error-msg" aria-live="polite">{errors.lastName}</div>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <FormGroup className="has-float-label">
                            <Input className="form-control"
                                style={{ height: '56px' }}
                                id="second" type="text"
                                value={this.state.planNumber}
                                placeholder="Plan number"
                                onChange={this.handleInputChange}
                                name="planNumber" />
                            <Label htmlFor="second">Plan Number</Label>
                            {errors.planNumber.length > 0 &&
                                <div className="error-msg" aria-live="polite">{errors.planNumber}</div>}
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }
}