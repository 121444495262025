import React from 'react';
import styles from './SpinnerButton.module.css'
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

const SpinnerButton = ({
    spinning,               // boolean controlled by parent, probably as part of the submit/continue/clickHandler function
    onClick,                // handler
    text,                   // text to populate button
    type = 'primary',       // supports 'primary' or 'secondary'
    className = '',         // for custom classes or "utility" classes like margin and padding
    ariaLabel = '',
    disabled = false 
}) => {

    // stores global class from passed 'type' string
    const buttonClass = {
        primary: 'btn-primary',
        secondary: 'btn-secondary'
    }

    // Signal to developer if incorrect button type was passed.
    if (!buttonClass[type]) return <div>{'Developer Error :( Invalid Button Type'}</div>

    return ( 
        <button 
            className={classNames(
                'btn',
                buttonClass[type],
                styles.relative,
                className
            )} 
            aria-label={ariaLabel}
            onClick={onClick}
            disabled={disabled}
        >
            {spinning && (
                <span className={styles.loader}>
                    <Spinner 
                        color="light" 
                        animation={spinning && "border"} 
                    />
                </span>
            )}
            <span className={spinning && styles.hideText}>
                {text}
            </span>
        </button>
     );
}
 
export default SpinnerButton;