import React, { Component } from 'react';
import {
    Container, Row
} from 'reactstrap';
import ProgressBar from '../../../components/Vendor/Apple/IntakeLoss/ProgressBar';
import VerifyInfo from '../../../components/Vendor/Apple/IntakeLoss/VerifyInfo';
import ClaimSummary from '../../../components/Vendor/Apple/IntakeLoss/ClaimSummary';
import { callAdobe } from '../../../helpers'
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent'
import { CombinedLangContractContext } from '../../../components/contexts/ProviderLangContractContext';

export default class VerifyInfoContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entitlementDetails: {}
        }
    }
  
    componentDidMount() {
        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: 'vendor/apple/verifyinfo',
            pageName: 'Verify Info',
            country: this.context.languageContext?.country,
            language: this.context.languageContext?.language,
            currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
            caseId: this.props.history?.location?.state?.entitlementDetails?.caseNumber,
            claimStatus: this.props?.history?.location?.state?.entitlementDetails?.contract?.statusModel?.statusName,
        })
    }

    render() {
        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/VerifyInfoContainer'}>
                {international => {
                    const {
                        countryVars: {
                            currency
                        },
                        translations,
                        englishVars: {
                            deductibleName
                        }
                    } = international
                    return (
                        <Container className="contentWrapper">
                            <Row>
                                <section className="formBody col-lg-7 mr-auto">
                                    <ProgressBar step={2} aria-hidden="true" />
                                    <h1>{translations.title || 'Verify Your Shipping Details'}</h1>
                                    <VerifyInfo history={this.props.history} entitlementDetails={this.props.location?.state?.entitlementDetails} />
                                </section>

                                <ClaimSummary step={2} entitlementDetails={this.props.location?.state?.entitlementDetails} />
                                { /*  Need Help Component Goes Here */}
                                {/*<NeedHelp/>*/}
                            </Row>
                        </Container>
                    );
                }
                }
            </UseTranslationInClassComponent>
        );
    }
}
VerifyInfoContainer.contextType = CombinedLangContractContext;