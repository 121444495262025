import React, { Fragment, useState, useCallback, useEffect, useContext, useRef } from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";
import ReCAPTCHA from "react-recaptcha";
import SimpleReactValidator from "simple-react-validator";
import { DropDownInput } from "../../../components/inputs/DropDownInput";
import { isAppleRoute, isETRoute } from "../../../helpers";
import ExpandableTextarea from "../../../components/shared/ExpandableTextarea/ExpandableTextarea";
import PhoneNumberInput from "../../../components/shared/PhoneNumberUtility/PhoneNumberInput";
import useLoadAtTop from "../../../components/_ReactHooks/useLoadAtTop";
import useTranslation from "../../../components/contexts/LanguageTranslation/useTranslation";
import StandardInput from "../../../components/shared/StandardInput/StandardInput";
import { useHistory } from "react-router-dom";
import InternationalReCAPTCHA from "./IntlReCAPTCHA";
import ContactUsCards from "../../../components/shared/ContactUsCards/ContactUsCards";
import { ContractContext } from "../../../components/contexts/ContractContext";
import getClientContent from "../../../components/_BrandingProvider/brandContent";
import SpinnerButton from '../../../components/shared/SpinnerButton/SpinnerButton'
import { LanguageContext } from "../../../components/contexts/LanguageTranslation/LanguageContext";

const TEST_SITE_KEY = "6LeyNfoSAAAAAPb-dogoo24hW1Ah4rn8g6Vb9o_z";

export function ContactUsForm() {
    /**
     *  ==================================
     *  =========== Hooks ================
     *  ==================================
     */
    useLoadAtTop();
    const international = useTranslation('screens/vendor/apple/ContactUsForm')
    const { translations, language } = international
    const history = useHistory()
    const context = useContext(ContractContext);
    const languageContext = useContext(LanguageContext)
    const { clientId } = context.data?.contract || 'et';

    const validator = useRef(new SimpleReactValidator({
        element: (message) => (
            <div className="errorMsg" role="alert">
                {message.charAt(0).toUpperCase() + message.slice(1)}
            </div>
        ),
        messages: {
            required: ":attribute is required.",
            phone: 'Please enter a valid phone number.',
        },
        validators: {
            valid_phone: {
                message: 'Please enter a valid phone number.',
                rule: (val, params, validator) => {
                    return !new RegExp('^([0-9])\\1+$').test(val.match(/\d+/g).join(''))
                }
            }
        },
    }));

    /**
     *  ==================================
     *  =========== State ================
     *  ==================================
     */
    const [selectedOption, setSelectedOption] = useState("");
    const [showDropDownError, setShowDropDownError] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [caseId, setCaseId] = useState("");
    const [planNumber, setPlanNumber] = useState("");
    const [incidentDescription, setIncidentDescription] = useState("");
    const [value, setValue] = useState("");
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [validationFired, setIsValidationFired] = useState(false);

    /**
     *  ======================================
     *  ===== Continue Component Logic =======
     *  ======================================
     */

    const isET = isETRoute(clientId)
    const isApple = isAppleRoute() && !isET;

    const fetchtheAgentPortalHostname = (url) => {
        if (url.includes("localhost")) {
            return "https://localhost:44385";
        } else if (url.includes("dev")) {
            return "https://dev.warrantyinfoservhub.com";
        } else if (url.includes("qa")) {
            return "https://qa.warrantyinfoservhub.com";
        } else {
            return "https://warrantyinfoservhub.com";
        }
    };

    const getIntlPhoneRules = () => {
        const country = languageContext?.country;
        switch (country) {
            case 'US':
                return "required|phone|valid_phone";
            default:
                return "required|min:6"
        }
    }

    const continueNext =
        (e) => {
            e.preventDefault();
            setShowDropDownError(!selectedOption);
            setIsValidationFired(true);
            if (validator.current.allValid()) {
                setIsloading(true);
                if (isApple === true) {
                    if (value) {
                        setShowCaptchaError(false);
                        fetch(
                            "api/communication/GetRecipientsforTheftandLostEmail/".concat(
                                "apple"
                            )
                        )
                            .then((res) => res.json())
                            .then((recipients) => {

                                // sending Contact Us Email notification
                                fetch("api/communication/".concat("apple"), {
                                    method: "post",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        emailModel: {
                                            //Recipients: ['vakavaram@sndirect.com'],

                                            Recipients: recipients,
                                            Cc: [],
                                            Bcc: [],
                                            IsBodyHtml: true,
                                            emailType: 18,
                                        },
                                        serviceModel: {
                                            CustomerName: name,
                                            CustomerContactReason: selectedOption,
                                            CustomerEmail: email,
                                            CustomerPhone: phone.length === 14 ? phone : phone.replace(
                                                /(\d{3})(\d{3})(\d{4})/,
                                                "($1) $2-$3"
                                            ),
                                            CustomerAdditionalDescription: incidentDescription,
                                            CustomerViewContract:
                                                fetchtheAgentPortalHostname(window.location.origin) +
                                                "/?caseId=" +
                                                caseId,
                                            CustomerDispatchIdOrCaseNumber: caseId,
                                            CaseNumber: caseId,
                                            WarrantyObjectTypeId: 2,
                                            CountryCode: languageContext?.country
                                        },
                                    }),
                                }).then((res) => {
                                    const statusCode = res.status;
                                    const url = "/vendor/apple/ContactConfirmation/";

                                    history.push(url, { caseId: caseId });
                                    return res.json();
                                });
                            });
                    } else {
                        setShowCaptchaError(true);
                    }
                }
                else {
                    if (value) {
                        setShowCaptchaError(false);
                        fetch(
                            "api/communication/GetRecipientsforTheftandLostEmail/".concat(
                                "et"
                            )
                        )
                            .then((res) => res.json())
                            .then((recipients) => {

                                // sending Contact Us Email notification
                                fetch("api/communication/".concat("et"), {
                                    method: "post",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        emailModel: {
                                            //Recipients: ['vakavaram@sndirect.com'],

                                            Recipients: recipients,
                                            Cc: [],
                                            Bcc: [],
                                            IsBodyHtml: true,
                                            emailType: 184,
                                        },
                                        serviceModel: {
                                            CustomerName: name,
                                            CustomerContactReason: selectedOption,
                                            CustomerEmail: email,
                                            CustomerPhone: phone.length === 14 ? phone : phone.replace(
                                                /(\d{3})(\d{3})(\d{4})/,
                                                "($1) $2-$3"
                                            ),
                                            ContractNumber: planNumber,
                                            CustomerAdditionalDescription: incidentDescription,
                                            CustomerViewContract:
                                                fetchtheAgentPortalHostname(window.location.origin) +
                                                "/loadcontract/" + planNumber + "/et",
                                            WarrantyObjectTypeId: 2,
                                            CountryCode: "US"

                                        },
                                    }),
                                }).then((res) => {
                                    const statusCode = res.status;
                                    const url = "/vendor/et/ContactConfirmation/";
                                    history.push(url);
                                    return res.json();
                                });
                            });
                    }
                    else {
                        setShowCaptchaError(true);
                        setIsloading(false);
                    }
                }
            } else {
                validator.current.showMessages();
                setIsloading(false);
                // rerender to show messages for the first time
                // you can use the autoForceUpdate option to do this automatically`
                // this.forceUpdate();
            }
        };

    const handleCaptcha = value => {
        if (value) {
            setValue(value);
            setShowCaptchaError(false);
        }
        else setShowCaptchaError(true)
        // if value is null recaptcha expired
        // if (value === null) this.setState({ expired: "true" });
    }

    const handleDescription = e => {
        setIncidentDescription(e.target.value);
    }

    const handlePhoneInput = e => {
        // PhoneNumberInput comp adds "phoneNumberModel" to event.target, 
        // then passes it back to onChange function here.
        const { significant, input } = e.target.phoneNumberModel.number
        setPhone(significant || input);
    }

    const handleStateDropDown = useCallback(
        (e) => {
            setSelectedOption(e);
            setShowDropDownError(false);
        },
        [setSelectedOption, setShowDropDownError]
    );

    const getOptions = () => {
        switch (clientId?.toLowerCase()) {
            case 'et':
                return etOptions;
            default:
                return options;
        }
    }

    const options = [
        {
            data: "fileClaim",
            display: translations.option1 || "File A Claim",
        },
        {
            data: "generalInfo",
            display: translations.option2 || "General Information",
        },
        {
            data: "trackClaim",
            display: translations.option3 || "Track Claim",
        },
        {
            data: "uploadDoc",
            display: translations.option4 || "Upload a Document",
        },
    ];

    const etOptions = [
        {
            data: "claimStatus",
            display: translations.option1 || "Claim Status",
        },
        {
            data: "fileMyClaim",
            display: translations.option2 || "File My Claim",
        },
        {
            data: "generalInformation",
            display: translations.option3 || "General Information",
        },
        {
            data: "uploadDoc",
            display: translations.option4 || "Upload a Document",
        },
    ];

    // block component rendering until translation service call resolves translations
    if (!international.isTranslationResolved) return null

    return (
        <>
            <Col className="mb-2 p-0">
                <FormGroup className={showDropDownError ? "form-error" : ""}>
                    <label htmlFor="helpOptions" className="form-label font-weight-bold">
                        {translations.howCanWeHelp || 'How can we help?'}
                    </label>
                    <span aria-hidden="true" className="required-indicator">
                        *
                    </span>

                    <DropDownInput
                        name="helptype"
                        options={getOptions()}
                        className="form-control"
                        onChangedValue={handleStateDropDown}
                        ariaLabel={translations.dropdownAria || 'Selected option is'}
                        placeholder={translations.dropdownPlaceholder || "Please select an option..."}
                        id="helpOptions"
                        validator={validator.current.message("selectedOption", selectedOption, "required", { messages: { required: translations.errors?.selectedOption || "Please select an option." } })}
                    />
                </FormGroup>

                <StandardInput
                    hasError={validator.current.message("name", name, "required")}
                    ariaLabelAndFieldId="name"
                    labelCustomClass='font-weight-bold'
                    label={translations.fullName || "Name"}
                    fieldName="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    validator={validator.current.message("name", name, "required", { messages: { required: translations.errors?.name || "Name is required." } })}
                />

                <StandardInput
                    hasError={validator.current.message("email", email, "required|email")}
                    ariaLabelAndFieldId="email"
                    labelCustomClass='font-weight-bold'
                    label={translations.email || "Email"}
                    fieldName="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    validator={validator.current.message("email", email, "required|email", { 
                        messages: { 
                            required: translations.errors?.emailRequired || "Email is required.",
                            email: translations.errors?.emailInvalid || "Please enter a valid email address."
                        } 
                    })}
                    htmlInputType='email'
                    maxLength={320}
                />
                <FormGroup className={validator.current.message("contactPhone", phone, getIntlPhoneRules()) ? "form-error" : ""}>
                    <PhoneNumberInput
                        fieldName='contactPhone'
                        label={translations.phone || "Phone"}
                        labelCustomClass="font-weight-bold"
                        value={phone}
                        onChange={handlePhoneInput}
                        validationFired={validationFired}
                        error={validator.current.message("contactPhone", phone, getIntlPhoneRules(), {
                            messages: {
                                required: translations.errors?.phone || "Phone is required.",
                                phone: translations.errors?.phoneInvalid || "Please enter a valid phone number.",
                                valid_phone: translations.errors?.phoneInvalid || "Please enter a valid phone number.",
                                min: translations.errors?.phoneInvalid || "Please enter a valid phone number."
                            },
                        })}
                        htmlInputType='tel'
                    />
                </FormGroup>

                {isApple && !isET && (
                    <StandardInput
                        hasError={validator.current.message("caseId", caseId, "required")}
                        ariaLabelAndFieldId="caseId"
                        labelCustomClass='font-weight-bold'
                        label={translations.dispatchOrCaseId || "Apple Dispatch ID or Case ID"}
                        fieldName="caseId"
                        value={caseId}
                        onChange={e => setCaseId(e.target.value)}
                        validator={validator.current.message("caseId", caseId, "required", { messages: { required: translations.errors?.caseId || "Apple Dispatch ID or Case ID is required." } })}
                        htmlInputType='number'
                    />
                )}

                {isET && (
                    <StandardInput
                        hasError={validator.current.message("planNumber", planNumber, "required")}
                        ariaLabelAndFieldId="planNumber"
                        labelCustomClass='font-weight-bold'
                        label={"Plan Number"}
                        fieldName="planNumber"
                        value={planNumber}
                        onChange={e => setPlanNumber(e.target.value)}
                        validator={validator.current.message("planNumber", planNumber, "required", { messages: { required: translations.errors?.planNumber || "Plan Number is required." } })}
                        htmlInputType='number'
                    />
                )}

                <ExpandableTextarea
                    hasError={validator.current.message("incidentDescription", incidentDescription, "required")}
                    label={translations.description || "Please provide a detailed description of your question."}
                    placeholder={translations.descriptionPlaceholder}
                    labelCustomClass="font-weight-bold"
                    validator={validator.current.message("incidentDescription", incidentDescription, "required", { messages: { required: translations.errors?.description || "Description is required." } })}
                    value={incidentDescription}
                    maxLength={500}
                    onChange={handleDescription}
                    ariaLabelAndFieldId="Comments"
                />
            </Col>
            <Col className="p-0">

                <InternationalReCAPTCHA handleCaptcha={handleCaptcha} />

                {showCaptchaError && (
                    <div className="errorMsg">{translations.errors?.captchaError || "Please check above option."}</div>
                )}

                <button
                    className="btn btn-primary mb-5"
                    style={{ marginTop: "3.5rem" }}
                    onClick={continueNext}
                    aria-label={translations.submitAria || "Click to submit your contact request for review."}
                >
                    {translations.submit || 'Submit'}
                </button>
            </Col>

            {isET && (
                <ContactUsCards
                    email={getClientContent(clientId).variableText.customerService.email}
                    emailDescription={'Send Us an Email'}
                    phone={getClientContent(clientId).variableText.customerService.phone}
                    phoneDescription={'Available 6 Days a Week'}
                />
            )}
        </>
    );
}
