import React, { useEffect, useState, useContext } from 'react';
import { Container, Col, Card, CardBody } from 'reactstrap';
import styles from './ChatEnd.module.css';
import BrandedIcon from '../../_BrandingProvider/BrandedIcon';
import { ContractContext } from '../../contexts/ContractContext';
import { isHWAChatEndRoute } from '../../../helpers';

const ChatEnd = () => {
    const [logoRedirectUri, setLogoRedirectUri] = useState('');
    const context = useContext(ContractContext)
    const [clientId, setClientId] = useState(context?.data?.contract?.clientId || 'aig');
    useEffect(() => {
        const hwaChat = async () => {
            const result = await isHWAChatEndRoute();
            console.log(result);
            if (result == true) {
                setClientId('hwa');
            }
        };
        hwaChat();
    }, []); 

    useEffect(() => {
        async function fetchUri() {
            const res = await fetch('vendor/GetHwaRedirectUri');
            const data = await res.json();
            setLogoRedirectUri(data.uri + 'service-agreement')
        }

        fetchUri();
    }, []);

    return (
        <Container>
            <Card className={styles.chatEndContainer}>
                <CardBody className="text-center pl-0 pr-0">
                    <Col className={styles.flexContainer}>
                        <BrandedIcon client={clientId} iconType={'chatcomplete'} />
                        <h1 className="h3 mb-3">Your chat session has ended</h1>
                        <p className="mb-5">Thank you for contacting us.</p>
                        <a href={logoRedirectUri} className="btn btn-primary">Login</a>
                    </Col>
                </CardBody>
            </Card>
        </Container>
    )
}

export default ChatEnd;
