export const getContractTerms = async (freshData = false, clientId = "") => {
    return new Promise((resolve, reject) => {
        let newData = [];
        let ssItem = getStorageItem("contractTerms");

        if (freshData || ssItem == null) {
            fetch(`api/contract/getallcontractterms/${clientId}`)
                .then(res => res.json())
                .then(data => {
                    newData = JSON.stringify(data);
                    sessionStorage.setItem("contractTerms", newData);

                    resolve(data);

                })
                .catch((e) => {
                    console.log("Error fetching terms");
                    reject(null);
                });
        }
        else {
            resolve(ssItem);
        }
    })
}

export const getClaimStatus = async (freshData = false, clientId = "") => {
    return new Promise((resolve, reject) => {

    });
}

export const getStorageItem = (key) => {
    let ssItem = sessionStorage.getItem(key);

    if (ssItem) {
        ssItem = JSON.parse(ssItem);
    }

    return ssItem;
}

export const cancelClaim = (clientId, claimId) => {
    fetch(`api/claim/cancelclaim/${clientId}/${claimId}/false`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => {
        if (res.ok) return true;

        return false;

        // this will be silent to user, if they're cancelling claim and issue with that
        // we don't want to alert as they won't care. will handle. These should pretty much
        // always succeed 
    });
}

export const updateClaimStatus = (clientId, claimId, claimStatusId) => {
    return new Promise((resolve, reject) => {
        const settings = {
            body: JSON.stringify({}),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        fetch(`/api/claim/UpdateClaimStatus/${clientId}/${claimId}/${claimStatusId}`, settings)
            .then(res => {
                if (res.ok) {
                    resolve(true);
                }
                else
                {
                    console.log("Error updating claim status.")
                    reject(null);
                }
            })
            .catch(() => {
                console.log("Error updating claim status.")
                reject(null);
            })
    })
}

export const saveLanguage = (contractId, langCode, clientId) => {
    fetch(`api/claim/SavePreferredLanguage/${contractId}/${langCode}/${clientId}`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => {
        if (res.ok) return true;

        return false;
    });
}

export const getSavedLanguage = (contractId, clientId) => {
    fetch(`api/claim/GetPreferredLanguage/${contractId}/${clientId}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => {
        if (res.ok) return true;

        return false;
    });
}