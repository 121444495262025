import React, { createContext, useContext } from "react";
import { ContractContext } from "./ContractContext";
import { LanguageContext } from "./LanguageTranslation/LanguageContext";

export const CombinedLangContractContext = createContext(null);

export const CombinedLangContractProvider = ({ children }) => {
    const contractContext = useContext(ContractContext);
    const languageContext = useContext(LanguageContext);
    return (
        <CombinedLangContractContext.Provider value={{ contractContext, languageContext }}>
            {children}
        </CombinedLangContractContext.Provider>
    );
}