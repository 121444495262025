import React, { Component } from 'react';
import { Input, Label, Popover, PopoverBody, Container } from 'reactstrap';
import styles from "./PlanInfo.module.css";
import classNames from 'classnames';
import { callAdobe } from '../../../helpers'
import NY from "../../../assets/Documents/Apple_T&Cs_BBY_NY_01.pdf"
import NotNY from "../../../assets/Documents/Apple_T&Cs_BBY_NonNY_01.pdf"
import AppleNonNYTCs from '../../../assets/Documents/Apple_T&Cs_NonNY_01.pdf';
import AppleNYTCs from '../../../assets/Documents/Apple_T&Cs_NY_01.pdf';
import CloseIcon from '../../../assets/images/close.svg';

let radioGroup = styles.radioGroup;
let radioGroupStates = styles.radioGroupStates
let btnLinkCustom = styles.btnLinkCustom;
let retailerSelection = styles.retailerSelection;

export class ProgramSummary extends Component {

    state = {
        planSelected: false,
        showPlanOptions: false,
        showBBLocation: false,
        showPopover: false,
        redirectUrl: ""


    }

    onAppleSlection = () => {
        this.setState({ showPlanOptions: true, showBBLocation: false, planSelected: false });
        callAdobe({
            retailer: 'Apple'
        })
    }

    onBBSlection = () => {
        this.setState({ showBBLocation: true, showPlanOptions: false, planSelected: false, });
        callAdobe({
            retailer: 'Best Buy'
        })
    }
    handleOnChangeForAppleOptions = (type) => {
        if (type === 'singlepay') {
            this.setState({
                planSelected: true,
                redirectUrl: AppleNonNYTCs
            })
        }
        else if (type === 'monthlypay') {
            this.setState({
                planSelected: true,
                redirectUrl: AppleNYTCs
            })
        }
        else if (type === 'onlyNewYork') {
            this.setState({
                planSelected: true,
                redirectUrl: NY
            })
        }
        else if (type === 'allStates') {
            this.setState({
                planSelected: true,
                redirectUrl: NotNY
            })
        }
    }

    //fetchRedirectURL = () => {
    // var test = require("../../../assets/Documents/AppleCarePlus-with-Theft-and-Loss-eConsents.pdf");
    // return test;
    //}

    componentDidMount() {
        // FOR ADOBE ANALYTICS
        callAdobe({
            pagePath: '/vendor/apple/programsummarydisclosures',
            pageName: 'Program Summary Disclosures'
        })
    }

    render() {
        return (
            <div>
                <div className={styles.pageHeader}>
                    <Container className={styles.pageHeaderContent}>
                        <h1>AppleCare+ with Theft and Loss Program Summary</h1>
                        <p className="mb-0">Choose from the selection below to view the correct document.</p>
                    </Container>
                </div>
                <div className={classNames("container", retailerSelection)}>
                    <Label className="cardTitle mb-0" for="radioButtonGroup">Which retailer did you purchase your plan from?</Label>
                    <div className={classNames("radioButton", "twoColWrapper", radioGroup)}>
                        <Input type="radio" value="Apple" id="apple" name="retailerSelect" onChange={this.onAppleSlection} />
                        <Label for="apple" value="Apple">Apple</Label>
                        <Input type="radio" value="BestBuy" id="bestBuy" name="retailerSelect" onChange={this.onBBSlection} />
                        <Label for="bestBuy" value="Best Buy">Best Buy</Label>
                    </div>
                    {this.state.showPlanOptions &&
                        <div className={styles.paymentPlanSelect}>
                            <p className="cardTitle mb-0">
                                Choose the appropriate version (
                                <button
                                    className={classNames("btnLink", btnLinkCustom)}
                                    id="learnMore"
                                    color="link"
                                    title="Learn more"
                                    onClick={() => this.setState({ showPopover: !this.state.showPopover })}
                                >
                                    learn more
                                </button>
                                <Popover
                                    className={styles.popOverLM}
                                    placement="bottom"
                                    isOpen={this.state.showPopover}
                                    target="learnMore"
                                    toggle={() => this.setState({ showPopover: !this.state.showPopover })}
                                >
                                    <PopoverBody>
                                        <div className={styles.popOverLMHead}>
                                            <p>Select the state based on where the device was purchased:</p>
                                            <button className={styles.closeBtn} onClick={() => this.setState({ showPopover: !this.state.showPopover })}>
                                                <img alt="close" src={CloseIcon} />
                                            </button>
                                        </div>
                                        <ul>
                                            <li>
                                                <b>Apple or Best Buy store </b>- state of store
                                            </li>
                                            <li>
                                                <b>Online </b> - state associated with the billing address
                                                tied to the method of payment used to purchase coverage
                                            </li>
                                            <li>
                                                <b>Online purchase picked up at Best Buy store </b>- state
                                                of store
                                            </li>
                                        </ul>
                                    </PopoverBody>
                                </Popover>).
                            </p>
                            <div className={classNames("radioButton", "twoColWrapper", radioGroupStates)}>
                                <Input type="radio" value="Single Pay" id="singlePay" name="planSelect" onChange={this.handleOnChangeForAppleOptions.bind(this, "singlepay")} />
                                <Label for="singlePay" value="Single Pay"><p className="mb-0">All States</p>(except NY)</Label>
                                <Input type="radio" value="Pay Monthly" id="payMonthly" name="planSelect" onChange={this.handleOnChangeForAppleOptions.bind(this, "monthlypay")} />
                                <Label for="payMonthly" value="Pay Monthly"><p className="mb-0">New York</p> (only)</Label>
                            </div>
                        </div>
                    }
                    {this.state.showBBLocation &&
                        <div className={styles.paymentPlanSelect}>
                            <p className="cardTitle mb-0">
                                Choose the appropriate version (
                                <button
                                    className={classNames("btnLink", btnLinkCustom)}
                                    id="learnMore"
                                    color="link"
                                    title="Learn more"
                                    onClick={() => this.setState({ showPopover: !this.state.showPopover })}
                                >
                                    learn more
                                </button>
                                <Popover
                                    className={styles.popOverLM}
                                    placement="bottom"
                                    isOpen={this.state.showPopover}
                                    target="learnMore"
                                    toggle={() => this.setState({ showPopover: !this.state.showPopover })}
                                >
                                    <PopoverBody>
                                        <div className={styles.popOverLMHead}>
                                            <p>Select the state based on where the device was purchased:</p>
                                            <button className={styles.closeBtn} onClick={() => this.setState({ showPopover: !this.state.showPopover })}>
                                                <img alt="close" src={CloseIcon} />
                                            </button>
                                        </div>
                                        <ul>
                                            <li>
                                                <b>Apple or Best Buy store </b>- state of store
                                            </li>
                                            <li>
                                                <b>Online </b> - state associated with the billing address
                                                tied to the method of payment used to purchase coverage
                                            </li>
                                            <li>
                                                <b>Online purchase picked up at Best Buy store </b>- state
                                                of store
                                            </li>
                                        </ul>
                                    </PopoverBody>
                                </Popover>).
                            </p>
                            <div className={classNames("radioButton", "twoColWrapper", radioGroupStates)}>
                                <Input type="radio" value="Single Pay" id="allStates" name="selectState" onChange={this.handleOnChangeForAppleOptions.bind(this, "allStates")} />
                                <Label for="allStates" value="All States"><p className="mb-0">All States</p>(except NY)</Label>
                                <Input type="radio" value="Pay Monthly" id="onlyNewYork" name="selectState" onChange={this.handleOnChangeForAppleOptions.bind(this, "onlyNewYork")} />
                                <Label for="onlyNewYork" value="New York"><p className="mb-0">New York</p> (only)</Label>
                            </div>
                        </div>
                    }
                    {this.state.planSelected ? (
                        < a
                            href={this.state.redirectUrl}
                            className="btn btn-primary"
                            disabled={!this.state.planSelected}
                            target="_blank"
                            rel="noreferrer"
                        >
                            View Summary
                        </a>
                    ) : (
                        <button
                            className="btn btn-primary"
                            disabled={true}
                        >
                            View Summary
                        </button>
                    )}
                </div>
                <p class="discl mt-5"><a href="https://www.apple.com/legal/sales-support/applecare/applecareplus/us/" target="blank">Click here</a> to view the AppleCare+ portion of your AppleCare+ with Theft and Loss terms and conditions.</p>
            </div>
        )
    }
}