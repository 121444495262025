import React from 'react';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import {
    Col, Row, Label, Input
} from 'reactstrap';

let cardTitle = styles.cardTitle;

const Symptoms = ({
    symptomSelected, 
    symptoms, 
    showError
}) => {

    const sortedSymptoms = symptoms.sort((symptomA, symptomB) => {
        const a = symptomA.symptom.toLowerCase();
        const b = symptomB.symptom.toLowerCase();
        if (a < b) {
            return -1
        } else if (a > b) {
            return 1
        } else return 0
    })

    return (
        <Row id='symptom'>
            <Col lg='7' style={{margin: '0 auto'}}>
                <Label className={classNames("mb-0", cardTitle)} aria-label="Select a symptom from the list" for="symptomGroup">
                    What issue are you experiencing?
                </Label>
                <p className="helpText">
                    Select the issue closest to what you're experiencing.
                </p>
                <Row className="stepsFormBlock">
                    <Col>
                        <Row role="radiogroup" className="symptomGroup list">
                            {sortedSymptoms.map((e, i) => 
                                <Col md="6" className="radioButton symptoms" key={e.symptom}>
                                    <Input 
                                        type="radio" 
                                        name="symptomList" 
                                        id={`symptom${i}`} 
                                        value={e.symptom} 
                                        onChange={(eve) => symptomSelected(e)} 
                                    />
                                    <Label 
                                        className="radio-controls-label" 
                                        for={`symptom${i}`}  
                                    >
                                        {e.symptom}
                                    </Label>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                {showError &&
                    <p className='error-msg'>
                        Select an issue to continue.
                    </p>
                }
            </Col>
        </Row>
    ) 
}

export default Symptoms;