import React from "react";
import styles from "./ExpandableTextarea.module.css";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import { Label } from "reactstrap";

const ExpandableTextAreaWithLabel = ({
  placeholder,
  value,
  maxLength = "250",
  onChange,
  label = "Additional Notes", // optional; default is "Additional Notes",
	ariaForAndId = 'notes',
	formItemName,
  ...props
}) => {
  return (
    <div className={styles.expandableTextarea}>
      <Label className="form-label font-weight-bold" htmlFor={ariaForAndId}>
        {label}
      </Label>
      <TextareaAutosize
        onChange={onChange}
        value={value}
        placeholder={placeholder || "Enter Description"}
        className={styles.formControlTextarea}
        maxLength={maxLength}
				name={formItemName} 
        id={ariaForAndId}
      />
      <div className={styles.charCount}>
        {value.length}/{maxLength}
      </div>
      {value.length > 0 && (
        <div
          type="button"
          onClick={() => onChange({ target: { value: "" } })}
          className={styles.emptyTextArea}
        >
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default ExpandableTextAreaWithLabel;
