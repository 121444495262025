import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ReactComponent as AppleLogo } from '../../../assets/images/Vendor/Apple/Apple-Logo.svg';
import styles from './ResumeClaimSubmission.module.css';
import useTranslation from "../../../components/contexts/LanguageTranslation/useTranslation";
import { useRouteMatch } from 'react-router-dom';
import { ContractContext } from '../../../components/contexts/ContractContext';
import { LanguageContext } from '../../../components/contexts/LanguageTranslation/LanguageContext';
import SuspendedLanding from './SuspendedLanding';
import PageLoading from '../../../components/shared/PageLoading/PageLoading';


const ResumeClaimSubmission = (props) => {

    const international = useTranslation('screens/Vendor/Apple/ResumeClaimSubmission')
    const { englishVars } = international;
    const urlPath = useRouteMatch('/vendor/apple/resume/:token');
    const [paymentSuspendedValue, setPaymentSuspendedValue] = useState(false);
    let [token] = useState(urlPath?.params?.token);
    const languageContext = useContext(LanguageContext);
    const contractContext = useContext(ContractContext);
    const isChileApple = contractContext?.data?.isChileApple;

    const verifyToken = () => {
        fetch(`vendor/apple/verifyToken/${token}/apple`)
            .then(res => {
                if (res.status === 200) {
                    redirectToTargetedPage(res);
                } else {
                    props.history.push("/vendor/apple")
                }
            })
    }

    const redirectToTargetedPage = (res) => {

        res.json().then(data => {

            if (data != null && data?.claim != null && data?.claim?.claimId != null) {
                let { claim, paymentSuspended, countryCode, contract, shipToCountryCode } = data;
                const formattedCountryCode = countryCode.toUpperCase() ?? 'US'
                sessionStorage.setItem('country', formattedCountryCode);
                languageContext.updateCountry(formattedCountryCode);
                contractContext.updateContract(contract);

                let entitleObject = {
                    caseNumber: claim.caseNumber,
                    clientId: "apple",
                    claimId: claim.claimId,
                    claimNumber: claim.claimNumber,
                    incidentDescription: claim.incidentDescription,
                    incidentDate: claim.incidentDate === undefined ? claim.lossDate : claim.incidentDate,
                    caseNumber: claim.clientClaimNumber,
                    countryCode: countryCode ?? "us",
                    contract: contract,
                    party: contract.party,
                    shipToCountryCode: shipToCountryCode
                }

                if (paymentSuspended === true && countryCode.toLowerCase() === "us") {
                    setPaymentSuspendedValue(true);
                }

                else if (claim.claimStatusId == 6 || claim.claimStatusId == 8) {

                    fetch(`api/contract/GetByContractAssetId/${claim.contractAssetId}/` + "apple", {
                        method: 'Get',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(res => {
                        if (res.ok) {
                            res.json().then(contractData => {

                                let party = contractData.party;
                                let riskProfile = contractData.riskProfile.riskProfileConfigurationModel;
                                let deductible = riskProfile.find(item => { return item.pricingAttributeId === 19 }).value;
                                let featureIndex = claim.featureClaimModel.length - 1;
                                let contractAssetCoverageId = typeof claim.featureClaimModel[featureIndex] === "undefined" ? null : claim.featureClaimModel[featureIndex].contractAssetCoverageId;

                                let entitle = {
                                    caseNumber: claim.caseNumber,
                                    clientId: "apple",
                                    contract: contractData,
                                    contractAsset: contractData.contractAssetModel[0],
                                    claimId: claim.claimId,
                                    claimNumber: claim.claimNumber,
                                    party: party,
                                    deductible: deductible,
                                    incidentDescription: claim.incidentDescription,
                                    incidentDate: claim.incidentDate === undefined ? claim.lossDate : claim.incidentDate,
                                    claimSetupDate: claim.claimSetupDate,
                                    coverageId: contractAssetCoverageId,
                                    caseNumber: claim.clientClaimNumber,
                                    countryCode: countryCode ?? "us",
                                    shipToCountryCode: shipToCountryCode
                                }
                                if (claim?.claimConfigurationModel?.find(cc => cc.claimAttributeId == 5)?.value == 1) {

                                    // Redirect to Entitlement
                                    props.history.push('/vendor/apple/entitlement', {
                                        entitlementDetails: {
                                            clientId: "apple",
                                            contract: contractData,
                                            contractAsset: contractData.contractAssetModel[0],
                                            party: party,
                                            deductible: deductible,
                                            claimId: claim.claimId,
                                            claimNumber: claim.claimNumber,
                                            caseNumber: claim.clientClaimNumber,
                                            coverageId: contractAssetCoverageId,
                                            countryCode: countryCode ?? "us",
                                            shipToCountryCode: shipToCountryCode
                                        }, claim: claim
                                    });
                                }
                                else if (claim?.claimConfigurationModel?.find(cc => cc.claimAttributeId == 5)?.value == 2) {

                                    // Redirect to verifyinfo
                                    props.history.push('/vendor/apple/verifyinfo', {
                                        entitlementDetails: entitle,
                                        claim: claim
                                    });
                                }
                                else if (claim.claimConfigurationModel.find(cc => cc.claimAttributeId == 5).value == 3) {

                                    let mailingAddress = party.partyAddressModel.find(p => p.addressTypeId == 2);
                                    let shippingAddress = party.partyAddressModel.find(p => p.addressTypeId == 3); // 3- shipping Address Type

                                    shippingAddress = shippingAddress ?? mailingAddress;

                                    // Redirect to payment
                                    props.history.push('/vendor/apple/payment', {
                                        entitlementDetails: {
                                            clientId: "apple",
                                            caseNumber: claim.clientClaimNumber,
                                            partyId: contractData.party.partyId,
                                            partyStatusId: party.partyStatusId,
                                            firstName: party.firstName,
                                            lastName: party.lastName,
                                            partyName: party.firstName + " " + party.lastName,
                                            partyAddressModel: party.partyAddressModel,
                                            partyPhoneModel: party.partyPhoneModel,
                                            partyEmailModel: party.partyEmailModel,
                                            contract: contractData,
                                            contractAsset: contractData.contractAssetModel[0],
                                            claimId: claim.claimId,
                                            claimNumber: claim.claimNumber,
                                            party: party,
                                            deductible: deductible,
                                            incidentDescription: claim.incidentDescription,
                                            incidentDate: claim.incidentDate === undefined ? claim.lossDate : claim.incidentDate,
                                            claimSetupDate: claim.claimSetupDate,
                                            coverageId: contractAssetCoverageId,
                                            mailingAddress: mailingAddress.address1,
                                            mailingAddress2: mailingAddress.address2,
                                            city: mailingAddress.city,
                                            state: mailingAddress.state,
                                            stateId: mailingAddress.stateId,
                                            zip: mailingAddress.zip,
                                            contactPhone: party.partyPhoneModel.find(pp => pp.phoneType = 2).number,
                                            email: party.partyEmailModel[0].address,
                                            shippingAddress: shippingAddress,
                                            countryCode: countryCode ?? "us",
                                            shipToCountryCode: shipToCountryCode
                                        }, claim: claim
                                    });
                                }
                                else if (claim.claimConfigurationModel.find(cc => cc.claimAttributeId == 5).value == 4) {

                                    let mailingAddress = party.partyAddressModel.find(p => p.addressTypeId == 2);
                                    let shippingAddress = party.partyAddressModel.find(p => p.addressTypeId == 3); // 3- shipping Address Type

                                    shippingAddress = shippingAddress ?? mailingAddress;

                                    // Redirect to upload docs
                                    props.history.push('/vendor/apple/uploaddocs', {
                                        entitlementDetails: {
                                            clientId: "apple",
                                            caseNumber: claim.clientClaimNumber,
                                            partyId: contractData.party.partyId,
                                            partyStatusId: party.partyStatusId,
                                            firstName: party.firstName,
                                            lastName: party.lastName,
                                            partyName: party.firstName + " " + party.lastName,
                                            partyAddressModel: party.partyAddressModel,
                                            partyPhoneModel: party.partyPhoneModel,
                                            partyEmailModel: party.partyEmailModel,
                                            contract: contractData,
                                            contractAsset: contractData.ccontractAssetModel[0],
                                            claimId: claim.claimId,
                                            claimNumber: claim.claimNumber,
                                            party: party,
                                            deductible: deductible,
                                            incidentDescription: claim.incidentDescription,
                                            incidentDate: claim.incidentDate === undefined ? claim.lossDate : claim.incidentDate,
                                            coverageId: contractAssetCoverageId,
                                            mailingAddress: mailingAddress.address1,
                                            mailingAddress2: mailingAddress.address2,
                                            city: mailingAddress.city,
                                            state: mailingAddress.state,
                                            zip: mailingAddress.zip,
                                            contactPhone: party.partyPhoneModel.find(partyPhone => partyPhone.phoneType = 2).number,
                                            email: party.partyEmailModel[0].address,
                                            shippingAddress: shippingAddress,
                                            countryCode: countryCode ?? "us",
                                            shipToCountryCode: shipToCountryCode
                                        }, claim: claim
                                    });
                                }
                                else {
                                    if (!claim.hasDocuments) {

                                        // Redirect to upload documents SEU-712
                                        props.history.push("/vendor/apple/claimoverview/", {
                                            isDocUploaded: false,
                                            isUnderReview: false,
                                            isValidClaim: false,
                                            claim: claim,
                                            claimId: claim.claimId,
                                            entitlementDetails: entitle,
                                            clientId: "apple",
                                            caseNumber: claim.clientClaimNumber,
                                            claimNumber: claim.claimNumber,
                                            isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                                            isPendingReview: data.isPendingReview,
                                            isClaimDenied: data.isClaimDenied
                                        });
                                    }
                                    else if (data.isPendingClaim) {

                                        // Redirect to scripting page SEU-712
                                        props.history.push("/vendor/apple/claimoverview/", {
                                            isDocUploaded: true,
                                            isUnderReview: true,
                                            isValidClaim: false,
                                            claim: claim,
                                            claimId: claim.claimId,
                                            entitlementDetails: entitle,
                                            clientId: "apple",
                                            caseNumber: claim.clientClaimNumber,
                                            claimNumber: claim.claimNumber,
                                            isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                                            isPendingReview: data.isPendingReview,
                                            isClaimDenied: data.isClaimDenied
                                        });
                                    }
                                    else if (!data.isValidClaim) {

                                        // Redirect to Failed B. Rules page SEU-713
                                        props.history.push("/vendor/apple/claimoverview/", {
                                            isDocUploaded: true,
                                            isUnderReview: false,
                                            isValidClaim: false,
                                            claim: claim,
                                            claimId: claim.claimId,
                                            entitlementDetails: entitle,
                                            clientId: "apple",
                                            caseNumber: claim.clientClaimNumber,
                                            claimNumber: claim.claimNumber,
                                            isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                                            isPendingReview: data.isPendingReview,
                                            isClaimDenied: data.isClaimDenied
                                        });
                                    }
                                    else {

                                        // Redirect to Claims Overview SEU-613
                                        props.history.push("/vendor/apple/claimoverview/", {
                                            isDocUploaded: true,
                                            isUnderReview: false,
                                            isValidClaim: true,
                                            claim: claim,
                                            claimId: claim.claimId,
                                            entitlementDetails: entitle,
                                            clientId: "apple",
                                            caseNumber: claim.clientClaimNumber,
                                            claimNumber: claim.claimNumber,
                                            isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                                            isPendingReview: data.isPendingReview,
                                            isClaimDenied: data.isClaimDenied
                                        });
                                    }
                                }
                            })
                        }
                    })
                }
                else if (data.additionalReview) {

                    // Redirect to upload documents SEU-1443 Additional documents required
                    props.history.push("/vendor/apple/claimoverview/", {
                        additionalReview: true,
                        isDocUploaded: false,
                        isUnderReview: false,
                        isValidClaim: false,
                        claim: claim,
                        claimId: claim.claimId,
                        entitlementDetails: entitleObject,
                        clientId: "apple",
                        caseNumber: claim.clientClaimNumber,
                        claimNumber: claim.claimNumber,
                        ccNumber: data.creditCardNumber,
                        ccType: data.creditCardType,
                        isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                        isPendingReview: data.isPendingReview,
                        isClaimDenied: data.isClaimDenied
                    });
                }
                else if (claim.claimStatusId == 3) {

                    // partial claim
                    props.history.push("/vendor/apple");
                }
                else if (!(data.hasDocuments && !data.documentResubmissionRequested)) {

                    // Redirect to upload documents SEU-712
                    props.history.push("/vendor/apple/claimoverview/", {
                        additionalReview: false,
                        isDocUploaded: false,
                        isUnderReview: false,
                        isValidClaim: false,
                        claim: claim,
                        claimId: claim.claimId,
                        entitlementDetails: entitleObject,
                        clientId: "apple",
                        caseNumber: claim.clientClaimNumber,
                        claimNumber: claim.claimNumber,
                        ccNumber: data.creditCardNumber,
                        ccType: data.creditCardType,
                        isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                        isPendingReview: data.isPendingReview,
                        isClaimDenied: data.isClaimDenied
                    });
                }
                else if (data.isPendingClaim) {

                    // Redirect to scripting page SEU-712
                    props.history.push("/vendor/apple/claimoverview/", {
                        additionalReview: false,
                        isDocUploaded: true,
                        isUnderReview: true,
                        isValidClaim: false,
                        claim: claim,
                        claimId: claim.claimId,
                        entitlementDetails: entitleObject,
                        clientId: "apple",
                        caseNumber: claim.clientClaimNumber,
                        claimNumber: claim.claimNumber,
                        ccNumber: data.creditCardNumber,
                        ccType: data.creditCardType,
                        isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                        isPendingReview: data.isPendingReview,
                        isClaimDenied: data.isClaimDenied
                    });
                }
                else if (!data.isValidClaim) {

                    // Redirect to Failed B. Rules page SEU-713
                    props.history.push("/vendor/apple/claimoverview/", {
                        additionalReview: false,
                        isDocUploaded: true,
                        isUnderReview: false,
                        isValidClaim: false,
                        claim: claim,
                        claimId: claim.claimId,
                        entitlementDetails: entitleObject,
                        clientId: "apple",
                        caseNumber: claim.clientClaimNumber,
                        claimNumber: claim.claimNumber,
                        isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                        isPendingReview: data.isPendingReview,
                        isClaimDenied: data.isClaimDenied
                    });
                }
                else {

                    // Redirect to Claims Overview SEU-613
                    props.history.push("/vendor/apple/claimoverview/", {
                        additionalReview: false,
                        isDocUploaded: true,
                        isUnderReview: false,
                        isValidClaim: true,
                        claim: claim,
                        claimId: claim.claimId,
                        entitlementDetails: entitleObject,
                        clientId: "apple",
                        caseNumber: claim.clientClaimNumber,
                        claimNumber: claim.claimNumber,
                        isDeductibleUnsuccessful: data.isDeductibleUnsuccessful,
                        isPendingReview: data.isPendingReview,
                        isClaimDenied: data.isClaimDenied
                    });
                }

            } else {
                props.history.push("/vendor/apple");
            }
        })
    }

    useEffect(() => {
        verifyToken()
    }, []);

    return (
        <Container className="contentWrapper">
            <Row>
                <Col className="text-center">
                    <div className={styles.appleLogo}>
                        <AppleLogo />
                    </div>
                    <h1 className={`${styles.contentHeader} mt-3 mb-5 ${isChileApple ? styles.resumeClaimSB : ""}`}>
                        {'Welcome to the AppleCare+'}
                        <br className="show-md-up" aria-hidden="true" />
                        {`with Theft and Loss Service ${englishVars.center}`}
                    </h1>
                    {paymentSuspendedValue
                        ? <SuspendedLanding />
                        : (
                            <>
                                <h5 className="mb-2">Loading your claim, please wait...</h5>
                                <p className="mb-0">Do not refresh or close your browser.</p>
                                <PageLoading
                                    text=""
                                    height="100"
                                    color="secondary"
                                />
                            </>
                        )
                    }
                </Col>
            </Row>
        </Container>
    )

}
export default ResumeClaimSubmission;