import React from 'react';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import {
    Container, Col, Row, Card, CardHeader, CardBody, Label, Input, Form, CardTitle, FormGroup
} from 'reactstrap';

let cardTitle = styles.cardTitle;

function PriorityService({ followUpChosen, showError }) {
    return <div id="priorityService">
        <form>
            <div className="form-group">
                <h2 className={styles.cardTitle} for="followUpGroup">Does your product need priority service?</h2>
                <Row className="stepsFormBlock">
                    <Col md="5" lg="4">
                        <Row role="radiogroup" className="symptomGroup">
                            <Col md="6" className="radioButton">
                                <Input type="radio" name="yesNoGroup" id="Yes" value="Yes" onChange={e => followUpChosen(e.target.value)} />
                                <Label className="radio-controls-label" for="Yes">Yes</Label>
                            </Col>
                            <Col md="6" className="radioButton">
                                <Input type="radio" name="yesNoGroup" id="No" value="No" onChange={e => followUpChosen(e.target.value)} />
                                <Label className="radio-controls-label" for="No">No</Label>
                            </Col>                           
                        </Row>
                    </Col>                  
                </Row>
                {showError &&
                    <p text-align="center" className="error-msgPS" >
                        Please select an option to continue.
                                    </p>
                }
            </div>
        </form>
    </div>

}

export default PriorityService;