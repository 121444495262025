import React from 'react';
import { ReactComponent as RangeIcon } from '../../../assets/images/range.svg';
import { ReactComponent as ElectricalIcon } from '../../../assets/images/electrical.svg';
import { ReactComponent as HvacIcon } from '../../../assets/images/hvac.svg';
import { ReactComponent as PlumbingIcon } from '../../../assets/images/plumbing.svg';
import { ReactComponent as PoolIcon } from '../../../assets/images/pool.svg';
import { ReactComponent as HomeIcon } from '../../../assets/images/home.svg';

const AssetListIcon = ({categoryName, className}) => {
    return (
        <>
            {categoryName.toLowerCase() === "appliances" && <RangeIcon className={className} />}
            {categoryName.toLowerCase() === "plumbing" && <PlumbingIcon className={className} />}
            {categoryName.toLowerCase() === "electric" && <ElectricalIcon className={className} />}
            {categoryName.toLowerCase() === "heating & ac" && <HvacIcon className={className} />}
            {categoryName.toLowerCase() === "pool & spa" && <PoolIcon className={className} />}
            {categoryName.toLowerCase() === "indoor/outdoor" && <HomeIcon className={className} />}
        </>
    );
}

export default AssetListIcon;
