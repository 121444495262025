import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { phoneFormat } from '../shared/util';
import styles from './PlanOverview.module.css';

const PlanInfo = ({party, contract}) => {
    const serviceAddress = party.partyAddressModel.filter(a => (
        a.addressTypeId === 2)[0]
    )
    console.log('=== serviceAddress ===', serviceAddress)
    const { address1, address2, city, zip } = serviceAddress;
    const serviceState = serviceAddress.state;          // NAMED DIFFERENTLY TO PREVENT CONFUSION BETWEEN REACT "STATE" AND SERVICER STATE.
    
    const { description } = contract.riskProfile;

    const breakFixCoverage = contract.contractAssetModel[0].contractAssetCoverageModel[0];
    const startDate = breakFixCoverage.startDateString

    console.log('=== address ===', {address2, address1, city, serviceState, zip})
    const showAddress = (!!address1 || !!address2 || !!city || !!serviceState || !!zip)
    console.log('=== showAddress ===', showAddress)

    return (                     
        <Col className={styles.planDetailsCol}>
            <h2 className="h4">Plan Information</h2>

            {/* PLAN DESCRIPTION */}
            <ul className={styles.detailsList}>
                <li className={styles.listHeader}>{description}</li>
                <li>Payment Preference: Monthly</li>
                <li>Service Fee: $49.99</li>
                <li>Plan Start Date: {startDate}</li>
            </ul>

            {/* SERVICE ADDRESS */}
            <div className={styles.detailsList}>
                {showAddress && (
                    <ul className={styles.detailsList}>
                        <li className={styles.listHeader}>Service Address</li>
                        {!!address1 && <li>{address1}</li>}
                        {!!address2 && <li>{address2}</li>}
                        {(!!city, !!serviceState, !!zip) && (
                            <li>
                                {`${!!city && (city + ',')} ${!!serviceState && serviceState} ${!!zip && zip}`}
                            </li>
                        )}
                    </ul>
                )}
                <span className="text-sm">Incorrect Service Address? <br aria-hidden="true"/>Call {phoneFormat('(877) 752-6790')}</span>
            </div>
        </Col>
    );
}

export default PlanInfo