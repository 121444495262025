import React from 'react';
import styles from './SuspendedLanding.module.css';
import classNames from 'classnames';

const SuspendedLanding = ({ }) => {
    return (
        <div className="w-100">
            <p className={classNames(styles.confirmBold)}>
                Unfortunately, we are unable to proceed with your claim at
                <br className="show-md-up" aria-hidden="true" />
                this time due to an outstanding policy payment.
            </p>
            <br className="show-md-up" aria-hidden="true" />
            <p className={classNames(styles.confirmBold)}>
                Please contact Apple at
                &nbsp;<a href="tel:866-258-7419" className={classNames("callLink")}>
                    (866) 258-7419
                </a>
                &nbsp;to make a payment.
            </p>
        </div>
    )
}
export default SuspendedLanding;