import React from 'react';
import styles from './Entitlement.module.css';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import DateField from '../shared/DateField/DateField';

export default function ({
    handleSelectIncidentDate,
    showError
}) {

    return (    
        <Row id='incident-date'>
            <Col lg='7' style={{margin: '0 auto'}}>
                <label htmlFor="incidentDateInput" className={styles.cardTitle}>When did you start experiencing this issue?</label>
                <Row className="stepsFormBlock">
                    <Col className={classNames(styles.mw275, 'd-sm-block', 'd-md-block', 'd-lg-block', 'd-xl-block')}>
                        <DateField
                            name="incidentDate"
                            showError={false}
                            onDateChange={handleSelectIncidentDate}
                            inputMinDate={true}
                            calendarMargin={false}
                            required
                        />
                        {showError &&
                            <p className="error-msg">
                                Select a date to continue.
                            </p>
                        }
                    </Col> 
                </Row>
            </Col>
        </Row>
    )
}
